import {formatLargeNumber} from './format';

export const chartMargin = {top: 10, right: 30, bottom: 40, left: 75};
export const chartAxisBottom = {
  tickSize: 0,
  tickPadding: 15,
};

export const timeXScale: {type: any; format: string; precision: string} = {
  type: 'time',
  format: '%Y-%m-%dT%H:%M:%S.%LZ',
  precision: 'second',
};

export const chartAxisY = ({unit = ''}: {unit?: string}) => {
  return {
    tickSize: 0,
    tickPadding: 15,
    format: (val: number) => `${formatLargeNumber(val)}${unit}`,
    tickValues: 5,
  };
};
