import {useMemo, useState} from 'react';

export const useInitializeRegistrationFormContext = () => {
  const [isShowingRegistration, setIsShowingRegistration] = useState(false);

  return useMemo(
    () => ({
      isShowingRegistration,
      setIsShowingRegistration,
    }),
    [isShowingRegistration, setIsShowingRegistration],
  );
};
