import {CreateIndexParam} from '../../../../constants';
import {type Environment} from '../../../../types';
import {type IndexSchema, METRIC_TYPES, indexNameCharacterLimit} from './schema';

const INDEX_DEFAULTS = {
  name: '',
  engineType: 'approximated',
  metric: 'cosine',
  pods: 1,
  replicas: 1,
  podType: 's1',
  podSize: 'x1',
} as IndexSchema;

export const metricTypes = METRIC_TYPES.map((value) => ({value}));

export function getProviderFromEnv(env: string, allEnvInfo: Environment[]) {
  const envInfo = allEnvInfo.find((e) => e.name === env);
  return envInfo?.provider;
}

function getServerlessEnvFromCloudRegion(
  allEnvInfo: Environment[],
  cloud?: string,
  region?: string,
) {
  return allEnvInfo.find(
    (e) =>
      e.env_type === 'serverless' &&
      e.provider.toLowerCase() === cloud?.toLowerCase() &&
      e.provider_region === region,
  );
}

export function getQueryParams(locationString: string) {
  const queryParams = new URLSearchParams(locationString);

  return {
    name: queryParams.get(CreateIndexParam.NAME),
    dimensions: queryParams.get(CreateIndexParam.DIMENSIONS),
    from_collection: queryParams.get(CreateIndexParam.FROM_COLLECTION),
    from_backup: queryParams.get(CreateIndexParam.FROM_BACKUP),
    environment: queryParams.get(CreateIndexParam.ENVIRONMENT),
    metric: queryParams.get(CreateIndexParam.METRIC),
    cloud: queryParams.get(CreateIndexParam.CLOUD),
    region: queryParams.get(CreateIndexParam.REGION),
  };
}

export function getDefaults(
  locationString: string,
  allEnvInfo: Environment[],
  defaultEnv?: Environment,
) {
  const params = getQueryParams(locationString);
  const dimensions = Number(params.dimensions) || INDEX_DEFAULTS.dimensions;
  const metric = METRIC_TYPES.find((type) => type === params.metric) ?? INDEX_DEFAULTS.metric;
  const name = params.name
    ? `${params.name}${params.from_collection || params.from_backup ? '-index' : ''}`
    : INDEX_DEFAULTS.name;
  const provider = params.cloud || getProviderFromEnv(params.environment || '', allEnvInfo);
  const environment =
    params.environment ||
    getServerlessEnvFromCloudRegion(allEnvInfo, params.cloud || '', params.region || '')?.name;

  return {
    ...INDEX_DEFAULTS,
    dimensions,
    environment: environment || defaultEnv?.name || '',
    from_collection: params.from_collection || undefined,
    from_backup: params.from_backup || undefined,
    metric,
    name: name.slice(0, indexNameCharacterLimit),
    podType: INDEX_DEFAULTS.podType,
    provider: provider || defaultEnv?.provider || '',
    deletionProtection: false,
  };
}
