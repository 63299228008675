import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import {type ReactElement} from 'react';

interface SummaryItemProps {
  label: string;
  value?: ReactElement | string | number;
  enableCopy?: boolean;
  tooltip?: string;
}

export default function SummaryItem({
  label,
  value = '',
  enableCopy = false,
  tooltip = '',
}: SummaryItemProps) {
  return (
    <Box>
      <Typography variant="overline" color="text.secondary">
        {label}
      </Typography>
      <Box display="flex" alignItems="center" height={30}>
        {typeof value === 'string' || typeof value === 'number' ? (
          <>
            <Typography mr={0.5} variant="subtitle2" noWrap>
              {value}
            </Typography>
            {enableCopy && <CopyButton sx={{m: 0}} text={value as string} description={label} />}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </Tooltip>
            )}
          </>
        ) : (
          value
        )}
      </Box>
    </Box>
  );
}
