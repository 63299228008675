import {MicrosoftE5Large} from '../../models/constants';
import type {Dataset} from './types';

export const tatoebaMultilingual: Dataset = {
  datasetInformation: {
    collectionName: 'tatoeba-en-es-50cb341',
    indexName: 'sample-tatoeba-en-es',
    title: 'Tatoeba Multilingual (English-Spanish)',
    subtitle: 'English and Spanish sentence translation pairs from Tatoeba.',
    description:
      'This dataset contains English and Spanish sentence translation pairs from Tatoeba. The dataset is used for multilingualism and semantic search purposes in the education industry.',
    dimension: 1024,
    metric: 'cosine',
    totalNamespaces: 2,
    totalVectors: 350690,
    upgradeRequired: true,
  },
  embeddingModel: MicrosoftE5Large,
  exampleVectorData: [
    {
      id: '0',
      values: [
        0.0279388428, 0.0174713135, -0.00801849365, -0.0309448242, 0.0595397949, -0.00484848,
        0.012336731, 0.0612792969, 0.0368652344, 0.00717926025, -0.00125980377, 0.0347595215,
        -0.0666503906, -0.0283050537, -0.0113449097, -0.0166931152, 0.0132980347, 0.0419616699,
        0.0362548828, 0.0156860352, 0.0152816772, -0.00815582275, -0.0242462158, -0.0268707275,
        -0.0271606445, -0.0179595947, -0.0503845215, -0.0375671387, 0.0117340088, -0.0401916504,
        -0.0194396973, 0.0139541626, -0.0472106934, -0.0428771973, -0.034942627, 0.0510864258,
        0.0382385254, 0.0235290527, -0.0173797607, -0.0100250244, -0.0299987793, 0.0240478516,
        0.00176239014, -0.0087890625, -0.0326843262, 0.0233306885, 0.0313415527, -0.00563812256,
        -0.037902832, 0.0143585205, -0.00440216064, 0.00636291504, -0.00324630737, -0.0287475586,
        -0.0510253906, 0.0189056396, -0.0108032227, -0.0062828064, -0.0728149414, -0.00770187378,
        -0.00364494324, 0.0085144043, 0.0369567871, -0.0204620361, -0.0639038086, 0.0378112793,
        0.0202026367, 0.00780487061, -0.059173584, 0.00951385498, -0.0166778564, 0.0075378418,
        -0.0356750488, -0.00206947327, -0.0182800293, -0.000967502594, 0.0244140625, -0.00807189941,
        0.016784668, 0.000417709351, 0.0582275391, -0.0243225098, -0.0141677856, -0.0220489502,
        0.0701904297, 0.0669555664, 0.0268554688, 0.0492553711, 0.0551147461, 0.0235900879,
        -0.0163879395, 0.010269165, 0.0265960693, -0.0184783936, -0.0386657715, 0.0143737793,
        0.02003479, 0.0346679688, -0.00770568848, -0.00986480713, -0.0198974609, -0.0141525269,
        0.0448608398, -0.0709838867, -0.0230712891, 0.0403137207, 0.0126724243, 0.0157165527,
        0.0242462158, 0.013710022, 0.022644043, 0.0449829102, 0.00594711304, -0.0170593262,
        -0.0240478516, 0.0216369629, 0.00747299194, 0.00862121582, -0.0170288086, 0.024230957,
        0.0432739258, 0.0411987305, 0.0234680176, -0.053894043, 0.0254669189, 0.00925445557,
        0.0183258057, 0.026184082, -0.0248565674, 0.00624084473, 0.0525512695, 0.042755127,
        -0.00677871704, -0.037322998, -0.0338439941, -0.0361328125, -0.0252227783, -0.0116043091,
        -0.0201263428, -0.00601196289, -0.0169677734, 0.0510864258, 0.0311584473, -0.0431518555,
        -0.00944519, -0.0479125977, -0.0672607422, 0.0373840332, -0.0133666992, -0.00439834595,
        0.0378112793, -0.00114154816, -0.0219268799, 0.0225219727, 0.0176544189, -0.0354309082,
        -0.0152587891, 0.0535583496, 0.0271606445, -0.00547790527, -0.0332946777, -0.0625610352,
        -0.00872802734, -0.0196838379, 0.00196266174, -0.0163269043, 0.0491638184, 0.0361328125,
        0.0141448975, -0.0330505371, 0.0136566162, -0.0231323242, 0.0230712891, -0.0563964844,
        -0.00479888916, 0.0565185547, 0.0322875977, 0.0322570801, 0.0361328125, -0.0272979736,
        0.0234527588, 0.036895752, 0.0506896973, -0.0377807617, 0.013381958, 0.0113372803,
        0.0379333496, 0.008934021, 0.0282592773, 0.0196380615, 0.0562438965, -0.0344543457,
        -0.0367431641, 0.00968170166, -0.0404968262, 0.0307312012, 0.0336303711, 0.0139694214,
        -0.0456542969, -0.0502929688, 0.038269043, 0.0305480957, -0.0229949951, 0.0199127197,
        0.0286407471, -0.0255737305, -0.0398254395, -0.0094833374, 0.0498657227, -0.0310821533,
        -0.0379638672, 0.00953674316, 0.0175476074, 0.0092086792, -0.00952911377, -0.0203552246,
        -0.0478210449, -0.0326843262, 0.0166778564, 0.024307251, 0.0366516113, 0.0576171875,
        0.0305175781, 0.0208587646, 0.00589370728, 0.0312042236, 0.0162811279, 0.00644683838,
        0.0426330566, 0.00743484497, 0.000466346741, 0.0284118652, -0.0559082031, -0.0176849365,
        0.006690979, -0.0327758789, -0.0283050537, 0.0166931152, 0.0204162598, 0.0163116455,
        -0.0366821289, 0.0124282837, 0.0377197266, -0.00472641, -0.00185203552, -0.0328979492,
        0.0460205078, -0.0172424316, 0.0548706055, -0.0173187256, -0.00848388672, 0.0238494873,
        -0.0310668945, -0.0123672485, 0.00593185425, 0.0308532715, 0.0482788086, 0.0164337158,
        -0.0186920166, -0.0283508301, 0.0165557861, 0.02003479, -0.00645446777, 0.0403747559,
        0.00229644775, 0.0140838623, -0.0243225098, -0.0226135254, -0.0303039551, -0.0346984863,
        -0.0380554199, -0.0411987305, -0.0427246094, -0.0142593384, -0.0345459, 0.0149917603,
        -0.0506591797, -0.0473327637, -0.00188159943, -0.0204620361, -0.0104599, -0.00913238525,
        0.00750732422, -0.0082244873, 0.0166625977, 0.00240516663, 0.0118255615, -0.000175952911,
        0.0123138428, -0.0360717773, 0.0384216309, -0.0279846191, 0.0608825684, 0.0330200195,
        0.027557373, 0.0330505371, -0.038269043, -0.0811767578, -0.0235900879, 0.0137176514,
        -0.0107650757, -0.0432128906, 0.0285949707, 0.0185089111, -0.0434875488, -0.00818634,
        0.035736084, 0.00950622559, -0.0743408203, 0.0456237793, 0.0413208, 0.014175415,
        -0.00464630127, -0.0278930664, -0.0422363281, 0.00933074951, -0.0199890137, 0.036315918,
        -0.0408630371, 0.073059082, -0.0152206421, 0.0033454895, -0.0520324707, -0.0110473633,
        0.00296592712, 0.0242919922, 0.000378370285, -0.0238189697, 0.0170593262, -0.0419616699,
        0.0195465088, -0.0404968262, 0.00567245483, 0.0366821289, -0.00716018677, -0.015914917,
        -0.0221405029, 0.0473022461, 0.0213470459, -0.0167541504, 0.00166416168, -0.00550460815,
        -0.0537414551, -0.00869751, 0.0163269043, -0.04296875, 0.021484375, -0.0188293457,
        0.00663757324, 0.0247344971, -0.00887298584, -0.0488891602, 0.0350952148, 0.0165557861,
        -0.0220489502, 0.0352172852, 0.00541687, -0.0453796387, 0.0227813721, -0.00147819519,
        0.00373840332, 0.0125198364, 0.00643920898, -0.0332641602, -0.0356445312, -0.00777816772,
        0.0170898438, -0.00454711914, -0.0667724609, -0.0422363281, 0.0368347168, 0.018447876,
        0.00589752197, 0.0271148682, -0.00278091431, -0.0323181152, -0.0580749512, 0.0229187012,
        0.00983429, -0.0710449219, -0.00815582275, -0.0303344727, 0.0278930664, -0.0334472656,
        0.0245666504, -0.022064209, 0.166870117, 0.0248260498, 0.0550231934, -0.0431213379,
        -0.0224914551, 0.00788116455, -0.00155830383, 0.00748825073, 0.0432128906, -0.00298309326,
        -0.0034828186, -0.000463008881, 0.0316772461, -0.054107666, 0.0158538818, 0.0367431641,
        0.0321960449, 0.0388183594, 0.0344848633, -0.0193786621, 0.0308227539, -0.0429382324,
        0.0223999023, 0.0108108521, -0.0268554688, -0.00965881348, -0.00619888306, 0.0344543457,
        -0.0249481201, 0.0250396729, -0.0349731445, 0.0389709473, -0.0413208, -0.0464782715,
        0.049407959, 0.0233459473, 0.0228729248, -0.0613708496, 0.063659668, 0.00148296356,
        -0.019241333, 0.0354003906, -0.0422668457, 0.078918457, -0.00225830078, 0.0368042,
        0.0634155273, -0.0393981934, -0.0109481812, 0.0371704102, 0.0188446045, 0.0286712646,
        -0.00828552246, -0.038482666, 0.000340938568, 0.00113296509, -0.0334777832, -0.0425109863,
        -0.0109863281, -0.00901794434, 0.0288696289, -0.00304603577, -0.0260009766, -0.0266876221,
        0.0339660645, 0.0127487183, -0.0197143555, -0.0164337158, -0.0308074951, -0.0179443359,
        -0.0118865967, -0.0254211426, -0.0233917236, 0.0066947937, 0.0242004395, 0.0168457031,
        -0.015411377, 0.0578918457, 0.0475769043, -0.0109176636, 0.031463623, -0.0364990234,
        0.00758361816, 0.00292778015, 0.0490112305, -0.0268707275, 0.0344543457, 0.0273742676,
        -0.0117492676, 0.00393295288, 0.00402450562, 0.0299835205, -0.035949707, 0.0223846436,
        0.0110778809, -0.0782470703, 0.0357971191, 0.019821167, -0.0242462158, -0.0379638672,
        -0.0140762329, 0.00848388672, -0.0204315186, -0.0464477539, 0.00984954834, -0.00868988,
        -0.0216217041, 0.043762207, 0.038482666, -0.00495147705, 0.0317993164, -0.0152740479,
        0.0025100708, -0.0136032104, 0.0283050537, 0.0202789307, 0.0423278809, -0.0406799316,
        0.0136260986, 0.0257568359, -0.011428833, 0.03515625, -0.0332641602, 0.0815429688,
        0.00608444214, 0.037109375, -0.0212402344, 0.0333862305, 0.0281524658, 0.0371398926,
        0.00281143188, -0.0303039551, -0.0305175781, 0.0092086792, 0.0194091797, -0.021484375,
        0.0491027832, -0.0246276855, 0.0223693848, 0.0444335938, -0.00909423828, 0.0205688477,
        -0.0347900391, 0.0586547852, -0.0252075195, 0.0264587402, -0.0395202637, -0.0319519043,
        -0.0320739746, -0.0176849365, 0.0378417969, -0.0440368652, 0.0170898438, 0.00157547,
        -0.0329589844, -0.0492248535, 0.0179290771, 0.0244445801, 0.0382385254, -0.0291595459,
        -0.0328979492, -0.00565338135, 0.0112915039, 0.0824584961, 0.0467834473, 0.0466918945,
        -0.0325317383, 0.0324707031, 0.0453491211, 0.0173187256, -0.0136947632, -0.0379638672,
        -0.037689209, 0.028427124, 0.0236358643, -0.00622558594, -0.0338745117, 0.0319824219,
        -0.0462646484, 0.00325393677, -0.00978088379, 0.00136852264, 0.0456237793, -0.0289306641,
        -0.014541626, -0.0181732178, 0.0335388184, -0.0343017578, -0.0348205566, 0.00161743164,
        -0.0440673828, 0.0705566406, -0.00114440918, -0.0107803345, -0.0463562, -0.0122146606,
        0.0504760742, 0.0111923218, -0.0411376953, -0.014503479, -0.0119247437, -0.0272369385,
        -0.0351867676, -0.0254821777, -0.0411376953, -0.00367164612, 0.00315284729, 0.0505065918,
        0.00108337402, 0.0323791504, 0.0111923218, -0.0308990479, 0.0163574219, 0.00143909454,
        0.00141811371, 0.0316772461, 0.0104064941, 0.0346984863, 0.031829834, 0.00782775879,
        0.0192718506, 0.0290374756, 0.0289306641, -0.0254821777, -0.0509338379, -0.0207061768,
        0.0206604, 0.0119934082, -0.0273895264, -0.0187225342, 0.00332260132, -0.0226593018,
        -0.0417785645, -0.0418701172, 0.012046814, -0.0128097534, 0.0385437, 0.0118865967,
        0.0278930664, -0.0391540527, -0.00427246094, -0.00721359253, 0.0198516846, -0.019744873,
        -0.0288085938, 0.0204925537, -0.0506896973, -0.0233612061, 0.0193328857, 0.0268707275,
        0.012008667, -0.0513000488, 0.01953125, 0.0883789062, -0.029510498, -0.0114059448,
        0.00653076172, -0.0444335938, -0.0631103516, -0.0291595459, 0.0256195068, 0.040802002,
        -0.00744247437, -0.00539016724, -0.00792694092, 0.0297241211, 0.0488891602, -0.0440673828,
        -0.0101699829, -0.073059082, 0.0175476074, 0.0130462646, -0.0197143555, -0.0731811523,
        0.0670166, 0.0266265869, -0.0537414551, -0.0340881348, -0.0453186035, -0.0213165283,
        0.00428390503, 0.0141448975, -0.00749588, 0.00318145752, 0.0548400879, 0.0155792236,
        -0.0361328125, 0.0377197266, -0.0321350098, -0.032409668, -0.0171203613, 0.01222229,
        -0.0152587891, 0.00869751, -0.027557373, -0.0670776367, -0.0134277344, 0.00410079956,
        0.00993347168, -0.0323181152, -0.0129928589, -0.037902832, -0.0573730469, -0.0173797607,
        -0.0059928894, 0.00177955627, -0.0321960449, -0.0141448975, 0.0446777344, 0.0382385254,
        -0.00728988647, -0.0556335449, 0.00049495697, 0.0212402344, -0.00881195068, -0.00254249573,
        0.0109405518, -0.0296020508, -0.0204772949, 0.0579834, -0.0418396, -0.0393371582,
        -0.028213501, 0.010887146, 0.00716781616, -0.00752639771, 0.0424804688, 0.0195465088,
        0.0210571289, -0.0161895752, 0.00689315796, -0.00382804871, -0.0364685059, -0.0386047363,
        -0.024810791, 0.0143356323, 0.016494751, -0.00910186768, -0.0171356201, -0.032409668,
        0.0118103027, 0.025680542, 0.0513916, -0.073059082, 0.0211639404, -0.0139770508,
        -0.0254211426, 0.0457458496, -0.0437927246, -0.0102615356, -0.00817871094, 0.0468139648,
        0.0000270605087, 0.000783443451, -0.037902832, 0.0183105469, -0.0400390625, 0.0112304688,
        0.0235290527, -0.0536499023, -0.0195007324, -0.0196990967, 0.0243530273, -0.0293731689,
        -0.0328063965, 0.00949859619, -0.0124511719, 0.0237731934, -0.0316772461, 0.0123901367,
        -0.0301208496, 0.0503845215, -0.0518493652, -0.0221862793, -0.0299530029, 0.0431518555,
        -0.0493774414, -0.0108718872, 0.0221252441, 0.013381958, 0.0495605469, -0.051361084,
        -0.0223693848, -0.00440597534, 0.0183563232, -0.0442504883, 0.00315666199, 0.0486755371,
        0.0275726318, 0.0550231934, -0.0221557617, 0.0490112305, -0.0389099121, 0.0178375244,
        -0.0692138672, -0.00673675537, -0.0126724243, -0.022277832, -0.0307006836, -0.00680160522,
        -0.0392150879, 0.00984954834, -0.00150871277, -0.0450744629, 0.0182037354, 0.0675048828,
        0.0296478271, -0.00246047974, -0.0199432373, 0.0403137207, 0.0333557129, 0.000719070435,
        0.038482666, 0.0116271973, 0.0492553711, -0.0155563354, -0.00833129883, 0.00909423828,
        -0.00239372253, 0.032989502, 0.0310668945, 0.0214538574, -0.0226745605, -0.0599060059,
        -0.0177001953, -0.0270843506, 0.0127792358, 0.0439147949, -0.0225219727, 0.00638961792,
        -0.0234222412, -0.0417785645, -0.0149078369, 0.0127334595, -0.0301055908, 0.0423584,
        -0.010887146, 0.00532531738, -0.00882720947, 0.0429992676, -0.0127868652, -0.00882720947,
        0.0065612793, -0.0208587646, -0.0413818359, -0.00452423096, -0.00743103027, 0.0521240234,
        0.0243530273, 0.0365905762, 0.0186004639, -0.0422973633, 0.0288238525, 0.0152130127,
        -0.00246238708, -0.0143890381, -0.0404968262, -0.014465332, -0.0542297363, -0.032989502,
        -0.037689209, -0.0383605957, -0.01197052, 0.0200042725, 0.00420379639, -0.0227508545,
        0.00158405304, 0.0103607178, 0.0280456543, -0.0142059326, -0.037322998, 0.0191650391,
        0.0353088379, -0.0187225342, -0.0298461914, -0.0242919922, 0.00662231445, 0.00772476196,
        -0.0321350098, 0.000462532043, -0.0105285645, -0.0305633545, -0.042755127, 0.0216064453,
        -0.0299530029, 0.00580215454, 0.0208740234, -0.0433349609, -0.0159606934, -0.0195159912,
        -0.0157165527, -0.024520874, 0.0154571533, 0.0395813, -0.016494751, -0.00709533691,
        0.0083694458, -0.0156097412, -0.00615692139, 0.0116195679, 0.0227508545, -0.0245666504,
        0.0146713257, -0.0156021118, -0.0458068848, -0.0197753906, -0.0354919434, -0.0235443115,
        -0.0495300293, 0.0318603516, -0.00485992432, 0.0187072754, -0.0309448242, -0.0117263794,
        -0.00971221924, -0.0710449219, 0.0222625732, -0.00695800781, 0.0232696533, -0.0283813477,
        0.019821167, -0.0612487793, -0.0197753906, -0.00182819366, 0.0486450195, -0.00974273682,
        0.0637207, 0.00497055054, -0.0291290283, -0.0149993896, -0.049407959, 0.0376281738,
        -0.00409317, 0.0365905762, -0.00885009766, -0.0574951172, -0.0209655762, -0.0350036621,
        -0.0432739258, 0.0184021, -0.0395813, -0.0356445312, -0.0136184692, -0.00281906128,
        0.0178527832, -0.0299987793, -0.0471191406, 0.0582580566, 0.0191802979, 0.0157623291,
        -0.0145187378, -0.0338439941, 0.0276489258, 0.0173339844, -0.0383300781, 0.0121917725,
        -0.046295166, 0.0106430054, -0.0369262695, -0.0372924805, 0.000371456146, 0.0227050781,
        -0.0322875977, -0.019744873, 0.0151062012, 0.033996582, -0.0325622559, -0.00585174561,
        0.0374755859, 0.00811767578, 0.0461730957, 0.0150527954, 0.0359802246, 0.0179595947,
        -0.00314903259, -0.00283432, -0.0182189941, 0.0386047363, 0.033782959, 0.0759277344,
        0.00588226318, 0.0356140137, -0.00988006592, -0.00704193115, -0.0257720947, 0.0285186768,
        -0.0433044434, 0.0192718506, 0.0531921387, -0.0374450684, 0.00836181641, 0.00682830811,
        -0.046081543, -0.00605011, -0.0219421387, 0.00237464905, 0.0380859375, -0.0127792358,
        -0.030960083, -0.0589599609, -0.0260620117, 0.018737793, -0.00579071045, 0.0203704834,
        0.0606689453, -0.0134506226, 0.0183563232, 0.0291137695, 0.0000521540642, 0.0232543945,
        0.0497131348, -0.0283508301, -0.0336608887, 0.0346374512, 0.0192718506, 0.025680542,
        0.0072593689, 0.0298156738, 0.00629043579, 0.0404052734, -0.0586853027, 0.0114440918,
        -0.00566864, -0.0202636719, 0.00141525269, 0.0144958496, 0.0220947266, 0.0323486328,
        -0.0244750977, -0.0062713623, 0.0744018555, -0.00354385376, 0.0262908936, 0.0228118896,
        -0.00180053711, -0.036895752, -0.0199584961, -0.000764846802,
      ],
      metadata: {
        english_text: "Let's try something.",
        spanish_text: '¡Intentemos algo!',
      },
    },
    {
      id: '1',
      values: [
        0.016494751, -0.0154571533, -0.00366973877, -0.0490722656, 0.0205383301, -0.00772094727,
        -0.0302581787, 0.0503540039, 0.0228881836, -0.0410766602, 0.0287475586, 0.0198516846,
        -0.0419311523, -0.0187988281, -0.0206298828, -0.043182373, 0.040435791, 0.0261535645,
        -0.0104293823, 0.0224609375, 0.0235443115, -0.0316162109, -0.035736084, -0.00735092163,
        -0.0383911133, -0.0311889648, -0.0159912109, -0.0150909424, -0.00635910034, -0.0185089111,
        0.00964355469, 0.00330162048, -0.0489196777, -0.0581970215, -0.0294647217, 0.0377807617,
        0.0478210449, 0.0515136719, -0.0135726929, 0.0222930908, -0.0139541626, 0.0251159668,
        -0.00260734558, -0.0229797363, 0.00121593475, 0.0230865479, 0.0419921875, 0.0317077637,
        0.00830078125, 0.0317993164, 0.0379333496, 0.00649642944, 0.0134353638, 0.00449752808,
        -0.0590515137, 0.0399475098, -0.019744873, -0.00758743286, 0.0122680664, -0.045715332,
        -0.00884246826, 0.00985717773, 0.0346984863, -0.0226287842, -0.0192871094, 0.0239715576,
        0.0351867676, -0.0123062134, -0.0361328125, 0.00730133057, -0.0393371582, 0.0333862305,
        -0.032989502, -0.00453567505, -0.0214385986, -0.00503921509, 0.0109176636, -0.00479125977,
        0.0129165649, -0.0318603516, 0.0936889648, -0.0215606689, 0.0358886719, -0.013885498,
        0.017074585, 0.0319519043, 0.00940704346, 0.0603027344, 0.050567627, 0.0351867676,
        -0.0282897949, 0.016494751, 0.0184021, -0.0198822021, -0.0338745117, 0.00359916687,
        0.0336303711, -0.00590896606, -0.0010433197, 0.0265808105, 0.0124816895, -0.0026474,
        0.0378723145, -0.0126571655, -0.0353088379, 0.0348510742, 0.02394104, 0.0213775635,
        -0.0218658447, 0.0204925537, 0.0654296875, 0.0422363281, 0.028793335, -0.0393676758,
        -0.00706863403, -0.021987915, -0.00385856628, -0.00537109375, -0.00846862793, 0.0173339844,
        0.0440368652, 0.0293121338, -0.013053894, -0.0341491699, 0.0174713135, -0.00717544556,
        0.0140609741, 0.00653457642, -0.0185089111, 0.0026474, 0.0324401855, 0.0224609375,
        -0.0160369873, -0.040222168, -0.0275878906, 0.0104599, -0.0127105713, -0.00187110901,
        -0.0345153809, -0.0120925903, -0.0281219482, 0.0153808594, 0.0511779785, -0.0372009277,
        -0.012336731, -0.0172424316, 0.00492477417, 0.038482666, -0.00183582306, -0.0173034668,
        0.0135498047, -0.00385093689, -0.0545043945, 0.043548584, 0.0323486328, 0.00682067871,
        0.0110015869, 0.0659179688, -0.00207710266, -0.00429153442, -0.053894043, -0.0240783691,
        -0.0321655273, -0.0157775879, 0.00791168213, -0.0325927734, 0.00964355469, 0.0298309326,
        0.00415420532, -0.00619125366, -0.0393066406, -0.0531311035, -0.00666046143, -0.0350952148,
        0.000293254852, 0.0477905273, 0.012840271, 0.0391845703, 0.010848999, -0.0321655273,
        0.00136089325, 0.0498657227, 0.0403747559, -0.0265045166, -0.0193328857, 0.0401916504,
        0.0478210449, 0.0216064453, 0.0419921875, 0.0338134766, 0.0303192139, -0.0345153809,
        -0.0366210938, 0.0276794434, -0.033782959, 0.0149383545, 0.00199127197, 0.0550231934,
        -0.0253753662, -0.0216522217, -0.034362793, 0.00470733643, -0.00910186768, -0.00687408447,
        0.015335083, -0.0546569824, -0.0220794678, -0.027557373, 0.0246582031, -0.010635376,
        -0.00141716, 0.00401306152, -0.0169067383, 0.0245361328, -0.0338745117, -0.0465698242,
        -0.0511779785, -0.0397949219, 0.0173950195, -0.00553131104, 0.0167999268, 0.0719604492,
        0.0224456787, 0.0250701904, -0.00232505798, 0.0598754883, 0.0233612061, 0.0345153809,
        0.0656738281, 0.0263977051, 0.0278167725, 0.0182189941, -0.0641479492, -0.0108261108,
        0.0265808105, -0.0290679932, -0.0272827148, 0.0191040039, 0.028503418, 0.00239562988,
        -0.0593261719, 0.0129470825, -0.00479507446, -0.0225219727, 0.0286712646, 0.00402069092,
        0.0426025391, -0.0317688, 0.0279846191, 0.00298690796, 0.00845336914, 0.00510025024,
        -0.0386047363, -0.0232391357, 0.0162811279, 0.0364074707, 0.016998291, 0.000733375549,
        -0.000940322876, -0.0053062439, 0.0347290039, 0.0360717773, -0.0225982666, 0.0270385742,
        0.0205841064, 0.00707244873, -0.00429153442, -0.0213012695, -0.0296173096, -0.0527038574,
        -0.0443725586, 0.00537872314, -0.0521850586, -0.0115966797, 0.00471115112, -0.0238952637,
        -0.0289459229, -0.0235443115, 0.00835418701, 0.00057888031, 0.00504684448, 0.00372314453,
        0.069152832, -0.0422668457, 0.00584030151, -0.0467224121, -0.00924682617, -0.0263519287,
        -0.00681304932, -0.00693893433, -0.00598526, -0.00829315186, 0.0591430664, 0.0480651855,
        0.00517272949, 0.0258331299, -0.0504455566, -0.0651855469, -0.0301971436, 0.0136260986,
        -0.0244445801, -0.0599975586, 0.0313720703, 0.0199737549, -0.0657959, -0.0260162354,
        0.0501403809, 0.0335693359, -0.0598449707, 0.0626831055, 0.0108566284, 0.0451965332,
        0.0473327637, -0.0299224854, -0.0409545898, 0.00247001648, -0.0294647217, 0.0131607056,
        -0.0202484131, 0.0145492554, -0.0158844, -0.034942627, -0.0472717285, 0.00278282166,
        -0.00791931152, 0.0120925903, 0.049621582, -0.0312347412, 0.0051612854, -0.0295257568,
        0.0318603516, -0.0437316895, -0.0241851807, 0.0523071289, -0.0238647461, 0.044342041,
        -0.0163879395, 0.0580444336, 0.0272369385, 0.0031452179, 0.0144500732, 0.00172805786,
        -0.0383300781, -0.0257568359, 0.00759887695, -0.0616455078, 0.015411377, -0.00939941406,
        0.0229034424, 0.0168457031, 0.0228881836, -0.0322265625, 0.0262146, 0.0269775391,
        -0.00761032104, 0.0213317871, 0.0241851807, -0.0161132812, 0.00304985046, -0.0309295654,
        0.0402526855, -0.016418457, 0.0629882812, -0.0255737305, -0.0182495117, 0.0127410889,
        -0.00301933289, -0.0202178955, -0.0494995117, -0.0404663086, 0.00283432, 0.00242042542,
        -0.026763916, 0.01171875, -0.0111541748, -0.00113582611, -0.0832519531, -0.0345459,
        -0.0245361328, -0.0532836914, -0.030670166, -0.0311737061, 0.0184631348, -0.0382995605,
        -0.00940704346, -0.0476989746, 0.162475586, 0.0104141235, 0.0473632812, -0.0831298828,
        -0.0235900879, 0.0217437744, 0.0121231079, 0.00519943237, 0.0229034424, 0.0203094482,
        -0.0294189453, 0.0131225586, 0.0172271729, -0.0346679688, 0.0314941406, 0.0672607422,
        0.0121765137, 0.0388183594, 0.0410766602, -0.00309562683, 0.0500488281, -0.0528564453,
        -0.00891876221, -0.00877380371, -0.0168457031, -0.0297393799, 0.00326728821, 0.0258026123,
        -0.0570678711, -0.00807952881, -0.0383300781, 0.0601196289, -0.0399475098, -0.0347290039,
        0.012046814, 0.0661010742, 0.00785827637, -0.0285339355, 0.0253753662, 0.00796508789,
        -0.0498657227, 0.00805664062, -0.0311737061, 0.0603027344, -0.0083694458, 0.0239715576,
        0.0551147461, -0.01222229, 0.00657653809, 0.0138244629, 0.0263061523, 0.022857666,
        -0.0213012695, -0.00464630127, 0.0189056396, -0.0371704102, -0.0380249023, -0.00937652588,
        -0.00891113281, -0.00526428223, 0.0573425293, -0.00652313232, -0.0565490723, -0.01902771,
        0.0128707886, 0.0328979492, -0.0548400879, -0.00471878052, -0.0216064453, -0.0278625488,
        0.00225830078, 0.0162963867, -0.016494751, 0.0255279541, 0.00866699219, -0.0110778809,
        -0.032989502, 0.0510864258, 0.0384216309, -0.0274505615, 0.022354126, -0.0483703613,
        -0.0101852417, -0.00957489, 0.0341796875, -0.0163116455, 0.0505371094, 0.0219726562,
        -0.0345459, 0.0287475586, 0.0473632812, 0.0385437, -0.0341186523, 0.021484375,
        0.000400543213, -0.0822753906, 0.0105438232, 0.0168762207, -0.0230255127, 0.0108261108,
        -0.0274658203, -0.00207901, -0.017074585, -0.0400695801, -0.02394104, -0.00817108154,
        0.0210113525, -0.000981330872, 0.0272979736, -0.0278625488, 0.00673675537, 0.0031337738,
        0.0290679932, -0.0254364014, 0.0637207, 0.026763916, 0.0100402832, -0.067565918,
        0.0128250122, 0.0108642578, -0.0144500732, 0.0166778564, -0.0311889648, 0.0358886719,
        0.0041809082, 0.0420227051, -0.0281677246, -0.00238418579, 0.0291900635, 0.029876709,
        0.0126190186, -0.0590820312, -0.0398254395, 0.0108261108, 0.0407409668, 0.00901031494,
        0.0669555664, -0.0246582031, 0.00352287292, 0.0353393555, -0.00120162964, 0.0389404297,
        -0.0206604, 0.0255279541, 0.0247039795, 0.0276031494, -0.0551147461, -0.0327453613,
        -0.0281219482, -0.0343322754, 0.0220336914, -0.00203895569, 0.037689209, 0.00759124756,
        -0.0193328857, -0.0605773926, 0.0112915039, 0.00746154785, 0.0391540527, -0.0585327148,
        -0.0232086182, 0.00332641602, 0.00386047363, 0.0641479492, 0.0495910645, 0.0178070068,
        -0.059967041, 0.0472412109, 0.0108184814, 0.0324401855, -0.0155029297, 0.00667953491,
        -0.0101242065, 0.0181427, 0.017074585, -0.0493164062, -0.00630950928, 0.0485839844,
        -0.0131759644, 0.00434875488, -0.0138244629, 0.0321960449, 0.044128418, 0.00480651855,
        -0.0110549927, 0.0282897949, 0.0161132812, -0.0510864258, -0.00514221191, 0.0152740479,
        -0.0398254395, 0.0340576172, -0.0393371582, 0.0123977661, -0.011428833, 0.0163879395,
        0.020111084, 0.0282440186, -0.0412902832, -0.0376281738, 0.0079422, -0.0158233643,
        -0.0301361084, -0.00219535828, -0.0357971191, -0.0335388184, 0.0124206543, 0.014465332,
        0.0270385742, 0.0239715576, 0.0014333725, -0.0297241211, 0.0292816162, 0.0266723633,
        0.0458068848, 0.0373535156, 0.0595703125, 0.0203094482, 0.0112686157, -0.0269927979,
        0.0228881836, 0.0155334473, 0.0176849365, -0.0596008301, -0.0549316406, -0.00709533691,
        0.0497131348, 0.0200042725, -0.0068321228, -0.0129013062, 0.0153503418, 0.000423908234,
        -0.00375747681, -0.0349121094, -0.0548400879, -0.0294494629, 0.0174713135, 0.0181732178,
        0.0193634033, -0.0046081543, 0.0220947266, -0.025894165, 0.00820922852, -0.0164794922,
        -0.0255279541, 0.0738525391, -0.0634765625, -0.0110778809, 0.0359191895, 0.0158691406,
        -0.00442123413, -0.0433959961, 0.0426330566, 0.0781860352, -0.00733947754, -0.0199432373,
        0.0148696899, -0.0473632812, -0.0718383789, -0.0129394531, 0.0175628662, 0.042175293,
        -0.0266876221, -0.002784729, -0.0338745117, 0.0226898193, 0.0273895264, -0.0161895752,
        0.0183410645, -0.0734863281, 0.0272979736, -0.036315918, -0.0347290039, -0.0448608398,
        0.0558166504, 0.0456237793, -0.0314941406, -0.0271606445, -0.00983429, -0.0651245117,
        0.00674819946, 0.0521850586, 0.00380706787, 0.0174407959, -0.00933837891, 0.0188446045,
        -0.0382080078, -0.0154418945, 0.00510787964, -0.0341186523, -0.0273590088, 0.00379562378,
        -0.0606384277, -0.015586853, -0.0740356445, -0.0361328125, -0.0339660645, -0.025390625,
        -0.00437545776, -0.0390930176, 0.0267791748, 0.00118350983, -0.0453796387, -0.020401001,
        0.0112991333, 0.0403747559, -0.0145263672, -0.0101852417, 0.0568847656, 0.0608825684,
        0.000865936279, -0.0430908203, -0.00206184387, 0.043548584, -0.00637435913, -0.022354126,
        0.0134658813, -0.0334777832, -0.00429534912, 0.0256347656, -0.0449523926, -0.0158691406,
        -0.0311889648, -0.0199737549, 0.0345153809, 0.0316467285, 0.0058555603, 0.0284423828,
        0.0169067383, 0.00964355469, 0.0247192383, 0.00556182861, -0.0517578125, 0.00182533264,
        -0.0102996826, -0.000789642334, -0.00138759613, -0.0440979, -0.00749206543, -0.0198822021,
        -0.00137043, 0.0325927734, 0.0465698242, -0.0501403809, 0.00833892822, -0.00234603882,
        -0.0220947266, 0.0240478516, -0.0175323486, -0.024017334, -0.0431518555, 0.0440063477,
        -0.00372886658, 0.0174865723, -0.0166931152, 0.0235443115, -0.0432434082, 0.0167694092,
        0.0369873047, -0.027130127, -0.0416259766, -0.00719070435, 0.0200042725, 0.012336731,
        -0.0369567871, 0.00480651855, -0.015411377, 0.0479125977, -0.025680542, 0.00741958618,
        -0.0327758789, 0.0254974365, -0.0269317627, -0.0267333984, -0.0321044922, 0.00769042969,
        -0.0280151367, 0.0276489258, 0.0181732178, 0.0159759521, 0.0201263428, -0.0446777344,
        -0.0421142578, -0.0061340332, 0.0394897461, -0.0655517578, -0.0183868408, 0.0344238281,
        -0.0152053833, 0.00662994385, -0.000409603119, 0.0572509766, -0.038848877, 0.016708374,
        -0.0682983398, 0.00455474854, -0.0112228394, -0.0225524902, -0.0406188965, -0.0122756958,
        -0.0237731934, 0.00961303711, 0.0299987793, -0.0339355469, 0.00726699829, 0.0263061523,
        -0.000513076782, -0.00114917755, -0.000905513763, 0.0175170898, 0.00242233276,
        -0.0220184326, 0.0175170898, 0.0152587891, 0.021697998, -0.0233459473, -0.00277328491,
        0.0340881348, -0.0227966309, 0.0448303223, -0.00466918945, 0.0228881836, -0.0608825684,
        -0.0430603027, 0.0166473389, 0.00316619873, -0.0124206543, 0.0471191406, -0.0279388428,
        0.0258331299, -0.00623321533, -0.0336303711, -0.00337982178, 0.0259552, -0.0542602539,
        0.0577697754, -0.0364990234, 0.00981140137, -0.0108032227, 0.0523376465, 0.000192046165,
        -0.00403213501, 0.00713348389, 0.00529098511, -0.0400390625, -0.0242004395, 0.0343017578,
        0.0701904297, 0.0122299194, -0.00372886658, 0.0306243896, -0.0368347168, 0.0171356201,
        0.00506591797, 0.0218811035, -0.0150756836, -0.0405273438, -0.0443725586, -0.012840271,
        -0.0342407227, -0.0341796875, -0.0146408081, -0.0259552, 0.0467529297, -0.0199584961,
        -0.0325622559, 0.00353622437, -0.027923584, 0.0405578613, -0.0385742188, -0.0424804688,
        0.00350379944, 0.0185089111, 0.0280761719, -0.0543212891, -0.0193634033, 0.0210418701,
        0.0111083984, -0.00752639771, 0.0263977051, 0.00119113922, -0.0701293945, -0.0275421143,
        0.00672531128, -0.0419006348, -0.0320129395, 0.0497436523, -0.0109634399, 0.00964355469,
        -0.0256500244, -0.0560302734, -0.0225067139, 0.00824737549, 0.0305480957, -0.00127696991,
        -0.0335998535, -0.0259857178, 0.0163726807, -0.00475692749, 0.00107479095, 0.0470275879,
        -0.0118713379, 0.0340576172, -0.0752563477, -0.0467529297, -0.0150146484, -0.0512695312,
        0.0324707031, -0.034942627, -0.00350379944, 0.00254058838, 0.0175170898, -0.00220680237,
        -0.00920105, -0.0179443359, -0.0539855957, 0.0594177246, 0.000121235847, 0.014289856,
        -0.0158996582, 0.0136413574, -0.0148620605, 0.033996582, -0.0255737305, 0.0162963867,
        -0.0548095703, 0.0407409668, -0.0116958618, -0.0266723633, -0.00435638428, -0.0200500488,
        0.0340881348, -0.013923645, 0.0271453857, 0.00259399414, -0.034942627, -0.0424194336,
        -0.0351867676, -0.0292053223, 0.0107574463, -0.0552978516, -0.0516967773, 0.0058555603,
        0.0132598877, 0.00914764404, -0.0364685059, -0.0263519287, 0.0302429199, 0.000564575195,
        0.043548584, -0.00589752197, 0.0272827148, 0.0230712891, 0.0360412598, -0.00645828247,
        -0.0114517212, -0.0518188477, 0.0290222168, -0.00524139404, -0.00212478638, -0.00115394592,
        0.0305175781, -0.0380554199, 0.0191955566, 0.00571060181, 0.0167388916, -0.0326843262,
        0.0552063, 0.0130996704, 0.01902771, 0.0522155762, -0.0104446411, 0.0303955078,
        0.0209960938, -0.0249786377, -0.00791168213, -0.0511779785, 0.0339050293, 0.0109481812,
        0.0696411133, 0.00775527954, 0.0178375244, 0.00780487061, 0.0116882324, -0.0540771484,
        0.0284423828, -0.0539550781, 0.0635986328, 0.0172424316, -0.0475463867, -0.00606155396,
        0.0132369995, -0.0484619141, 0.013053894, 0.00523757935, -0.0336303711, 0.0219116211,
        0.00914764404, -0.0031337738, -0.00381851196, -0.0338134766, -0.0223388672, -0.028717041,
        0.029586792, 0.0947265625, -0.0106201172, 0.0351867676, -0.00456237793, 0.0204162598,
        0.0126113892, 0.033782959, -0.0217437744, 0.00971984863, 0.0530395508, 0.03074646,
        0.0258789062, 0.00288963318, 0.00503540039, -0.0103378296, 0.00430297852, -0.0525512695,
        0.00687789917, -0.0225982666, -0.00827789307, 0.00709152222, 0.01197052, -0.00159835815,
        -0.0242919922, -0.0159454346, -0.0137329102, 0.0143203735, 0.033996582, -0.0392456055,
        0.00785827637, 0.0335693359, -0.0247650146, -0.0356140137, 0.0117721558,
      ],
      metadata: {
        english_text: 'I have to go to sleep.',
        spanish_text: 'Tengo que irme a dormir.',
      },
    },
    {
      id: '2',
      values: [
        0.0321350098, 0.0176849365, -0.00497055054, -0.0518493652, 0.0499572754, -0.0331420898,
        -0.0342407227, 0.0588378906, 0.0588989258, -0.0455932617, 0.0392456055, 0.020904541,
        -0.0455932617, 0.00714492798, -0.0430908203, -0.0398864746, 0.0208129883, 0.0496826172,
        -0.00157356262, -0.00512313843, 0.020614624, 0.00852966309, -0.0194396973, -0.0240325928,
        -0.0331420898, -0.0247955322, -0.0403442383, -0.0510864258, 0.00855255127, -0.0537109375,
        -0.0158081055, 0.00151348114, -0.0419921875, -0.0506286621, -0.00699234, 0.0105438232,
        0.0440979, 0.0378723145, -0.0220184326, 0.0282745361, -0.0148849487, 0.063293457,
        -0.0169219971, -0.031829834, -0.041809082, 0.0153884888, 0.037902832, 0.0224304199,
        -0.0418396, -0.00416564941, 0.0255737305, 0.0296630859, -0.0255126953, -0.0297546387,
        -0.0704956055, 0.0255126953, -0.0276489258, 0.00408172607, -0.0160217285, -0.020614624,
        -0.0236206055, -0.0305938721, 0.0215606689, -0.0274505615, -0.0345459, 0.0256347656,
        0.024520874, -0.00140285492, -0.0232391357, 0.00271224976, -0.0263977051, 0.022644043,
        -0.00324249268, -0.0273895264, -0.00891876221, -0.00798797607, 0.000568389893,
        -0.00282287598, -0.0064163208, -0.0121383667, 0.0785522461, -0.0175933838, 0.0102615356,
        -0.0319213867, 0.0150985718, 0.02394104, -0.00353050232, 0.0322570801, 0.0353088379,
        0.03515625, 0.0225830078, 0.0174407959, 0.0111312866, -0.0243682861, -0.0395202637,
        0.030456543, -0.0305633545, 0.00783538818, -0.0282745361, 0.0076789856, 0.0120162964,
        -0.00652313232, 0.0132217407, -0.0321350098, -0.0113449097, 0.0545349121, 0.032623291,
        0.0450134277, -0.00353050232, 0.0152053833, 0.0443725586, 0.0047416687, 0.000242710114,
        -0.0514831543, -0.0256347656, 0.0116424561, 0.0342407227, -0.0216217041, -0.0102844238,
        0.0353393555, 0.0279083252, 0.03125, 0.005443573, -0.0489501953, 0.0266571045,
        -0.0156021118, -0.0209350586, 0.02734375, -0.00168228149, 0.00113201141, 0.0448608398,
        0.0122833252, -0.0131378174, -0.0356750488, -0.0479125977, -0.013961792, -0.0126876831,
        -0.00756073, -0.0316467285, 0.0108795166, -0.00709152222, 0.0329284668, 0.0666503906,
        -0.0212249756, -0.0362548828, -0.0242462158, -0.0205841064, 0.0213470459, -0.0121688843,
        -0.00581359863, 0.0344543457, -0.022644043, -0.0310668945, -0.0175323486, 0.0345153809,
        -0.0209960938, 0.0141525269, 0.0506896973, 0.0252227783, 0.00971221924, -0.0148468018,
        -0.0556030273, -0.041229248, -0.0227661133, -0.00708770752, -0.00644683838, 0.0359802246,
        0.0355834961, -0.0208892822, 0.00203704834, -0.0109024048, -0.0403137207, 0.029586792,
        -0.037322998, -0.0364990234, 0.0333557129, 0.0263366699, 0.0233306885, 0.0278778076,
        -0.0301971436, -0.0378723145, 0.0462646484, 0.059387207, -0.00400543213, -0.0147476196,
        0.0272979736, 0.0269012451, 0.0158996582, 0.0404968262, 0.0227661133, 0.0693359375,
        -0.0266571045, -0.0260620117, 0.0434570312, -0.0222625732, 0.0354919434, 0.0553283691,
        0.0414428711, -0.0234985352, -0.0436096191, -0.0205841064, 0.0116195679, -0.0536193848,
        0.0254058838, 0.0122756958, -0.0283203125, -0.0211792, -0.0228424072, 0.0528869629,
        -0.040435791, -0.0258636475, -0.00625610352, 0.0209198, 0.0165710449, -0.0466308594,
        -0.059753418, -0.0407104492, -0.0118103027, 0.0318603516, -0.00891113281, 0.00719070435,
        0.0674438477, -0.00489044189, 0.0223083496, 0.00997924805, 0.025604248, 0.0745239258,
        0.0412902832, 0.0197753906, 0.0144805908, -0.000475883484, 0.0397033691, -0.0881347656,
        -0.00560379028, -0.0187835693, -0.00588989258, -0.0458068848, -0.00983429, 0.0147094727,
        0.041229248, -0.0386352539, 0.0150756836, 0.00838470459, -0.0215911865, 0.00155735016,
        0.0018196106, 0.0260620117, -0.0308074951, 0.0287475586, -0.0275115967, 0.0217895508,
        0.0229034424, -0.0177307129, 0.000445365906, 0.035949707, 0.021194458, 0.0205383301,
        -0.0113220215, -0.0144119263, -0.00601959229, 0.0337219238, -0.000611782074, -0.00665664673,
        0.032989502, 0.0136260986, 0.0236358643, -0.0040435791, 0.00441741943, -0.00141906738,
        -0.0538024902, -0.0397644043, -0.0194091797, -0.0548706055, 0.0113296509, -0.00039935112,
        -0.0162353516, -0.00849914551, -0.0197906494, 0.000333309174, 0.00904083252, -0.0152130127,
        0.00344467163, 0.0388793945, 0.00160884857, 0.0139846802, -0.0325622559, 0.00150585175,
        -0.0133743286, 0.0198364258, -0.0361022949, 0.0276184082, -0.00991821289, 0.0797729492,
        0.0221862793, -0.0061378479, 0.0189056396, -0.0517272949, -0.0855102539, -0.022644043,
        0.00352096558, -0.0400390625, -0.0926513672, 0.0581359863, 0.0222015381, -0.046875,
        -0.0387268066, -0.00109195709, -0.00238227844, -0.0462341309, 0.0289306641, 0.0227355957,
        0.0428161621, 0.0217895508, -0.0272521973, -0.059387207, -0.014831543, -0.0211334229,
        0.00408935547, -0.0118484497, 0.0269927979, -0.0363769531, -0.0240936279, -0.0266113281,
        -0.000854492188, 0.0331115723, 0.00235557556, 0.0144424438, -0.0514221191, 0.0103530884,
        -0.0378723145, 0.0280609131, -0.0326843262, -0.0126419067, 0.0440368652, -0.0539855957,
        0.00398254395, -0.0337219238, 0.0216522217, 0.0259399414, 0.0115509033, 0.0328369141,
        -0.0219268799, -0.0745849609, -0.000368356705, 0.00219154358, -0.0338439941, 0.0330810547,
        -0.0204772949, 0.0354614258, 0.0283508301, 0.0317077637, 0.000132322311, 0.0254364014,
        -0.00887298584, -0.0472412109, 0.0329589844, 0.0133590698, -0.0403137207, 0.0180511475,
        -0.00975799561, 0.0217895508, 0.0173492432, 0.0502319336, -0.0389404297, -0.0184173584,
        -0.0300750732, -0.000461578369, -0.000509262085, -0.0492248535, -0.0238342285, 0.020324707,
        0.0307159424, 0.00225257874, 0.0199127197, -0.0104446411, -0.000809192657, -0.0462036133,
        -0.0152130127, 0.0177001953, -0.04296875, -0.0495605469, -0.0222167969, 0.0166931152,
        -0.00264549255, 0.00518798828, -0.00897216797, 0.194091797, 0.0143737793, 0.0690918,
        -0.05078125, 0.00633621216, 0.0251922607, 0.00643539429, 0.0469970703, 0.0104980469,
        0.0373840332, -0.0150604248, -0.00131893158, 0.0362243652, -0.03125, 0.0291442871,
        0.0380249023, -0.00727844238, 0.0494995117, 0.0211792, -0.0202636719, 0.0422363281,
        -0.0357055664, -0.00127506256, 0.0102996826, -0.0222473145, -0.0635376, 0.0272521973,
        0.0117492676, -0.0600280762, 0.0274047852, -0.00288200378, 0.0372924805, -0.0369262695,
        0.00720596313, 0.0365905762, 0.033782959, 0.0375671387, -0.0220184326, 0.00889587402,
        0.0105285645, -0.00612258911, -0.0258026123, -0.00595474243, 0.0503234863, 0.00438308716,
        0.0197296143, 0.0444335938, -0.00455856323, -0.00856781, 0.00242042542, 0.0216064453,
        0.00269126892, -0.0054473877, -0.0252532959, -0.0179748535, -0.033996582, -0.0413513184,
        -0.0435180664, 0.0115432739, 0.0125045776, 0.0588989258, -0.0214538574, -0.032989502,
        -0.0222320557, 0.0328369141, 0.0246124268, -0.0110015869, -0.0712280273, -0.0455627441,
        -0.0608215332, -0.0350646973, -0.014503479, -0.0340271, 0.0308532715, 0.0202789307,
        -0.014251709, -0.00289535522, 0.0322570801, 0.0628662109, -0.0203704834, 0.012046814,
        -0.040222168, -0.0563659668, 0.0370178223, 0.0334777832, -0.00308990479, 0.0197296143,
        0.0324401855, -0.0259552, 0.0200958252, 0.0440368652, 0.0169830322, -0.00170326233,
        0.0402832031, -0.00968933105, -0.0220336914, -0.0178833, 0.00311088562, -0.0212554932,
        -0.0227966309, -0.0249176025, 0.0196228027, -0.0261535645, -0.0109176636, 0.0267486572,
        -0.0298156738, 0.0277709961, 0.0211334229, 0.0227355957, -0.014831543, 0.0232391357,
        0.00784301758, 0.0314331055, -0.00467681885, 0.0261383057, 0.0350646973, 0.0458068848,
        -0.0433044434, 0.0227661133, -0.00888824463, 0.00116920471, 0.05859375, -0.0582885742,
        0.0650634766, -0.0266265869, 0.0554199219, -0.0394287109, 0.00245285034, 0.046295166,
        0.030456543, 0.0177307129, -0.0208587646, -0.0449829102, 0.046081543, 0.0435791,
        -0.0321350098, 0.0773925781, -0.0279083252, -0.0311279297, 0.0739135742, 0.0243530273,
        0.0369873047, -0.0279083252, 0.00356674194, -0.00482940674, -0.00312995911, -0.00774765,
        -0.0251312256, -0.0289001465, -0.0132369995, 0.0300140381, -0.00784301758, 0.0550537109,
        0.0156402588, -0.0320129395, -0.0305023193, 0.0331115723, 0.0406799316, 0.0346984863,
        -0.0300292969, -0.0427856445, -0.00849914551, -0.00274276733, 0.101501465, 0.0447998047,
        0.0354614258, -0.0319824219, 0.030380249, 0.0147705078, 0.0589904785, -0.0254516602,
        -0.0310058594, 0.00455093384, 0.0150146484, 0.0310668945, -0.01612854, -0.000389575958,
        0.0363769531, -0.0602417, 0.000589847565, -0.018661499, 0.0262146, 0.0587158203,
        0.0130462646, -0.0184326172, 0.00717926025, 0.00870513916, -0.0326843262, -0.0417175293,
        0.0276184082, -0.0227813721, 0.055480957, -0.00919342, -0.010093689, -0.0158844, 0.00687027,
        -0.0200195312, 0.0335693359, -0.0372924805, -0.032989502, -0.0291137695, -0.00805664062,
        -0.0281066895, -0.0122833252, -0.0183105469, -0.0311279297, 0.00348854065, 0.0196685791,
        0.0380859375, 0.0322265625, 0.00668716431, -0.0117034912, 0.00909423828, 0.0290222168,
        0.0317382812, 0.0137634277, 0.0367736816, 0.0336914062, 0.0239562988, -0.0502319336,
        0.0104064941, 0.0248413086, 0.0354919434, -0.034576416, -0.0595397949, -0.0204925537,
        0.0397033691, 0.0150375366, -0.0227813721, -0.0309906, 0.0163726807, -0.0106964111,
        -0.000542163849, -0.0500183105, -0.0425415039, -0.0321350098, 0.0384521484, -0.00182151794,
        0.0178070068, -0.0446472168, 0.0473632812, -0.00469970703, 0.00761795044, -0.0146179199,
        -0.00894165, 0.0944213867, -0.0224761963, -0.0177764893, 0.0100250244, 0.0165557861,
        0.0119171143, -0.033203125, 0.0222167969, 0.0594482422, 0.00147724152, -0.00545120239,
        0.0028629303, -0.0235137939, -0.047668457, -0.0563659668, -0.00270271301, -0.00485610962,
        -0.0237579346, 0.00634384155, -0.0468139648, 0.00650787354, 0.0216522217, -0.0416259766,
        -0.00035738945, -0.0309448242, 0.0309448242, -0.011756897, -0.0532226562, -0.0480651855,
        0.0398254395, 0.0365600586, -0.0123062134, -0.0367736816, -0.0229034424, -0.0294189453,
        -0.028717041, 0.0267028809, -0.0253601074, 0.00170707703, 0.0199584961, 0.0230712891,
        -0.0412597656, -0.0174102783, 0.0254516602, -0.00862884521, -0.0310058594, 0.0354614258,
        -0.029510498, 0.0149078369, -0.00806427, -0.0552368164, -0.0334777832, -0.01537323,
        0.0026474, -0.0483398438, 0.0244903564, -0.0187988281, -0.0512084961, -0.0440063477,
        -0.00451660156, 0.00773620605, -0.0277099609, -0.0355834961, 0.0592651367, 0.0222320557,
        -0.0060005188, -0.0222167969, -0.00156402588, 0.016998291, 0.014793396, -0.0254211426,
        0.0347290039, -0.0244140625, -0.0303344727, 0.0179748535, -0.00827789307, -0.0250549316,
        -0.0187072754, -0.00174045563, 0.0279541016, 0.0416564941, 0.0353088379, 0.0331115723,
        0.0155639648, 0.00904083252, 0.00457000732, 0.00491714478, -0.0279541016, -0.0111923218,
        -0.0208282471, -0.0506896973, -0.00748825073, -0.0287017822, -0.00249290466, -0.0556030273,
        -0.0529174805, 0.0657348633, 0.0596923828, -0.0173950195, 0.0189971924, -0.0129852295,
        -0.00786590576, 0.0471496582, -0.025390625, -0.0222320557, -0.0449829102, 0.0567016602,
        -0.00745010376, -0.00490951538, -0.00695800781, 0.041595459, -0.0199737549, 0.0132598877,
        0.0234375, -0.0424499512, -0.0571594238, 0.0133285522, 0.0222167969, 0.020614624,
        -0.041595459, 0.0122756958, -0.0111160278, 0.0310668945, -0.0363769531, 0.00811004639,
        0.0022983551, 0.0333557129, -0.0562744141, -0.0020275116, -0.0240325928, 0.00459671,
        -0.0244445801, 0.00220680237, 0.0382080078, 0.0213317871, 0.0121078491, -0.0430908203,
        -0.0331420898, -0.0305023193, 0.0273132324, -0.0791626, 0.00317955017, 0.0211334229,
        -0.0135192871, 0.0288238525, -0.0401916504, 0.039855957, -0.0265045166, 0.00510406494,
        -0.0737915039, 0.0000576376915, -0.0221710205, -0.0216217041, -0.0355224609, -0.0278015137,
        -0.0545959473, -0.00251197815, 0.0187072754, -0.0414428711, 0.022354126, -0.00679779053,
        0.00481414795, 0.00424575806, 0.00084400177, 0.00261306763, 0.0419616699, -0.0234069824,
        0.00130653381, 0.0276641846, 0.0244140625, -0.032623291, -0.0317688, 0.0155334473,
        0.0374755859, 0.0448303223, 0.0200805664, 0.0047416687, -0.0350646973, -0.0426635742,
        -0.00890350342, 0.0171356201, -0.0294189453, 0.050567627, -0.0163269043, 0.0334472656,
        0.000573158264, 0.0281066895, -0.0112533569, 0.0122528076, -0.0128097534, 0.0342712402,
        -0.0218353271, 0.0213012695, -0.00161457062, 0.0325317383, 0.0212554932, -0.0154495239,
        0.0112380981, -0.0357055664, -0.0346374512, -0.0322265625, 0.0407104492, 0.0835571289,
        0.0107955933, 0.0154266357, 0.0293731689, -0.013458252, 0.0224914551, 0.0279693604,
        -0.00640106201, -0.00551223755, -0.0341186523, -0.00273132324, -0.0425109863, -0.0319213867,
        -0.063659668, -0.0298461914, -0.0262298584, 0.01612854, -0.0275115967, -0.0105133057,
        0.0100708008, 0.0121307373, 0.0409545898, -0.034362793, -0.0571594238, 0.00958251953,
        0.0250549316, 0.0133895874, -0.0181884766, -0.0113220215, 0.00112628937, 0.00545501709,
        -0.00968170166, -0.0117263794, 0.00888824463, -0.0374755859, -0.0485839844, 0.0538635254,
        -0.0254516602, 0.00302124023, -0.0223083496, -0.019241333, -0.000938415527, -0.0297241211,
        -0.0559082031, -0.0451660156, 0.00370407104, 0.0213623047, 0.000952243805, -0.0313110352,
        -0.00325775146, 0.0176391602, 0.00491333, -0.0028553009, 0.0299835205, 0.00645828247,
        0.0308685303, -0.0314941406, -0.0422668457, -0.0391845703, -0.0286712646, -0.00484466553,
        -0.054901123, 0.0142288208, 0.00807952881, -0.00114059448, -0.0184631348, -0.0104675293,
        -0.0296630859, -0.0560913086, 0.0228424072, -0.0315246582, 0.00153923035, -0.0385131836,
        0.0321350098, -0.0261993408, -0.0068359375, -0.0240631104, 0.0420227051, -0.0622253418,
        0.0517272949, 0.00424194336, -0.0302429199, -0.0149765015, -0.020614624, 0.0419921875,
        -0.00540161133, 0.0304718018, -0.00857543945, -0.022354126, -0.0406799316, -0.0414733887,
        -0.0150375366, 0.0345153809, -0.047668457, -0.0682373047, -0.0184021, 0.0102005005,
        -0.0192260742, -0.0393676758, -0.0436401367, 0.0224761963, 0.0138244629, 0.017791748,
        0.00912475586, 0.0170593262, 0.0402832031, 0.0294494629, -0.00122642517, -0.00827026367,
        -0.0407409668, 0.0292358398, -0.0123977661, -0.0342102051, 0.0104217529, 0.0214538574,
        -0.0379333496, 0.00015771389, 0.0149383545, 0.0235290527, -0.0343017578, 0.0338439941,
        0.0169372559, 0.0154495239, 0.0569763184, 0.00826263428, 0.020690918, 0.0167236328,
        -0.00579834, 0.0262146, -0.00610351562, 0.0308685303, 0.0268859863, 0.0663452148,
        0.00932312, 0.0129318237, -0.0188598633, 0.0285797119, -0.0107727051, 0.0527648926,
        -0.0788574219, 0.0663452148, 0.0384521484, -0.0145339966, 0.0123519897, 0.0256500244,
        -0.0322570801, -0.0224761963, -0.0112838745, 0.00635147095, 0.0379333496, 0.0204925537,
        -0.0205993652, -0.0254364014, -0.0337219238, -0.0191802979, -0.0171051025, 0.0594482422,
        0.0369567871, -0.0289764404, -0.00706863403, 0.00996398926, 0.0166778564, -0.0171356201,
        0.0402832031, -0.0282745361, 0.0169677734, 0.0286712646, 0.0401306152, 0.016998291,
        -0.00253105164, -0.0114898682, 0.0126037598, 0.00953674316, -0.047668457, 0.0338745117,
        -0.0119781494, -0.0159759521, -0.0108261108, 0.00793457, 0.0106277466, -0.013343811,
        -0.0101852417, -0.0175628662, 0.0137405396, 0.00390625, 0.0128936768, -0.00379180908,
        0.0152282715, -0.0118179321, -0.0365600586, 0.020690918,
      ],
      metadata: {
        english_text: "Today is June 18th and it is Muiriel's birthday!",
        spanish_text: '¡Hoy es 18 de junio y es el cumpleaños de Muiriel!',
      },
    },
    {
      id: '3',
      values: [
        0.0238800049, -0.0124511719, -0.0141448975, -0.0598449707, 0.0268096924, -0.0209350586,
        -0.0524597168, 0.0727539062, 0.064453125, -0.0433349609, 0.0261688232, 0.0311737061,
        -0.0352172852, 0.00144863129, -0.0310821533, -0.037109375, 0.0118026733, 0.0379638672,
        -0.0111541748, -0.0186767578, 0.0282440186, -0.0190887451, -0.033996582, -0.0223693848,
        -0.0144119263, -0.0447387695, -0.0293579102, -0.0515136719, -0.00740432739, -0.0399169922,
        -0.0252532959, -0.0119552612, -0.0470581055, -0.0557250977, -0.0220336914, 0.0110931396,
        0.0364074707, 0.0428771973, -0.0148773193, 0.0193481445, 0.00510406494, 0.023147583,
        -0.0173339844, -0.0206451416, -0.0357971191, -0.0039100647, 0.032623291, 0.0257873535,
        -0.0207366943, 0.0210723877, 0.0307617188, 0.0294189453, -0.00640106201, -0.0188293457,
        -0.0778808594, 0.0251312256, -0.0094909668, 0.00356483459, -0.00791168213, 0.000530719757,
        -0.0362548828, 0.0040397644, 0.0163421631, -0.00784301758, -0.0336303711, 0.0357055664,
        0.0520324707, 0.00205802917, -0.0190124512, -0.0135574341, -0.0469360352, 0.0391845703,
        -0.0199737549, -0.0278320312, -0.0247650146, 0.000364780426, 0.0160217285, -0.014213562,
        -0.01512146, -0.00800323486, 0.0809326172, -0.0331115723, 0.0226135254, -0.0263214111,
        0.0286865234, 0.0389099121, 0.0145874023, 0.0149307251, 0.0294799805, 0.0467834473,
        -0.00534439087, 0.00511169434, 0.00647735596, -0.0145187378, -0.0472106934, 0.029006958,
        -0.00381279, 0.0220031738, -0.0105133057, 0.00847625732, -0.00137805939, 0.00344848633,
        0.00464630127, -0.0147018433, -0.0168609619, 0.0465393066, 0.019241333, 0.0241394043,
        0.0321350098, 0.0283355713, 0.0680542, 0.013710022, -0.00411224365, -0.0336608887,
        -0.0427856445, -0.00714492798, 0.0103912354, -0.00234794617, -0.0261535645, 0.025604248,
        0.0397644043, 0.0183410645, 0.0055770874, -0.0450744629, 0.0117874146, -0.0132598877,
        0.0101623535, 0.00724029541, -0.0217285156, -0.00191783905, 0.0219268799, 0.0202026367,
        0.0000916719437, -0.0303649902, -0.0101394653, -0.0125579834, -0.00166988373, -0.0126953125,
        -0.0392150879, -0.000606536865, -0.00128078461, 0.0397033691, 0.056854248, -0.0389099121,
        -0.0305023193, -0.011932373, -0.0284576416, 0.0135116577, -0.0282592773, -0.00720596313,
        0.0175628662, -0.00330543518, -0.0440673828, -0.0181121826, 0.0321350098, -0.0210571289,
        0.00664520264, 0.0294799805, 0.0182952881, -0.00505065918, -0.032043457, -0.0404052734,
        -0.0473022461, -0.0387268066, -0.00276756287, 0.00666427612, 0.0243835449, 0.0273284912,
        -0.0226745605, -0.0140991211, -0.013961792, -0.0564880371, 0.0136032104, -0.0424804688,
        -0.0267181396, 0.0414733887, 0.024520874, 0.0461120605, 0.00227355957, -0.0223693848,
        -0.0158844, 0.0323486328, 0.0631103516, -0.00445175171, -0.0158844, 0.0487670898,
        0.0193939209, 0.00780105591, 0.0420532227, 0.0534973145, 0.0561218262, -0.0165252686,
        -0.0101623535, 0.0192871094, -0.00704574585, 0.0278930664, 0.0306549072, 0.0211639404,
        -0.00070810318, -0.0367736816, -0.0241546631, 0.0332336426, -0.0364990234, 0.00510787964,
        -0.00010073185, -0.0423278809, -0.0213165283, -0.0173797607, 0.043182373, -0.0242919922,
        -0.0108795166, -0.00392150879, 0.0024433136, 0.0308074951, -0.0384521484, -0.0681152344,
        -0.0389099121, -0.00519180298, 0.037109375, -0.0117340088, 0.00620651245, 0.0557250977,
        -0.0115966797, 0.0108718872, 0.00699996948, 0.0370483398, 0.0552063, 0.0430297852,
        0.0173797607, 0.0237579346, 0.0189208984, 0.0302276611, -0.113342285, -0.00961303711,
        -0.00701904297, -0.0247497559, -0.0215759277, 0.00173282623, 0.0260925293, 0.0401916504,
        -0.0510864258, 0.0251922607, -0.0101318359, -0.0141830444, 0.0115661621, 0.00202178955,
        0.0361938477, -0.0424499512, 0.0220031738, -0.0161132812, 0.030380249, 0.0282592773,
        -0.0123214722, -0.0145187378, 0.0213928223, 0.00772476196, 0.00128936768, -0.00105476379,
        -0.0201416016, -0.0137786865, 0.0147399902, -0.00139904022, -0.0317993164, 0.0325927734,
        0.0100402832, 0.0297698975, 0.0128936768, 0.00000202655792, -0.0204925537, -0.0410461426,
        -0.0670776367, 0.000104308128, -0.0378417969, 0.00224113464, -0.00408172607, -0.00448226929,
        -0.0181274414, -0.0213775635, -0.0126419067, -0.0182495117, -0.0160827637, -0.000577449799,
        0.0301055908, -0.017578125, 0.00859069824, -0.0408325195, 0.000294208527, -0.0082244873,
        0.000209212303, -0.0308990479, 0.0341796875, -0.0133514404, 0.0672607422, 0.0188598633,
        -0.0132598877, 0.0222930908, -0.0230560303, -0.076965332, -0.0150756836, 0.018661499,
        -0.024017334, -0.0809936523, 0.0513916, 0.0214996338, -0.0504150391, -0.0247039795,
        -0.00612258911, -0.0029964447, -0.0513916, 0.0134277344, 0.0201721191, 0.0449523926,
        0.00746154785, -0.0170593262, -0.0687255859, -0.00438308716, -0.0219421387, 0.0302124023,
        -0.0216674805, 0.0274200439, -0.0294342041, -0.0333251953, -0.041809082, -0.0183105469,
        0.0331115723, 0.0120697021, 0.0290527344, -0.061340332, -0.00589370728, -0.0415039062,
        0.0269470215, -0.037109375, -0.00876617432, 0.0364685059, -0.0369873047, 0.00682830811,
        -0.0310974121, 0.058013916, 0.0287323, -0.00779342651, 0.05859375, -0.00640487671,
        -0.0600280762, -0.00317192078, 0.0305023193, -0.0454101562, 0.0141448975, -0.0245666504,
        0.0256958, -0.00436401367, 0.0121612549, -0.027130127, 0.0296936035, 0.0120162964,
        -0.027923584, 0.0625610352, -0.0218048096, -0.0378112793, -0.000307321548, -0.0259094238,
        0.0364990234, 0.0214233398, 0.0532531738, -0.0302734375, -0.0246429443, -0.0200195312,
        0.00806427, -0.0044631958, -0.0467834473, -0.0289764404, 0.0339050293, 0.0290374756,
        -0.0372009277, 0.0295410156, 0.0083770752, -0.00868988, -0.0709228516, -0.0176696777,
        0.0187988281, -0.0434875488, -0.0262298584, -0.0184021, -0.000535488129, -0.029006958,
        -0.00343132019, -0.00292396545, 0.209716797, 0.0160827637, 0.0662231445, -0.0433959961,
        -0.00554657, 0.0191345215, 0.00525665283, 0.0471496582, 0.0144195557, 0.0368652344,
        0.00751113892, 0.0112304688, 0.0338439941, -0.0397033691, 0.0238647461, 0.0349731445,
        0.0160064697, 0.0369873047, 0.0339050293, -0.02293396, 0.0712280273, -0.0351257324,
        0.00508880615, 0.024597168, -0.0407714844, -0.0399169922, 0.00910186768, 0.00691604614,
        -0.0612792969, 0.0059967041, -0.0068321228, 0.0390014648, -0.038482666, 0.010597229,
        0.0397033691, 0.0442199707, 0.0276641846, -0.0323486328, 0.00630188, 0.00475311279,
        -0.0252227783, -0.034942627, -0.0237121582, 0.0491333, -0.0127487183, 0.0341796875,
        0.0332946777, -0.00484085083, -0.037902832, 0.0178833, 0.0362548828, 0.0211486816,
        -0.025177002, -0.0287323, -0.0131225586, -0.04296875, -0.0323181152, -0.02684021,
        -0.00503540039, 0.00785827637, 0.0560302734, -0.00312423706, -0.0432434082, -0.0160217285,
        0.0473938, 0.0139465332, -0.0118637085, -0.0753173828, -0.030456543, -0.0475769043,
        -0.0175933838, -0.0202331543, -0.0454711914, 0.00915527344, 0.0361022949, -0.0170135498,
        -0.0240783691, 0.0393981934, 0.0719604492, -0.018081665, 0.00610733032, -0.0394287109,
        -0.0630493164, 0.027130127, 0.0439147949, -0.00229263306, 0.041015625, 0.0354003906,
        -0.0168304443, 0.0250244141, 0.0162658691, 0.0325317383, -0.00471878052, 0.042388916,
        0.000293970108, -0.0417480469, 0.00180435181, 0.0270080566, -0.0205688477, -0.0212554932,
        -0.0278167725, 0.0174102783, -0.00728988647, -0.0452270508, 0.00220108032, -0.0269622803,
        0.0282897949, 0.0112762451, 0.0261077881, -0.0160064697, 0.055267334, 0.0130844116,
        0.0153121948, -0.00844574, 0.0463867188, 0.0259857178, 0.0326843262, -0.0465698242,
        0.0204162598, -0.0085144043, -0.0111923218, 0.0300598145, -0.0731201172, 0.0738525391,
        -0.023147583, 0.0445251465, -0.0400695801, 0.0129928589, 0.0290679932, 0.0184173584,
        0.0151138306, -0.039642334, -0.0153884888, 0.0377502441, 0.0201568604, -0.00195884705,
        0.0604858398, -0.0298309326, -0.0176239014, 0.0715332, -0.00115966797, -0.00291633606,
        -0.0104675293, 0.0322875977, 0.0278015137, 0.0238037109, -0.0144500732, -0.0125656128,
        -0.0194396973, -0.00695037842, 0.0279998779, -0.0306549072, 0.0486755371, 0.00202178955,
        -0.0364685059, -0.0570373535, 0.0388183594, 0.0574951172, 0.0452880859, -0.0629882812,
        -0.0340576172, -0.0046081543, -0.0270233154, 0.0783691406, 0.0400695801, 0.0165252686,
        -0.038269043, 0.0382080078, 0.0149917603, 0.0209350586, -0.0187988281, -0.0210876465,
        0.00819397, 0.00374031067, 0.0145950317, -0.00213432312, -0.00315284729, 0.0567932129,
        -0.0275726318, 0.0138320923, -0.0262146, 0.0257568359, 0.0649414062, -0.00860595703,
        -0.00178337097, 0.00426101685, 0.00422287, -0.0461120605, -0.011756897, 0.0190124512,
        -0.0220794678, 0.0304260254, -0.0251312256, -0.00872039795, -0.0223693848, 0.00241851807,
        -0.0147705078, 0.0311126709, -0.00378417969, -0.0217437744, -0.0375061035, 0.0281677246,
        -0.0457458496, -0.0237121582, -0.0152893066, -0.0310668945, 0.00823974609, 0.0236206055,
        0.0331420898, 0.0314941406, 0.0106277466, -0.0365905762, 0.0157318115, 0.0343322754,
        0.0111465454, 0.0381774902, 0.0144882202, 0.0247344971, 0.0233917236, -0.0403442383,
        0.0170440674, 0.0171356201, 0.038482666, -0.0445556641, -0.049407959, -0.00113201141,
        0.0450439453, 0.00170898438, -0.0296783447, -0.0422668457, 0.01512146, -0.00465393066,
        -0.0132751465, -0.0600280762, -0.034942627, -0.0208129883, 0.0278015137, 0.0171356201,
        0.0267791748, -0.0251922607, 0.0312042236, -0.00913238525, -0.00218391418, -0.013053894,
        -0.0233459473, 0.0917358398, -0.0507507324, -0.00819397, 0.00431442261, 0.0176239014,
        0.0220794678, -0.0547790527, 0.0585327148, 0.045501709, 0.0187988281, -0.0118484497,
        -0.0177001953, -0.0534057617, -0.0387878418, -0.0528564453, 0.00873565674, 0.00700378418,
        -0.0230102539, 0.00810241699, -0.0447998047, -0.000782489777, 0.0579528809, -0.030166626,
        0.00687027, -0.0531616211, 0.0392150879, -0.00720214844, -0.0513916, -0.0499267578,
        0.0597229, 0.034942627, -0.0184631348, -0.0370178223, -0.0339660645, -0.0204925537,
        -0.0178222656, 0.0165252686, 0.0106124878, 0.00921630859, 0.0126953125, 0.00258636475,
        -0.0230865479, -0.0112380981, 0.0144882202, -0.00458145142, -0.0166168213, 0.0422973633,
        -0.0202484131, 0.0126800537, -0.0269622803, -0.0355529785, -0.0247802734, -0.0189819336,
        0.0030708313, -0.0274047852, 0.0198059082, -0.0228881836, -0.0590820312, -0.0271911621,
        0.00193881989, -0.00303840637, -0.0272369385, -0.0174713135, 0.0377807617, 0.0261383057,
        -0.0219268799, -0.0346374512, 0.00349235535, 0.0270385742, 0.0187225342, -0.033203125,
        0.0245056152, -0.0322570801, -0.021194458, 0.0218505859, -0.0235290527, -0.0354003906,
        -0.0421447754, -0.00904083252, 0.00143146515, 0.0386962891, 0.0349731445, 0.0207824707,
        0.0102081299, 0.0259857178, 0.00000828504562, -0.00570297241, -0.025894165, 0.00468826294,
        -0.0336303711, -0.0343933105, -0.0128555298, -0.0164489746, -0.0066947937, -0.0484619141,
        -0.027130127, 0.0397949219, 0.0366821289, -0.0199737549, 0.0172729492, -0.0202941895,
        -0.0106658936, 0.0562438965, -0.025390625, -0.0316162109, -0.0388793945, 0.0539245605,
        -0.0146713257, -0.00603866577, -0.022277832, 0.0359802246, -0.0363769531, 0.0412902832,
        0.0240783691, -0.0405578613, -0.0614318848, -0.00291824341, 0.0356750488, 0.0185241699,
        -0.0253143311, -0.000984191895, -0.0132980347, 0.0266265869, -0.0181274414, 0.0231323242,
        -0.0148010254, 0.0137939453, -0.0788574219, 0.00608444214, -0.0186767578, 0.0266265869,
        0.00946807861, 0.0196838379, 0.0416564941, 0.0223083496, 0.0321350098, -0.0338134766,
        -0.0308837891, -0.0180969238, 0.0390625, -0.0719604492, 0.0202789307, 0.0240325928,
        -0.00537109375, 0.0321655273, -0.0343322754, 0.029296875, -0.0332641602, -0.00220489502,
        -0.0698852539, 0.0279541016, -0.0197143555, -0.0335083, -0.0368042, -0.0344543457,
        -0.0319824219, 0.000313043594, 0.0413818359, -0.0153274536, 0.0336608887, 0.00021314621,
        0.0167694092, 0.0109024048, 0.0114898682, 0.0205078125, 0.0394287109, -0.00908660889,
        0.0017156601, 0.0110397339, 0.0182647705, -0.00830078125, -0.0275421143, 0.0118865967,
        0.0404968262, 0.0242004395, 0.00537109375, 0.0121994019, -0.0266723633, -0.0317993164,
        -0.0111694336, -0.00252914429, -0.0111923218, 0.0394592285, -0.0190582275, 0.0373840332,
        0.00821685791, 0.0148925781, -0.0341491699, 0.0296630859, -0.0255279541, 0.051940918,
        -0.0429992676, 0.0206604, 0.00720214844, 0.0447692871, 0.0255737305, -0.0220031738,
        0.0160675049, -0.0361633301, -0.0286865234, -0.0189819336, 0.038848877, 0.0678100586,
        0.0172729492, 0.00409317, 0.0261077881, -0.0389709473, 0.0261535645, 0.0145721436,
        -0.00536727905, -0.0079498291, -0.0285186768, -0.0344238281, -0.0307617188, -0.0287323,
        -0.0675048828, -0.0155944824, -0.0346069336, 0.0157012939, 0.00724029541, -0.0156021118,
        0.0074005127, 0.013130188, 0.0389404297, -0.0271911621, -0.0639038086, 0.0107574463,
        0.0247650146, 0.0223083496, -0.0128555298, 0.00448608398, 0.00801086426, -0.0067024231,
        0.00914764404, 0.0289917, -0.0146331787, -0.0687866211, -0.0505981445, 0.0278930664,
        -0.0219573975, 0.00788879395, -0.0129089355, -0.031829834, -0.00135803223, -0.0112838745,
        -0.0496521, -0.0482177734, 0.0317382812, 0.0240325928, -0.00148010254, -0.0178375244,
        0.0198059082, 0.00291633606, 0.0164794922, 0.0260467529, 0.0455932617, -0.0247802734,
        0.0499267578, -0.0420532227, -0.0522155762, -0.0296325684, -0.0333557129, 0.00303268433,
        -0.0359802246, 0.0159606934, 0.0150299072, 0.0139694214, -0.0191345215, 0.00903320312,
        -0.0283355713, -0.0532531738, 0.067565918, -0.0194854736, 0.0192871094, -0.0317993164,
        0.0129318237, -0.0261688232, 0.0118637085, -0.0305786133, 0.0407104492, -0.0772094727,
        0.029510498, -0.00505828857, -0.0190124512, -0.0246429443, -0.00574111938, 0.0286865234,
        0.000377655029, 0.0158691406, -0.00826263428, -0.0399169922, -0.0416259766, -0.0267944336,
        -0.0190887451, 0.0357666, -0.0572509766, -0.0412597656, -0.0315246582, 0.0027217865,
        0.00344467163, -0.0310668945, -0.0361328125, 0.00830841064, 0.0143661499, 0.0372009277,
        -0.00929260254, 0.00984191895, 0.0587463379, 0.0492553711, -0.00922393799, -0.0373840332,
        -0.0458068848, 0.0348205566, 0.0126953125, -0.0387878418, -0.00787353516, 0.0255279541,
        -0.0357971191, -0.00181007385, 0.0219573975, 0.0193634033, -0.0080871582, 0.0613098145,
        0.00819397, -0.0117721558, 0.0595397949, 0.0168762207, 0.00541687, 0.0290527344,
        -0.0121841431, 0.0178375244, -0.0120849609, 0.0394287109, -0.00543212891, 0.0805053711,
        -0.00741577148, 0.0204162598, -0.0242919922, 0.0147399902, -0.0350341797, 0.0394287109,
        -0.0556335449, 0.0551452637, 0.0288238525, -0.0219421387, -0.00576782227, 0.0225219727,
        -0.0363769531, -0.0180053711, 0.00736999512, -0.0133514404, 0.030380249, 0.0201416016,
        -0.0222625732, -0.0529785156, -0.0549621582, 0.000820159912, -0.00520324707, 0.0446167,
        0.0328674316, -0.00858306885, 0.0121994019, 0.0186157227, 0.0319519043, 0.0215148926,
        0.0406494141, -0.0386352539, 0.00337219238, 0.0633544922, 0.0377807617, 0.0339660645,
        0.015083313, -0.00881958, -0.0118255615, 0.00792694092, -0.0330200195, 0.00229454041,
        -0.0165252686, -0.026550293, -0.0227355957, 0.0141067505, 0.0227050781, 0.0110549927,
        -0.0207672119, -0.0261383057, 0.0192718506, -0.0133972168, 0.0101394653, 0.000532150269,
        0.0190734863, -0.00414657593, -0.0288696289, 0.0157012939,
      ],
      metadata: {
        english_text: 'Muiriel is 20 now.',
        spanish_text: 'Ahora, Muiriel tiene 20 años.',
      },
    },
  ],
};
