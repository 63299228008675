import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import {useCallback} from 'react';
import {type CreateStorageIntegrationRequest} from '../../../../api/types/controllerApi';
import {useBooleanState} from '../../../../hooks/utils';
import {StorageIntegrationProvider} from '../../../../types';
import {assertUnreachable} from '../../../../utils/types';
import StorageForm from '../StorageForm/StorageForm';
import {type StorageFormData} from '../StorageForm/utilities/schema';

type CreateStorageButtonProps = {
  /** Callback for when the create storage form is completed. */
  onCreate: (data: CreateStorageIntegrationRequest) => void;
};

/** A button for creating a new storage integration. */
function CreateStorageButton({onCreate}: CreateStorageButtonProps) {
  const {
    val: createFormOpen,
    setFalse: hideCreateForm,
    setTrue: showCreateForm,
  } = useBooleanState(false);

  const onSubmit = useCallback(
    (data: StorageFormData) => {
      let createData: CreateStorageIntegrationRequest;
      switch (data.provider) {
        case StorageIntegrationProvider.S3:
          createData = {
            name: data.name,
            provider: data.provider,
            aws_iam_role: {role_arn: data.aws_iam_role_arn},
          };
          break;
        case StorageIntegrationProvider.GCS:
          createData = {
            name: data.name,
            provider: data.provider,
            gcp_service_account: {key_json: data.gcp_service_account_key_json},
          };
          break;
        case StorageIntegrationProvider.AzureBlob:
          createData = {
            name: data.name,
            provider: data.provider,
            azure_app_credentials: {
              client_id: data.azure_app_credentials_client_id,
              client_secret: data.azure_app_credentials_client_secret,
              tenant_id: data.azure_app_credentials_tenant_id,
            },
          };
          break;
        default:
          assertUnreachable(data);
      }
      onCreate(createData);
    },
    [onCreate],
  );

  return (
    <>
      <Button variant="contained" startIcon={<AddIcon />} onClick={showCreateForm}>
        Add integration
      </Button>
      <StorageForm
        open={createFormOpen}
        close={hideCreateForm}
        title="Add a storage integration"
        subtitle="Have your storage account ready to start."
        submitText="Add integration"
        onSubmit={onSubmit}
      />
    </>
  );
}

export default CreateStorageButton;
