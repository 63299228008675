import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {type CreateCmekKeyRequest} from '../../../../api/types/controllerApi';
import ModalForm from '../../../../components/ModalForm';
import {useBooleanState} from '../../../../hooks/utils';
import {type CmekFormSchema, cmekFormSchema} from './schema';

interface CreateCmekButtonProps {
  onCreate: (data: CreateCmekKeyRequest) => void;
}

function CreateCmekButton({onCreate}: CreateCmekButtonProps) {
  const {
    val: createFormOpen,
    setFalse: hideCreateForm,
    setTrue: showCreateForm,
  } = useBooleanState(false);

  const {
    handleSubmit,
    control,
    formState: {isValid},
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      role_arn: '',
      key_arn: '',
    },
    resolver: zodResolver(cmekFormSchema),
  });

  const onClose = () => {
    reset();
    hideCreateForm();
  };

  const onSubmit = useCallback(
    ({name, role_arn, key_arn}: CmekFormSchema) => {
      onCreate({
        name,
        region: '*',
        cloud: 'aws',
        aws: {
          role_arn,
          key_arn,
        },
      });
      reset();
      hideCreateForm();
    },
    [onCreate, reset, hideCreateForm],
  );

  return (
    <>
      <Button variant="contained" onClick={showCreateForm}>
        Add CMEK key
      </Button>

      <ModalForm
        isValid={isValid}
        open={createFormOpen}
        close={onClose}
        title="Add a CMEK key"
        subtitle="Ensure your encryption key and necessary permissions are set up before proceeding."
        submitText="Create key"
        onSubmit={handleSubmit(onSubmit)}
        form={
          <Box
            data-testid="create-cmek-form"
            sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: 1}}
          >
            <TextInput
              fullWidth
              name="name"
              label="Key name"
              control={control}
              placeholder="Create a unique name"
            />
            <TextInput
              fullWidth
              name="role_arn"
              label="Role ARN"
              control={control}
              placeholder="arn:aws:iam::123456789/Pinecone"
              tooltip="The Amazon Resource Name (ARN) of the IAM role that Pinecone will assume to access your encryption key."
            />
            <TextInput
              fullWidth
              name="key_arn"
              label="Key ARN"
              control={control}
              placeholder="arn:aws:kms:region:account-id/key-id"
              tooltip="The Amazon Resource Name (ARN) of the KMS key that will be used for encrypting your data."
            />
          </Box>
        }
      />
    </>
  );
}

export default CreateCmekButton;
