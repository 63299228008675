import {Box} from '@mui/material';
import Image from '../../Utilities/Image/Image';

const styles = {
  root: {
    display: 'grid',
    placeItems: 'center',
    py: 3,
    px: 12,
  },
};

function ProviderCard(providerName: string, lightSrc: string, darkSrc: string) {
  return (
    <Box sx={styles.root}>
      <Image srcLight={lightSrc} srcDark={darkSrc} alt={providerName} height="42px" />
    </Box>
  );
}

export default ProviderCard;
