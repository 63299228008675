import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import {type UpdateStorageIntegrationRequest} from '../../../../../../api/types/controllerApi';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {useBooleanState} from '../../../../../../hooks/utils';
import {type StorageIntegration, StorageIntegrationProvider} from '../../../../../../types';
import {assertUnreachable} from '../../../../../../utils/types';
import StorageForm from '../../../StorageForm/StorageForm';
import {type StorageFormData} from '../../../StorageForm/utilities/schema';

type StorageActionsProps = {
  /** The integration to be acted upon. */
  storageIntegration: StorageIntegration;
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: UpdateStorageIntegrationRequest) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** Actions that may be taken for a given integration. */
function StorageActions({storageIntegration, onEdit, onDelete}: StorageActionsProps) {
  const {val: editFormOpen, setTrue: showEditForm, setFalse: hideEditForm} = useBooleanState(false);
  const {
    val: deleteModalOpen,
    setTrue: showDeleteModal,
    setFalse: hideDeleteModal,
  } = useBooleanState(false);

  const onSubmit = useCallback(
    (data: StorageFormData) => {
      let updateData: UpdateStorageIntegrationRequest;
      switch (data.provider) {
        case StorageIntegrationProvider.S3:
          updateData = {
            name: data.name,
            aws_iam_role: {role_arn: data.aws_iam_role_arn},
          };
          break;
        case StorageIntegrationProvider.GCS:
          updateData = {
            name: data.name,
            gcp_service_account: {key_json: data.gcp_service_account_key_json},
          };
          break;
        case StorageIntegrationProvider.AzureBlob:
          updateData = {
            name: data.name,
            azure_app_credentials: {
              client_id: data.azure_app_credentials_client_id,
              client_secret: data.azure_app_credentials_client_secret,
              tenant_id: data.azure_app_credentials_tenant_id,
            },
          };
          break;
        default:
          assertUnreachable(data);
      }
      onEdit(storageIntegration.id, updateData);
    },
    [storageIntegration.id, onEdit],
  );

  const doDelete = useCallback(() => {
    onDelete(storageIntegration.id);
    hideDeleteModal();
  }, [hideDeleteModal, storageIntegration.id, onDelete]);

  return (
    <>
      <SelectorMenuButton
        id={`storage-integration-actions-${storageIntegration.id}`}
        items={[
          {
            id: 'configure',
            name: 'Configure',
            icon: <SettingsOutlinedIcon fontSize="small" />,
            onClick: showEditForm,
          },
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
            onClick: showDeleteModal,
            color: 'error',
          },
        ]}
      />
      <StorageForm
        open={editFormOpen}
        close={hideEditForm}
        onSubmit={onSubmit}
        submitText="Update settings"
        title="Manage storage integration"
        initialValues={storageIntegration}
      />
      <DeleteConfirmationModal
        open={deleteModalOpen}
        close={hideDeleteModal}
        doDelete={doDelete}
        itemType="Storage Integration"
        name={storageIntegration.name}
      />
    </>
  );
}

export default StorageActions;
