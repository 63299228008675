import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import AssistantFileActionMenu from '../../../../../../components/AssistantFileActionMenu/AssistantFileActionMenu';
import AssistantFileStatusLabel from '../../../../../../components/AssistantFileStatusLabel/AssistantFileStatusLabel';
import {AssistantFileStatus} from '../../../../../../constants';
import {type AssistantFile} from '../../../../../../types';
import {formatByteSize} from '../../../../../../utils/format';

interface FileCellProps {
  file: AssistantFile;
  col: number;
  getPath: (id: string) => string;
}

const styles = {
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  unavailable: {
    color: 'text.disabled',
  },
};

export default function FileCell({file, col, getPath}: FileCellProps) {
  const unavailable = file.status !== AssistantFileStatus.AVAILABLE;

  switch (col) {
    case 0:
      if (unavailable) {
        return <Typography sx={styles.unavailable}>{file.name}</Typography>;
      }

      return (
        <Typography>
          <Link component={RouterLink} sx={styles.link} to={getPath(file.id)}>
            {file.name}
          </Link>
        </Typography>
      );
    case 1:
      return (
        <Typography sx={[unavailable && styles.unavailable]}>
          {new Date(file.updated_on).toLocaleDateString()}
        </Typography>
      );
    case 2:
      return (
        <Typography sx={[unavailable && styles.unavailable]}>
          {file.size ? formatByteSize(file.size, 2) : '--'}
        </Typography>
      );
    case 3:
      return (
        <Typography>
          <AssistantFileStatusLabel fileId={file.id} />
        </Typography>
      );
    case 4:
      return (
        <Box sx={{m: -1}}>
          <AssistantFileActionMenu name={file.name} id={file.id} status={file.status} />
        </Box>
      );
    default:
      return null;
  }
}
