import {createReducer, isAnyOf} from '@reduxjs/toolkit';

import * as actions from '../actions/rbacActions';
import {type RbacRoles} from '../types';

interface RbacState {
  roleBindings: {
    projectId: string;
    principalId: string;
    role: RbacRoles;
  }[];
}

const initialState = {
  roleBindings: [],
} as RbacState;

const rbacReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.createRolesForPrincipalSuccess, (state, action) => {
      state.roleBindings = [
        ...state.roleBindings,
        ...action.payload.roles.map((role) => ({
          projectId: action.payload.globalProjectId,
          principalId: action.payload.principalId,
          role,
        })),
      ];
    })
    .addMatcher(
      isAnyOf(actions.getRolesForPrincipalSuccess, actions.editRolesForPrincipalSuccess),
      (state, action) => {
        state.roleBindings = state.roleBindings.filter(
          (roleBinding) => roleBinding.principalId !== action.payload.principalId,
        );
        state.roleBindings = [
          ...state.roleBindings,
          ...action.payload.roles.map((role) => ({
            role: role.role_name,
            principalId: action.payload.principalId,
            projectId: action.payload.globalProjectId,
          })),
        ];
      },
    );
});

export default rbacReducer;
