import {useUserData} from '../../selectors/userSession';
import {LANGUAGE_OPTIONS, LANGUAGE_OPTIONS_LIST, type LanguageTypes} from './constants';

/*
 * Returns the language to use for a user
 * If a user has not given a preferred language, default to python.
 * If they gave a preferred language we support, use that, else default to python.
 */
export const useSelectedLanguage = <T extends LanguageTypes = LanguageTypes, U extends T = T>(
  languageOptions: U[] = LANGUAGE_OPTIONS_LIST as U[],
  defaultOption: U = LANGUAGE_OPTIONS.python as U,
): U => {
  const userData = useUserData();
  const language = userData?.params?.language;
  if (!language) {
    return defaultOption;
  }
  const userLanguage = language.toLowerCase() as T;
  if (languageOptions.includes(userLanguage as U)) {
    return userLanguage as U;
  }
  return defaultOption;
};
