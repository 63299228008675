import {type SliceTooltipProps} from '@nivo/line';
import ChartTooltip from '../../../../../../components/chart/ChartTooltip/ChartTooltip';

const PodFullnessTooltip = ({slice}: SliceTooltipProps) => {
  const {points} = slice;
  const point = points[0];
  const time = point.data.x;
  const item = {
    color: point.serieColor,
    label: point.serieId as string,
    value: String(point.data.y),
    unit: '%',
  };
  return <ChartTooltip timestamp={new Date(time)} items={[item]} />;
};

export default PodFullnessTooltip;
