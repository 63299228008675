import {type Guide, GuideId} from '../../types';
import {
  CreateVectorEmbeddings,
  Section1Header,
  UnderstandRecords,
  ViewSampleData,
} from './Section1';
import {ConfigurePineconeIndex, UpsertRecords} from './Section2';
import {CreateQueryVector, QueryYourIndex, Section3Header} from './Section3';
import {INTERACTIVE_DEMO_LANGUAGE_OPTIONS} from './constants';

export const INTERACTIVE_DEMO_GUIDE: Guide = {
  id: GuideId.INTERACTIVE_DEMO,
  supportedLanguages: INTERACTIVE_DEMO_LANGUAGE_OPTIONS,
  sections: [
    {
      index: 0,
      title: 'Generate vectors',
      component: Section1Header,
      blocks: [
        {
          index: 0,
          title: 'View sample data',
          component: ViewSampleData,
        },
        {
          index: 1,
          title: 'Create vector embeddings',
          component: CreateVectorEmbeddings,
          hideNextButton: true,
        },
        {
          index: 2,
          title: 'Understand records',
          component: UnderstandRecords,
        },
      ],
    },
    {
      index: 1,
      title: 'Create index',
      blocks: [
        {
          index: 0,
          title: 'Configure Pinecone index',
          component: ConfigurePineconeIndex,
          hideNextButton: true,
        },
        {
          index: 1,
          title: 'Upsert Records',
          component: UpsertRecords,
          hideNextButton: true,
        },
      ],
    },
    {
      index: 2,
      title: 'Query',
      component: Section3Header,
      blocks: [
        {
          index: 0,
          title: 'Create a query vector',
          component: CreateQueryVector,
          hideNextButton: true,
        },
        {
          index: 1,
          title: 'Query your index',
          component: QueryYourIndex,
        },
      ],
    },
  ],
};
