import {useAppSelector as useSelector} from '../app/store';
import {PROVIDER_INFORMATION_MAP, PaymentStatus, Plans, Providers, Roles} from '../constants';
import {useSelectedOrganizationId} from './params';
import {useUserId} from './userSession';

export const useOrganizations = () => {
  return useSelector((state) => state.organizations.items);
};

export const useOrganization = (organizationId: string) => {
  const organizations = useOrganizations();
  return organizations[organizationId];
};

export const useOrganizationsAreLoading = () => {
  return useSelector((state) => state.organizations.loading);
};

export const useMarketplace = (organizationId: string) => {
  return useSelector((state) => state.organizations.marketplaces[organizationId]);
};

export const useOrgRole = (userId: string, orgId?: string) => {
  return useSelector((state) => {
    return state.organizations.userRoles.find(
      (userRole) => userRole.userId === userId && userRole.orgId === orgId,
    );
  })?.role;
};

export const useOrgMembers = (orgId: string) => {
  return useSelector((state) => {
    return state.organizations.userRoles.filter((userRole) => {
      return userRole.orgId === orgId;
    });
  });
};

export const useSelectedOrganization = () => {
  const orgId = useSelectedOrganizationId();
  return useOrganization(orgId || '');
};

export const useIsSelectedOrgValid = () => {
  const organizations = useOrganizations();
  const selectedOrgId = useSelectedOrganizationId();
  return selectedOrgId in organizations;
};

export const useSelectedOrganizationPaymentStatus = () => {
  const selectedOrganization = useSelectedOrganization();
  return selectedOrganization.payment_status;
};

const suspendedStatuses = [
  PaymentStatus.RESTRICTED,
  PaymentStatus.SUSPENDED,
  PaymentStatus.DEACTIVATED,
];
export const useSelectedOrganizationIsSuspended = () => {
  const status = useSelectedOrganizationPaymentStatus();
  return suspendedStatuses.includes(status);
};

export const useIsSelectedOrganiztionMarketplace = () => {
  const selectedOrganization = useSelectedOrganization();
  return (
    selectedOrganization.gcp_marketplace_id ||
    selectedOrganization.aws_marketplace_id ||
    selectedOrganization.azure_marketplace_id
  );
};

export const useSelectedOrgMarketplaceInfo = () => {
  const selectedOrganization = useSelectedOrganization();
  if (
    selectedOrganization.gcp_marketplace_id ||
    selectedOrganization.aws_marketplace_id ||
    selectedOrganization.azure_marketplace_id
  ) {
    let key = '' as Providers;
    if (selectedOrganization.gcp_marketplace_id) {
      key = Providers.GCP;
    } else if (selectedOrganization.aws_marketplace_id) {
      key = Providers.AWS;
    } else if (selectedOrganization.azure_marketplace_id) {
      key = Providers.AZURE;
    }
    return PROVIDER_INFORMATION_MAP[key];
  }
  return null;
};

export const useIsOwnerOfOrganization = () => {
  const userId = useUserId();
  const organization = useSelectedOrganization();
  const role = useOrgRole(userId || '', organization?.id || '');
  return role === Roles.OWNER;
};

const useOwners = (orgId: string) => {
  return useOrgMembers(orgId).filter((userRole) => {
    return userRole.role === Roles.OWNER;
  });
};

export const useIsOnlyOwner = () => {
  const isOwner = useIsOwnerOfOrganization();
  const organization = useSelectedOrganization();
  const owners = useOwners(organization.id);
  return isOwner && owners.length === 1;
};

export const useSelectedOrganizationPlan = () => {
  const selectedOrganization = useSelectedOrganization();
  return selectedOrganization?.plan || Plans.FREE;
};

export const useIsSelectedOrganizationSubscribed = () => {
  const plan = useSelectedOrganizationPlan();
  return (
    plan === Plans.STANDARD ||
    plan === Plans.ENTERPRISE ||
    plan === Plans.DEDICATED ||
    plan === Plans.INTERNAL
  );
};
