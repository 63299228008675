import {Box, Button, Checkbox, Typography} from '@mui/material';
import {
  type PaginationState,
  usePagination,
} from '@pinecone-experience/timber/PaginationControls/hooks';
import Table from '@pinecone-experience/timber/Table';
import {useMultiSelect} from '@pinecone-experience/timber/Table/hooks';
import {deleteVectors, fetchVectors} from '../../../../../../actions/dataActions';
import {type QueryData} from '../../../../../../api/dataPlaneApi';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {useDataPlane} from '../../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../../hooks/utils';
import {
  useExplorerListIsLoading,
  useExplorerListLimit,
  useExplorerListNamespace,
  useExplorerListResults,
  useExplorerListResultsType,
  useExplorerListResultsUsage,
  useVectorInfo,
} from '../../../../../../selectors/explorer';
import {GUTTER} from '../../../../../../styles/theme';
import {formatPluralAmount} from '../../../../../../utils/format';
import ExplorerResultsFooter from '../../ExplorerResultsFooter/ExplorerResultsFooter';
import ResultsGuard from '../../ResultsGuard/ResultsGuard';
import VectorActions from '../../VectorCard/VectorActions/VectorActions';
import VectorCard from '../../VectorCard/VectorCard';
import {type IndexExplorerState} from '../../utilities/utils';

type ListResultsProps = {
  indexExplorerState: IndexExplorerState;
  setQueryExplorerFormQuery: (data: QueryData, namespace: string) => void;
  onSubmitQueryExplorer: () => void;
};

type VectorCardWrapperProps = {
  indexExplorerState: IndexExplorerState;
  vectorId: string;
  paginationState: PaginationState<string>;
  index: number;
  namespace: string;
  setQueryExplorerFormQuery: (data: QueryData, namespace: string) => void;
  onSubmitQueryExplorer: () => void;
};

function VectorCardWrapper({
  indexExplorerState,
  vectorId,
  paginationState,
  namespace,
  index,
  setQueryExplorerFormQuery,
  onSubmitQueryExplorer,
}: VectorCardWrapperProps) {
  const {service, project} = indexExplorerState;
  const vectorInfo = useVectorInfo(project.id, service, vectorId, namespace);
  return (
    <VectorCard
      first={index === 0 && paginationState.currentPage === 0}
      setFormQuery={setQueryExplorerFormQuery}
      onSubmit={onSubmitQueryExplorer}
      rank={index + paginationState.currentPage * paginationState.rowsPerPage + 1}
      vector={vectorInfo}
      namespace={namespace}
      indexExplorerState={indexExplorerState}
    />
  );
}

function ListResults({
  indexExplorerState,
  setQueryExplorerFormQuery,
  onSubmitQueryExplorer,
}: ListResultsProps) {
  const {project, service, host} = indexExplorerState;
  const results = useExplorerListResults(project.id, service);
  const paginationState = usePagination(results, ['record', 'records'], 10);
  const {itemsToShow, label} = paginationState;
  const {selectedIndexes, onClickRow, toggleAllSelected, amtSelected, selectedItems} =
    useMultiSelect(itemsToShow);
  const loading = useExplorerListIsLoading(project.id, service);
  const usage = useExplorerListResultsUsage(project.id, service);
  const limit = useExplorerListLimit(project.id, service);
  const namespace = useExplorerListNamespace(project.id, service);
  const listResultsType = useExplorerListResultsType(project.id, service);
  const {setQueryData: setQueryDataFetch} = useDataPlane<{
    namespace: string;
    ids: string[];
  }>(project, service, host);
  const doFetchVectors = (ids: string[]) => {
    setQueryDataFetch({
      action: fetchVectors,
      data: {
        namespace,
        ids,
      },
    });
  };
  const doQuery = (id: string) => {
    setQueryExplorerFormQuery({id}, namespace);
    onSubmitQueryExplorer();
  };
  const cellRenderer = (row: number, col: number) => {
    const vectorId = itemsToShow[row];
    if (col === 0) {
      return (
        <Checkbox
          key={selectedIndexes[row]}
          checked={selectedIndexes[row]}
          onClick={(evt) => onClickRow(row, evt)}
          size="small"
          sx={{maxWidth: 10}}
        />
      );
    }
    if (col === 1) {
      return <Typography width={400}>{vectorId}</Typography>;
    }
    if (col === 2) {
      return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <VectorActions
            vectorId={vectorId}
            first={paginationState.currentPage === 0 && row === 0}
            namespace={namespace}
            indexExplorerState={indexExplorerState}
            variant="menu"
            doFetch={() => doFetchVectors([vectorId])}
            doQuery={() => doQuery(vectorId)}
          />
        </Box>
      );
    }
    return '';
  };
  const selectedLabel = `${amtSelected} of ${itemsToShow.length} selected`;
  const unselectedLabel = `Showing ${label}${limit ? ` (limit=${limit})` : ''}`;
  const fetchSelected = () => {
    doFetchVectors(selectedItems);
  };
  const {val: deleteIsOpen, setFalse: closeDelete, setTrue: openDelete} = useBooleanState();
  const {setQueryData: setQueryDataDelete} = useDataPlane<{
    namespace: string;
    ids: string[];
  }>(project, service, host);
  const deleteSelected = () => {
    setQueryDataDelete({
      action: deleteVectors,
      data: {
        ids: selectedItems,
        namespace,
      },
    });
  };
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', pt: 3, gap: 1}}>
      <Box display="flex" justifyContent="space-between">
        <Typography>{amtSelected > 0 ? selectedLabel : unselectedLabel}</Typography>
        <Box visibility={amtSelected > 0 ? 'initial' : 'hidden'} display="flex" gap={1}>
          <Button onClick={openDelete} color="error">
            Delete
          </Button>
          <Button onClick={fetchSelected} variant="contained">{`Fetch ${formatPluralAmount(
            amtSelected,
            'record',
            'records',
          )}`}</Button>
          <DeleteConfirmationModal
            open={deleteIsOpen}
            close={closeDelete}
            itemType="Vector"
            name={selectedItems.join(', ')}
            skipCheck
            doDelete={deleteSelected}
          />
        </Box>
      </Box>
      <ResultsGuard loading={loading} total={results.length}>
        {listResultsType === 'list' ? (
          <Table
            itemLabel={['vector', 'vectors']}
            columns={[
              {
                name: 'check',
                component: (
                  <Checkbox
                    indeterminate={amtSelected > 0 && amtSelected < itemsToShow.length}
                    checked={amtSelected > 0 && amtSelected === itemsToShow.length}
                    onClick={toggleAllSelected}
                    size="small"
                    sx={{maxWidth: 10}}
                  />
                ),
                dataType: 'actions',
              },
              {name: `ID (${limit})`, dataType: 'string'},
              {name: 'Actions', dataType: 'actions'},
            ]}
            bodyCellStyleOverrides={{py: 0}}
            gutter={GUTTER}
            numRows={itemsToShow.length}
            cellRenderer={cellRenderer}
            customIdPrefix="index-explorer-vector-list"
          />
        ) : (
          <Box sx={{minHeight: 50}}>
            {paginationState.itemsToShow.map((vectorId, index) => (
              <VectorCardWrapper
                key={vectorId}
                vectorId={vectorId}
                index={index}
                indexExplorerState={indexExplorerState}
                namespace={namespace}
                paginationState={paginationState}
                onSubmitQueryExplorer={onSubmitQueryExplorer}
                setQueryExplorerFormQuery={setQueryExplorerFormQuery}
              />
            ))}
          </Box>
        )}
      </ResultsGuard>
      <ExplorerResultsFooter paginationState={paginationState} readUnits={usage?.readUnits} />
    </Box>
  );
}

export default ListResults;
