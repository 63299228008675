import {z} from 'zod';

const schema = z.object({
  feedback: z.string().nonempty(),
  mood: z.string(),
  files: z.array(z.object({file: z.instanceof(File)})),
});

export default schema;
export type FeedbackData = z.infer<typeof schema>;
