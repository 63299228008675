import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import {type GlobalProject} from '../../../../types';
import AssistantActionMenu from '../../AssistantActionMenu/AssistantActionMenu';

type AssistantHeaderProps = {
  name: string;
  project: GlobalProject;
  goToList: () => void;
};

function AssistantHeader({name, project, goToList}: AssistantHeaderProps) {
  return (
    <PageHeader
      title={name}
      titleHidden
      breadcrumb={{text: 'Go back', action: goToList}}
      actions={<AssistantActionMenu project={project} name={name} />}
    />
  );
}

export default AssistantHeader;
