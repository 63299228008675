import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import type * as React from 'react';
import {useBooleanState} from '../hooks/utils';
import DeleteConfirmationModal from './DeleteConfirmationModal';

type ButtonWithConfirmProps = {
  icon: React.ReactElement;
  itemType: string;
  name: string;
  doDelete: () => void;
  warningText?: string;
  title?: string;
  disabled?: boolean;
  action?: string;
  titleAction?: string;
  customId?: string;
  ariaLabel?: string;
  skipCheck?: boolean;
};

const ButtonWithConfirm: React.FC<ButtonWithConfirmProps> = ({
  icon,
  itemType,
  name,
  doDelete,
  warningText = '',
  title = '',
  disabled = false,
  action = undefined,
  titleAction = undefined,
  customId = '',
  ariaLabel = '',
  skipCheck = false,
}) => {
  const {val: showConfirm, setTrue: setShowConfirm, setFalse: hideConfirm} = useBooleanState();
  return (
    <>
      <IconButton
        id={customId}
        onClick={setShowConfirm}
        icon={icon}
        tooltip={title}
        disabled={disabled}
        ariaLabel={ariaLabel}
        ariaExpanded={showConfirm ? true : undefined}
        ariaControls={showConfirm ? 'delete-confirmation-modal' : undefined}
        border
      />
      <DeleteConfirmationModal
        open={showConfirm}
        close={hideConfirm}
        itemType={itemType}
        name={name}
        doDelete={doDelete}
        warningText={warningText}
        action={action}
        titleAction={titleAction}
        skipCheck={skipCheck}
      />
    </>
  );
};

export default ButtonWithConfirm;
