import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {type Control} from 'react-hook-form';
import {type EndpointFormSchema} from '../endpointFormSchema';
import {formStepStyles} from '../stepStyles';

interface SelectRegionStepProps {
  control: Control<EndpointFormSchema>;
  selectedRegion: string;
  endpoints: Record<string, string>;
  onClose: () => void;
  onNext: () => void;
}

const styles = {
  impact: {
    mt: -1,
    ml: 1,
  },
};

export default function SelectRegionStep({
  control,
  selectedRegion,
  endpoints,
  onClose,
  onNext,
}: SelectRegionStepProps) {
  const options = Object.keys(endpoints).map((key) => ({
    value: key,
  }));

  return (
    <>
      <Box sx={formStepStyles.content}>
        <Typography data-testid="region-description">
          Select your VPC region to set up the Private Endpoint. All Pinecone indexes in{' '}
          <InlineCode text={selectedRegion} /> can connect via this endpoint.
        </Typography>
        <Box>
          <SelectInput
            control={control}
            name="region"
            fullWidth
            options={options}
            label="Select your VPC Region"
          />
          <Box sx={styles.impact}>
            <Typography variant="body2" color="secondary">
              Indexes outside this region will not be able to connect via this endpoint.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={formStepStyles.actions}>
        <Button color="secondary" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </>
  );
}
