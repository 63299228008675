import {useEffect, useState} from 'react';

function createFreshArray(amt: number, selected = false) {
  return new Array(amt).fill(selected);
}

export function useMultiSelect<T>(items: T[]) {
  const [selectedIndexes, setSelectedIndexes] = useState(createFreshArray(items.length));
  const [lastSelected, setLastSelected] = useState(-1);
  useEffect(() => {
    setSelectedIndexes(createFreshArray(items.length));
  }, [items]);
  const onClickRow = (row: number, evt: React.MouseEvent) => {
    if (evt.shiftKey && lastSelected >= 0) {
      const min = Math.min(row, lastSelected);
      const max = Math.max(row, lastSelected);
      const newSelected = [...selectedIndexes];
      for (let i = min; i <= max; i++) {
        newSelected[i] = true;
      }
      setSelectedIndexes(newSelected);
    } else {
      selectedIndexes[row] = !selectedIndexes[row];
      setSelectedIndexes([...selectedIndexes]);
      setLastSelected(row);
    }
  };
  const amtSelected = selectedIndexes.filter((a) => a).length;
  const toggleAll = (val: boolean) => {
    setSelectedIndexes(createFreshArray(items.length, val));
    setLastSelected(-1);
  };
  const toggleAllSelected = () => {
    setSelectedIndexes(createFreshArray(items.length, amtSelected !== items.length));
    setLastSelected(-1);
  };
  const selectedItems = selectedIndexes
    .map((selected, index) => {
      if (selected) {
        return items[index];
      }
      return '';
    })
    .filter(Boolean) as T[];
  return {
    selectedIndexes,
    onClickRow,
    toggleAllSelected,
    toggleAll,
    amtSelected,
    selectedItems,
  };
}

export type MultiSelectState<T> = {
  selectedIndexes: boolean[];
  onClickRow: (row: number, evt: React.MouseEvent) => void;
  toggleAllSelected: () => void;
  toggleAll: (val: boolean) => void;
  amtSelected: number;
  selectedItems: T[];
};
