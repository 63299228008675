import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/assistantActions';
import {STREAM_ERROR_MESSAGES} from '../api/streamChatCompletion/constants';
import {AssistantQuotaKeys, DEFAULT_ASSISTANT_CHAT_CONFIG, IndexState} from '../constants';
import {
  type Assistant,
  type AssistantChat,
  type AssistantChatConfig,
  type AssistantUsage,
  type ChatSession,
  type UsageQuota,
} from '../types';
import {formatBytesToGB} from '../utils/format';
import {type AsyncDataState, initAsyncDataState, isGlobalProjectAction, isOrgAction} from './utils';

type ProjectState = {
  assistants: AsyncDataState<Record<string, Assistant>>;
  chats: Record<string, AssistantChat>;
  quotas: AsyncDataState<UsageQuota[]>;
};

type OrgState = {
  acceptedTerms: AsyncDataState<boolean>;
};

const initialProjectState = {
  assistants: initAsyncDataState({}, true),
  chats: {},
  quotas: initAsyncDataState(),
} as ProjectState;

const initialOrgState = {
  acceptedTerms: initAsyncDataState(undefined, true),
} as unknown as OrgState;

interface AssistantState {
  projects: Record<string, ProjectState>;
  organizations: Record<string, OrgState>;
}

const initialState = {
  projects: {},
  organizations: {},
} as AssistantState;

export const getInitialChatState = (
  configOverride?: Partial<AssistantChatConfig>,
): ChatSession => ({
  messages: [],
  config: {
    ...DEFAULT_ASSISTANT_CHAT_CONFIG,
    ...configOverride,
  },
});

function getQuotasFromAssistantUsage(
  key: AssistantQuotaKeys,
  data: AssistantUsage | undefined,
  formatter = (value: number) => value,
): UsageQuota | undefined {
  if (data === undefined || data.limit === null) {
    return undefined;
  }

  return {
    key,
    limit: formatter(data.limit),
    utilization: formatter(data.usage),
  };
}

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.listAssistantsRequest, (state) => {
      state.assistants.loading = true;
    })
    .addCase(actions.listAssistantsSuccess, (state, action) => {
      const assistants: Record<string, Assistant> = {};
      action.payload.assistants.forEach((assistant) => {
        assistants[assistant.name] = assistant;
      });
      state.assistants.data = assistants;
      state.assistants.loading = false;
    })
    .addCase(actions.listAssistantsFailure, (state) => {
      state.assistants.loading = false;
    })
    .addCase(actions.getAssistantSuccess, (state, action) => {
      state.assistants.data = state.assistants.data || {};
      state.assistants.data[action.payload.assistant.name] = action.payload.assistant;
      state.assistants.loading = false;
    })
    .addCase(actions.getAssistantFailure, (state) => {
      state.assistants.loading = false;
    })
    .addCase(actions.createAssistantRequest, (state) => {
      state.assistants.loading = true;
    })
    .addCase(actions.createAssistantSuccess, (state, action) => {
      state.assistants.data = state.assistants.data || {};
      state.assistants.data[action.payload.assistant.name] = action.payload.assistant;
      state.assistants.loading = false;
    })
    .addCase(actions.createAssistantFailure, (state) => {
      state.assistants.loading = false;
    })
    .addCase(actions.deleteAssistantRequest, (state) => {
      state.assistants.loading = true;
    })
    .addCase(actions.deleteAssistantSuccess, (state, action) => {
      state.assistants.data = state.assistants.data || {};
      state.assistants.data[action.payload.assistantName].status = IndexState.TERMINATING;
      state.assistants.loading = false;
    })
    .addCase(actions.deleteAssistantFailure, (state) => {
      state.assistants.loading = false;
    })
    .addCase(actions.assistantFinishedTerminating, (state, action) => {
      state.assistants.data = state.assistants.data || {};
      state.chats = state.chats || {};
      delete state.assistants.data[action.payload.assistantName];
      delete state.chats[action.payload.assistantName];
    })
    .addCase(actions.clearAssistantChat, (state, action) => {
      const {assistantName} = action.payload;
      state.chats[assistantName].data = state.chats[assistantName]?.data || getInitialChatState();
      state.chats[assistantName].data.messages = [];
    })
    .addCase(actions.streamAssistantChatCompletionRequest, (state, action) => {
      const {assistantName, message} = action.payload;
      const data = state.chats[assistantName]?.data || getInitialChatState();
      data.messages.push(message);
      data.messages.push({role: 'assistant', content: ''});

      state.chats[action.payload.assistantName] = initAsyncDataState(data, true);
    })
    .addCase(actions.streamAssistantChatCompletionResponse, (state, action) => {
      const {assistantName, responseChunk} = action.payload;
      const lastChat = state.chats[assistantName]?.data?.messages.at(-1);

      // Case 1:
      // Assistant has started to response, so new response chunks need to be
      // appended to the Assistant's response message.
      if (lastChat?.role === 'assistant' && lastChat.content !== undefined) {
        lastChat.content = lastChat.content.concat(responseChunk);
        return;
      }

      // Case 2:
      // Assistant has not started to response yet,
      // so we need to initialize a new response message.
      const data = state.chats[assistantName]?.data || getInitialChatState();

      state.chats[action.payload.assistantName] = initAsyncDataState(
        {...data, messages: [...data.messages, {role: 'assistant', content: ''}]},
        true,
      );
    })
    .addCase(actions.streamAssistantChatCompletionChatIdReceived, (state, action) => {
      const {assistantName, chatId} = action.payload;
      const data = state.chats[assistantName]?.data || getInitialChatState();

      state.chats[assistantName].data = {...data, id: chatId};
    })
    .addCase(actions.streamAssistantChatCompletionSuccess, (state, action) => {
      const lastChat = state.chats[action.payload.assistantName].data?.messages.at(-1);

      // If the last message is an empty Assistant message, remove it and set an error.
      if (lastChat?.role === 'assistant' && lastChat?.content === '') {
        state.chats[action.payload.assistantName].data?.messages.pop();
        state.chats[action.payload.assistantName].error ??= STREAM_ERROR_MESSAGES.emptyResponse;
      }

      state.chats[action.payload.assistantName].loading = false;
    })
    .addCase(actions.streamAssistantChatCompletionFailure, (state, action) => {
      const lastChat = state.chats[action.payload.assistantName].data?.messages.at(-1);

      // If the last message is an empty Assistant message, remove it and set an error.
      if (lastChat?.role === 'assistant' && lastChat?.content === '') {
        state.chats[action.payload.assistantName].data?.messages.pop();
        state.chats[action.payload.assistantName].error ??= STREAM_ERROR_MESSAGES.emptyResponse;
      }

      state.chats[action.payload.assistantName].error = action.payload.error;
      state.chats[action.payload.assistantName].loading = false;
    })
    .addCase(actions.updateAssistantChatModel, (state, action) => {
      const {assistantName, model} = action.payload;

      if (state.chats[assistantName]?.data) {
        state.chats[assistantName].data.config.model = model;
        return;
      }

      state.chats[assistantName] = initAsyncDataState(getInitialChatState({model}));
    })
    .addCase(actions.getAssistantsUsageRequest, (state) => {
      state.quotas.loading = true;
    })
    .addCase(actions.getAssistantsUsageSuccess, (state, action) => {
      state.quotas.loading = false;

      const {usage} = action.payload;
      const quotas = [
        getQuotasFromAssistantUsage(AssistantQuotaKeys.QUERIES_PER_MONTH, usage.queries),
        getQuotasFromAssistantUsage(
          AssistantQuotaKeys.STORAGE_GB_PER_PROJECT,
          usage.storage_bytes,
          formatBytesToGB,
        ),
        getQuotasFromAssistantUsage(AssistantQuotaKeys.PROMPT_TOKENS, usage.prompt_tokens),
        getQuotasFromAssistantUsage(
          AssistantQuotaKeys.COMPLETIONS_TOKENS,
          usage.completions_tokens,
        ),
      ].filter((quota) => quota !== undefined) as UsageQuota[];

      state.quotas.data = quotas;
    })
    .addCase(actions.getAssistantsUsageFailure, (state) => {
      state.quotas.loading = false;
    });
});

const orgReducer = createReducer(initialOrgState, (builder) => {
  builder
    .addCase(actions.getAcceptedAssistantsTermsRequest, (state) => {
      state.acceptedTerms.loading = true;
    })
    .addCase(actions.getAcceptedAssistantsTermsSuccess, (state, action) => {
      state.acceptedTerms.data = action.payload.acceptedTerms;
      state.acceptedTerms.loading = false;
    })
    .addCase(actions.getAcceptedAssistantsTermsFailure, (state, action) => {
      state.acceptedTerms.loading = false;
      state.acceptedTerms.error = action.payload.error;
    })
    .addCase(actions.acceptAssistantsTermsRequest, (state) => {
      state.acceptedTerms.loading = true;
    })
    .addCase(actions.acceptAssistantsTermsSuccess, (state, action) => {
      state.acceptedTerms.data = action.payload.acceptedTerms;
      state.acceptedTerms.loading = false;
    })
    .addCase(actions.acceptAssistantsTermsFailure, (state) => {
      state.acceptedTerms.loading = false;
    });
});

const assistantsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
  builder.addMatcher(isOrgAction, (state, action) => {
    state.organizations[action.payload.organizationId] = orgReducer(
      state.organizations[action.payload.organizationId],
      action,
    );
  });
});

export default assistantsReducer;
