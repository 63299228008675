import {useAppSelector as useSelector} from '../app/store';

export const useProjectQuotaLimits = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaLimits.projects?.[projectId]?.quotaLimits || {};
  });
};

export const useProjectQuotaLimitsIsLoading = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaLimits.projects?.[projectId]?.loading;
  });
};
