import {checkCollectionTerminating, getCollection} from '../actions/collectionActions';
import {IndexState, POLLING_INTERVAL} from '../constants';
import {useCollectionStatusByGlobalProjectId} from '../selectors/collections';
import {type GlobalProject} from '../types';
import {useControllerApi} from './use-api';
import {useInterval} from './utils';

export function usePollCollectionStatus(
  collection: string,
  project: GlobalProject,
  shouldPoll = true,
) {
  const {setQueryData} = useControllerApi<{
    name: string;
    globalProjectId: string;
  }>(project);
  const collectionStatus = useCollectionStatusByGlobalProjectId(project?.id, collection);
  useInterval(
    () => {
      setQueryData({
        action: getCollection,
        data: {name: collection, globalProjectId: project?.id},
      });
    },
    collectionStatus?.status !== IndexState.READY && shouldPoll ? POLLING_INTERVAL : null,
  );
  useInterval(
    () => {
      setQueryData({
        action: checkCollectionTerminating,
        data: {name: collection, globalProjectId: project?.id},
      });
    },
    collectionStatus?.status === IndexState.TERMINATING && shouldPoll ? POLLING_INTERVAL : null,
  );
  return collectionStatus;
}
