import StatusBox from '../../../../components/StatusBox';
import {IndexState} from '../../../../constants';
import {usePollCollectionStatus} from '../../../../hooks/collections';
import {useIndexStatusColorMap} from '../../../../hooks/colorMaps';
import {useSelectedGlobalProject} from '../../../../selectors/projects';

type CollectionStatusBoxProps = {
  collection: string;
};

function CollectionStatusBox({collection}: CollectionStatusBoxProps) {
  const selectedProject = useSelectedGlobalProject();
  const collectionStatus = usePollCollectionStatus(collection, selectedProject);
  const color = useIndexStatusColorMap(collectionStatus?.status || IndexState.UNKNOWN);
  return <StatusBox statusText={collectionStatus?.status} color={color} sx={{ml: 2}} />;
}

export default CollectionStatusBox;
