import {type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type CidrConfig, type NetworkAllowlist} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {projectLoaded} from './serviceActions';
import {decodeErrorMessage} from './utils/errors';

export const clearNetworkAllowlistUpdateError = createAction<undefined>(
  'CLEAR_NETWORK_ALLOWLIST_UPDATE_ERROR',
);

export const getNetworkAllowlistRequest = createAction<{globalProjectId: string}>(
  'GET_NETWORK_ALLOWLIST_REQUEST',
);
export const getNetworkAllowlistSuccess = createAction<{
  globalProjectId: string;
  data: NetworkAllowlist;
}>('GET_NETWORK_ALLOWLIST_SUCCESS');
export const getNetworkAllowlistFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('GET_NETWORK_ALLOWLIST_FAILURE');

export function getNetworkAllowlist(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getNetworkAllowlistRequest({globalProjectId: data.globalProjectId}));
    return api
      .getNetworkAllowlist(data.globalProjectId)
      .then((response) => {
        dispatch(
          getNetworkAllowlistSuccess({
            globalProjectId: data.globalProjectId,
            data: response.entries,
          }),
        );
        dispatch(projectLoaded(data));
      })
      .catch((error) => {
        dispatch(
          getNetworkAllowlistFailure({
            globalProjectId: data.globalProjectId,
            error: decodeErrorMessage(error.message),
          }),
        );
      });
  };
}

export const createNetworkAllowlistEntryRequest = createAction<{globalProjectId: string}>(
  'CREATE_NETWORK_ALLOWLIST_ENTRY_REQUEST',
);
export const createNetworkAllowlistEntrySuccess = createAction<{
  globalProjectId: string;
  entryCreated: boolean;
}>('CREATE_NETWORK_ALLOWLIST_ENTRY_SUCCESS');
export const createNetworkAllowlistEntryFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('CREATE_NETWORK_ALLOWLIST_ENTRY_FAILURE');

export function createNetworkAllowlistEntry(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    config: CidrConfig;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createNetworkAllowlistEntryRequest(data));
    return api
      .createNetworkAllowlistEntry(data.globalProjectId, data.config)
      .then((response) => {
        dispatch(
          createNetworkAllowlistEntrySuccess({
            globalProjectId: data.globalProjectId,
            entryCreated: response.ok,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Project internet access successfully updated.`,
          }),
        );
        dispatch(
          getNetworkAllowlist(api, {
            globalProjectId: data.globalProjectId,
          }) as unknown as AnyAction,
        );
      })
      .catch((error) => {
        dispatch(
          createNetworkAllowlistEntryFailure({
            globalProjectId: data.globalProjectId,
            error: decodeErrorMessage(error.message),
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Project internet access could not be updated. ${decodeErrorMessage(
              error.message,
            )}`,
          }),
        );
      });
  };
}

export const deleteNetworkAllowlistEntryRequest = createAction<{
  globalProjectId: string;
  entryId: string;
}>('DELETE_NETWORK_ALLOWLIST_ENTRY_REQUEST');
export const deleteNetworkAllowlistEntrySuccess = createAction<{
  globalProjectId: string;
  entryId: string;
}>('DELETE_NETWORK_ALLOWLIST_ENTRY_SUCCESS');
export const deleteNetworkAllowlistEntryFailure = createAction<{
  globalProjectId: string;
  entryId: string;
}>('DELETE_NETWORK_ALLOWLIST_ENTRY_FAILURE');

export function deleteNetworkAllowlistEntry(
  api: ControllerApi,
  data: {globalProjectId: string; entryId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(deleteNetworkAllowlistEntryRequest(data));
    return api
      .deleteNetworkAllowlistEntry(data.entryId)
      .then(() => {
        dispatch(deleteNetworkAllowlistEntrySuccess(data));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Project internet access successfully updated.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(deleteNetworkAllowlistEntryFailure(data));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Project internet access could not be updated. ${decodeErrorMessage(
              error.message,
            )}`,
          }),
        );
      });
  };
}
