import {useTheme} from '@mui/material';
import {useAppSelector as useSelector} from '../app/store';
import {THEME} from '../constants';

export const useAppTheme = () => {
  return useSelector((state) => state.ui.theme);
};

export const useIsDarkTheme = () => {
  return useTheme().palette.mode === THEME.DARK;
};
