import Box, {type BoxProps} from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, {type TabsProps} from '@mui/material/Tabs';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import type * as React from 'react';
import {Link, Navigate, Route, Routes, useLocation, useParams} from 'react-router-dom';

type StyledTabsProps<T extends string> = {
  tabs: TabData<T>[];
  idPrefix: string;
  defaultPath?: string;
  hidden?: boolean;
  sx?: Required<TabsProps['sx']>;
  sticky?: boolean;
  contentSx?: Required<BoxProps['sx']>;
};

type TabData<T extends string> = {
  value: T;
  component: React.ReactNode;
  label?: string;
  disabled?: boolean;
  tooltip?: string;
  hidden?: boolean;
};

const TAB_HEIGHT = 50;

const styles = {
  tabs: (isSticky: boolean) => ({
    position: isSticky ? 'sticky' : 'relative',
    top: 0,
    zIndex: 25,
    backgroundColor: 'background.default',
    borderBottom: 1,
    borderColor: 'divider',
    height: TAB_HEIGHT,
  }),
  hidden: {
    display: 'none',
  },
  content: {
    pt: 3,
    boxSizing: 'border-box',
    height: `calc(100% - ${TAB_HEIGHT}px)`,
    position: 'relative',
  },
};

function StyledTabs<T extends string>({
  tabs,
  idPrefix,
  defaultPath,
  hidden = false,
  sticky = true,
  sx = {},
  contentSx = {},
}: StyledTabsProps<T>) {
  const match = useParams();
  const location = useLocation();
  return (
    <Box flex={1}>
      <Tabs sx={{...styles.tabs(sticky), ...(hidden && styles.hidden), ...sx}} value={match['*']}>
        {/* Placing a tooltip on an active tab prevents the select css from showing.
          Only wrap with tooltip if disabled */}
        {tabs.map((tab) => {
          if (tab.hidden) {
            return null;
          }

          const t = (
            <Tab
              key={tab.value}
              component={Link}
              to={{
                ...location,
                pathname: tab.value,
              }}
              id={`${idPrefix}-${tab.value}-tab`}
              value={tab.value}
              label={tab.label || tab.value.toUpperCase()}
              disabled={tab.disabled}
            />
          );
          if (tab.disabled) {
            return (
              <Tooltip key={tab.value} title={tab.disabled && tab.tooltip}>
                {t}
              </Tooltip>
            );
          }
          return t;
        })}
      </Tabs>
      <Box sx={[styles.content, contentSx]}>
        <Routes>
          {tabs.map(({value, component}) => {
            return <Route key={value} path={value} element={component} />;
          })}
          {defaultPath && <Route path="" element={<Navigate replace to={defaultPath} />} />}
        </Routes>
      </Box>
    </Box>
  );
}

export default StyledTabs;
