import {getAssistant} from '../actions/assistantActions';
import {getAssistantFile} from '../actions/assistantFilesActions';
import {AssistantFileStatus, IndexState, POLLING_INTERVAL} from '../constants';
import {useAssistant, useAssistantFile} from '../selectors/assistants';
import {type GlobalProject} from '../types';
import {useAssistantDataApi, useControllerApi} from './use-api';
import {useInterval} from './utils';

export function usePollAssistantStatus(assistantName: string, project: GlobalProject) {
  const {setQueryData} = useControllerApi(project, getAssistant, {
    assistantName,
    globalProjectId: project?.id,
  });
  const assistant = useAssistant(project.id, assistantName);
  useInterval(
    () => {
      setQueryData({
        action: getAssistant,
        data: {assistantName, globalProjectId: project?.id},
      });
    },
    assistant?.status !== IndexState.READY ? POLLING_INTERVAL : null,
  );
  return assistant?.status;
}

interface AssistantFileState {
  status: AssistantFileStatus;
  percentDone: number;
}

export function usePollAssistantFileState(
  fileId: string,
  assistantName: string,
  globalProjectId: string,
): AssistantFileState {
  const {setQueryData} = useAssistantDataApi<{
    assistantName: string;
    fileId: string;
    silentPoll: boolean;
  }>(globalProjectId);

  const file = useAssistantFile(assistantName, fileId);
  const status = file?.status || AssistantFileStatus.PROCESSING;
  const percentDone = file?.percent_done || 0;

  useInterval(
    () => {
      setQueryData({
        action: getAssistantFile,
        data: {
          assistantName,
          fileId,
          silentPoll: true,
        },
      });
    },
    status === AssistantFileStatus.PROCESSING || status === AssistantFileStatus.DELETING
      ? POLLING_INTERVAL
      : null,
  );

  return {status, percentDone};
}
