import dayjs from '../../../../utils/dayjs';

const utcTime = dayjs.utc();

export const TimespanMap = {
  'Current Month': {
    start: utcTime.startOf('month').valueOf(),
    end: utcTime.valueOf(),
  },
  'Last Month': {
    start: utcTime.subtract(1, 'month').startOf('month').valueOf(),
    end: utcTime.subtract(1, 'month').endOf('month').valueOf(),
  },
  'Last 30 Days': {
    start: utcTime.subtract(30, 'days').valueOf(),
    end: utcTime.valueOf(),
  },
  'Last 90 Days': {
    start: utcTime.subtract(90, 'days').valueOf(),
    end: utcTime.valueOf(),
  },
  'This Year': {
    start: utcTime.startOf('year').valueOf(),
    end: utcTime.valueOf(),
  },
};
export type TimespanTypes = keyof typeof TimespanMap;

export const GroupByOptions = {
  serviceName: 'Product',
  projectId: 'Project',
};
export type GroupByTypes = keyof typeof GroupByOptions;

export interface UsageDatapoint {
  date: string;
  projectId: string;
  quantity: number;
  service: string;
  serviceName: string;
  subtotalCharge: number;
  unitLabel: string;
}

export const DATE_FORMAT = 'M/D/YY';
