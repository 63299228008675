import {Typography} from '@mui/material';
import {isAllowedOrigin} from '@pinecone-experience/integrations';
import {MessageAction, broadcastMessage, listenForMessageOnce} from '@pinecone-experience/messages';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {type PropsWithChildren, useMemo, useState} from 'react';
import {useEffect} from 'react';
import {CONNECT_URL} from '../../../../../constants';
import {useSelectedIntegration} from '../../../../../context/selected-integration';
import {HostContext} from '../context';

type HostGuardProps = PropsWithChildren<{}>;

// When running in Cypress integration tests, the grant page is hosted in an iframe, not a popup.
const parentWindow = window.Cypress ? window.parent : window.opener;

/**
 * Ensures that the host is sending messages from an allowed origin before
 * rendering its children.
 */
function HostGuard({children}: HostGuardProps) {
  const [origin, setOrigin] = useState<string | null>(null);

  const integration = useSelectedIntegration();
  const isAllowed = useMemo(
    () =>
      !!parentWindow &&
      !!origin &&
      (origin === CONNECT_URL || isAllowedOrigin(integration, origin)),
    [integration, origin],
  );

  useEffect(() => {
    if (!origin && parentWindow) {
      const cleanup = listenForMessageOnce(
        MessageAction.ACK,
        () => true,
        (_data, _source, ackOrigin) => {
          setOrigin(ackOrigin);
        },
      );
      broadcastMessage(MessageAction.READY, parentWindow);
      return cleanup;
    }
    return () => {};
  }, [origin]);

  const ctxValue = useMemo(() => (origin ? {origin} : null), [origin]);

  return (
    <LoadingContainer loading={!origin}>
      {isAllowed ? (
        <HostContext.Provider value={ctxValue}>{children}</HostContext.Provider>
      ) : (
        <Typography color="error">Origin {origin} is not allowed.</Typography>
      )}
    </LoadingContainer>
  );
}

export default HostGuard;
