import {deleteService} from '../../../../actions/serviceActions';
import {useNavigateToIndexListGlobal} from '../../../../hooks/navigation';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {
  HEAP_TRACKING_EVENTS,
  TRACKING_EVENTS,
  sendToGa,
  sendToHeap,
} from '../../../../utils/tracking';
import DeleteConfirmationModal from '../../../DeleteConfirmationModal';

function DeleteIndexConfirmationForm({
  service,
  open,
  close,
}: {
  service: string;
  open: boolean;
  close: () => void;
}) {
  const selectedProject = useSelectedGlobalProject();
  const selectedOrgId = useSelectedOrganizationId();
  const {go: navigateToIndexList} = useNavigateToIndexListGlobal(
    selectedOrgId,
    selectedProject?.id,
  );
  const {setQueryData} = useControllerApi<{
    name: string;
    globalProjectId: string;
  }>(selectedProject);
  const confirmDelete = () => {
    setQueryData({
      action: deleteService,
      data: {
        name: service,
        globalProjectId: selectedProject.id,
      },
    });
    navigateToIndexList();
    sendToGa(TRACKING_EVENTS.DELETE_INDEX);
    sendToHeap(HEAP_TRACKING_EVENTS.INDEX_DELETED);
  };
  return (
    <DeleteConfirmationModal
      open={open}
      close={close}
      itemType="Index"
      name={service}
      doDelete={confirmDelete}
      warningText="Your data will be permanently deleted."
    />
  );
}

export default DeleteIndexConfirmationForm;
