// TODO: CHANGE NAME TO DATABASE
import {type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import {push} from 'redux-first-history';
import type ControllerApi from '../api/controllerApi';
import {type Vector} from '../api/dataPlaneApi';
import {type IndexTargetType} from '../components/ServerlessMigration/schema';
import {POLLING_INTERVAL} from '../constants';
import {
  type GlobalProject,
  type ImportOperationResponse,
  type MigrationOperationResponse,
  type V4IndexInfoResponse,
} from '../types';
import {viewedIndexHistory} from '../utils/services';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const serviceFinishedTerminating = createAction<{globalProjectId: string; service: string}>(
  'SERVICE_FINISHED_TERMINATING',
);

export const listServicesRequest = createAction<{globalProjectId: string}>('LIST_SERVICES_REQUEST');
export const listServicesSuccess = createAction<{
  globalProjectId: string;
  serviceNames: string[];
  services: V4IndexInfoResponse[];
}>('LIST_SERVICES_SUCCESS');
export const listServicesFailure = createAction<{globalProjectId: string; error: Error}>(
  'LIST_SERVICES_FAILURE',
);

export const projectLoaded = createAction<{globalProjectId: string}>('PROJECT_LOADED');
export const projectNotLoaded = createAction<{globalProjectId: string}>('PROJECT_NOT_LOADED');

export const createServiceRequest = createAction<{globalProjectId: string}>(
  'CREATE_SERVICE_REQUEST',
);
export const createServiceSuccess = createAction<{
  globalProjectId: string;
  service: string;
  dataset?: Vector[];
}>('CREATE_SERVICE_SUCCESS');
export const createServiceFailure = createAction<{globalProjectId: string; error: string}>(
  'CREATE_SERVICE_FAILURE',
);

export const createServiceFromDatasetRequest = createAction<{globalProjectId: string}>(
  'CREATE_SERVICE_REQUEST',
);
export const createServiceFromDatasetSuccess = createAction<{
  globalProjectId: string;
  service: string;
}>('CREATE_SERVICE_SUCCESS');

export const createServiceFromDatasetFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('CREATE_SERVICE_FAILURE');

export const upgradeServiceRequest = createAction<{globalProjectId: string; service: string}>(
  'UPGRADE_SERVICE_REQUEST',
);
export const upgradeServiceSuccess = createAction<{globalProjectId: string; service: string}>(
  'UPGRADE_SERVICE_SUCCESS',
);
export const upgradeServiceFailure = createAction<{
  globalProjectId: string;
  service: string;
  error: string;
}>('UPGRADE_SERVICE_FAILURE');

export const migrateServicesRequest = createAction<{
  globalProjectId: string;
  services: IndexTargetType[];
}>('MIGRATE_SERVICES_REQUEST');
export const migrateServiceSuccess = createAction<{
  globalProjectId: string;
  target: string;
  operation: MigrationOperationResponse;
}>('MIGRATE_SERVICE_SUCCESS');
export const migrateServiceFailure = createAction<{
  globalProjectId: string;
  target: string;
  error: Error;
}>('MIGRATE_SERVICE_FAILURE');

export const getMigrationOperationRequest = createAction<{
  globalProjectId: string;
  operationId: string;
}>('GET_MIGRATION_OPERATION_REQUEST');
export const getMigrationOperationSuccess = createAction<{
  globalProjectId: string;
  operation: MigrationOperationResponse;
  target: string;
}>('GET_MIGRATION_OPERATION_SUCCESS');
export const getMigrationOperationFailure = createAction<{
  globalProjectId: string;
  operationId: string;
  error: Error;
}>('GET_MIGRATION_OPERATION_FAILURE');

export const listMigrationOperationsRequest = createAction<{globalProjectId: string}>(
  'LIST_MIGRATION_OPERATIONS_REQUEST',
);
export const listMigrationOperationsSuccess = createAction<{
  globalProjectId: string;
  operations: MigrationOperationResponse[];
}>('LIST_MIGRATION_OPERATIONS_SUCCESS');
export const listMigrationOperationsFailure = createAction<{globalProjectId: string; error: Error}>(
  'LIST_MIGRATION_OPERATIONS_FAILURE',
);

export const getImportOperationRequest = createAction<{
  globalProjectId: string;
  operationId: string;
}>('GET_IMPORT_OPERATION_REQUEST');
export const getImportOperationSuccess = createAction<{
  globalProjectId: string;
  operation: ImportOperationResponse;
  target: string;
}>('GET_IMPORT_OPERATION_SUCCESS');
export const getImportOperationFailure = createAction<{
  globalProjectId: string;
  operationId: string;
  error: Error;
}>('GET_IMPORT_OPERATION_FAILURE');

export const deleteServiceSuccess = createAction<{globalProjectId: string; service: string}>(
  'DELETE_SERVICE_SUCCESS',
);

export const clearPendingDataset = createAction<{globalProjectId: string; service: string}>(
  'CLEAR_PENDING_DATASET',
);

export const getServiceSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  serviceData: V4IndexInfoResponse;
}>('GET_SERVICE_SCCESS');
export const patchServiceSuccess = createAction<{
  globalProjectId: string;
  name: string;
  replicas: string;
  serviceData: V4IndexInfoResponse;
}>('PATCH_SERVICE_SUCCESS');

export function getService(api: ControllerApi, data: {globalProjectId: string; name: string}) {
  return (dispatch: Dispatch) => {
    return api
      .getService(data.name)
      .then((serviceData) => {
        dispatch(
          getServiceSuccess({
            serviceName: serviceData.name,
            globalProjectId: data.globalProjectId,
            serviceData,
          }),
        );
        return serviceData;
      })
      .catch(() => {});
  };
}

export function checkServiceTerminating(
  api: ControllerApi,
  data: {globalProjectId: string; name: string},
) {
  return (dispatch: Dispatch) => {
    return api.listServices().then((services) => {
      const names = 'indexes' in services ? services.indexes.map((index) => index.name) : services;
      if (!names.includes(data.name)) {
        dispatch(
          serviceFinishedTerminating({globalProjectId: data.globalProjectId, service: data.name}),
        );
      }
    });
  };
}

export function listServices(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listServicesRequest({globalProjectId: data.globalProjectId}));
    return api
      .listServices()
      .then((res) => {
        dispatch(
          listServicesSuccess({
            globalProjectId: data.globalProjectId,
            serviceNames: res.indexes.map((db) => db.name),
            services: res.indexes,
          }),
        );
        dispatch(projectLoaded({globalProjectId: data.globalProjectId}));
        return res;
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(projectNotLoaded({globalProjectId: data.globalProjectId}));
          setTimeout(() => {
            dispatch(listServices(api, data) as unknown as AnyAction);
          }, POLLING_INTERVAL);
          return null;
        }

        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to load indexes. ${decodeErrorMessage(error.message)}`,
          }),
        );

        dispatch(listServicesFailure({globalProjectId: data.globalProjectId, error}));
        return error;
      });
  };
}

export function createService(
  api: ControllerApi,
  data: {
    name: string;
    dimensions: number;
    pods: number;
    replicas: number;
    metric: string;
    podType: string;
    podSize: string;
    from_collection?: string;
    from_backup?: string;
    project: GlobalProject;
    provider: string;
    region: string;
    environment: string;
    isServerless: boolean;
    deletionProtection: boolean;
    dataset?: Vector[];
    skipRedirect?: boolean;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createServiceRequest({globalProjectId: data.project.id}));
    return api
      .createService(data)
      .then(() => {
        dispatch(
          createServiceSuccess({
            globalProjectId: data.project.id,
            service: data.name,
            dataset: data.dataset,
          }),
        );
        if (!data.skipRedirect) {
          dispatch(
            push(
              `/organizations/${data.project.organization_id}/projects/${data.project.id}/indexes/${data.name}`,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          createServiceFailure({
            globalProjectId: data.project.id,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Index "${data.name}" failed to create. ${decodeErrorMessage(error.message)}`,
          }),
        );
        return error;
      });
  };
}

export function createServiceFromDataset(
  api: ControllerApi,
  data: {
    project: GlobalProject;
    name: string;
    dimensions: number;
    metric: string;
    source_collection?: string;
    provider: string;
    region: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createServiceFromDatasetRequest({globalProjectId: data.project.id}));
    return api
      .createFromDataset(data)
      .then(() => {
        dispatch(
          createServiceFromDatasetSuccess({
            globalProjectId: data.project.id,
            service: data.name,
          }),
        );
        dispatch(
          push(
            `/organizations/${data.project.organization_id}/projects/${data.project.id}/indexes/${data.name}`,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          createServiceFromDatasetFailure({
            globalProjectId: data.project.id,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Index "${data.name}" failed to create. ${decodeErrorMessage(error.message)}`,
          }),
        );
        return error;
      });
  };
}

export function upgradeService(
  api: ControllerApi,
  data: {
    organizationId: string;
    globalProjectId: string;
    name: string;
    region: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(upgradeServiceRequest({globalProjectId: data.globalProjectId, service: data.name}));
    return api
      .upgradeService(data.name, data.region)
      .then(() => {
        dispatch(
          upgradeServiceSuccess({globalProjectId: data.globalProjectId, service: data.name}),
        );
        dispatch(
          push(
            `/organizations/${data.organizationId}/projects/${data.globalProjectId}/indexes/${data.name}`,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          upgradeServiceFailure({
            globalProjectId: data.globalProjectId,
            service: data.name,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Index ${data.name} failed to upgrade. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export function migrateServices(
  api: ControllerApi,
  data: {services: IndexTargetType[]; globalProjectId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      migrateServicesRequest({globalProjectId: data.globalProjectId, services: data.services}),
    );
    return Promise.all(
      data.services.map((service) => {
        return api
          .migrateService(service)
          .then((operationResponse) => {
            dispatch(
              migrateServiceSuccess({
                globalProjectId: data.globalProjectId,
                target: service.targetName,
                operation: operationResponse,
              }),
            );
          })
          .catch((err) => {
            dispatch(
              migrateServiceFailure({
                globalProjectId: data.globalProjectId,
                target: service.targetName,
                error: err,
              }),
            );
            dispatch(
              enqueNotification({
                type: NotificationTypes.ERROR,
                text: `Index ${
                  service.sourceName
                } failed to trigger a migration: ${decodeErrorMessage(err.message)}`,
              }),
            );
          });
      }),
    );
  };
}

export function getMigrationOperation(
  api: ControllerApi,
  data: {globalProjectId: string; operationId: string; targetIndex: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(getMigrationOperationRequest(data));
    return api
      .getMigrationOperation(data.operationId)
      .then((operationResponse) => {
        dispatch(
          getMigrationOperationSuccess({
            globalProjectId: data.globalProjectId,
            operation: operationResponse,
            target: data.targetIndex,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          getMigrationOperationFailure({
            globalProjectId: data.globalProjectId,
            operationId: data.operationId,
            error: err,
          }),
        );
      });
  };
}

export function listMigrationOperations(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listMigrationOperationsRequest(data));
    return api
      .listMigrationOperations()
      .then((res) => {
        dispatch(
          listMigrationOperationsSuccess({
            globalProjectId: data.globalProjectId,
            operations: res.operations,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          listMigrationOperationsFailure({globalProjectId: data.globalProjectId, error: err}),
        );
      });
  };
}

export function getImportOperation(
  api: ControllerApi,
  data: {globalProjectId: string; operationId: string; targetIndex: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(getImportOperationRequest(data));
    return api
      .getImportOperation(data.operationId)
      .then((operationResponse) => {
        dispatch(
          getImportOperationSuccess({
            globalProjectId: data.globalProjectId,
            operation: operationResponse,
            target: data.targetIndex,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          getImportOperationFailure({
            globalProjectId: data.globalProjectId,
            operationId: data.operationId,
            error: err,
          }),
        );
      });
  };
}

export function deleteService(api: ControllerApi, data: {globalProjectId: string; name: string}) {
  return (dispatch: Dispatch) => {
    return api
      .deleteService(data.name)
      .then((res) => {
        if (res.success) {
          dispatch(
            enqueNotification({
              type: NotificationTypes.SUCCESS,
              text: `Index ${data.name} is being terminated.`,
            }),
          );

          dispatch(
            deleteServiceSuccess({globalProjectId: data.globalProjectId, service: data.name}),
          );

          viewedIndexHistory.remove(data.globalProjectId, data.name);
        } else {
          dispatch(
            enqueNotification({
              type: NotificationTypes.ERROR,
              text: `Something went wrong. ${data.name} is not being terminated.`,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Index failed to terminate. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export function patchService(
  api: ControllerApi,
  data: {
    name: string;
    replicas: string;
    podType: string;
    deletionProtection: boolean;
    isServerless: boolean;
    globalProjectId: string;
  },
) {
  return (dispatch: Dispatch) => {
    return api
      .patchService(
        data.name,
        data.replicas,
        data.podType,
        data.deletionProtection,
        data.isServerless,
      )
      .then((res) => {
        dispatch(
          patchServiceSuccess({
            globalProjectId: data.globalProjectId,
            name: data.name,
            replicas: data.replicas,
            serviceData: res,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Index ${data.name} is updating to the new configuration.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Index failed to patch. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}
