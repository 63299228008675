import {Box, Chip, Divider, Paper, Typography} from '@mui/material';
import {PlanNameMapping, Plans} from '../../../../../constants';
import PlanInfo from '../../PlanInfo/PlanInfo';
import {type BillingPlanDetails} from '../../constants';
import ChangePlanButton from './ChangePlanButton/ChangePlanButton';

const styles = {
  container: {
    borderRadius: 1,
    p: 3,
    flex: 1,
    minWidth: 175,
  },
  tier: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
  },
  button: {
    mb: 1,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface BillingPlanCardProps {
  plan: BillingPlanDetails;
  showPlanInfo?: boolean;
}

const BillingPlanCard = ({plan, showPlanInfo}: BillingPlanCardProps) => (
  <Paper sx={styles.container}>
    <Box sx={styles.tier}>
      <Typography variant="h5" mb={1} color="text.primary">
        {PlanNameMapping[plan.tier]}
      </Typography>
      {plan.tier === Plans.FREE ? <Chip label="Free" color="success" variant="outlined" /> : null}
      {plan.tier === Plans.STANDARD ? (
        <Chip label="Popular" color="primary" variant="outlined" />
      ) : null}
    </Box>
    <Typography variant="body1" mt={1} mb={2} color="text.primary">
      {plan?.description}
    </Typography>
    <ChangePlanButton desiredPlan={plan.tier} variant="contained" sx={{mb: 1}} fullWidth />
    {plan.subtitle && (
      <Typography variant="h6" mt={1} mb={2} color="text.secondary">
        {plan?.subtitle}
      </Typography>
    )}
    {showPlanInfo && (
      <>
        <Divider sx={{mt: 2}} />
        <PlanInfo plan={plan} allowCollapse={false} />
      </>
    )}
  </Paper>
);

export default BillingPlanCard;
