import {createReducer} from '@reduxjs/toolkit';
import {
  createPrivateEndpointClearResponse,
  createPrivateEndpointFailure,
  createPrivateEndpointRequest,
  createPrivateEndpointSuccess,
  deletePrivateEndpointSuccess,
  listPrivateEndpointsFailure,
  listPrivateEndpointsRequest,
  listPrivateEndpointsSuccess,
} from '../actions/privateEndpointsActions';
import {type PrivateEndpointsItem} from '../types';
import {type AsyncDataState, initAsyncDataState} from './utils';

interface PrivateEndpointsState {
  items: Record<string, AsyncDataState<PrivateEndpointsItem | undefined>>;
  createEndpointResponse: AsyncDataState<{endpointCreated: boolean}>;
}

const initialState: PrivateEndpointsState = {
  items: {},
  createEndpointResponse: initAsyncDataState<{endpointCreated: boolean}>(undefined, false),
};

const privateEndpointsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(listPrivateEndpointsRequest, (state, action) => {
      state.items[action.payload.globalProjectId] = initAsyncDataState<PrivateEndpointsItem>(
        state.items[action.payload.globalProjectId]?.data,
        true,
      );
    })
    .addCase(listPrivateEndpointsSuccess, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.items[action.payload.globalProjectId].data = action.payload;
    })
    .addCase(listPrivateEndpointsFailure, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.items[action.payload.globalProjectId].error = action.payload.error;
    })
    .addCase(createPrivateEndpointRequest, (state) => {
      state.createEndpointResponse = initAsyncDataState<{endpointCreated: boolean}>(
        undefined,
        true,
      );
    })
    .addCase(createPrivateEndpointSuccess, (state, action) => {
      state.createEndpointResponse.loading = false;
      state.createEndpointResponse.data = {endpointCreated: action.payload.endpointCreated};
    })
    .addCase(createPrivateEndpointFailure, (state, action) => {
      state.createEndpointResponse = {
        loading: false,
        error: action.payload.error,
        data: undefined,
      };
    })
    .addCase(createPrivateEndpointClearResponse, (state) => {
      state.createEndpointResponse = initAsyncDataState<{endpointCreated: boolean}>(
        undefined,
        false,
      );
    })
    .addCase(deletePrivateEndpointSuccess, (state, action) => {
      const {globalProjectId} = action.payload;
      const endpoints = state.items[globalProjectId].data?.endpoints || [];

      state.items[globalProjectId].data = {
        globalProjectId,
        endpoints: endpoints.filter(({id}) => id !== action.payload.endpointId),
      };
    });
});

export default privateEndpointsReducer;
