import {Box} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useMemo, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {migrateServices} from '../../actions/serviceActions';
import {LocalStorageKeys} from '../../constants';
import {useControllerApi} from '../../hooks/use-api';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {useServiceSpecsByGlobalProject} from '../../selectors/services';
import {MODAL_WIDTH_SMALL} from '../../styles/theme';
import {canMigrate} from '../../utils/services';
import {localStorageSet} from '../../utils/storage';
import ConfigureIndex from './ConfigureIndex/ConfigureIndex';
import MigrationInProgress from './MigrationInProgress/MigrationInProgress';
import PrepareScreen from './PrepareScreen/PrepareScreen';
import SelectIndexesScreen from './SelectIndexScreen/SelectIndexScreen';
import {type IndexTargetType, type MigrationSchemaType} from './schema';

const styles = {
  container: {
    width: MODAL_WIDTH_SMALL,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

function ServerlessMigrationModal() {
  const [screen, setScreen] = useState(0);
  const [indexNum, setIndexNum] = useState(0);
  const onSelectIndexes = () => {
    setScreen(1);
  };
  const {control, setValue, handleSubmit} = useForm<MigrationSchemaType>({
    defaultValues: {
      indexes: [],
    },
  });
  const {fields: indexList, append, remove} = useFieldArray({control, name: 'indexes'});
  const goBack = () => {
    if (indexNum === 0) {
      setScreen((s) => s - 1);
    } else {
      setIndexNum((i) => i - 1);
    }
  };
  const selectedProject = useSelectedGlobalProject();
  const {setQueryData} = useControllerApi<{
    services: IndexTargetType[];
    globalProjectId: string;
  }>(selectedProject);
  const doContinue = (formData: MigrationSchemaType) => {
    if (indexNum + 1 === formData.indexes.length) {
      setQueryData({
        action: migrateServices,
        data: {services: formData.indexes, globalProjectId: selectedProject.id},
      });
      setScreen((s) => s + 1);
    } else {
      setIndexNum((i) => i + 1);
    }
  };
  const storageKey = `${LocalStorageKeys.POD_TO_SERVERLESS_ALERT}-${selectedProject.id}`;
  // TODO(@damargulis): While the tool is limited in scope, we will not show this modal.
  // Once the tool if fully available, flip this back to begin showing the modal
  // const hasViewed = localStorageGet(storageKey);
  const hasViewed = true;
  const doClose = () => {
    localStorageSet(storageKey, 'true');
    setScreen(-1);
  };
  const allIndexes = useServiceSpecsByGlobalProject(selectedProject.id);
  const filteredIndexes = useMemo(() => {
    return allIndexes.filter((index) => {
      return canMigrate(index);
    });
  }, [allIndexes]);
  const screens = [
    <SelectIndexesScreen
      append={append}
      remove={remove}
      selectedList={indexList}
      key={0}
      onSubmit={onSelectIndexes}
      availableIndexes={filteredIndexes}
    />,
    <PrepareScreen key={1} onContinue={() => setScreen(2)} />,
    <ConfigureIndex
      control={control}
      key={indexList[indexNum]?.id}
      onContinue={handleSubmit(doContinue)}
      goBack={goBack}
      indexNum={indexNum}
      indexList={indexList}
      setValue={setValue}
    />,
    <MigrationInProgress key={3} close={() => setScreen(-1)} project={selectedProject} />,
  ];
  return (
    <BaseModal
      open={screen >= 0 && filteredIndexes.length > 0 && !hasViewed}
      onClose={doClose}
      showCloseIcon
    >
      <Box sx={styles.container}>{screens[screen]}</Box>
    </BaseModal>
  );
}

export default ServerlessMigrationModal;
