import {DarkTheme, LightTheme} from '@pinecone-experience/theme';
import {THEME} from '../constants';
import {useAppTheme} from '../selectors/ui';

export const MODAL_WIDTH_SMALL = '500px';
export const MODAL_WIDTH_MEDIUM = '675px';

export const NAV_BAR_WIDTH = '245px';
export const APP_BAR_HEIGHT = '75px';
export const ALERT_BAR_HEIGHT = '50px';

export const NARROW_PAGE_WIDTH = '1040px';
export const WIDE_PAGE_WIDTH = '1380px';
export const APP_MIN_WIDTH = '1140px';

/**
 * theme.spacing(6): 48px
 */
export const CONTENT_PADDING = 6;
/**
 * theme.spacing(3): 24px
 */
export const GUTTER = CONTENT_PADDING / 2;

function useTheme() {
  const mode = useAppTheme();

  switch (mode) {
    case THEME.DARK:
      return DarkTheme;
    case THEME.LIGHT:
    default:
      return LightTheme;
  }
}
export default useTheme;
