import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Switch, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {Controller, useForm} from 'react-hook-form';
import {editSsoConfig, setupSsoConfig} from '../../../../../../actions/organizationActions';
import {useDashboardApi} from '../../../../../../hooks/use-api';
import {type SsoConnectionConfig} from '../../../../../../types';
import {CreateConfigForm, type CreateConfigType} from '../utilities/schema';
import DeleteSsoConfigButton from './DeleteSsoConfigButton/DeleteSsoConfigButton';

type SsoConfigFormProps = {
  goNext: () => void;
  orgId: string;
  connection?: SsoConnectionConfig;
  loading: boolean;
};

function SsoConfigForm({goNext, connection, orgId, loading}: SsoConfigFormProps) {
  const {
    handleSubmit,
    control,
    formState: {isValid},
  } = useForm<CreateConfigType>({
    defaultValues: {
      signInEndpoint: '',
      domain: '',
      cert: '',
      enforceSsoAuthentication: false,
    },
    values: connection,
    resolver: zodResolver(CreateConfigForm),
  });
  const {setQueryData} = useDashboardApi<{organizationId: string; connection: CreateConfigType}>();
  const onSubmit = (form: CreateConfigType) => {
    const action = connection ? editSsoConfig : setupSsoConfig;
    setQueryData({
      action,
      data: {
        organizationId: orgId,
        connection: {
          ...connection,
          ...form,
        },
      },
    });
    goNext();
  };
  return (
    <Box>
      <Typography variant="body2" py={3}>
        {`Access these details from your identity provider's configuration page.`}
      </Typography>
      <TextInput
        label="Login URL"
        fullWidth
        name="signInEndpoint"
        control={control}
        placeholder="e.g., https://example.com/login"
      />
      <TextInput
        label="Email Domain(s)"
        fullWidth
        name="domain"
        control={control}
        placeholder="e.g., example.com"
      />
      <TextInput
        label="Certificate"
        fullWidth
        name="cert"
        control={control}
        placeholder="Paste your certificate here"
        multiline
        rows={5}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={6}>
        <Box>
          <Typography fontWeight={500} gutterBottom>
            Enforce SSO authentication for all users
          </Typography>
          <Typography variant="body2" color="textSecondary">
            This will make SSO the only way to access this organization and disable invites for
            external users.
          </Typography>
        </Box>
        <Controller
          name="enforceSsoAuthentication"
          control={control}
          render={({field}) => {
            return (
              <Switch
                checked={field.value}
                onChange={field.onChange}
                inputProps={{'aria-label': 'Toggle enforce SSO Authentication Enforcement'}}
              />
            );
          }}
        />
      </Box>
      <Box display="flex" sx={{justifyContent: 'flex-end', width: '100%', mt: 2, mb: 0, gap: 1}}>
        {connection && <DeleteSsoConfigButton orgId={orgId} />}
        <Button
          disabled={!isValid || loading}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {`${connection ? 'Update' : 'Enable'} SSO`}
        </Button>
      </Box>
    </Box>
  );
}

export default SsoConfigForm;
