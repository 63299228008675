import {ResponsiveLine} from '@nivo/line';
import {useMemo, useState} from 'react';
import {requestLatencyQuery} from '../../../../../actions/prometheusActions';
import ChartLayout from '../../../../../components/chart/ChartLayout/ChartLayout';
import ChartLegend from '../../../../../components/chart/ChartLegend/ChartLegend';
import {
  type RequestType,
  requestTypes,
  useFormatDateTick,
  useGetYAxisLabel,
  useNivoTheme,
} from '../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../hooks/use-api';
import {useLatencyMetrics} from '../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {useServiceByGlobalProject} from '../../../../../selectors/services';
import {type PrometheusLatencyResponse} from '../../../../../types';
import {chartAxisBottom, chartAxisY, chartMargin, timeXScale} from '../../../../../utils/chart';
import {getSpecs} from '../../../../../utils/services';
import FilterRequestTypes from '../FilterRequestTypes/FilterRequestTypes';
import LatencyTooltip from './LatencyTooltip/LatencyTooltip';
import {type QuantileLabel, getColor, quantilesNameMap} from './utilities/LatencyUtils';

type ChartProps = {
  formattedData: PrometheusLatencyResponse;
  colors: string[];
};

function Chart({formattedData, colors}: ChartProps) {
  const nivoTheme = useNivoTheme();
  const {yAxisMin, yAxisMax} = useGetYAxisLabel(formattedData);
  const formatTick = useFormatDateTick({formattedData});
  return (
    <ResponsiveLine
      theme={nivoTheme}
      colors={colors}
      data={formattedData}
      margin={chartMargin}
      xScale={timeXScale}
      yScale={{type: 'linear', min: yAxisMin, max: yAxisMax}}
      gridYValues={5}
      curve="step"
      lineWidth={2}
      areaOpacity={1}
      enablePoints={false}
      enableGridX={false}
      enableSlices="x"
      axisBottom={{
        ...chartAxisBottom,
        format: formatTick,
      }}
      axisLeft={chartAxisY({unit: 'ms'})}
      useMesh
      sliceTooltip={LatencyTooltip}
    />
  );
}

function LatencyChart({service, start, end}: {service: string; start: string; end: string}) {
  const projectId = useSelectedGlobalProjectId();
  const serviceInfo = useServiceByGlobalProject(projectId || '', service);
  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    requestLatencyQuery,
    projectId || '',
    service,
    start,
    end,
    !!serverlessSpec,
  );
  const metrics = useLatencyMetrics(service);
  const [selectedRequestTypes, setSelectedRequestTypes] = useState(() => [requestTypes[0]]);
  const selectedRequestTypeNames = selectedRequestTypes.map((type) => type.name);
  const formattedData = useMemo(() => {
    const data = metrics?.data || [];
    return data
      .filter(
        (item) =>
          item.quantile in quantilesNameMap &&
          selectedRequestTypeNames.some((x) => item.id.includes(x)),
      )
      .sort((a, b) => Number(a.quantile) - Number(b.quantile));
  }, [metrics?.data, selectedRequestTypeNames]);

  const {colors, legend} = useMemo(() => {
    const legendList: RequestType[] = [];
    const colorsList = formattedData.map((item) => {
      const requestType = requestTypes.find((type) => item.request_type === type.name) || {
        color: '',
      };
      const legendItem = {
        name: quantilesNameMap[item.quantile as QuantileLabel],
        color: getColor(requestType.color, item.quantile),
      };
      if (!legendList.includes(legendItem)) {
        legendList.push(legendItem);
      }
      return getColor(requestType.color, item.quantile);
    });

    return {colors: colorsList, legend: legendList};
  }, [formattedData]);

  return (
    <ChartLayout
      title="Request Latency"
      error={metrics?.error}
      chart={<Chart formattedData={formattedData} colors={colors} />}
      filters={
        <FilterRequestTypes
          selectedRequestTypes={selectedRequestTypes}
          setSelectedRequestTypes={setSelectedRequestTypes}
          exclusive
        />
      }
      tooltip="Request latency by operation"
      legend={<ChartLegend keys={legend} />}
      loading={metrics?.loading}
    />
  );
}

export default LatencyChart;
