import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Divider, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {suggestModel} from '../../../../../actions/userActions';
import ModalForm from '../../../../../components/ModalForm';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {MODAL_WIDTH_MEDIUM} from '../../../../../styles/theme';
import schema, {type ModelSuggestionData} from './ModelSuggestionSchema';

type ModelSuggestionFormProps = {
  open: boolean;
  close: () => void;
  goBack: () => void;
};

function ModelSuggestionForm({open, close, goBack}: ModelSuggestionFormProps) {
  const {control, handleSubmit, reset} = useForm({
    defaultValues: {
      provider: '',
      model: '',
      usecase: '',
    },
    resolver: zodResolver(schema),
  });
  const {setQueryData} = useDashboardApi<ModelSuggestionData>();
  const submitSuggestion = (data: ModelSuggestionData) => {
    setQueryData({
      action: suggestModel,
      data,
    });
    close();
    reset();
  };
  return (
    <ModalForm
      hideBackdrop
      open={open}
      close={close}
      title="Suggest a model"
      titleSx={{color: 'primary.dark'}}
      submitText="Submit suggestion"
      isValid
      width={MODAL_WIDTH_MEDIUM}
      onSubmit={handleSubmit(submitSuggestion)}
      form={
        <Box>
          <Typography
            pb={2}
            role="doc-subtitle"
          >{`Let us know what you're intending to build with your index`}</Typography>
          <TextInput
            required
            label="Provider"
            name="provider"
            control={control}
            placeholder="ex. OpenAI, Cohere, HuggingFace"
            fullWidth
          />
          <Box height={16} />
          <TextInput
            required
            label="Model name"
            name="model"
            control={control}
            placeholder="ex. text-embedding-ada-002"
            fullWidth
          />
          <Box height={16} />
          <TextInput
            name="usecase"
            label="What are you looking to build?"
            control={control}
            placeholder="ex. semantic search, image search, chatbot agent..."
            fullWidth
          />
          <Divider sx={{mt: 4, mx: -4}} />
        </Box>
      }
      secondaryButton={
        <Button color="secondary" onClick={goBack} sx={{ml: 1, mr: 3}}>
          Go back
        </Button>
      }
    />
  );
}

export default ModelSuggestionForm;
