import {Box, Card, Link, Typography} from '@mui/material';
import Switch from '@pinecone-experience/timber/Inputs/Switch';
import {type Control, useWatch} from 'react-hook-form';
import {PUBLIC_DOCS} from '../../../constants';

type FormValuesWithDeletionProtection = {
  deletionProtection: boolean;
};

type DeletionProtectionControlProps<T extends FormValuesWithDeletionProtection> = {
  control: T extends FormValuesWithDeletionProtection ? Control<T> : never;
};

function DeletionProtectionControl<T extends FormValuesWithDeletionProtection>({
  control,
}: DeletionProtectionControlProps<T>) {
  const isProtected = useWatch({control, name: 'deletionProtection'});
  return (
    <Card sx={{color: isProtected ? '' : 'action.disabled'}}>
      <Box display="flex" color="inherit" justifyContent="space-between">
        <Typography color="inherit" variant="h6">
          Deletion protection
        </Typography>
        <Switch hideError control={control} name="deletionProtection" />
      </Box>
      <Typography color="inherit">
        When enabled, prevents users from accidentally deleting this index. Disable protection to
        allow deletion.{' '}
        <Link
          color="inherit"
          href={PUBLIC_DOCS.DELETION_PROTECTION}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </Link>
        .
      </Typography>
    </Card>
  );
}

export default DeletionProtectionControl;
