import {useEffect} from 'react';
import {
  getBillingDetails,
  updateBillingDetails,
} from '../../../../../../actions/organizationActions';
import type {
  GetBillingDetailsData,
  UpdateBillingDetailsData,
} from '../../../../../../actions/types/organizationActions.types';
import PaymentForm from '../../../../../../components/billing/payment/PaymentForm/PaymentForm';
import usePaymentForm from '../../../../../../components/billing/payment/PaymentForm/usePaymentForm';
import PaymentModal from '../../../../../../components/billing/payment/PaymentModal/PaymentModal';
import {useDashboardApi} from '../../../../../../hooks/use-api';
import {useBillingDetails} from '../../../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../../../selectors/params';

type EditBillingModalProps = {
  open: boolean;
  onClose: () => void;
};

function EditBillingModal({open, onClose}: EditBillingModalProps) {
  const {setQueryData: setGetBillingDetailsQuery} = useDashboardApi<GetBillingDetailsData>();
  const {setQueryData: setUpdateBillingDetailsQueryData} =
    useDashboardApi<UpdateBillingDetailsData>();
  const {data: billingDetails} = useBillingDetails();

  const selectedOrgId = useSelectedOrganizationId();
  useEffect(() => {
    setGetBillingDetailsQuery({
      action: getBillingDetails,
      data: {
        organizationId: selectedOrgId,
      },
    });
  }, [setGetBillingDetailsQuery, selectedOrgId]);

  const submit = async (queryData: UpdateBillingDetailsData) => {
    setUpdateBillingDetailsQueryData({
      action: updateBillingDetails,
      data: queryData,
    });
    onClose();
  };

  const paymentFormProps = usePaymentForm({
    currentBillingDetails: billingDetails,
    onCancel: onClose,
  });
  const {cancel} = paymentFormProps;

  return (
    <PaymentModal open={open} onClose={cancel} title="Edit Billing Contact Information">
      <PaymentForm
        includeCancel
        onSuccess={submit}
        show
        submitText="Update"
        {...paymentFormProps}
      />
    </PaymentModal>
  );
}

export default EditBillingModal;
