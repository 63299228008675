import {type Params} from 'react-router-dom';
import {z} from 'zod';
import {Providers} from '../../constants';
import {type Marketplace} from '../../types';

export interface ValidateMarketplaceSetupParams {
  params: Readonly<Params<string>>;
  setup_token?: string;
}

const MarketplaceSetupSchema = z.object({
  params: z.object({
    marketplaceType: z.nativeEnum(Providers, {errorMap: () => ({message: 'marketplaceType'})}),
    marketplaceId: z.string().nonempty({message: 'marketplaceId'}),
  }),
  setup_token: z.string().nonempty({message: 'setup_token'}),
});

export function validateMarketplaceSetup({params, setup_token}: ValidateMarketplaceSetupParams) {
  const validation = MarketplaceSetupSchema.safeParse({params, setup_token});

  if (!validation.success) {
    const invalidFields = validation.error.errors.map((e) => e.message);
    const errorMessage = `Invalid fields: ${invalidFields.join(', ')}`;
    return {validationError: errorMessage};
  }

  const {
    params: {marketplaceType, marketplaceId},
    setup_token: setupToken,
  } = validation.data;

  return {marketplaceType, marketplaceId, setupToken};
}

export function getIdentifierFromMarketplaceRecord(marketplaceRecord: Marketplace) {
  if (!marketplaceRecord) {
    return null;
  }
  if ('aws_customer_id' in marketplaceRecord) {
    return marketplaceRecord.aws_customer_id;
  }
  if ('google_user_id' in marketplaceRecord) {
    return marketplaceRecord.google_user_id;
  }
  if ('azure_subscription_id' in marketplaceRecord) {
    return marketplaceRecord.azure_subscription_id;
  }
  return null;
}
