import {Box} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import BillingPlans from '../../../components/billing/plans/BillingPlans/BillingPlans';
import {useCurrentSubscription} from '../../../selectors/billing';
import {useIsSelectedOrganizationSubscribed} from '../../../selectors/organizations';

const PlansPage = () => {
  const currentSub = useCurrentSubscription();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  return (
    <LoadingContainer showChildrenInBackground loading={isSubscribed && currentSub.loading}>
      <Box mt={6}>
        <BillingPlans />
      </Box>
    </LoadingContainer>
  );
};

export default PlansPage;
