import PaginationControls from '@pinecone-experience/timber/PaginationControls';
import {usePagination} from '@pinecone-experience/timber/PaginationControls/hooks';
import {useMultiSelect} from '@pinecone-experience/timber/Table/hooks';
import {listBackups} from '../../../actions/backupActions';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useControllerApi} from '../../../hooks/use-api';
import {useBackupsByIndexId} from '../../../selectors/backups';
import {useSelectedIndexId} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {useIndexById} from '../../../selectors/services';
import BackupDetailsHeader from './BackupDetailsHeader/BackupDetailsHeader';
import BackupsDetailsFooter from './BackupsDetailsFooter/BackupsDetailsFooter';
import BackupsDetailsTable from './BackupsDetailsTable/BackupsDetailsTable';

function BackupsDetailPage() {
  const indexId = useSelectedIndexId();
  const project = useSelectedGlobalProject();
  useControllerApi(project, listBackups, {globalProjectId: project.id});
  const backups = useBackupsByIndexId(project.id, indexId || '');
  const index = useIndexById(project.id, indexId || '');

  const paginationState = usePagination(backups, ['backup', 'backups'], 10);
  const {itemsToShow} = paginationState;
  const multiSelectState = useMultiSelect(itemsToShow);
  const indexName = index?.name || backups[0]?.index_name;
  return (
    <Page
      header={
        <PageHeader
          title={`${indexName} backups`}
          subtitle={!index ? 'Index deleted' : ''}
          titleSx={!index ? {color: 'secondary.main'} : {}}
          breadcrumb={{text: 'Back to all backups'}}
        />
      }
      footer={<BackupsDetailsFooter multiSelectState={multiSelectState} project={project} />}
    >
      <BackupDetailsHeader sourceIndex={index} backups={backups} />
      <BackupsDetailsTable
        backups={itemsToShow}
        project={project}
        sourceIndex={index}
        multiSelectState={multiSelectState}
      />
      <PaginationControls paginationState={paginationState} includeSizeControls />
    </Page>
  );
}

export default BackupsDetailPage;
