import {type PaletteOptions, type TypographyVariantsOptions} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import PineconeShadows from './PineconeShadows';

const palette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#3C72FF', // Cobalt New
  },
  secondary: {
    main: '#738FAB', // Oxford
  },
  background: {
    default: '#12121A', // Dark Grey
    paper: '#000', // Black
  },
};

const typography: TypographyVariantsOptions = {
  h1: {
    fontFamily: 'Space Mono',
    fontSize: 54,
  },
  h2: {
    fontFamily: 'Space Mono',
    fontSize: 34,
  },
  h3: {
    fontFamily: 'Space Mono',
    fontSize: 19,
    fontWeight: 'bold',
  },
  h4: {
    fontFamily: 'Space Mono',
    fontSize: 16,
    fontWeight: 'bold',
  },
  button: {
    fontFamily: 'Space Mono',
    fontSize: 16,
    letterSpacing: '.1rem',
    fontWeight: 800,
  },
  caption: {
    fontFamily: 'Space Mono',
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 'bold',
  },
};

const AdminTheme = createTheme({
  palette,
  typography,
  shadows: PineconeShadows.DARK,
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

export default AdminTheme;
