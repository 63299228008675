import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {
  useNavigateToGlobalProject,
  useNavigateToSelectProject,
} from '../../../../../hooks/navigation';
import {useSelectedOrganization} from '../../../../../selectors/organizations';
import {
  useGlobalProjectsFromSelectedOrganization,
  useSelectedGlobalProject,
} from '../../../../../selectors/projects';
import {getRecentProjectIdsToDisplay} from '../../../../../utils/recent-items';
import NavButton from '../NavButton/NavButton';
import NavSelector from '../NavSelector/NavSelector';

const PROJECTS_LIMIT = 3;

function ProjectSelector() {
  const {go: goToProject} = useNavigateToGlobalProject();
  const selectedOrg = useSelectedOrganization();
  const projectsInOrg = useGlobalProjectsFromSelectedOrganization();
  const selectedProject = useSelectedGlobalProject();
  const {go: goToSelectProject} = useNavigateToSelectProject(selectedOrg?.id || '');
  const recentProjects = getRecentProjectIdsToDisplay(
    selectedOrg?.id || '',
    projectsInOrg,
    PROJECTS_LIMIT,
  );

  const selectorItems = recentProjects.map((projectId) => ({
    id: projectId,
    name: projectsInOrg.find(({id}) => id === projectId)?.name || '',
    onClick: () => goToProject(selectedOrg.id, projectId),
    icon: <FolderOutlinedIcon fontSize="small" color="secondary" />,
  }));

  if (!selectedOrg) {
    return null;
  }

  if (!selectedProject) {
    return (
      <NavButton
        data-testid="all-projects-nav-link"
        onClick={goToSelectProject}
        startIcon={<FolderOutlinedIcon color="secondary" />}
        endIcon={<KeyboardArrowRightOutlinedIcon />}
      >
        All Projects
      </NavButton>
    );
  }

  return (
    <NavSelector
      id="projects"
      startIcon={<FolderOutlinedIcon color="secondary" />}
      title="Projects"
      placeholder="All Projects"
      items={selectorItems}
      selectedItem={selectedProject}
      onViewAll={goToSelectProject}
    />
  );
}

export default ProjectSelector;
