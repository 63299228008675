import {IndexState} from '../../constants';
import {usePollAssistantStatus} from '../../hooks/assistants';
import {useIndexStatusColorMap} from '../../hooks/colorMaps';
import {useSelectedGlobalProject} from '../../selectors/projects';
import StatusBox from '../StatusBox';

type AssistantStatusBoxProps = {
  assistantName: string;
};

function AssistantStatusBox({assistantName}: AssistantStatusBoxProps) {
  const selectedProject = useSelectedGlobalProject();
  const assistantStatus = usePollAssistantStatus(assistantName, selectedProject);
  const color = useIndexStatusColorMap(assistantStatus || IndexState.UNKNOWN);
  return <StatusBox statusText={assistantStatus} color={color} />;
}

export default AssistantStatusBox;
