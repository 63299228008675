import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/notificationActions';

interface NotifcationState {
  notifications: {
    type: actions.NotificationTypes;
    text: string;
  }[];
}

const initialState = {
  notifications: [],
} as NotifcationState;

const notifyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.enqueNotification, (state, action) => {
      state.notifications.push(action.payload);
    })
    .addCase(actions.clearNotification, (state) => {
      state.notifications.splice(0, 1);
    });
});

export default notifyReducer;
