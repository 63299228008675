import {Box, Typography} from '@mui/material';
import {useEffect, useMemo} from 'react';
import {Navigate} from 'react-router-dom';
import {describeIndexStats} from '../../../actions/dataActions';
import StyledTabs from '../../../components/StyledTabs';
import Page from '../../../components/layout/Page/Page';
import {IndexDetailTab} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import {useInternetAccessEntry} from '../../../hooks/internet-access';
import {useNavigateToIndexListGlobal} from '../../../hooks/navigation';
import {
  useHasDataPlane,
  useHasMetrics,
  useHasNamespaces,
  useIsFreeIndex,
} from '../../../hooks/specUtils';
import {useDataPlane} from '../../../hooks/use-api';
import {useInterval} from '../../../hooks/utils';
import {useIndexStatsByGlobalProject} from '../../../selectors/explorer';
import {useNetworkAllowlist} from '../../../selectors/networkAllowlists';
import {
  useIsSelectedOrganizationSubscribed,
  useSelectedOrganization,
} from '../../../selectors/organizations';
import {useSelectedService} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {
  useServiceByGlobalProject,
  useServicesAreLoadingByGlobalProject,
  useServicesByGlobalProject,
} from '../../../selectors/services';
import {type IndexStatsDescription} from '../../../types';
import {canImport, getSpecs, viewedIndexHistory} from '../../../utils/services';
import IndexDetailHeader from './IndexDetailHeader/IndexDetailHeader';
import IndexExplorer from './IndexExplorer/IndexExplorer';
import {type IndexExplorerState} from './IndexExplorer/utilities/utils';
import IndexImports from './IndexImports/IndexImports';
import IndexMetrics from './IndexMetrics/IndexMetrics';
import IndexNamespaceList from './IndexNamespaceList/IndexNamespaceList';
import IndexOverview from './IndexOverview/IndexOverview';
import LegacyIndexMetrics from './LegacyIndexMetrics/LegacyIndexMetrics';
import MigrationSuccessModal from './MigrationSuccessModal/MigrationSuccessModal';

const POLLING_INTERVAL = 5000;

type DetailTab = {
  value: IndexDetailTab;
  getComponent: (props: {
    indexExplorerState: IndexExplorerState;
    internetDisabled?: boolean;
    isServerless: boolean;
  }) => React.ReactNode;
  getLabel?: (props: {indexStats?: IndexStatsDescription}) => string;
  unreachable?: boolean;
};
const BrowserTab: DetailTab = {
  value: IndexDetailTab.BROWSER,
  getComponent: (props) => (
    <IndexExplorer
      internetDisabled={props.internetDisabled}
      indexExplorerState={props.indexExplorerState}
    />
  ),
};
const LegacyMetricsTab: DetailTab = {
  value: IndexDetailTab.METRICS,
  getComponent: (props) => (
    <LegacyIndexMetrics
      service={props.indexExplorerState.service}
      project={props.indexExplorerState.project}
    />
  ),
};
const LegacyMetricsComparisonTab: DetailTab = {
  value: IndexDetailTab.METRICS_CLASSIC,
  getLabel: () => 'METRICS (CLASSIC)',
  getComponent: (props) => (
    <LegacyIndexMetrics
      service={props.indexExplorerState.service}
      project={props.indexExplorerState.project}
    />
  ),
};
const MetricsTab: DetailTab = {
  value: IndexDetailTab.METRICS,
  getComponent: (props) => (
    <IndexMetrics
      service={props.indexExplorerState.service}
      projectId={props.indexExplorerState.project?.id || ''}
      isServerless={props.isServerless}
    />
  ),
};
const NamespacesTab: DetailTab = {
  value: IndexDetailTab.NAMESPACES,
  getComponent: (props) => (
    <IndexNamespaceList
      internetDisabled={props.internetDisabled}
      indexExplorerState={props.indexExplorerState}
    />
  ),
  getLabel: (props) => {
    if (!props.indexStats?.namespaces) {
      return `NAMESPACES`;
    }
    return `NAMESPACES (${Object.keys(props.indexStats.namespaces).length})`;
  },
};
const ImportsTab: DetailTab = {
  value: IndexDetailTab.IMPORTS,
  getComponent: (props) => (
    <IndexImports
      indexName={props.indexExplorerState.service}
      project={props.indexExplorerState.project}
      host={props.indexExplorerState.host}
    />
  ),
};

function IndexDetailPage() {
  const selectedOrg = useSelectedOrganization();
  const selectedService = useSelectedService() || '';
  const selectedProject = useSelectedGlobalProject();
  const servicesAreLoading = useServicesAreLoadingByGlobalProject(selectedProject?.id);
  const availbleServices = useServicesByGlobalProject(selectedProject?.id);
  useEffect(() => {
    viewedIndexHistory.append(selectedProject?.id, selectedService);
  }, [selectedProject, selectedService]);
  const serviceInfo = useServiceByGlobalProject(selectedProject?.id, selectedService);
  const {go: goToIndexList, path: indexListPath} = useNavigateToIndexListGlobal(
    selectedOrg.id,
    selectedProject.id,
  );
  const {setQueryData} = useDataPlane(selectedProject, selectedService, serviceInfo?.host || '');
  useInterval(() => {
    setQueryData({action: describeIndexStats});
  }, POLLING_INTERVAL);
  const indexStats = useIndexStatsByGlobalProject(selectedProject?.id, selectedService);
  const isFree = useIsFreeIndex(serviceInfo);
  const hasDataPlane = useHasDataPlane(serviceInfo);
  const hasMetrics = useHasMetrics(serviceInfo);
  const hasNamespaces = useHasNamespaces(serviceInfo);
  const internetAllowlist = useNetworkAllowlist(selectedProject?.id);
  const {access: internetAccess} = useInternetAccessEntry(internetAllowlist);
  const isServerless = !!getSpecs(serviceInfo)?.serverlessSpec;
  const internetAccessDisabled = isServerless && internetAccess === 'disabled';
  const enableMetricsRevamp = useFlag(Flags.ENABLE_METRICS_REVAMP);
  const enableMetricsRevampComparison = useFlag(Flags.ENABLE_METRICS_REVAMP_COMPARISON);
  const isOrgSubscribed = useIsSelectedOrganizationSubscribed();
  const showImportsTab =
    useFlag(Flags.SHOW_BULK_IMPORT) && isOrgSubscribed && canImport(serviceInfo);

  const tabs = useMemo(() => {
    const tabsToShow = [];
    if (hasDataPlane) {
      tabsToShow.push(BrowserTab);
    }
    if (hasMetrics) {
      if (enableMetricsRevamp) {
        tabsToShow.push(MetricsTab);
        if (enableMetricsRevampComparison) {
          tabsToShow.push(LegacyMetricsComparisonTab);
        }
      } else {
        tabsToShow.push(LegacyMetricsTab);
      }
    }
    if (hasDataPlane && hasNamespaces) {
      tabsToShow.push(NamespacesTab);
    }

    if (showImportsTab) {
      tabsToShow.push(ImportsTab);
    }

    return tabsToShow.map((tab) => {
      return {
        value: tab.value,
        component: tab.getComponent({
          indexExplorerState: {
            service: selectedService,
            project: selectedProject,
            organization: selectedOrg,
            host: serviceInfo?.host || '',
            dimensions: parseInt(serviceInfo?.dimension, 10) || 0,
            indexStats,
          },
          internetDisabled: internetAccessDisabled,
          isServerless,
        }),
        label: tab.getLabel?.({indexStats}),
      };
    });
  }, [
    selectedService,
    selectedProject,
    selectedOrg,
    serviceInfo,
    indexStats,
    hasNamespaces,
    hasDataPlane,
    hasMetrics,
    internetAccessDisabled,
    enableMetricsRevamp,
    enableMetricsRevampComparison,
    isServerless,
    showImportsTab,
  ]);

  if (!servicesAreLoading && !availbleServices.includes(selectedService)) {
    return <Navigate to={indexListPath} />;
  }
  return (
    <Page
      flexChildren={false}
      header={<IndexDetailHeader goToIndexList={goToIndexList} service={selectedService} />}
    >
      <IndexOverview
        service={selectedService}
        organization={selectedOrg}
        serviceInfo={serviceInfo}
        serviceStats={indexStats}
        isFree={isFree}
      />
      <Box padding={1} />
      <StyledTabs
        tabs={tabs}
        idPrefix="index-detail"
        sticky={false}
        defaultPath={internetAccessDisabled ? 'metrics' : 'browser'}
      />
      {tabs.length === 0 && (
        <Typography variant="h4" textAlign="center" py={4}>
          No data plane access
        </Typography>
      )}
      <MigrationSuccessModal serviceInfo={serviceInfo} />
    </Page>
  );
}

export default IndexDetailPage;
