import {Add} from '@mui/icons-material';
import {Button, type ButtonProps} from '@mui/material';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import type React from 'react';

type ClickHandler = {href: string; onClick?: undefined} | {href?: undefined; onClick: () => void};
type CreateButtonProps = ClickHandler & {
  text: string;
  disabled?: boolean;
  includePlus?: boolean;
  customId?: string;
  variant?: ButtonProps['variant'];
  tooltip?: React.ReactNode;
  color?: ButtonProps['color'];
  dataCy?: string;
};

function CreateButton({
  href,
  onClick,
  text,
  disabled = false,
  includePlus = true,
  customId = undefined,
  variant = 'contained',
  tooltip = null,
  color = 'primary',
  dataCy = '',
}: CreateButtonProps) {
  return (
    <Tooltip title={tooltip}>
      <Button
        href={href}
        data-testid="create-button"
        data-cy={dataCy}
        id={customId}
        color={color}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        sx={{pointerEvents: 'all !important'}}
        startIcon={includePlus && <Add />}
      >
        {text}
      </Button>
    </Tooltip>
  );
}

export default CreateButton;
