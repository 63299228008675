import {Box, type OutlinedInputProps, Typography} from '@mui/material';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useDispatch} from 'react-redux';
import {patchUserParams} from '../../actions/userActions';
import Image from '../Utilities/Image/Image';
import {LANGUAGE_CONTENT, LANGUAGE_OPTIONS_LIST, type LanguageTypes} from './constants';
import {useSelectedLanguage} from './utils';

interface CodingLanguageSelectorProps {
  label?: string;
  languageOptions?: LanguageTypes[];
  inputComponent?: 'SelectInput' | 'SelectBoxInput';
  sx?: OutlinedInputProps['sx'];
}

const LanguageBox = (language: LanguageTypes) => {
  return (
    <Box>
      <Image src={LANGUAGE_CONTENT[language].icon} width={30} height={30} sx={{m: 3}} />
      <Typography>{LANGUAGE_CONTENT[language].display}</Typography>
    </Box>
  );
};

function CodingLanguageSelector({
  label,
  languageOptions = LANGUAGE_OPTIONS_LIST,
  inputComponent = 'SelectInput',
  sx,
}: CodingLanguageSelectorProps) {
  const selectedLanguage = useSelectedLanguage();
  const dispatch = useDispatch();
  const handleLanguageChange = (language: LanguageTypes) => {
    dispatch(
      patchUserParams({
        params: {
          language,
        },
      }),
    );
  };

  if (inputComponent === 'SelectBoxInput') {
    return (
      <SelectBoxInput
        id="select-language"
        label={label}
        layout="grid"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        options={languageOptions.map((option) => ({
          value: option,
          component: () => LanguageBox(option),
        }))}
      />
    );
  }

  return (
    <SelectInput
      id="select-language"
      label={label}
      value={selectedLanguage}
      onChange={handleLanguageChange}
      options={languageOptions.map((option) => ({
        value: option,
        label: LANGUAGE_CONTENT[option].display,
      }))}
      sx={{width: 125, mb: -2, ...(sx as object)}}
    />
  );
}

export default CodingLanguageSelector;
