import {Box, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {fetchSupport} from '../../../../actions/organizationActions';
import SupportPlans from '../../../../components/billing/plans/SupportPlans/SupportPlans';
import {Plans} from '../../../../constants';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useCurrentSubscription} from '../../../../selectors/billing';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useSelectedOrganizationPlan,
} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';

const SupportPlansPage = () => {
  const currentSub = useCurrentSubscription();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const isOwner = useIsOwnerOfOrganization();
  const plan = useSelectedOrganizationPlan();
  const selectedOrgId = useSelectedOrganizationId();
  const shouldFetchSupport = isOwner && plan !== Plans.INTERNAL;
  useDashboardApi(fetchSupport, {organizationId: selectedOrgId || ''}, shouldFetchSupport);

  if (!isOwner) {
    return (
      <Alert
        title="Unauthorized"
        description="You must be an owner of the organization to view support plans"
      />
    );
  }

  return (
    <LoadingContainer showChildrenInBackground loading={isSubscribed && currentSub.loading}>
      <Box mt={6}>
        <Box mb={3}>
          <Typography variant="h5">Manage your support plan</Typography>
          {!isSubscribed && (
            <Typography variant="body1" pt={1}>
              Note: Upgrading your support plan requires first upgrading to a Standard Pay-As-You-Go
              plan. View the benefits here.
            </Typography>
          )}
        </Box>
        <SupportPlans />
      </Box>
    </LoadingContainer>
  );
};

export default SupportPlansPage;
