import {Box, Button, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useMigrationOperations} from '../../../selectors/services';
import {type GlobalProject} from '../../../types';
import OperationDisplay from '../OperationDisplay/OperationDisplay';

type MigrationInProgressProps = {
  close: () => void;
  project: GlobalProject;
};

const styles = {
  shaded: {
    p: 2,
    backgroundColor: 'background.surface',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

function MigrationInProgress({close, project}: MigrationInProgressProps) {
  const operations = useMigrationOperations(project.id);
  const operationTargets = Object.keys(operations || {});
  return (
    <>
      <ModalHeader title="Migration in progress" />
      <LoadingContainer loading={operationTargets.length === 0}>
        <Box sx={styles.shaded}>
          {operationTargets.map((target) => {
            const ops = operations[target];
            return <OperationDisplay key={target} project={project} ops={ops} close={close} />;
          })}
        </Box>
      </LoadingContainer>
      <Box>
        <Typography component="span" fontWeight="fontWeightBold">
          Your serverless migration is underway.{' '}
        </Typography>
        <Typography component="span">
          This process can take several hours. You can safely close this window and check the status
          on your index page at anytime.
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" fullWidth onClick={close}>
          Close
        </Button>
      </Box>
    </>
  );
}

export default MigrationInProgress;
