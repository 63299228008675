import {useCallback, useEffect} from 'react';
import {createCmekKey, deleteCmekKey, listCmekKeys} from '../../../actions/cmekKeysActions';
import {type CreateCmekKeyRequest} from '../../../api/types/controllerApi';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {ProjectPage} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import {useNavigateToProjectPageForSelectedOrg} from '../../../hooks/navigation';
import {useControllerApi} from '../../../hooks/use-api';
import {useCmekKeys, useCmekKeysAreLoading} from '../../../selectors/cmekKeys';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import CmekList from './CmekList/CmekList';

function CmekPage() {
  const selectedProject = useSelectedGlobalProject();
  const globalProjectId = selectedProject.id;
  const cmekEnabledForProject = selectedProject.force_encryption_with_cmek;
  const enableCmekPage = useFlag(Flags.ENABLE_CMEK_PROJECTS);
  const {go: goToProjectPage} = useNavigateToProjectPageForSelectedOrg();

  useEffect(() => {
    // Because the enableCmekPage flag is targeted using OrgId, there is a chance that the flag
    // initially returns false and then becomes true after the initial render. To avoid falsely
    // redirecting users away from this page, we also check if the project is CMEK-enabled.
    if (!enableCmekPage && !cmekEnabledForProject) {
      goToProjectPage(globalProjectId, ProjectPage.INDEXES);
    }
  }, [enableCmekPage, cmekEnabledForProject, globalProjectId, goToProjectPage]);

  useControllerApi(selectedProject, cmekEnabledForProject ? listCmekKeys : undefined, {
    globalProjectId,
  });

  const loading = useCmekKeysAreLoading(globalProjectId);
  const cmekList = useCmekKeys(globalProjectId);

  const {setQueryData: setCreateData} = useControllerApi<{
    globalProjectId: string;
    data: CreateCmekKeyRequest;
  }>(selectedProject);

  const {setQueryData: setDeleteData} = useControllerApi<{
    globalProjectId: string;
    cmekId: string;
  }>(selectedProject);

  const onCreate = useCallback(
    (data: CreateCmekKeyRequest) => {
      setCreateData({
        action: createCmekKey,
        data: {globalProjectId, data},
      });
    },
    [globalProjectId, setCreateData],
  );

  const onDelete = useCallback(
    (cmekId: string) => {
      setDeleteData({
        action: deleteCmekKey,
        data: {globalProjectId, cmekId},
      });
    },
    [globalProjectId, setDeleteData],
  );

  return (
    <Page
      header={<PageHeader title="Customer-managed encrypted keys" />}
      loading={cmekEnabledForProject && loading}
    >
      {cmekEnabledForProject ? (
        <CmekList keys={cmekList} onCreate={onCreate} onDelete={onDelete} />
      ) : (
        <EmptyState
          heading="Encryption with CMEK is unavailable for this project"
          description="This project was not setup with Customer Managed Encryption Keys. Please create a new project with CMEK enabled."
        />
      )}
    </Page>
  );
}

export default CmekPage;
