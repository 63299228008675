import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';

export interface TickProps {
  axis: 'x' | 'y';
  x: number;
  y: number;
  unit?: string;
  payload: {value: string; isShow: boolean};
  formatter?: (value: string) => string;
}

export function Tick({axis, x, y, payload, unit, formatter}: TickProps) {
  const theme = useTheme();
  // If `isShow` is undefined, recharts wants us to render all ticks
  if (payload.isShow === false) {
    return null;
  }

  const isXAxis = axis === 'x';
  const value = formatter ? formatter(payload.value) : payload.value;
  const unitLabel = Number(value) === 0 || !unit ? '' : unit;

  return (
    <Typography
      component="text"
      variant="body2"
      orientation={isXAxis ? 'bottom' : 'left'}
      textAnchor={isXAxis ? 'middle' : 'end'}
      stroke="none"
      x={x}
      y={y + 4}
      fill={theme.palette.text.primary}
    >
      <tspan x={x}>{`${value}${unitLabel}`}</tspan>
    </Typography>
  );
}
