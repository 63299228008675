import {useAppSelector as useSelector} from '../app/store';

export const useUserData = () => {
  return useSelector((state) => state.userSession.loggedInUser.data);
};

export const useUserId = () => {
  const userData = useUserData();
  return userData?.firebase_id;
};

export const useUserIsLoading = () => {
  return useSelector((state) => state.userSession.loggedInUser.loading);
};

export const useRegistrationNeeded = () => {
  const user = useUserData();
  const isLoading = useUserIsLoading();
  return !user?.params.registered && !isLoading;
};
