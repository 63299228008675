import {Box, LinearProgress, Typography} from '@mui/material';
import {type IndexStatsDescription, type V4IndexInfoResponse} from '../../../../../types';
import {getTotalPods} from '../../../../../utils/indexes';
import {getSpecs} from '../../../../../utils/services';

const styles = {
  root: {
    flexGrow: 1,
    p: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    borderTop: 1,
    borderColor: 'divider',
  },
  podFullness: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    mr: 3,
  },
  progressBar: {
    flexShrink: 1,
    my: 1,
    mr: 1,
    minWidth: 100,
    width: '100%',
    height: 6,
    maxWidth: 500,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: 'divider',
    },
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: 'primary.dark',
    },
  },
  configuration: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
};

interface CapacityProps {
  serviceInfo?: V4IndexInfoResponse;
  serviceStats?: IndexStatsDescription;
}
const Capacity = ({serviceInfo, serviceStats}: CapacityProps) => {
  const fullness = (serviceStats?.indexFullness || 0) * 100;
  const {podSpec} = getSpecs(serviceInfo);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.podFullness}>
        <LinearProgress
          variant="determinate"
          value={Number(fullness)}
          valueBuffer={0}
          sx={styles.progressBar}
        />
        <Typography ml={1} component="span" whiteSpace="nowrap">
          <strong>{Number(fullness)}%</strong> Pod Fullness
        </Typography>
      </Box>
      {podSpec && (
        <Box sx={styles.configuration}>
          <Typography mr={2} component="span">
            Pods <strong>{podSpec.shards}</strong>
          </Typography>
          <Typography mr={2} component="span">
            Replicas <strong>{podSpec.replicas}</strong>
          </Typography>
          <Typography component="span">
            Total Pods <strong>{getTotalPods(podSpec)}</strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Capacity;
