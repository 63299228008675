import {Box, Card, Typography} from '@mui/material';
import {type Dataset} from '../../../../components/datasets/data/types';
import ModelChip from '../../../../components/models/ModelChip/ModelChip';
import {useNavigateToCreateIndexFromDatasetPageGlobal} from '../../../../hooks/navigation';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minHeight: 200,
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      boxShadow: 6,
    },
  },
};

function DatasetCard({id, dataset}: {id: string; dataset: Dataset}) {
  const {title, subtitle, totalVectors, totalNamespaces} = dataset.datasetInformation;
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {go: goToCreateIndexFromDatasetPage} = useNavigateToCreateIndexFromDatasetPageGlobal(
    orgId,
    projectId,
    id,
  );
  return (
    <Card component="button" sx={styles.card} onClick={goToCreateIndexFromDatasetPage}>
      <Typography variant="h6">{title}</Typography>
      {subtitle && <Typography mt={1}>{subtitle}</Typography>}
      <Box display="flex" my={2}>
        <ModelChip model={dataset.embeddingModel} />
      </Box>
      <Box flexGrow={1} />
      <Box display="flex" color="text.secondary">
        <Typography>{`${totalVectors.toLocaleString()} vectors`}</Typography>
        <Typography ml={2}>{`${totalNamespaces} ${
          totalNamespaces > 1 ? 'namespaces' : 'namespace'
        }`}</Typography>
      </Box>
    </Card>
  );
}

export default DatasetCard;
