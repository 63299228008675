import SuccessCard from '@pinecone-experience/timber/Cards/SuccessCard';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useEffect} from 'react';
import {IndexState} from '../../../../constants';
import {isUpgrading} from '../../../../hooks/specUtils';
import {useBooleanState, usePrevious} from '../../../../hooks/utils';
import {type V4IndexInfoResponse} from '../../../../types';
import {getSpecs} from '../../../../utils/services';

type MigrationSuccessModalProps = {
  serviceInfo?: V4IndexInfoResponse;
};

function MigrationSuccessModal({serviceInfo}: MigrationSuccessModalProps) {
  const {val: isOpen, setTrue: setOpen, setFalse: close} = useBooleanState();

  const prevInfo = usePrevious(serviceInfo);
  const {serverlessSpec} = getSpecs(serviceInfo);
  const wasUpgrading = isUpgrading(prevInfo);
  const isDoneUpgrading = serviceInfo?.status.state === IndexState.READY && !!serverlessSpec;
  useEffect(() => {
    if (wasUpgrading && isDoneUpgrading && prevInfo?.name === serviceInfo?.name) {
      setOpen();
    }
  }, [prevInfo?.name, serviceInfo, wasUpgrading, setOpen, serverlessSpec, isDoneUpgrading]);
  return (
    <BaseModal open={isOpen} onClose={close}>
      <SuccessCard
        title="Conversion Successful"
        text="Your new index is ready. Please update your host or reinitialize your client."
        ctaText="Close"
        cta={close}
        show
      />
    </BaseModal>
  );
}

export default MigrationSuccessModal;
