import {createReducer, isAnyOf} from '@reduxjs/toolkit';
import {
  getOrganizationEnvironmentsFailure,
  getOrganizationEnvironmentsRequest,
  getOrganizationEnvironmentsSuccess,
} from '../actions/environmentActions';
import {type Environment} from '../types';
import {type AsyncDataState, initAsyncDataState} from './utils';

const initialEnvironmentState = initAsyncDataState<Environment[]>([], false);

interface EnvironmentsState {
  organizations: Record<string, AsyncDataState<Environment[]>>;
}

const initialState = {
  organizations: {},
} as EnvironmentsState;

const organizationReducer = createReducer(initialEnvironmentState, (builder) => {
  builder
    .addCase(getOrganizationEnvironmentsRequest, (state) => {
      state.loading = true;
    })
    .addCase(getOrganizationEnvironmentsSuccess, (state, action) => {
      state.data = action.payload.environments;
      state.loading = false;
    })
    .addCase(getOrganizationEnvironmentsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
});

const environmentsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(
    isAnyOf(
      getOrganizationEnvironmentsRequest,
      getOrganizationEnvironmentsSuccess,
      getOrganizationEnvironmentsFailure,
    ),
    (state, action) => {
      state.organizations[action.payload.organizationId] = organizationReducer(
        state.organizations[action.payload.organizationId],
        action,
      );
    },
  );
});

export default environmentsReducer;
