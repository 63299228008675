import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LockIcon from '@mui/icons-material/Lock';
import {Box, Button, Collapse, type Theme} from '@mui/material';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useMemo, useState} from 'react';
import {type Control} from 'react-hook-form';
import StyledDescriptiveInput from '../../../../components/StyledDescriptiveInput';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {type IndexSchema} from '../utilities/schema';
import {getPodSizeInfo} from './utilities/pod-size';

const styles = {
  inputRow: (disabled: boolean, theme: Theme) => ({
    mt: 1,
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    gap: 2,
    opacity: disabled ? theme.palette.action.disabledOpacity : 1,
  }),
};

type AdvancedConfigurationProps = {
  hidePaid: boolean;
  control: Control<IndexSchema>;
};

function AdvancedConfiguration({hidePaid, control}: AdvancedConfigurationProps) {
  const isOrgFree = !useIsSelectedOrganizationSubscribed();
  const podSizeOptions = useMemo(() => getPodSizeInfo(isOrgFree), [isOrgFree]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true);
  return (
    <Box mb={2} display="flex" flexDirection="column" justifyContent="space-between">
      <Box my={1} display="flex" alignItems="center">
        <Button
          size="small"
          color="primary"
          onClick={() => setShowAdvancedOptions((val) => !val)}
          endIcon={showAdvancedOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {showAdvancedOptions ? 'Hide' : 'Show'} advanced configuration
        </Button>
        {hidePaid && <LockIcon sx={{ml: 1}} color="disabled" />}
      </Box>
      <Collapse in={showAdvancedOptions}>
        <Box sx={(theme) => styles.inputRow(hidePaid, theme)}>
          <StyledDescriptiveInput
            header="Pod Count"
            description="Increase the number of pods to store more vectors. You cannot change this later."
            shrinkTypographyHierarchy
            inputComponent={
              <NumberInput
                name="pods"
                control={control}
                placeholder="Enter Pods"
                disabled={hidePaid}
                fullWidth
              />
            }
          />
          <StyledDescriptiveInput
            header="Replica Count"
            description="Increase the number of replicas for more throughput and greater availability."
            shrinkTypographyHierarchy
            inputComponent={
              <NumberInput
                name="replicas"
                control={control}
                placeholder="Enter Replicas"
                disabled={hidePaid}
                fullWidth
              />
            }
          />
          <StyledDescriptiveInput
            header="Pod Size"
            description="Use Larger pod sizes to store more vectors.
                  You can increase (but not decrease) the pod size on running indexes."
            shrinkTypographyHierarchy
            inputComponent={
              <SelectInput
                name="podSize"
                control={control}
                disabled={hidePaid}
                options={podSizeOptions}
                fullWidth
              />
            }
          />
        </Box>
      </Collapse>
    </Box>
  );
}

export default AdvancedConfiguration;
