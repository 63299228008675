const parseHash = (hash: string): Record<string, string> => {
  try {
    return hash
      .substring(1)
      .split('&')
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {} as Record<string, string>);
  } catch (_error) {
    return {};
  }
};

export const parseError = (search: string, hash: string): Record<string, string> => {
  const queryParams = new URLSearchParams(search);
  if (queryParams.has('error')) {
    return {
      errorTitle: queryParams.get('error') || '',
      errorMessage: queryParams.get('error_description') || '',
    };
  }
  const hashParams = parseHash(hash);
  if (hashParams.error) {
    return {
      errorTitle: hashParams.error,
      errorMessage: hashParams.error_description || '',
    };
  }
  return {
    errorTitle: '',
    errorMessage: '',
  };
};

export const clearErrorURLParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('error');
  url.searchParams.delete('error_description');
  window.history.replaceState({}, '', url.toString());
  window.location.hash = '';
};
