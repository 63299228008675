import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {getAssistantFile} from '../../../actions/assistantFilesActions';
import AssistantFileActionMenu from '../../../components/AssistantFileActionMenu/AssistantFileActionMenu';
import AssistantFileStatusLabel from '../../../components/AssistantFileStatusLabel/AssistantFileStatusLabel';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {AssistantPagePanel} from '../../../constants';
import {useNavigateToAssistant} from '../../../hooks/navigation';
import {useAssistantDataApi} from '../../../hooks/use-api';
import {useAssistantFile, useAssistantFilesAreLoading} from '../../../selectors/assistants';
import {
  useSelectedAssistantName,
  useSelectedFileId,
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../selectors/params';
import {formatByteSize} from '../../../utils/format';

const styles = {
  infoItems: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
};

const InfoItem = ({label, value}: {label: string; value: React.ReactNode}) => {
  return (
    <Box>
      <Typography variant="overline" color="secondary">
        {label}
      </Typography>
      <Typography mt={1}>{value}</Typography>
    </Box>
  );
};

const MetadataItem = ({label, value}: {label: string; value: string}) => {
  return (
    <Box mt={1}>
      <Typography component="span" sx={{textTransform: 'capitalize'}}>
        <strong>{label}:</strong>
      </Typography>
      <Typography component="span"> {value}</Typography>
    </Box>
  );
};

export default function AssistantFilePage() {
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const assistantName = useSelectedAssistantName() || '';
  const fileId = useSelectedFileId();
  const file = useAssistantFile(assistantName, fileId);
  const filesAreLoading = useAssistantFilesAreLoading(assistantName);

  const {go: goToAssistant} = useNavigateToAssistant(orgId, projectId);

  useAssistantDataApi(projectId, getAssistantFile, {
    assistantName,
    fileId,
  });

  const redirectToAssistantFiles = () => {
    goToAssistant(assistantName || '-', AssistantPagePanel.FILES);
  };

  if (!filesAreLoading && !file) {
    redirectToAssistantFiles();
    return null;
  }

  if (!file) {
    return <Page loading />;
  }

  return (
    <Page
      loading={filesAreLoading}
      header={
        <PageHeader
          title={file ? file.name : ''}
          titleHidden
          breadcrumb={{text: 'Go back'}}
          actions={
            file ? (
              <AssistantFileActionMenu
                onDelete={redirectToAssistantFiles}
                name={file.name}
                id={file.id}
                status={file.status}
              />
            ) : null
          }
        />
      }
    >
      <Card>
        <Typography component="h1" variant="h6" mb={4}>
          {file.name}
        </Typography>
        <Box sx={styles.infoItems}>
          <InfoItem label="Status" value={<AssistantFileStatusLabel fileId={file.id} />} />
          <InfoItem label="Added" value={new Date(file.created_on).toLocaleDateString()} />
          <InfoItem label="Updated" value={new Date(file.updated_on).toLocaleDateString()} />
          <InfoItem label="Size" value={file.size ? formatByteSize(file.size, 2) : '--'} />
        </Box>
        {file.metadata ? (
          <Box mt={4} data-testid="metadata">
            <Typography variant="overline" color="secondary">
              Metadata
            </Typography>
            {Object.keys(file.metadata).map((key) => (
              <MetadataItem key={key} label={key} value={file.metadata?.[key] || ''} />
            ))}
          </Box>
        ) : null}
      </Card>
    </Page>
  );
}
