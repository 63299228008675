import {zodResolver} from '@hookform/resolvers/zod';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {createApiKey} from '../../../../actions/apiKeyActions';
import ModalForm from '../../../../components/ModalForm';
import {API_KEY_LABEL_MAX_LENGTH} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedGlobalProjectId} from '../../../../selectors/params';
import {ALPHANUMERIC_HYPHENS_REGEX} from '../../../../utils/format';
import {TRACKING_EVENTS, sendToGa} from '../../../../utils/tracking';
import CreateApiKeyForm from './CreateApiKeyForm';

const schema = z.object({
  label: z
    .string()
    .nonempty({message: 'Label must not be empty'})
    .max(API_KEY_LABEL_MAX_LENGTH, {
      message: `Label must not be longer than ${API_KEY_LABEL_MAX_LENGTH} characters`,
    })
    .regex(ALPHANUMERIC_HYPHENS_REGEX, {
      message: 'Label can only contain alpha-numeric characters and hyphens',
    }),
});

type CreateApiKeyFormData = z.infer<typeof schema>;

interface CreateApiKeyModalProps {
  open: boolean;
  onClose: () => void;
}

/**
 * Any button that opens this modal should check for
 * isSelectedProjectOwner to prevent errors on create.
 * */
function CreateApiKeyModal({open, onClose}: CreateApiKeyModalProps) {
  const selectedProjectId = useSelectedGlobalProjectId();
  const {
    setValue,
    handleSubmit,
    formState: {isValid},
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {label: ''},
    resolver: zodResolver(schema),
  });

  const {setQueryData} = useDashboardApi<{
    globalProjectId: string;
    label: string;
  }>();

  const onCreate = (data: {label: string}) =>
    setQueryData({
      action: createApiKey,
      data: {
        label: data.label,
        globalProjectId: selectedProjectId,
      },
    });

  const handleCreate = (data: CreateApiKeyFormData) => {
    onCreate(data);
    sendToGa(TRACKING_EVENTS.CREATE_API_KEY);
    setValue('label', '');
    onClose();
  };

  const handleClose = () => {
    setValue('label', '');
    onClose();
  };

  const useApiKeyRoles = useFlag(Flags.API_KEY_SHOWN_ONCE);

  if (useApiKeyRoles) {
    return <CreateApiKeyForm open={open} close={handleClose} />;
  }
  return (
    <ModalForm
      title="Create a new API key"
      isValid={isValid}
      onSubmit={handleSubmit(handleCreate)}
      submitText="Create API key"
      open={open}
      close={handleClose}
      form={
        <TextInput
          name="label"
          control={control}
          required
          autoFocus
          label="Enter new API key name"
          fullWidth
        />
      }
    />
  );
}

export default CreateApiKeyModal;
