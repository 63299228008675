import {type Reducer, combineReducers} from 'redux';
import assistantFiles from './assistantFiles';
import assistants from './assistants';
import backups from './backupsReducer';
import billing from './billingReducer';
import bulkImports from './bulkImportsReducer';
import cmekKeys from './cmekKeysReducer';
import collections from './collectionsReducer';
import environments from './environmentsReducer';
import explorer from './explorerReducer';
import forcedMigration from './forcedMigration';
import freeTierDowngradeEligibilities from './freeTierDowngradeEligibilityReducer';
import globalError from './globalErrorReducer';
import inference from './inferenceReducer';
import integrations from './integrationReducer';
import invites from './inviteReducer';
import keys from './keyReducer';
import marketplaceSetup from './marketplaceSetupReducer';
import metrics from './metricsReducer';
import networkAllowlists from './networkAllowlistReducer';
import notify from './notifyReducer';
import organizations from './organizationReducer';
import privateEndpoints from './privateEndpointsReducer';
import project from './projectReducer';
import quotaLimits from './quotaLimitsReducer';
import quotaUtilization from './quotaUtilizationReducer';
import rbac from './rbacReducer';
import services from './serviceReducer';
import sso from './ssoReducer';
import storageIntegrations from './storageIntegrationReducer';
import systemStatus from './systemStatusReducer';
import ui from './uiReducer';
import usageData from './usageDataReducer';
import userSession from './userSessionReducer';
import users from './usersReducer';

const createReducer = (router: Reducer) =>
  combineReducers({
    ui,
    router,
    notify,
    keys,
    services,
    collections,
    metrics,
    project,
    billing,
    sso,
    organizations,
    environments,
    users,
    userSession,
    usageData,
    invites,
    explorer,
    integrations,
    marketplaceSetup,
    forcedMigration,
    freeTierDowngradeEligibilities,
    quotaLimits,
    quotaUtilization,
    globalError,
    privateEndpoints,
    networkAllowlists,
    assistants,
    assistantFiles,
    rbac,
    storageIntegrations,
    backups,
    systemStatus,
    inference,
    bulkImports,
    cmekKeys,
  });

export default createReducer;
