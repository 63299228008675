/* eslint-disable no-console */
import imageUrlBuilder from '@sanity/image-url';
import {DefaultEmbeddingModels} from '../../constants';
import type {EmbeddingModel} from '../../types';
import {isDevelopment} from '../environment';
import {client} from './client';

const MODEL_LIMIT = 6;

export async function fetchEmbeddingModels(): Promise<EmbeddingModel[]> {
  try {
    const response = await client.fetch(`
      *[_type == 'model'
      && modelType=='embedding'
      && productConsole == true
      && image!=null
      && dimension != null
      && distanceMetric!=null][0..${MODEL_LIMIT - 1}] {
        name,
        image,
       "imageRef": image.asset._ref,
        slug,
        dimension,
        "metric": distanceMetric,
        "type": modality
      }`);

    const builder = imageUrlBuilder(client);

    const embeddingModels = response.map((model: EmbeddingModel) => {
      const imageUrl = model.imageRef ? builder.image(model.imageRef).url() : null;
      return {
        icon: imageUrl,
        name: model.name,
        dimension: model.dimension,
        metric: model.metric,
        type: model.type,
      };
    });

    if (embeddingModels.length > 0) {
      return embeddingModels;
    }
  } catch (e) {
    if (isDevelopment) {
      console.log('Sanity failed to initialize');
    }
  }
  return DefaultEmbeddingModels;
}
