import {QuotaKeys} from '@pinecone-experience/commons/quotas';
import {capitalize, formatStringList} from '../../../utils/format';
import {type EnrichedUsageQuota, UsageQuotaEffectActionMap} from '../QuotaUtilizationTracker/types';

export const getBlockedText = (enrichedQuotas: EnrichedUsageQuota[]) => {
  const exceededQuotas = enrichedQuotas.filter((quota) => quota.utilization >= quota.limit);
  const onlyStorageExceeded =
    exceededQuotas.length === 1 &&
    exceededQuotas[0].key === QuotaKeys.SERVERLESS_STORAGE_GB_PER_PROJECT;

  const exceededEffects = [...new Set(exceededQuotas.flatMap((quota) => quota.effects))];
  if (exceededEffects.length > 0) {
    const exceededQuotasFullNameAndAbbreviation = exceededQuotas.map(
      (quota) => `${quota.name}${quota.abbreviation ? ` (${quota.abbreviation})` : ''}`,
    );

    const exceededEffectAction = exceededEffects.map((effect) => UsageQuotaEffectActionMap[effect]);
    const header = `${formatStringList(exceededEffects.map(capitalize))} access paused`;

    const body = onlyStorageExceeded
      ? 'You’ve reached the storage limit for this project. To update or upsert new data, delete records or upgrade your plan.'
      : `You’ve reached your ${formatStringList(
          exceededQuotasFullNameAndAbbreviation,
        )} limit for the current month. To continue ${formatStringList(
          exceededEffectAction,
        )} data, upgrade your plan.`;
    return {
      header,
      body,
    };
  }
  return null;
};
