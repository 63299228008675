// Flags are taken from: https://github.com/Yummygum/flagpack-core/tree/358034b43143b81e4ad0b046a693208501940c9f/lib/flags/l
import {ReactComponent as BelgiumFlag} from '../icons/flags/belgium_flag.svg';
import {ReactComponent as CanadaFlag} from '../icons/flags/canada_flag.svg';
import {ReactComponent as IrelandFlag} from '../icons/flags/ireland_flag.svg';
import {ReactComponent as JapanFlag} from '../icons/flags/japan_flag.svg';
import {ReactComponent as NetherlandsFlag} from '../icons/flags/netherlands_flag.svg';
import {ReactComponent as SingaporeFlag} from '../icons/flags/singapore_flag.svg';
import {ReactComponent as USAFlag} from '../icons/flags/usa_flag.svg';

export function getFlag(flag?: string, style?: object) {
  if (flag === 'ireland') {
    return <IrelandFlag style={style} />;
  }
  if (flag === 'usa') {
    return <USAFlag style={style} />;
  }
  if (flag === 'singapore') {
    return <SingaporeFlag style={style} />;
  }
  if (flag === 'canada') {
    return <CanadaFlag style={style} />;
  }
  if (flag === 'japan') {
    return <JapanFlag style={style} />;
  }
  if (flag === 'netherlands') {
    return <NetherlandsFlag style={style} />;
  }
  if (flag === 'belgium') {
    return <BelgiumFlag style={style} />;
  }
  return null;
}
