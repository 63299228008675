import Table from '@pinecone-experience/timber/Table';
import {type UpdateStorageIntegrationRequest} from '../../../../api/types/controllerApi';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {useStorageIntegrations} from '../../../../selectors/storageIntegrations';
import {GUTTER} from '../../../../styles/theme';
import StorageCell from './StorageCell/StorageCell';

type StorageListParams = {
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: UpdateStorageIntegrationRequest) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** A list of integrations. Provides options to edit or delete each integration. */
function StorageList({onEdit, onDelete}: StorageListParams) {
  const selectedProject = useSelectedGlobalProject();
  const storageIntegrations = useStorageIntegrations(selectedProject.id);

  const cellRenderer = (row: number, col: number) => (
    <StorageCell row={row} col={col} onEdit={onEdit} onDelete={onDelete} />
  );

  return (
    <Table
      isLoading={false}
      columns={[
        {name: 'Name', dataType: 'string'},
        {name: 'ID', dataType: 'string'},
        {name: 'Provider', dataType: 'string'},
        {name: 'Status', dataType: 'string'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={storageIntegrations.length}
      cellRenderer={cellRenderer}
      customIdPrefix="storage-integrations-page"
      gutter={GUTTER}
    />
  );
}

export default StorageList;
