import {Box, Checkbox, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import {type MultiSelectState} from '@pinecone-experience/timber/Table/hooks';
import {useBackupsByProjectId} from '../../../../selectors/backups';
import {type Backup, type GlobalProject, type V4IndexInfoResponse} from '../../../../types';
import {formatByteSize} from '../../../../utils/format';
import BackupsDetailActions from '../BackupsDetailActions/BackupsDetailActions';
import BackupStatusBox from './BackupStatusBox/BackupStatusBox';

type BackupsDetailsTableProps = {
  backups: Backup[];
  project: GlobalProject;
  sourceIndex?: V4IndexInfoResponse;
  multiSelectState: MultiSelectState<Backup>;
};

function BackupsDetailsTable({
  backups,
  project,
  sourceIndex,
  multiSelectState,
}: BackupsDetailsTableProps) {
  const {loading: backupsLoading} = useBackupsByProjectId(project.id) || {};
  const {selectedIndexes, toggleAllSelected, amtSelected, onClickRow} = multiSelectState;
  const cellRenderer = (row: number, col: number) => {
    const backup = backups[row];
    if (col === 0) {
      return (
        <Checkbox
          key={String(selectedIndexes[row])}
          checked={selectedIndexes[row]}
          onClick={(evt) => onClickRow(row, evt)}
          size="small"
        />
      );
    }
    if (col === 1) {
      return (
        <Box display="flex">
          <Typography fontWeight="bold">{backup.name}</Typography>
          <BackupStatusBox project={project} backup={backup} />
        </Box>
      );
      return backup.name;
    }
    if (col === 2) {
      return new Date(backup.created_at).toLocaleString();
    }
    if (col === 3) {
      return backup.record_count?.toLocaleString();
    }
    if (col === 4) {
      return formatByteSize(backup.size_bytes || 0);
    }
    if (col === 5) {
      return <BackupsDetailActions project={project} backup={backup} sourceIndex={sourceIndex} />;
    }
    return null;
  };
  return (
    <Table
      isLoading={backupsLoading}
      cellRenderer={cellRenderer}
      columns={[
        {
          name: 'check',
          component: (
            <Checkbox
              checked={amtSelected > 0 && amtSelected === backups.length}
              indeterminate={amtSelected > 0 && amtSelected < backups.length}
              onClick={toggleAllSelected}
              size="small"
            />
          ),
          dataType: 'actions',
        },
        {
          name: 'Backup',
          dataType: 'string',
        },
        {
          name: 'Created',
          dataType: 'string',
        },
        {
          name: 'Vectors',
          dataType: 'number',
        },
        {
          name: 'Size (GB)',
          dataType: 'number',
        },
        {
          name: 'Actions',
          dataType: 'actions',
        },
      ]}
      numRows={backups.length}
    />
  );
}

export default BackupsDetailsTable;
