import {Link} from '@mui/material';
import {PlanNameMapping, Plans, SupportTiers} from '../../../constants';

export type PlanDetails = BillingPlanDetails | SupportPlanDetails;

export interface BillingPlanDetails {
  tier: Plans;
  description: string;
  subtitle?: string;
  details: PlanDetailsSection[];
}

export interface SupportPlanDetails {
  tier: SupportTiers;
  subtitle: string;
  description: string;
  details: PlanDetailsSection[];
  footer?: string;
}

export interface ItemType {
  item: React.ReactNode;
  description?: React.ReactNode;
  badge?: React.ReactNode;
  disabled?: boolean;
}

interface PlanDetailsSection {
  sectionName: string;
  badge?: string;
  defaultCollapsed?: boolean;
  items: ItemType[];
}

export const BillingPlansDetails: BillingPlanDetails[] = [
  {
    tier: Plans.FREE,
    description: 'For trying out and for small applications.',
    subtitle: 'Free',
    details: [
      {
        sectionName: 'Features',
        defaultCollapsed: false,
        items: [
          {item: 'Pinecone Serverless'},
          {item: 'Pinecone Inference'},
          {item: 'Pinecone Assistant'},
          {item: 'Console Metrics'},
          {item: 'Community Support'},
        ],
      },
    ],
  },
  {
    tier: Plans.STANDARD,
    description: 'For production applications at any scale.',
    subtitle: 'Pay as you go',
    details: [
      {
        sectionName: 'Features',
        defaultCollapsed: false,
        items: [
          {item: 'Unlimited Serverless, Inference, and Assistant usage'},
          {item: 'Choose your cloud and region'},
          {item: 'Import from object storage'},
          {item: 'Multiple projects and users'},
          {item: 'RBAC'},
          {item: 'Backups'},
          {item: 'Prometheus metrics', badge: 'COMING SOON'},
          {
            item: (
              <>
                Access to{' '}
                <Link href="https://www.pinecone.io/pricing/?plans=support">Developer</Link> and{' '}
                <Link href="https://www.pinecone.io/pricing/?plans=support">Pro support</Link>
              </>
            ),
          },
          {item: 'Pod Based Indexes'},
        ],
      },
    ],
  },
  {
    tier: Plans.ENTERPRISE,
    description: 'For mission-critical production applications.',
    subtitle: 'Custom',
    details: [
      {
        sectionName: 'Features',
        defaultCollapsed: false,
        items: [
          {item: 'Everything in Standard'},
          {item: '99.95% Uptime SLA'},
          {item: 'Single sign-on'},
          {item: 'Private Link', badge: 'PUBLIC PREVIEW'},
          {item: 'CMEK', badge: 'COMING SOON'},
          {item: 'Audit Logs', badge: 'COMING SOON'},
          {
            item: (
              <>
                <Link href="https://www.pinecone.io/pricing/?plans=support">
                  Enterprise support
                </Link>{' '}
                included
              </>
            ),
          },
        ],
      },
    ],
  },
];

export const SupportPlansDetails: SupportPlanDetails[] = [
  {
    tier: SupportTiers.FREE,
    subtitle: 'Included in starter plan',
    description:
      'The Free plan is ideal for small applications and beginners who need basic access to community forums without direct support.',
    details: [
      {
        sectionName: 'Features:',
        items: [{item: 'Access: NA', disabled: true}],
      },
      {
        sectionName: 'Support:',
        items: [
          {item: 'Support bot', disabled: false},
          {item: 'Community forum', disabled: false},
          {
            item: 'Response-time SLA',
            description: (
              <>
                <u>Sev-1</u>: NA
                <br />
                <u>Sev-2</u>: NA
                <br />
                <u>Sev-3</u>: NA
                <br />
                <u>Sev-4</u>: NA
              </>
            ),
            disabled: true,
          },
          {item: 'Availability: NA', disabled: true},
          {item: 'Users', disabled: true},
          {item: 'Ticket portal', disabled: true},
          {item: 'Email', disabled: true},
          {item: 'Zoom / Meet', disabled: true},
          {item: 'Dedicated Slack channel', disabled: true},
        ],
      },
    ],
  },
  {
    tier: SupportTiers.DEVELOPER,
    subtitle: '$29/month',
    description:
      'The Developer plan is perfect for developers who require business hours support and the ability to create tickets through our portal.',
    details: [
      {
        sectionName: 'Features',
        items: [{item: 'Access: business hours', disabled: false}],
      },
      {
        sectionName: 'Support',
        items: [
          {item: 'Support bot', disabled: false},
          {item: 'Community forum', disabled: false},
          {
            item: 'Response-time SLA',
            description: (
              <>
                <u>Sev-1</u>: 8 business hours
                <br />
                <u>Sev-2</u>: 2 business days
                <br />
                <u>Sev-3</u>: 3 business days
                <br />
                <u>Sev-4</u>: 3 business days
              </>
            ),
            disabled: false,
          },
          {item: 'Availability: business hours', disabled: false},
          {item: 'Support for 1 user', disabled: false},
          {item: 'Ticket portal', disabled: false},
          {item: 'Email', disabled: true},
          {item: 'Zoom / Meet', disabled: true},
          {item: 'Dedicated Slack channel', disabled: true},
        ],
      },
    ],
  },
  {
    tier: SupportTiers.PRO,
    subtitle: '$499/month*',
    description:
      'The Pro plan offers 24/7 support, faster response times, and more communication channels for businesses.',
    details: [
      {
        sectionName: 'Features',
        items: [{item: 'Access: 24/7', disabled: false}],
      },
      {
        sectionName: 'Support',
        items: [
          {item: 'Support bot', disabled: false},
          {item: 'Community forum', disabled: false},
          {
            item: 'Response-time SLA',
            description: (
              <>
                <u>Sev-1</u>: 2 business hours
                <br />
                <u>Sev-2</u>: 4 business hours
                <br />
                <u>Sev-3</u>: 8 business hours
                <br />
                <u>Sev-4</u>: 2 business days
              </>
            ),
            disabled: false,
          },
          {item: '24/7/365 availability', disabled: false},
          {item: 'Up to 5 users', disabled: false},
          {item: 'Ticket portal', disabled: false},
          {item: 'Email', disabled: false},
          {item: 'Zoom / Meet', disabled: true},
          {item: 'Dedicated Slack channel', disabled: true},
        ],
      },
    ],
    footer: '* Included with Standard annual commit at no extra cost',
  },
  {
    tier: SupportTiers.ENTERPRISE,
    subtitle: 'Request a quote',
    description:
      'Enterprise offers top-tier support with the fastest response times, unlimited users, and a dedicated Slack channel.',
    details: [
      {
        sectionName: 'Features',
        items: [{item: 'Access: 24/7', disabled: false}],
      },
      {
        sectionName: 'Support',
        items: [
          {item: 'Support bot', disabled: false},
          {item: 'Community forum', disabled: false},
          {
            item: 'Response-time SLA',
            description: (
              <>
                <u>Sev-1</u>: 30 minutes
                <br />
                <u>Sev-2</u>: 2 business hours
                <br />
                <u>Sev-3</u>: 8 business hours
                <br />
                <u>Sev-4</u>: 8 business hours
              </>
            ),
            disabled: false,
          },
          {item: '24/7/365 availability', disabled: false},
          {item: 'Unlimited users', disabled: false},
          {item: 'Ticket portal', disabled: false},
          {item: 'Email', disabled: false},
          {item: 'Zoom / Meet', disabled: false},
          {item: 'Dedicated Slack channel', disabled: false},
        ],
      },
    ],
    footer: '* Included with Enterprise annual commit at no extra cost',
  },
];

// Define the data structure
export const detailedPricingData = [
  {
    feature: 'Serverless Indexes',
    isSection: true,
    docsLink: 'https://docs.pinecone.io/guides/indexes/understanding-indexes#serverless-indexes',
  },
  {
    feature: 'Storage',
    starter: '2GB included',
    standard: '$0.33/GB/mo.',
    enterprise: '$0.33/GB/mo.',
  },
  {
    feature: 'Write Units',
    starter: '2M/mo. included',
    standard: 'Starting at $4/M',
    enterprise: 'Starting at $6/M',
  },
  {
    feature: 'Read Units',
    starter: '1M/mo. included',
    standard: 'Starting at $16/M',
    enterprise: 'Starting at $24/M',
  },
  {
    feature: 'Import from object storage',
    starter: '—',
    standard: '$1/GB',
    enterprise: '$1/GB',
    tag: 'EARLY ACCESS',
  },
  {
    feature: 'Projects per Organization',
    starter: '1',
    standard: '20',
    enterprise: '100',
  },
  {
    feature: 'Indexes per Project',
    starter: '5',
    standard: '20',
    enterprise: '200',
  },
  {
    feature: 'Namespaces per Index',
    starter: '100',
    standard: '10,000',
    enterprise: '100,000',
  },
  {
    feature: 'Pod Based Indexes',
    starter: '—',
    standard: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    feature: 'Backup Storage',
    starter: '—',
    standard: 'Starting at $0.12/GB/mo.',
    enterprise: 'Starting at $0.12/GB/mo.',
    tag: 'PUBLIC PREVIEW',
  },
  {
    feature: 'Restore from backup',
    starter: '—',
    standard: 'Starting at $0.50/GB',
    enterprise: 'Starting at $0.50/GB',
    tag: 'PUBLIC PREVIEW',
  },
  {
    feature: 'Inference',
    isSection: true,
    tag: 'PUBLIC PREVIEW',
    docsLink: 'https://docs.pinecone.io/guides/inference/understanding-inference',
  },
  {
    feature: 'Embed API',
    starter: '5M tokens/mo. included',
    standard: '$0.08/M tokens',
    enterprise: '$0.08/M tokens',
  },
  {
    feature: 'Rerank API',
    starter: '—',
    standard: '$2/1k requests',
    enterprise: '$2/1k requests',
  },
  {
    feature: 'Pinecone Assistant',
    isSection: true,
    tag: 'PUBLIC PREVIEW',
    docsLink: 'https://docs.pinecone.io/guides/assistant/understanding-assistant',
  },
  {
    feature: 'Storage',
    starter: '1GB Storage included',
    standard: '$3 per GB/mo.',
    enterprise: '$3 per GB/mo.',
  },
  {
    feature: 'Input Tokens',
    starter: '1.5M included',
    standard: '$8 per 1M',
    enterprise: '$8 per 1M',
  },
  {
    feature: 'Output Tokens',
    starter: '200k included',
    standard: '$15 per 1M',
    enterprise: '$15 per 1M',
  },
  {
    feature: 'Assistants per day',
    starter: '—',
    standard: '$0.20/Assistant per day',
    enterprise: '$0.20/Assistant per day',
  },
];

export const DedicatedPlanDetails = {
  tier: PlanNameMapping[Plans.DEDICATED],
  description:
    'Everything in Enterprise, deployed in a single-tenant VPC environment in the region of your choice. <a target="_blank" rel="noreferrer" href="https://www.pinecone.io/contact/">Contact us</a> for details and pricing.',
};

export const freePlanUpgradeConfirmation = {
  [SupportTiers.DEVELOPER]:
    'A monthly charge of $29 for the Developer tier, which includes benefits like business hours support, faster response times, and the ability to create support tickets through our portal.',
  [SupportTiers.PRO]:
    'A monthly charge of $499 for the Pro tier, which includes benefits like 24/7 support, priority response times, and multiple communication channels, including email and a ticket portal.',
  [SupportTiers.FREE]: '',
  [SupportTiers.ENTERPRISE]: '',
  [SupportTiers.INTERNAL]: '',
};

export const upgradeConfirmation = {
  [SupportTiers.DEVELOPER]:
    'Your support plan upgrade will include a monthly charge of $29 for the Developer tier, providing you with business hours support, faster response times, and the ability to create support tickets. ',
  [SupportTiers.PRO]:
    'Your support plan upgrade will include a monthly charge of $499 for the Pro tier, providing you with 24/7 support, priority response times, and multiple communication channels, including email and a ticket portal. ',
  [SupportTiers.FREE]: '',
  [SupportTiers.ENTERPRISE]: '',
  [SupportTiers.INTERNAL]: '',
};

export const downgradeConfirmationItems = {
  [SupportTiers.FREE]: [
    {
      item: 'Support availability',
      description: 'You will move to Free support, which does not include dedicated support.',
    },
    {
      item: 'Response Times',
      description: 'You will no longer have guaranteed response times for any severity levels.',
    },
    {
      item: 'Support Channels',
      description:
        'Access to current support channels will be replaced by Community Forum support only.',
    },
    {
      item: 'Support Users',
      description: 'The ability to have dedicated Support Users will be removed.',
    },
  ],
  [SupportTiers.DEVELOPER]: [
    {
      item: 'Support availability',
      description: 'You will move from 24/7 support to Business Hours support.',
    },
    {
      item: 'Response Times',
      description: 'Your response-time SLA will change',
    },
    {
      item: 'Support Channels',
      description:
        'Access to premium support channels such as Slack and Zoom/Meet will be replaced by Ticket Portal and Support Bot only',
    },
    {
      item: 'Support Users',
      description: 'The number of support users will be limited to 1.',
    },
  ],
  [SupportTiers.PRO]: [],
  [SupportTiers.ENTERPRISE]: [],
  [SupportTiers.INTERNAL]: [],
};

export const supportUpgradeSuccessText = (
  <>
    Your support plan has been successfully upgraded. You now have access to faster response times,
    dedicated support, and additional communication channels.{' '}
    <em>Note: You&apos;ll only be billed for the remaining days of this month.</em>
  </>
);
export const supportDowngradeSuccessText = (
  <>
    Your support plan has been successfully downgraded. You will retain your current plan&rsquo;s
    benefits for the remainder of this month, and the downgrade will take effect at the start of the
    next billing cycle.
  </>
);
