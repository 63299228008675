import {useAppSelector as useSelector} from '../app/store';
import {type CmekKey} from '../types';

export const useCmekKeys = (projectId: string): CmekKey[] => {
  return Object.values(useSelector((state) => state.cmekKeys.projects[projectId]?.data) || {});
};

export const useCmekKeysAreLoading = (projectId: string) => {
  return useSelector((state) => {
    const data = state.cmekKeys.projects[projectId];
    if (!data) {
      return true;
    }

    return data.loading;
  });
};

export const useCmekKey = (projectId: string, keyId: string): CmekKey | undefined => {
  const keys = useSelector((state) => state.cmekKeys.projects[projectId]?.data) || {};

  return keys[keyId];
};
