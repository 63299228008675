import {Box} from '@mui/material';
import {useNavigateToAssistant} from '../../../../hooks/navigation';
import {useAssistantsList} from '../../../../selectors/assistants';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import AssistantCard from './AssistantCard/AssistantCard';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    gap: 3,
  },
};

function AssistantList() {
  const project = useSelectedGlobalProject();
  const assistants = useAssistantsList(project.id);
  const {go: navigateToAssistant} = useNavigateToAssistant(project.organization_id, project.id);
  const onSelectAssistant = (baseId: string) => {
    navigateToAssistant(baseId);
  };
  return (
    <Box sx={styles.container}>
      {assistants.map((assistant) => (
        <AssistantCard
          key={assistant.name}
          onSelect={() => onSelectAssistant(assistant.name)}
          assistant={assistant}
        />
      ))}
    </Box>
  );
}

export default AssistantList;
