import {localStorageGet, localStorageSet} from './storage';

const HISTORY_STORAGE_LIMIT = 10;

export const storedHistory = {
  get: (key: string) => {
    const history = localStorageGet(key);

    return history?.split(',').reverse();
  },
  append: (key: string, item: string) => {
    const history = localStorageGet(key)?.split(',') || [];
    const newHistory = [...history.filter((entry) => entry !== item), item];

    localStorageSet(key, newHistory.slice(-1 * HISTORY_STORAGE_LIMIT).join(','));
  },
  remove: (key: string, item: string) => {
    const history = localStorageGet(key)?.split(',');

    if (!history) {
      // No history for given key
      return;
    }

    const newHistory = [...history.filter((entry) => entry !== item)];

    localStorageSet(key, newHistory.slice(-1 * HISTORY_STORAGE_LIMIT).join(','));
  },
};
