import {type Dispatch, type SetStateAction, createContext} from 'react';

interface RegistrationFormContextType {
  isShowingRegistration: boolean;
  setIsShowingRegistration: Dispatch<SetStateAction<boolean>>;
}

export const RegistrationFormContext = createContext<RegistrationFormContextType>({
  isShowingRegistration: false,
  setIsShowingRegistration: () => {},
});
