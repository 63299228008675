import type * as inkeepJS from '@inkeep/uikit-js';
import React, {useEffect, useRef} from 'react';
import {baseSettings, initializeInkeep} from './inkeepConfig';

interface InkeepCustomTriggerComponentProps {
  children: React.ReactElement;
}

const InkeepCustomTriggerComponent: React.FC<InkeepCustomTriggerComponentProps> = ({children}) => {
  const triggerRef = useRef<ReturnType<ReturnType<typeof inkeepJS.Inkeep>['embed']> | null>(null);

  useEffect(() => {
    const inkeep = initializeInkeep();

    const handleClose = () => {
      triggerRef.current?.render({isOpen: false, baseSettings});
    };

    triggerRef.current = inkeep.embed({
      componentType: 'CustomTrigger',
      targetElement: '#inkeep-custom-trigger',
      properties: {
        isOpen: false,
        onClose: handleClose,
        baseSettings,
        searchSettings: {
          shouldOpenLinksInNewTab: true,
        },
        modalSettings: {
          defaultView: 'SEARCH',
          isModeSwitchingEnabled: false,
        },
      },
    });

    return () => {
      if (triggerRef.current && typeof triggerRef.current.unmount === 'function') {
        try {
          triggerRef.current.unmount();
        } catch (error) {
          /* empty */
        }
      }
      triggerRef.current = null;
    };
  }, []);

  const handleOpen = () => {
    triggerRef.current?.render({isOpen: true, baseSettings});
  };

  const handleClick = (e: React.MouseEvent) => {
    handleOpen();
    if (children.props.onClick) {
      children.props.onClick(e);
    }
  };

  return (
    <>
      {React.cloneElement(children, {onClick: handleClick})}
      <div id="inkeep-custom-trigger" />
    </>
  );
};

export default InkeepCustomTriggerComponent;
