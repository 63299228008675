import {Box, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';

type ResultsGuardProps = {
  loading: boolean;
  total: number;
  children: React.ReactNode;
};

function ResultsGuard({loading, total, children}: ResultsGuardProps) {
  return (
    <LoadingContainer loading={loading}>
      {total > 0 ? (
        children
      ) : (
        <Box sx={{minHeight: 50, textAlign: 'center', py: 6}}>
          <Typography variant="h5">No results found</Typography>
          <Typography py={4}>Please check your filters and try again</Typography>
        </Box>
      )}
    </LoadingContainer>
  );
}

export default ResultsGuard;
