import {freeEnvironmentPodName} from '../../../../../constants';
import PodCard from '../PodCard/PodCard';

export function getPodInfo(isSelectedEnvironmentFree: boolean) {
  const podInfo = [
    ...(isSelectedEnvironmentFree
      ? [
          {
            value: freeEnvironmentPodName.value,
            headline: freeEnvironmentPodName.displayName,
            description: 'Included in the starter plan',
            disabled: !isSelectedEnvironmentFree,
            hide: !isSelectedEnvironmentFree,
          },
        ]
      : []),
    {
      value: 's1',
      headline: 's1',
      description: 'Best storage capacity',
      disabled: isSelectedEnvironmentFree,
      paid: isSelectedEnvironmentFree,
    },
    {
      value: 'p1',
      headline: 'p1',
      description: 'Faster queries',
      disabled: isSelectedEnvironmentFree,
      paid: isSelectedEnvironmentFree,
    },
    {
      value: 'p2',
      headline: 'p2',
      description: 'Lowest latency and highest throughput',
      disabled: isSelectedEnvironmentFree,
      paid: isSelectedEnvironmentFree,
    },
  ];

  return podInfo.map((pod) => ({
    value: pod.value,
    component: () => PodCard(pod.headline, pod.description, pod.paid),
    disabled: !!(pod.disabled || pod.paid),
    hide: pod.hide,
  }));
}
