import Box from '@mui/material/Box';
import SuccessCard from '@pinecone-experience/timber/Cards/SuccessCard';
import {OrgSettingsPage} from '../../../../../../constants';
import {useNavigateToOrgSettingsPage} from '../../../../../../hooks/navigation';
import {useSupportChangeCompleted} from '../../../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../../../selectors/params';
import {MODAL_WIDTH_SMALL} from '../../../../../../styles/theme';
import {supportDowngradeSuccessText, supportUpgradeSuccessText} from '../../../constants';
import {ChangeSupportAction} from '../../supportUtils';

function ChangeSupportSuccessCard({show}: {show: boolean}) {
  const organizationId = useSelectedOrganizationId();
  const actionCompleted = useSupportChangeCompleted();
  const ctaText = 'Go to the Help center';

  const text = (() => {
    if (actionCompleted === ChangeSupportAction.UPGRADE) {
      return supportUpgradeSuccessText;
    }
    if (actionCompleted === ChangeSupportAction.DOWNGRADE) {
      return supportDowngradeSuccessText;
    }
    return '';
  })();

  const goToSupportPages = useNavigateToOrgSettingsPage(organizationId);
  return (
    <Box width={MODAL_WIDTH_SMALL} position="relative">
      <SuccessCard
        title={`Support plan ${actionCompleted?.toLowerCase() ?? 'unchange'}d`}
        text={text}
        cta={() => goToSupportPages(OrgSettingsPage.SUPPORT)}
        ctaText={ctaText}
        show={show}
      />
    </Box>
  );
}

export default ChangeSupportSuccessCard;
