import {createProject} from '../../../../actions/dashboardActions';
import {createSubscription} from '../../../../actions/organizationActions';
import type {
  CreateSubscriptionData,
  UpdateBillingDetailsData,
} from '../../../../actions/types/organizationActions.types';
import {type CreateProjectParams} from '../../../../api/DashboardApi';
import {PlanNameMapping, type Plans} from '../../../../constants';
import {useAuth} from '../../../../hooks/auth';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useBooleanState} from '../../../../hooks/utils';
import {useCurrentSubscription} from '../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {TRACKING_EVENTS, sendToGa} from '../../../../utils/tracking';
import {useCreateProjectButton} from '../../../projects/CreateProjectButton/hooks/createProjectButton';
import PaymentForm from '../PaymentForm/PaymentForm';
import usePaymentForm from '../PaymentForm/usePaymentForm';
import PaymentModal from '../PaymentModal/PaymentModal';
import MarketplaceSelection from './MarketplaceSelection/MarketplaceSelection';
import PaymentLoadingCard from './PaymentLoadingCard/PaymentLoadingCard';
import UpgradeSuccessCard from './UpgradeSuccessCard/UpgradeSuccessCard';
import {useDefaultServerlessProjectData} from './utils';

interface SubscribeModalProps {
  open: boolean;
  onClose: () => void;
  plan: Plans;
  title?: string;
  subtitle?: string | React.ReactNode;
  submitText?: string;
  includeCancel?: boolean;
}

function SubscribeModal({
  open,
  onClose,
  plan,
  title,
  subtitle,
  submitText,
  includeCancel = true,
}: SubscribeModalProps) {
  const currentSubscription = useCurrentSubscription();
  const isLoading = currentSubscription?.loading;
  const isSubscribed = currentSubscription?.data;
  const {
    val: isCreditCard,
    setTrue: setCreditCard,
    setFalse: clearCreditCard,
  } = useBooleanState(false);

  const {user} = useAuth();
  const close = () => {
    clearCreditCard();
    onClose();
  };
  const selectedOrgId = useSelectedOrganizationId();
  const {modal: createProjectModal} = useCreateProjectButton();
  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    userId: string;
    params: CreateProjectParams;
  }>();
  const defaultData = useDefaultServerlessProjectData(selectedOrgId);
  const getStarted = () => {
    setQueryData({
      action: createProject,
      data: defaultData,
    });
  };

  const {setQueryData: setCreateSubscriptionQueryData} = useDashboardApi<CreateSubscriptionData>();

  const submit = async (queryData: UpdateBillingDetailsData) => {
    sendToGa(TRACKING_EVENTS.UPGRADE);
    setCreateSubscriptionQueryData({
      action: createSubscription,
      data: {plan, ...queryData},
    });
  };

  const paymentFormProps = usePaymentForm({
    currentBillingDetails: {email: user?.email},
    onCancel: close,
  });
  const {cancel} = paymentFormProps;

  return (
    <>
      {createProjectModal}
      <PaymentModal
        open={open}
        onClose={cancel}
        title={title || `Subscribe to ${PlanNameMapping[plan]}`}
        subtitle={subtitle || 'Add a payment option:'}
        withHeader={!isLoading && !isSubscribed}
      >
        <PaymentLoadingCard show={isLoading} />
        <UpgradeSuccessCard
          show={isSubscribed && !isLoading}
          getStarted={getStarted}
          onClose={onClose}
        />
        <PaymentForm
          includeCancel={includeCancel}
          onSuccess={submit}
          show={isCreditCard && !isLoading && !isSubscribed}
          submitText={submitText || 'Upgrade'}
          {...paymentFormProps}
        />
        <MarketplaceSelection
          show={!isCreditCard && !isLoading && !isSubscribed}
          setCreditCard={setCreditCard}
        />
      </PaymentModal>
    </>
  );
}

export default SubscribeModal;
