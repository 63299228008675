import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import ModalForm from '../../../../../components/ModalForm';
import {type VectorState} from '../../../../../reducers/explorerReducer';
import {useVectorInfo} from '../../../../../selectors/explorer';
import {MODAL_WIDTH_MEDIUM} from '../../../../../styles/theme';
import {type UpsertSchemaType, createUpsertSchema} from '../utilities/schema';
import {type IndexExplorerState, buildInputsFromMetadata, useDoUpsert} from '../utilities/utils';
import VectorFormControls from './VectorFormControls';

type VectorFormModalProps = {
  open: boolean;
  close: () => void;
  typeName: string;
  indexExplorerState: IndexExplorerState;
  vectorId?: string;
  namespace?: string;
  onlySubmitWhenDirty?: boolean;
  allowAutoType: boolean;
};

const defaultValues = {
  id: '',
  vector: [],
  metadata: [],
  namespace: '',
};

const defaultValuesFromVector = (vector: VectorState, namespace?: string) => {
  return {
    id: vector.id,
    vector: vector.values,
    metadata: buildInputsFromMetadata(vector.metadata),
    namespace: namespace || '',
    sparse: vector.sparseValues
      ? {
          values: vector.sparseValues.values,
          indices: vector.sparseValues.indices,
        }
      : undefined,
  };
};

function VectorFormModal({
  open,
  close,
  typeName,
  indexExplorerState,
  vectorId,
  namespace,
  allowAutoType,
  onlySubmitWhenDirty = false,
}: VectorFormModalProps) {
  const {project, service, dimensions, host} = indexExplorerState;
  const vector = useVectorInfo(project.id, service, vectorId || '', namespace || '');
  const schema = useMemo(() => createUpsertSchema(dimensions), [dimensions]);
  const upsertFormState = useForm<UpsertSchemaType>({
    defaultValues: vector ? defaultValuesFromVector(vector, namespace) : defaultValues,
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    formState: {isDirty},
  } = upsertFormState;
  useEffect(() => {
    if (vector) {
      reset(defaultValuesFromVector(vector, namespace));
    }
  }, [vector, namespace, reset]);
  const onSubmit = useDoUpsert(project, service, host, () => {
    reset();
    close();
  });
  return (
    <ModalForm
      open={open}
      close={close}
      title={`${typeName} Vector`}
      width={MODAL_WIDTH_MEDIUM}
      form={
        <VectorFormControls
          typeName={typeName}
          indexExplorerState={indexExplorerState}
          vectorId={vectorId}
          namespace={namespace}
          allowAutoType={allowAutoType}
          upsertFormState={upsertFormState}
        />
      }
      isValid={!onlySubmitWhenDirty || isDirty}
      onSubmit={handleSubmit(onSubmit)}
      submitText={typeName}
      submitButtonId={`explorer-${typeName}-vector-submit`}
    />
  );
}

export default VectorFormModal;
