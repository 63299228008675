import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import {type Control, type Path} from 'react-hook-form';

interface QuotaFormElementProps<T extends {quota: number}> {
  value: number;
  disabled?: boolean;
  control: Control<T>;
}

function QuotaFormElement<T extends {quota: number}>({
  value,
  control,
  disabled = false,
}: QuotaFormElementProps<T>) {
  return (
    <NumberInput
      name={'quota' as Path<T>}
      control={control}
      label="Pod Limit"
      value={value}
      fullWidth
      disabled={disabled}
    />
  );
}

export default QuotaFormElement;
