import {useMemo} from 'react';
import {useAppSelector as useSelector} from '../app/store';

export const useBackupsByProjectId = (projectId: string) => {
  return useSelector((state) => state.backups.projects[projectId]?.backups);
};

export const useBackupsByIndexId = (projectId: string, indexId: string) => {
  const backups = useBackupsByProjectId(projectId);
  return useMemo(() => {
    return (backups?.data || []).filter((backup) => backup.index_id === indexId);
  }, [backups, indexId]);
};
