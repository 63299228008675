import {Box, Button, Typography} from '@mui/material';
import {useRef} from 'react';
import {Link} from 'react-router-dom';
import {PUBLIC_DOCS_URL} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useNavigateToSupport} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {useSelectedOrganization} from '../../../../selectors/organizations';
import {APP_BAR_HEIGHT} from '../../../../styles/theme';
import ThemeAwarePineconeLogo from '../../../Utilities/ThemeAwarePineconeLogo';
import FeedbackModal from './FeedbackModal/FeedbackModal';
import GetHelpMenu from './GetHelpMenu/GetHelpMenu';
import {NavButtonStyles} from './NavButton/NavButton';
import OrganizationSelector from './OrganizationSelector/OrganizationSelector';
import ProjectSelector from './ProjectSelector/ProjectSelector';
import ThemeSwitcher from './ThemeSwitcher/ThemeSwitcher';
import UserMenu from './UserMenu/UserMenu';

const styles = {
  root: {
    height: APP_BAR_HEIGHT,
    width: '100%',
    gridArea: 'appBar',
    borderBottom: 1,
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    px: 3,
  },
  logo: {
    mb: 0.5,
    mr: 0.5,
  },
  selectors: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  selector: {
    display: 'grid', // Ensures width of element is set by parent's flexbox.
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
  },
  externalLinks: {
    mx: 2,
  },
  navButton: {
    flexShrink: 0,
    px: 2,
  },
};

function AppBar() {
  const selectedOrg = useSelectedOrganization();
  const {go: goToSupportPage} = useNavigateToSupport(selectedOrg?.id);
  const {val: openFeedback, setTrue: setOpenFeedback, setFalse: closeFeedback} = useBooleanState();
  const feedbackAnchor = useRef(null);
  const enableSupportPlans = useFlag(Flags.ENABLE_SUPPORT_PLANS);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles.selectors}>
          <Box sx={styles.logo}>
            <Link to="/">
              <ThemeAwarePineconeLogo fullLogo sx={{height: 21}} />
            </Link>
          </Box>
          <Typography color="secondary">/</Typography>
          <Box sx={styles.selector}>
            <OrganizationSelector />
          </Box>
          <Typography color="secondary">/</Typography>
          <Box sx={styles.selector}>
            <ProjectSelector />
          </Box>
        </Box>
        <Box sx={styles.actions}>
          <Box sx={[styles.actions, styles.externalLinks]}>
            <Button
              onClick={setOpenFeedback}
              ref={feedbackAnchor}
              sx={[NavButtonStyles, styles.navButton]}
            >
              Feedback
            </Button>
            <FeedbackModal
              open={openFeedback}
              close={closeFeedback}
              anchor={feedbackAnchor.current}
              placement="bottom-end"
            />
            {enableSupportPlans ? (
              <GetHelpMenu />
            ) : (
              <>
                <Button
                  href={PUBLIC_DOCS_URL}
                  target="_blank"
                  sx={[NavButtonStyles, styles.navButton]}
                >
                  Docs
                </Button>
                <Button onClick={goToSupportPage} sx={[NavButtonStyles, styles.navButton]}>
                  Support
                </Button>
              </>
            )}
          </Box>
          <ThemeSwitcher />
          <UserMenu />
        </Box>
      </Box>
    </Box>
  );
}

export default AppBar;
