import {type CartesianMarkerProps, type DatumValue} from '@nivo/core';

export const markers = [
  {
    axis: 'y',
    value: 95,
    lineStyle: {
      stroke: 'rgba(255, 0, 0, .25)',
      strokeWidth: 30,
    },
  },
  {
    axis: 'y',
    value: 85,
    lineStyle: {
      stroke: 'rgba(255, 255, 0, .25)',
      strokeWidth: 30,
    },
  },
  {
    axis: 'y',
    value: 80,
    lineStyle: {
      stroke: 'rgba(255, 255, 0, .75)',
      strokeWidth: 2,
      strokeDasharray: 5,
    },
  },
  {
    axis: 'y',
    value: 90,
    lineStyle: {
      stroke: 'rgba(255, 128, 0, .75)',
      strokeWidth: 2,
      strokeDasharray: 5,
    },
  },
  {
    axis: 'y',
    value: 100,
    lineStyle: {
      stroke: 'rgba(255, 0, 0, .75)',
      strokeWidth: 2,
      strokeDasharray: 5,
    },
  },
] as CartesianMarkerProps<DatumValue>[];
