export const inputStyles = {
  root: {
    my: 0.5,
  },
  input: {
    py: 0.25,
    borderRadius: 1,
    backgroundColor: 'background.paper',
    minWidth: 100,
    minHeight: 42,

    // Controls border styling on input
    '> fieldset': {
      borderColor: 'divider',
    },
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  label: {
    display: 'flex',
    alignItems: 'end',
    mb: 0.5,
  },
  error: {
    color: 'error.main',
    height: 10,
    mt: 0.25,
    mb: 0.5,
  },
};

export const menuStyles = {
  menu: {
    mt: 0.5,
    backgroundColor: 'background.paper',
    borderRadius: 1.5,
    ul: {
      py: 0.5,
      maxHeight: 500,
    },
    li: {
      gap: 1,
      borderRadius: 1,
      mx: 0.5,
      pr: 0.5,
      pl: 2,
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      },
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    },
  },
  menuIcon: {
    mr: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuCheckBox: {
    p: 0,
    ml: -1,
    color: 'primary.main',
  },
  menuCheckmark: {
    ml: 'auto',
    color: 'primary.main',
  },
};
