import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {useBooleanState} from '../../../../../../hooks/utils';
import {type BulkImportStatus} from '../../../../../../types';

interface ImportActionMenuProps {
  id: string;
  status?: BulkImportStatus;
  onCancel: (id: string) => void;
}

export default function ImportActionMenu({id, status, onCancel}: ImportActionMenuProps) {
  const {
    val: showCancelModal,
    setTrue: openCancelModal,
    setFalse: closeCancelModal,
  } = useBooleanState();

  const canCancelImport = status === 'InProgress' || status === 'Pending';

  return (
    <>
      <SelectorMenuButton
        id={`${id}-actions-showActions`}
        iconSx={{m: 0}}
        ariaLabel="Import actions"
        placement="bottom-end"
        items={[
          {
            id: 'cancel',
            name: 'Cancel',
            icon: <CloseOutlinedIcon fontSize="small" />,
            tooltip: canCancelImport
              ? undefined
              : `Import cannot be canceled when status is ${status}`,
            onClick: canCancelImport ? openCancelModal : undefined,
          },
        ]}
      />
      <DeleteConfirmationModal
        open={showCancelModal}
        close={closeCancelModal}
        titleAction="cancellation"
        action="Cancel"
        itemType="Import"
        name={`Import ID ${id}`}
        doDelete={() => onCancel(id)}
        skipCheck
        warningText="This action is permanent and cannot be undone."
      />
    </>
  );
}
