import {useMemo} from 'react';
import {useAppSelector as useSelector} from '../app/store';
import {useSelectedGlobalProjectId, useSelectedService} from './params';
import {useGlobalProjectsFromSelectedOrganization} from './projects';

const emptyArr: never[] = [];
const emptyMap = {};

export const useHasServerlessIndexesInOrg = () => {
  const projects = useGlobalProjectsFromSelectedOrganization();
  return useSelector((state) => {
    return projects.some((project) => {
      return Object.values(state.services.projects[project.id]?.services || {}).some((index) => {
        return 'serverless' in index.spec;
      });
    });
  });
};

export const useServicesByGlobalProject = (projectId: string) => {
  return useSelector((state) => state.services.projects[projectId]?.items || emptyArr);
};

export const useServiceSpecsByGlobalProject = (projectId: string) => {
  return useSelector((state) => {
    const services = state.services.projects[projectId]?.items || emptyArr;
    return services.map(
      (service) => state.services.projects[projectId]?.services[service] || emptyMap,
    );
  });
};

export const useStarterServices = () => {
  const projects = useGlobalProjectsFromSelectedOrganization();
  return useSelector((state) => {
    return projects
      .map((project) => {
        const projServices = state.services.projects[project.id]?.items || [];
        return projServices
          .filter((service) => {
            const {spec} = state.services.projects[project.id]?.services[service] || {};
            const podSpec = spec && 'pod' in spec ? spec.pod : null;
            return podSpec?.pod_type === 'starter';
          })
          .map((service) => ({service, globalProjectId: project.id}));
      })
      .flat();
  });
};

export const usePendingDatasetsByGlobalProject = (projectId: string) => {
  return useSelector((state) => state.services.projects[projectId]?.pendingDatasets || emptyMap);
};

export const usePendingDatasetByGlobalProject = (projectId: string, service: string) => {
  return usePendingDatasetsByGlobalProject(projectId)[service];
};

export const useServicesAreLoadingByGlobalProject = (projectId: string) => {
  return useSelector((state) => state.services.projects[projectId]?.loading ?? true);
};

export const useGlobalProjectServices = (projectId: string) => {
  return useSelector((state) => {
    return state.services.projects[projectId]?.services || emptyMap;
  });
};

export const useServiceByGlobalProject = (projectId: string, service: string) => {
  const services = useGlobalProjectServices(projectId);
  return services[service];
};

export const usePodServicesByGlobalProject = (projectId: string) => {
  const services = useGlobalProjectServices(projectId);
  return Object.keys(services).filter((service) => {
    return 'pod' in services[service].spec;
  });
};

export const useServiceStatusByGlobalProject = (projectId: string, service: string) => {
  const serviceData = useServiceByGlobalProject(projectId, service);
  return serviceData?.status || emptyMap;
};

export const useSelectedServiceData = () => {
  const projectId = useSelectedGlobalProjectId() || '';
  const service = useSelectedService() || '';
  return useServiceByGlobalProject(projectId, service);
};

export const useMigrationOperations = (projectId: string) => {
  return useSelector((state) => state.services.projects[projectId]?.migrationOperations);
};

export const useMigrationOperation = (projectId: string, serviceName: string) => {
  const operations = useMigrationOperations(projectId);
  return operations?.[serviceName];
};

export const useIndexById = (projectId: string, indexId: string) => {
  const specs = useServiceSpecsByGlobalProject(projectId);
  return useMemo(() => {
    return specs.find((spec) => spec.id === indexId);
  }, [specs, indexId]);
};
