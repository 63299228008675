import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import {Box} from '@mui/material';
import {assertUnreachable} from '../../../../../utils/types';
import {GuideStatus} from '../utilities/GuideContext';

const styles = {
  statusBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: '100%',
    border: '1px solid',
    borderColor: 'background.surface',
    transition: 'transform 0.3s',
    fontSize: 20,
  },
  rotate: {
    transform: 'rotate(-90deg)',
  },
  statusBoxInComplete: {
    border: 1,
    borderStyle: 'dashed',
    borderColor: 'action.disabled',
  },
};

export function StatusMarker({status, open}: {status: GuideStatus; open: boolean}) {
  switch (status) {
    case GuideStatus.IN_PROGRESS:
      return (
        <Box sx={[styles.statusBox, open && styles.rotate]}>
          <ArrowDownwardIcon />
        </Box>
      );
    case GuideStatus.COMPLETE:
      return (
        <Box sx={styles.statusBox}>
          <CheckIcon color="success" />
        </Box>
      );
    case GuideStatus.INCOMPLETE:
    case GuideStatus.SKIPPED:
      return <Box sx={[styles.statusBox, styles.statusBoxInComplete]} />;
    default:
      return assertUnreachable(status);
  }
}

export function getTitleColor({
  sectionStatus,
  blockStatus,
}: {
  sectionStatus: GuideStatus;
  blockStatus: GuideStatus;
}) {
  if (blockStatus === GuideStatus.COMPLETE || blockStatus === GuideStatus.IN_PROGRESS) {
    return 'text.primary';
  }
  if (sectionStatus === GuideStatus.IN_PROGRESS && blockStatus === GuideStatus.INCOMPLETE) {
    return 'text.secondary';
  }
  return 'action.disabled';
}
