import {assertUnreachable} from '../../../../../utils/types';
import AddRecordPage from '../AddRecordPage/AddRecordPage';
import ListExplorer from '../ListExplorer/ListExplorer';
import QueryExplorer from '../QueryExplorer/QueryExplorer';
import {type QueryExplorerState} from '../QueryExplorer/utils';
import {type IndexExplorerState} from '../utilities/utils';

export enum ExplorerPages {
  QUERY = 'query',
  LIST = 'list',
  ADD = 'add',
}

type ExplorerPageProps = {
  indexExplorerState: IndexExplorerState;
  page: ExplorerPages;
  queryExplorerState: QueryExplorerState;
  onSubmitQueryExplorer: () => void;
};

function ExplorerPage({
  indexExplorerState,
  page,
  queryExplorerState,
  onSubmitQueryExplorer,
}: ExplorerPageProps) {
  switch (page) {
    case ExplorerPages.QUERY:
      return (
        <QueryExplorer
          indexExplorerState={indexExplorerState}
          queryExplorerState={queryExplorerState}
        />
      );
    case ExplorerPages.LIST:
      return (
        <ListExplorer
          indexExplorerState={indexExplorerState}
          setQueryExplorerFormQuery={queryExplorerState.setFormQuery}
          onSubmitQueryExplorer={onSubmitQueryExplorer}
        />
      );
    case ExplorerPages.ADD:
      return <AddRecordPage indexExplorerState={indexExplorerState} />;
    default:
      return assertUnreachable(page);
  }
}

export default ExplorerPage;
