import {type QuotaKeys, type QuotaLimit} from '@pinecone-experience/commons/quotas';
import {createReducer, isAnyOf} from '@reduxjs/toolkit';
import {
  getProjectQuotaLimitsFailure,
  getProjectQuotaLimitsRequest,
  getProjectQuotaLimitsSuccess,
} from '../actions/quotaActions';

interface ProjectState {
  loading: boolean;
  quotaLimits: Record<QuotaKeys, QuotaLimit>;
}

const initialProjectState = {
  loading: true,
  quotaLimits: {},
} as ProjectState;

interface QuotaLimitsState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as QuotaLimitsState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(getProjectQuotaLimitsRequest, (state) => {
      state.loading = true;
    })
    .addCase(getProjectQuotaLimitsSuccess, (state, action) => {
      state.quotaLimits = action.payload.quotaLimits;
      state.loading = false;
    })
    .addCase(getProjectQuotaLimitsFailure, (state) => {
      state.loading = false;
    });
});

const quotaLimitsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(
    isAnyOf(
      getProjectQuotaLimitsFailure,
      getProjectQuotaLimitsRequest,
      getProjectQuotaLimitsSuccess,
    ),
    (state, action) => {
      state.projects[action.payload.projectId] = projectReducer(
        state.projects[action.payload.projectId],
        action,
      );
    },
  );
});

export default quotaLimitsReducer;
