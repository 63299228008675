import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import {DataVisColors} from '@pinecone-experience/theme/colors';
import {
  TIME_INTERVAL,
  type TimeInterval,
} from '@pinecone-experience/timber/charts/TimestampLineChart/constants/time';
import {calculateTimePeriod} from '@pinecone-experience/timber/charts/TimestampLineChart/utils/calculateTimePeriod';
import {formatDistance} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {
  podFullnessPercentageQuery,
  readUnitTotalQuery,
  requestDurationQuery,
  requestRecordTotalQuery,
  requestStatusQuery,
  requestTotalQuery,
  storageSizeQuery,
  writeUnitTotalQuery,
} from '../../../../actions/prometheusActions';
import {LocalStorageKeys} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useLocalStorageState} from '../../../../hooks/utils';
import {formatMilliseconds} from '../../../../utils/format';
import IndexTabHeader from '../IndexTabHeader/IndexTabHeader';
import MetricChart from './MetricChart/MetricChart';

interface IndexMetricsProps {
  service: string;
  projectId: string;
  isServerless: boolean;
}

const styles = {
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  updatedLabel: {
    mb: 0.5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2,
  },
};

const UPDATED_AT_REFRESH_INTERVAL = 60000; // 1 minute

export default function IndexMetrics({service, projectId, isServerless}: IndexMetricsProps) {
  const enableRequestsByStatusChart = useFlag(Flags.ENABLE_REQUESTS_BY_STATUS_CHART);
  const {val: timeInterval, setVal: setStoredTimeInterval} = useLocalStorageState<TimeInterval>(
    LocalStorageKeys.INDEX_METRICS_INTERVAL,
    TIME_INTERVAL['4hrs'],
  );
  const [endDate, setEndDate] = useState(new Date());
  const [updatedAt, setUpdatedAt] = useState(
    formatDistance(endDate, new Date(), {addSuffix: true}),
  );
  const timePeriod = calculateTimePeriod(timeInterval, endDate);

  useEffect(() => {
    setUpdatedAt(formatDistance(endDate, new Date(), {addSuffix: true}));

    const intervalId = setInterval(() => {
      setUpdatedAt(formatDistance(endDate, new Date(), {addSuffix: true}));
    }, UPDATED_AT_REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [endDate]);

  const setTime = useCallback(
    (interval: TimeInterval) => {
      setStoredTimeInterval(interval);
      setEndDate(new Date());
    },
    [setStoredTimeInterval],
  );

  const onSetInterval = useCallback(
    (_: React.MouseEvent, val?: string) => {
      if (val) {
        setTime(val as TimeInterval);
      }
    },
    [setTime],
  );

  return (
    <Box>
      <IndexTabHeader
        title="Metrics"
        subtitle="All metrics are represented in your local timezone."
        actions={
          <>
            <Box sx={styles.updatedLabel}>
              <Typography variant="caption" color="textSecondary">
                Updated {updatedAt}
              </Typography>
            </Box>
            <Box sx={styles.controls}>
              <IconButton
                aria-label="Refresh metrics"
                sx={{mt: '-2px', mr: 1}}
                size="small"
                color="secondary"
                id="refresh-interval"
                onClick={() => setTime(timeInterval)}
              >
                <RefreshIcon color="secondary" />
              </IconButton>
              <ToggleButtonGroup exclusive value={timeInterval} onChange={onSetInterval}>
                {Object.values(TIME_INTERVAL).map((int) => (
                  <ToggleButton key={int} sx={{py: 0}} value={int}>
                    {int}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          </>
        }
      />
      <Box sx={styles.charts}>
        {isServerless && (
          <>
            <MetricChart
              metric="readUnitsTotal"
              query={readUnitTotalQuery}
              title="Read units"
              unit="/s"
              tooltip="RUs measure the resources consumed by read operations such as query, fetch, and list."
              lines={[{dataKey: 'RURate', label: 'RU rate'}]}
              projectId={projectId}
              service={service}
              start={timePeriod.start}
              end={timePeriod.end}
              interval={timeInterval}
              yAxisFloor={0.1}
            />
            <MetricChart
              metric="writeUnitsTotal"
              query={writeUnitTotalQuery}
              title="Write units"
              unit="/s"
              tooltip="WUs measure the resources consumed by write operations such as upsert, update, and delete."
              lines={[{dataKey: 'WURate', label: 'WU rate'}]}
              projectId={projectId}
              service={service}
              start={timePeriod.start}
              end={timePeriod.end}
              interval={timeInterval}
              yAxisFloor={0.1}
            />
          </>
        )}
        <MetricChart
          metric="requestTotal"
          query={requestTotalQuery}
          title="Requests per second"
          unit="/s"
          tooltip="The number of requests successfully executed per second by endpoint"
          lines={[
            {dataKey: 'query'},
            {dataKey: 'upsert', color: DataVisColors.PURPLE},
            {dataKey: 'update', color: DataVisColors.AQUA},
            {dataKey: 'delete', color: DataVisColors.ORANGE},
          ]}
          projectId={projectId}
          service={service}
          start={timePeriod.start}
          end={timePeriod.end}
          interval={timeInterval}
          yAxisFloor={0.1}
        />
        {enableRequestsByStatusChart && isServerless && (
          <MetricChart
            metric="requestStatus"
            query={requestStatusQuery}
            title="Requests per second by status"
            unit="/s"
            tooltip="The number of requests successfully executed per second by status code"
            lines={[
              {dataKey: '2xx', label: '2xx'},
              {dataKey: '4xx', label: '4xx', color: DataVisColors.AMBER},
              {dataKey: '5xx', label: '5xx', color: DataVisColors.ORANGE},
            ]}
            projectId={projectId}
            service={service}
            start={timePeriod.start}
            end={timePeriod.end}
            interval={timeInterval}
            endpoints={['query', 'upsert', 'update', 'delete']}
            yAxisFloor={0.1}
          />
        )}
        <MetricChart
          metric="requestDuration"
          query={requestDurationQuery}
          title="Request latency"
          unit="ms"
          tooltip="Latency is the time in seconds between when the time the server receives a request and executes it. It does not include returning responses to the client"
          lines={[
            {dataKey: 'bucket_p50', label: 'p50'},
            {dataKey: 'bucket_p95', label: 'p95', color: DataVisColors.AQUA},
            {dataKey: 'bucket_p99', label: 'p99', color: DataVisColors.PURPLE},
          ]}
          projectId={projectId}
          service={service}
          start={timePeriod.start}
          end={timePeriod.end}
          interval={timeInterval}
          endpoints={['query', 'upsert', 'update', 'delete']}
          yAxisFloor={100}
          yAxisFormatter={(value) => formatMilliseconds(Number(value))}
          yAxisShowUnits={false}
        />
        {isServerless && (
          <MetricChart
            metric="storageSize"
            query={storageSizeQuery}
            title="Storage size"
            unit=" GB"
            tooltip="The amount of data stored in an index"
            lines={[{dataKey: 'storageSize', label: 'Storage'}]}
            projectId={projectId}
            service={service}
            start={timePeriod.start}
            end={timePeriod.end}
            interval={timeInterval}
            yAxisFloor={1}
          />
        )}
        <MetricChart
          metric="recordTotal"
          query={requestRecordTotalQuery}
          title="Record count"
          tooltip="The number of records stored in the index"
          lines={[{dataKey: 'recordTotal', label: 'Record count'}]}
          projectId={projectId}
          service={service}
          start={timePeriod.start}
          end={timePeriod.end}
          interval={timeInterval}
          yAxisFloor={9}
        />
        {!isServerless && (
          <MetricChart
            metric="podFullnessPercentage"
            query={podFullnessPercentageQuery}
            title="Pod Fullness"
            tooltip="Total pod usage including both vectors and metadata. Pod fullness is measured in increments of 10%."
            unit="%"
            lines={[{dataKey: 'podFullness', label: 'Fullness'}]}
            projectId={projectId}
            service={service}
            start={timePeriod.start}
            end={timePeriod.end}
            interval={timeInterval}
            referenceAreas={[
              {y1: 90, y2: 100, color: DataVisColors.ORANGE},
              {y1: 80, y2: 90, color: DataVisColors.AMBER},
            ]}
            yAxisFloor={100}
          />
        )}
      </Box>
    </Box>
  );
}
