export function getFormattedDate(dateString: string) {
  try {
    const date = new Date(dateString).toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    return date;
  } catch {
    return '';
  }
}
