import Table from '@pinecone-experience/timber/Table';
import {GUTTER} from '../../../../../styles/theme';
import {type AssistantFile} from '../../../../../types';
import FileCell from './FileCell/FileCell';

interface FilesTableProps {
  files: AssistantFile[];
  loading?: boolean;
  getFilePath: (id: string) => string;
}

export default function FilesTable({files, loading, getFilePath}: FilesTableProps) {
  const cellRenderer = (row: number, col: number) => {
    return <FileCell file={files[row]} col={col} getPath={getFilePath} />;
  };

  return (
    <Table
      isLoading={loading}
      numRows={files.length}
      cellRenderer={cellRenderer}
      pagination={{labelled: false, sizeControl: true}}
      columns={[
        {name: 'Name', dataType: 'string'},
        {name: 'Last updated', dataType: 'string'},
        {name: 'Size', dataType: 'string'},
        {name: 'Status', dataType: 'string'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      gutter={GUTTER}
    />
  );
}
