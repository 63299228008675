import {useTheme} from '@mui/material/styles';
import {useState} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartLineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {type NameType, type ValueType} from 'recharts/types/component/DefaultTooltipContent';
import {LegendText} from '../LegendText/LegendText';
import {Tick, type TickProps} from '../Tick/Tick';
import TooltipContent, {type LabelConfig} from '../Tooltip/Tooltip';
import {getLineLabel} from '../utils/getLineLabel';

export interface LineConfig {
  dataKey: string;
  label?: string;
  color?: string;
}

interface XAxisConfig {
  dataKey: string;
  formatter?: (value: string) => string;
  ticks?: string[];
}

export interface YAxisConfig {
  formatter?: (value: string) => string;
  domain?: [number, number];
  showUnits?: boolean;
}

export interface ReferenceAreaConfig {
  y1: number;
  y2: number;
  color: string;
}

interface LineChartProps {
  title: string;
  data: Record<string, number | string | null>[];
  lines: LineConfig[];
  xAxis: XAxisConfig;
  yAxis?: YAxisConfig;
  tooltipLabel?: LabelConfig;
  unit?: string;
  referenceAreas?: ReferenceAreaConfig[];
  disableAnimation?: boolean;
}

// TODO(cboltt): Consolidate with DataVisColors from theme
const DEFAULT_LINE_COLOR = '#0D3082';
const DEFAULT_YAXIS_WIDTH = 32;

export default function LineChart({
  title,
  unit,
  data,
  lines,
  xAxis,
  yAxis,
  tooltipLabel,
  referenceAreas,
  disableAnimation = false,
}: LineChartProps) {
  const theme = useTheme();
  const [inactiveLines, setInactiveLines] = useState<Array<string>>([]);
  const gridColor = theme.palette.divider;
  const yAxisTick = (tickProps: TickProps) => (
    <Tick
      {...tickProps}
      axis="y"
      formatter={yAxis?.formatter}
      unit={yAxis?.showUnits ? unit : undefined}
    />
  );
  const xAxisTick = (tickProps: TickProps) => (
    <Tick {...tickProps} axis="x" formatter={xAxis.formatter} />
  );
  const tooltipContent = (props: TooltipProps<ValueType, NameType>) => (
    <TooltipContent {...props} labelConfig={tooltipLabel} lines={lines} />
  );
  const legendText = (value: string) => (
    <LegendText value={getLineLabel(lines, value)} inactive={inactiveLines.includes(value)} />
  );

  const handleLegendClick = (dataKey: string) => {
    if (inactiveLines.includes(dataKey)) {
      setInactiveLines(inactiveLines.filter((el) => el !== dataKey));
    } else {
      setInactiveLines((prev) => [...prev, dataKey]);
    }
  };

  return (
    <ResponsiveContainer>
      <RechartLineChart
        data-testId="line-chart"
        title={title}
        syncId="anyId"
        accessibilityLayer
        data={data}
        margin={{top: 16, right: 32, bottom: 16, left: 32}}
      >
        <CartesianGrid vertical={false} stroke={gridColor} />
        <Tooltip content={tooltipContent} cursor={{stroke: gridColor}} />
        <YAxis
          width={DEFAULT_YAXIS_WIDTH}
          tickMargin={8}
          tickLine={false}
          axisLine={false}
          tick={yAxisTick}
          domain={yAxis?.domain}
          tickFormatter={yAxis?.formatter}
        />
        <XAxis
          tickMargin={24}
          dataKey={xAxis.dataKey}
          tickLine={false}
          axisLine={false}
          minTickGap={24}
          tick={xAxisTick}
          tickFormatter={xAxis.formatter}
          ticks={xAxis.ticks}
          interval="equidistantPreserveStart"
        />
        {lines.map((line) => (
          <Line
            isAnimationActive={!disableAnimation}
            key={line.dataKey}
            dataKey={line.dataKey}
            stroke={line.color || DEFAULT_LINE_COLOR}
            unit={unit}
            type="monotone"
            strokeWidth={1.5}
            dot={false}
            opacity={inactiveLines.includes(line.dataKey) ? 0.1 : 1}
          />
        ))}
        {referenceAreas?.map((area) => (
          <ReferenceArea
            key={area.y1}
            y1={area.y1}
            y2={area.y2}
            fill={area.color}
            stroke={area.color}
            fillOpacity={0.1}
            strokeDasharray="3 3"
            ifOverflow="extendDomain"
          />
        ))}
        {lines.length > 1 && (
          <Legend
            wrapperStyle={{bottom: 0}}
            iconType="rect"
            formatter={legendText}
            onClick={({dataKey}) => handleLegendClick(dataKey as string)}
          />
        )}
      </RechartLineChart>
    </ResponsiveContainer>
  );
}
