import {type QuotaKeys, type QuotaLimit} from '@pinecone-experience/commons/quotas';
import * as Sentry from '@sentry/react';
import type Orb from 'orb-billing';
import type Stripe from 'stripe';
import {type GetStartedProgressLog} from '../actions/types/getStartedGuideActions.types';
import {
  type OrgBillingDetails,
  type ServerlessSavings,
  type UpdateSubscriptionPlanResponse,
} from '../actions/types/organizationActions.types';
import {type FeedbackData} from '../components/app/MainLayout/AppBar/FeedbackModal/utilities/schema';
import {type ChurnData} from '../components/app-modals/ChurnModal/utils/schema';
import {type RegistrationData} from '../components/app-modals/RegistrationModal/utils/schema';
import {type SchemaType as RegionRequestData} from '../components/environment/RegionPicker/RegionRequestModal/utilities/schema';
import {DASHBOARD_BASE_URL_CLOUDRUN, type Plans, type Roles, type SupportTiers} from '../constants';
import {type ModelSuggestionData} from '../pages/indexes/IndexCreationPage/SetupWithModelButton/ModelSuggestionForm/ModelSuggestionSchema';
import {type ConnectIndexVercel} from '../pages/integrations/vercel/OAuthVercel2/ConnectIndex/ConnectIndex';
import {type CreateConfigType} from '../pages/org-settings/AccountPage/SsoControls/SsoForm/utilities/schema';
import {type UsageDatapoint} from '../pages/org-settings/UsagePage/Usage/config';
import {
  type ApiKey,
  type Environment,
  type FreeTierDowngradeEligibility,
  type GlobalProject,
  type Integration,
  type IntegrationCreateData,
  type IntegrationUpdateData,
  type Invite,
  type Marketplace,
  type MarketplaceSetupOptions,
  type MemberOrganization,
  type MemberProject,
  type Organization,
  type RbacRoles,
  type SsoConnectionConfig,
  type SsoConnectionRule,
  type User,
  type UserMember,
} from '../types';
import {type VercelOAuthConnection} from '../types/vercel';
import type {
  CreateSubscriptionReqBody,
  UpdateBillingDetailsReqBody,
} from './types/dashboardApi.types';

export interface EditSsoRules {
  orgRole: Roles;
  projects: {
    id: string;
    role: Roles;
  }[];
}

type FetchOptions = {
  headers?: Record<string, string | undefined>;
  method?: string;
  body?: string | FormData;
};

type GetMarketplaceEntityForSetupParams = {
  marketplaceType: string;
  marketplaceId: string;
  setupToken: string | null;
};

type AttachMarketplaceEntityToOrganizationParams = {
  marketplaceType: string;
  organizationId: string;
  marketplaceId: string;
  setupToken: string | null;
};

export type CreateProjectParams = {
  name: string;
  environment: string;
  quota: number;
  force_encryption_with_cmek?: boolean;
};

// TODO: combine w/ promApi
// TODO: create baseApi class for all these instead of rewriting...
export default class DashboardApi {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  rawFetch(url: string, options: FetchOptions = {}) {
    const headers = {
      Authorization: `Bearer ${this.token}`,
      ...options.headers,
    };
    return fetch(`${DASHBOARD_BASE_URL_CLOUDRUN}/dashboard/${url}`, {
      ...options,
      headers,
    });
  }

  fetch<T = void>(url: string, options: FetchOptions = {}) {
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };
    return this.rawFetch(url, options).then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          if (res.status >= 500 && res.status < 600) {
            Sentry.captureException(data.message || data.error);
            throw new Error('A management plane error has occurred.');
          } else {
            throw new Error(data.message || data.error);
          }
        });
      }
      return res.json() as Promise<T>;
    });
  }

  get<T>(url: string, params = {}, options = {}) {
    return this.fetch<T>(`${url}?${new URLSearchParams(params)}`, options);
  }

  post<T>(url: string, params = {}) {
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  postFormData(url: string, body: FormData) {
    return this.rawFetch(url, {
      method: 'POST',
      body,
    });
  }

  delete<T>(url: string, params = {}) {
    const options = {
      method: 'DELETE',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  patch<T>(url: string, params = {}) {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  patchFormData(url: string, body: FormData) {
    return this.rawFetch(url, {
      method: 'PATCH',
      body,
    });
  }

  put<T>(url: string, params = {}) {
    const options = {
      method: 'PUT',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  listApiKeys(projectId: string) {
    return this.get<{keys: ApiKey[]}>(`projects/${projectId}/api-keys`);
  }

  getMarketplaceEntityForSetup({
    marketplaceType,
    marketplaceId,
    setupToken,
  }: GetMarketplaceEntityForSetupParams) {
    return this.get<Marketplace>(`marketplaces/${marketplaceType}/${marketplaceId}/setup`, {
      setup_token: setupToken,
    });
  }

  attachMarketplaceEntityToOrganization({
    marketplaceType,
    organizationId,
    marketplaceId,
    setupToken,
  }: AttachMarketplaceEntityToOrganizationParams) {
    return this.post<Marketplace>(`organizations/${organizationId}/marketplaces/attach`, {
      setupToken,
      marketplaceType,
      marketplaceId,
    });
  }

  createApiKey(projectId: string, label: string, roles: RbacRoles[], integrationId?: string) {
    return this.post<{key: ApiKey}>(`projects/${projectId}/api-key`, {label, integrationId, roles});
  }

  deleteApiKey(projectId: string, label: string, userName: string) {
    return this.delete<{success: boolean}>(`projects/${projectId}/api-key`, {label, userName});
  }

  rotateApiKey(projectId: string, label: string, userName: string, displayLabel: string) {
    return this.post<{key: ApiKey}>(`projects/${projectId}/api-key/rotate`, {
      label,
      userName,
      displayLabel,
    });
  }

  getQuota(projectId: string) {
    return this.get<{quota: number}>(`projects/${projectId}/quota`);
  }

  setQuota(projectId: string, quota: number) {
    return this.post<{quota: number}>(`projects/${projectId}/quota`, {quota});
  }

  getProjectInfo(projectId: string) {
    return this.get<{userRoles: UserMember[]}>(`projects/${projectId}`);
  }

  getOrganizationUsers(organizationId: string) {
    return this.get<{userRoles: UserMember[]}>(`organizations/${organizationId}`);
  }

  editOrganization(organizationId: string, name: string) {
    return this.patch<{success: boolean}>(`organizations/${organizationId}`, {name});
  }

  createProject(organizationId: string, params: CreateProjectParams) {
    return this.post<{globalProject: GlobalProject}>(
      `organizations/${organizationId}/projects`,
      params,
    );
  }

  editProject(projectId: string, editData: object) {
    return this.patch<{success: boolean}>(`projects/${projectId}`, editData);
  }

  deleteProject(projectId: string) {
    return this.delete<{success: boolean}>(`projects/${projectId}`);
  }

  inviteUser(projectId: string, email: string) {
    return this.post<{user: User}>(`projects/${projectId}/users/invite`, {email});
  }

  removeUserFromOrg(organizationId: string, userId: string) {
    return this.delete<{success: boolean}>(`organizations/${organizationId}/users/${userId}`);
  }

  exitOrganization(organizationId: string) {
    return this.post<{success: boolean}>(`organizations/${organizationId}/exit`);
  }

  exitProject(projectId: string) {
    return this.post<{success: boolean}>(`projects/${projectId}/exit`);
  }

  removeUserFromProject(projectId: string, userId: string) {
    return this.delete<{success: boolean}>(`projects/${projectId}/users`, {userId});
  }

  createSubscription(organizationId: string, body: CreateSubscriptionReqBody) {
    // TODO: we can do a bit more here to validate body before sending with zod schemas
    return this.post<Orb.Subscription>(`organizations/${organizationId}/create-subscription`, body);
  }

  createSetupIntent(organizationId: string) {
    return this.post<Stripe.SetupIntent>(`organizations/${organizationId}/create-setup-intent`);
  }

  getBillingDetails(organizationId: string) {
    return this.get<OrgBillingDetails>(`organizations/${organizationId}/billing`);
  }

  updateBillingDetails(organizationId: string, data: UpdateBillingDetailsReqBody) {
    return this.put<OrgBillingDetails>(`organizations/${organizationId}/billing`, data);
  }

  getServerlessSavings(organizationId: string) {
    return this.get<{savings?: ServerlessSavings}>(
      `organizations/${organizationId}/billing/serverless-savings`,
    );
  }

  getPastInvoices(organizationId: string) {
    return this.get<Omit<Stripe.Invoice, 'invoice_pdf'>[]>(
      `organizations/${organizationId}/past-invoices`,
    );
  }

  cancelSubscription(organizationId: string) {
    return this.post<{success: boolean}>(`organizations/${organizationId}/cancel-subscription`);
  }

  updateSubscription(organizationId: string, plan: Plans) {
    return this.post<UpdateSubscriptionPlanResponse>(
      `organizations/${organizationId}/update-subscription`,
      {plan},
    );
  }

  createOrganization(name: string, marketplaceSetupOptions?: MarketplaceSetupOptions) {
    return this.post<{organization: Organization}>('organizations', {
      name,
      ...marketplaceSetupOptions,
    });
  }

  deleteOrganization(organizationId: string) {
    return this.delete<{success: boolean}>(`organizations/${organizationId}`);
  }

  inviteToOrg(
    organizationId: string,
    orgRole: string,
    emails: string[],
    projectRole: Roles,
    projects: string[],
    organization_roles: Record<string, {role: Roles}>,
    project_roles: Record<string, {role: Roles}>,
  ) {
    return this.post<{
      invitesData: Invite[];
      newProjectUsers: Record<string, string[]>;
      orgUsers: User[];
      users: User[];
    }>(`organizations/${organizationId}/invites`, {
      orgRole,
      emails,
      projectRole,
      projects,
      organization_roles,
      project_roles,
    });
  }

  getPendingInvites(organizationId: string) {
    return this.get<{invites: Invite[]}>(`organizations/${organizationId}/invites`);
  }

  removeInvite(organizationId: string, inviteId: string) {
    return this.delete<{success: boolean}>(`organizations/${organizationId}/invites`, {inviteId});
  }

  editOrgRole(organizationId: string, userId: string, role: Roles) {
    return this.patch<{success: boolean}>(`organizations/${organizationId}/users/${userId}`, {
      role,
    });
  }

  editProjectRole(projectId: string, userId: string, role: Roles) {
    return this.patch<{success: boolean}>(`projects/${projectId}/users/${userId}`, {role});
  }

  getOrganizations() {
    return this.get<{
      userId: string;
      newOrgs: MemberOrganization[];
      projects: MemberProject[];
    }>('organizations');
  }

  getUsageData(organizationId: string, startTimestamp: number, endTimestamp: number) {
    return this.get<{usageData: UsageDatapoint[]}>(`organizations/${organizationId}/usage`, {
      startTimestamp,
      endTimestamp,
    });
  }

  getUserInfo() {
    return this.get<{user: User}>('users');
  }

  registerUser(registrationData: RegistrationData, orgId?: string, projectId?: string) {
    return this.post<{user: User; globalOrg: Organization; project?: GlobalProject}>(
      `users/register`,
      {
        orgId,
        projectId,
        registrationData,
      },
    );
  }

  submitChurnSurvey(churnData: ChurnData, orgId?: string) {
    return this.post<{success: boolean}>(`users/churn`, {
      orgId,
      churnData,
    });
  }

  submitFeedback(
    feedbackData: FeedbackData,
    context: {projectId?: string; orgId?: string; location: string},
  ) {
    const formData = new FormData();
    feedbackData.files.forEach((file) => {
      formData.append('files', file.file);
    });
    formData.append('pageUrl', context.location);
    formData.append('feedback', feedbackData.feedback);
    formData.append('mood', feedbackData.mood);
    formData.append('projectId', context.projectId || '');
    formData.append('orgId', context.orgId || '');
    return this.postFormData(`users/submit-feedback`, formData);
  }

  requestRegion(requestData: RegionRequestData, context: {projectId?: string; orgId?: string}) {
    return this.post(`users/region-request`, {...requestData, ...context});
  }

  suggestModel(data: ModelSuggestionData) {
    return this.post<{success: boolean}>(`users/suggest-model`, {
      ...data,
    });
  }

  getSsoConfig(organizationId: string) {
    return this.get<{connection: SsoConnectionConfig; rules: SsoConnectionRule[]}>(
      `organizations/${organizationId}/sso`,
    );
  }

  setupSsoConfig(organizationId: string, config: CreateConfigType) {
    return this.post<{connection: SsoConnectionConfig; rules: SsoConnectionRule[]}>(
      `organizations/${organizationId}/sso`,
      {
        ...config,
        cert: config.cert && btoa(config.cert),
      },
    );
  }

  editSsoConfig(organizationId: string, config: CreateConfigType) {
    return this.patch<{connection: SsoConnectionConfig}>(`organizations/${organizationId}/sso`, {
      ...config,
      cert: config.cert && btoa(config.cert),
    });
  }

  editSsoRules(organizationId: string, data: EditSsoRules) {
    return this.patch<{rules: SsoConnectionRule[]}>(
      `organizations/${organizationId}/sso/rules`,
      data,
    );
  }

  deleteSsoConfig(organizationId: string) {
    return this.delete(`organizations/${organizationId}/sso`);
  }

  connectIndexVercel(data: ConnectIndexVercel) {
    return this.post<{success: boolean}>(`projects/${data.projectId}/vercel/inject-index`, {
      ...data,
    });
  }

  createVercelOAuthConnection(data: {code: string; oauthId?: string}) {
    return this.post<{connection: VercelOAuthConnection}>(`oauth/vercel`, {
      ...data,
    });
  }

  connectOrganizationOAuth(data: {id: string; organizationId: string}) {
    return this.post<{organizationId: string}>(`organizations/${data.organizationId}/link-oauth`, {
      ...data,
    });
  }

  downloadInvoice(data: {invoiceId: string; organizationId: string}) {
    return this.rawFetch(
      `organizations/${data.organizationId}/download-invoice/${data.invoiceId}`,
      {
        headers: {
          'Content-Type': 'application/pdf',
        },
      },
    ).then((res) => res.blob());
  }

  getArchived(projectId: string) {
    return this.get<{archived: {indexName: string}[]}>(`projects/${projectId}/migrations/archived`);
  }

  getProjectQuotaLimits(projectId: string) {
    return this.get<{quotaLimits: Record<QuotaKeys, QuotaLimit>}>(
      `projects/${projectId}/quotas/limits`,
    );
  }

  getProjectQuotaUtilization(projectId: string) {
    return this.get<{quotaUtilization: Record<QuotaKeys, number>}>(
      `projects/${projectId}/quotas/utilization`,
    );
  }

  getStartedGuideLogProgress(data: GetStartedProgressLog) {
    return this.post<{success: boolean}>(`projects/${data.projectId}/guides/log-progress`, data);
  }

  getFreeTierDowngradeEligibility(organizationId: string) {
    return this.get<Omit<FreeTierDowngradeEligibility, 'organizationId'>>(
      `organizations/${organizationId}/free-tier-downgrade-eligibility`,
    );
  }

  getOrganizationEnvironments(organizationId: string) {
    return this.get<{environments: Environment[]}>(`organizations/${organizationId}/environments`);
  }

  listIntegrations(organizationId: string) {
    return this.get<{integrations: Integration[]}>(`organizations/${organizationId}/integrations`);
  }

  getIntegration(organizationId: string, integrationId: string) {
    return this.get<Integration>(`organizations/${organizationId}/integrations/${integrationId}`);
  }

  async createIntegration(organizationId: string, data: IntegrationCreateData) {
    const formData = new FormData();
    formData.append('slug', data.slug);
    formData.append('name', data.name);
    formData.append('return_mechanism', data.return_mechanism);
    formData.append('logo', data.logo);
    if (data.allowed_origins) {
      data.allowed_origins.forEach((origin) => {
        formData.append('allowed_origins', origin);
      });
    }
    const res = await this.postFormData(`organizations/${organizationId}/integrations`, formData);
    return (await res.json()) as Integration;
  }

  async editIntegration(
    organizationId: string,
    integrationId: string,
    data: IntegrationUpdateData,
  ) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('return_mechanism', data.return_mechanism);
    if (data.logo) formData.append('logo', data.logo);
    if (data.allowed_origins) {
      data.allowed_origins.forEach((origin) => {
        formData.append('allowed_origins', origin);
      });
    }
    const res = await this.patchFormData(
      `organizations/${organizationId}/integrations/${integrationId}`,
      formData,
    );
    return (await res.json()) as Integration;
  }

  deleteIntegration(organizationId: string, integrationId: string) {
    return this.delete<Integration>(
      `organizations/${organizationId}/integrations/${integrationId}`,
    );
  }

  getDeletedApiKeys(orgId: string) {
    return this.get<{apiKeysDeleted: boolean}>(`organizations/${orgId}/free-tier-api-key-deletion`);
  }

  fetchSupport(organizationId: string) {
    return this.get<{
      isSupportIncluded: boolean;
      currentSupportTier: SupportTiers;
      supportSubscriptions: Orb.Price[] | Orb.Subscription.PriceInterval[];
    }>(`organizations/${organizationId}/subscription/support`);
  }

  changeSupport(organizationId: string, desiredSupportTier: SupportTiers) {
    return this.post<{
      currentSupportTier: SupportTiers;
      supportSubscriptions: Orb.Subscription.PriceInterval[];
      scheduledSupportTier: SupportTiers;
    }>(`organizations/${organizationId}/subscription/support/change`, {desiredSupportTier});
  }
}
