import {useState} from 'react';
import CreateButton from '../../../../components/CreateButton';
import CreateApiKeyModal from './CreateApiKeyModal';

type CreateApiKeyButtonProps = {
  /** Whether the user is the owner of the selected project. */
  isOwner: boolean;
};

/** Renders a button for the user to create a new API key. */
export default function CreateApiKeyButton({isOwner}: CreateApiKeyButtonProps) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <CreateButton
        tooltip={!isOwner ? 'You must be a project owner to create an API key' : null}
        disabled={!isOwner}
        onClick={() => setShowForm(true)}
        text="Create API key"
      />
      <CreateApiKeyModal open={showForm} onClose={() => setShowForm(false)} />
    </>
  );
}
