import SearchIcon from '@mui/icons-material/Search';
import {Box, Button} from '@mui/material';

const searchStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
  },
  button: {
    borderRadius: 20,
    border: 1,
    borderColor: 'transparent',
    backgroundColor: 'background.surface',
    color: 'text.secondary',
    textTransform: 'none',
    justifyContent: 'flex-start',
    py: 1.5,
    px: 3,
    '&:hover': {
      backgroundColor: 'action.hover',
    },
  },
  searchIcon: {
    marginRight: 1,
  },
};

interface SearchBarProps {
  onClick?: () => void;
}

export function SearchBar({onClick}: SearchBarProps) {
  return (
    <Box sx={searchStyles.root}>
      <Button
        fullWidth
        variant="outlined"
        endIcon={<SearchIcon />}
        onClick={onClick}
        sx={searchStyles.button}
      >
        <span
          style={{
            color: 'var(--Light-Text-Secondary, #72788D)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          Search for answers, articles, guides and more...
        </span>
        <span style={{marginLeft: 'auto'}} />
      </Button>
    </Box>
  );
}
