import {Box, Typography} from '@mui/material';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import ConnectClientCodeSnippet from './ConnectClientCodeSnippet';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

type ConnectClientProps = {
  service: string;
  title?: string;
  subtitle?: string;
  withGetIndex?: boolean;
  useFallback?: boolean;
};

const styles = {
  subtext: {
    pb: 2,
  },
};

const CONNECT_SUBTEXT =
  'Ready to get started with Pinecone? ' +
  'Copy the code below to set up your environment and establish a connection to your index.';

function ConnectClient({
  service,
  title,
  subtitle,
  withGetIndex = false,
  useFallback = false,
}: ConnectClientProps) {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  if (!selectedLanguageContent.connectTextTemplate) {
    if (useFallback && selectedLanguageContent.connectFallback) {
      return (
        <Box role="region" aria-labelledby="connect">
          <Typography variant="subtitle1" id="connect">
            {selectedLanguageContent.connectFallback.title}
          </Typography>
          <Typography sx={[styles.subtext, {width: '80%'}]}>
            {selectedLanguageContent.connectFallback.subtext}
          </Typography>
          <CodeBlock
            code={selectedLanguageContent.connectFallback.code}
            language={selectedLanguage}
          />
        </Box>
      );
    }
    return null;
  }
  return (
    <Box role="region" aria-labelledby="connect">
      <Typography variant="subtitle1" id="connect">
        {title || 'Initialize'}
      </Typography>
      <Typography sx={styles.subtext}>{subtitle || CONNECT_SUBTEXT}</Typography>
      <ConnectClientCodeSnippet
        codeTemplate={selectedLanguageContent.connectTextTemplate}
        language={selectedLanguage}
        service={service}
        withGetIndex={withGetIndex}
      />
    </Box>
  );
}

export default ConnectClient;
