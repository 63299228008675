import {DataVisColors} from '@pinecone-experience/theme/colors';
import {serverlessWriteUnitCountQuery} from '../../../../../actions/prometheusActions';
import ChartLayout from '../../../../../components/chart/ChartLayout/ChartLayout';
import LineChart from '../../../../../components/chart/LineChart/LineChart';
import {useFilterDataByRequestType} from '../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../hooks/use-api';
import {useLegacyMetrics} from '../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {useServiceByGlobalProject} from '../../../../../selectors/services';
import {getSpecs} from '../../../../../utils/services';

function ServerlessWriteUnitsChart({
  service,
  start,
  end,
}: {
  service: string;
  start: string;
  end: string;
}) {
  const projectId = useSelectedGlobalProjectId();
  const serviceInfo = useServiceByGlobalProject(projectId || '', service);
  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    serverlessWriteUnitCountQuery,
    projectId || '',
    service,
    start,
    end,
    !!serverlessSpec,
  );
  const metrics = useLegacyMetrics('writeUnitsCount', service);
  const selectedRequestTypes = [{name: 'write units count'}];
  const formattedData = useFilterDataByRequestType({
    data: metrics?.data || [],
    selectedRequestTypes,
  });

  return (
    <ChartLayout
      title="Write Units"
      tooltip="Updates, upserts, and deletes to the index, as units representing the compute resources used"
      error={metrics?.error}
      chart={<LineChart formattedData={formattedData} colors={[DataVisColors.DARK_BLUE]} unit="" />}
      loading={metrics?.loading}
    />
  );
}

export default ServerlessWriteUnitsChart;
