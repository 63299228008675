export const formStepStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  actions: {
    mt: 4,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
  },
};
