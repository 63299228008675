export default {
  section: {
    p: 2,
    m: 1,
  },
  error: {
    color: 'error.main',
    height: 10,
    mt: 0.25,
    mb: 2.5,
  },
};
