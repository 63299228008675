export const gridStyles = ({
  gap = 1.5,
  gridMinWidth = 100,
}: {
  gap?: number;
  gridMinWidth?: number;
}) => ({
  grid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `repeat(auto-fit, minmax(${gridMinWidth || 100}px, 1fr))`,
    gap,
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap,
  },
});
