import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type AssistantDataApi from '../api/assistantApi';
import {type AssistantFile} from '../types';
import {formatPluralAmount} from '../utils/format';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const listAssistantFilesRequest = createAction<{assistantName: string}>(
  'LIST_ASSISTANT_FILES_REQUEST',
);
export const listAssistantFilesSuccess = createAction<{
  files: AssistantFile[];
  assistantName: string;
}>('LIST_ASSISTANT_FILES_SUCCESS');
export const listAssistantFilesFailure = createAction<{assistantName: string}>(
  'LIST_ASSISTANT_FILES_FAILURE',
);

export function listAssistantFiles(api: AssistantDataApi, data: {assistantName: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listAssistantFilesRequest({assistantName: data.assistantName}));
    return api
      .listAssistantFiles(data.assistantName)
      .then((res) => {
        dispatch(
          listAssistantFilesSuccess({
            assistantName: data.assistantName,
            files: res.files,
          }),
        );
      })
      .catch((error) => {
        dispatch(listAssistantFilesFailure({assistantName: data.assistantName}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to load assistant files. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const getAssistantFileRequest = createAction<{
  assistantName: string;
  fileId: string;
  silentPoll?: boolean;
}>('GET_ASSISTANT_FILE_REQUEST');
export const getAssistantFileSuccess = createAction<{
  assistantName: string;
  file: AssistantFile;
}>('GET_ASSISTANT_FILE_SUCCESS');
export const getAssistantFileFailure = createAction<{assistantName: string}>(
  'GET_ASSISTANT_FILE_FAILURE',
);
export const assistantFileFinishedDeleting = createAction<{
  assistantName: string;
  fileId: string;
}>('ASSISTANT_FILE_FINISHED_DELETING');

export function getAssistantFile(
  api: AssistantDataApi,
  data: {assistantName: string; fileId: string; silentPoll?: boolean},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      getAssistantFileRequest({
        assistantName: data.assistantName,
        fileId: data.fileId,
        silentPoll: data.silentPoll,
      }),
    );
    return api
      .getAssistantFile(data.assistantName, data.fileId)
      .then((file) => {
        dispatch(getAssistantFileSuccess({assistantName: data.assistantName, file}));
      })
      .catch((error) => {
        if (error.status === 404) {
          dispatch(
            assistantFileFinishedDeleting({
              assistantName: data.assistantName,
              fileId: data.fileId,
            }),
          );
        } else {
          dispatch(
            enqueNotification({
              type: NotificationTypes.ERROR,
              text: `Failed to load file. ${decodeErrorMessage(error.message)}`,
            }),
          );
          dispatch(
            getAssistantFileFailure({
              assistantName: data.assistantName,
            }),
          );
        }
      });
  };
}

export const deleteAssistantFileRequest = createAction<{assistantName: string}>(
  'DELETE_ASSISTANT_FILE_REQUEST',
);
export const deleteAssistantFileSuccess = createAction<{
  assistantName: string;
  fileId: string;
}>('DELETE_ASSISTANT_FILE_SUCCESS');
export const deleteAssistantFileFailure = createAction<{
  assistantName: string;
}>('DELETE_ASSISTANT_FILE_FAILURE');

export function deleteAssistantFile(
  api: AssistantDataApi,
  data: {assistantName: string; fileId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(deleteAssistantFileRequest({assistantName: data.assistantName}));
    return api
      .deleteAssistantFile(data.assistantName, data.fileId)
      .then(() => {
        dispatch(
          deleteAssistantFileSuccess({assistantName: data.assistantName, fileId: data.fileId}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `File marked for deletion.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(deleteAssistantFileFailure({assistantName: data.assistantName}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to delete file. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const uploadAssistantFilesRequest = createAction<{assistantName: string}>(
  'UPLOAD_ASSISTANT_FILES_REQUEST',
);
export const uploadAssistantFilesSuccess = createAction<{
  assistantName: string;
  files: AssistantFile[];
}>('UPLOAD_ASSISTANT_FILES_SUCCESS');
export const uploadAssistantFilesFailure = createAction<{
  assistantName: string;
}>('UPLOAD_ASSISTANT_FILES_FAILURE');

export function uploadAssistantFiles(
  api: AssistantDataApi,
  data: {assistantName: string; files: File[]},
) {
  return (dispatch: Dispatch) => {
    dispatch(uploadAssistantFilesRequest({assistantName: data.assistantName}));
    dispatch(
      enqueNotification({
        type: NotificationTypes.INFO,
        text: `Uploading ${formatPluralAmount(data.files.length, 'file', 'files')}.`,
      }),
    );

    const uploadPromises: Promise<AssistantFile>[] = [];
    data.files.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);
      uploadPromises.push(api.uploadAssistantFile(data.assistantName, formData));
    });

    return Promise.allSettled(uploadPromises).then(async (results) => {
      const succeeded = results.filter((result) => result.status === 'fulfilled');
      const failed = results.filter(
        (result) => result.status === 'rejected',
      ) as PromiseRejectedResult[];

      const uploadedFiles = await Promise.all(
        succeeded.map((res) => (res as PromiseFulfilledResult<AssistantFile>).value),
      );

      if (failed.length > 0) {
        dispatch(uploadAssistantFilesFailure({assistantName: data.assistantName}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `${formatPluralAmount(
              failed.length,
              'file',
              'files',
            )} failed to upload. ${decodeErrorMessage(failed[0].reason)}.`,
          }),
        );
      }

      if (succeeded.length > 0) {
        dispatch(
          uploadAssistantFilesSuccess({assistantName: data.assistantName, files: uploadedFiles}),
        );
      }
    });
  };
}
