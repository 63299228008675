import StatusBox from '../../../../../../components/StatusBox';
import {useStorageIntegrationStatusColorMap} from '../../../../../../hooks/colorMaps';
import {usePollStorageIntegrationStatus} from '../../../../../../hooks/storageIntegrations';
import {useSelectedGlobalProject} from '../../../../../../selectors/projects';
import {StorageIntegrationStatus} from '../../../../../../types';

type StorageStatusBoxProps = {
  integrationId: string;
};

function getStatusText(status?: StorageIntegrationStatus) {
  switch (status) {
    case StorageIntegrationStatus.VALIDATED:
      return 'Connected';
    case StorageIntegrationStatus.INVALID:
      return 'Permission Denied';
    default:
      return 'Unknown Error';
  }
}

function StorageStatusBox({integrationId}: StorageStatusBoxProps) {
  const selectedProject = useSelectedGlobalProject();
  const status = usePollStorageIntegrationStatus(integrationId, selectedProject);
  const color = useStorageIntegrationStatusColorMap(status ?? StorageIntegrationStatus.INVALID);
  return <StatusBox statusText={getStatusText(status)} color={color} />;
}

export default StorageStatusBox;
