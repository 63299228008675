import {createReducer} from '@reduxjs/toolkit';
import * as projectActions from '../actions/dashboardActions';
import * as actions from '../actions/organizationActions';
import * as userActions from '../actions/userActions';
import {Roles} from '../constants';
import {type Marketplace, type Organization} from '../types';

interface OrganizationsState {
  items: Record<string, Organization>;
  loading: boolean;
  marketplaces: Record<string, Marketplace>;
  userRoles: {
    orgId: string;
    userId: string;
    role: Roles;
  }[];
}

const initialState = {
  items: {},
  loading: true,
  spannerOrganizations: [],
  marketplaces: {},
  userRoles: [],
} as OrganizationsState;

const ORGANIZATION_REQUEST_ACTIONS = [
  actions.getOrganizationsRequest.type,
  actions.editOrganizationRequest.type,
  actions.createOrganizationRequest.type,
  projectActions.createProjectRequest.type,
  projectActions.editProjectRequest.type,
  projectActions.deleteProjectRequest.type,
  actions.deleteOrganizationRequest.type,
];

const ORGANIZATION_FINISHED_ACTIONS = [
  actions.editOrganizationFailure.type,
  projectActions.createProjectFailure.type,
  projectActions.createProjectSuccess.type,
  projectActions.deleteProjectFailure.type,
  projectActions.deleteProjectSuccess.type,
  projectActions.editProjectSuccess.type,
  actions.createOrganizationFailure.type,
  projectActions.editProjectFailure.type,
  actions.deleteOrganizationFailure.type,
];

const ORGANIZATION_SUBSCRIPTION_ACTIONS = [
  actions.createSubscriptionSuccess.type,
  actions.updateSubscriptionSuccess.type,
  actions.cancelSubscriptionSuccess.type,
];

const organizationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.attachMarketplaceToOrganizationRequest, (state, _action) => {
      state.loading = true;
    })
    .addCase(actions.attachMarketplaceToOrganizationRequestSuccess, (state, action) => {
      const {organizationId, marketplace} = action.payload;
      state.marketplaces[organizationId] = marketplace;
      // TODO(@mmakarov): Reload the affected organization
      state.loading = false;
    })
    .addCase(actions.attachMarketplaceToOrganizationRequestError, (state, _action) => {
      state.loading = false;
    })
    .addCase(userActions.registerUserSuccess, (state, action) => {
      if (action.payload.globalOrg) {
        state.items[action.payload.globalOrg.id] = action.payload.globalOrg;
      }
    })
    .addCase(actions.getOrganizationsSuccess, (state, action) => {
      state.items = {};
      action.payload.newOrgs.forEach((org) => {
        state.items[org.id] = org;
      });
      state.loading = false;
      state.userRoles = action.payload.newOrgs.map((org) => ({
        orgId: org.id,
        userId: action.payload.userId,
        role: org.role,
      }));
    })
    .addCase(actions.getOrganizationUsersSuccess, (state, action) => {
      state.userRoles = state.userRoles.filter(
        (user) => user.orgId !== action.payload.organizationId,
      );
      state.userRoles = [
        ...state.userRoles,
        ...action.payload.users.map((user) => ({
          userId: user.firebase_id,
          role: user.role,
          orgId: action.payload.organizationId,
        })),
      ];
    })
    .addCase(actions.createOrganizationSuccess, (state, action) => {
      state.items[action.payload.org.id] = action.payload.org;
      state.userRoles = [
        ...state.userRoles,
        {
          orgId: action.payload.org.id,
          userId: action.payload.userId,
          role: Roles.OWNER,
        },
      ];
    })
    .addCase(actions.editOrganizationSuccess, (state, action) => {
      state.loading = false;
      state.items[action.payload.organizationId].name = action.payload.name;
    })
    .addCase(actions.addUsersToOrgSuccess, (state, action) => {
      action.payload.users.forEach((user) => {
        state.userRoles.push({
          orgId: action.payload.organizationId,
          userId: user.firebase_id,
          role: user.organization_roles[action.payload.organizationId].role,
        });
      });
    })
    .addCase(actions.removeUserFromOrgSuccess, (state, action) => {
      state.userRoles = state.userRoles.filter(
        (userRole) =>
          !(
            userRole.userId === action.payload.userId &&
            userRole.orgId === action.payload.organizationId
          ),
      );
    })
    .addCase(actions.editOrgRoleSuccess, (state, action) => {
      state.userRoles = state.userRoles.map((userRole) => {
        if (
          userRole.orgId === action.payload.organizationId &&
          userRole.userId === action.payload.userId
        ) {
          return {...userRole, role: action.payload.role};
        }
        return userRole;
      });
    })
    .addCase(actions.deleteOrganizationSuccess, (state, action) => {
      delete state.items[action.payload];
      state.loading = false;
    })
    .addCase(actions.exitOrganizationSuccess, (state, action) => {
      delete state.items[action.payload];
    })
    .addMatcher(
      (action) => ORGANIZATION_SUBSCRIPTION_ACTIONS.includes(action.type),
      (state, action) => {
        state.items[action.payload.organizationId].plan = action.payload.plan;
      },
    )
    .addMatcher(
      (action) => ORGANIZATION_REQUEST_ACTIONS.includes(action.type),
      (state) => {
        state.loading = true;
      },
    )
    .addMatcher(
      (action) => ORGANIZATION_FINISHED_ACTIONS.includes(action.type),
      (state) => {
        state.loading = false;
      },
    );
});

export default organizationsReducer;
