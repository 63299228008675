import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/userActions';
import {type User} from '../types';

interface UserSessionState {
  loggedInUser: {
    data?: User;
    loading: boolean;
  };
}

const initialState = {
  loggedInUser: {
    loading: true,
  },
} as UserSessionState;

const userSessionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUserRequest, (state) => {
      state.loggedInUser.loading = true;
    })
    .addCase(actions.registerUserRequest, (state) => {
      state.loggedInUser.loading = true;
    })
    .addCase(actions.getUserSuccess, (state, action) => {
      state.loggedInUser.data = action.payload;
      state.loggedInUser.loading = false;
    })
    .addCase(actions.registerUserSuccess, (state, action) => {
      state.loggedInUser.data = action.payload.user;
      state.loggedInUser.loading = false;
    })
    .addCase(actions.patchUserParams, (state, action) => {
      if (state.loggedInUser?.data) {
        state.loggedInUser.data.params = {
          ...state.loggedInUser.data.params,
          ...action.payload.params,
        };
      }
    });
});

export default userSessionReducer;
