import {IndexState, Plans, PodTypes} from '../constants';
import {useIsFreeEnvironment} from '../selectors/environments';
import {useOrganization} from '../selectors/organizations';
import {useSelectedOrganizationId} from '../selectors/params';
import {useServiceSpecsByGlobalProject} from '../selectors/services';
import {type V4IndexInfoResponse} from '../types';
import {getSpecs} from '../utils/services';

export const useCanCreateCollections = (indexInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(indexInfo);
  if (!podSpec) {
    return false;
  }
  return podSpec.pod_type !== PodTypes.STARTER;
};

export const useIsFreeIndex = (indexInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(indexInfo);
  const orgId = useSelectedOrganizationId();
  const isFreeEnv = useIsFreeEnvironment(podSpec?.environment || '', orgId);
  if (!podSpec) {
    return false;
  }
  if (isFreeEnv) {
    return true;
  }
  if (podSpec.pod_type === PodTypes.STARTER) {
    return true;
  }
  return false;
};

export const useIsStarter = (indexInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(indexInfo);
  return podSpec?.pod_type === PodTypes.STARTER;
};

export const useHasCapacityInfo = (serviceInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(serviceInfo);
  if (!podSpec) {
    return false;
  }
  if (podSpec.pod_type === PodTypes.STARTER) {
    return false;
  }
  return true;
};

export const useCanShowPrice = (serviceInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(serviceInfo);
  if (!podSpec) {
    return false;
  }
  if (podSpec.pod_type === PodTypes.STARTER) {
    return false;
  }
  return true;
};

export const useHasNamespaces = (_serviceInfo?: V4IndexInfoResponse) => {
  return true;
};

const PrivateEnvironments = ['notion-us-west-2', 'esprlvuy-us-east-1'];

// TODO(damargulis): replace this with a property in the environment info itself
// once we get envs from spanner
export const useHasDataPlane = (serviceInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(serviceInfo);
  return !PrivateEnvironments.includes(podSpec?.environment || '');
};

export const useHasMetrics = (serviceInfo?: V4IndexInfoResponse) => {
  const {podSpec} = getSpecs(serviceInfo);
  return !PrivateEnvironments.includes(podSpec?.environment || '');
};

export const isUpgrading = (serviceInfo?: V4IndexInfoResponse) => {
  // If an index is upgrading, it will have a status of initializing, but also be marked ready.
  // This is the only time that combination will occur
  return serviceInfo?.status.ready && serviceInfo?.status.state === IndexState.INITIALIZING;
};

export const useShouldShowForcedMigration = (orgId: string, serviceInfo?: V4IndexInfoResponse) => {
  const org = useOrganization(orgId);
  const isStarter = useIsStarter(serviceInfo);
  const {podSpec} = getSpecs(serviceInfo);
  if (org.plan !== Plans.FREE) {
    return false;
  }
  if (podSpec?.pod_type === PodTypes.NANO) {
    return true;
  }
  return !isStarter && !!podSpec;
};

export const useFirstForcedMigrationServiceByGlobalProject = (orgId: string, projectId: string) => {
  const org = useOrganization(orgId);
  const services = useServiceSpecsByGlobalProject(projectId);
  const nanoService = services.find((service) => {
    const {podSpec} = getSpecs(service);
    return podSpec?.pod_type === PodTypes.NANO;
  });
  if (org.plan !== Plans.FREE) {
    return null;
  }
  if (nanoService) {
    return nanoService;
  }
  return services.find((service) => {
    const {podSpec} = getSpecs(service);
    return podSpec && podSpec.pod_type !== PodTypes.STARTER;
  });
};
