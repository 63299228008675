import {createReducer, isAnyOf} from '@reduxjs/toolkit';
import * as actions from '../actions/dashboardActions';
import * as organizationActions from '../actions/organizationActions';
import * as serviceActions from '../actions/serviceActions';
import * as userActions from '../actions/userActions';
import {Roles} from '../constants';
import {type GlobalProject} from '../types';

interface ProjectState {
  loading: boolean;
  globalItems: Record<string, GlobalProject & {creating: boolean}>;
  userRoles: {
    projectId: string;
    userId: string;
    role: Roles;
  }[];
}

const initialState = {
  loading: true,
  globalItems: {},
  userRoles: [],
} as ProjectState;

const deleteGlobalProject = (state: ProjectState, globalProjectId: string) => {
  delete state.globalItems[globalProjectId];
  state.userRoles = state.userRoles.filter((userRole) => userRole.projectId !== globalProjectId);
  return state;
};

const projectReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(organizationActions.getOrganizationsSuccess, (state, action) => {
      action.payload.projects.forEach((project) => {
        state.globalItems[project.id] = {
          ...project,
          creating: false,
        };
      });
      state.userRoles = action.payload.projects.map((project) => ({
        projectId: project.id,
        userId: action.payload.userId,
        role: project.role,
      }));
      state.loading = false;
    })
    .addCase(actions.getProjectInfoSuccess, (state, action) => {
      state.userRoles = state.userRoles.filter(
        (user) => user.projectId !== action.payload.projectId,
      );
      state.userRoles = [
        ...state.userRoles,
        ...action.payload.users.map((user) => ({
          userId: user.firebase_id,
          role: user.role,
          projectId: action.payload.projectId,
        })),
      ];
      state.loading = false;
    })
    .addCase(organizationActions.addUsersToProjectSuccess, (state, action) => {
      const projectIds = Object.keys(action.payload.projectUsers);
      projectIds.forEach((projectId) => {
        action.payload.projectUsers[projectId].forEach((userId) => {
          state.userRoles.push({
            projectId,
            userId,
            role: action.payload.inviteData.project_roles[projectId].role,
          });
        });
      });
      state.loading = false;
    })
    .addCase(actions.removeUserFromProjectSuccess, (state, action) => {
      state.loading = false;
      state.userRoles = state.userRoles.filter(
        (userRole) =>
          !(
            userRole.userId === action.payload.userId &&
            userRole.projectId === action.payload.projectId
          ),
      );
    })
    .addCase(organizationActions.removeUserFromOrgSuccess, (state, action) => {
      state.userRoles = state.userRoles.filter((userRole) => {
        if (userRole.userId !== action.payload.userId) {
          return true;
        }
        const project = state.globalItems[userRole.projectId];
        return action.payload.organizationId !== project.organization_id;
      });
    })
    .addCase(actions.editProjectRoleSuccess, (state, action) => {
      state.userRoles = state.userRoles.map((userRole) => {
        if (
          userRole.projectId === action.payload.projectId &&
          userRole.userId === action.payload.userId
        ) {
          return {...userRole, role: action.payload.role};
        }
        return userRole;
      });
      state.loading = false;
    })
    .addCase(actions.editProjectSuccess, (state, action) => {
      state.globalItems[action.payload.globalProjectId].name = action.payload.editData.name;
      state.globalItems[action.payload.globalProjectId].quota = action.payload.editData.quota;
      state.loading = false;
    })
    .addCase(actions.createProjectSuccess, (state, action) => {
      state.globalItems[action.payload.project.id] = {
        ...action.payload.project,
        creating: false,
      };
      state.userRoles = [
        ...state.userRoles,
        {
          projectId: action.payload.project.id,
          userId: action.payload.userId,
          role: Roles.OWNER,
        },
      ];
      state.loading = false;
    })
    .addCase(actions.deleteProjectSuccess, (state, action) => {
      delete state.globalItems[action.payload.globalProjectId];
      state.loading = false;
    })
    .addCase(userActions.registerUserSuccess, (state, action) => {
      const {project} = action.payload;
      if (project) {
        state.globalItems[project.id] = {...project, creating: false};
      }
    })
    .addCase(actions.removeUserFromProjectFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.removeUserFromProjectRequest, (state) => {
      state.loading = true;
    })
    .addCase(serviceActions.projectLoaded, (state, action) => {
      state.globalItems[action.payload.globalProjectId] = {
        ...state.globalItems[action.payload.globalProjectId],
        creating: false,
      };
    })
    .addCase(serviceActions.projectNotLoaded, (state, action) => {
      state.globalItems[action.payload.globalProjectId] = {
        ...state.globalItems[action.payload.globalProjectId],
        creating: true,
      };
    })
    .addMatcher(
      isAnyOf(
        organizationActions.deleteOrganizationSuccess,
        organizationActions.exitOrganizationSuccess,
      ),
      (state, action) => {
        Object.values(state.globalItems).forEach((globalProject) => {
          if (globalProject.organization_id === action.payload) {
            state = deleteGlobalProject(state, globalProject.id);
          }
        });
      },
    )
    .addMatcher(
      isAnyOf(actions.exitProjectSuccess, actions.deleteProjectSuccess),
      (state, action) => {
        deleteGlobalProject(state, action.payload.globalProjectId);
      },
    );
});

export default projectReducer;
