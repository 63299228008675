import {useAppSelector as useSelector} from '../app/store';

const emptyArr: never[] = [];

export const useStorageIntegrations = (globalProjectId: string) => {
  return useSelector(
    (state) => state.storageIntegrations.projects[globalProjectId]?.data || emptyArr,
  );
};

export const useStorageIntegrationsAreLoading = (globalProjectId: string) => {
  return useSelector((state) => state.storageIntegrations.projects[globalProjectId]?.loading);
};

export const useStorageIntegration = (globalProjectId: string, integrationId: string) => {
  return useSelector((state) =>
    state.storageIntegrations.projects[globalProjectId]?.data?.find(
      (integration) => integration.id === integrationId,
    ),
  );
};
