import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Table from '@pinecone-experience/timber/Table';
import {Link as RouterLink} from 'react-router-dom';
import AssistantStatusBox from '../../../../components/AssistantStatusBox/AssistantStatusBox';
import {useNavigateToAssistant} from '../../../../hooks/navigation';

import {type Assistant, type GlobalProject} from '../../../../types';
import AssistantActionMenu from '../../AssistantActionMenu/AssistantActionMenu';

interface AssistantTableProps {
  loading: boolean;
  assistants: Assistant[];
  project: GlobalProject;
}

const styles = {
  name: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

function AssistantTable({loading, assistants, project}: AssistantTableProps) {
  const {getPath: getAssistantPath} = useNavigateToAssistant(project.organization_id, project.id);

  const cellRenderer = (row: number, column: number) => {
    const assistant = assistants[row];

    switch (column) {
      case 0:
        return (
          <Typography sx={styles.name}>
            <Link sx={styles.link} component={RouterLink} to={getAssistantPath(assistant.name)}>
              {assistant.name}
            </Link>
            <AssistantStatusBox assistantName={assistant.name} />
          </Typography>
        );
      case 1:
        return <Typography>{new Date(assistant.created_at).toLocaleDateString()}</Typography>;
      case 2:
        return <Typography>{new Date(assistant.updated_at).toLocaleDateString()}</Typography>;
      case 3:
        return <AssistantActionMenu project={project} name={assistant.name} />;
      default:
        return null;
    }
  };

  return (
    <Table
      columns={[
        {
          name: 'Name',
          dataType: 'string',
        },
        {
          name: 'Date created',
          dataType: 'string',
        },
        {
          name: 'Date updated',
          dataType: 'string',
        },
        {
          name: 'Actions',
          dataType: 'actions',
        },
      ]}
      isLoading={loading}
      numRows={assistants.length}
      cellRenderer={cellRenderer}
      itemLabel={['assistant', 'assistants']}
      pagination={{
        labelled: true,
        sizeControl: true,
      }}
    />
  );
}

export default AssistantTable;
