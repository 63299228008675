import {Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useState} from 'react';
import {logGetStartedProgress} from '../../../../../actions/getStartedGuideActions';
import {type GetStartedProgressLog} from '../../../../../actions/types/getStartedGuideActions.types';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {GuideStatus, useGuideContext} from '../utilities/GuideContext';

type NextButtonProps = {
  sectionIndex: number;
  blockIndex: number;
  title: string;
  blockTitle: string;
  status: GuideStatus;
  onClick: () => void;
  disabled?: boolean;
  loadingTimeout?: number;
};

function NextButton({
  sectionIndex,
  blockIndex,
  title,
  blockTitle,
  status,
  onClick,
  disabled,
  loadingTimeout,
}: NextButtonProps) {
  const {state: guideState} = useGuideContext();
  const projectId = useSelectedGlobalProjectId();
  const [isLoading, setIsLoading] = useState(false);

  const {setQueryData} = useDashboardApi<GetStartedProgressLog>();
  const handleClick = () => {
    // log progress
    setQueryData({
      action: logGetStartedProgress,
      data: {
        projectId,
        guideId: guideState.id,
        sectionIndex,
        blockIndex,
      },
    });

    if (loadingTimeout && loadingTimeout > 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        onClick();
      }, loadingTimeout * 1000);
    } else {
      onClick();
    }
  };

  return (
    <Button
      id={`section-${sectionIndex}-block-${blockIndex}-next`}
      data-testid={`section-${sectionIndex}-block-${blockIndex}-next`}
      aria-label={`mark-${blockTitle}-as-complete`}
      variant="contained"
      disabled={status !== GuideStatus.IN_PROGRESS || disabled}
      onClick={handleClick}
      sx={{
        mt: 2,
      }}
      fullWidth
    >
      {isLoading ? <CircularProgress size={24} color="inherit" /> : title}
    </Button>
  );
}

export default NextButton;
