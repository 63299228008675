import {Auth0Provider} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {withLDProvider} from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import {Provider} from 'react-redux';
import {unstable_HistoryRouter as Router} from 'react-router-dom';
import {getHistory, setupStore} from './app/store';
import MainApp from './components/app/MainApp/MainApp';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, LAUNCH_DARKLY_ID} from './constants';
import ErrorPage from './pages/app/ErrorPage/ErrorPage';
// Import shepherd styles for modals and tooltips to show properly
// See https://github.com/shipshapecode/react-shepherd/issues/570
import 'shepherd.js/dist/css/shepherd.css';
// Import Jetbrains Mono font family for code snippets
import '@fontsource/jetbrains-mono';
import DatadogMetrics from './utils/datadogMetrics';
import {isProduction} from './utils/environment';

if (isProduction) {
  DatadogMetrics.init();
}

Sentry.init({
  dsn: 'https://d0d8ce4365d44fdc9e354393d677bfb1@o375717.ingest.sentry.io/6119926',
  // further configuration: https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
  integrations: [new Sentry.BrowserTracing()],
  release: import.meta.env.BUILD_ID,
  environment: import.meta.env.MODE || 'development',
  tracesSampleRate: 0.5,
  normalizeDepth: 10,
  enabled: import.meta.env.DEV !== true,
});

// NOTE: this will load the launchdarkly flags async.
// If we ever need a flag to be present at render, we can switch to asyncWithLDProvider https://docs.launchdarkly.com/sdk/client-side/react/react-web
const LDProvider = LAUNCH_DARKLY_ID
  ? withLDProvider({
      clientSideID: LAUNCH_DARKLY_ID,
    })(MainApp as React.ComponentType<object>)
  : MainApp;

const StatusPageWidget = () => {
  React.useEffect(() => {
    const body = document.querySelector('body');
    const script = document.createElement('script');

    if (import.meta.env.PROD) {
      script.setAttribute('src', 'https://status.pinecone.io/embed/script.js');
      body?.appendChild(script);
    }

    return () => {
      body?.removeChild(script);
    };
  }, []);

  return null;
};
const store = setupStore({});
const history = getHistory(store);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Auth0Provider
        domain={AUTH0_DOMAIN || ''}
        clientId={AUTH0_CLIENT_ID || ''}
        redirectUri={window.location.origin}
        audience={AUTH0_AUDIENCE}
        scope="read:current_user"
        cacheLocation="localstorage"
        onRedirectCallback={(appState) => {
          if (appState?.targetUrl) {
            history.replace(appState.targetUrl);
          }
        }}
      >
        <Provider store={store}>
          {/* @ts-ignore */}
          <Router history={history}>
            <LDProvider />
          </Router>
        </Provider>
      </Auth0Provider>
      <StatusPageWidget />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
