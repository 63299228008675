import Table from '@pinecone-experience/timber/Table';
import {GUTTER} from '../../../styles/theme';
import {type GlobalProject} from '../../../types';
import ProjectCellContainer from './ProjectCellContainer/ProjectCellContainer';

type ProjectsTableProps = {
  projects: GlobalProject[];
  dataKey?: string;
};

function ProjectsTable({projects, dataKey}: ProjectsTableProps) {
  const cellRenderer = (row: number, col: number) => (
    <ProjectCellContainer project={projects[row]} key={projects[row].id} col={col} />
  );

  return (
    <Table
      isLoading={false}
      dataKey={dataKey}
      pagination={{labelled: true, sizeControl: true}}
      itemLabel={['project', 'projects']}
      columns={[
        {name: 'Project name', dataType: 'string'},
        {name: 'ID', dataType: 'string'},
        {name: 'Indexes', dataType: 'number'},
        {name: 'API keys', dataType: 'number'},
        {name: 'Users', dataType: 'number'},
        {name: 'Created date', dataType: 'number'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={projects.length}
      cellRenderer={cellRenderer}
      customIdPrefix="project-table-page"
      gutter={GUTTER}
    />
  );
}

export default ProjectsTable;
