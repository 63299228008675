import {Box, Typography} from '@mui/material';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

const styles = {
  subtext: {
    pb: 2,
    maxWidth: '80%',
  },
};

function InstallClient({title, subtitle}: {title?: string; subtitle?: (name: string) => string}) {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  if (!selectedLanguageContent.installText) {
    return null;
  }
  return (
    <Box role="region" aria-labelledby="install">
      <Typography variant="subtitle1" id="install">
        {title || 'Install Pinecone'}
      </Typography>
      <Typography sx={styles.subtext}>
        {(subtitle && subtitle(selectedLanguageContent.display)) ||
          'Run the following on the command line.'}
      </Typography>
      <CodeBlock code={selectedLanguageContent.installText} language="text" />
    </Box>
  );
}

export default InstallClient;
