import {Box, Paper} from '@mui/material';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useIndexStatusBox} from '../../../../hooks/indexes';
import {useHasCapacityInfo} from '../../../../hooks/specUtils';
import {
  type IndexStatsDescription,
  type Organization,
  type V4IndexInfoResponse,
} from '../../../../types';
import Capacity from './Capacity/Capacity';
import Details from './Details/Details';
import HeartbeatChart from './HeartbeatChart/HeartbeatChart';

const styles = {
  row: {
    display: 'flex',
    alignItems: 'stretch',
    gap: 2,
    flexWrap: 'wrap',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
  },
};
type IndexOverviewProps = {
  service: string;
  organization: Organization;
  serviceInfo?: V4IndexInfoResponse;
  serviceStats?: IndexStatsDescription;
  isFree: boolean;
};

const IndexOverview = ({
  service,
  organization,
  serviceInfo,
  serviceStats,
  isFree,
}: IndexOverviewProps) => {
  const {shouldShowHeartbeatChart} = useFlags();
  const {statusText, statusColor} = useIndexStatusBox(service);
  const hasCapacityInfo = useHasCapacityInfo(serviceInfo);
  return (
    <Box sx={styles.row}>
      <Paper sx={styles.column}>
        <Details
          service={service}
          organization={organization}
          serviceInfo={serviceInfo}
          serviceStats={serviceStats}
          statusText={statusText}
          statusColor={statusColor}
          isFree={isFree}
        />
        {hasCapacityInfo && <Capacity serviceInfo={serviceInfo} serviceStats={serviceStats} />}
      </Paper>
      {shouldShowHeartbeatChart && (
        <HeartbeatChart service={service} statusText={statusText} serviceInfo={serviceInfo} />
      )}
    </Box>
  );
};

export default IndexOverview;
