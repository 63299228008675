import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/dataActions';
import {deleteServiceSuccess} from '../actions/serviceActions';
import {type Usage, type Vector} from '../api/dataPlaneApi';
import {type IndexStatsDescription} from '../types';
import {isGlobalProjectAction} from './utils';

export type VectorState = Vector & {loading?: boolean};

interface ExplorerState {
  // TODO: query hash?
  query: {
    loading: boolean;
    matches: {
      vectorId: string;
      score: number;
    }[];
    namespace: string;
    usage: Usage;
  };
  list: {
    loading: boolean;
    vectorIds: string[];
    namespace: string;
    limit?: number;
    usage: Usage;
    type: 'list' | 'fetch';
  };
  // {namespace: {vectorId: vectorInfo}}
  vectors: Record<string, Record<string, VectorState>>;
  description?: IndexStatsDescription;
}

const initialIndexState = {
  query: {
    loading: false,
    matches: [],
    namespace: '',
    usage: {},
  },
  list: {
    loading: false,
    vectorIds: [],
    namespace: '',
    usage: {},
    type: 'list',
  },
  vectors: {},
} as ExplorerState;

const indexReducer = createReducer(initialIndexState, (builder) => {
  builder
    .addCase(actions.describeIndexSuccess, (state, action) => {
      state.description = action.payload.data;
    })
    .addCase(actions.vectorQueryRequest, (state, action) => {
      state.query.loading = true;
      state.query.matches = [];
      state.query.namespace = action.payload.namespace || '';
      state.query.usage = {};
    })
    .addCase(actions.vectorQuerySuccess, (state, action) => {
      const {namespace, matches, usage} = action.payload.data;
      state.query.loading = false;
      state.query.matches = matches?.map((vector) => ({
        vectorId: vector.id,
        score: vector.score,
      }));
      state.vectors[namespace] = state.vectors[namespace] || {};
      action.payload.data.matches?.forEach((vector) => {
        state.vectors[namespace][vector.id] = vector;
      });
      state.query.namespace = namespace;
      state.query.usage = usage;
    })
    .addCase(actions.listVectorsRequest, (state, action) => {
      state.list.loading = true;
      state.list.vectorIds = [];
      state.list.namespace = action.payload.namespace || '';
      state.list.usage = {};
      state.list.limit = action.payload.limit;
      state.list.type = 'list';
    })
    .addCase(actions.listVectorsSuccess, (state, action) => {
      state.list.loading = false;
      state.list.vectorIds = action.payload.data.vectors.map((vector) => vector.id);
      state.list.namespace = action.payload.data.namespace;
      state.list.usage = action.payload.data.usage;
    })
    .addCase(actions.listVectorsFailure, (state, action) => {
      state.list.loading = false;
      state.list.vectorIds = [];
      state.list.namespace = action.payload.namespace || '';
      state.list.usage = {};
    })
    .addCase(actions.vectorFetchRequest, (state, action) => {
      if (!action.payload.hidden) {
        state.list.loading = true;
        state.list.vectorIds = [];
        state.list.namespace = action.payload.namespace || '';
        state.list.usage = {};
        state.list.limit = undefined;
        state.list.type = 'fetch';
      }
    })
    .addCase(actions.vectorFetchSuccess, (state, action) => {
      const {vectors, namespace, usage} = action.payload.data;
      if (!action.payload.hidden) {
        state.list.loading = false;
        state.list.vectorIds = Object.keys(vectors);
        state.list.namespace = namespace;
        state.list.usage = usage;
      }
      state.vectors[namespace] = state.vectors[namespace] || {};
      Object.values(action.payload.data.vectors).forEach((vector) => {
        state.vectors[namespace][vector.id] = {
          ...vector,
          loading: false,
        };
      });
    })
    .addCase(actions.vectorFetchFailure, (state) => {
      state.list.loading = true;
      state.list.vectorIds = [];
      state.list.usage = {};
    })
    .addCase(actions.vectorUpsertRequest, (state, action) => {
      const {upserts} = action.payload;
      upserts.forEach((upsertRequest) => {
        const {namespace = ''} = upsertRequest;
        upsertRequest.vectors.forEach((vector) => {
          state.vectors[namespace] = state.vectors[namespace] || {};
          if (state.vectors[namespace]?.[vector.id]) {
            state.vectors[namespace][vector.id].loading = true;
          }
        });
      });
    })
    .addCase(actions.vectorUpsertSuccess, (state, action) => {
      const {upserts} = action.payload;
      upserts.forEach((upsertRequest) => {
        const {namespace = ''} = upsertRequest;
        state.vectors[namespace] = state.vectors[namespace] || {};
        upsertRequest.vectors.forEach((vector) => {
          state.vectors[namespace][vector.id] = {
            ...state.vectors[namespace][vector.id],
            ...vector,
            loading: false,
          };
        });
      });
    })
    .addCase(actions.vectorDeleteRequest, (state, action) => {
      const {vectorIds, namespace} = action.payload;
      state.vectors[namespace] = state.vectors[namespace] || {};
      vectorIds.forEach((vectorId) => {
        state.vectors[namespace][vectorId] = {
          ...(state.vectors[namespace][vectorId] || {}),
          loading: true,
        };
      });
    })
    .addCase(actions.vectorDeleteSuccess, (state, action) => {
      const {namespace, vectorIds} = action.payload;
      state.vectors[namespace] = state.vectors[namespace] || {};
      vectorIds.forEach((vectorId) => {
        delete state.vectors[namespace][vectorId];
        state.query.matches = state.query.matches.filter((vector) => vector.vectorId !== vectorId);
        state.list.vectorIds = state.list.vectorIds.filter((id) => id !== vectorId);
      });
    })
    .addCase(actions.deleteNamespaceSuccess, (state, action) => {
      delete state.vectors[action.payload.namespace];
      delete state.description?.namespaces[action.payload.namespace];
    });
});

interface ProjectState {
  indexes: Record<string, ExplorerState>;
}

const initialProjectState = {
  indexes: {},
} as ProjectState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(deleteServiceSuccess, (state, action) => {
      delete state.indexes[action.payload.service];
    })
    .addMatcher(
      (action) => typeof action.payload?.serviceName === 'string',
      (state, action) => {
        state.indexes[action.payload.serviceName] = indexReducer(
          state.indexes[action.payload.serviceName],
          action,
        );
      },
    );
});

interface AppState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as AppState;

const explorerReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default explorerReducer;
