import {Box} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useEffect, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {migrateServices} from '../../actions/serviceActions';
import {useControllerApi} from '../../hooks/use-api';
import {usePrevious} from '../../hooks/utils';
import {useServerlessEnvironments} from '../../selectors/environments';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {MODAL_WIDTH_SMALL} from '../../styles/theme';
import ConfigureIndex from './ConfigureIndex/ConfigureIndex';
import MigrationInProgress from './MigrationInProgress/MigrationInProgress';
import PrepareScreen from './PrepareScreen/PrepareScreen';
import {type IndexTargetType, type MigrationSchemaType} from './schema';

type ServerlessMigrationSingleIndexModalProps = {
  service: string;
  open: boolean;
  close: () => void;
};

const styles = {
  container: {
    width: MODAL_WIDTH_SMALL,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

function ServerlessMigrationSingleIndexModal({
  service,
  open,
  close,
}: ServerlessMigrationSingleIndexModalProps) {
  const project = useSelectedGlobalProject();
  const serverlessEnvironments = useServerlessEnvironments(project?.organization_id);
  const {control, setValue, handleSubmit} = useForm<MigrationSchemaType>({
    defaultValues: {
      indexes: [
        {
          sourceName: service,
          targetName: `${service}-serverless`,
          cloud: serverlessEnvironments[0]?.provider,
          region: serverlessEnvironments[0]?.provider_region,
        },
      ],
    },
  });
  const prevEnvs = usePrevious(serverlessEnvironments);
  useEffect(() => {
    if (prevEnvs?.length === 0 && serverlessEnvironments.length > 0) {
      setValue('indexes.0.cloud', serverlessEnvironments[0].provider);
      setValue('indexes.0.region', serverlessEnvironments[0].provider_region || '');
    }
  }, [serverlessEnvironments, setValue, prevEnvs?.length]);
  const {fields: indexList} = useFieldArray({control, name: 'indexes'});
  const [screen, setScreen] = useState(0);
  const {setQueryData} = useControllerApi<{
    services: IndexTargetType[];
    globalProjectId: string;
  }>(project);
  const doSubmit = (formData: MigrationSchemaType) => {
    setQueryData({
      action: migrateServices,
      data: {services: formData.indexes, globalProjectId: project.id},
    });
    setScreen(2);
  };
  const onClose = () => {
    setScreen(0);
    close();
  };
  const screens = [
    <PrepareScreen key={0} onContinue={() => setScreen(1)} />,
    <ConfigureIndex
      key={1}
      control={control}
      onContinue={handleSubmit(doSubmit)}
      goBack={() => setScreen(0)}
      indexNum={0}
      indexList={indexList}
      setValue={setValue}
    />,
    <MigrationInProgress key={2} close={onClose} project={project} />,
  ];
  return (
    <BaseModal open={open} onClose={onClose} showCloseIcon>
      <Box sx={styles.container}>{screens[screen]}</Box>
    </BaseModal>
  );
}

export default ServerlessMigrationSingleIndexModal;
