import {Box} from '@mui/material';
import EmptyStateCard from '../../../components/EmptyStateCard';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import CreateProjectButton from '../../../components/projects/CreateProjectButton/CreateProjectButton';
import ProjectsTable from '../../../components/projects/ProjectsTable/ProjectsTable';
import {useSelectedOrganization} from '../../../selectors/organizations';
import {useGlobalProjectsFromSelectedOrganization} from '../../../selectors/projects';

function OrgProjectsPage() {
  const selectedOrg = useSelectedOrganization();
  const availableProjects = useGlobalProjectsFromSelectedOrganization();

  return (
    <Box>
      {availableProjects.length > 0 ? (
        <Page
          header={
            <PageHeader
              title="Projects"
              titleHidden
              description={
                <>
                  Create and manage Projects in <strong>{selectedOrg?.name}</strong> here
                </>
              }
              actions={<CreateProjectButton includePlus />}
            />
          }
        >
          <ProjectsTable projects={availableProjects} />
        </Page>
      ) : (
        <EmptyStateCard
          title="Start exploring Pinecone by setting up a new Project"
          cta={<CreateProjectButton includePlus text="Create a Project" />}
        />
      )}
    </Box>
  );
}

export default OrgProjectsPage;
