import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography, {type TypographyProps} from '@mui/material/Typography';
import {Link as RouterLink} from 'react-router-dom';

interface ProjectCellProps {
  link?: string;
  sx?: Required<TypographyProps['sx']>;
  loading?: boolean;
  children: React.ReactNode;
  badge?: React.ReactNode;
}

const styles = {
  rootWithBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 0.5,
  },
  badge: {
    mt: 0.25,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default function ProjectCell({link, sx = {}, loading, children, badge}: ProjectCellProps) {
  const content = loading || children === undefined ? '-' : children;

  if (link && !loading) {
    if (badge) {
      return (
        <Box sx={[styles.rootWithBadge, sx]}>
          <Link sx={styles.link} component={RouterLink} to={link}>
            <Typography>{content}</Typography>
          </Link>
          <Box sx={styles.badge}>{badge}</Box>
        </Box>
      );
    }

    return (
      <Link sx={[styles.link, sx]} component={RouterLink} to={link}>
        <Typography>{content}</Typography>
      </Link>
    );
  }

  return <Typography sx={sx}>{content}</Typography>;
}
