// TODO: Find a way to sync this file. It's copied from https://github.com/pinecone-io/dashboard/blob/main/api-server/functions/src/util/constants.ts
export const DefaultQuotas = {
  FREE: 1,
  PAID: 2,
};

export enum Roles {
  OWNER = 'owner',
  USER = 'user',
}

export enum Plans {
  FREE = 'free',
  STANDARD = 'standard',
  ENTERPRISE = 'enterprise',
  DEDICATED = 'dedicated',
  INTERNAL = 'internal',
}

export enum PodTypes {
  P1 = 'p1',
  S1 = 's1',
  P2 = 'p2',
  S1H = 's1h',
  nano = 'nano',
  C1 = 'c1',
  starter = 'starter',
}

export enum ProductTypes {
  COLLECTIONS = 'collections',
}

export type BillableUnit = ProductTypes | PodTypes;

export enum CloudProviders {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
}

export enum CollectionStatus {
  READY = 'Ready',
  INITIALIZING = 'Initializing',
  TERMINATING = 'Terminating',
}

/*
  TODO: right now, this mapping which gets used for GCP needs to be set separately
  than the prices in Stripe.
  It would be nice to find a way to hook our stripe
  configuration up with our CI/CD system such that we can
  have a single source of truth for all pricing
*/

// Collections has the same price for all plans/providers
export const collectionsPrice = (0.000035 / 60) * 100;

// Prices in cents/minute/pod (or GB for collections):
export const ProductsToPriceMap = {
  [CloudProviders.GCP]: {
    [ProductTypes.COLLECTIONS]: {
      [Plans.STANDARD]: collectionsPrice,
      [Plans.ENTERPRISE]: collectionsPrice,
      [Plans.DEDICATED]: collectionsPrice,
    },
    [PodTypes.P1]: {
      [Plans.STANDARD]: 0.16,
      [Plans.ENTERPRISE]: 0.24,
      [Plans.DEDICATED]: 0.32,
    },
    [PodTypes.S1]: {
      [Plans.STANDARD]: 0.16,
      [Plans.ENTERPRISE]: 0.24,
      [Plans.DEDICATED]: 0.32,
    },
    [PodTypes.S1H]: {
      [Plans.STANDARD]: 0.16,
      [Plans.ENTERPRISE]: 0.24,
      [Plans.DEDICATED]: 0.32,
    },
    [PodTypes.P2]: {
      [Plans.STANDARD]: 0.24,
      [Plans.ENTERPRISE]: 0.36,
      [Plans.DEDICATED]: 0.48,
    },
  },
  [CloudProviders.AWS]: {
    [ProductTypes.COLLECTIONS]: {
      [Plans.STANDARD]: collectionsPrice,
      [Plans.ENTERPRISE]: collectionsPrice,
      [Plans.DEDICATED]: collectionsPrice,
    },
    [PodTypes.P1]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.S1]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.S1H]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.P2]: {
      [Plans.STANDARD]: 0.2775,
      [Plans.ENTERPRISE]: 0.41625,
      [Plans.DEDICATED]: 0.555,
    },
  },
  [CloudProviders.AZURE]: {
    [ProductTypes.COLLECTIONS]: {
      [Plans.STANDARD]: collectionsPrice,
      [Plans.ENTERPRISE]: collectionsPrice,
      [Plans.DEDICATED]: collectionsPrice,
    },
    [PodTypes.P1]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.S1]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.S1H]: {
      [Plans.STANDARD]: 0.185,
      [Plans.ENTERPRISE]: 0.2775,
      [Plans.DEDICATED]: 0.37,
    },
    [PodTypes.P2]: {
      [Plans.STANDARD]: 0.2775,
      [Plans.ENTERPRISE]: 0.41625,
      [Plans.DEDICATED]: 0.555,
    },
  },
} as Record<CloudProviders, Record<BillableUnit, Record<Plans, number>>>;

export const BILLING_INTERVAL_MINUTES = 15;
// This is used to set the anchor at midnight in this timezone for all subscriptions.
export const BILLING_TIMEZONE = 'UTC';

export const DATABASE_PATH = 'project-data';

export const AWS_BILLING_DIMENSION = 'pinecone_billing_unit';

// The maximum timeout allowed is 9 minutes.
export const MAX_GCP_FUNCTION_TIMEOUT_SECONDS = 540;
