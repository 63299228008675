import {useEffect, useState} from 'react';
import {useMatch, useSearchParams} from 'react-router-dom';
import {CreateIndexParam, LocalStorageKeys, RoutePath} from '../../../../constants';
import {localStorageGet, localStorageSet} from '../../../../utils/storage';

export const useSkipRegistration = () => {
  const skipRegistration = localStorageGet(LocalStorageKeys.SKIP_REGISTRATION_FORM) === 'true';
  const setSkipRegistration = () =>
    localStorageSet(LocalStorageKeys.SKIP_REGISTRATION_FORM, 'true');
  return {skipRegistration, setSkipRegistration};
};

export const useDelayRegistration = () => {
  const onCreateIndexPage = !!useMatch(RoutePath.CREATE_INDEX);
  const [searchParams] = useSearchParams();
  const hasSearchParam = Object.values(CreateIndexParam).some((param) => searchParams.has(param));
  const [delayRegistration, setDelayRegistration] = useState<boolean>(false);
  const shouldDelayRegistration = onCreateIndexPage && hasSearchParam;
  useEffect(() => {
    if (shouldDelayRegistration) setDelayRegistration(true);
  }, [shouldDelayRegistration]);
  return delayRegistration || shouldDelayRegistration;
};
