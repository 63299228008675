import {Box, Typography} from '@mui/material';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useFormContext} from 'react-hook-form';
import OtherOption from '../../../Utilities/OtherOption';

const REASON_LABEL = 'What made you decide to downgrade your plan?';

const REASON_OPTIONS = [
  {
    value: 'Found it difficult to use',
  },
  {
    value: 'Too expensive',
  },
  {
    value: 'Dissatisfied with support',
  },
  {
    value: 'No longer need a vector database',
  },
  {
    label: 'Other',
    value: '',
  },
];

const FEEDBACK_LABEL = 'We really appreciate your input. Could you share additional details?';
const FEEDBACK_PLACEHOLDER =
  'Mention the primary factor(s) that influenced your decision, such as cost, specific missing features, or ease of use.';

const FEATURES_LABEL = 'Which features did you use regularly and which ones were you missing?';
const FEATURES_PLACEHOLDER =
  'Identify the functionalities you found most beneficial and any that you needed but were not available.';

function ChurnFormFields() {
  const {control, watch} = useFormContext();
  const reasonVal = watch('reason');
  return (
    <>
      <Typography mt={1}>{`Please let us know why you're downgrading`}</Typography>
      <Box height={24} />
      <SelectBoxInput
        name="reason"
        label={REASON_LABEL}
        control={control}
        options={REASON_OPTIONS}
        addOption={OtherOption({
          fieldName: 'reason',
          fieldValue: reasonVal,
          optionsArray: REASON_OPTIONS,
          control,
        })}
      />
      <TextInput
        label={FEEDBACK_LABEL}
        name="feedback"
        control={control}
        placeholder={FEEDBACK_PLACEHOLDER}
        fullWidth
        multiline
        rows={4}
      />
      <TextInput
        label={FEATURES_LABEL}
        name="features"
        control={control}
        placeholder={FEATURES_PLACEHOLDER}
        fullWidth
        multiline
        rows={4}
      />
    </>
  );
}

export default ChurnFormFields;
