import {Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useFormContext} from 'react-hook-form';
import {
  useOrganizations,
  useOrganizationsAreLoading,
} from '../../../../../../selectors/organizations';
import styles from '../utilities/styles';

type OrganizationSelectorProps = {
  disabled?: boolean;
};

// TODO(@retwere): consolodate this with the organization selector for Vercel.
function OrganizationSelector({disabled = false}: OrganizationSelectorProps) {
  const {control} = useFormContext();

  const organizationsAreLoading = useOrganizationsAreLoading();
  const organizations = useOrganizations();

  const organizationOptions = Object.values(organizations).map((org) => ({
    label: org.name,
    value: org.id,
  }));

  return (
    <>
      <Typography variant="h6" sx={styles.label}>
        Organization
      </Typography>
      <SelectInput
        name="organizationId"
        placeholder="Select an organization"
        control={control}
        fullWidth
        disabled={disabled || organizationsAreLoading}
        options={organizationOptions}
      />
    </>
  );
}

export default OrganizationSelector;
