import * as Sentry from '@sentry/react';
import {ASSISTANT_DATA_PLANE_URL} from '../constants';
import {
  type AssistantChatConfig,
  type AssistantFile,
  type AssistantUsage,
  type ChatMessage,
  type ChatMessageCompletion,
} from '../types';
import streamChatCompletion from './streamChatCompletion';

class AssistantDataError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export default class AssistantDataApi {
  rootUrl: string;

  token: string;

  projectId: string;

  constructor(token: string, projectId: string) {
    this.rootUrl = `https://${ASSISTANT_DATA_PLANE_URL}`;
    this.token = token;
    this.projectId = projectId;
  }

  fetch<ReturnType>(url: string, options = {}): Promise<ReturnType> {
    return fetch(`${this.rootUrl}/${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'X-Project-Id': this.projectId,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          Sentry.captureException(new AssistantDataError(text, res.status));
          throw new AssistantDataError(text, res.status);
        });
      }
      return res.json();
    });
  }

  listAssistantFiles(assistantName: string) {
    return this.fetch<{files: AssistantFile[]}>(`assistant/files/${assistantName}`);
  }

  getAssistantFile(assistantName: string, fileId: string) {
    return this.fetch<AssistantFile>(`assistant/files/${assistantName}/${fileId}`);
  }

  deleteAssistantFile(assistantName: string, fileId: string) {
    return this.fetch<AssistantFile>(`assistant/files/${assistantName}/${fileId}`, {
      method: 'DELETE',
    });
  }

  uploadAssistantFile(assistantName: string, formData: FormData) {
    return this.fetch<AssistantFile>(`assistant/files/${assistantName}`, {
      method: 'POST',
      body: formData,
    });
  }

  sendAssistantChatMessage(assistantName: string, messages: ChatMessage[]) {
    return this.fetch<ChatMessageCompletion>(`assistant/chat/${assistantName}/chat/completions`, {
      method: 'POST',
      body: JSON.stringify({messages, stream: false}),
    });
  }

  streamAssistantChatCompletion(
    assistantName: string,
    messages: ChatMessage[],
    config: AssistantChatConfig,
    onMessage: (value: string) => void,
    onChatIdReceived: (chatId: string) => void,
  ) {
    return streamChatCompletion({
      projectId: this.projectId,
      token: this.token,
      url: `${this.rootUrl}/assistant/chat/${assistantName}/chat/completions`,
      messages,
      config,
      onMessage,
      onChatIdReceived,
    });
  }

  getAssistantsUsage() {
    return this.fetch<{
      queries?: AssistantUsage;
      storage_bytes?: AssistantUsage;
      prompt_tokens?: AssistantUsage;
      completions_tokens?: AssistantUsage;
    }>(`assistant/usage`);
  }
}
