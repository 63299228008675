import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {Box, Button, Paper, Typography} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import {deleteOrganization} from '../../../actions/organizationActions';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useDashboardApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useSelectedOrganization,
} from '../../../selectors/organizations';
import {useGlobalProjectsFromSelectedOrganization} from '../../../selectors/projects';
import {type Organization} from '../../../types';
import EditOrgNameModal from './EditOrgNameModal/EditOrgNameModal';
import SsoControls from './SsoControls/SsoControls';

const styles = {
  section: {
    p: 3,
    mb: 3,
    position: 'relative',
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    gap: 3,
  },
  title: {
    pb: 3,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const deleteOrgText = (isSubscribed: boolean) => `Before deleting the organization, you must${
  isSubscribed ? ' downgrade to free and' : ''
} delete all projects and their resources.
This action cannot be undone.`;

function AccountPage() {
  const {
    val: showEditOrgNameModal,
    setTrue: setShowEditOrgNameModal,
    setFalse: setHideEditOrgNameModal,
  } = useBooleanState(false);
  const {
    val: showDeleteModal,
    setTrue: setShowDeleteModal,
    setFalse: setHideDeleteModal,
  } = useBooleanState(false);
  const selectedOrg = useSelectedOrganization();
  const isOwnerOfOrg = useIsOwnerOfOrganization();
  const projectsInOrg = useGlobalProjectsFromSelectedOrganization();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const projectIds = Object.keys(projectsInOrg);
  const {setQueryData: setDeleteOrgQueryData} = useDashboardApi<{organization: Organization}>();
  const deleteOrg = () => {
    setDeleteOrgQueryData({
      action: deleteOrganization,
      data: {
        organization: selectedOrg,
      },
    });
  };
  return (
    <Page
      header={
        <PageHeader
          title="Account settings"
          titleHidden
          description={
            <>
              Manage settings in <strong>{selectedOrg?.name}</strong> here
            </>
          }
        />
      }
    >
      <Box>
        <Box sx={styles.row}>
          <Paper sx={styles.section}>
            <Box sx={styles.title}>
              <Typography variant="h6">Organization Name</Typography>
              <IconButton
                id="edit-organization-name-button"
                ariaLabel="edit-organization-name"
                tooltip="Edit Name"
                onClick={setShowEditOrgNameModal}
                icon={<EditOutlinedIcon fontSize="small" />}
                sx={{position: 'relative', top: -8}}
              />
            </Box>
            <Typography>{selectedOrg.name}</Typography>
            <EditOrgNameModal open={showEditOrgNameModal} onClose={setHideEditOrgNameModal} />
          </Paper>
          <Paper sx={styles.section}>
            <Box sx={styles.title}>
              <Typography variant="h6">Organization ID</Typography>
              <CopyButton text={selectedOrg.id} />
            </Box>
            <Typography>{selectedOrg.id}</Typography>
          </Paper>
        </Box>
        {isOwnerOfOrg && (
          <>
            <Paper sx={styles.section}>
              <Box sx={styles.title}>
                <Typography variant="h6">Single Sign-On</Typography>
                <SsoControls plan={selectedOrg.plan} orgId={selectedOrg.id} />
              </Box>
              <Typography>
                {`Enable SSO authentication with your organization's Identity Provider`}
              </Typography>
            </Paper>
            <Paper sx={styles.section}>
              <Box sx={styles.title}>
                <Typography variant="h6" flex={1}>
                  Delete Organization
                </Typography>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={setShowDeleteModal}
                  disabled={projectIds.length > 0 || !isOwnerOfOrg || isSubscribed}
                  id="delete-organization-button"
                >
                  Delete this organization
                </Button>
              </Box>
              <Typography>{deleteOrgText(isSubscribed)}</Typography>
              <DeleteConfirmationModal
                itemType="Organization"
                doDelete={deleteOrg}
                name={selectedOrg.name}
                close={setHideDeleteModal}
                open={showDeleteModal}
                action="Delete"
              />
            </Paper>
          </>
        )}
      </Box>
    </Page>
  );
}

export default AccountPage;
