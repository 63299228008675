import {Box, Divider, Link, type SxProps, type Theme, Typography} from '@mui/material';

interface LinkGroupProps {
  title: string;
  links: Array<{text: string; href: string}>;
  divider?: boolean;
  sx?: SxProps<Theme>;
}

const styles = {
  linkGroup: {
    mb: 3,
  },
  linkGroupTitle: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.5,
    mb: 1,
  },
  link: {
    display: 'block',
    mb: 0.5,
    color: 'text.primary',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
};

function LinkGroup({title, links, divider = true, sx}: LinkGroupProps) {
  return (
    <Box sx={{...styles.linkGroup, ...sx}}>
      <Typography variant="subtitle1" sx={styles.linkGroupTitle}>
        {title}
      </Typography>
      {links.map((link, index) => (
        <Link key={index} href={link.href} target="_blank" sx={styles.link}>
          {link.text}
        </Link>
      ))}
      {divider && <Divider sx={{mt: 3}} />}
    </Box>
  );
}

export default LinkGroup;
