import {useMemo, useState} from 'react';

export const useInitializeAlertBarContext = () => {
  const [pageHasAlertBar, setPageHasAlertBar] = useState(false);

  return useMemo(
    () => ({
      pageHasAlertBar,
      setPageHasAlertBar,
    }),
    [pageHasAlertBar, setPageHasAlertBar],
  );
};
