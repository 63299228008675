import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {indexName} from '../../pages/indexes/IndexCreationPage/utilities/schema';
import {useServicesByGlobalProject} from '../../selectors/services';
import ForcedMigrationSteps from './ForcedMigrationSteps';

type MigrationNamePickerProps = {
  setName: (name: string) => void;
  globalProjectId: string;
  service: string;
};

const getSchema = (services: string[]) => {
  return z.object({
    indexName: indexName.refine(
      (name) => {
        return !services.includes(name);
      },
      {message: 'This name already exists'},
    ),
  });
};

type FormType = z.infer<ReturnType<typeof getSchema>>;

function MigrationNamePicker({service, setName, globalProjectId}: MigrationNamePickerProps) {
  const services = useServicesByGlobalProject(globalProjectId);
  const schema = getSchema(services);
  const {
    control,
    formState: {isValid},
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      indexName: '',
    },
    resolver: zodResolver(schema),
  });
  const onSubmit = (data: FormType) => {
    setName(data.indexName);
  };
  return (
    <Box position="relative">
      <ModalHeader title="Migration in progress" subtitle={service} />
      <ForcedMigrationSteps fullMigration activeStep={1} />
      <Box py={2}>
        <TextInput
          label="Name your serverless index"
          fullWidth
          control={control}
          name="indexName"
          placeholder="Enter a unique name"
        />
      </Box>
      <Button variant="contained" disabled={!isValid} onClick={handleSubmit(onSubmit)} fullWidth>
        Continue
      </Button>
    </Box>
  );
}

export default MigrationNamePicker;
