import {Box} from '@mui/material';
import LinkGroup from './LinkGroup';

const styles = {
  linkSection: {
    width: 218,
  },
  linkSectionHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    gap: 4,
  },
  linkSectionVertical: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    justifyContent: 'flex-start',
    gap: 0,
  },
  linkGroupHorizontal: {
    width: 331,
  },
  linkGroupVertical: {
    width: 'auto',
  },
};

export function LinkSection({orientation = 'vertical'}: {orientation?: 'vertical' | 'horizontal'}) {
  const isHorizontal = orientation === 'horizontal';
  return (
    <Box
      sx={{
        ...styles.linkSection,
        ...(isHorizontal ? styles.linkSectionHorizontal : styles.linkSectionVertical),
      }}
    >
      <LinkGroup
        title="Featured guides"
        links={[
          {text: 'Quickstart', href: 'https://docs.pinecone.io/guides/get-started/quickstart'},
          {text: 'Key concepts', href: 'https://docs.pinecone.io/guides/get-started/key-concepts'},
          {
            text: 'Build a RAG chatbot',
            href: 'https://docs.pinecone.io/guides/get-started/build-a-rag-chatbot',
          },
          {
            text: 'Implement multitenancy',
            href: 'https://docs.pinecone.io/guides/indexes/implement-multitenancy',
          },
          {
            text: 'Understanding indexes',
            href: 'https://docs.pinecone.io/guides/indexes/understanding-indexes',
          },
        ]}
        divider={!isHorizontal}
        sx={{
          width: isHorizontal ? 331 : 'auto',
        }}
      />
      <LinkGroup
        title="Billing questions"
        links={[
          {
            text: 'Understanding cost',
            href: 'https://docs.pinecone.io/guides/organizations/manage-cost/understanding-cost',
          },
          {
            text: 'Monitor your usage',
            href: 'https://docs.pinecone.io/guides/organizations/manage-cost/monitor-your-usage',
          },
          {
            text: 'Manage cost',
            href: 'https://docs.pinecone.io/guides/organizations/manage-cost/manage-cost',
          },
          {
            text: 'Change your billing plan',
            href: 'https://docs.pinecone.io/guides/organizations/manage-billing/changing-your-billing-plan',
          },
          {text: 'Quotas and limits', href: 'https://docs.pinecone.io/reference/quotas-and-limits'},
        ]}
        divider={!isHorizontal}
        sx={{
          width: isHorizontal ? '331px' : 'auto',
        }}
      />
      <LinkGroup
        title="FAQ"
        divider={false}
        links={[
          {
            text: 'How to work with support',
            href: 'https://docs.pinecone.io/troubleshooting/how-to-work-with-support',
          },
          {
            text: 'Pinecone support SLAs',
            href: 'https://docs.pinecone.io/troubleshooting/pinecone-support-slas',
          },
          {text: 'Security', href: 'https://docs.pinecone.io/reference/security'},
          {
            text: 'Serverless architecture',
            href: 'https://docs.pinecone.io/reference/architecture/serverless-architecture',
          },
          {text: 'Best practices', href: 'https://docs.pinecone.io/troubleshooting/best-practices'},
        ]}
        sx={{
          width: isHorizontal ? '331px' : 'auto',
        }}
      />
    </Box>
  );
}
