import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';

export const getArchivedRequest = createAction<{globalProjectId: string}>('GET_ARCHIVED_REQUEST');
export const getArchivedSuccess = createAction<{
  globalProjectId: string;
  archived: {indexName: string}[];
}>('GET_ARCHIVED_SUCCESS');
export const getArchivedFailure = createAction<{globalProjectId: string; err: Error}>(
  'GET_ARCHIVED_FAILURE',
);

export const openModal = createAction<{migratingIndex: string}>('OPEN_MIGRATION_MODAL');
export const closeModal = createAction<undefined>('CLOSE_MIGRATION_MODAL');

export function getArchived(token: string, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getArchivedRequest({globalProjectId: data.globalProjectId}));
    return new DashboardApi(token)
      .getArchived(data.globalProjectId)
      .then((res) => {
        dispatch(
          getArchivedSuccess({globalProjectId: data.globalProjectId, archived: res.archived || []}),
        );
      })
      .catch((err) => {
        dispatch(getArchivedFailure({globalProjectId: data.globalProjectId, err}));
      });
  };
}

export const getDeletedApiKeysRequest = createAction<{orgId: string}>(
  'GET_DELETED_API_KEYS_REQUEST',
);
export const getDeletedApiKeysSuccess = createAction<{orgId: string; apiKeysDeleted: boolean}>(
  'GET_DELETED_API_KEYS_SUCCESS',
);
export const getDeletedApiKeysFailure = createAction<{orgId: string}>(
  'GET_DELETED_API_KEYS_FAILURE',
);

export function getDeletedApiKeys(token: string, data: {orgId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getDeletedApiKeysRequest({orgId: data.orgId}));
    return new DashboardApi(token)
      .getDeletedApiKeys(data.orgId)
      .then((res) => {
        dispatch(getDeletedApiKeysSuccess({orgId: data.orgId, apiKeysDeleted: res.apiKeysDeleted}));
      })
      .catch(() => {
        dispatch(getDeletedApiKeysFailure({orgId: data.orgId}));
      });
  };
}
