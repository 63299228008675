import {type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import {push} from 'redux-first-history';
import type DataPlaneApi from '../api/dataPlaneApi';
import {
  type ListBulkImportsResponse,
  type PaginatedResponseRequestParams,
  type StartBulkImportRequest,
} from '../api/dataPlaneApi';
import {IndexDetailTab} from '../constants';
import {type BulkImport} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const listBulkImportsRequest = createAction<{
  globalProjectId: string;
  indexName: string;
  limit?: number;
  paginationToken?: string;
}>('LIST_BULK_IMPORTS_REQUEST');
export const listBulkImportsSuccess = createAction<{
  globalProjectId: string;
  indexName: string;
  response: ListBulkImportsResponse;
}>('LIST_BULK_IMPORTS_SUCCESS');
export const listBulkImportsFailure = createAction<{
  globalProjectId: string;
  indexName: string;
  error: string;
}>('LIST_BULK_IMPORTS_FAILURE');

export function listBulkImports(
  api: DataPlaneApi,
  globalProjectId: string,
  indexName: string,
  data: PaginatedResponseRequestParams,
) {
  return (dispatch: Dispatch) => {
    dispatch(
      listBulkImportsRequest({
        globalProjectId,
        indexName,
        limit: data.limit,
        paginationToken: data.paginationToken,
      }),
    );
    return api
      .listBulkImports(data)
      .then((res) => {
        dispatch(
          listBulkImportsSuccess({
            globalProjectId,
            indexName,
            response: res,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          listBulkImportsFailure({
            globalProjectId,
            indexName,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to fetch imports: ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const getBulkImportSuccess = createAction<{
  globalProjectId: string;
  indexName: string;
  data: BulkImport;
}>('GET_BULK_IMPORT_SUCCESS');
export function getBulkImport(
  api: DataPlaneApi,
  globalProjectId: string,
  indexName: string,
  data: {
    id: string;
  },
) {
  return (dispatch: Dispatch) => {
    return api
      .getBulkImport(data.id)
      .then((res) => {
        dispatch(
          getBulkImportSuccess({
            globalProjectId,
            indexName,
            data: res,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to fetch import data. ${decodeErrorMessage(err.message)}`,
          }),
        );
      });
  };
}

export const startBulkImportRequest = createAction<{
  globalProjectId: string;
  indexName: string;
}>('START_BULK_IMPORT_REQUEST');
export const startBulkImportFailure = createAction<{
  globalProjectId: string;
  indexName: string;
}>('START_BULK_IMPORT_FAILURE');

export function startBulkImport(
  api: DataPlaneApi,
  globalProjectId: string,
  indexName: string,
  data: StartBulkImportRequest,
) {
  return (dispatch: Dispatch) => {
    dispatch(
      startBulkImportRequest({
        globalProjectId,
        indexName,
      }),
    );

    return api
      .startBulkImport(data)
      .then((res) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Import successfully initiated with ID: ${res.id}`,
          }),
        );
        // No need to dispatch success event, as response has no data and
        // listBulkImports will eventually set loading state to false
        dispatch(listBulkImports(api, globalProjectId, indexName, {}) as unknown as AnyAction);
        dispatch(
          push(
            `/organizations/-/projects/${globalProjectId}/indexes/${indexName}/${IndexDetailTab.IMPORTS}`,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Import failed to initiate. ${decodeErrorMessage(err.message)}`,
          }),
        );
        dispatch(
          startBulkImportFailure({
            globalProjectId,
            indexName,
          }),
        );
      });
  };
}

export const cancelBulkImportRequest = createAction<{
  globalProjectId: string;
  indexName: string;
}>('CANCEL_BULK_IMPORT_REQUEST');
export const cancelBulkImportFailure = createAction<{
  globalProjectId: string;
  indexName: string;
}>('CANCEL_BULK_IMPORT_FAILURE');

export function cancelBulkImport(
  api: DataPlaneApi,
  globalProjectId: string,
  indexName: string,
  data: {id: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      cancelBulkImportRequest({
        globalProjectId,
        indexName,
      }),
    );

    return api
      .cancelBulkImport(data.id)
      .then(() => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Import successfully cancelled.`,
          }),
        );
        // No need to dispatch success event, as response has no data and
        // listBulkImports will eventually set loading state to false
        dispatch(listBulkImports(api, globalProjectId, indexName, {}) as unknown as AnyAction);
      })
      .catch((err) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to cancel import. ${decodeErrorMessage(err.message)}`,
          }),
        );
        dispatch(
          cancelBulkImportFailure({
            globalProjectId,
            indexName,
          }),
        );
      });
  };
}
