import {z} from 'zod';
import {Roles} from '../../../../../../constants';

const errorMessage = 'Required';
export const CreateConfigForm = z.object({
  signInEndpoint: z.string().nonempty(errorMessage),
  domain: z.string().nonempty(errorMessage),
  cert: z.string().nonempty(errorMessage),
  enforceSsoAuthentication: z.boolean(),
});

export type CreateConfigType = z.infer<typeof CreateConfigForm>;

export const SsoRulesForm = z.object({
  orgRole: z.nativeEnum(Roles),
  projects: z.array(
    z.object({
      projectId: z.string().nonempty(),
      role: z.nativeEnum(Roles),
    }),
  ),
});
export type SsoRulesType = z.infer<typeof SsoRulesForm>;
