import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Link, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import {useState} from 'react';
import {useMatch} from 'react-router-dom';
import {LocalStorageKeys, PUBLIC_DOCS_URL} from '../../constants';
import {useNavigateToCollectionPageGlobal} from '../../hooks/navigation';
import {useArchivedIndexesByGlobalProject} from '../../selectors/forcedMigration';
import {localStorageGet, localStorageSet} from '../../utils/storage';

type ArchivedAlertsProps = {
  globalProjectId: string;
  orgId: string;
};

const COLLECTIONS_DOCS = `${PUBLIC_DOCS_URL}/docs/collections`;

function ArchivedAlerts({orgId, globalProjectId}: ArchivedAlertsProps) {
  const archivedIndexes = useArchivedIndexesByGlobalProject(globalProjectId);
  const {getPath} = useNavigateToCollectionPageGlobal(orgId, globalProjectId);
  const path = getPath();
  const match = useMatch(path);
  const [show, setShown] = useState(
    !localStorageGet(LocalStorageKeys.VIEWED_ARCHIVED_NOTIFICATION),
  );
  const close = () => {
    localStorageSet(LocalStorageKeys.VIEWED_ARCHIVED_NOTIFICATION, 'true');
    setShown(false);
  };
  if (!archivedIndexes?.length || !show) {
    return null;
  }
  return (
    <Box>
      {archivedIndexes.map((archived) => (
        <Alert
          key={archived.indexName}
          title={`"${archived.indexName}" has been auto-archived`}
          action={
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          }
          description={
            match ? (
              <Typography>
                To reactive your index, find it in the list and click Actions {'>'} Create index.{' '}
                <Link href={COLLECTIONS_DOCS} target="_blank" rel="noreferrer">
                  Learn More
                </Link>
              </Typography>
            ) : (
              <Typography>
                To reactivate your index, go to this <Link href={path}>Collection</Link> and click
                {` '`}Create Index{`'`}.{' '}
                <Link target="_blank" rel="noreferrer" href={COLLECTIONS_DOCS}>
                  Learn More
                </Link>
              </Typography>
            )
          }
        />
      ))}
    </Box>
  );
}

export default ArchivedAlerts;
