import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {type TooltipProps} from 'recharts';
import {type NameType, type ValueType} from 'recharts/types/component/DefaultTooltipContent';
import {type LineConfig} from '../LineChart/LineChart';
import {getLineLabel} from '../utils/getLineLabel';

export interface LabelConfig {
  titleFormatter: (value: string) => string;
  subtitleFormatter?: (value: string) => string;
}

export type TooltipContentProps = TooltipProps<ValueType, NameType> & {
  labelConfig?: LabelConfig;
  lines: LineConfig[];
};

const styles = {
  container: {
    p: 2,
    minWidth: 200,
    boxShadow: 2,
    zIndex: 50,
  },
  colorTag: {
    display: 'inline-block',
    borderRadius: '4px',
    height: 25,
    width: 5,
    mr: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
};

export default function TooltipContent({
  active,
  label,
  payload,
  labelConfig,
  lines,
}: TooltipContentProps) {
  if (!active || !label) return null;

  const title = labelConfig?.titleFormatter(label) || label;
  const subtitle = labelConfig?.subtitleFormatter ? labelConfig.subtitleFormatter(label) : label;

  return (
    <Card sx={styles.container}>
      <Typography variant="overline" color="text.secondary">
        {subtitle}
      </Typography>
      <Typography variant="h6" component="h4" gutterBottom>
        {title}
      </Typography>
      <Box display="flex" flexDirection="column">
        {payload?.map(({dataKey = '', name, color, value, unit}) => {
          const lineLabel = getLineLabel(lines, dataKey.toString());

          if (value === undefined) {
            return null;
          }

          return (
            <Box key={name} display="flex" justifyContent="space-between" alignItems="center">
              <Box sx={styles.row}>
                <Box sx={[styles.colorTag, {backgroundColor: color}]} />
                <Typography variant="body1" display="inline-block" noWrap>
                  {lineLabel}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography ml={2} variant="h6">
                  {Number(value).toLocaleString()}
                </Typography>
                <Typography ml={0.5}>{unit || ''}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
}
