import {Box, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {LANGUAGE_CONTENT} from '../../../../../../components/CodeSnippets/constants';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import {
  CHECK_THE_INDEX,
  CREATE_EMBEDDINGS_CODE,
  DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS,
  DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS,
  UPSERT_CODE_SNIPPETS,
} from './constants';

export const Section3Header = () => {
  return (
    <Box>
      <Typography mb={2}>
        Now that you are connected to Pinecone, the next critical step is to set up an index to
        store your data.
      </Typography>
    </Box>
  );
};

export const CreateServerlessIndex = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  const dimension = DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS.some(
    (l) => l === selectedLanguage,
  )
    ? 1024
    : 2;
  return (
    <Box>
      <Typography mb={2}>
        An index defines the dimension of vectors to be stored and the similarity metric to be used
        when querying them.
      </Typography>
      <Typography mb={2}>
        Create a serverless index with a dimension and similarity metric based on the embedding
        model you’ll use to create the vector embeddings:
      </Typography>
      <CodeBlock
        code={selectedLanguageContent.createIndexCode(dimension)}
        language={selectedLanguage}
      />
    </Box>
  );
};

export const CreateEmbeddings = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  return (
    <Box>
      <Typography mb={2}>
        A vector embedding is a series of numerical values that represent the meaning and
        relationships of words, sentences, and other data.
      </Typography>
      <Typography mb={2}>
        Use Pinecone Inference to generate embeddings from sentences related to the word
        &quot;apple&quot;:
      </Typography>
      <CodeBlock language={selectedLanguage} code={CREATE_EMBEDDINGS_CODE[selectedLanguage]} />
    </Box>
  );
};

export const UpsertVectors = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  return (
    <Box>
      {DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS.some((l) => l === selectedLanguage) ? (
        <Box mb={2}>
          <Typography mb={2}>
            Upsert the six generated vector embeddings into a new <InlineCode text="ns1" />{' '}
            namespace in your index:
          </Typography>
        </Box>
      ) : (
        <Box mb={2}>
          <Typography mb={2}>
            Upsert six sample 2-dimensional vector embeddings into 2 distinct namespaces in your
            index:
          </Typography>
        </Box>
      )}
      <CodeBlock language={selectedLanguage} code={UPSERT_CODE_SNIPPETS[selectedLanguage]} />
    </Box>
  );
};

export const CheckTheIndex = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  return (
    <Box>
      <Typography mb={2}>
        Use the <InlineCode text="describe_index_stats" /> operation to check if the current vector
        count matches the number of vectors you upserted (6):
      </Typography>
      <CodeBlock code={CHECK_THE_INDEX[selectedLanguage]} language={selectedLanguage} />
      <Alert
        severity="info"
        description="Pinecone is eventually consistent, so there can be a delay before your upserted vectors are available to query."
      />
    </Box>
  );
};
