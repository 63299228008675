import {MicrosoftE5Large} from '../../models/constants';
import moviesVectorData from './movies-vectors.json';
import type {Dataset} from './types';

export const moviesDataset: Dataset = {
  datasetInformation: {
    vectorData: moviesVectorData,
    writeUnits: 643,
    indexName: 'sample-movies',
    title: 'Movies',
    subtitle: "Blockbuster hits from 'Avatar' to 'Star Wars'.",
    description:
      "This curated sample of data includes brief overviews of blockbuster hits from “Avatar” to “Star Wars”. Each entry lists their box office revenue, title, release year, and summary of the film's narrative.",
    dimension: 1024,
    metric: 'cosine',
    totalNamespaces: 1,
    totalVectors: 100,
    upgradeRequired: false,
  },
  embeddingModel: MicrosoftE5Large,
  exampleVectorData: moviesVectorData.slice(0, 4),
};
