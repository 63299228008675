import {useCallback} from 'react';
import {
  createStorageIntegration,
  deleteStorageIntegration,
  listStorageIntegrations,
  updateStorageIntegration,
} from '../../../actions/storageIntegrationActions';
import {
  type CreateStorageIntegrationRequest,
  type UpdateStorageIntegrationRequest,
} from '../../../api/types/controllerApi';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useControllerApi} from '../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {useStorageIntegrationsAreLoading} from '../../../selectors/storageIntegrations';
import CreateStorageButton from './CreateStorageButton/CreateStorageButton';
import StorageList from './StorageList/StorageList';

/** A page for users to manage their project's storage integrations. */
function StoragePage() {
  const selectedProject = useSelectedGlobalProject();
  const globalProjectId = selectedProject.id;
  const loading = useStorageIntegrationsAreLoading(globalProjectId);

  useControllerApi(selectedProject, listStorageIntegrations, {globalProjectId});

  const {setQueryData: setCreateData} = useControllerApi<{
    globalProjectId: string;
    data: CreateStorageIntegrationRequest;
  }>(selectedProject);
  const {setQueryData: setUpdateData} = useControllerApi<{
    globalProjectId: string;
    integrationId: string;
    data: UpdateStorageIntegrationRequest;
  }>(selectedProject);
  const {setQueryData: setDeleteData} = useControllerApi<{
    globalProjectId: string;
    integrationId: string;
  }>(selectedProject);

  const onCreate = useCallback(
    (data: CreateStorageIntegrationRequest) => {
      setCreateData({
        action: createStorageIntegration,
        data: {globalProjectId, data},
      });
    },
    [globalProjectId, setCreateData],
  );

  const onEdit = useCallback(
    (integrationId: string, data: UpdateStorageIntegrationRequest) => {
      setUpdateData({
        action: updateStorageIntegration,
        data: {globalProjectId, integrationId, data},
      });
    },
    [globalProjectId, setUpdateData],
  );

  const onDelete = useCallback(
    (integrationId: string) => {
      setDeleteData({
        action: deleteStorageIntegration,
        data: {globalProjectId, integrationId},
      });
    },
    [globalProjectId, setDeleteData],
  );

  return (
    <Page
      header={
        <PageHeader
          title="Storage integrations"
          actions={<CreateStorageButton onCreate={onCreate} />}
        />
      }
      loading={loading}
    >
      <StorageList onEdit={onEdit} onDelete={onDelete} />
    </Page>
  );
}

export default StoragePage;
