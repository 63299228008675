import {Box, Typography} from '@mui/material';
import {ReturnMechanismNames} from '@pinecone-experience/integrations';
import Image from '../../../../../components/Utilities/Image/Image';
import {useIntegrationLogo} from '../../../../../selectors/integrations';
import {type Integration, type IntegrationUpdateData} from '../../../../../types';
import IntegrationActions from './IntegrationActions/IntegrationActions';

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center',
  },
};

type IntegrationCellProps = {
  /** The integrations to be listed. */
  integrations: Integration[];
  /** Which integration (row) to render. */
  row: number;
  /** Which cell (column) to render. */
  col: number;
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: IntegrationUpdateData) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** A cell in the table of integrations. */
const IntegrationCell = ({integrations, row, col, onEdit, onDelete}: IntegrationCellProps) => {
  const integration = integrations[row];
  const logoUrl = useIntegrationLogo(integration.slug);
  switch (col) {
    case 0:
      return (
        <Box sx={{maxWidth: 80, overflow: 'hidden'}}>
          <Image src={logoUrl} contrast alt="Logo" width={64} height={64} padding={0.5} />
        </Box>
      );
    case 1:
      return (
        <Box sx={styles.row}>
          <Typography fontWeight="bold">{integration.profile.name}</Typography>
        </Box>
      );
    case 2:
      return <Typography>{integration.slug}</Typography>;
    case 3:
      return <Typography>{ReturnMechanismNames[integration.return_mechanism]}</Typography>;
    case 4:
      return <IntegrationActions integration={integration} onEdit={onEdit} onDelete={onDelete} />;
    default:
      return null;
  }
};

export default IntegrationCell;
