import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {useState} from 'react';
import {Flags, useFlag} from '../../hooks/flags';
import {useFirstApiKeyByGlobalProjectId} from '../../selectors/keys';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {useServiceByGlobalProject} from '../../selectors/services';
import {getSpecs} from '../../utils/services';
import type {ConnectTextTemplate} from './constants';

interface ConnectClientCodeSnippetProps {
  codeTemplate: ConnectTextTemplate;
  language: string;
  service?: string;
  withGetIndex: boolean;
}

export const API_KEY_REDACTED = '********-****-****-****-************';

function ConnectClientCodeSnippet({
  codeTemplate,
  language,
  service,
  withGetIndex,
}: ConnectClientCodeSnippetProps) {
  const showKeyOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);
  const [showApiKey, setShowApiKey] = useState(false);
  const toggleApiKeyVisibility = () => setShowApiKey((visibility) => !visibility);

  const project = useSelectedGlobalProject() || {};
  const {value: apiKeyValue} = useFirstApiKeyByGlobalProjectId(project.id) || {};
  const serviceInfo = useServiceByGlobalProject(project.id, service || '');
  const {serverlessSpec} = getSpecs(serviceInfo);

  const code = codeTemplate({
    project,
    cloud: serverlessSpec?.cloud || '',
    region: serverlessSpec?.region || '',
    host: serviceInfo?.host || '',
    serviceName: service,
    apiKey: apiKeyValue,
    withGetIndex,
  });

  const codeRedacted = codeTemplate({
    project,
    cloud: serverlessSpec?.cloud,
    region: serverlessSpec?.region,
    serviceName: service,
    host: serviceInfo?.host || '',
    apiKey: API_KEY_REDACTED,
    withGetIndex,
  });

  return (
    <CodeBlock
      code={showApiKey ? code : codeRedacted}
      controls={
        !showKeyOnce && (
          <IconButton
            id="connect-hide-api-key"
            ariaLabel="toggle-api-key-visibility"
            onClick={toggleApiKeyVisibility}
            tooltip={showApiKey ? 'Hide API Key' : 'Show API Key'}
            icon={
              showApiKey ? (
                <VisibilityIcon fontSize="small" data-testid="show-api-key-icon" />
              ) : (
                <VisibilityOffIcon fontSize="small" data-testid="hide-api-key-icon" />
              )
            }
          />
        )
      }
      language={language}
      label="connect-client-code"
    />
  );
}

export default ConnectClientCodeSnippet;
