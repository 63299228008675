import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import {AssistantPagePanel} from '../../../../constants';
import {useNavigateToAssistant} from '../../../../hooks/navigation';
import {useSelectedAssistantName} from '../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import AssistantActionMenu from '../../AssistantActionMenu/AssistantActionMenu';
import {ControlButton} from './ControlButton/ControlButton';

interface AssistantDrawerControlsProps {
  id: string;
  hidden: boolean;
  selected?: AssistantPagePanel;
  controls: DrawerControls;
}

export interface DrawerControls {
  show: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

export default function AssistantDrawerControls({
  id,
  selected,
  hidden,
  controls,
}: AssistantDrawerControlsProps) {
  const project = useSelectedGlobalProject();
  const assistantName = useSelectedAssistantName() || '';
  const {go} = useNavigateToAssistant(project.organization_id, project.id);

  const handleClick = (panel: AssistantPagePanel) => {
    controls.onOpen();
    go(assistantName, panel);
  };

  return (
    <Box sx={styles.root}>
      <ControlButton
        title="Settings"
        id={`${id}-settings-button`}
        icon={<SettingsOutlinedIcon fontSize="small" />}
        selected={selected === AssistantPagePanel.SETTINGS}
        onClick={() => handleClick(AssistantPagePanel.SETTINGS)}
        hidden={hidden}
      />
      <ControlButton
        title="Files"
        id={`${id}-files-button`}
        icon={<FolderOpenOutlinedIcon fontSize="small" />}
        selected={selected === AssistantPagePanel.FILES}
        onClick={() => handleClick(AssistantPagePanel.FILES)}
        hidden={hidden}
      />
      <ControlButton
        title="Connect"
        id={`${id}-connect-button`}
        icon={<CodeOutlinedIcon fontSize="small" />}
        selected={selected === AssistantPagePanel.CONNECT}
        onClick={() => handleClick(AssistantPagePanel.CONNECT)}
        hidden={hidden}
      />
      <AssistantActionMenu disabled={hidden} project={project} name={assistantName} />
    </Box>
  );
}
