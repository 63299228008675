/* eslint-disable no-console */
import {datadogRum} from '@datadog/browser-rum';
import {datadogConfig} from '../constants';
import {env, isDevelopment} from './environment';

interface DatadogConfig {
  applicationId: string;
  clientToken: string;
  netlifyBuildId: string;
}

const isCloudrunUrl = (url: string) => url.includes('console-api.pinecone.io');

export const getTag = (resourceUrl: string) => {
  // Classfies the paths - api or satelite
  const tag = {
    internal: true,
    apiType: '',
    env: '',
    obscuredPath: '',
    isCloudrun: false,
  };
  const url = new URL(resourceUrl);
  let obscuredPath = url.pathname;
  const isCloudrun = isCloudrunUrl(url.href);
  const isDashboard = url.href.includes('api/v2/dashboard') || isCloudrun;

  if (isDashboard) {
    tag.isCloudrun = isCloudrun;
    // eslint-disable-next-line prefer-destructuring
    obscuredPath = obscuredPath.split('/v2/')[1];
    tag.apiType = 'dashboard';
  } else if (
    url.hostname.endsWith('pinecone.io') &&
    (url.hostname.startsWith('controller') || url.hostname.includes('.svc.'))
  ) {
    tag.apiType = 'sat';
    const segments = url.hostname.split('.pinecone.io')[0].split('.');
    tag.env = segments[segments.length - 1];
  }
  obscuredPath = obscuredPath.replace(/\/-[a-zA-Z0-9_-]+/g, '/:id');
  obscuredPath = obscuredPath.replace(/\/[a-z0-9-]+-[a-z0-9-]+:[a-z-0-9]+/g, '/:id');
  obscuredPath = obscuredPath.replace(/\/databases\/[a-zA-Z0-9_.-]+/g, '/databases/:id');
  obscuredPath = obscuredPath.replace(/\/collections\/[a-zA-Z0-9_.-]+/g, '/collections/:id');
  tag.obscuredPath = obscuredPath;

  return tag;
};

class Metrics {
  applicationId: string;

  clientToken: string;

  netlifyBuildId: string;

  datadogRum: any;

  constructor({applicationId, clientToken, netlifyBuildId}: DatadogConfig) {
    this.applicationId = applicationId;
    this.clientToken = clientToken;
    this.netlifyBuildId = netlifyBuildId;
    this.datadogRum = datadogRum;
  }

  init() {
    const {applicationId, clientToken, netlifyBuildId} = this;

    if (!applicationId) {
      return;
    }

    try {
      datadogRum.init({
        applicationId,
        clientToken,
        env,
        site: 'datadoghq.com',
        service: 'console-ux',
        version: netlifyBuildId || '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: (event) => {
          if (event.type === 'resource' && event.resource.type === 'fetch') {
            try {
              const tag = getTag(event.resource.url);
              event.context = {...event.context, ...tag};
            } catch (e) {
              if (isDevelopment) {
                console.log('DataDog failed to attach tags');
              }
            }
          }
        },
      });
    } catch (e) {
      if (isDevelopment) {
        console.log('DataDog failed to initialize');
      }
    }
  }

  logAction(action: string, data = {}) {
    try {
      this.datadogRum.addAction(action, data);
    } catch (e) {
      if (isDevelopment) {
        console.log('DataDog failed to log');
      }
    }
  }
}

const {applicationId, clientToken} = datadogConfig;
const netlifyBuildId = import.meta.env.BUILD_ID || '1.0.0';

const DatadogMetrics = new Metrics({applicationId, clientToken, netlifyBuildId});

export default DatadogMetrics;
