import {Box, Button, Checkbox, FormControlLabel, FormGroup, Link, Typography} from '@mui/material';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useServerlessEnvironments} from '../../../selectors/environments';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {type V4IndexInfoResponse} from '../../../types';
import {MIGRATION_DOCS_URL} from '../constants';
import {type IndexTargetType} from '../schema';

type SelectIndexScreenProps = {
  onSubmit: () => void;
  append: (index: IndexTargetType) => void;
  remove: (index: number) => void;
  selectedList: IndexTargetType[];
  availableIndexes: V4IndexInfoResponse[];
};

const styles = {
  shaded: {
    p: 2,
    backgroundColor: 'background.surface',
  },
};

function IndexCheckRow({
  indexName,
  checked,
  onSelect,
  remove,
}: {
  indexName: string;
  checked: boolean;
  onSelect: () => void;
  remove: () => void;
}) {
  const onChange = (event: React.ChangeEvent<EventTarget>) => {
    if ((event.target as HTMLInputElement).checked) {
      onSelect();
    } else {
      remove();
    }
  };
  return (
    <FormControlLabel
      onChange={onChange}
      componentsProps={{typography: {fontWeight: checked ? 'bold' : ''}}}
      control={<Checkbox checked={checked} />}
      label={indexName}
    />
  );
}

function SelectIndexesScreen({
  onSubmit,
  append,
  remove,
  selectedList,
  availableIndexes,
}: SelectIndexScreenProps) {
  const orgId = useSelectedOrganizationId();
  const serverlessEnvironments = useServerlessEnvironments(orgId);
  const onSelectIndex = (sourceName: string) => {
    append({
      sourceName,
      targetName: `${sourceName}-serverless`,
      cloud: serverlessEnvironments[0]?.provider,
      region: serverlessEnvironments[0]?.provider_region || '',
    });
  };
  return (
    <>
      <ModalHeader title="Reduce costs by up to 50X with serverless migration!" />
      <Box>
        <Typography>
          Move your pod-based indexes to serverless to slash costs by up to 50 times with
          usage-based pricing and no minimum fees. Our scalable, seamless architecture ensures your
          data stays fresh and relevant. Read{' '}
          <Link href={MIGRATION_DOCS_URL} target="_blank" rel="noreferrer">
            more
          </Link>{' '}
          about it.
        </Typography>
      </Box>
      <Box sx={styles.shaded}>
        <Typography fontWeight="bold" mb={2}>
          Migrate these indexes today:
        </Typography>
        <FormGroup>
          {availableIndexes.map((index) => {
            const isSelected = selectedList.findIndex(
              (selectedIndex) => selectedIndex.sourceName === index.name,
            );
            return (
              <IndexCheckRow
                remove={() => remove(isSelected)}
                onSelect={() => onSelectIndex(index.name)}
                key={index.name}
                indexName={index.name}
                checked={isSelected >= 0}
              />
            );
          })}
        </FormGroup>
      </Box>
      <Box>
        <Button
          disabled={selectedList.length === 0}
          onClick={onSubmit}
          variant="contained"
          fullWidth
        >
          {`Migrate ${selectedList.length} indexes`}
        </Button>
      </Box>
    </>
  );
}

export default SelectIndexesScreen;
