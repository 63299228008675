import {type Dispatch, createAction} from '@reduxjs/toolkit';
import PrometheusApi from '../api/prometheusApi';
import {
  type PrometheusLatencyResponse,
  type PrometheusLegacyResponse,
  type PrometheusTimestampedResponse,
} from '../types';
import {decodeErrorMessage} from './utils/errors';

export const vectorCountQueryRequest = createAction<string>('PROMETHEUS_ITEM_COUNT_REQUEST');
export const vectorCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_ITEM_COUNT_SUCCESS');
export const vectorCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_ITEM_COUNT_FAILURE',
);

// TODO: create prom api in hook and pass in here instead?
export function requestVectorCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
  isServerless: boolean,
) {
  return (dispatch: Dispatch) => {
    dispatch(vectorCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryVectorCount(projectId, service, start, end, step, isServerless)
      .then((data) => {
        dispatch(vectorCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(vectorCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const storageUnitsCountQueryRequest = createAction<string>(
  'PROMETHEUS_STORAGE_UNITS_COUNT_REQUEST',
);
export const storageUnitsCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_STORAGE_UNITS_COUNT_SUCCESS');
export const storageUnitsCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_STORAGE_UNITS_COUNT_FAILURE',
);

// TODO: create prom api in hook and pass in here instead?
export function storageUnitsCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(storageUnitsCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryStorageUnitsCountQuery(projectId, service, start, end, step)
      .then((data) => {
        dispatch(storageUnitsCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(storageUnitsCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const heartbeatQueryRequest = createAction<string>(
  'PROMETHEUS_HEARTBEAT_ITEM_COUNT_REQUEST',
);
export const heartbeatQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_HEARTBEAT_ITEM_COUNT_SUCCESS');
export const heartbeatQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_HEARTBEAT_ITEM_COUNT_FAILURE',
);

// TODO: create prom api in hook and pass in here instead?
export function requestHeartBeatQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
  isServerless: boolean,
) {
  return (dispatch: Dispatch) => {
    dispatch(heartbeatQueryRequest(service));
    return new PrometheusApi(token)
      .queryVectorCount(projectId, service, start, end, step, isServerless)
      .then((data) => {
        dispatch(heartbeatQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(heartbeatQueryFailure({service, error}));
        return error;
      });
  };
}
export const requestCountQueryRequest = createAction<string>('PROMETHEUS_REQUEST_COUNT_REQUEST');
export const requestCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_REQUEST_COUNT_SUCCESS');
export const requestCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_REQUEST_COUNT_FAILURE',
);

export function requestRequestCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(requestCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryRequestCount(projectId, service, start, end, step)
      .then((data) => {
        dispatch(requestCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(requestCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const readUnitsCountQueryRequest = createAction<string>(
  'PROMETHEUS_READ_UNITS_COUNT_REQUEST',
);
export const readUnitsCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_READ_UNITS_COUNT_SUCCESS');
export const readUnitsCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_READ_UNITS_COUNT_FAILURE',
);

export function serverlessReadUnitCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(readUnitsCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryServerlessReadUnitsCount(projectId, service, start, end, step)
      .then((data) => {
        dispatch(readUnitsCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(readUnitsCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const writeUnitsCountQueryRequest = createAction<string>(
  'PROMETHEUS_WRITE_UNITS_COUNT_REQUEST',
);
export const writeUnitsCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_WRITE_UNITS_COUNT_SUCCESS');
export const writeUnitsCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_WRITE_UNITS_COUNT_FAILURE',
);

export function serverlessWriteUnitCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(writeUnitsCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryServerlessWriteUnitsCount(projectId, service, start, end, step)
      .then((data) => {
        dispatch(writeUnitsCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(writeUnitsCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const errorCountQueryRequest = createAction<string>('PROMETHEUS_ERROR_COUNT_REQUEST');
export const errorCountQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_ERROR_COUNT_SUCCESS');
export const errorCountQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_ERROR_COUNT_FAILURE',
);

export function requestErrorCountQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(errorCountQueryRequest(service));
    return new PrometheusApi(token)
      .queryErrorCount(projectId, service, start, end, step)
      .then((data) => {
        dispatch(errorCountQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(errorCountQueryFailure({service, error}));
        return error;
      });
  };
}

export const latencyQueryRequest = createAction<string>('PROMETHEUS_LATENCY_REQUEST');
export const latencyQuerySuccess = createAction<{service: string; data: PrometheusLatencyResponse}>(
  'PROMETHEUS_LATENCY_SUCCESS',
);
export const latencyQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_LATENCY_FAILURE',
);

export function requestLatencyQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(latencyQueryRequest(service));
    return new PrometheusApi(token)
      .queryLatency(projectId, service, start, end, step)
      .then((data) => {
        dispatch(latencyQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(latencyQueryFailure({service, error}));
        return error;
      });
  };
}

export const podFullnessQueryRequest = createAction<string>('PROMETHEUS_POD_FULLNESS_REQUEST');
export const podFullnessQuerySuccess = createAction<{
  service: string;
  data: PrometheusLegacyResponse;
}>('PROMETHEUS_POD_FULLNESS_SUCCESS');
export const podFullnessQueryFailure = createAction<{service: string; error: Error}>(
  'PROMETHEUS_POD_FULLNESS_FAILURE',
);

export function requestPodFullnessQuery(
  token: string,
  projectId: string,
  service: string,
  start: number,
  end: number,
  step: number,
) {
  return (dispatch: Dispatch) => {
    dispatch(podFullnessQueryRequest(service));
    return new PrometheusApi(token)
      .queryPodFullness(projectId, service, start, end, step)
      .then((data) => {
        dispatch(podFullnessQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(podFullnessQueryFailure({service, error}));
        return error;
      });
  };
}

// METRICS REVAMP

export const readUnitsTotalQueryRequest = createAction<string>(
  'PROMETHEUS_READ_UNITS_TOTAL_REQUEST',
);
export const readUnitsTotalQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_READ_UNITS_TOTAL_SUCCESS');
export const readUnitsTotalQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_READ_UNITS_TOTAL_FAILURE',
);

export function readUnitTotalQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(readUnitsTotalQueryRequest(service));
    return new PrometheusApi(token)
      .queryReadUnitsTotal(projectId, service, start, end, step)
      .then((data) => {
        dispatch(readUnitsTotalQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          readUnitsTotalQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const writeUnitsTotalQueryRequest = createAction<string>(
  'PROMETHEUS_WRITE_UNITS_TOTAL_REQUEST',
);

export const writeUnitsTotalQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_WRITE_UNITS_TOTAL_SUCCESS');

export const writeUnitsTotalQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_WRITE_UNITS_TOTAL_FAILURE',
);

export function writeUnitTotalQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(writeUnitsTotalQueryRequest(service));
    return new PrometheusApi(token)
      .queryWriteUnitsTotal(projectId, service, start, end, step)
      .then((data) => {
        dispatch(writeUnitsTotalQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          writeUnitsTotalQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const recordTotalQueryRequest = createAction<string>('PROMETHEUS_RECORD_TOTAL_REQUEST');
export const recordTotalQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_RECORD_TOTAL_SUCCESS');
export const recordTotalQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_RECORD_TOTAL_FAILURE',
);

export function requestRecordTotalQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(recordTotalQueryRequest(service));
    return new PrometheusApi(token)
      .queryRecordTotal(projectId, service, start, end, step)
      .then((data) => {
        dispatch(recordTotalQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          recordTotalQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const storageSizeQueryRequest = createAction<string>(
  'PROMETHEUS_STORAGE_SIZE_TOTAL_REQUEST',
);
export const storageSizeQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_STORAGE_SIZE_TOTAL_SUCCESS');
export const storageSizeQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_STORAGE_SIZE_TOTAL_FAILURE',
);

export function storageSizeQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(storageSizeQueryRequest(service));
    return new PrometheusApi(token)
      .queryStorageSize(projectId, service, start, end, step)
      .then((data) => {
        dispatch(storageSizeQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          storageSizeQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const requestDurationQueryRequest = createAction<string>(
  'PROMETHEUS_REQUEST_DURATION_REQUEST',
);
export const requestDurationQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_REQUEST_DURATION_SUCCESS');
export const requestDurationQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_REQUEST_DURATION_FAILURE',
);

export function requestDurationQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
  endpoint?: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(requestDurationQueryRequest(service));

    return new PrometheusApi(token)
      .queryRequestDuration(projectId, service, start, end, step, endpoint)
      .then((data) => {
        dispatch(requestDurationQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          requestDurationQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const requestTotalQueryRequest = createAction<string>('PROMETHEUS_REQUEST_TOTAL_REQUEST');
export const requestTotalQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_REQUEST_TOTAL_SUCCESS');
export const requestTotalQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_REQUEST_TOTAL_FAILURE',
);

export function requestTotalQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(requestTotalQueryRequest(service));

    return new PrometheusApi(token)
      .queryRequestTotal(projectId, service, start, end, step)
      .then((data) => {
        dispatch(requestTotalQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          requestTotalQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const requestStatusQueryRequest = createAction<string>('PROMETHEUS_REQUEST_STATUS_REQUEST');
export const requestStatusQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_REQUEST_STATUS_SUCCESS');
export const requestStatusQueryFailure = createAction<{service: string; errorMessage: string}>(
  'PROMETHEUS_REQUEST_STATUS_FAILURE',
);

export function requestStatusQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
  endpoint?: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(requestStatusQueryRequest(service));

    return new PrometheusApi(token)
      .queryRequestStatus(projectId, service, start, end, step, endpoint)
      .then((data) => {
        dispatch(requestStatusQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          requestStatusQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}

export const podFullnessPercentageQueryRequest = createAction<string>(
  'PROMETHEUS_POD_FULLNESS_PERCENTAGE_REQUEST',
);
export const podFullnessPercentageQuerySuccess = createAction<{
  service: string;
  data: PrometheusTimestampedResponse;
}>('PROMETHEUS_POD_FULLNESS_PERCENTAGE_SUCCESS');
export const podFullnessPercentageQueryFailure = createAction<{
  service: string;
  errorMessage: string;
}>('PROMETHEUS_POD_FULLNESS_PERCENTAGE_FAILURE');

export function podFullnessPercentageQuery(
  token: string,
  projectId: string,
  service: string,
  start: string,
  end: string,
  step: string,
) {
  return (dispatch: Dispatch) => {
    dispatch(podFullnessPercentageQueryRequest(service));

    return new PrometheusApi(token)
      .queryPodFullnessPercentage(projectId, service, start, end, step)
      .then((data) => {
        dispatch(podFullnessPercentageQuerySuccess({service, data}));
      })
      .catch((error) => {
        dispatch(
          podFullnessPercentageQueryFailure({
            service,
            errorMessage: decodeErrorMessage(error.message, false),
          }),
        );
        return error;
      });
  };
}
