import {format} from 'date-fns/format';
import {useCallback, useMemo} from 'react';
import {type PrometheusLegacyResponse} from '../../../../../../types';

export const HEART_BEAT_POLLING_INTERVAL = 1000 * 10;

export const margin = {top: 60, bottom: 30, left: 0, right: 20};

export const useHeartbeatFormatDateTick = (formattedData: PrometheusLegacyResponse) => {
  return useCallback(
    (date: string) => {
      function formatTick(dateString: string) {
        const dateArray = (formattedData[0]?.data || []).map((point) => point.x);
        const len = dateArray.length;
        let ticks: string[] = [];
        if (len > 10) {
          ticks = [
            dateArray[Math.ceil(len / 5) - 1],
            dateArray[Math.ceil(len / 2) - 1],
            dateArray[Math.ceil(len - len / 5) - 1],
          ];
        }
        return ticks.includes(dateString) ? format(new Date(dateString), 'h:mm aa') : '';
      }
      return formatTick(date);
    },
    [formattedData],
  );
};

export const useHeartbeatDataYScales = (data: PrometheusLegacyResponse) => {
  return useMemo(() => {
    const yValues = (data[0]?.data || []).map((p) => p.y);
    const sorted = yValues.sort((a, b) => a - b);
    return {
      min: sorted[0] - sorted[0] * 0.1 > 0 ? sorted[0] - sorted[0] * 0.1 : 0,
      max: sorted[sorted.length - 1] * 1.1,
      latestCount: yValues[yValues.length - 1],
    };
  }, [data]);
};
