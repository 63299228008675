import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Table from '@pinecone-experience/timber/Table';
import {useEffect} from 'react';
import {cancelBulkImport, listBulkImports} from '../../../../actions/bulkImportActions';
import {type PaginatedResponseRequestParams} from '../../../../api/dataPlaneApi';
import StartBulkImportModal from '../../../../components/StartBulkImportModal/StartBulkImportModal';
import {PUBLIC_DOCS} from '../../../../constants';
import {useDataPlane} from '../../../../hooks/use-api';
import {useBooleanState} from '../../../../hooks/utils';
import {useBulkImports, useBulkImportsAreLoading} from '../../../../selectors/bulkImports';
import {type GlobalProject} from '../../../../types';
import IndexTabHeader from '../IndexTabHeader/IndexTabHeader';
import {ImportCell} from './ImportCell/ImportCell';

interface IndexImportsProps {
  indexName: string;
  project: GlobalProject;
  host: string;
}

export default function IndexImports({indexName, project, host}: IndexImportsProps) {
  const {setQueryData: queryBulkImports} = useDataPlane<PaginatedResponseRequestParams>(
    project,
    indexName,
    host,
  );
  const {setQueryData: queryCancelBulkImport} = useDataPlane<{id: string}>(
    project,
    indexName,
    host,
  );
  const loading = useBulkImportsAreLoading(project.id, indexName);
  const imports = useBulkImports(project.id, indexName) || [];

  useEffect(() => {
    if (project.id && indexName) {
      queryBulkImports({action: listBulkImports, data: {}});
    }
  }, [project.id, indexName, queryBulkImports]);

  const handleCancel = (id: string) => {
    queryCancelBulkImport({action: cancelBulkImport, data: {id}});
  };

  const cellRenderer = (row: number, col: number) => (
    <ImportCell
      indexName={indexName}
      bulkImport={imports[row]}
      column={col}
      onCancel={handleCancel}
    />
  );

  const {
    val: showImportForm,
    setTrue: setOpenImportForm,
    setFalse: setCloseImportForm,
  } = useBooleanState();

  return (
    <Box>
      <StartBulkImportModal
        selectedIndexName={indexName}
        open={showImportForm}
        close={setCloseImportForm}
      />
      <IndexTabHeader
        title="Imports"
        subtitle={
          (!!imports.length || loading) && (
            <>
              <Link href={PUBLIC_DOCS.IMPORTS} target="_blank">
                Learn more
              </Link>{' '}
              about importing data from object storage.
            </>
          )
        }
        actions={
          imports.length && (
            <Button
              id="header-import-open-form"
              variant="contained"
              onClick={setOpenImportForm}
              color="primary"
            >
              Import data
            </Button>
          )
        }
      />
      <Table
        isLoading={loading}
        columns={[
          {
            name: 'ID',
            dataType: 'string',
          },
          {
            name: 'Status',
            dataType: 'string',
          },
          {
            name: 'Start time',
            dataType: 'string',
          },
          {
            name: 'End time',
            dataType: 'string',
          },
          {
            name: 'Source',
            dataType: 'string',
          },
          {
            name: 'Records imported',
            dataType: 'number',
          },
          {
            name: 'Error',
            dataType: 'string',
          },
          {
            name: 'Actions',
            dataType: 'actions',
          },
        ]}
        numRows={imports.length}
        cellRenderer={cellRenderer}
        itemLabel={['import', 'imports']}
        pagination={{labelled: false, sizeControl: true}}
        emptyState={
          <Box width="100%" textAlign="center" pt={6}>
            <Typography variant="h5" component="h3">
              No imports found
            </Typography>
            <Typography py={2} color="text.primary">
              Import data into your index from object storage.{' '}
              <Link href={PUBLIC_DOCS.IMPORTS} target="_blank">
                Learn more
              </Link>
            </Typography>
            <Button
              id="empty-state-import-open-form"
              variant="contained"
              onClick={setOpenImportForm}
              color="primary"
            >
              Import data
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
