import {createReducer} from '@reduxjs/toolkit';
import {
  getFreeTierDowngradeEligibilityError,
  getFreeTierDowngradeEligibilityRequest,
  getFreeTierDowngradeEligibilitySuccess,
} from '../actions/freeTierDowngradeEligibilityActions';
import {type FreeTierDowngradeEligibility} from '../types';
import {type AsyncDataState, initAsyncDataState} from './utils';

const initialState: Record<string, AsyncDataState<FreeTierDowngradeEligibility | undefined>> = {};

const freeTierEligibilityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFreeTierDowngradeEligibilityRequest, (state, action) => {
      state[action.payload.organizationId] = initAsyncDataState<FreeTierDowngradeEligibility>(
        undefined,
        true,
      );
    })
    .addCase(getFreeTierDowngradeEligibilitySuccess, (state, action) => {
      state[action.payload.organizationId].loading = false;
      state[action.payload.organizationId].data = action.payload;
    })
    .addCase(getFreeTierDowngradeEligibilityError, (state, action) => {
      state[action.payload.organizationId].loading = false;
    });
});

export default freeTierEligibilityReducer;
