import {type PreloadedState, configureStore} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {createBrowserHistory} from 'history';
import {type TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {createReduxHistoryContext} from 'redux-first-history';
import createReducer from '../reducers/rootReducer';

const createCtx = () => {
  const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
    history: createBrowserHistory(),
  });
  return {createReduxHistory, routerMiddleware, routerReducer};
};

const historyMap = new WeakMap();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// TODO(michael): Break test store object to factory
// and fix this type to PreloadedState<RootState>.
export function setupStore(preloadedState?: any) {
  const {routerMiddleware, routerReducer, createReduxHistory} = createCtx();
  const middleware = [routerMiddleware];

  const store = configureStore({
    reducer: createReducer(routerReducer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    enhancers: [sentryReduxEnhancer],
    preloadedState,
  });

  const history = createReduxHistory(store);
  historyMap.set(store, history);
  return store;
}

export const getHistory = (store: AppStore) => historyMap.get(store);

export type AppStore = ReturnType<typeof setupStore>;
export type PreloadedStoreState = PreloadedState<RootState>;
export const store = setupStore();

export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
