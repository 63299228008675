import {type Integration} from '@pinecone-experience/integrations';
import {createContext, useContext} from 'react';

export const IntegrationContext = createContext<Integration | null>(null);

export const useSelectedIntegration = () => {
  const integration = useContext(IntegrationContext);
  if (!integration) {
    throw new Error('useSelectedIntegration must be used within an IntegrationGuard');
  }
  return integration;
};
