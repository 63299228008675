import {z} from 'zod';
import {freeEnvironmentPodName} from '../../../../constants';
import {isLowercaseAlphaNumericWithHyphens} from '../../../../utils/format';

export const METRIC_TYPES = ['cosine', 'euclidean', 'dotproduct'] as const;

export const indexNameCharacterLimit = 45;
const podTypes = [freeEnvironmentPodName.value, 's1', 'p1', 'p2'] as const;
const podSizes = ['x1', 'x2', 'x4', 'x8'] as const;
const engineTypes = ['approximated'] as const;

export const indexName = z
  .string()
  .max(indexNameCharacterLimit, {
    message: `Index name must be ${indexNameCharacterLimit} characters or less`,
  })
  .nonempty('Index name is required')
  .refine((name) => isLowercaseAlphaNumericWithHyphens(name), {
    message: 'Can only contain lowercase letters, numbers, and hyphens.',
  });
export const dimensions = z.number().positive().int().lte(20000);
export const metric = z.enum(METRIC_TYPES);
export const indexSchema = z.object({
  name: indexName,
  dimensions,
  engineType: z.enum(engineTypes),
  metric,
  pods: z.number().positive().int(),
  replicas: z.number().positive().int(),
  podType: z.enum(podTypes),
  podSize: z.enum(podSizes),
  deletionProtection: z.boolean(),
  from_collection: z.string().optional(),
  from_backup: z.string().optional(),
  provider: z.string(),
  environment: z.string(),
});

export type IndexSchema = z.infer<typeof indexSchema>;

export const ServerlessIndexSchema = z.object({
  name: indexName,
  dimensions,
  metric,
  provider: z.string(),
  region: z.string(),
  source_collection: z.string().optional(),
});
export type ServerlessIndexSchemaType = z.infer<typeof ServerlessIndexSchema>;
