import * as z from 'zod';
import {TAX_IDS} from '../../../../../utils/stripe/constants';

const TaxIdData = z.object({
  taxIdType: z.enum(TAX_IDS),
  taxIdValue: z.string().nonempty(),
});

// Unfortunately, `null` and `undefined` don't play well with react-hook-form
// So this is the workaround for empty values
export const TaxIdEmptyValue = z.literal('');

const TaxIdDataEmpty = z.object({
  taxIdType: TaxIdEmptyValue,
  taxIdValue: TaxIdEmptyValue,
});

// Tax ID should either both be provided or both omitted
const TaxIdDataSchema = TaxIdData.or(TaxIdDataEmpty);

const PaymentForm = z
  .object({
    name: z.string().nonempty('Required. Enter your name.'),
    email: z
      .string()
      .email({message: 'Invalid email address.'})
      .nonempty('Required. Enter your email.'),
  })
  .and(TaxIdDataSchema);

export type PaymentFormType = z.infer<typeof PaymentForm>;

export default PaymentForm;
