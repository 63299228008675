import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/serviceActions';
import {type Vector} from '../api/dataPlaneApi';
import {IndexState} from '../constants';
import {type MigrationOperationDetails, type V4IndexInfoResponse} from '../types';
import {isGlobalProjectAction} from './utils';

interface ProjectState {
  services: Record<string, V4IndexInfoResponse>;
  items: string[];
  loading: boolean;
  pendingDatasets: Record<string, Vector[] | undefined>;
  migrationOperations: Record<string, MigrationOperationDetails>;
}

const initialProjectState = {
  services: {},
  items: [],
  loading: true,
  pendingDatasets: {},
  migrationOperations: {},
} as ProjectState;

interface ServiceState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as ServiceState;

const SERVICE_REQUEST_ACTIONS = [
  actions.listServicesRequest.type,
  actions.createServiceRequest.type,
];

function createServiceState(
  existingService: V4IndexInfoResponse,
  updatedService: V4IndexInfoResponse,
): V4IndexInfoResponse | undefined {
  const status = existingService?.status || {};
  const isTerminating = status.state === IndexState.TERMINATING;
  const isUpgrading = status?.state === IndexState.INITIALIZING && status.ready;
  // If upgrading to serverless, and the response is still a pod index, ignore.
  // This is to prevent status from flipping back due to regular status polling before the
  // upgrade request has been fully registered
  if (isUpgrading && 'pod' in updatedService.spec) {
    return undefined;
  }
  return {
    ...updatedService,
    status: {
      ...updatedService.status,
      state: isTerminating ? IndexState.TERMINATING : updatedService.status.state,
    },
  };
}

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.patchServiceSuccess, (state, action) => {
      const {replicas, name, serviceData} = action.payload;
      const {spec: oldSpec} = state.services[name];
      state.services[name] = serviceData;
      if ('pod' in oldSpec) {
        const isScaleUp = Number(replicas) >= Number(oldSpec.pod.replicas);
        if (Number(replicas) !== Number(oldSpec.pod.replicas)) {
          state.services[name].status.state = isScaleUp
            ? IndexState.SCALING_UP
            : IndexState.SCALING_DOWN;
        }
      }
    })
    .addCase(actions.upgradeServiceSuccess, (state, action) => {
      const {status} = state.services[action.payload.service];
      status.state = IndexState.INITIALIZING;
    })
    .addCase(actions.migrateServiceSuccess, (state, action) => {
      state.migrationOperations[action.payload.target] = {
        migrationOperation: action.payload.operation,
      };
    })
    .addCase(actions.getMigrationOperationSuccess, (state, action) => {
      state.migrationOperations[action.payload.target] = {
        ...state.migrationOperations[action.payload.target],
        migrationOperation: action.payload.operation,
      };
    })
    .addCase(actions.getImportOperationSuccess, (state, action) => {
      state.migrationOperations[action.payload.target] = {
        ...state.migrationOperations[action.payload.target],
        importOperation: action.payload.operation,
      };
    })
    .addCase(actions.listMigrationOperationsSuccess, (state, action) => {
      action.payload.operations.forEach((operation) => {
        state.migrationOperations[operation.target_index_name] = {
          ...state.migrationOperations[operation.target_index_name],
          migrationOperation: operation,
        };
      });
    })
    .addCase(actions.getServiceSuccess, (state, action) => {
      const {serviceName} = action.payload;
      const serviceState = createServiceState(
        state.services[serviceName],
        action.payload.serviceData,
      );

      if (serviceState) {
        state.services[serviceName] = serviceState;
      }
    })
    .addCase(actions.serviceFinishedTerminating, (state, action) => {
      state.items = state.items.filter((service) => service !== action.payload.service);
      delete state.services[action.payload.service];
    })
    .addCase(actions.listServicesSuccess, (state, action) => {
      state.items = action.payload.serviceNames;

      state.services = action.payload.services.reduce((servicesObj, service) => {
        const serviceState = createServiceState(state.services[service.name], service);
        if (serviceState) {
          servicesObj[service.name] = serviceState;
        }

        return servicesObj;
      }, {} as Record<string, V4IndexInfoResponse>);

      state.loading = false;
    })
    .addCase(actions.listServicesFailure, (state) => {
      state.loading = false;
      state.items = [];
    })
    .addCase(actions.createServiceSuccess, (state, action) => {
      state.loading = false;
      state.items.push(action.payload.service);
      state.pendingDatasets[action.payload.service] = action.payload.dataset;
    })
    .addCase(actions.createServiceFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.deleteServiceSuccess, (state, action) => {
      state.services[action.payload.service].status.state = IndexState.TERMINATING;
    })
    .addCase(actions.clearPendingDataset, (state, action) => {
      delete state.pendingDatasets[action.payload.service];
    })
    .addMatcher(
      (action) => SERVICE_REQUEST_ACTIONS.includes(action.type),
      (state) => {
        state.loading = true;
      },
    );
});

const serviceReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default serviceReducer;
