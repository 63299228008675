import {Box, Button, Link, Typography} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {Link as RouterLink} from 'react-router-dom';
import {useBooleanState} from '../../../hooks/utils';
import CodingLanguageSelector from '../../CodeSnippets/CodingLanguageSelector';
import ConnectClient from '../../CodeSnippets/ConnectClient';
import InstallClient from '../../CodeSnippets/InstallClient';
import LanguageContentDocs from '../../CodeSnippets/LanguageContentDocs';
import QueryData from '../../CodeSnippets/QueryData';
import UpsertData from '../../CodeSnippets/UpsertData';
import GlowWrapper from '../../Utilities/GlowWrapper';

const styles = {
  connectButton: {
    color: 'primary.dark',
    borderColor: 'divider',
  },
  modalSize: {
    height: '75vh',
    maxHeight: 850,
    width: '80%',
    minWidth: 420,
    maxWidth: 736,
  },
  selectLanguage: {
    position: 'absolute',
    right: 32,
    pb: 2,
  },
  mainContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  content: {
    p: 1,
  },
};

function ConnectIndexGlobal() {
  const service = 'quickstart';
  const host = 'https://YOUR_INDEX_ENDPOINT';
  return (
    <>
      <Typography variant="h5" pb={2}>
        Connect to your Index
      </Typography>
      <Box role="region" sx={[styles.mainContent, styles.content]}>
        <Box sx={styles.selectLanguage}>
          <CodingLanguageSelector />
        </Box>
        <InstallClient
          title="Install Pinecone"
          subtitle={(name) =>
            `If you haven’t already, install the ${name} client on the command line:`
          }
        />
        <ConnectClient
          service={service}
          title="Initialize"
          subtitle={`Next, initialize the client and target the “${service}” index:`}
          withGetIndex
          useFallback
        />
        <UpsertData host={host} />
        <QueryData host={host} />
        <LanguageContentDocs
          subtext={
            <Typography>
              Now that you have experience with basic Pinecone operations, check out our{' '}
              <Link
                component={RouterLink}
                rel="noopener"
                target="_blank"
                to="https://docs.pinecone.io/examples/sample-apps"
              >
                sample applications
              </Link>{' '}
              using common AI patterns, tools, and algorithms and head over to our documentation for
              detailed guidance and best practices:
            </Typography>
          }
          global
          connect
        />
      </Box>
    </>
  );
}

function ConnectIndexButton({doGlow = false}: {doGlow?: boolean}) {
  const {val: showConnect, setTrue: openConnect, setFalse: closeConnect} = useBooleanState();
  return (
    <>
      <GlowWrapper doGlow={doGlow}>
        <Button
          id="index-connect"
          onClick={openConnect}
          sx={styles.connectButton}
          variant="outlined"
        >
          Connect
        </Button>
      </GlowWrapper>
      <BaseModal
        id="connect-modal"
        open={showConnect}
        onClose={closeConnect}
        showCloseIcon
        sx={styles.modalSize}
      >
        <ConnectIndexGlobal />
      </BaseModal>
    </>
  );
}

export default ConnectIndexButton;
