import {
  TIME_INTERVAL_STEP_MAP,
  type TimeInterval,
} from '@pinecone-experience/timber/charts/TimestampLineChart/constants/time';
import {type Dispatch} from '@reduxjs/toolkit';
import {parseISO} from 'date-fns/parseISO';
import {useEffect, useState} from 'react';
import AssistantDataApi from '../api/assistantApi';
import ControllerApi from '../api/controllerApi';
import DataPlaneApi from '../api/dataPlaneApi';
import {type RootState, useAppDispatch} from '../app/store';
import {AUTH0_AUDIENCE} from '../constants';
import {useFirstApiKeyByGlobalProjectId} from '../selectors/keys';
import {useServiceByGlobalProject} from '../selectors/services';
import {type GlobalProject} from '../types';
import {getSpecs} from '../utils/services';
import {useAuth} from './auth';
import {usePrevious} from './utils';

export const useControllerApi = <T>(
  project?: GlobalProject,
  initialAction?: (api: ControllerApi, data: T) => (dispatch: Dispatch) => Promise<any>,
  initialQueryData?: T,
) => {
  const dispatch = useAppDispatch();
  const {getAccessTokenSilently} = useAuth();
  const [queryData, setQueryData] = useState<{
    action?: (api: ControllerApi, data: T) => (dispatch: Dispatch) => Promise<any>;
    data?: T;
  }>({
    action: initialAction,
    data: initialQueryData as T,
  });
  const {action, data} = queryData;
  useEffect(() => {
    if (action && project?.id) {
      getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }).then((token) => {
        const controllerApi = new ControllerApi({
          projectId: project.id,
          token,
        });
        dispatch(action(controllerApi, data as T));
      });
    }
  }, [action, data, project?.id, dispatch, getAccessTokenSilently]);
  return {
    setQueryData,
  };
};

// TODO: or just combine all these....
export const useDashboardApi = <T>(
  initialAction?: (
    token: string,
    data: T,
  ) => (dispatch: Dispatch, getState: () => RootState) => Promise<any>,
  initialQueryData?: T,
  shouldFetch: boolean = true,
) => {
  const dispatch = useAppDispatch();
  const {getAccessTokenSilently} = useAuth();
  const [queryData, setQueryData] = useState<{
    action?: (
      token: string,
      data: T,
    ) => (dispatch: Dispatch, getState: () => RootState) => Promise<any>;
    data?: T;
  }>({
    action: initialAction,
    data: initialQueryData as T,
  });
  const {action, data} = queryData;
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
      scope: 'read:current_user',
    }).then((token) => {
      if (action) {
        dispatch(action(token, data as T));
      }
    });
  }, [action, data, dispatch, getAccessTokenSilently, shouldFetch]);
  return {
    setQueryData,
  };
};

export const useDataPlane = <T>(
  project: GlobalProject,
  service: string,
  host: string,
  initialAction?: (
    api: DataPlaneApi,
    globalProjectId: string,
    service: string,
    data: T,
  ) => (dispatch: Dispatch) => Promise<any>,
  initialQueryData?: T,
) => {
  const apiKey = useFirstApiKeyByGlobalProjectId(project.id);
  const dispatch = useAppDispatch();
  // TODO: error if no service status and/or !serviceStatus.ready
  const [queryData, setQueryData] = useState<{
    action?: (
      api: DataPlaneApi,
      globalProjectId: string,
      service: string,
      data: T,
    ) => (dispatch: Dispatch) => Promise<any>;
    data?: T;
  }>({action: initialAction, data: initialQueryData});
  const prevData = usePrevious(queryData);
  const prevKey = usePrevious(apiKey);
  const indexInfo = useServiceByGlobalProject(project.id, service);
  const {serverlessSpec} = getSpecs(indexInfo);
  const {getAccessTokenSilently} = useAuth();
  useEffect(() => {
    // TODO(damargulis): clean this once authentication occurs through proxy
    // Ensure we dont run this if a new service is selected while effect is active
    const firstKey = !prevKey && apiKey;
    if ((prevData !== queryData || firstKey) && apiKey && host) {
      getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        score: 'read:current_user',
      }).then((token) => {
        if (queryData.action) {
          const api = new DataPlaneApi(host, apiKey.value, token, !!serverlessSpec);
          dispatch(queryData.action(api, project.id, service, queryData.data as T));
        }
      });
    }
  }, [
    queryData,
    apiKey,
    dispatch,
    service,
    prevData,
    project,
    prevKey,
    host,
    getAccessTokenSilently,
    serverlessSpec,
  ]);
  return {
    setQueryData,
  };
};

const MIN_STEP_SIZE = 10;
const MAX_NUM_POINTS = 100;

export const usePrometheusRangeQuery = (
  query: (
    token: string,
    globalProjectId: string,
    service: string,
    startTime: number,
    endTime: number,
    step: number,
    isServerless: boolean,
  ) => (dispatch: Dispatch) => Promise<any>,
  globalProjectId: string,
  service: string,
  start: string,
  end: string,
  isServerless: boolean,
) => {
  const dispatch = useAppDispatch();
  const {getAccessTokenSilently} = useAuth();
  const serviceData = useServiceByGlobalProject(globalProjectId, service);

  useEffect(() => {
    // We wait until serviceData has loaded to ensure we include the environment if one exists,
    // but not all have the environment so use name to check if its loaded
    if (service && serviceData?.name) {
      getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }).then((token) => {
        const startTime = Number(parseISO(start)) / 1000;
        const endTime = Number(parseISO(end)) / 1000;
        const diff = endTime - startTime;
        const step = Math.max(MIN_STEP_SIZE, Math.floor(diff / MAX_NUM_POINTS));
        dispatch(query(token, globalProjectId, service, startTime, endTime, step, isServerless));
      });
    }
  }, [
    query,
    start,
    end,
    dispatch,
    service,
    globalProjectId,
    serviceData?.name,
    getAccessTokenSilently,
    isServerless,
  ]);
};

// METRICS REVAMP
export const usePrometheusQuery = (
  query: (
    token: string,
    globalProjectId: string,
    service: string,
    startTime: string,
    endTime: string,
    step: string,
    endpoint?: string,
  ) => (dispatch: Dispatch) => Promise<any>,
  globalProjectId: string,
  service: string,
  start: string,
  end: string,
  interval: TimeInterval,
  endpoint?: string,
) => {
  const dispatch = useAppDispatch();
  const {getAccessTokenSilently} = useAuth();
  const serviceData = useServiceByGlobalProject(globalProjectId, service);
  const step = TIME_INTERVAL_STEP_MAP[interval];

  useEffect(() => {
    // We wait until serviceData has loaded to ensure we include the environment if one exists,
    // but not all have the environment so use name to check if its loaded
    if (service && serviceData?.name) {
      getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }).then((token) => {
        dispatch(query(token, globalProjectId, service, start, end, step, endpoint));
      });
    }
  }, [
    query,
    start,
    end,
    step,
    dispatch,
    service,
    globalProjectId,
    serviceData?.name,
    getAccessTokenSilently,
    endpoint,
  ]);
};

export const useAssistantDataApi = <T>(
  projectId: string,
  initialAction?: (api: AssistantDataApi, data: T) => (dispatch: Dispatch) => Promise<any>,
  initialQueryData?: T,
) => {
  const dispatch = useAppDispatch();
  const [queryData, setQueryData] = useState<{
    action?: (api: AssistantDataApi, data: T) => (dispatch: Dispatch) => Promise<any>;
    data?: T;
  }>({
    action: initialAction,
    data: initialQueryData as T,
  });
  const {action, data} = queryData;
  const {getAccessTokenSilently} = useAuth();

  useEffect(() => {
    if (action && projectId) {
      getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }).then((token) => {
        const assistantDataApi = new AssistantDataApi(token, projectId);
        dispatch(action(assistantDataApi, data as T));
      });
    }
  }, [action, data, projectId, dispatch, getAccessTokenSilently]);
  return {
    setQueryData,
  };
};
