import {LocalStorageKeys, PodTypes, Providers} from '../constants';
import {type IndexStatsDescription, type V4IndexInfoResponse} from '../types';
import {storedHistory} from './stored-history';

function getViewedIndexKey(globalProjectId: string) {
  return `${LocalStorageKeys.VIEWED_INDEXES_HISTORY}-${globalProjectId}`;
}

export const viewedIndexHistory = {
  get: (globalProjectId: string) => {
    return storedHistory.get(getViewedIndexKey(globalProjectId));
  },
  append: (globalProjectId: string, index: string) => {
    return storedHistory.append(getViewedIndexKey(globalProjectId), index);
  },
  remove: (globalProjectId: string, index: string) => {
    return storedHistory.remove(getViewedIndexKey(globalProjectId), index);
  },
};

export const getSpecs = (indexInfo?: V4IndexInfoResponse) => {
  const spec = indexInfo?.spec;
  const podSpec = spec && 'pod' in spec ? spec.pod : null;
  const serverlessSpec = spec && 'serverless' in spec ? spec.serverless : null;
  return {podSpec, serverlessSpec};
};

const MAX_VECTOR_COUNT = 20_000_000;
const MAX_NAMESPACES = 20_000;

export const canMigrate = (
  indexInfo?: V4IndexInfoResponse,
  indexStatsDescription?: IndexStatsDescription,
) => {
  const {podSpec} = getSpecs(indexInfo);
  if (!podSpec) {
    return false;
  }
  if (podSpec.environment.includes('azure')) {
    return false;
  }
  if (indexStatsDescription) {
    if (indexStatsDescription.totalVectorCount > MAX_VECTOR_COUNT) {
      return false;
    }
    if (Object.keys(indexStatsDescription.namespaces).length > MAX_NAMESPACES) {
      return false;
    }
  }
  return podSpec.pod_type !== PodTypes.STARTER;
};

export const canBackup = (indexInfo?: V4IndexInfoResponse) => {
  const {serverlessSpec} = getSpecs(indexInfo);
  return !!serverlessSpec;
};

export const canImport = (indexInfo?: V4IndexInfoResponse) => {
  const {serverlessSpec} = getSpecs(indexInfo);

  if (!indexInfo?.status?.ready) {
    return false;
  }

  if (!serverlessSpec) {
    return false;
  }

  if (serverlessSpec.cloud !== Providers.AWS) {
    return false;
  }

  return true;
};
