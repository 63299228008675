import {type AnyAction} from '@reduxjs/toolkit';

export function isOrgAction(action: AnyAction) {
  return typeof action.payload?.organizationId === 'string';
}

export function isProjectAction(action: AnyAction) {
  return typeof action.payload?.projectId === 'string';
}

export function isGlobalProjectAction(action: AnyAction) {
  return typeof action.payload?.globalProjectId === 'string';
}

export function isAssistantAction(action: AnyAction) {
  return typeof action.payload?.assistantName === 'string';
}

export type AsyncDataState<T> = {
  loading: boolean;
  data: T | undefined;
  error: null | string | Error | undefined;
};

export const initAsyncDataState = <T>(
  initialData?: T,
  initialLoadingState = false,
): AsyncDataState<T> => ({
  loading: initialLoadingState,
  data: initialData,
  error: undefined,
});
