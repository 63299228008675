import {useMemo, useState} from 'react';

const DEFAULT_PER_PAGE = 25;

export type PaginationState<T> = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (num: number) => void;
  itemsToShow: T[];
  total: number;
  label: string;
};

const getPaginationLabel = (
  rowsPerPage: number,
  currentPage: number,
  total: number,
  itemLabel: [singular: string, plural: string],
) => {
  const [singular, plural] = itemLabel;
  if (total === 1) {
    return `1 ${singular}`;
  }
  if (total <= rowsPerPage) {
    return `${total} ${plural}`;
  }
  return `${Math.min(rowsPerPage * currentPage + 1, total)}-${Math.min(
    rowsPerPage * (currentPage + 1),
    total,
  )} of ${total} ${plural}`;
};

export function usePagination<T>(
  items: T[],
  itemLabel: [singular: string, plural: string],
  initPerPage = DEFAULT_PER_PAGE,
): PaginationState<T> {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);
  const minItem = rowsPerPage * currentPage;
  const itemsToShow = useMemo(() => {
    return items.slice(minItem, minItem + rowsPerPage);
  }, [minItem, rowsPerPage, items]);
  return {
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    itemsToShow,
    total: items.length,
    label: getPaginationLabel(rowsPerPage, currentPage, items.length, itemLabel),
  };
}
