import {listApiKeys} from '../../../../../actions/apiKeyActions';
import {getProjectInfo} from '../../../../../actions/dashboardActions';
import {listServices} from '../../../../../actions/serviceActions';
import {ProjectPage} from '../../../../../constants';
import {useNavigateToProjectPageForSelectedOrg} from '../../../../../hooks/navigation';
import {useControllerApi, useDashboardApi} from '../../../../../hooks/use-api';
import {useKeysAreLoadingByGlobalProjectId} from '../../../../../selectors/keys';
import {useProjectCellData} from '../../../../../selectors/projects';
import {type GlobalProject} from '../../../../../types';
import ProjectCell from '../ProjectCell/ProjectCell';

interface CellProps {
  project: GlobalProject;
}

export function IndexesCell({project}: CellProps) {
  const {loading, indexes} = useProjectCellData(project.id);
  const shouldFetch = indexes === undefined ? listServices : undefined;
  useControllerApi<{globalProjectId: string}>(project, shouldFetch, {
    globalProjectId: project.id,
  });
  const {getPath} = useNavigateToProjectPageForSelectedOrg();

  return (
    <ProjectCell link={getPath(project.id, ProjectPage.INDEXES)} loading={loading}>
      {indexes}
    </ProjectCell>
  );
}

export function ApiKeysCell({project}: CellProps) {
  const {loading, apiKeys} = useProjectCellData(project.id);
  const keysLoading = useKeysAreLoadingByGlobalProjectId(project.id);
  const shouldFetch = apiKeys === undefined && !keysLoading ? listApiKeys : undefined;
  useDashboardApi<{globalProjectId: string}>(shouldFetch, {globalProjectId: project.id});
  const {getPath} = useNavigateToProjectPageForSelectedOrg();

  return (
    <ProjectCell link={getPath(project.id, ProjectPage.KEYS)} loading={loading}>
      {apiKeys}
    </ProjectCell>
  );
}

export function UsersCell({project}: CellProps) {
  const {loading, members} = useProjectCellData(project.id);
  // If members === 1, still fetch as it may just be counting current user
  // and not entire list of users.
  const shouldFetch = members === undefined || members === 1 ? getProjectInfo : undefined;
  useDashboardApi<GlobalProject>(shouldFetch, project);
  const {getPath} = useNavigateToProjectPageForSelectedOrg();

  return (
    <ProjectCell link={getPath(project.id, ProjectPage.MEMBERS)} loading={loading}>
      {members}
    </ProjectCell>
  );
}
