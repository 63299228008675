import {Box, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import PaginationControls from '@pinecone-experience/timber/PaginationControls';
import {usePagination} from '@pinecone-experience/timber/PaginationControls/hooks';
import {useEffect} from 'react';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {
  useGlobalProjectServices,
  useServicesAreLoadingByGlobalProject,
} from '../../../../selectors/services';
import IndexCard from './IndexCard/IndexCard';

const styles = {
  list: {
    display: 'grid',
    gap: 2,
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    mt: 2,
  },
  perPage: {
    display: 'flex',
    alignItems: 'center',
    pr: 1,
  },
  pagination: {
    ul: {
      color: 'primary.dark',
      '.Mui-selected': {
        backgroundColor: 'primary.dark',
        color: 'background.paper',
      },
      justifyContent: 'end',
      svg: {
        color: 'text.secondary',
      },
    },
  },
};

const DEFAULT_PER_PAGE = 10;

const IndexList = ({services}: {services: string[]}) => {
  const selectedProject = useSelectedGlobalProject();
  const servicesLoading = useServicesAreLoadingByGlobalProject(selectedProject?.id);
  const serviceDataMap = useGlobalProjectServices(selectedProject?.id);

  const paginationState = usePagination(services, ['index', 'indexes'], DEFAULT_PER_PAGE);
  const {setCurrentPage, rowsPerPage, itemsToShow} = paginationState;

  useEffect(() => {
    setCurrentPage(0);
  }, [services.length, rowsPerPage, setCurrentPage]);

  return (
    <>
      <LoadingContainer loading={servicesLoading}>
        <Box sx={styles.list}>
          <Typography variant="body1">{`Showing ${paginationState.label}`}</Typography>
          {itemsToShow.map((service: string, i: number) => {
            const serviceData = serviceDataMap[service];
            return <IndexCard key={i} service={service} serviceData={serviceData} />;
          })}
        </Box>
      </LoadingContainer>
      {services.length > DEFAULT_PER_PAGE && (
        <Box mt={2}>
          <PaginationControls paginationState={paginationState} includeSizeControls />
        </Box>
      )}
    </>
  );
};

export default IndexList;
