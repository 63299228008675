import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {createCollection} from '../../../../actions/collectionActions';
import ModalForm from '../../../../components/ModalForm';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {usePodServicesByGlobalProject} from '../../../../selectors/services';

type CreateCollectionFormProps = {
  open: boolean;
  close: () => void;
};

function CreateCollectionForm({open, close}: CreateCollectionFormProps) {
  const selectedProject = useSelectedGlobalProject();
  const indexes = usePodServicesByGlobalProject(selectedProject?.id);
  const {setValue, handleSubmit, control, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      index: indexes[0] || '',
    },
  });
  const data = watch();
  const firstIndex = indexes[0];
  useEffect(() => {
    setValue('index', firstIndex);
  }, [setValue, firstIndex]);
  const {setQueryData} = useControllerApi<{
    name: string;
    index: string;
    globalProjectId: string;
  }>(selectedProject);
  const onSubmit = (formData: {name: string; index: string}) => {
    setQueryData({
      action: createCollection,
      data: {
        name: formData.name,
        index: formData.index,
        globalProjectId: selectedProject?.id,
      },
    });
    close();
  };
  return (
    <ModalForm
      open={open}
      close={close}
      title="Create Collection"
      isValid={!!data.index}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Create Collection"
      form={
        <>
          <TextInput name="name" control={control} required autoFocus label="Name" fullWidth />
          <SelectInput
            name="index"
            fullWidth
            control={control}
            options={indexes.map((name: string) => ({value: name}))}
            label="Index"
            noOptionsText="No Pod-Based Indexes"
          />
        </>
      }
    />
  );
}

export default CreateCollectionForm;
