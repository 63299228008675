import {Box, Paper, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import LinearProgress from '@pinecone-experience/timber/Progress/LinearProgress';
import {useState} from 'react';
import {IndexState} from '../../../../constants';
import {useAnimatedDots} from '../../../../hooks/utils';
import {
  useMigrationOperation,
  useServiceStatusByGlobalProject,
} from '../../../../selectors/services';
import {type MigrationOperationDetails} from '../../../../types';
import {getEffectivePercent} from '../../../../utils/migrations';
import IndexTabHeader from '../IndexTabHeader/IndexTabHeader';
import {InternetAccessDisabled} from '../InternetAccessDisabled/InternetAccessDisabled';
import EmptyIndexGuard from './EmptyIndexGuard/EmptyIndexGuard';
import {ExplorerPages} from './ExplorerPage/ExplorerPage';
import {useQueryExplorerSetup} from './QueryExplorer/utils';
import {type IndexExplorerState} from './utilities/utils';

const styles = {
  notReadyCard: {
    p: 4,
    height: 300,
    my: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'action.hover',
  },
  controlsContainer: {
    my: 2,
    p: 2,
  },
};

interface IndexExplorerProps {
  indexExplorerState: IndexExplorerState;
  internetDisabled?: boolean;
}

interface ProgressDisplayProps {
  ready: boolean;
  operations?: MigrationOperationDetails;
}

function ProgressDisplay({ready, operations}: ProgressDisplayProps) {
  const dots = useAnimatedDots();
  if (operations) {
    const progress = getEffectivePercent(operations.migrationOperation, operations.importOperation);
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{width: 150}}>
          <LinearProgress value={progress} />
        </Box>
        <Typography component="span" variant="h5" color="text.primary">
          {`Migration ${Math.round(progress)}% complete`}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography component="span" variant="h5" color="text.primary">
        {!ready ? 'Index Initializing' : 'Conversion in progress'}
      </Typography>
      <Typography
        component="span"
        variant="h5"
        color="text.primary"
        display="inline-block"
        width={30}
      >
        {dots}
      </Typography>
    </Box>
  );
}

function IndexExplorer({indexExplorerState, internetDisabled}: IndexExplorerProps) {
  const {project, service, dimensions, host} = indexExplorerState;
  const queryExplorerState = useQueryExplorerSetup({
    dimensions,
    project,
    service,
    host,
  });
  const ops = useMigrationOperation(project.id, service);
  const serviceStatus = useServiceStatusByGlobalProject(project.id, service);
  const [explorerTab, setExplorerTab] = useState(ExplorerPages.QUERY);
  const onSetExplorerTab = (_: React.MouseEvent, val?: ExplorerPages) => {
    if (val) {
      setExplorerTab(val);
    }
  };
  const onSubmitQueryExplorer = () => {
    setExplorerTab(ExplorerPages.QUERY);
    queryExplorerState.controllerState.handleSubmit(queryExplorerState.onClickQuery)();
  };

  if (internetDisabled) {
    return <InternetAccessDisabled />;
  }

  return (
    <Box>
      <IndexTabHeader
        title="Index records"
        actions={
          <ToggleButtonGroup exclusive value={explorerTab} onChange={onSetExplorerTab}>
            <ToggleButton sx={{py: 0}} value={ExplorerPages.QUERY}>
              Query
            </ToggleButton>
            <ToggleButton sx={{py: 0}} value={ExplorerPages.LIST}>
              List/Fetch
            </ToggleButton>
            <ToggleButton sx={{py: 0}} value={ExplorerPages.ADD}>
              Add a record
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
      {serviceStatus.state === IndexState.INITIALIZING ? (
        <Paper sx={styles.notReadyCard}>
          <ProgressDisplay ready={serviceStatus.ready} operations={ops} />
          <Typography mt={1}>Your index will be ready momentarily</Typography>
        </Paper>
      ) : (
        <EmptyIndexGuard
          indexExplorerState={indexExplorerState}
          page={explorerTab}
          queryExplorerState={queryExplorerState}
          onSubmitQueryExplorer={onSubmitQueryExplorer}
          setExplorerTab={setExplorerTab}
        />
      )}
    </Box>
  );
}

export default IndexExplorer;
