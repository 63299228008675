import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {closeModal, getDeletedApiKeys, openModal} from '../../actions/forcedMigrationActions';
import {LocalStorageKeys, PodTypes} from '../../constants';
import {useNavigateToBilling} from '../../hooks/navigation';
import {useFirstForcedMigrationServiceByGlobalProject} from '../../hooks/specUtils';
import {useDashboardApi} from '../../hooks/use-api';
import {useHasDeletedApiKeys, useMigratingService} from '../../selectors/forcedMigration';
import {useIsSelectedOrganizationSubscribed} from '../../selectors/organizations';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../selectors/params';
import {useHasServerlessIndexesInOrg} from '../../selectors/services';
import {getDaysSinceDeadline} from '../../utils/forcedMigration';
import {getSpecs} from '../../utils/services';
import {localStorageGet} from '../../utils/storage';
import ArchivedApiKeysModal from './ArchivedApiKeysModal';
import ForcedMigrationTrackerBox from './ForcedMigrationTrackerBox';
import ForcedMigrationWarning from './ForcedMigrationWarning';

function ForcedMigrationTracker() {
  const daysPast = getDaysSinceDeadline();
  const orgId = useSelectedOrganizationId();
  const globalProjectId = useSelectedGlobalProjectId();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const unmigratedService = useFirstForcedMigrationServiceByGlobalProject(
    orgId,
    globalProjectId || '',
  );
  const hasViewed = localStorageGet(LocalStorageKeys.VIEWED_FORCED_MIGRATION_FORM);
  const hasServerless = useHasServerlessIndexesInOrg();
  const dispatch = useDispatch();
  const doOpenModal = useCallback(() => {
    if (unmigratedService?.name) {
      dispatch(openModal({migratingIndex: unmigratedService?.name}));
    }
  }, [dispatch, unmigratedService?.name]);
  useEffect(() => {
    if (unmigratedService && !hasViewed) {
      doOpenModal();
    }
  }, [hasViewed, unmigratedService, doOpenModal]);
  const migratingService = useMigratingService();
  const closeFlow = () => {
    dispatch(closeModal());
  };
  useDashboardApi(getDeletedApiKeys, {orgId});
  const hasDeletedApiKeys = useHasDeletedApiKeys(orgId);
  const {go: goToBilling} = useNavigateToBilling(orgId);
  const onClickUpgrade = () => {
    goToBilling();
  };
  if (!unmigratedService) {
    return null;
  }
  const {podSpec} = getSpecs(unmigratedService);
  const showApiKeysModal = hasDeletedApiKeys && !isSubscribed && !hasServerless;
  return (
    <>
      <ForcedMigrationTrackerBox daysPast={daysPast} onClick={doOpenModal} />
      <ForcedMigrationWarning
        service={migratingService || ''}
        open={!!migratingService}
        close={closeFlow}
      />
      {showApiKeysModal && (
        <ArchivedApiKeysModal
          onClickMigrate={doOpenModal}
          onClickUpgrade={onClickUpgrade}
          isNano={podSpec?.pod_type === PodTypes.NANO}
        />
      )}
    </>
  );
}

export default ForcedMigrationTracker;
