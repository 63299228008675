import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/organizationActions';
import {type Invite} from '../types';

interface InviteState {
  items: Record<string, Invite[]>;
  loading: boolean;
}

const initialState = {
  items: {},
  loading: true,
} as InviteState;

const INVITE_REQUEST_ACTIONS = [
  actions.getPendingInvitesRequest.type,
  actions.deleteInviteRequest.type,
  actions.inviteToOrgRequest.type,
];

const inviteReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getPendingInvitesSuccess, (state, action) => {
      state.items[action.payload.organizationId] = action.payload.invites;
      state.loading = false;
    })
    .addCase(actions.deleteInviteSuccess, (state, action) => {
      state.items[action.payload.organizationId] = state.items[
        action.payload.organizationId
      ].filter((invite) => invite.id !== action.payload.inviteId);
      state.loading = false;
    })
    .addCase(actions.inviteToOrgSuccess, (state, action) => {
      state.items[action.payload.organizationId].push(...action.payload.invites);
      state.loading = false;
    })
    .addCase(actions.inviteToOrgFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.addUsersToOrgSuccess, (state) => {
      state.loading = false;
    })
    .addMatcher(
      (action) => INVITE_REQUEST_ACTIONS.includes(action.type),
      (state) => {
        state.loading = true;
      },
    );
});

export default inviteReducer;
