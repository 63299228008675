import {LANGUAGE_OPTIONS} from '../../../../../../components/CodeSnippets/constants';
import {moviesDataset} from '../../../../../../components/datasets/data/movies';
import type {VectorDatasetInformation} from '../../../../../../components/datasets/data/types';
import {
  ServerlessIndexSchema,
  dimensions,
  metric,
} from '../../../../../indexes/IndexCreationPage/utilities/schema';

export const movieVectorData = (moviesDataset.datasetInformation as VectorDatasetInformation)
  .vectorData;

export const INTERACTIVE_DEMO_LANGUAGE_OPTIONS = [
  LANGUAGE_OPTIONS.python,
  LANGUAGE_OPTIONS.javascript,
  LANGUAGE_OPTIONS.shell,
];

// Section 1
export const CREATE_VECTOR_EMBEDDINGS = {
  [LANGUAGE_OPTIONS.python]: `from pinecone.grpc import PineconeGRPC as Pinecone

pc = Pinecone(api_key="YOUR_API_KEY")

first_plot = "On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver."

results = pc.inference.embed(
  model="multilingual-e5-large",
  inputs=[first_plot],
  parameters={
    "input_type": "passage",
    "truncate": "END"
  }
)`,
  [LANGUAGE_OPTIONS.javascript]: `import { Pinecone } from '@pinecone-database/pinecone';

const client = new Pinecone({ apiKey: 'YOUR_API_KEY' });
const model = 'multilingual-e5-large';

const first_plot = [
  'On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver.',
];

async function generateDocEmbeddings() {
  try {
    return await client.inference.embed(
      model,
      {
        input: first_plot,
        params: { inputType: 'passage', truncate: 'END' }
      }
    );
  } catch (error) {
    console.error('Error generating embeddings:', error);
  }
}`,
  [LANGUAGE_OPTIONS.shell]: `PINECONE_API_KEY="YOUR_API_KEY"

curl -X POST "https://api.pinecone.io/embed" \\
  -H 'Api-Key: $PINECONE_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -H "X-Pinecone-API-Version: 2024-07" \\
  -d '{
    "model": "multilingual-e5-large",
    "parameters": {
      "input_type": "passage",
      "truncate": "END"
    },
    "inputs": [
      {
        "first_plot": "On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver."
      }
    ]
  }'`,
};

// Section 2

// Index Creation Schema
export const INDEX_NAME = 'movies-walkthrough';

export const schema = ServerlessIndexSchema.extend({
  dimensions: dimensions.refine((val) => moviesDataset.datasetInformation.dimension === val, {
    message: `The dimension must be ${moviesDataset.datasetInformation.dimension}`,
  }),
  metric: metric.refine((val) => moviesDataset.datasetInformation.metric === val, {
    message: `The metric must be ${moviesDataset.datasetInformation.metric}`,
  }),
});

export const UPSERT_MOVIE_VECTORS = {
  [LANGUAGE_OPTIONS.python]: `index.upsert(
  vectors=[
    {
      "id": "1",
      "values": [0.0234527587890625, 0.0291595458984375, ..., 0.005062103271484375],
      "metadata": {
        "title": "Avatar",
        "summary": "On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver.",
        "year": 2009,
        "box-office": 2923706026
      }
    },
    {
      "id": "2", 
      ...
    },
    ...
  ]
)`,
  [LANGUAGE_OPTIONS.javascript]: `await index.upsert([
  {
    "id": "1",
    "values": [0.0234527587890625, 0.0291595458984375, ..., 0.005062103271484375],
    "metadata": {
      "title": "Avatar",
      "summary": "On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver.",
      "year": 2009,
      "box-office": 2923706026
    }
  },
  {
    "id": "2", 
    ...
  },
  ...
]);`,
  [LANGUAGE_OPTIONS.shell]: `curl -X POST "https://$INDEX_HOST/vectors/upsert" \\
  -H "Api-Key: $PINECONE_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -H "X-Pinecone-API-Version: 2024-07" \\
  -d '{
    "vectors": [
      {
        "id": "1",
        "values": [0.0234527587890625, 0.0291595458984375, ..., 0.005062103271484375],
        "metadata": {
          "title": "Avatar",
          "summary": "On the alien world of Pandora, paraplegic Marine Jake Sully uses an avatar to walk again and becomes torn between his mission and protecting the planet's indigenous Na'vi people. The film stars Sam Worthington, Zoe Saldana, and Sigourney Weaver.",
          "year": 2009,
          "box-office": 2923706026
        }
      },
      {
        "id": "2", 
        ...
      },
      ...
    ]
  }'`,
};

// Section 3
export const QUERY_OPTIONS = [
  {value: 'Movies about space exploration'},
  {value: 'Kids movies with animals'},
  {value: 'Películas basadas en cómics'},
  {value: 'Write your own query'},
];

export const GENERATE_QUERY_EMBEDDING = {
  [LANGUAGE_OPTIONS.python]: (queryText: string) => `raw_data = "${queryText}"

results = pc.inference.embed(
  model="multilingual-e5-large",
  inputs=[raw_data],
  parameters={
    "input_type": "query",
    "truncate": "END"
  }
)`,

  [LANGUAGE_OPTIONS.javascript]: (
    queryText: string,
  ) => `import { Pinecone } from '@pinecone-database/pinecone';

const client = new Pinecone({ apiKey: 'YOUR_API_KEY' });
const model = 'multilingual-e5-large';

const raw_data = ['${queryText}'];

async function generateDocEmbeddings() {
  try {
    return await client.inference.embed(
      model,
      {
        input: raw_data,
        params: { inputType: 'query', truncate: 'END' }
      }
    );
  } catch (error) {
    console.error('Error generating embeddings:', error);
  }
}`,

  [LANGUAGE_OPTIONS.shell]: (queryText: string) => `PINECONE_API_KEY="YOUR_API_KEY"

curl -X POST "https://api.pinecone.io/embed" \\
  -H 'Api-Key: $PINECONE_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -H "X-Pinecone-API-Version: 2024-07" \\
  -d '{
    "model": "multilingual-e5-large",
    "parameters": {
      "input_type": "query",
      "truncate": "END"
    },
    "inputs": [
      { "raw_data": "${queryText}" }
    ]
  }'`,
};

export const QUERY_VECTOR_SNIPPETS = {
  [LANGUAGE_OPTIONS.python]: (queryEmbedding: string, topK: number) => `index.query(
  vector=${queryEmbedding},
  top_k=${topK},
  include_metadata=True
)`,
  [LANGUAGE_OPTIONS.javascript]: (
    queryEmbedding: string,
    topK: number,
  ) => `const queryResponse = await index.query({
  vector: ${queryEmbedding},
  topK: ${topK},
  includeMetadata: true
});`,
  [LANGUAGE_OPTIONS.shell]: (
    queryEmbedding: string,
    topK: number,
  ) => `curl -X POST "https://$INDEX_HOST/query" \\
  -H "Api-Key: $PINECONE_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -H "X-Pinecone-API-Version: 2024-07" \\
  -d '{
    "vector": ${queryEmbedding},
    "topK": ${topK},
    "includeMetadata": true
  }'`,
};
