import {format} from 'date-fns/format';
import LineChart, {
  type LineConfig,
  type ReferenceAreaConfig,
  type YAxisConfig,
} from '../LineChart/LineChart';
import {EXTENDED_DAY_FORMAT, HOUR_FORMAT, type TimeInterval} from './constants/time';
import {generateTicks} from './utils/calculateTimePeriod';
import {getTimestampFormat} from './utils/getTimestampFormat';

export interface TimestampedData {
  timestamp: string;
  [yKey: string]: string | number | null;
}

interface TimestampLineChartProps {
  title: string;
  data: TimestampedData[];
  lines: LineConfig[];
  interval: TimeInterval;
  unit?: string;
  referenceAreas?: ReferenceAreaConfig[];
  yAxis?: YAxisConfig;
  disableAnimation?: boolean;
}

export default function TimestampLineChart({
  title,
  lines,
  unit,
  data,
  interval,
  referenceAreas,
  yAxis,
  disableAnimation,
}: TimestampLineChartProps) {
  return (
    <LineChart
      title={title}
      data={data}
      lines={lines}
      unit={unit}
      disableAnimation={disableAnimation}
      referenceAreas={referenceAreas}
      yAxis={yAxis}
      xAxis={{
        dataKey: 'timestamp',
        formatter: (value) => format(new Date(value), getTimestampFormat(interval)),
        ticks: generateTicks(
          new Date(data[0].timestamp),
          new Date(data[data.length - 1].timestamp),
          interval,
        ),
      }}
      tooltipLabel={{
        subtitleFormatter: (value) => format(new Date(value), EXTENDED_DAY_FORMAT),
        titleFormatter: (value) => format(new Date(value), HOUR_FORMAT),
      }}
    />
  );
}
