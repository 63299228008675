import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/storageIntegrationActions';
import {type StorageIntegration} from '../types';
import {type AsyncDataState, initAsyncDataState, isGlobalProjectAction} from './utils';

interface StorageIntegrationsState {
  projects: {
    [projectId: string]: AsyncDataState<StorageIntegration[]>;
  };
}

const initialState: StorageIntegrationsState = {
  projects: {},
};

const initialProjectState = initAsyncDataState<StorageIntegration[]>();

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.listStorageIntegrationsRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.listStorageIntegrationsSuccess, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    })
    .addCase(actions.listStorageIntegrationsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.createStorageIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.createStorageIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.data = [...(state.data || []), action.payload.integration];
    })
    .addCase(actions.createStorageIntegrationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.updateStorageIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.updateStorageIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.data = (state.data || []).map((integration) =>
        integration.id === action.payload.integration.id ? action.payload.integration : integration,
      );
    })
    .addCase(actions.validateStorageIntegrationSuccess, (state, action) => {
      state.data = (state.data || []).map((integration) =>
        integration.id === action.payload.integration.id ? action.payload.integration : integration,
      );
    })
    .addCase(actions.updateStorageIntegrationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.deleteStorageIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.deleteStorageIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.data = (state.data || []).filter(
        (integration) => integration.id !== action.payload.integrationId,
      );
    })
    .addCase(actions.deleteStorageIntegrationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});

const storageIntegrationReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default storageIntegrationReducer;
