import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/bulkImportActions';
import {deleteServiceSuccess} from '../actions/serviceActions';
import {type ListBulkImportsResponse} from '../api/dataPlaneApi';
import {type BulkImport} from '../types';
import {type AsyncDataState, initAsyncDataState, isGlobalProjectAction} from './utils';

type BulkImportsEntry = {
  imports: Record<string, BulkImport>;
  pagination?: ListBulkImportsResponse['pagination'];
};

type IndexState = AsyncDataState<BulkImportsEntry>;

const initialIndexState = initAsyncDataState({imports: []}) as IndexState;

const indexReducer = createReducer(initialIndexState, (builder) => {
  builder
    .addCase(actions.startBulkImportRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.startBulkImportFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.cancelBulkImportRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.cancelBulkImportFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.listBulkImportsRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.getBulkImportSuccess, (state, action) => {
      const data: BulkImportsEntry = state.data || {imports: {}};
      data.imports[action.payload.data.id] = action.payload.data;

      state.data = data;
    })
    .addCase(actions.listBulkImportsSuccess, (state, action) => {
      const imports: Record<string, BulkImport> = {};
      action.payload.response.data.forEach((importData) => {
        imports[importData.id] = importData;
      });

      state.data = {
        imports,
        pagination: action.payload.response.pagination,
      };

      state.loading = false;
    })
    .addCase(actions.listBulkImportsFailure, (state) => {
      state.loading = false;
    });
});

interface ProjectState {
  indexes: Record<string, IndexState>;
}

const initialProjectState = {
  indexes: {},
} as ProjectState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(deleteServiceSuccess, (state, action) => {
      delete state.indexes[action.payload.service];
    })
    .addMatcher(
      (action) => typeof action.payload?.indexName === 'string',
      (state, action) => {
        state.indexes[action.payload.indexName] = indexReducer(
          state.indexes[action.payload.indexName],
          action,
        );
      },
    );
});

interface AppState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as AppState;

const bulkImportsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default bulkImportsReducer;
