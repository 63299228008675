import {Box, Button, Link, Typography} from '@mui/material';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {PUBLIC_DOCS_URL} from '../../constants';
import {getDaysSinceDeadline} from '../../utils/forcedMigration';
import RowWithCheck from '../Utilities/RowWithCheck';

type IntroFormProps = {
  onClick: () => void;
  close: () => void;
};

function IntroForm({onClick, close}: IntroFormProps) {
  const daysPast = getDaysSinceDeadline();
  return (
    <>
      <Box position="relative">
        <ModalHeader
          title="Take action: Migrate today to avoid service disruptions"
          close={close}
        />
        <Typography mb={2}>
          {`You are ${daysPast} days past the migration deadline. Your non-serverless indexes on the Starter tier will soon be archived as collections. To avoid service interruptions, migrate your pod-based indexes to serverless today. `}
          <Link
            target="_blank"
            rel="noreferrer"
            href={`${PUBLIC_DOCS_URL}/guides/indexes/migrate-a-legacy-starter-index-to-serverless`}
          >
            Learn more
          </Link>
          .
        </Typography>
        <Box>
          <RowWithCheck text="The Starter tier offers 2GB of storage, 2M WUs, and 1M RUs each month for free." />
          <RowWithCheck text="Serverless indexes scale automatically based on usage." />
          <RowWithCheck text="No pods to provision or manage as you scale." />
          <RowWithCheck
            text={
              <>
                <Typography fontWeight="fontWeightBold" component="span">
                  Avoid surprise service disruptions!
                </Typography>
                <Typography component="span">
                  {' '}
                  To keep your system running smoothly, please migrate now.
                </Typography>
              </>
            }
          />
        </Box>
      </Box>
      <Button onClick={onClick} fullWidth variant="contained" sx={{mb: 2, mt: 3}}>
        Get started
      </Button>
    </>
  );
}

export default IntroForm;
