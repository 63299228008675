import {useState} from 'react';
import {requestErrorCountQuery} from '../../../../../../actions/prometheusActions';
import ChartLayout from '../../../../../../components/chart/ChartLayout/ChartLayout';
import ChartLegend from '../../../../../../components/chart/ChartLegend/ChartLegend';
import LineChart from '../../../../../../components/chart/LineChart/LineChart';
import {requestTypes, useFilterDataByRequestType} from '../../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../../hooks/use-api';
import {useLegacyMetrics} from '../../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../../selectors/params';
import {useServiceByGlobalProject} from '../../../../../../selectors/services';
import {getSpecs} from '../../../../../../utils/services';
import FilterRequestTypes from '../../FilterRequestTypes/FilterRequestTypes';

function RequestsErrorsChart({service, start, end}: {service: string; start: string; end: string}) {
  const projectId = useSelectedGlobalProjectId();
  const serviceInfo = useServiceByGlobalProject(projectId || '', service);
  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    requestErrorCountQuery,
    projectId || '',
    service,
    start,
    end,
    !!serverlessSpec,
  );
  const metrics = useLegacyMetrics('errorCount', service);
  const [selectedRequestTypes, setSelectedRequestTypes] = useState(requestTypes);
  const formattedData = useFilterDataByRequestType({
    data: metrics?.data || [],
    selectedRequestTypes,
  });
  const colors = selectedRequestTypes.map((srt) => srt.color);
  return (
    <ChartLayout
      title="Request Errors"
      filters={
        <FilterRequestTypes
          selectedRequestTypes={selectedRequestTypes}
          setSelectedRequestTypes={setSelectedRequestTypes}
        />
      }
      tooltip="Errors per second by operation"
      error={metrics?.error}
      chart={<LineChart formattedData={formattedData} colors={colors} />}
      legend={<ChartLegend keys={selectedRequestTypes} />}
      loading={metrics?.loading}
    />
  );
}

export default RequestsErrorsChart;
