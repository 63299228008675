import type React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {showBlockingError} from '../../../../../actions/globalErrorActions';
import {useGlobalError} from '../../../../../selectors/globalError';
import GenericAuthError from './GenericAuthError/GenericAuthError';
import MarketplaceAlreadyLinkedError from './MarketplaceError/MarketplaceError';
import {checkMarketplaceError} from './MarketplaceError/utilities/MarketplaceError.utils';
import {clearErrorURLParams, parseError} from './utilities/AuthError.util';

type AuthErrorGuardProps = {
  children: JSX.Element;
  isAuthenticated?: boolean;
};

const AuthErrorGuard: React.FC<AuthErrorGuardProps> = ({
  children,
  isAuthenticated = false,
}: AuthErrorGuardProps): JSX.Element => {
  const dispatch = useDispatch();
  const {search, hash} = useLocation();
  const {hasError, error} = useGlobalError();
  const {title, message, isMarketplaceError} = error;

  useEffect(() => {
    // Auth0 returns error as a hash sometimes, so we need to parse it
    const {errorTitle, errorMessage} = parseError(search, hash);
    if (errorTitle) {
      dispatch(
        showBlockingError({
          title: errorTitle,
          message: errorMessage,
          isMarketplaceError: checkMarketplaceError(errorMessage),
        }),
      );
      clearErrorURLParams();
    }
  }, [search, hash, dispatch]);

  if (!hasError || !title || !message) {
    return children;
  }

  if (isMarketplaceError) {
    return (
      <MarketplaceAlreadyLinkedError
        errorType={title}
        errorMessage={message}
        isAuthenticated={isAuthenticated}
      />
    );
  }
  return (
    <GenericAuthError errorType={title} errorMessage={message} isAuthenticated={isAuthenticated} />
  );
};

export default AuthErrorGuard;
