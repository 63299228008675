import {fetchEventSource} from '@microsoft/fetch-event-source';
import * as Sentry from '@sentry/react';

import {DEFAULT_STREAM_OPTIONS, STREAM_ERROR_MESSAGES, StreamErrorType} from './constants';
import {
  type FetchCompletionEventSourceParams,
  type ReadEventParams,
  type StreamChatCompletionParams,
  type StreamError,
} from './types';

function handleStreamError({message, data, type}: StreamError, reject: (error: Error) => void) {
  if (data) {
    Sentry.addBreadcrumb({
      level: 'info',
      category: type,
      data,
    });
  }

  // If the error is coming from the response, do not report
  // exception and pass error to user.
  if (type === StreamErrorType.FetchResponseError) {
    reject(new Error(message));
  } else {
    Sentry.captureException(new Error(message));
    reject(new Error(STREAM_ERROR_MESSAGES[type]));
  }
}

function fetchCompletionEventSource({
  onMessage,
  onClose,
  onError,
  fetchOptions,
  retryOptions,
}: FetchCompletionEventSourceParams) {
  let attempts = 0;

  const validateResponse = async (res: Response) => {
    if (!res.ok) {
      const error = await res.clone().text();
      onError({message: error, type: StreamErrorType.FetchResponseError});
    }
  };

  const handleMessageError = (error: any) => {
    attempts += 1;

    if (attempts < retryOptions.retries) {
      return retryOptions.interval;
    }

    onError({
      message: error.message ?? error,
      type:
        error.name === 'TimeoutError' ? StreamErrorType.FetchTimeout : StreamErrorType.FetchFailed,
    });

    if (!retryOptions.keepOpenAfterRetries) {
      throw new Error();
    }

    return null;
  };

  fetchEventSource(fetchOptions.url, {
    openWhenHidden: true,
    method: 'POST',
    signal: AbortSignal.timeout(fetchOptions.timeout),
    body: fetchOptions.body,
    headers: {
      Authorization: `Bearer ${fetchOptions.token}`,
      'X-Project-Id': fetchOptions.projectId,
      'X-Disable-Bearer-Auth': 'true', // required for chat completion endpoint
    },
    onopen: validateResponse,
    onerror: handleMessageError,
    onmessage: onMessage,
    onclose: () => onClose('closed'),
  });
}

function readEvent({
  event,
  chatId,
  onError,
  onMessageContent,
  setChatId,
  onFinish,
}: ReadEventParams) {
  let message;

  try {
    message = JSON.parse(event.data);
  } catch (error) {
    onError({message: (error as Error).message, data: event, type: StreamErrorType.ReadFailed});
  }

  if (!chatId && message?.id) {
    setChatId(message.id);
  }

  const content = message?.choices[0]?.delta?.content;

  if (content) {
    onMessageContent(content);
  }

  if (message?.finish_reason) {
    onFinish(message.finish_reason);
  }
}

export default async function streamChatCompletion({
  projectId,
  token,
  url,
  messages,
  config,
  options = DEFAULT_STREAM_OPTIONS,
  onMessage,
  onChatIdReceived,
}: StreamChatCompletionParams) {
  let chatId: string | undefined;

  const fetchOptions = {
    projectId,
    token,
    url,
    body: JSON.stringify({
      stream: true,
      messages,
      model: config.model,
    }),
    timeout: options.fetchTimeout,
  };

  const retryOptions = {
    retries: options.retries,
    interval: options.retryInterval,
    keepOpenAfterRetries: options.keepOpenAfterRetries,
  };

  return new Promise<string>((resolve, reject) => {
    fetchCompletionEventSource({
      fetchOptions,
      retryOptions,
      onClose: resolve,
      onError: (error) => handleStreamError(error, reject),
      onMessage: (event) =>
        readEvent({
          chatId,
          event,
          onFinish: resolve,
          onError: (error) => handleStreamError(error, reject),
          onMessageContent: onMessage,
          setChatId: (id) => {
            chatId = id;
            onChatIdReceived(id);
          },
        }),
    });
  });
}
