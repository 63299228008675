import {Box, Link, Typography} from '@mui/material';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {Link as RouterLink} from 'react-router-dom';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

const styles = {
  subtext: {
    pb: 2,
  },
};

function UpsertData({host}: {host: string}) {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  const code = selectedLanguageContent.upsertText(host);
  return (
    <Box role="region" aria-labelledby="install">
      <Typography variant="h6" id="install">
        Upsert
      </Typography>
      <Typography sx={styles.subtext}>
        Then write vectors into a namespace in your index. You use{' '}
        <Link
          component={RouterLink}
          to="https://docs.pinecone.io/docs/namespaces"
          rel="noopener"
          target="_blank"
        >
          namespaces
        </Link>{' '}
        to help speed up queries and comply with multi-tenancy requirements.
      </Typography>
      <CodeBlock code={code} language={selectedLanguage} />
    </Box>
  );
}

export default UpsertData;
