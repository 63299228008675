import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useFormContext} from 'react-hook-form';
import {getOrganizations} from '../../../../actions/organizationActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useOrganizations, useOrganizationsAreLoading} from '../../../../selectors/organizations';

const OrganizationsSelect = () => {
  const {control} = useFormContext();
  useDashboardApi(getOrganizations);
  const organizationsAreLoading = useOrganizationsAreLoading();
  const organizations = useOrganizations();

  return (
    <SelectInput
      label="Pinecone Organization"
      name="organizationId"
      control={control}
      fullWidth
      disabled={organizationsAreLoading}
      options={Object.values(organizations).map((org) => ({
        label: org.name,
        value: org.id,
      }))}
    />
  );
};

export default OrganizationsSelect;
