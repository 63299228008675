import {createContext, useContext} from 'react';

type HostContextType = {
  origin: string;
};

export const HostContext = createContext<HostContextType | null>(null);

export const useHost = () => {
  const context = useContext(HostContext);
  if (!context) {
    throw new Error('useHost must be used within a HostGuard');
  }
  return context;
};
