import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type InferenceModels, type InferenceResponse} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const embedTextRequest = createAction<{
  globalProjectId: string;
  model: InferenceModels;
  query: string;
}>('EMBED_TEXT_REQUEST');
export const embedTextSuccess = createAction<{
  globalProjectId: string;
  query: string;
  data: InferenceResponse;
}>('EMBED_TEXT_SUCCESS');
export const embedTextFailure = createAction<{
  globalProjectId: string;
  query: string;
  error: string;
}>('EMBED_TEXT_FAILURE');

export function embed(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    model: InferenceModels;
    query: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(embedTextRequest(data));
    return api
      .embed(data.model, data.query)
      .then((response) => {
        dispatch(
          embedTextSuccess({
            globalProjectId: data.globalProjectId,
            query: data.query,
            data: response,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          embedTextFailure({
            globalProjectId: data.globalProjectId,
            query: data.query,
            error: decodeErrorMessage(error.message),
          }),
        );
        enqueNotification({
          type: NotificationTypes.ERROR,
          text: `Failed to embed text. ${error.message}`,
        });
      });
  };
}
