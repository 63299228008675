import {Box} from '@mui/material';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {useEffect} from 'react';
import {listApiKeys} from '../../../../actions/apiKeyActions';
import {useNavigateToGlobalProject} from '../../../../hooks/navigation';
import {useDashboardApi} from '../../../../hooks/use-api';
import {
  useFirstApiKeyByGlobalProjectId,
  useKeysAreLoadingByGlobalProjectId,
} from '../../../../selectors/keys';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {type GlobalProject} from '../../../../types';
import ProjectEncryptionBadge from '../../../ProjectEncryptionBadge/ProjectEncryptionBadge';
import ProjectActions from '../../ProjectActions/ProjectActions';
import ProjectCell from './ProjectCell/ProjectCell';
import {ApiKeysCell, IndexesCell, UsersCell} from './cells';
import {getFormattedDate} from './utils/date';

interface ProjectCellContainerProps {
  project: GlobalProject;
  col: number;
}

const styles = {
  projectName: {
    minWidth: '40ch',
  },
  projectId: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function ProjectCellContainer({project, col}: ProjectCellContainerProps) {
  const orgId = useSelectedOrganizationId();
  const {getPath} = useNavigateToGlobalProject();

  const apiKeyForFetch = useFirstApiKeyByGlobalProjectId(project.id);
  const {setQueryData} = useDashboardApi<{globalProjectId: string}>();
  const keysAreLoading = useKeysAreLoadingByGlobalProjectId(project.id);

  useEffect(() => {
    if (!apiKeyForFetch && !keysAreLoading) {
      setQueryData({action: listApiKeys, data: {globalProjectId: project.id}});
    }
  }, [project.id, setQueryData, apiKeyForFetch, keysAreLoading]);

  switch (col) {
    case 0:
      return (
        <ProjectCell
          sx={styles.projectName}
          link={getPath(orgId, project.id)}
          badge={
            <ProjectEncryptionBadge condensed cmekEncrypted={project.force_encryption_with_cmek} />
          }
        >
          {project.name}
        </ProjectCell>
      );

    case 1:
      return (
        <ProjectCell>
          <Box sx={styles.projectId}>
            <InlineCode text={project.id} noWrap />
            <CopyButton text={project.id} description="Project ID" sx={{my: 0}} />
          </Box>
        </ProjectCell>
      );

    case 2:
      return <IndexesCell project={project} />;

    case 3:
      return <ApiKeysCell project={project} />;

    case 4:
      return <UsersCell project={project} />;

    case 5:
      return <ProjectCell>{getFormattedDate(project.created_at || '')}</ProjectCell>;

    case 6:
      return <ProjectActions project={project} renderAsMenu />;

    default:
      return null;
  }
}
