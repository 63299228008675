import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type FreeTierDowngradeEligibility} from '../types';

export const getFreeTierDowngradeEligibilityRequest = createAction<{organizationId: string}>(
  'GET_FREE_TIER_ELIGIBILITY_REQUEST',
);
export const getFreeTierDowngradeEligibilityError = createAction<{organizationId: string}>(
  'GET_FREE_TIER_ELIGIBILITY_ERROR',
);
export const getFreeTierDowngradeEligibilitySuccess = createAction<
  FreeTierDowngradeEligibility & {organizationId: string}
>('GET_FREE_TIER_ELIGIBILITY_SUCCESS');

export function getFreeTierDowngradeEligibility(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getFreeTierDowngradeEligibilityRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .getFreeTierDowngradeEligibility(data.organizationId)
      .then((res) => {
        dispatch(
          getFreeTierDowngradeEligibilitySuccess({organizationId: data.organizationId, ...res}),
        );
      })
      .catch(() => {
        dispatch(getFreeTierDowngradeEligibilityError({organizationId: data.organizationId}));
      });
  };
}
