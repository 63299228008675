import {ReturnMechanism} from '@pinecone-experience/integrations';
import {MessageAction, sendMessage} from '@pinecone-experience/messages';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {connectProject, connectProjectReset} from '../../../../actions/integrationActions';
import {useAppDispatch} from '../../../../app/store';
import {useSelectedIntegration} from '../../../../context/selected-integration';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useOnce} from '../../../../hooks/utils';
import {useConnectIntegration} from '../../../../selectors/integrations';
import {useUserId} from '../../../../selectors/userSession';
import {assertUnreachable} from '../../../../utils/types';
import {type ConnectProjectSchema} from './ConnectProject/ConnectProject';
import {useHost} from './context';

// When running in Cypress integration tests, the grant page is hosted in an iframe, not a popup.
const parentWindow = window.Cypress ? window.parent : window.opener;

export const useHandleConnection = () => {
  const integration = useSelectedIntegration();
  const host = useHost();

  const {setQueryData} = useDashboardApi<{
    integrationId: string;
    organizationId: string;
    globalProjectId: string;
    newProjectName?: string;
    userId: string;
  }>();
  const userId = useUserId() || '';

  const onConfirm = useCallback(
    ({organizationId, globalProjectId, newProjectName}: ConnectProjectSchema) => {
      setQueryData({
        action: connectProject,
        data: {
          integrationId: integration.slug,
          organizationId,
          globalProjectId,
          newProjectName,
          userId,
        },
      });
    },
    [integration.slug, setQueryData, userId],
  );

  const onCancel = useCallback(() => {
    if (integration.return_mechanism === ReturnMechanism.WEB_MESSAGE) {
      sendMessage(MessageAction.CANCEL, parentWindow, host.origin);
    }
    window.close();
  }, [integration.return_mechanism, host.origin]);

  const dispatch = useAppDispatch();

  const onReset = useCallback(() => {
    dispatch(connectProjectReset());
  }, [dispatch]);

  return {onConfirm, onCancel, onReset};
};

export const useFinishConnection = () => {
  const connection = useConnectIntegration();
  const integration = useSelectedIntegration();
  const host = useHost();

  const navigate = useNavigate();
  useOnce(() => {
    if (connection.data) {
      switch (integration.return_mechanism) {
        case ReturnMechanism.WEB_MESSAGE:
          sendMessage(MessageAction.CONNECTION_INFO, parentWindow, host.origin, {
            organizationId: connection.data.organizationId,
            globalProjectId: connection.data.globalProjectId,
            key: connection.data.apiKey.value,
          });
          window.close();
          break;
        case ReturnMechanism.COPY_PASTE:
          navigate(`/connect/${integration.slug}/finish`);
          break;
        default:
          assertUnreachable(integration.return_mechanism);
      }
    }
  }, !!connection.data);
};
