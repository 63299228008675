import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import CreateBackupForm from '../../../../components/backups/CreateBackupForm/CreateBackupForm';
import EmptyState from '../../../../components/layout/EmptyState/EmptyState';
import {PUBLIC_DOCS} from '../../../../constants';
import {getOrgBillingPlansPath} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';

function BackupsEmptyState() {
  const isPaid = useIsSelectedOrganizationSubscribed();
  const orgId = useSelectedOrganizationId();
  const orgBillingPlansPath = getOrgBillingPlansPath(orgId);
  const {val: showForm, setTrue: setShowForm, setFalse: hideForm} = useBooleanState();

  if (isPaid) {
    return (
      <EmptyState heading="No backups found" description="Ready to create one? Let's get started.">
        <Button variant="contained" color="primary" onClick={setShowForm}>
          Create a backup
        </Button>
        {showForm && <CreateBackupForm open={showForm} close={hideForm} />}
      </EmptyState>
    );
  }
  return (
    <EmptyState
      heading="Upgrade to unlock serverless backups"
      description={
        <>
          Ensure your data is always secure, recoverable, and safeguarded against unexpected events.{' '}
          <Link component={RouterLink} to={orgBillingPlansPath}>
            Upgrade
          </Link>{' '}
          today or{' '}
          <Link target="_blank" rel="noreferrer" href={PUBLIC_DOCS.BACKUPS}>
            learn more
          </Link>
          .
        </>
      }
    />
  );
}

export default BackupsEmptyState;
