import MuiClearIcon from '@mui/icons-material/Clear';

function StyledClearIcon() {
  return (
    <MuiClearIcon
      sx={{backgroundColor: 'grey', color: 'white', borderRadius: '50%'}}
      fontSize="small"
    />
  );
}

export default StyledClearIcon;
