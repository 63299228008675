import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {type Control, type Path} from 'react-hook-form';
import {Roles} from '../constants';
import {capitalize} from '../utils/format';

interface RoleOptionsProps<T extends Record<string, any>> {
  control: Control<T>;
  name: Path<T>;
  label: string;
}

function RoleOptions<T extends Record<string, any>>({control, name, label}: RoleOptionsProps<T>) {
  const options = Object.values(Roles).map((role) => ({value: role, label: capitalize(role)}));
  return <SelectInput name={name} control={control} options={options} label={label} fullWidth />;
}

export default RoleOptions;
