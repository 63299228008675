import FileSaver from 'file-saver';
import OpenAPIRequestValidator, {type OpenAPIRequestValidatorArgs} from 'openapi-request-validator';
import {v4} from 'uuid';
import {type IndexStatsDescription} from '../types';
// TODO: find a way to get this dynamically -- relying on manual updates right now.
// From: https://github.com/pinecone-io/core/blob/master/api-docs/pinecone_api.json
import spec from './spec.json';

const MAX_SAMPLE_VECTORS = 10;

export function createSampleVectors(dimensions: number) {
  const numVectors = Math.min(dimensions, MAX_SAMPLE_VECTORS);
  return new Array(numVectors).fill(0).map((_, index) => {
    const vector = new Array(dimensions).fill(0);
    vector[index] = 1;
    return vector;
  });
}

export function readFiles(files: Blob[]): Promise<string[]> {
  const promises = Object.values(files).map(
    (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve((reader.result as string).trim());
        reader.readAsBinaryString(file);
      }),
  );
  return Promise.all(promises) as Promise<string[]>;
}

export function processData(data: string) {
  return JSON.parse(data).map((vector: {id: string; values: number[]}) => ({
    id: vector.id,
    values: vector.values,
  }));
}

// TODO: figure out why this modifys the data sometimes???
function validateFromSpec(pathSpec: object, data: object) {
  const fullSpec = {...pathSpec, schemas: spec.components.schemas};
  const requestValidator = new OpenAPIRequestValidator([fullSpec] as OpenAPIRequestValidatorArgs);
  // This modifies the data sometimes, stringify and parse to prevent.
  const errors = requestValidator.validateRequest({
    body: JSON.parse(JSON.stringify(data)),
    headers: {'api-key': 'fake', 'content-type': 'application/json'},
  });
  if (errors && errors.errors[0].location === 'body') {
    throw new Error(
      `${errors.errors[0].path ? errors.errors[0].path : ''} ${errors.errors[0].message}`,
    );
  }
  return true;
}

export function validateVectors(vectors: {values: number[]}[], dimensions: number) {
  if (!Array.isArray(vectors)) {
    throw Error('Bad JSON. Vectors should be in an array.');
  }
  vectors.forEach((vector, vecNum) => {
    if (!vector.values) {
      throw Error(`Bad JSON. Vector number ${vecNum + 1} has no values.`);
    }
    if (vector.values.length !== dimensions) {
      throw Error(
        `Bad Dimensions. vectors[${vecNum}].values has dimensionality of ${vector.values.length}, expected ${dimensions}.`,
      );
    }
  });
  return vectors;
}

export function validateQueryVector(vector: number[], dimensions: number) {
  // when query doesn't have the vector field
  if (vector == null) {
    return vector;
  }
  if (!Array.isArray(vector)) {
    throw Error('Bad JSON. Vector should be an array.');
  }
  if (vector.length !== dimensions) {
    throw Error(
      `Bad Dimensions. vector has dimensionality of ${vector.length}, expected ${dimensions}.`,
    );
  }
  return vector;
}

export function validateUpsert(data: string, dimensions: number) {
  const rawData = JSON.parse(data);
  validateFromSpec(spec.paths['/vectors/upsert'].post, rawData);
  // TODO: validate metada if possible
  validateVectors(rawData.vectors, dimensions);
  // validatefromspec might modify data so copy data
  return rawData;
}

export function validateUpdate(data: string, dimensions: number) {
  const rawData = JSON.parse(data);
  validateFromSpec(spec.paths['/vectors/update'].post, rawData);
  if (rawData.values) {
    validateVectors([rawData], dimensions);
  }
  return rawData;
}

export function validateQueries(data: string, dimensions: number) {
  const rawData = JSON.parse(data);
  // TODO: validator errors if not given includeMetadata and includeValues,
  // even tho not required -- figure out why
  const validatorData = {...rawData};
  validatorData.includeMetadata =
    typeof rawData.includeMetadata === 'undefined' ? false : rawData.includeMetadata;
  validatorData.includeValues =
    typeof rawData.includeValues === 'undefined' ? false : rawData.includeValues;
  validatorData.topK = typeof rawData.topK === 'undefined' ? 5 : rawData.topK;
  validateFromSpec(spec.paths['/query'].post, validatorData);
  validateQueryVector(rawData.vector, dimensions);
  return rawData;
}

export function saveJson(json: object, name: string) {
  const csvData = new Blob([JSON.stringify(json, null, 2)], {type: 'text/json;charset=utf-8;'});
  FileSaver.saveAs(csvData, `${name.toLowerCase().split(' ').join('_')}.json`);
}

// Returns [0,1] randomly in normal distribution
// https://stackoverflow.com/questions/25582882/javascript-math-random-normal-distribution-gaussian-bell-curve
export function randomBoxMuller(): number {
  let u = 0;
  let v = 0;
  while (u === 0) u = Math.random();
  while (v === 0) v = Math.random();
  const num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  if (num > 1 || num < 0) return randomBoxMuller();
  return num;
}

export function createRandomId(length = 7) {
  return v4().split('-').join('').substr(0, length);
}

export function sortNamespaces(indexStats?: IndexStatsDescription) {
  if (!indexStats?.namespaces) {
    return [];
  }
  return Object.keys(indexStats.namespaces).sort((name1, name2) => {
    const count1 = indexStats.namespaces[name1].vectorCount || 0;
    const count2 = indexStats.namespaces[name2].vectorCount || 0;
    if (count1 !== count2) {
      return count2 - count1;
    }
    return name1.localeCompare(name2);
  });
}

export function shuffleArray<T>(arr: T[]) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}
