import {type Dispatch, createAction} from '@reduxjs/toolkit';
import ZendeskApi, {
  ZENDESK_TICKET_CATEGORY_FIELD_ID,
  ZENDESK_TICKET_SEVERITY_FIELD_ID,
  type ZendeskTicketData,
} from '../api/zendeskApi';

export const submitSupportTicketRequest = createAction<Omit<ZendeskTicketData, 'uploads'>>(
  'SUBMIT_SUPPORT_TICKET_REQUEST',
);
export const submitSupportTicketSuccess = createAction<{success: boolean}>(
  'SUBMIT_SUPPORT_TICKET_SUCCESS',
);
export const submitSupportTicketFailure = createAction<string>('SUBMIT_SUPPORT_TICKET_FAILURE');

export function submitSupportTicket(data: {
  subject: string;
  email: string;
  additionalDetails: string;
  severity: string;
  category: string;
  files?: File[];
  chatSession?: string;
}) {
  return async (dispatch: Dispatch) => {
    try {
      const fileTokens: string[] = [];

      if (data.files && data.files.length > 0) {
        for (const file of data.files) {
          try {
            const token = await ZendeskApi.uploadFile(file);
            fileTokens.push(token);
          } catch (uploadError) {
            dispatch(
              submitSupportTicketFailure(`Failed to upload file: ${file.name}. Please try again.`),
            );
          }
        }
      }

      const ticketData: ZendeskTicketData = {
        ticket: {
          subject: data.subject,
          comment: {
            body: data.additionalDetails,
            uploads: fileTokens,
            public: true,
          },
          requester: {
            name: data.email.split('@')[0],
            email: data.email,
          },
          custom_fields: [
            {
              id: ZENDESK_TICKET_CATEGORY_FIELD_ID,
              value: data.category,
            },
            {
              id: ZENDESK_TICKET_SEVERITY_FIELD_ID,
              value: data.severity,
            },
          ],
          tags: ['console_support'],
        },
      };

      if (data.chatSession) {
        ticketData.ticket.comment.body += `\n\nChat Session:\n${data.chatSession}`;
      }

      try {
        dispatch(submitSupportTicketRequest(ticketData));
        await ZendeskApi.createTicket(ticketData);

        // We do not bother to check if the ticket was created successfully
        // because we'll assume success if we reach this point due to cors policy
        dispatch(submitSupportTicketSuccess({success: true}));
        return {success: true};
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        dispatch(submitSupportTicketFailure(errorMessage));
        return {success: false};
      }
    } catch (error) {
      dispatch(submitSupportTicketFailure('An unknown error occurred'));
      return {success: false};
    }
  };
}
