import {Box, Link, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import {useState} from 'react';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import {PUBLIC_DOCS_URL} from '../../../constants';
import {
  useAllCollectionStatusesByGlobalProjectId,
  useCollectionsAreLoadingByGlobalProjectId,
  useCollectionsByGlobalProjectId,
} from '../../../selectors/collections';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {GUTTER} from '../../../styles/theme';
import {formatByteSize} from '../../../utils/format';
import CollectionActions from './CollectionActions/CollectionActions';
import CollectionStatusBox from './CollectionStatusBox/CollectionStatusBox';
import CreateCollectionButton from './CreateCollectionButton/CreateCollectionButton';
import CreateCollectionForm from './CreateCollectionForm/CreateCollectionForm';

function CollectionsList() {
  const project = useSelectedGlobalProject();
  const collections = useCollectionsByGlobalProjectId(project?.id) || [];
  const allCollectionInfo = useAllCollectionStatusesByGlobalProjectId(project?.id);
  const cellRenderer = (row: number, col: number) => {
    const collection = collections[row];
    const info = allCollectionInfo[collection] || {};
    if (col === 0) {
      return (
        <Box display="flex">
          <Typography fontWeight="bold">{collection}</Typography>
          <CollectionStatusBox collection={collection} />
        </Box>
      );
    }
    if (col === 1) {
      return info?.environment;
    }
    if (col === 2) {
      return info?.vector_count;
    }
    if (col === 3) {
      return info.dimension;
    }
    if (col === 4) {
      if (info.size || info.size === 0) {
        return formatByteSize(info.size);
      }
      return '';
    }
    if (col === 5) {
      return (
        <CollectionActions
          collection={collection}
          dimensions={String(info.dimension)}
          environment={info.environment}
          status={info.status}
        />
      );
    }
    return null;
  };
  const [showForm, setShowForm] = useState(false);
  const collectionsAreLoading = useCollectionsAreLoadingByGlobalProjectId(project?.id);
  if (!collectionsAreLoading && collections.length === 0) {
    return (
      <EmptyState
        heading="You don't have any collections"
        description={
          <Typography textAlign="center">
            A collection is a snapshot of your index. Learn more{' '}
            <Link
              href={`${PUBLIC_DOCS_URL}/guides/indexes/understanding-collections`}
              target="_blank"
              rel="noreferrer"
            >
              here
            </Link>
            .
          </Typography>
        }
      >
        <CreateCollectionButton text="Create a collection" />
        <CreateCollectionForm open={showForm} close={() => setShowForm(false)} />
      </EmptyState>
    );
  }
  return (
    <Table
      isLoading={collectionsAreLoading}
      pagination={{labelled: true, sizeControl: true}}
      columns={[
        {name: 'Name', dataType: 'string'},
        {name: 'Environment', dataType: 'string'},
        {
          name: 'Record Count',
          dataType: 'number',
          info: 'Record Count only available on Collections created after 2/1/23',
        },
        {name: 'Dimensions', dataType: 'number'},
        {name: 'Size', dataType: 'number'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={collections.length}
      cellRenderer={cellRenderer}
      gutter={GUTTER}
    />
  );
}

export default CollectionsList;
