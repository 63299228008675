import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SelectorMenu from '@pinecone-experience/timber/Menus/SelectorMenu';
import {useRef} from 'react';
import {useNavigateToAssistant} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {useAssistantsList} from '../../../../selectors/assistants';
import {useSelectedOrganizationId} from '../../../../selectors/params';

interface AssistantSelectorProps {
  id: string;
  projectId: string;
  selectedItem?: string;
}

const styles = {
  menu: {
    minWidth: 250,
    maxWidth: 500,
    maxHeight: 500,
    mt: 1,
  },
  button: {
    color: 'text.primary',
    borderColor: 'divider',
    maxWidth: '32ch',
  },
};

function AssistantSelector({id, projectId, selectedItem}: AssistantSelectorProps) {
  const orgId = useSelectedOrganizationId();
  const {go} = useNavigateToAssistant(orgId, projectId);
  const {
    val: showSelector,
    setTrue: setShowSelector,
    setFalse: setHideSelector,
  } = useBooleanState();
  const menuAnchor = useRef(null);

  const handleItemClick = (name: string) => {
    setHideSelector();
    go(name);
  };

  const assistantItems = useAssistantsList(projectId).map(({name}) => ({
    id: name,
    name,
    onClick: () => handleItemClick(name),
  }));

  return (
    <>
      <Button
        id={`${id}-selector`}
        ref={menuAnchor}
        onClick={setShowSelector}
        endIcon={showSelector ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
        variant="outlined"
        sx={styles.button}
      >
        <Typography noWrap>{selectedItem}</Typography>
      </Button>
      <SelectorMenu
        id={`${id}-selector-menu`}
        open={showSelector}
        anchor={menuAnchor.current}
        onClose={setHideSelector}
        sx={styles.menu}
        placement="bottom-start"
        title="Assistants"
        items={assistantItems}
        selectedItemId={selectedItem}
      />
    </>
  );
}

export default AssistantSelector;
