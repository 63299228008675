import {Box, Typography} from '@mui/material';
import SuccessCard from '@pinecone-experience/timber/Cards/SuccessCard';
import {useEffect} from 'react';
import {upgradeService} from '../../../../../actions/serviceActions';
import {useControllerApi} from '../../../../../hooks/use-api';
import {usePrevious} from '../../../../../hooks/utils';
import {useIsUpgradeLoading} from '../../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../../selectors/params';
import {useGlobalProject} from '../../../../../selectors/projects';
import {useStarterServices} from '../../../../../selectors/services';
import MigrationDetails from '../../../../indexes/MigrationDetails/MigrationDetails';

const styles = {
  container: {
    width: 400,
    p: 1,
  },
};

const DEFAULT_SERVERLESS_REGION = 'us-west-2';

function UpgradeSuccessCard({
  getStarted,
  show,
  onClose,
}: {
  onClose: () => void;
  getStarted: () => void;
  show?: boolean;
}) {
  const starterServices = useStarterServices();
  const serviceInfo = starterServices[0];
  const orgId = useSelectedOrganizationId();
  const project = useGlobalProject(serviceInfo?.globalProjectId);
  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    name: string;
    organizationId: string;
    region: string;
  }>(project);
  const doMigrate = () => {
    if (!serviceInfo?.service) {
      return;
    }
    setQueryData({
      action: upgradeService,
      data: {
        organizationId: orgId,
        globalProjectId: project.id,
        name: serviceInfo.service,
        region: DEFAULT_SERVERLESS_REGION,
      },
    });
  };
  const upgradeLoading = useIsUpgradeLoading();
  const prevLoading = usePrevious(upgradeLoading);
  useEffect(() => {
    if (prevLoading && !upgradeLoading) {
      onClose();
    }
  }, [prevLoading, upgradeLoading, onClose]);
  if (serviceInfo) {
    return (
      <Box sx={{textAlign: 'center', display: show ? 'block' : 'none'}}>
        <Box sx={styles.container} position="relative">
          <Box>
            <Typography variant="h5" mb={2}>
              Upgrade your {serviceInfo.service} index to serverless
            </Typography>
          </Box>
          <MigrationDetails onSubmit={doMigrate} variant="alt" />
        </Box>
      </Box>
    );
  }
  const ctaText = 'Start building';
  const text = 'You now have access to Pinecone serverless!';
  return (
    <SuccessCard
      title="Upgrade Success"
      text={text}
      cta={getStarted}
      ctaText={ctaText}
      show={show}
    />
  );
}

export default UpgradeSuccessCard;
