import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import {useNavigateToBilling} from '../../../hooks/navigation';
import {useBooleanState} from '../../../hooks/utils';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {
  useProjectQuotaLimits,
  useProjectQuotaLimitsIsLoading,
} from '../../../selectors/quotaLimits';
import {
  useProjectQuotaUtilization,
  useProjectQuotaUtilizationIsLoading,
} from '../../../selectors/quotaUtilization';
import StarterBenefitsModal from '../../StarterBenefitsModal/StarterBenefitsModal';
import QuotaUtilizationTracker from '../QuotaUtilizationTracker/QuotaUtilizationTracker';
import {getBlockedText} from './blockedText';

const styles = {
  upgradeButton: {
    color: 'text.primary',
  },
};

const StarterUsageQuotaUtilizationTracker = ({projectId}: {projectId: string}) => {
  const selectedOrganizationId = useSelectedOrganizationId();
  const quotaLimits = useProjectQuotaLimits(projectId);
  const quotaUtilization = useProjectQuotaUtilization(projectId);
  const quotaLimitsIsLoading = useProjectQuotaLimitsIsLoading(projectId);
  const quotaUtilizationIsLoading = useProjectQuotaUtilizationIsLoading(projectId);
  const {go: navigateToBilling} = useNavigateToBilling(selectedOrganizationId);

  const {
    val: showStarterBenefitsModal,
    setTrue: setShowStarterBenefitsModal,
    setFalse: setHideStarterBenefitsModal,
  } = useBooleanState();

  const quotas = Object.values(quotaLimits).map((limit) => {
    return {
      key: limit.key,
      limit: Number(limit.value),
      utilization: quotaUtilization[limit.key] || 0,
    };
  });

  return (
    <QuotaUtilizationTracker
      title="Starter Usage"
      quotas={quotas || []}
      isLoading={quotaLimitsIsLoading || quotaUtilizationIsLoading}
      contentHeight={125}
      getBlockedText={getBlockedText}
      titleAction={
        <>
          <IconButton
            id="starter-benefits-info"
            sx={{m: 0, mr: -1}}
            onClick={setShowStarterBenefitsModal}
            color="secondary"
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
          <StarterBenefitsModal
            open={showStarterBenefitsModal}
            onClose={setHideStarterBenefitsModal}
          />
        </>
      }
      action={
        <Button
          onClick={navigateToBilling}
          variant="outlined"
          color="secondary"
          size="small"
          sx={styles.upgradeButton}
          fullWidth
        >
          Upgrade now
        </Button>
      }
    />
  );
};

export default StarterUsageQuotaUtilizationTracker;
