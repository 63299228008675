// TODO(michael): Find a way to export/combine code from
// dashboard/blob/main/api-server/functions

import {CloudProviders, Plans, PodTypes, ProductsToPriceMap} from './pricingConstants';

interface CalculatePriceParams {
  organization: any;
  pods: number;
  productType: PodTypes;
  podSize: number;
  replicas: number;
  environment: string;
}

const hoursPerMonth: number = 730;

function calculatePrice({
  organization,
  pods,
  productType,
  podSize,
  replicas,
  environment,
}: CalculatePriceParams) {
  const plan = organization.plan as Plans;

  // c1 and s1h are a special pod type used experimentally by only a select few.
  // We do not have set prices for c1
  if (
    [Plans.FREE, Plans.INTERNAL].includes(plan) ||
    productType === PodTypes.S1H ||
    productType === PodTypes.C1 ||
    productType === PodTypes.nano ||
    productType === PodTypes.starter
  ) {
    return 0;
  }

  const podUsage = pods * replicas * podSize;
  const provider = environment.includes('aws') ? CloudProviders.AWS : CloudProviders.GCP;

  const basePrice = ProductsToPriceMap?.[provider]?.[productType]?.[plan];
  const overrides = organization.contractDetails?.overrides?.[provider]?.[productType];
  const price = overrides?.priceCentsPerMinute || basePrice;
  const contractDiscount = organization.contractDetails?.contractDiscountPercentage || 0;
  const discount =
    !!overrides?.discountPercentage || overrides?.discountPercentage === 0
      ? overrides.discountPercentage
      : contractDiscount;
  // Price cents per minute => monthly
  return (price - (price * discount) / 100) * podUsage * 60 * hoursPerMonth;
}

export default calculatePrice;
