import {useAppSelector as useSelector} from '../app/store';
import {type PrivateEndpoint} from '../types';

export const useCreatePrivateEndpointResponse = () => {
  return useSelector((state) => state.privateEndpoints.createEndpointResponse);
};

export const usePrivateEndpointsItem = (globalProjectId: string) => {
  return useSelector((state) => state.privateEndpoints.items[globalProjectId]);
};

export const usePrivateEndpoint = (
  globalProjectId: string,
  cloud: string,
  region: string,
): PrivateEndpoint | undefined => {
  return useSelector(
    (state) => state.privateEndpoints.items[globalProjectId],
  )?.data?.endpoints.find((endpoint) => endpoint.region === region && endpoint.cloud === cloud);
};

export const usePrivateEndpointLink = (
  globalProjectId: string,
  cloud: string,
  region: string,
  host: string,
): string | undefined => {
  const privateEndpointExists = !!usePrivateEndpoint(globalProjectId, cloud, region);

  if (!privateEndpointExists || !host) {
    return undefined;
  }

  return host.replace('.svc', '.svc.private');
};
