import {createReducer} from '@reduxjs/toolkit';
import * as projectActions from '../actions/dashboardActions';
import * as organizationActions from '../actions/organizationActions';
import {type User} from '../types';

interface UserState {
  items: Record<string, User>;
  loading: boolean;
}

const initialState = {
  items: {},
  loading: false,
} as UserState;

const USERS_REQUEST_ACTIONS = [
  organizationActions.getOrganizationUsersRequest.type,
  organizationActions.removeUserFromOrgRequest.type,
];

const USERS_CHANGE_ACTIONS = [
  organizationActions.removeUserFromOrgSuccess.type,
  projectActions.getProjectInfoSuccess.type,
  organizationActions.getOrganizationUsersSuccess.type,
  organizationActions.addUsersToOrgSuccess.type,
];

const usersReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(
      (action) => USERS_REQUEST_ACTIONS.includes(action.type),
      (state) => {
        state.loading = true;
      },
    )
    .addMatcher(
      (action) => USERS_CHANGE_ACTIONS.includes(action.type),
      (state, action) => {
        action.payload.users?.forEach((user: User) => {
          state.items[user.firebase_id] = user;
        });
        state.loading = false;
      },
    );
});

export default usersReducer;
