import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import {Box, IconButton, Input, Paper, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useIntegrationLogo} from '../../../../../selectors/integrations';
import {type IntegrationFormData} from '../utilities/schema';
import styles from '../utilities/styles';

type LogoFieldProps = {
  /** The URL slug of the integration, if pre-existing. */
  slug?: string;
};

/** Field to allow the user to upload a logo image. */
function LogoField({slug}: LogoFieldProps) {
  const logoUrl = useIntegrationLogo(slug ?? '');
  const defaultImage = slug ? logoUrl : null;
  const [image, setImage] = useState<string | null>(defaultImage);
  const {
    setValue,
    formState: {errors},
  } = useFormContext<IntegrationFormData>();
  const logoFile = useWatch<IntegrationFormData>({name: 'logo'}) as File | undefined;

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) setValue('logo', file);
  };

  useEffect(() => {
    if (logoFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(logoFile);
    } else {
      setImage(defaultImage);
    }
  }, [defaultImage, logoFile]);

  return (
    <Paper sx={{...styles.section, display: 'flex', justifyContent: 'space-between'}}>
      <Box>
        <Typography color="text.primary" fontWeight="500" mr={1} mb={1}>
          Logo
        </Typography>
        <Typography>Upload a logo for your integration.</Typography>
        {errors.logo && (
          <Typography variant="body2" color="error" sx={styles.error}>
            {errors.logo.message}
          </Typography>
        )}
      </Box>
      <IconButton
        component="label"
        sx={{
          borderRadius: 2,
          border: image ? 0 : 1,
          p: 0,
          width: 85,
          height: 85,
          overflow: 'hidden',
        }}
      >
        {image ? (
          <Box component="img" src={image} alt="Logo" sx={{m: 0, width: 85, height: 85}} />
        ) : (
          <PhotoCameraOutlinedIcon fontSize="large" sx={{m: 3}} />
        )}
        <Input
          type="file"
          sx={{display: 'none'}}
          onChange={handleUpload}
          placeholder="Upload a logo file"
        />
      </IconButton>
    </Paper>
  );
}

export default LogoField;
