import {Button, Paper, Typography} from '@mui/material';
import PlanInfo from '../../../../../components/billing/plans/PlanInfo/PlanInfo';
import {BillingPlansDetails} from '../../../../../components/billing/plans/constants';
import {PlanNameMapping, type Plans} from '../../../../../constants';
import {useNavigateToBillingPlans} from '../../../../../hooks/navigation';
import {useSelectedOrganization} from '../../../../../selectors/organizations';

interface CurrentPlanCardProps {
  showPlanInfo?: boolean;
}

const CurrentPlanCard = ({showPlanInfo = true}: CurrentPlanCardProps) => {
  const organization = useSelectedOrganization();
  const mappedPlan = PlanNameMapping[organization.plan as Plans];
  const goToBillingPlans = useNavigateToBillingPlans(organization.id);
  const orgPlan = BillingPlansDetails.filter(
    (plan) => plan.tier === (organization.plan as Plans),
  )[0];
  return (
    <Paper sx={{flex: 1, p: 3}}>
      <Typography variant="h6">{`${mappedPlan} Plan`}</Typography>
      {showPlanInfo ? <PlanInfo plan={orgPlan} allowCollapse /> : null}
      <Button sx={{mt: 3}} variant="contained" onClick={goToBillingPlans}>
        Manage Plan
      </Button>
    </Paper>
  );
};

export default CurrentPlanCard;
