import {Box, Button} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect} from 'react';
import {getSsoConfig} from '../../../../actions/organizationActions';
import {type Plans} from '../../../../constants';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useBooleanState, usePrevious} from '../../../../hooks/utils';
import {useSsoConfig} from '../../../../selectors/sso';
import {canUseSso} from '../../../../utils/planRules';
import SsoForm from './SsoForm/SsoForm';

type SsoControlsProps = {
  plan: Plans;
  orgId: string;
};

function SsoButton({orgId}: {orgId: string}) {
  useDashboardApi(getSsoConfig, {organizationId: orgId});
  const ssoConfig = useSsoConfig(orgId);
  const {val: isOpen, setTrue: openSsoConfig, setFalse: closeSsoConfig} = useBooleanState(false);
  const prevConfig = usePrevious(ssoConfig);
  // On deleting the SSO config, or updating the rules, close the modal
  useEffect(() => {
    if (prevConfig?.data && !ssoConfig?.data) {
      closeSsoConfig();
    } else if (prevConfig?.data?.rules && prevConfig.data.rules !== ssoConfig?.data?.rules) {
      closeSsoConfig();
    }
  }, [ssoConfig, closeSsoConfig, prevConfig]);
  return (
    <Box maxWidth={200}>
      <LoadingContainer loading={!ssoConfig || ssoConfig?.loading}>
        <Button variant="contained" onClick={openSsoConfig}>{`${
          ssoConfig?.data?.connection ? 'Edit' : 'Enable'
        } SSO`}</Button>
      </LoadingContainer>
      <SsoForm
        open={isOpen}
        orgId={orgId}
        close={closeSsoConfig}
        data={ssoConfig?.data}
        loading={ssoConfig?.loading}
      />
    </Box>
  );
}

function SsoControls({plan, orgId}: SsoControlsProps) {
  if (!canUseSso(plan)) {
    return (
      <Button variant="outlined" disabled>
        Enterprise plan required
      </Button>
    );
  }
  return <SsoButton orgId={orgId} />;
}

export default SsoControls;
