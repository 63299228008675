import {validateStorageIntegration} from '../actions/storageIntegrationActions';
import {POLLING_INTERVAL, STORAGE_INTEGRATION_PROVIDER_INFO} from '../constants';
import {useStorageIntegration} from '../selectors/storageIntegrations';
import {type GlobalProject, StorageIntegrationProvider, StorageIntegrationStatus} from '../types';
import {Flags, useFlag} from './flags';
import {useControllerApi} from './use-api';
import {useInterval} from './utils';

export function useStorageIntegrationProviderInfo() {
  const s3Enabled = true;
  const gcsEnabled = useFlag(Flags.ENABLE_GCS_STORAGE_INTEGRATIONS);
  const azureBlobEnabled = useFlag(Flags.ENABLE_AZURE_BLOB_STORAGE_INTEGRATIONS);
  return {
    [StorageIntegrationProvider.S3]: {
      name: STORAGE_INTEGRATION_PROVIDER_INFO[StorageIntegrationProvider.S3].name,
      enabled: s3Enabled,
    },
    [StorageIntegrationProvider.GCS]: {
      name: STORAGE_INTEGRATION_PROVIDER_INFO[StorageIntegrationProvider.GCS].name,
      enabled: gcsEnabled,
    },
    [StorageIntegrationProvider.AzureBlob]: {
      name: STORAGE_INTEGRATION_PROVIDER_INFO[StorageIntegrationProvider.AzureBlob].name,
      enabled: azureBlobEnabled,
    },
  };
}

export function usePollStorageIntegrationStatus(integrationId: string, project: GlobalProject) {
  const {setQueryData} = useControllerApi(project, validateStorageIntegration, {
    integrationId,
    globalProjectId: project?.id,
  });
  const integration = useStorageIntegration(project.id, integrationId);
  useInterval(
    () => {
      setQueryData({
        action: validateStorageIntegration,
        data: {integrationId, globalProjectId: project?.id},
      });
    },
    integration?.status !== StorageIntegrationStatus.VALIDATED ? POLLING_INTERVAL : null,
  );
  return integration?.status;
}
