import Card from '@mui/material/Card';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  clearAssistantChat,
  streamAssistantChatCompletion,
} from '../../../../actions/assistantActions';
import ChatInput from '../../../../components/ChatInput/ChatInput';
import {useAssistantDataApi} from '../../../../hooks/use-api';
import {useAssistantChat, useAssistantChatConfig} from '../../../../selectors/assistants';
import {type AssistantChatConfig, type ChatMessage} from '../../../../types';
import ChatHistory from './ChatHistory/ChatHistory';

type ChatTabProps = {
  assistantName: string;
  hasFiles: boolean;
  globalProjectId: string;
  fullscreen?: boolean;
};

const styles = {
  root: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    p: 0,
  },
  fullscreen: {
    border: 'none',
  },
};

function ChatTab({assistantName, hasFiles, globalProjectId, fullscreen}: ChatTabProps) {
  const dispatch = useDispatch();
  const chat = useAssistantChat(globalProjectId, assistantName);
  const config = useAssistantChatConfig(globalProjectId, assistantName);

  const {setQueryData} = useAssistantDataApi<{
    globalProjectId: string;
    assistantName: string;
    chatHistory: ChatMessage[];
    message: ChatMessage;
    config: AssistantChatConfig;
  }>(globalProjectId);

  const onSendMessage = (content: string) => {
    const message: ChatMessage = {
      role: 'user',
      content,
    };
    setQueryData({
      action: streamAssistantChatCompletion,
      data: {
        globalProjectId,
        assistantName,
        chatHistory: chat?.data?.messages || [],
        message,
        config,
      },
    });
  };

  const handleClearChat = useCallback(() => {
    dispatch(clearAssistantChat({globalProjectId, assistantName}));
  }, [dispatch, globalProjectId, assistantName]);

  return (
    <Card sx={[styles.root, fullscreen ? styles.fullscreen : {}]}>
      <ChatHistory chat={chat} hasFiles={hasFiles} />
      <ChatInput
        loading={chat.loading}
        onSendMessage={onSendMessage}
        onClearChat={handleClearChat}
        disableClearAction={!chat.data?.messages?.length || chat.loading}
      />
    </Card>
  );
}

export default ChatTab;
