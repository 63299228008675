import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  type PaperProps,
  Popper,
  type PopperProps,
} from '@mui/material';
import type * as React from 'react';

const styles = {
  menu: {
    py: 0.5,
    boxShadow: 10,
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    py: 0.5,
    li: {
      minHeight: '35px',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      borderRadius: 1.5,
      mx: 1,
      px: 1,
      py: 0.5,
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      },
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    },
  },
};
export interface MenuProps {
  id: string;
  open: boolean;
  onClose: () => void;
  placement?: PopperProps['placement'];
  anchor?: HTMLElement | null;
  sx?: Required<PaperProps['sx']>;
  labelledBy?: string;
  children: React.ReactNode;
}

function Menu({id, open, onClose, placement, anchor, sx = {}, labelledBy, children}: MenuProps) {
  return (
    <Popper
      sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={open}
      anchorEl={anchor}
      transition
      placement={placement}
    >
      {({TransitionProps}) => (
        <Grow style={{transformOrigin: 'center top'}} {...TransitionProps}>
          <Paper sx={[styles.menu, sx]}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList
                id={id}
                autoFocusItem={open}
                disablePadding
                component="ul"
                sx={styles.menuList}
                aria-labelledby={labelledBy}
              >
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default Menu;
