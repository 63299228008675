export function decodeErrorMessage(message: string, inMessageField = true) {
  let decodedMessage;
  try {
    const {error} = JSON.parse(message);
    decodedMessage = inMessageField ? error?.message : error;
  } catch {
    decodedMessage = message;
  }

  return decodedMessage ?? message;
}
