import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {type CodeComponent} from 'react-markdown/lib/ast-to-react';

interface MarkdownCodeBlockProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
}

const MarkdownCodeBlock: CodeComponent = ({
  inline,
  className,
  children,
  ...props
}: MarkdownCodeBlockProps) => {
  const match = /language-(\w+)/.exec(className || '');
  const isPreTag = !inline && className?.includes('pre');

  return !inline && (match || isPreTag) ? (
    <CodeBlock
      code={String(children).replace(/\n$/, '')}
      language={match ? match[1] : ''}
      {...props}
    />
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

export default MarkdownCodeBlock;
