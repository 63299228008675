import {Box, Button, Divider, Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useState} from 'react';
import {
  attachMarketplaceToOrganization,
  createOrganization,
  getOrganizations,
} from '../../../actions/organizationActions';
import {PROVIDER_INFORMATION_MAP, Plans, type Providers} from '../../../constants';
import {useDashboardApi} from '../../../hooks/use-api';
import {useOrganizations, useOrganizationsAreLoading} from '../../../selectors/organizations';
import {useUserId} from '../../../selectors/userSession';
import {type Marketplace, type MarketplaceSetupOptions} from '../../../types';
import dayjs from '../../../utils/dayjs';
import {capitalize} from '../../../utils/format';
import Image from '../../Utilities/Image/Image';
import ThemeAwarePineconeLogo from '../../Utilities/ThemeAwarePineconeLogo';
import MarketplaceSetupConfirmationModal from '../MarketplaceSetupConfirmationModal/MarketplaceSetupConfirmationModal';

const styles = {
  topBannerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 3,
  },
  organizationSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: 4,
    px: 6,
  },
  organizationSelector: {
    alignItems: 'left',
    pb: 1,
    textAlign: 'left',
  },
};

const newOrganizationVal = 'create-new-organization';

function MarketplaceOrganizationConnector({
  marketplaceRecord,
  marketplaceType,
  setupToken,
}: {
  marketplaceRecord: Marketplace;
  marketplaceType: Providers;
  setupToken?: string;
}) {
  useDashboardApi(getOrganizations);
  const marketplaceProviderInformation = PROVIDER_INFORMATION_MAP[marketplaceType];
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const organizations = useOrganizations();
  const organizationsIsLoading = useOrganizationsAreLoading();
  const userId = useUserId();

  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [newOrganizationName, setNewOrganizationName] = useState('');

  const organizationOptions = [
    ...Object.values(organizations || {}).map((org) => ({
      value: org.id,
      label: org.name,
      description: `Plan: ${capitalize(org.plan)} -- Created: ${dayjs(org.created_at).format(
        'MM/DD/YY',
      )} `,
      disabled: org.plan !== Plans.FREE,
    })),
    {
      value: newOrganizationVal,
      label: '+ Create New Organization',
    },
  ];

  const {setQueryData: attachMarketplaceToNewOrganization} = useDashboardApi<{
    name: string;
    userId: string;
    marketplaceSetupOptions: MarketplaceSetupOptions;
  }>();

  const {setQueryData: attachMarketplaceToExistingOrg} = useDashboardApi<{
    marketplaceType: string;
    setupToken: string;
    marketplaceId: string;
    organizationId: string;
  }>();

  const handleAttachMarketplaceToExistingOrganization = () => {
    if (!selectedOrgId || !setupToken) return;
    attachMarketplaceToExistingOrg({
      action: attachMarketplaceToOrganization,
      data: {
        organizationId: selectedOrgId,
        marketplaceType,
        marketplaceId: marketplaceRecord.id,
        setupToken,
      },
    });
  };

  const handleAttachMarketplaceToNewOrganization = () => {
    if (!setupToken) return;
    attachMarketplaceToNewOrganization({
      action: createOrganization,
      data: {
        name: newOrganizationName,
        userId: userId || '',
        marketplaceSetupOptions: {
          marketplaceType,
          marketplaceId: marketplaceRecord.id,
          setupToken,
        },
      },
    });
  };

  const handleConnect = () => {
    if (selectedOrgId === newOrganizationVal) {
      handleAttachMarketplaceToNewOrganization();
    } else {
      handleAttachMarketplaceToExistingOrganization();
    }
    // Show loading indicator until org is created and user is rerouted
    setAwaitingResponse(true);
  };

  return (
    <Box>
      <Box sx={styles.topBannerContainer}>
        <ThemeAwarePineconeLogo
          fullLogo
          sx={{
            height: 25,
            mb: 1,
          }}
        />
        <Divider orientation="vertical" flexItem sx={{mx: 3}} />
        <Image
          srcLight={marketplaceProviderInformation.logo}
          srcDark={marketplaceProviderInformation.logoDark}
          alt={marketplaceProviderInformation.name}
          height={25}
        />
        <Typography variant="h6" ml={3}>
          {marketplaceProviderInformation.name} Marketplace
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="h4" component="h1" mb={2}>
          Connect {marketplaceProviderInformation.name} to Pinecone in seconds
        </Typography>
      </Box>
      <LoadingContainer loading={awaitingResponse || organizationsIsLoading}>
        <>
          <Box textAlign="center">
            <Typography variant="h6" component="h2">
              Select or create a new organization to connect to Pinecone
            </Typography>
          </Box>
          <Box sx={styles.organizationSelectorContainer}>
            <Box sx={styles.organizationSelector}>
              <SelectInput
                label="Select an organization"
                tooltip="Only free organizations are available for connecting to Pinecone via marketplace at this time."
                value={selectedOrgId}
                onChange={(val) => setSelectedOrgId(val)}
                placeholder="Organization"
                fullWidth
                options={organizationOptions}
              />
              {selectedOrgId === newOrganizationVal && (
                <TextInput
                  placeholder="Type organization name ..."
                  label="New Organization Name"
                  value={newOrganizationName}
                  onChange={(val) => setNewOrganizationName(val)}
                  fullWidth
                />
              )}
            </Box>
            <Button
              name="Connect to Pinecone"
              disabled={
                !selectedOrgId || (selectedOrgId === newOrganizationVal && !newOrganizationName)
              }
              variant="contained"
              color="primary"
              sx={{mb: 3}}
              fullWidth
              onClick={() => setOpenConfirmationModal(true)}
            >
              Connect to Pinecone
            </Button>
            <MarketplaceSetupConfirmationModal
              open={openConfirmationModal}
              onConfirm={handleConnect}
              onClose={() => setOpenConfirmationModal(false)}
              marketplaceRecord={marketplaceRecord}
              selectedOrganizationName={
                selectedOrgId === newOrganizationVal
                  ? newOrganizationName
                  : organizations?.[selectedOrgId]?.name
              }
              marketplaceType={marketplaceType}
            />
          </Box>
        </>
      </LoadingContainer>
    </Box>
  );
}

export default MarketplaceOrganizationConnector;
