import {Box, Button, Typography} from '@mui/material';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import ProgressRow from '../Utilities/ProgressRow';
import ForcedMigrationSteps from './ForcedMigrationSteps';
import {Stage} from './hooks';

type MigrationInProgressFormProps = {
  service: string;
  stage: Stage;
  fullMigration: boolean;
  doDelete: () => void;
  close: () => void;
};

const getMigrationStage = (stage: Stage) => {
  if (stage === Stage.COLLECTION) {
    return 'waiting';
  }
  if (stage === Stage.MIGRATION) {
    return 'progress';
  }
  return 'done';
};

function MigrationInProgressForm({
  service,
  stage,
  fullMigration,
  doDelete,
  close,
}: MigrationInProgressFormProps) {
  const prepareStage = 'done';
  const collectionStage = stage === Stage.COLLECTION ? 'progress' : 'done';
  const isDone = stage === Stage.DONE;
  const migrateStage = getMigrationStage(stage);
  return (
    <Box position="relative">
      <Box>
        <ModalHeader
          title={fullMigration ? 'Migration in progress' : 'Saving to collection'}
          subtitle={service}
          close={isDone ? close : undefined}
        />
        <ForcedMigrationSteps fullMigration={fullMigration} activeStep={isDone ? 2 : 1} />
      </Box>
      <Box py={2}>
        {isDone ? (
          <>
            <Typography component="span" fontWeight="bold">
              Congratulations!
            </Typography>
            <Typography component="span">
              {' '}
              {fullMigration
                ? 'Your data has migrated.'
                : 'Your pod index has been saved to a collection.'}{' '}
              If you are no longer using your pod index, you can now safely delete it.
            </Typography>
          </>
        ) : (
          <>
            <Typography component="span" fontWeight="bold">
              {fullMigration
                ? 'Your serverless migration is underway'
                : 'Your index is being saved to a collection'}
            </Typography>
            <Typography component="span">
              {' '}
              and should be done in about five minutes.{' '}
              {fullMigration
                ? 'Please do not refresh or close this page during the migration process.'
                : 'You can safely close this window and check the status on the Collections page at anytime.'}
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{backgroundColor: 'background.surface', p: 3, mt: 1, mb: 4}}>
        <Typography fontWeight="bold">Next Steps:</Typography>
        <ProgressRow
          stage={prepareStage}
          text={
            prepareStage === 'done' ? 'Data ready for migration' : 'Preparing data for migration'
          }
        />
        <ProgressRow
          stage={collectionStage}
          text={
            collectionStage === 'done'
              ? 'Data saved to a collection'
              : 'Saving data to a collection'
          }
        />
        {fullMigration && (
          <ProgressRow
            stage={migrateStage}
            text={
              migrateStage === 'done'
                ? 'Data migrated to a serverless index'
                : 'Migrating data to a serverless index'
            }
          />
        )}
      </Box>
      <Button fullWidth variant="contained" disabled={!isDone} onClick={doDelete}>
        {isDone ? 'Delete pod index and finish' : 'Complete migration'}
      </Button>
    </Box>
  );
}

export default MigrationInProgressForm;
