import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {Link as RouterLink} from 'react-router-dom';
import ApiQuickstartCard from '../../../../../components/ApiQuickstartCard/ApiQuickstartCard';
import {useSelectedLanguage} from '../../../../../components/CodeSnippets/utils';
import {PUBLIC_DOCS, ProjectPage} from '../../../../../constants';
import {getGlobalProjectPath} from '../../../../../hooks/navigation';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {ASSISTANT_DOCS_LANGUAGES, GETTING_STARTED_CONTENT} from '../../../docs/docs';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default function GettingStartedGuide() {
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const selectedLanguage = useSelectedLanguage(ASSISTANT_DOCS_LANGUAGES);
  const content = GETTING_STARTED_CONTENT[selectedLanguage];

  return (
    <ApiQuickstartCard languageOptions={ASSISTANT_DOCS_LANGUAGES}>
      <Box sx={styles.root}>
        <Box sx={styles.section}>
          <Box>
            <Typography variant="subtitle1">Get your API key</Typography>
            <Typography>
              Securely access our API with a project API key. Don’t have one?{' '}
              <Link
                component={RouterLink}
                to={`${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.KEYS}`}
              >
                Generate a new key
              </Link>
              .
            </Typography>
          </Box>
        </Box>
        {content.map((section) => (
          <Box key={section.title} sx={styles.section}>
            <Box>
              <Typography variant="subtitle1">{section.title}</Typography>
              <Typography>{section.description}</Typography>
            </Box>
            {section.code && <CodeBlock code={section.code} language={selectedLanguage} />}
          </Box>
        ))}
        <Typography>
          Now you’re ready to start using Pinecone Assistant. Head to our{' '}
          <Link href={PUBLIC_DOCS.ASSISTANT} target="_blank">
            documentation
          </Link>{' '}
          for detailed information and examples.
        </Typography>
      </Box>
    </ApiQuickstartCard>
  );
}
