import {createReducer} from '@reduxjs/toolkit';
import {
  fetchSystemStatusFailure,
  fetchSystemStatusRequest,
  fetchSystemStatusSuccess,
} from '../actions/systemStatusActions';
import {type AsyncDataState, initAsyncDataState} from './utils';

interface SystemStatus {
  isOperational: boolean;
  statusDescription: string;
}

type SystemStatusState = AsyncDataState<SystemStatus>;

const initialState: SystemStatusState = initAsyncDataState<SystemStatus>({
  isOperational: true,
  statusDescription: '',
});

// System status as reported by https://status.pinecone.io
const systemStatusReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchSystemStatusRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(fetchSystemStatusSuccess, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(fetchSystemStatusFailure, (state, action) => {
      state.loading = false;
      state.data = {
        isOperational: false,
        statusDescription: 'Unable to fetch status',
      };
      state.error = action.payload;
    });
});

export default systemStatusReducer;
