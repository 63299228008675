import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {MODAL_WIDTH_MEDIUM} from '../../styles/theme';
import CodingLanguageSelector from '../CodeSnippets/CodingLanguageSelector';
import {type LanguageTypes} from '../CodeSnippets/constants';

interface ApiQuickstartCardProps {
  children: React.ReactNode;
  languageOptions?: LanguageTypes[];
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  root: {
    p: 4,
    mb: 4,
    mt: 4,
    width: MODAL_WIDTH_MEDIUM,
    position: 'relative',
    boxShadow: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 2,
  },
};

function ApiQuickstartCard({children, languageOptions}: ApiQuickstartCardProps) {
  return (
    <Box pb={2}>
      <Paper sx={styles.root}>
        <Box sx={styles.header}>
          <Typography variant="h6" component="h3">
            API quickstart
          </Typography>
          <CodingLanguageSelector languageOptions={languageOptions} />
        </Box>
        <Box sx={styles.container}>{children}</Box>
      </Paper>
    </Box>
  );
}

export default ApiQuickstartCard;
