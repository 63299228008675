import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Button, Link, List, ListItem, Typography} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {PUBLIC_DOCS_URL} from '../../constants';
import {useBooleanState} from '../../hooks/utils';

type ArchivedApiKeysModalProps = {
  onClickMigrate: () => void;
  onClickUpgrade: () => void;
  isNano: boolean;
};

function ArchivedApiKeysModal({onClickMigrate, onClickUpgrade, isNano}: ArchivedApiKeysModalProps) {
  const {val: open, setFalse: close} = useBooleanState(true);
  const clickMigrate = () => {
    onClickMigrate();
    close();
  };
  const clickUpgrade = () => {
    onClickUpgrade();
    close();
  };
  return (
    <BaseModal open={open} onClose={close} showCloseIcon>
      <Box sx={{width: 420, p: 1, display: 'flex', flexDirection: 'column', gap: 2}}>
        <Box display="flex" gap={1} alignItems="center">
          <WarningAmberIcon color="error" fontSize="small" />
          <Typography variant="h5" color="error">
            Migration Required
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight="bold" component="span">
            The deadline to migrate your pod-based index to serverless has now passed.{' '}
          </Typography>
          <Typography component="span">
            As a final warning, your API key has been deleted. If you need immediate access to your
            index, you may create another API key.
          </Typography>
        </Box>
        <Box>
          <Typography>Your index is scheduled to be archived as a collection soon.</Typography>
          <Typography fontWeight="bold" component="span">
            Your data will not be lost,
          </Typography>
          <Typography component="span">
            {' '}
            and you will be able to create a serverless index from that collection.
          </Typography>
        </Box>
        {isNano ? (
          <Box>
            <Typography>
              However, to avoid a surprise disruption, we recommend you migrate now.
            </Typography>
            <Link
              target="_blank"
              rel="noreferrer"
              href={`${PUBLIC_DOCS_URL}/guides/indexes/migrate-a-legacy-starter-index-to-serverless`}
            >
              Learn More
            </Link>
          </Box>
        ) : (
          <>
            <Box>
              <Typography>However, to avoid a surprise disruption, we recommend you:</Typography>
            </Box>
            <Box px={2}>
              <List sx={{listStyleType: 'disc'}}>
                <ListItem sx={{display: 'list-item'}}>
                  <Typography fontWeight="bold" component="span">
                    Migrate for Free:{' '}
                  </Typography>
                  <Typography component="span">
                    Stay on the Starter tier by migrating to serverless.{' '}
                  </Typography>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={`${PUBLIC_DOCS_URL}/guides/indexes/migrate-a-legacy-starter-index-to-serverless`}
                  >
                    Learn More
                  </Link>
                </ListItem>
                <ListItem sx={{display: 'list-item'}}>
                  <Typography fontWeight="bold" component="span">
                    Upgrade Seamlessly:{' '}
                  </Typography>
                  <Typography component="span">
                    Access more features and scale easily with a simple upgrade.
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Box>
              <Typography>
                Contact{' '}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.pinecone.io/hc/en-us/requests/new"
                >
                  support
                </Link>{' '}
                if you need help.
              </Typography>
            </Box>
          </>
        )}
        <Box display="flex" gap={1}>
          <Button variant="contained" sx={{flex: 1}} onClick={clickMigrate}>
            Migrate Now
          </Button>
          {!isNano && (
            <Button color="secondary" variant="outlined" onClick={clickUpgrade}>
              Upgrade
            </Button>
          )}
        </Box>
      </Box>
    </BaseModal>
  );
}

export default ArchivedApiKeysModal;
