import {useEffect} from 'react';
import {getIntegrationInfo} from '../actions/integrationActions';
import {useAppDispatch} from '../app/store';
import {useIntegrationInfo} from '../selectors/integrations';

export const useIntegration = (integrationId?: string) => {
  const integration = useIntegrationInfo(integrationId ?? '');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (integrationId && !integration) dispatch(getIntegrationInfo(integrationId));
  }, [dispatch, integration, integrationId]);

  return integration;
};
