import {Box} from '@mui/material';
import {useEffect} from 'react';
import {getFreeTierDowngradeEligibility} from '../../../../actions/freeTierDowngradeEligibilityActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import PricingDetailsTable from '../../pricing/PricingDetailsTable/PricingDetailsTable';
import {BillingPlansDetails} from '../constants';
import BillingPlanCard from './BillingPlanCard/BillingPlanCard';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  plansContainer: {
    display: 'flex',
    gap: 3,
    flexGrow: 1,
  },
  bottomContainer: {
    borderRadius: 1,
    p: 3,
    my: 2,
  },
};

function BillingPlans({showPlanInfo = true}: {showPlanInfo?: boolean}) {
  const selectedOrgId = useSelectedOrganizationId() || '';

  const {setQueryData: setGetFreeTierDowngradeEligibilityData} = useDashboardApi<{
    organizationId: string;
  }>();

  useEffect(() => {
    if (selectedOrgId) {
      setGetFreeTierDowngradeEligibilityData({
        action: getFreeTierDowngradeEligibility,
        data: {
          organizationId: selectedOrgId,
        },
      });
    }
    // Run this only when the component is mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.container} id="billing-plans">
      <Box sx={styles.plansContainer}>
        {BillingPlansDetails.map((plan) => (
          <BillingPlanCard showPlanInfo={showPlanInfo} plan={plan} key={plan.tier} />
        ))}
      </Box>
      <PricingDetailsTable />
    </Box>
  );
}

export default BillingPlans;
