import {ResponsiveLine} from '@nivo/line';
import {requestPodFullnessQuery} from '../../../../../actions/prometheusActions';
import ChartLayout from '../../../../../components/chart/ChartLayout/ChartLayout';
import ChartLegend from '../../../../../components/chart/ChartLegend/ChartLegend';
import {useFormatDateTick, useNivoTheme} from '../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../hooks/use-api';
import {useLegacyMetrics} from '../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {useServiceByGlobalProject} from '../../../../../selectors/services';
import {type PrometheusLegacyResponse} from '../../../../../types';
import {chartAxisBottom, chartAxisY, chartMargin, timeXScale} from '../../../../../utils/chart';
import {getSpecs} from '../../../../../utils/services';
import PodFullnessTooltip from './PodFullnessTooltip/PodFullnessTooltip';
import {markers} from './utilities/Utils';

const podFullnessColor = '#0345A0';

function Chart({formattedData}: {formattedData: PrometheusLegacyResponse}) {
  const nivoTheme = useNivoTheme();
  const formatTick = useFormatDateTick({formattedData});
  return (
    <ResponsiveLine
      theme={nivoTheme}
      colors={[podFullnessColor]}
      data={formattedData}
      curve="step"
      areaOpacity={0.9}
      lineWidth={2}
      xScale={timeXScale}
      yScale={{type: 'linear', min: 0, max: 100}}
      gridYValues={5}
      margin={chartMargin}
      axisLeft={chartAxisY({unit: '%'})}
      axisTop={null}
      axisBottom={{
        ...chartAxisBottom,
        format: formatTick,
      }}
      enablePoints={false}
      enableGridX={false}
      markers={markers}
      enableArea
      useMesh
      enableSlices="x"
      sliceTooltip={PodFullnessTooltip}
    />
  );
}

const legend = [
  {
    name: 'Pod Fullness',
    color: podFullnessColor,
  },
];
function PodFullnessChart({service, start, end}: {service: string; start: string; end: string}) {
  const projectId = useSelectedGlobalProjectId();
  const serviceInfo = useServiceByGlobalProject(projectId || '', service);
  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    requestPodFullnessQuery,
    projectId || '',
    service,
    start,
    end,
    !!serverlessSpec,
  );
  const podFullnessMetrics = useLegacyMetrics('podFullness', service);
  return (
    <ChartLayout
      title="Pod Fullness"
      error={podFullnessMetrics?.error}
      chart={<Chart formattedData={podFullnessMetrics?.data || []} />}
      tooltip="Total pod usage including both vectors and metadata. Pod Fullness is measured in increments of 10%."
      legend={<ChartLegend keys={legend} />}
      loading={podFullnessMetrics?.loading}
    />
  );
}

export default PodFullnessChart;
