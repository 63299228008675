import InviteUserButton from '../../../components/InviteUsersButton';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useSelectedOrganization} from '../../../selectors/organizations';
import OrganizationUsersTable from './OrganizationUsersTable/OrganizationUsersTable';

function OrgMembersPage() {
  const selectedOrg = useSelectedOrganization();
  return (
    <Page
      header={
        <PageHeader
          title="Organization Members"
          titleHidden
          description={
            <>
              Set and modify member permissions in <strong>{selectedOrg?.name}</strong> here
            </>
          }
          actions={<InviteUserButton isOrg />}
        />
      }
    >
      <OrganizationUsersTable />
    </Page>
  );
}

export default OrgMembersPage;
