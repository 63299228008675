import {Paper, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useIntegration} from '../../../../../hooks/integrations';
import {type IntegrationFormData} from '../utilities/schema';
import styles from '../utilities/styles';

type SlugFieldProps = {
  disabled: boolean;
};

/** Form field for the integration slug. */
function SlugField({disabled}: SlugFieldProps) {
  const {
    control,
    setError,
    clearErrors,
    formState: {defaultValues},
  } = useFormContext<IntegrationFormData>();
  const slug = useWatch({name: 'slug'});

  const existingIntegration = useIntegration(slug);

  useEffect(() => {
    if (
      slug !== defaultValues?.slug &&
      !existingIntegration?.loading &&
      existingIntegration?.data
    ) {
      setError('slug', {type: 'unique', message: 'This slug is already in use.'});
    } else {
      clearErrors('slug');
    }
  }, [
    clearErrors,
    defaultValues?.slug,
    existingIntegration?.data,
    existingIntegration?.loading,
    setError,
    slug,
  ]);

  return (
    <Paper sx={styles.section}>
      <TextInput
        name="slug"
        control={control}
        required
        label="URL Slug"
        fullWidth
        startAdornment="connect.pinecone.io/"
        disabled={disabled}
      />
      <Typography>Your integration URL slug is public and cannot be changed.</Typography>
    </Paper>
  );
}

export default SlugField;
