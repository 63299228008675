import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useRef, useState} from 'react';
import IconButton, {type IconButtonProps} from '../Buttons/IconButton';
import {type MenuProps} from './Menu';
import SelectorMenu from './SelectorMenu';
import {type Item} from './types';

type SelectorMenuButtonProps = {
  items: Item[];
  id: string;
  placement?: MenuProps['placement'];
  tooltip?: IconButtonProps['tooltip'];
  disabled?: boolean;
  ariaLabel?: string;
  iconSx?: IconButtonProps['sx'];
  menuSx?: MenuProps['sx'];
};

function SelectorMenuButton({
  id,
  items,
  tooltip,
  placement,
  disabled,
  ariaLabel,
  iconSx,
  menuSx,
}: SelectorMenuButtonProps) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const closeMenu = () => setMenuIsOpen(false);
  const openMenu = () => setMenuIsOpen(true);
  const menuAnchor = useRef(null);
  const buttonId = `${id}-button`;
  const menuId = `${id}-menu`;
  return (
    <>
      <IconButton
        id={buttonId}
        dataTestId={buttonId}
        color="secondary"
        tooltip={tooltip}
        disabled={disabled}
        icon={<MoreHorizIcon />}
        ref={menuAnchor}
        onClick={menuIsOpen ? closeMenu : openMenu}
        ariaControls={menuIsOpen ? menuId : undefined}
        ariaLabel={ariaLabel}
        ariaExpanded={menuIsOpen}
        ariaHaspopup
        sx={iconSx}
      />
      <SelectorMenu
        id={menuId}
        open={menuIsOpen}
        anchor={menuAnchor.current}
        onClose={closeMenu}
        labelledBy={buttonId}
        placement={placement}
        items={items}
        sx={menuSx}
      />
    </>
  );
}

export default SelectorMenuButton;
