import {createAction} from '@reduxjs/toolkit';

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const enqueNotification = createAction<{
  type: NotificationTypes;
  text: string;
}>('ENQUE_NOTIFICATION');
export const clearNotification = createAction<undefined>('CLEAR_NOTIFICATION');
