import Box from '@mui/material/Box';
import {type Theme} from '@mui/material/styles';
import {GUTTER} from '../../../../styles/theme';

interface DrawerContainerProps {
  config?: DrawerConfig;
  gutter?: boolean;
  children: React.ReactElement;
}

export interface DrawerConfig {
  width: number;
  open: boolean;
  content: React.ReactNode;
}

const styles = {
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: (drawerOpen: boolean, width: number, gutter?: boolean) => ({
    marginRight: (theme: Theme) =>
      drawerOpen ? `calc(${width}px + ${gutter ? theme.spacing(GUTTER) : '0px'})` : 0,
    transition: (theme: Theme) =>
      theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
  }),
  drawer: (drawerOpen: boolean, width: number) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    borderLeft: 1,
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    width: drawerOpen ? width : 0,
    flexShrink: 0,
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
  }),
};

export default function DrawerContainer({config, children, gutter}: DrawerContainerProps) {
  if (!config) {
    return children;
  }

  const {open, width, content} = config;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content(open, width, gutter)}>{children}</Box>
      <Box sx={styles.drawer(open, width)}>{content}</Box>
    </Box>
  );
}
