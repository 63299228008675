import {useFlags, useLDClient} from 'launchdarkly-react-client-sdk';
import {useEffect} from 'react';
import {Outlet, useMatch, useSearchParams} from 'react-router-dom';
import {AUTH0_NAMESPACE} from '../../../../constants';
import {useAuth} from '../../../../hooks/auth';
import {useLogin} from '../../../../hooks/utils';
import LoadingPage from '../../../../pages/app/LoadingPage/LoadingPage';
import {useSelectedOrganizationPlan} from '../../../../selectors/organizations';
import {
  HEAP_TRACKING_EVENTS,
  TRACKING_EVENTS,
  sendToGa,
  sendToHeapOnce,
  setHeapEventProperty,
  setHeapProperty,
} from '../../../../utils/tracking';
import AuthErrorGuard from './AuthErrorGuard/AuthErrorGuard';

function AuthenticationGuard() {
  const login = useLogin();
  const {isAuthenticated, isLoading, user} = useAuth();
  const ldClient = useLDClient();

  const [searchParams] = useSearchParams();
  const integration = useMatch('/connect/:integrationId');
  const integrationId = integration?.params.integrationId || undefined;
  // sourceTag is only used when connecting an integration.
  const sourceTag = integrationId ? searchParams.get('sourceTag') || integrationId : undefined;
  const sessionType = searchParams.get('sessionType') || (integrationId ? 'login' : 'signup');

  const plan = useSelectedOrganizationPlan();
  const flags = useFlags();

  useEffect(() => {
    if (user && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: user.sub,
        name: user.name,
        email: user.email,
      });
    }
  }, [ldClient, user]);

  useEffect(() => {
    try {
      if (user && window.heap?.identify) {
        window.heap.identify(user.sub || '');
        setHeapProperty({email: user.email, plan});
        setHeapEventProperty(flags);
      }
      if (user?.email && window.signals?.identify) {
        window.signals.identify(user.email);
      }
    } catch (err) {
      // ignore
    }
    if (user && user[`${AUTH0_NAMESPACE}loginCount`] === 1) {
      // Users with no company found are set to companyId=-1 and will be considered non-commercial
      const isCommercial = user[`${AUTH0_NAMESPACE}hubspotCompanyId`] !== -1;
      sendToGa(TRACKING_EVENTS.SIGNUP, {isCommercial});
      sendToHeapOnce(HEAP_TRACKING_EVENTS.SIGNUP, {isCommercial});
    }
    if (user && window.ChurnZero) {
      const companyId = user[`${AUTH0_NAMESPACE}hubspotCompanyId`];
      // Users with no company found are set to companyId=-1 and should not be sent to ChurnZero
      if (companyId >= 0) {
        window.ChurnZero.push(['setAppKey', '1!arx-ox7NUFJNk44ctHtCBySGPGniPvwAJG445HIQZ5Et117F']);
        window.ChurnZero.push(['setContact', companyId, user.email]);
      }
    }
  }, [user, plan, flags]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    login({
      sessionType,
      sourceTag,
      integrationId,
    });
  }

  // Catches Errors thrown in Auth0 Rules
  return (
    <AuthErrorGuard isAuthenticated={isAuthenticated}>
      {isAuthenticated ? <Outlet /> : <LoadingPage />}
    </AuthErrorGuard>
  );
}

export default AuthenticationGuard;
