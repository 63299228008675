import {Box, type BoxProps, Typography} from '@mui/material';
import {IndexState} from '../constants';

const styles = {
  statusIcon: {
    display: 'inline-block',
    borderRadius: '100%',
    height: 12,
    width: 12,
    mr: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface StatusBoxProps {
  statusText?: string;
  color: string;
  includeText?: boolean;
  sx?: BoxProps['sx'];
}

function StatusBox({color, includeText = true, statusText = '', sx = {}}: StatusBoxProps) {
  if (statusText) {
    return (
      <Box sx={{...styles.container, ...sx}}>
        <Box sx={[styles.statusIcon, {background: color}]} id="index-status-indicator" />
        {includeText && statusText !== IndexState.READY && (
          <Typography variant="subtitle2" color="secondary">
            {statusText}
          </Typography>
        )}
      </Box>
    );
  }
  return <Box height={20} />;
}

export default StatusBox;
