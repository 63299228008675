import {useAuth} from '../hooks/auth';

export const useAuthData = () => {
  const {user} = useAuth();
  return user;
};

export const useUserEmail = () => {
  const {user} = useAuth();
  return user?.email;
};
