import type * as inkeepJS from '@inkeep/uikit-js';
import {Box} from '@mui/material';
import {useEffect, useRef} from 'react';
import {useAuth} from '../../hooks/auth';
import {baseSettings, createSupportFormConfig, initializeInkeep} from './inkeepConfig';

type EmbeddedChatInstance = ReturnType<ReturnType<typeof inkeepJS.Inkeep>['embed']>;

export const EmbeddedChat = () => {
  const embeddedChatRef = useRef<EmbeddedChatInstance | null>(null);

  const {user} = useAuth();

  useEffect(() => {
    const inkeep = initializeInkeep();
    const supportFormConfig = createSupportFormConfig(user);

    embeddedChatRef.current = inkeep.embed({
      componentType: 'EmbeddedChat',
      targetElement: '#embedded-chat-target',
      properties: {
        baseSettings,
        aiChatSettings: {
          chatSubjectName: 'Pinecone',
          botAvatarSrcUrl:
            'https://storage.googleapis.com/organization-image-assets/pinecone-botAvatarSrcUrl-1707422859519.svg',
          botAvatarDarkSrcUrl:
            'https://storage.googleapis.com/organization-image-assets/pinecone-botAvatarDarkSrcUrl-1707422858768.svg',
          quickQuestions: [
            'What are the benefits of serverless?',
            'How do I do a dense and sparse search?',
            'What are the primary ways to minimize latency?',
          ],
          getHelpCallToActions: [
            {
              name: 'Share feedback',
              type: 'OPEN_FORM',
              label: 'Test label',
              heading: 'Test heading',
              formConfig: supportFormConfig,
              icon: {
                builtIn: 'IoHelpBuoyOutline',
              },
            },
          ],
          includeAIAnnotations: {
            shouldEscalateToSupport: true,
          },
          aiAnnotationPolicies: {
            shouldEscalateToSupport: [
              {
                threshold: 'STANDARD',
                action: {
                  type: 'SHOW_SUPPORT_BUTTON',
                  label: 'Share feedback',
                  icon: {builtIn: 'LuUsers'},
                  action: {
                    type: 'OPEN_FORM',
                    formConfig: supportFormConfig,
                  },
                },
              },
            ],
          },
        },
      },
    });
  }, [user, user?.email]);

  return (
    <Box
      sx={{width: '100%', height: '100%'}}
      id="embedded-chat-target"
      className="ikp-embedded-chat-wrapper"
    />
  );
};
