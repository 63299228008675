import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {deleteNamespace} from '../../../../actions/dataActions';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import {useDataPlane} from '../../../../hooks/use-api';
import {useBooleanState} from '../../../../hooks/utils';
import {type IndexExplorerState} from '../IndexExplorer/utilities/utils';

type NamespaceActionsProps = {
  namespace: string;
  indexExplorerState: IndexExplorerState;
};

function NamespaceActions({namespace, indexExplorerState}: NamespaceActionsProps) {
  const {project, service, host} = indexExplorerState;
  const {val: deleteIsOpen, setTrue: openDelete, setFalse: closeDelete} = useBooleanState();
  const {setQueryData} = useDataPlane<{namespace: string}>(project, service, host);
  const doDeleteNamespace = () => {
    setQueryData({
      action: deleteNamespace,
      data: {namespace},
    });
  };
  return (
    <>
      <SelectorMenuButton
        id={`namespace-actions-${namespace}`}
        items={[
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
            color: 'error',
            onClick: openDelete,
          },
        ]}
      />
      <DeleteConfirmationModal
        open={deleteIsOpen}
        close={closeDelete}
        itemType="Namespace"
        name={namespace}
        doDelete={doDeleteNamespace}
      />
    </>
  );
}

export default NamespaceActions;
