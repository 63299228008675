import {z} from 'zod';
import {ControlPlaneRoles, DataPlaneRoles, ProjectRoles} from '../../../types';

export enum BaseOptions {
  CUSTOM = 'Custom',
}

export const ProjectOptions = [ProjectRoles.PROJECT_EDITOR, BaseOptions.CUSTOM] as const;

export const apiKeyPermissionsSchema = z.object({
  baseRole: z.enum(ProjectOptions),
  controlPlaneRole: z.nativeEnum(ControlPlaneRoles).or(z.literal('')),
  dataPlaneRole: z.nativeEnum(DataPlaneRoles).or(z.literal('')),
});

export type FormWithRoles = z.infer<typeof apiKeyPermissionsSchema>;
