import {addMilliseconds, addSeconds, eachMinuteOfInterval} from 'date-fns';
import {sub} from 'date-fns/sub';
import {TIME_INTERVAL, TIME_INTERVAL_TICK_STEP_MAP, type TimeInterval} from '../constants/time';

export function generateTicks(start: Date, end: Date, interval: TimeInterval): string[] {
  const secondsModifier = end.getSeconds();
  const msModifier = end.getMilliseconds();

  return eachMinuteOfInterval({start, end}, {step: TIME_INTERVAL_TICK_STEP_MAP[interval]}).map(
    (date) =>
      addMilliseconds(addSeconds(date.toISOString(), secondsModifier), msModifier).toISOString(),
  );
}

/**
 * In order to present a uniform time period, we need to modify the start and end time
 * to map to the specified interval. Then, we generate ticks that match the interval.
 */

export function calculateTimePeriod(
  interval: TimeInterval,
  end: Date,
): {
  start: string;
  end: string;
} {
  let start;
  switch (interval) {
    case TIME_INTERVAL['15m']:
      start = sub(end, {minutes: 15});
      break;
    case TIME_INTERVAL['4hrs']:
      start = sub(end, {hours: 4});
      break;
    case TIME_INTERVAL['12hrs']:
      start = sub(end, {hours: 12});
      break;
    case TIME_INTERVAL['1d']:
      start = sub(end, {days: 1});
      break;
    case TIME_INTERVAL['2d']:
      start = sub(end, {days: 2});
      break;
    case TIME_INTERVAL['1w']:
      start = sub(end, {weeks: 1});
      break;
    default:
      throw new Error('Unknown interval detected.');
  }

  return {start: start.toISOString(), end: end.toISOString()};
}
