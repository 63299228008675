import {Add} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import {
  type Control,
  type UseFormGetFieldState,
  type UseFormSetValue,
  useFieldArray,
} from 'react-hook-form';
import {type MetadataInput} from '../../utilities/schema';
import MetadataInputRow from './MetadataInputRow/MetadataInputRow';

type FormValuesWithMetadata = {
  metadata: MetadataInput[];
  [key: string]: any;
};

type MetadataEditorProps<T extends FormValuesWithMetadata> = {
  idPrefix: string;
  // See https://github.com/orgs/react-hook-form/discussions/9789 (although no one seems to know why it works...)
  control: T extends FormValuesWithMetadata ? Control<T> : never;
  setValue: UseFormSetValue<T>;
  getFieldState: UseFormGetFieldState<T>;
  allowAutoType?: boolean;
};

function MetadataEditor<T extends FormValuesWithMetadata>({
  idPrefix,
  control,
  setValue,
  getFieldState,
  allowAutoType = true,
}: MetadataEditorProps<T>) {
  const {append, remove, fields} = useFieldArray({
    control,
    name: 'metadata',
  });
  const addMetadataField = () => {
    append({
      key: '',
      value: '',
      valueType: 'auto',
    });
  };
  return (
    <>
      {fields.map((field, index) => {
        return (
          <MetadataInputRow
            key={field.id}
            control={control}
            index={index}
            getFieldState={getFieldState}
            setValue={setValue}
            remove={() => remove(index)}
            allowAutoType={allowAutoType}
          />
        );
      })}
      <Box>
        <Button
          id={`${idPrefix}-add-metadata`}
          color="secondary"
          startIcon={<Add />}
          onClick={addMetadataField}
        >
          Add Metadata
        </Button>
      </Box>
    </>
  );
}

export default MetadataEditor;
