import {Box, Button, Link, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import RowWithCheck from '../../Utilities/RowWithCheck';
import {MIGRATION_DOCS_URL} from '../constants';

type PrepareScreenProps = {
  onContinue: () => void;
};

function PrepareScreen({onContinue}: PrepareScreenProps) {
  return (
    <>
      <ModalHeader
        title="Prepare for migration"
        subtitle={
          <Typography>
            For full details, read our{' '}
            <Link href={MIGRATION_DOCS_URL} target="_blank" rel="noreferer">
              documentation
            </Link>
          </Typography>
        }
      />
      <Box>
        <RowWithCheck text="This process may take several hours" />
        <RowWithCheck text="Your new serverless index will not contain any writes made to the old index after migration starts." />
        <RowWithCheck text="You will have to reinitialize your client and make minor code changes" />
      </Box>
      <Alert
        description="Migrating to a serverless index is in public preview and is currently limited to pod-based indexes on AWS and GCP with less than 20 million records and 20,000 namespaces. Support for larger indexes and indexes on Azure is coming soon."
        severity="warning"
      />
      <Box>
        <Button variant="contained" fullWidth onClick={onContinue}>
          Continue
        </Button>
      </Box>
    </>
  );
}

export default PrepareScreen;
