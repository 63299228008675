import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useCallback, useRef} from 'react';
import EmptyState from '../../../../components/layout/EmptyState/EmptyState';
import {useProjectInternetAccessSwitch} from '../../../../hooks/internet-access';
import {useBooleanState} from '../../../../hooks/utils';
import {MODAL_WIDTH_MEDIUM} from '../../../../styles/theme';
import {type NetworkAllowlist} from '../../../../types';

interface AccessTabProps {
  allowlist?: NetworkAllowlist;
  loading?: boolean;
  hasError?: boolean;
}

const styles = {
  enabled: {
    color: 'text.secondary',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
  confirmModal: {
    width: MODAL_WIDTH_MEDIUM,
  },
  modalActions: {
    mt: 4,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
  },
};

export default function AccessTab({allowlist, loading, hasError}: AccessTabProps) {
  const {disabled: internetAccessDisabled, setAccess: setInternetAccess} =
    useProjectInternetAccessSwitch(allowlist);
  const confirmModalAnchor = useRef(null);

  const accessDescription = internetAccessDisabled
    ? 'Internet access is off. Users will no longer be able to read/write to indexes over the internet.'
    : 'This will disable internet access to the project and users will no longer be able to read/write to indexes over the internet.';

  const {val: showConfirm, setTrue: setShowConfirm, setFalse: hideConfirm} = useBooleanState();

  const handleSwitchChange = useCallback(
    ({target: {checked}}) => {
      if (checked) {
        setShowConfirm();
      } else {
        setInternetAccess(checked);
      }
    },
    [setInternetAccess, setShowConfirm],
  );

  const handleConfirmChange = useCallback(() => {
    hideConfirm();
    setInternetAccess(true);
  }, [hideConfirm, setInternetAccess]);

  if (loading && allowlist === undefined) {
    return (
      <Box height="100%" m={4}>
        <LoadingContainer loading />
      </Box>
    );
  }

  if (hasError) {
    return (
      <EmptyState
        heading="Access configuration"
        description="Configuration failed to load. Please refresh the page."
      >
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <Card ref={confirmModalAnchor}>
        <Box sx={styles.heading}>
          <Typography variant="h6" component="h2" sx={[!internetAccessDisabled && styles.enabled]}>
            Restrict access to only private endpoints
          </Typography>
          <Switch
            checked={internetAccessDisabled}
            onChange={handleSwitchChange}
            inputProps={{'aria-label': 'Toggle internet access for project'}}
          />
        </Box>
        <Typography sx={[!internetAccessDisabled && styles.enabled]}>
          {accessDescription}
        </Typography>
      </Card>
      <BaseModal
        container={confirmModalAnchor.current}
        sx={styles.confirmModal}
        open={showConfirm}
        onClose={hideConfirm}
      >
        <ModalHeader title="Confirm network access change" close={hideConfirm} />
        <Typography>
          You are about to disable internet access to the Project which can disrupt access to your
          production applications. Users will only be able to access indexes in this project via
          Private Endpoints.
        </Typography>
        <Box sx={styles.modalActions}>
          <Button color="secondary" variant="text" onClick={hideConfirm}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmChange}>
            Confirm and proceed
          </Button>
        </Box>
      </BaseModal>
    </>
  );
}
