import MuiClearIcon from '@mui/icons-material/Clear';

function ClearIcon() {
  return (
    <MuiClearIcon
      sx={{backgroundColor: 'grey', color: 'white', borderRadius: '50%'}}
      fontSize="small"
    />
  );
}

export default ClearIcon;
