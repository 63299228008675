import {SupportTiers, SupportTiersInOrder} from '../../../../constants';

export enum ChangeSupportAction {
  NO_ACTION = 'No Action',
  UPGRADE = 'Upgrade',
  DOWNGRADE = 'Downgrade',
}

export function evaluateChangeSupportAction(currentTier: SupportTiers, desiredTier: SupportTiers) {
  if (currentTier === desiredTier) {
    return ChangeSupportAction.NO_ACTION;
  }
  return SupportTiersInOrder.indexOf(currentTier) < SupportTiersInOrder.indexOf(desiredTier)
    ? ChangeSupportAction.UPGRADE
    : ChangeSupportAction.DOWNGRADE;
}

// The order of evaluation:
// 1. Check current plan
// 2. The 'Contact Sales' button should take precedence for enterprise over any upgrade
// 3. AC plans have plans included
// 4. Finally, decide whether the button is for an upgrade or a downgrade based on `isUpgrade`.

export const getSupportButton = (
  desiredTier: SupportTiers,
  action: ChangeSupportAction,
  isSupportIncluded: boolean,
): [string, string] => {
  if (action === ChangeSupportAction.NO_ACTION) return ['current-plan-button', 'Current'];
  if (desiredTier === SupportTiers.ENTERPRISE) return ['contact-sales-button', 'Contact Sales'];
  if (isSupportIncluded) return ['included-plan-button', 'Included'];
  return action === ChangeSupportAction.UPGRADE
    ? [`upgrade-to-${desiredTier}-button`, 'Upgrade']
    : [`downgrade-to-${desiredTier}-button`, 'Downgrade'];
};
