import {type SvgFillMatcher} from '@nivo/core';
import {type Datum} from '@nivo/line';
import {getShade} from '../../../../../../styles/utils';

export const getColor = (baseColor: string, quantile: QuantileLabel) => {
  switch (quantile) {
    case '0.99':
      return baseColor;
    case '0.9':
      return getShade(baseColor, 30);
    case '0.5':
      return getShade(baseColor, 60);
    default:
      return baseColor;
  }
};

export const gradient = {
  defs: [
    {
      colors: [
        {
          color: 'inherit',
          offset: 0,
        },
        {
          color: 'inherit',
          offset: 100,
          opacity: 0.75,
        },
      ],
      id: 'gradientA',
      type: 'linearGradient',
    },
  ],
  fill: [
    {
      id: 'gradientA',
      match: '*',
    },
  ] as {id: string; match: '*' | Record<string, unknown> | SvgFillMatcher<Datum>}[],
};

// Prometheus returns 1, 0.999, 0.99, 0.9, 0.5, 0
export type QuantileLabel = '0.5' | '0.9' | '0.99';
export const quantilesNameMap: Record<QuantileLabel, string> = {
  '0.5': 'P50',
  '0.9': 'P90',
  '0.99': 'P99',
};
