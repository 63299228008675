import {useCallback} from 'react';
import {upsertVectors} from '../../../../actions/dataActions';
import {getService} from '../../../../actions/serviceActions';
import {type UpsertRequest, type Vector} from '../../../../api/dataPlaneApi';
import {IndexState, POLLING_INTERVAL} from '../../../../constants';
import {useControllerApi, useDataPlane} from '../../../../hooks/use-api';
import {useInterval, useOnce} from '../../../../hooks/utils';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {
  usePendingDatasetsByGlobalProject,
  useServiceByGlobalProject,
} from '../../../../selectors/services';
import {type GlobalProject} from '../../../../types';

interface TrackerSegmentProps {
  project: GlobalProject;
  service: string;
  dataset: Vector[];
}

function TrackerSegment({project, service, dataset}: TrackerSegmentProps) {
  const serviceInfo = useServiceByGlobalProject(project.id, service);
  const {setQueryData: setDataplaneQuery} = useDataPlane<{
    upserts: UpsertRequest[];
    skipNotify: boolean;
    retries: number;
  }>(project, service, serviceInfo?.host);
  const {setQueryData} = useControllerApi<{name: string; globalProjectId: string}>(project);
  useInterval(
    () => {
      setQueryData({
        action: getService,
        data: {
          globalProjectId: project.id,
          name: service,
        },
      });
    },
    serviceInfo?.status.state === IndexState.INITIALIZING ? POLLING_INTERVAL : null,
  );
  const runUpsert = useCallback(() => {
    setDataplaneQuery({
      action: upsertVectors,
      data: {
        upserts: [
          {
            vectors: dataset,
          },
        ],
        skipNotify: true,
        retries: 3,
      },
    });
  }, [setDataplaneQuery, dataset]);
  useOnce(runUpsert, serviceInfo?.status.state === IndexState.READY);
  return null;
}

/**
 * This component is used to track indexes which were created with sample data.
 * Currently, the upsert of that data is handled by the browser once the index is ready.
 * For each index that was created with a sample data set, a TrackerSegment component will be
 * created which will monitor the index. Once its ready, itll upsert the data and clear the pending
 * dataset.
 */
function CreateIndexWithDataTracker() {
  const selectedProject = useSelectedGlobalProject();
  const pendingDatasets = usePendingDatasetsByGlobalProject(selectedProject?.id);
  return (
    <>
      {Object.keys(pendingDatasets).map((service) => {
        const dataset = pendingDatasets[service];
        return dataset ? (
          <TrackerSegment project={selectedProject} service={service} dataset={dataset} />
        ) : undefined;
      })}
    </>
  );
}

export default CreateIndexWithDataTracker;
