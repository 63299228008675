import {useAppSelector as useSelector} from '../app/store';

const emptyArr: never[] = [];

export const useExplorerStateByGlobalProject = (project: string, index: string) => {
  return useSelector((state) => state.explorer.projects[project]?.indexes[index]);
};

export const useIndexStatsByGlobalProject = (project: string, index: string) => {
  return useExplorerStateByGlobalProject(project, index)?.description;
};

export const useExplorerResultsByGlobalProject = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return (
    indexState?.query.matches
      .map((vector) => {
        const vectorData = indexState.vectors[indexState?.query.namespace || '']?.[vector.vectorId];
        if (!vectorData) {
          return undefined;
        }
        return {
          ...vectorData,
          score: vector.score,
        };
      })
      .filter((v): v is Exclude<typeof v, undefined> => !!v) || []
  );
};

export const useVectorInfo = (
  project: string,
  index: string,
  vectorId: string,
  namespace: string,
) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.vectors?.[namespace]?.[vectorId];
};

export const useExplorerResultsNamespaceByGlobalProject = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.query.namespace;
};

export const useExplorerResultsUsageByGlobalProject = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.query.usage;
};

export const useExplorerSearchIsLoadingByGlobalProject = (project: string, index: string) => {
  return useExplorerStateByGlobalProject(project, index)?.query.loading;
};

export const useExplorerListResults = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.vectorIds || emptyArr;
};

export const useExplorerListIsLoading = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.loading;
};

export const useExplorerListResultsUsage = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.usage;
};

export const useExplorerListResultsType = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.type;
};

export const useExplorerListLimit = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.limit;
};

export const useExplorerListNamespace = (project: string, index: string) => {
  const indexState = useExplorerStateByGlobalProject(project, index);
  return indexState?.list.namespace;
};

export const useIndexVectorsByGlobalProject = (
  project: string,
  index: string,
  namespace: string,
) => {
  return useExplorerStateByGlobalProject(project, index)?.vectors[namespace];
};
