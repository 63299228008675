import {Box, Paper, Typography} from '@mui/material';
import {type EmbeddingModel, type Metric} from '../../../types';
import Image from '../../Utilities/Image/Image';

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    p: 2,
    gap: 1,
    my: 1,
  },
};

function ModelCard({model}: {model: EmbeddingModel}) {
  const {name, dimension, metric, icon, iconDark} = model;
  return (
    <Paper sx={styles.card}>
      <Image alt={name} srcLight={icon} srcDark={iconDark || icon} height={40} />
      <Box>
        <Typography color="primary.dark" fontWeight="bold">
          {name}
        </Typography>
        <Box display="flex" gap={2} color="secondary.main">
          <Typography>
            {dimension.length > 1
              ? `Dimensions: [${(dimension as number[]).join(', ')}]`
              : `Dimension: ${dimension}`}
          </Typography>
          <Typography>
            {metric.length > 1
              ? `Metrics: [${(metric as Metric[]).join(', ')}]`
              : `Metric: ${metric}`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default ModelCard;
