import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {clearChurnSurvey, submitChurnSurvey} from '../../../actions/userActions';
import {useDashboardApi} from '../../../hooks/use-api';
import {useShouldShowChurn} from '../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {MODAL_WIDTH_MEDIUM} from '../../../styles/theme';
import ModalForm from '../../ModalForm';
import ChurnFormFields from './ChurnFormFields/ChurnFormFields';
import schema, {type ChurnData} from './utils/schema';

function ChurnModal() {
  const formFields = useForm({
    defaultValues: {
      reason: undefined,
      feedback: '',
      features: '',
    },
    resolver: zodResolver(schema),
  });
  const {
    formState: {isSubmitting},
    handleSubmit,
  } = formFields;
  const selectedOrgId = useSelectedOrganizationId();
  const {setQueryData} = useDashboardApi<{orgId?: string; churnData: ChurnData}>();
  const onSubmit = (data: {reason?: string; feedback: string; features: string}) => {
    setQueryData({
      action: submitChurnSurvey,
      data: {
        orgId: selectedOrgId,
        churnData: {
          reason: data.reason || '',
          feedback: data.feedback,
          features: data.features,
        },
      },
    });
  };
  const showChurn = useShouldShowChurn();
  const dispatch = useDispatch();
  const doClearChurnSurvey = () => {
    dispatch(clearChurnSurvey());
  };
  return (
    <FormProvider {...formFields}>
      <ModalForm
        open={showChurn}
        close={doClearChurnSurvey}
        title="We'd love to hear your feedback"
        titleSx={{color: 'unset', mb: 0}}
        onSubmit={handleSubmit(onSubmit)}
        submitText="Submit Feedback"
        isValid={!isSubmitting}
        width={MODAL_WIDTH_MEDIUM}
        includeClose={false}
        form={<ChurnFormFields />}
      />
    </FormProvider>
  );
}

export default ChurnModal;
