import {type QuotaKeys} from '@pinecone-experience/commons/quotas';
import {type ReturnMechanism} from '@pinecone-experience/integrations';
import type {
  AssistantFileStatus,
  AssistantQuotaKeys,
  BulkImportErrorModes,
  DeletionProtection,
  FreeTierDowngradeRestrictionKeys,
  IndexState,
  PaymentStatus,
  Plans,
  Providers,
  Roles,
  SupportTiers,
} from '../constants';
import {type QuantileLabel} from '../pages/indexes/IndexDetailPage/LegacyIndexMetrics/LatencyChart/utilities/LatencyUtils';
import {type AsyncDataState} from '../reducers/utils';

export type Environment = {
  id: string;
  name: string;
  obfuscated_name: string;
  provider: Providers;
  is_private: boolean;
  env_type?: 'pod' | 'nano' | 'serverless' | 'starter';
  provider_region?: string;
  region_location?: string;
  region_flag?: string;
  is_free_tier_eligible?: boolean;
};

export type ApiKey = {
  id: string;
  name: string;
  project_name: string;
  user_label: string;
  display_label: string;
  user_name: string;
  value: string;
  integration_id?: string;
  created_at?: string;
};

export type Collection = {
  name: string;
  dimension: number;
  environment: string;
  size: number;
  vector_count: number;
  status: IndexState;
};

export type PodBasedIndexSpec = {
  environment: string;
  pod_type: string;
  pods: number;
  replicas: number;
  shards: number;
  metadata_config?: {indexed: string[]};
};

export type ServerlessIndexSpec = {
  cloud: Providers;
  region: string;
};

export type V4IndexInfoResponse = {
  id: string;
  deletion_protection: DeletionProtection;
  name: string;
  dimension: string;
  metric: string;
  status: {
    ready: boolean;
    state: IndexState;
  };
  host: string;
  spec: {pod: PodBasedIndexSpec} | {serverless: ServerlessIndexSpec};
};

export type IndexStatsDescription = {
  dimension: number;
  indexFullness: number;
  namespaces: Record<
    string,
    {
      vectorCount: number;
    }
  >;
  totalVectorCount: number;
};

export type TableColumns = {
  name: string;
  dataType: 'string' | 'number' | 'actions';
}[];

export type Invite = {
  id: string;
  email: string;
  organization_id: string;
  organization_roles: Record<string, {role: Roles}>;
  project_roles: Record<string, {role: Roles}>;
  created_on: string;
};

export type UserTableData = {
  id: string;
  email: string;
  projectIds: string[];
  orgRoles: Roles[];
  projectRoles: Roles[];
  pending: boolean;
  name: string;
};

export interface FreeTierDowngradeEligibility {
  organizationId: string;
  isEligible: boolean;
  restrictionList: FreeTierDowngradeRestriction[];
}

export interface PrivateEndpointsItem {
  globalProjectId: string;
  endpoints: PrivateEndpoint[];
}

export interface PrivateEndpoint {
  id: string;
  project_id: string;
  cloud_endpoint_id: string;
  cloud: Providers;
  region: string;
}

export interface PrivateEndpointFormData {
  cloud_endpoint_id: string;
  cloud: string;
  region: string;
}

export type NetworkAllowlist = NetworkAllowlistEntry[];

export interface NetworkAllowlistEntry extends CidrConfig {
  id: string;
  project_id: string;
}

export interface CidrConfig {
  cidr: string;
}

export type Organization = {
  id: string;
  name: string;
  plan: Plans;
  payment_status: PaymentStatus;
  stripe_id: string | null;
  params: Record<string, string>;
  created_at: string;
  deleted_at?: string | null;
  active: boolean;
  updated_at?: string | null;
  gcp_marketplace_id?: string | null;
  aws_marketplace_id?: string | null;
  azure_marketplace_id?: string | null;
  orb_customer_id?: string | null;
  orb_subscription_id?: string | null;
  support_tier?: SupportTiers;
};

export type MemberOrganization = Organization & {
  role: Roles;
};

export type SsoConnectionConfig = {
  name: string;
  signInEndpoint: string;
  domain: string;
  cert: string;
  enforceSsoAuthentication: boolean;
};

export type SsoConnectionRule = {
  organization_id: string;
  organization_role: Roles;
  project_id?: string;
  project_role?: Roles;
};

export type GlobalProject = {
  id: string;
  name: string;
  organization_id: string;
  quota: number;
  vault_id: string;
  environment: string;
  created_at?: string;
  force_encryption_with_cmek?: boolean;
};

export type MemberProject = GlobalProject & {
  role: Roles;
};

export type User = {
  id: string;
  email: string;
  name: string;
  firebase_id: string;
  params: Record<string, string>;
  is_sso: boolean;
};

export type UserMember = User & {
  role: Roles;
};

type PrometheusLegacyResponseData = {
  id: string;
  data: {
    x: string;
    y: number;
  }[];
};

export type PrometheusLegacyResponse = PrometheusLegacyResponseData[];

// METRIC REVAMP (@tinedkim)
type PrometheusTimestampedData = {
  timestamp: string;
  [yKey: string]: string | number | null;
};

export type PrometheusTimestampedResponse = {
  maxValue: number | null;
  data: PrometheusTimestampedData[];
};

type PrometheusLatencyResponseObject = PrometheusLegacyResponseData & {
  request_type: string;
  quantile: QuantileLabel;
};
export type PrometheusLatencyResponse = PrometheusLatencyResponseObject[];

export interface FreeTierDowngradeRestriction {
  restriction: FreeTierDowngradeRestrictionKeys;
  limit: number;
  utilization: number;
  isExhausted: boolean;
}

export type Integration = {
  id: string;
  organization_id: string;
  slug: string;
  profile: {
    name: string;
  };
  return_mechanism: ReturnMechanism;
  allowed_origins?: string[];
};

export type IntegrationCreateData = Omit<Integration, 'id' | 'organization_id' | 'profile'> &
  Integration['profile'] & {logo: File};

export type IntegrationUpdateData = Omit<
  Integration,
  'id' | 'organization_id' | 'slug' | 'profile'
> &
  Integration['profile'] & {logo?: File};

export type Assistant = {
  name: string;
  metadata: null | Record<string, string>;
  status: IndexState;
  created_at: string;
  updated_at: string;
};

export interface AssistantFormData {
  name: string;
  metadata: Record<string, string>;
}

export interface AssistantFile {
  created_on: string;
  deleted_on: null | string;
  id: string;
  size?: number;
  metadata: null | Record<string, string>;
  name: string;
  path: string;
  status: AssistantFileStatus;
  updated_on: string;
  percent_done: number;
}

export type ChatMessage = {
  role: 'assistant' | 'user';
  content: string;
};

export type ChatCompletionChoice = {
  finish_reason: string;
  index: number;
  message: ChatMessage;
};

export type ChatMessageCompletion = {
  id: string;
  model: string;
  choices: ChatCompletionChoice[];
};

export type AssistantChatModel = 'gpt-4o' | 'claude-3-5-sonnet';

export type AssistantChatConfig = {
  model: AssistantChatModel;
};

export type ChatSession = {
  id?: string;
  messages: ChatMessage[];
  config: AssistantChatConfig;
};

export type AssistantChat = AsyncDataState<ChatSession>;

export type Metric = 'cosine' | 'dotproduct' | 'euclidean';
export type EmbeddingModel = {
  icon: string;
  iconDark?: string;
  name: string;
  dimension: number[];
  imageRef?: string;
  metric: Array<Metric>;
  type: string;
};

export enum OperationStatus {
  PENDING = 'Pending',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export type MigrationOperationResponse = {
  operation_id: string;
  target_index_name: string;
  import_operation_id?: string;
  status: OperationStatus;
  created_at: string;
};

export type ImportOperationResponse = {
  operation_id: string;
  status: OperationStatus;
  percent_complete: number;
};

export type MigrationOperationDetails = {
  migrationOperation: MigrationOperationResponse;
  importOperation?: ImportOperationResponse;
};

export type UsageQuota = {
  key: QuotaKeys | AssistantQuotaKeys;
  utilization: number;
  limit: number;
};

export interface AssistantUsage {
  usage: number;
  limit: number | null;
}

export type BaseMarketplace = {
  id: string;
  activated: boolean;
  email: string;
  organization_id: string | null;
  created_at: string | null;
  updated_at: string | null;
};

export type AwsMarketplace = BaseMarketplace & {
  aws_account_id: string;
  aws_customer_id: string;
  product_code: string;
};

export type GcpMarketplace = BaseMarketplace & {
  google_user_id: string;
  usage_reporting_id: string | null;
};

export type AzureMarketplace = BaseMarketplace & {
  azure_subscription_id: string;
  plan: string | null;
  status: string | null;
};

export type Marketplace = AwsMarketplace | GcpMarketplace | AzureMarketplace;

export type MarketplaceSetupOptions = {
  marketplaceType: Providers;
  marketplaceId: string;
  setupToken: string;
};

export enum ControlPlaneRoles {
  INDEX_RESOURCE_EDITOR = 'IndexResourceEditor',
  INDEX_RESOURCE_VIEWER = 'IndexResourceViewer',
}

export enum DataPlaneRoles {
  INDEX_DATA_EDITOR = 'IndexDataEditor',
  INDEX_DATA_VIEWER = 'IndexDataViewer',
}

export enum ProjectRoles {
  PROJECT_EDITOR = 'ProjectEditor',
}

export type RbacRoles = ControlPlaneRoles | DataPlaneRoles | ProjectRoles;

export type RoleBinding = {
  role_name: RbacRoles;
};

export enum PrincipalTypes {
  API_KEY = 'apikey',
}

export enum StorageIntegrationProvider {
  S3 = 's3',
  GCS = 'gcs',
  AzureBlob = 'azure_blob',
}

export enum StorageIntegrationStatus {
  VALIDATED = 'Validated',
  INVALID = 'Invalid',
}

export type BaseStorageIntegration = {
  id: string;
  name: string;
  provider: StorageIntegrationProvider;
  status: StorageIntegrationStatus;
};

export type AwsIamRole = {
  role_arn: string;
};

export type S3StorageIntegration = BaseStorageIntegration & {
  provider: StorageIntegrationProvider.S3;
  aws_iam_role: AwsIamRole;
};

export type GcpServiceAccount = {
  key_json: string;
};

export type GcsStorageIntegration = BaseStorageIntegration & {
  provider: StorageIntegrationProvider.GCS;
  gcp_service_account: GcpServiceAccount;
};

export type AzureAppCredentials = {
  tenant_id: string;
  client_id: string;
  client_secret: string;
};

export type AzureBlobStorageIntegration = BaseStorageIntegration & {
  provider: StorageIntegrationProvider.AzureBlob;
  azure_app_credentials: AzureAppCredentials;
};

export type StorageIntegration =
  | S3StorageIntegration
  | GcsStorageIntegration
  | AzureBlobStorageIntegration;

export type Backup = {
  id: string;
  name: string;
  index_id: string;
  project_id: string;
  created_at: string;
  size_bytes?: number;
  status: IndexState;
  cloud: string;
  region: string;
  record_count?: number;
  dimension?: number;
  index_name: string;
};

export enum InferenceModels {
  MULTILINGUAL_E5_LARGE = 'multilingual-e5-large',
}

export type InferenceResponse = {
  model: InferenceModels;
  data: [{values: number[]}];
  usage: {total_tokens: number};
};

export type BulkImportErrorMode = keyof typeof BulkImportErrorModes;

export type BulkImportStatus = 'Pending' | 'InProgress' | 'Completed' | 'Failed' | 'Cancelled';

export interface BulkImport {
  id: string;
  uri: string;
  status: BulkImportStatus;
  createdAt: string;
  finishedAt?: string;
  percentComplete: number;
  recordsImported: number;
  error?: string;
}

interface AwsCmekSpec {
  key_arn: string;
  role_arn: string;
}

export interface CmekKey {
  id: string;
  name: string;
  region: string;
  cloud: 'aws';
  aws?: AwsCmekSpec;
  indexes_using_key?: string[];
}
