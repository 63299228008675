import {type SliceTooltipProps} from '@nivo/line';
import ChartTooltip from '../../ChartTooltip/ChartTooltip';

const LineTooltip = ({slice}: SliceTooltipProps, unit: string) => {
  const {points} = slice;
  const time = points[0].data.x;
  const items = points.map((point) => {
    return {
      color: point.serieColor,
      label: point.serieId as string,
      value: String(point.data.y),
      unit,
    };
  });
  return <ChartTooltip timestamp={new Date(time)} items={items} />;
};

export default LineTooltip;
