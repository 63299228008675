import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {Box, Chip, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import type * as React from 'react';
import {removeInvite} from '../actions/organizationActions';
import {Roles} from '../constants';
import {useDashboardApi} from '../hooks/use-api';
import {
  useIsOwnerOfOrganization,
  useOrgRole,
  useSelectedOrganization,
} from '../selectors/organizations';
import {useIsOwnerOfSelectedProject, useProjectRole} from '../selectors/projects';
import {useUserId} from '../selectors/userSession';
import {useUserInfo} from '../selectors/users';
import {GUTTER} from '../styles/theme';
import {type GlobalProject, type Invite, type TableColumns} from '../types';
import {capitalize} from '../utils/format';
import ButtonWithConfirm from './ButtonWithConfirm';
import EditUserButton from './EditUserButton';

const styles = {
  tableContainer: {
    flexGrow: 1,
  },
  role: {
    display: 'inline',
  },
};

type UserTableProps = {
  userIds: string[];
  invites: Invite[];
  isLoading: boolean;
  project?: GlobalProject;
  handleEditUser: (role: Roles, user: string) => void;
  handleRemoveUser: (user: string) => void;
  handleExit: () => void;
  canLeave: boolean;
};

function UserCellRenderer({
  col,
  userId,
  orgId,
  projectId,
  handleEditUser,
  handleRemoveUser,
  handleExit,
  canLeave,
  groupName,
}: {
  col: number;
  userId: string;
  orgId?: string;
  projectId?: string;
  handleEditUser: (role: Roles, user: string) => void;
  handleRemoveUser: (user: string) => void;
  handleExit: () => void;
  canLeave: boolean;
  groupName: string;
}) {
  const selfId = useUserId();
  const isSelf = selfId === userId;
  const user = useUserInfo(userId);
  const projectRole = useProjectRole(userId, projectId);
  const orgRole = useOrgRole(userId, orgId);
  const label = projectId ? 'Project' : 'Organization';
  const isOrgOwner = useIsOwnerOfOrganization();
  const isProjectOwner = useIsOwnerOfSelectedProject();
  const isOwner = projectId ? isProjectOwner : isOrgOwner;
  const canEdit = isOwner && !isSelf && (!projectId || orgRole === Roles.USER);
  if (col === 0) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography>{`${user?.name || ''} ${isSelf ? ' (You)' : ''}`}</Typography>
        {user?.is_sso && <Chip variant="outlined" size="small" label="SSO" />}
      </Box>
    );
  }
  if (col === 1) {
    return <Typography>{user?.email}</Typography>;
  }
  if (col === 2) {
    return <Typography sx={styles.role}>{capitalize(orgRole)}</Typography>;
  }
  if (col === 3) {
    if (projectRole) {
      return <Typography sx={styles.role}>{capitalize(projectRole || '')}</Typography>;
    }
    return null;
  }
  if (col === 4) {
    if (canEdit) {
      return (
        <>
          <ButtonWithConfirm
            icon={<DeleteOutlinedIcon fontSize="small" />}
            title="Remove Member"
            itemType="Member"
            name={user?.name}
            doDelete={() => handleRemoveUser(user?.firebase_id)}
            action="Remove"
            titleAction="Removal"
            skipCheck
            ariaLabel="remove-user"
          />
          <EditUserButton
            label={label}
            userId={user?.firebase_id}
            handleEditUser={handleEditUser}
            userRole={projectId ? projectRole : orgRole}
          />
        </>
      );
    }
    if (isSelf && canLeave) {
      return (
        <ButtonWithConfirm
          icon={<LogoutOutlinedIcon fontSize="small" />}
          title={`Leave ${label}`}
          itemType={label}
          name={groupName}
          doDelete={handleExit}
          action="Leave"
          titleAction="Exit"
        />
      );
    }
    return null;
  }
  return null;
}

function InviteCellRenderer({
  col,
  invite,
  orgId,
  project,
}: {
  col: number;
  invite: Invite;
  orgId?: string;
  project?: GlobalProject;
}) {
  const {setQueryData: setQueryDataRemove} = useDashboardApi<{
    organizationId: string;
    inviteId: string;
  }>();
  const revokeInvite = () => {
    setQueryDataRemove({
      action: removeInvite,
      data: {
        organizationId: orgId || '',
        inviteId: invite.id,
      },
    });
  };
  if (col === 0) {
    return (
      <Box display="flex" alignItems="center">
        <Typography>{invite.email}</Typography>
        <Chip label="Pending..." variant="outlined" sx={{ml: 1}} />
      </Box>
    );
  }
  if (col === 1) {
    return <Typography sx={{color: 'primary.main'}}>{invite.email}</Typography>;
  }
  if (col === 2) {
    return (
      <Typography sx={styles.role}>
        {capitalize(invite.organization_roles[invite.organization_id].role)}
      </Typography>
    );
  }
  if (col === 3) {
    if (!project) {
      return null;
    }
    return (
      <Typography sx={styles.role}>
        {capitalize(invite.project_roles?.[project.id]?.role || '')}
      </Typography>
    );
  }
  if (col === 4) {
    return (
      <ButtonWithConfirm
        icon={<DeleteOutlinedIcon />}
        title="Revoke Invite"
        titleAction="Revoke"
        itemType="Invite"
        name={invite.email}
        doDelete={revokeInvite}
        action="Revoke"
        skipCheck
        ariaLabel="revoke-invite"
      />
    );
  }
  return null;
}

const UserTable: React.FC<UserTableProps> = ({
  userIds,
  invites,
  isLoading,
  project,
  handleEditUser,
  handleRemoveUser,
  handleExit,
  canLeave,
}) => {
  const invitesData = invites.map((invite: Invite) => ({...invite, pending: true}));
  const selectedOrg = useSelectedOrganization();

  const name = project ? project.name : selectedOrg.name;
  const usersTableCellRenderer = (row: number, col: number) => {
    if (row < userIds.length) {
      return (
        <UserCellRenderer
          col={col}
          userId={userIds[row]}
          orgId={selectedOrg?.id}
          projectId={project?.id}
          canLeave={canLeave}
          handleExit={handleExit}
          handleEditUser={handleEditUser}
          handleRemoveUser={handleRemoveUser}
          groupName={name}
        />
      );
    }
    return (
      <InviteCellRenderer
        col={col}
        invite={invitesData[row - userIds.length]}
        orgId={selectedOrg.id}
        project={project}
      />
    );
  };
  const columns: TableColumns = [
    {name: 'Name', dataType: 'string'},
    {name: 'Email', dataType: 'string'},
    {name: 'Organization Role', dataType: 'string'},
    {name: project ? 'Project Role' : '', dataType: 'string'},
    {name: '', dataType: 'actions'},
  ];
  return (
    <Box sx={styles.tableContainer}>
      <Table
        isLoading={isLoading}
        columns={columns}
        numRows={userIds.length + invitesData.length}
        cellRenderer={usersTableCellRenderer}
        gutter={GUTTER}
      />
    </Box>
  );
};

export default UserTable;
