import {useBooleanState} from '../../../../hooks/utils';
import ProjectCreationGlobal from '../ProjectCreationGlobal/ProjectCreationGlobal';

export const useCreateProjectButton = () => {
  const {val: open, setTrue: setModalOpen, setFalse: closeModal} = useBooleanState();
  const modal = <ProjectCreationGlobal open={open} close={closeModal} />;
  const doCreate = () => {
    setModalOpen();
  };
  return {modal, doCreate};
};
