import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import EmptyState from '../../../../components/layout/EmptyState/EmptyState';
import {PUBLIC_DOCS} from '../../../../constants';
import {useBooleanState} from '../../../../hooks/utils';
import {type PrivateEndpoint} from '../../../../types';
import CreatePrivateEndpointModal from './CreatePrivateEndpointModal/CreatePrivateEndpointModal';
import PrivateEndpointsList from './PrivateEndpointsList/PrivateEndpointsList';
import {useProjectEndpointActions} from './hooks/endpoint-actions';

interface PrivateEndpointsListProps {
  endpoints: PrivateEndpoint[];
  hasError?: boolean;
  loading?: boolean;
}

export default function PrivateEndpointsTab({
  endpoints,
  hasError,
  loading,
}: PrivateEndpointsListProps) {
  const {createEndpoint, deleteEndpoint} = useProjectEndpointActions();

  const {
    val: showCreateModal,
    setTrue: openCreateModal,
    setFalse: hideCreateModal,
  } = useBooleanState();

  if (hasError) {
    return (
      <EmptyState
        heading="Private Endpoints"
        description="Endpoints failed to load. Please refresh the page."
      >
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </EmptyState>
    );
  }

  const emptyStateMarkup = loading ? (
    <Box height="100%" m={4}>
      <LoadingContainer loading />
    </Box>
  ) : (
    <EmptyState
      heading="Private Endpoints"
      description={
        <div data-testid="empty-state-description">
          Set up an endpoint to AWS PrivateLink for secure, private network connectivity to this
          project. Read our{' '}
          <Link href={PUBLIC_DOCS.AWS_PRIVATELINK} target="_blank" rel="noreferrer">
            documentation
          </Link>
          .
        </div>
      }
    >
      <Button variant="contained" onClick={openCreateModal}>
        Add an endpoint
      </Button>
    </EmptyState>
  );

  return (
    <>
      <CreatePrivateEndpointModal
        open={showCreateModal}
        onCreate={createEndpoint}
        onClose={hideCreateModal}
      />
      {endpoints.length ? (
        <PrivateEndpointsList
          endpoints={endpoints}
          loading={loading}
          deleteEndpoint={deleteEndpoint}
          openCreateModal={openCreateModal}
        />
      ) : (
        emptyStateMarkup
      )}
    </>
  );
}
