import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {CreateIndexParam} from '../../../../constants';
import {useNavigateToCreateIndexGlobal} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {type Backup, type GlobalProject, type V4IndexInfoResponse} from '../../../../types';
import DeleteBackupsConfirmation from '../DeleteBackupsConfirmation/DeleteBackupsConfirmation';

type BackupsDetailActionsProps = {
  backup: Backup;
  project: GlobalProject;
  sourceIndex?: V4IndexInfoResponse;
};

function BackupsDetailActions({backup, project, sourceIndex}: BackupsDetailActionsProps) {
  const {val: deleteIsOpen, setTrue: openDelete, setFalse: closeDelete} = useBooleanState();
  const {go: goToRestore} = useNavigateToCreateIndexGlobal(project.organization_id, project.id, {
    [CreateIndexParam.FROM_BACKUP]: backup.id,
    [CreateIndexParam.CLOUD]: backup.cloud,
    [CreateIndexParam.REGION]: backup.region,
    [CreateIndexParam.NAME]: backup.name,
    [CreateIndexParam.DIMENSIONS]: String(backup.dimension),
    [CreateIndexParam.METRIC]: sourceIndex?.metric,
  });
  return (
    <>
      <SelectorMenuButton
        id={`backup-details-actions-${backup.id}`}
        items={[
          {
            id: 'restore',
            name: 'Restore to index',
            icon: <TableRowsOutlinedIcon fontSize="small" />,
            onClick: goToRestore,
          },
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon fontSize="small" color="error" />,
            onClick: openDelete,
            color: 'error',
          },
        ]}
      />
      <DeleteBackupsConfirmation
        open={deleteIsOpen}
        close={closeDelete}
        backups={[backup]}
        project={project}
      />
    </>
  );
}

export default BackupsDetailActions;
