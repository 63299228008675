import {zodResolver} from '@hookform/resolvers/zod';
import {Box, FormControlLabel, Grid, Paper, Switch, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import InlineCode from '@pinecone-experience/timber/InlineCode/index';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {createService, createServiceFromDataset} from '../../../../actions/serviceActions';
import {type Vector} from '../../../../api/dataPlaneApi';
import NavigateWithParams from '../../../../components/Navigation/NavigateWithParams';
import StyledJsonView from '../../../../components/StyledJsonView';
import MetadataTable from '../../../../components/datasets/MetadataTable/MetadataTable';
import {datasets} from '../../../../components/datasets/data/datasets';
import Page from '../../../../components/layout/Page/Page';
import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import ModelCard from '../../../../components/models/ModelCard/ModelCard';
import {DEFAULT_INDEX_SETTINGS, Plans} from '../../../../constants';
import {useNavigateToDatasetGalleryPageGlobal} from '../../../../hooks/navigation';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationPlan} from '../../../../selectors/organizations';
import {useSelectedDatasetId, useSelectedOrganizationId} from '../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {type GlobalProject} from '../../../../types';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../../../../utils/tracking';
import IndexCreationFooter from '../../IndexCreationPage/IndexCreationFooter/IndexCreationFooter';
import {
  type IndexSchema,
  ServerlessIndexSchema,
  type ServerlessIndexSchemaType,
} from '../../IndexCreationPage/utilities/schema';
import VectorData from '../../IndexDetailPage/IndexExplorer/VectorCard/VectorData/VectorData';
import IndexConfigurationTable from '../IndexConfigurationTable/IndexConfigurationTable';

const styles = {
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  rightColumn: {
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',
  },
};

function CreateIndexFromDatasetPage() {
  const orgId = useSelectedOrganizationId();
  const plan = useSelectedOrganizationPlan();
  const project = useSelectedGlobalProject();
  const selectedDatasetId = useSelectedDatasetId();
  const selectedDataset = datasets[selectedDatasetId];
  const datasetInformation = selectedDataset?.datasetInformation;
  const collectionToServerlessOp = 'collectionName' in datasetInformation;
  const {path: datasetPath} = useNavigateToDatasetGalleryPageGlobal(orgId, project?.id);
  const [viewAsJson, setViewAsJson] = useState(false);
  const onToggleViewJson = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewAsJson(event.target.checked);
  };

  const {handleSubmit, watch} = useForm<ServerlessIndexSchemaType>({
    defaultValues: {
      name: datasetInformation.indexName,
      dimensions: datasetInformation.dimension,
      metric: datasetInformation.metric as ServerlessIndexSchemaType['metric'],
      provider: DEFAULT_INDEX_SETTINGS.provider,
      region: DEFAULT_INDEX_SETTINGS.region,
      source_collection: collectionToServerlessOp ? datasetInformation.collectionName : undefined,
    },
    resolver: zodResolver(ServerlessIndexSchema),
  });

  const {setQueryData: setUpsertDatasetQueryData} = useControllerApi<
    IndexSchema & {
      from_collection?: string;
      project: GlobalProject;
      region: string;
      isServerless: boolean;
      dataset?: Vector[];
    }
  >(project);
  const {setQueryData: setCollectionDatasetQueryData} = useControllerApi<
    ServerlessIndexSchemaType & {project: GlobalProject}
  >(project);
  const createIndexFromDatasetHandler = (formData: ServerlessIndexSchemaType) => {
    sendToHeap(HEAP_TRACKING_EVENTS.INDEX_CREATED, {
      indexType: 'Serverless',
    });
    sendToHeap(HEAP_TRACKING_EVENTS.DATASET_INDEX_CREATED, {
      dataset: formData.name,
    });
    if (collectionToServerlessOp) {
      setCollectionDatasetQueryData({
        action: createServiceFromDataset,
        data: {
          ...formData,
          project,
        },
      });
    } else {
      setUpsertDatasetQueryData({
        action: createService,
        data: {
          ...formData,
          project,
          dataset: datasetInformation.vectorData,
          from_collection: undefined,
          isServerless: true,
          engineType: 'approximated',
          pods: 1,
          replicas: 1,
          podType: 'nano',
          podSize: 'x1',
          deletionProtection: false,
          environment: '---',
        },
      });
    }
  };

  if (!selectedDataset) {
    return <NavigateWithParams to={datasetPath} />;
  }

  return (
    <form
      onSubmit={handleSubmit(createIndexFromDatasetHandler)}
      id="create-index-from-dataset-form"
    >
      <Page
        flexChildren={false}
        header={
          <PageHeader
            title={datasetInformation.title}
            description={datasetInformation.subtitle}
            breadcrumb={{
              text: 'Go back',
            }}
          />
        }
        // TODO: (@zadamjee) clean up the footer props
        footer={
          <IndexCreationFooter
            selectedIndex={{
              isServerless: true,
              requiresSubscriptionUpgrade:
                datasetInformation.upgradeRequired && plan === Plans.FREE,
              from_collection: collectionToServerlessOp
                ? datasetInformation.collectionName
                : undefined,
              pods: 0,
              engineType: 'approximated',
              environment: '---',
              replicas: 0,
              podType: 's1',
              podSize: 'x1',
              deletionProtection: false,
              ...watch(),
            }}
            isValid
            upsertCost={'writeUnits' in datasetInformation ? datasetInformation.writeUnits : 0}
          />
        }
      >
        <Grid container spacing={6}>
          <Grid item xs={8} sx={styles.leftColumn}>
            <Box>
              <Typography fontWeight="bold">About the data</Typography>
              <Typography mb={2}>{datasetInformation.description}</Typography>
              <MetadataTable dataset={selectedDataset} />
            </Box>
            <Box>
              <Typography fontWeight="bold">Embedding model</Typography>
              <Typography mb={2}>
                {`Indexes are configured to work with a specific model by setting the same dimension and metric. In this example, we will use the ${selectedDataset.embeddingModel.name} for semantic search.`}
              </Typography>
              <ModelCard model={selectedDataset.embeddingModel} />
            </Box>
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Typography fontWeight="bold">Record upload format</Typography>
                <FormControlLabel
                  label="View as JSON"
                  labelPlacement="start"
                  control={<Switch value={viewAsJson} onChange={onToggleViewJson} />}
                />
              </Box>
              <Typography mb={2}>
                Each record contains an <InlineCode text="id" />, <InlineCode text="values" />, and
                optional <InlineCode text="metadata" />. The embedding model generates the values
                from the document text.
              </Typography>
              {viewAsJson ? (
                <StyledJsonView data={selectedDataset.exampleVectorData[0] as {}} />
              ) : (
                <Paper sx={{p: 2}}>
                  <VectorData vector={selectedDataset.exampleVectorData[0]} />
                </Paper>
              )}
            </Box>
          </Grid>
          <Grid item xs={4} sx={styles.rightColumn}>
            <IndexConfigurationTable
              datasetInformation={datasetInformation}
              embeddingModel={selectedDataset.embeddingModel}
              indexInformation={DEFAULT_INDEX_SETTINGS}
              writeUnits={'writeUnits' in datasetInformation ? datasetInformation.writeUnits : 0}
            />

            {collectionToServerlessOp && (
              <>
                <Box my={3} />
                <Alert
                  title="Long running operation"
                  description="Creating an index from this dataset may take up to 30 minutes."
                  severity="warning"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Page>
    </form>
  );
}

export default CreateIndexFromDatasetPage;
