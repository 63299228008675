import Box from '@mui/material/Box';
import {Route, Routes} from 'react-router-dom';
import {uploadAssistantFiles} from '../../../../actions/assistantFilesActions';
import {AssistantPagePanel} from '../../../../constants';
import {useAssistantDataApi} from '../../../../hooks/use-api';
import {useSelectedGlobalProjectId} from '../../../../selectors/params';
import {type Assistant} from '../../../../types';
import {type DrawerControls} from '../AssistantDrawerControls/AssistantDrawerControls';
import ConnectPanel from './ConnectPanel/ConnectPanel';
import DrawerPanel from './DrawerPanel/DrawerPanel';
import FilesPanel from './FilesPanel/FilesPanel';
import SettingsPanel from './SettingsPanel/SettingsPanel';

interface DrawerProps {
  assistant: Assistant;
  controls: DrawerControls;
}

export const DRAWER_WIDTH = 320;

const styles = {
  root: {
    pb: 2,
    px: 3,
    width: DRAWER_WIDTH,
    backgroundColor: 'background.paper',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default function AssistantDrawer({assistant, controls}: DrawerProps) {
  const projectId = useSelectedGlobalProjectId();
  const {setQueryData} = useAssistantDataApi<{assistantName: string; files: File[]}>(projectId);

  const importFiles = (filesToImport: File[]) => {
    setQueryData({
      action: uploadAssistantFiles,
      data: {assistantName: assistant.name, files: filesToImport},
    });
  };

  return (
    <Box sx={styles.root}>
      <Routes>
        <Route
          path={AssistantPagePanel.FILES}
          element={
            <DrawerPanel panel={AssistantPagePanel.FILES} controls={controls}>
              <FilesPanel assistantName={assistant.name} importFiles={importFiles} />
            </DrawerPanel>
          }
        />
        <Route
          path={AssistantPagePanel.CONNECT}
          element={
            <DrawerPanel panel={AssistantPagePanel.CONNECT} controls={controls}>
              <ConnectPanel assistantName={assistant.name} />
            </DrawerPanel>
          }
        />
        <Route
          path="*"
          element={
            <DrawerPanel panel={AssistantPagePanel.SETTINGS} controls={controls}>
              <SettingsPanel projectId={projectId} assistant={assistant} />
            </DrawerPanel>
          }
        />
      </Routes>
    </Box>
  );
}
