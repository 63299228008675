import {useCallback, useEffect, useMemo, useState} from 'react';
import {IndexState} from '../../constants';
import {usePollCollectionStatus} from '../../hooks/collections';
import {useCreateIndexHandler, useIndexStatusBox} from '../../hooks/indexes';
import {indexNameCharacterLimit} from '../../pages/indexes/IndexCreationPage/utilities/schema';
import {useCollectionStatusByGlobalProjectId} from '../../selectors/collections';
import {useServiceByGlobalProject} from '../../selectors/services';
import {type GlobalProject} from '../../types';
import {generateRandomName} from '../../utils/random';

export enum Stage {
  COLLECTION = 'collection',
  MIGRATION = 'migration',
  DONE = 'done',
}

const COLLECTION_SUFFIX = 'archive';

const getName = (name: string, suffix: string, maxLength = indexNameCharacterLimit) => {
  const fullName = `${name}-${suffix}`;
  return fullName.slice(-1 * maxLength);
};

export function useStage(
  oldIndexName: string,
  project: GlobalProject,
  shouldCreatePod: boolean,
  newIndexName: string,
) {
  const indexInfo = useServiceByGlobalProject(project.id, oldIndexName);
  const collectionName = useMemo(() => {
    return getName(oldIndexName, `${COLLECTION_SUFFIX}-${generateRandomName(4)}`);
  }, [oldIndexName]);
  const [stage, setStage] = useState<Stage>(Stage.COLLECTION);
  const collectionStatus = useCollectionStatusByGlobalProjectId(project?.id, collectionName);
  const newIndexInfo = useServiceByGlobalProject(project.id, newIndexName);

  const createIndexHandler = useCreateIndexHandler(project, /* isServerless= */ true);
  const doCreateIndex = useCallback(() => {
    createIndexHandler({
      name: newIndexName,
      dimensions: Number(indexInfo?.dimension),
      engineType: 'approximated',
      metric: indexInfo?.metric as 'cosine',
      provider: 'aws',
      environment: 'prod-aws-4',
      deletionProtection: false,
      // Below here is ignored by the handler when serverless=true
      podType: 'p1',
      podSize: 'x1',
      pods: 1,
      replicas: 1,
      from_collection: collectionName,
    });
  }, [indexInfo, createIndexHandler, newIndexName, collectionName]);

  useEffect(() => {
    if (stage !== Stage.COLLECTION || collectionStatus?.status !== IndexState.READY) {
      return;
    }
    if (!shouldCreatePod) {
      setStage(Stage.DONE);
      return;
    }
    doCreateIndex();
    setStage(Stage.MIGRATION);
  }, [collectionStatus?.status, stage, shouldCreatePod, doCreateIndex]);
  useEffect(() => {
    if (stage !== Stage.MIGRATION || newIndexInfo?.status.state !== IndexState.READY) {
      return;
    }
    setStage(Stage.DONE);
  }, [setStage, stage, newIndexInfo?.status]);
  useIndexStatusBox(newIndexName);
  usePollCollectionStatus(collectionName, project, stage === Stage.COLLECTION);
  return {stage, newIndexName, collectionName};
}
