import {useMemo} from 'react';
import {generatePath, useLocation, useMatch, useNavigate} from 'react-router-dom';
import {
  type AssistantPagePanel,
  CreateIndexParam,
  CreateIndexTabs,
  OrgSettingsPage,
  OrgSupportPage,
  ProjectPage,
} from '../constants';
import {useServerlessEnvironments} from '../selectors/environments';
import {useIsSelectedOrganizationSubscribed} from '../selectors/organizations';
import {useSelectedOrganizationId} from '../selectors/params';
import {useDefaultOrgId, useDefaultProjectId} from './defaults';
import {Flags, useFlag} from './flags';

const getOrgPath = (orgId: string) => `/organizations/${orgId}`;

export const getGlobalProjectPath = (orgId: string, projectId: string) => {
  return `${getOrgPath(orgId)}/projects/${projectId || ''}`;
};

export const useNavigateToParent = () => {
  const navigate = useNavigate();
  return () => navigate('..');
};

export const getOrgSettingsPath = (orgId: string) => `${getOrgPath(orgId)}/settings`;
export const getOrgBillingPlansPath = (orgId: string = '-') =>
  `${getOrgPath(orgId)}/settings/${OrgSettingsPage.BILLING}/plans`;
export const getOrgSupportPath = (orgId: string = '-') =>
  `${getOrgPath(orgId)}/settings/${OrgSettingsPage.SUPPORT}`;

export const useNavigateToCreateOrganization = () => {
  const navigate = useNavigate();
  const path = '/organizations/create-organization';
  return {
    path,
    go: () => navigate(path),
  };
};

export const useNavigateToOrg = () => {
  const navigate = useNavigate();
  const getPath = (orgId: string = '-') => getOrgPath(orgId);
  const go = (orgId: string = '-') => navigate(getPath(orgId));
  return {getPath, go};
};

export const useNavigateToSelectProject = (orgId: string) => {
  const navigate = useNavigate();
  const path = `${getOrgPath(orgId)}/projects`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToOrgSettingsPage = (orgId: string) => {
  const navigate = useNavigate();
  return (settingsPage: OrgSettingsPage) =>
    navigate(`${getOrgSettingsPath(orgId)}/${settingsPage}`);
};

export const useNavigateToOrgSupportPages = (orgId = '-') => {
  const navigate = useNavigate();
  return (supportPage: OrgSupportPage) => navigate(`${getOrgSupportPath(orgId)}/${supportPage}`);
};

export const useNavigateToBilling = (orgId = '-') => {
  const navigate = useNavigate();
  const path = `${getOrgSettingsPath(orgId)}/${OrgSettingsPage.BILLING}`;
  const go = () => navigate(path);
  return {go, path};
};

export const useNavigateToUsage = (orgId?: string) => {
  if (!orgId) {
    throw new Error('Org ID required');
  }
  const navigate = useNavigate();
  const path = `${getOrgSettingsPath(orgId)}/${OrgSettingsPage.USAGE}`;
  const go = () => navigate(path);
  return {go, path};
};

export const useNavigateToOrgMembers = (orgId?: string) => {
  const navigate = useNavigate();
  if (!orgId) {
    throw new Error('Org id required');
  }
  const path = `${getOrgSettingsPath(orgId)}/${OrgSettingsPage.MEMBERS}`;
  const go = () => navigate(path);
  return {go, path};
};

export const useNavigateToBillingPlans = (orgId: string) => {
  const navigate = useNavigate();
  return () => navigate(`${getOrgSettingsPath(orgId)}/${OrgSettingsPage.BILLING}/plans`);
};

export const useNavigateToUsers = (orgId: string) => {
  const navigate = useNavigate();
  return () => navigate(`${getOrgSettingsPath(orgId)}/users`);
};

export const useNavigateToGlobalProject = () => {
  const navigate = useNavigate();
  const getPath = (orgId: string, projectId: string) => getGlobalProjectPath(orgId, projectId);
  const go = (orgId: string, projectId: string) => navigate(getPath(orgId, projectId));
  return {getPath, go};
};

export const useNavigateToIndexListGlobal = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const path = `${getGlobalProjectPath(orgId, projectId)}/${projectId ? 'indexes' : ''}`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToIndexListFromOrg = () => {
  const navigate = useNavigate();
  const getPath = (orgId: string) => `${getOrgPath(orgId)}/projects/-/indexes`;
  const go = (orgId: string) => navigate(getPath(orgId));
  return {getPath, go};
};

export const useNavigateToIndexPageGlobal = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = (serviceName: string, tab?: string) =>
    `${getGlobalProjectPath(orgId, projectId)}/indexes/${serviceName}/${tab || ''}`;
  const go = (serviceName: string, tab?: string) => navigate(getPath(serviceName, tab));
  return {getPath, go};
};

export const useNavigateToApiKeys = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const path = `${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.KEYS}`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToAssistants = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const path = `${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.ASSISTANTS}`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToSupport = (orgId: string) => {
  const shouldShowSupportPage = useFlag(Flags.ENABLE_IN_CONSOLE_SUPPORT);
  const navigate = useNavigate();
  if (!shouldShowSupportPage) {
    return {path: '', go: () => window.open('https://support.pinecone.io', '_blank')};
  }
  const path = getOrgSupportPath(orgId);
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToSupportPlans = (orgId: string) => {
  const navigate = useNavigate();
  const path = `${getOrgSupportPath(orgId)}/${OrgSupportPage.SUPPORT_PLANS}`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToSupportTicket = (orgId: string) => {
  const navigate = useNavigate();
  const path = `${getOrgSupportPath(orgId)}/${OrgSupportPage.SUPPORT_TICKETS}`;
  const go = () => navigate(path);
  return {path, go};
};

export const useNavigateToAssistant = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = (assistantName: string, panel?: AssistantPagePanel) =>
    `${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.ASSISTANTS}/${assistantName}/${
      panel || ''
    }`;
  const go = (assistantName: string, panel?: AssistantPagePanel) =>
    navigate(getPath(assistantName, panel));
  return {getPath, go};
};

export const useNavigateToAssistantFile = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const {getPath: getAssistantPath} = useNavigateToAssistant(orgId, projectId);
  const getPath = (assistantName: string, fileId: string) =>
    `${getAssistantPath(assistantName)}files/${fileId}`;

  const go = (assistantName: string, fileId: string) => navigate(getPath(assistantName, fileId));
  return {getPath, go};
};

export const useNavigateToCollectionPageGlobal = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = () => `${getGlobalProjectPath(orgId, projectId)}/backups/collections`;
  const go = () => navigate(getPath());
  return {getPath, go};
};

export const useNavigateToGetStartedPage = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = (subPage?: string) =>
    `${getGlobalProjectPath(orgId, projectId)}/get-started/${subPage ?? `/${subPage}`}`;
  const go = (subPage?: string) => navigate(getPath(subPage));
  return {getPath, go};
};

/**
 * Provides navigation utilities to navigate to the project page if projectId is given
 * Otherwise navigates to the project list page
 */
export const useNavigateToProjectPageGlobal = (orgId: string) => {
  const navigate = useNavigate();
  // if empty projectId is given, go to the project list page
  const getPath = (projectId: string, page: ProjectPage) =>
    projectId
      ? `${getGlobalProjectPath(orgId, projectId)}/${page}`
      : getGlobalProjectPath(orgId, projectId);
  return {
    go: (projectId: string, page: ProjectPage) => navigate(getPath(projectId, page)),
    getPath,
  };
};

export const useNavigateToProjectPageForSelectedOrg = () => {
  const orgId = useSelectedOrganizationId();
  return useNavigateToProjectPageGlobal(orgId);
};

export const useNavigateToKeysPageGlobal = (orgId: string, projectId: string) => {
  const {go, getPath} = useNavigateToProjectPageGlobal(orgId);
  return {
    go: () => go(projectId, ProjectPage.KEYS),
    path: getPath(projectId, ProjectPage.KEYS),
  };
};

export const useNavigateToDatasetGalleryPageGlobal = (orgId: string, projectId: string) => {
  const {getPath} = useNavigateToProjectPageGlobal(orgId);
  const navigate = useNavigate();
  const path = getPath(projectId, ProjectPage.DATASET_GALLERY);
  return {path, go: () => navigate(path)};
};

export const useNavigateToCreateIndexFromDatasetPageGlobal = (
  orgId: string,
  projectId: string,
  datasetId: string,
) => {
  const {getPath} = useNavigateToProjectPageGlobal(orgId);
  const navigate = useNavigate();
  const path = `${getPath(projectId, ProjectPage.DATASET_GALLERY)}/${datasetId}`;
  return {path, go: () => navigate(path)};
};

export const useNavigateToCreateIndexGlobal = (
  orgId: string,
  projectId: string,
  params?: Partial<Record<CreateIndexParam, string>>,
) => {
  const {getPath} = useNavigateToProjectPageGlobal(orgId);
  const navigate = useNavigate();
  const serverlessEnvs = useServerlessEnvironments(orgId);
  const viewerHasSubscription = useIsSelectedOrganizationSubscribed();
  let path = getPath(projectId, ProjectPage.CREATE_INDEX);

  if (params) {
    const paramsString = new URLSearchParams(params).toString();
    const fromPodCollection =
      params[CreateIndexParam.ENVIRONMENT] &&
      !serverlessEnvs.find((env) => env.name === params[CreateIndexParam.ENVIRONMENT]);

    if (fromPodCollection && viewerHasSubscription) {
      path = `${path}/${CreateIndexTabs.PODS}?${paramsString}`;
    } else {
      path = `${path}/${CreateIndexTabs.SERVERLESS}?${paramsString}`;
    }
  }
  return {
    go: () => navigate(path),
    path,
  };
};

export const useNavigateToCreateStarterIndexGlobal = (orgId: string, projectId: string) => {
  const {path: createIndexPath} = useNavigateToCreateIndexGlobal(orgId, projectId);
  const starterPath = `${createIndexPath}/${CreateIndexTabs.STARTER}`;
  const navigate = useNavigate();
  return {
    go: () => navigate(starterPath),
    path: starterPath,
  };
};

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const getQueryParam = (key: string) => {
    return searchParams.get(key) || undefined;
  };

  const setQueryParams = (params: Record<string, string | null>, replace = true) => {
    const newSearchParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === '') {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });
    navigate({search: newSearchParams.toString()}, {replace});
  };

  return {getQueryParam, setQueryParams};
};

/**
 * Navigates to the default organization's landing page. This may either be the page of the default
 * project or the create project page, depending on whether the default org has any projects.
 */
export const useNavigateToDefaultOrg = () => {
  const navigate = useNavigate();
  const defaultOrgId = useDefaultOrgId();
  const defaultProjectId = useDefaultProjectId(defaultOrgId);
  const path = defaultProjectId
    ? getGlobalProjectPath(defaultOrgId, defaultProjectId)
    : getOrgPath(defaultOrgId);
  const go = () => navigate(path);
  return {go, path};
};

/**
 * Navigates to the same path, but with the default organization id substituted in for the selected
 * organization id.
 */
export const useSubstituteDefaultOrg = () => {
  const navigate = useNavigate();
  const match = useMatch('/organizations/:orgId/*');
  const defaultOrgId = useDefaultOrgId();
  const path =
    match && defaultOrgId
      ? generatePath(match.pattern.path, {...match.params, orgId: defaultOrgId})
      : '/organizations';
  const go = () => navigate(path);
  return {go, path};
};

export const useSubstituteProject = (projectId: string) => {
  const navigate = useNavigate();
  const match = useMatch('/organizations/:orgId/projects/:projectId/*');
  const selectedOrgId = useSelectedOrganizationId();
  const path =
    match && projectId
      ? generatePath(match.pattern.path, {...match.params, projectId})
      : getOrgPath(selectedOrgId);
  const go = () => navigate(path);
  return {go, path};
};

/**
 * Navigates to the same path, but with the default project id (for the selected org) substituted
 * in for the selected project id.
 */
export const useSubstituteDefaultProject = () => {
  const selectedOrgId = useSelectedOrganizationId();
  const defaultProjectId = useDefaultProjectId(selectedOrgId);
  return useSubstituteProject(defaultProjectId);
};

export const useNavigateToBackupDetailsPage = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = (indexId: string) =>
    `${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.BACKUPS}/index/${indexId}`;
  const go = (indexId: string) => navigate(getPath(indexId));
  return {getPath, go};
};

export const useNavigateToModelsPage = (orgId: string, projectId: string) => {
  const navigate = useNavigate();
  const getPath = () => `${getGlobalProjectPath(orgId, projectId)}/${ProjectPage.MODELS}`;
  const go = () => navigate(getPath());
  return {getPath, go};
};
