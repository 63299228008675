import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type Environment} from '../types';

export const getOrganizationEnvironmentsRequest = createAction<{organizationId: string}>(
  'ORGANIZATION_ENVIRONMENTS_REQUEST',
);
export const getOrganizationEnvironmentsSuccess = createAction<{
  organizationId: string;
  environments: Environment[];
}>('ORGANIZATION_ENVIRONMENTS_SUCCESS');
export const getOrganizationEnvironmentsFailure = createAction<{
  organizationId: string;
  error: Error;
}>('ORGANIZATION_ENVIRONMENTS_FAILURE');

export function getOrganizationEnvironments(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getOrganizationEnvironmentsRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .getOrganizationEnvironments(data.organizationId)
      .then((res) => {
        dispatch(
          getOrganizationEnvironmentsSuccess({
            organizationId: data.organizationId,
            environments: res.environments,
          }),
        );
      })
      .catch((err: Error) => {
        dispatch(
          getOrganizationEnvironmentsFailure({organizationId: data.organizationId, error: err}),
        );
      });
  };
}
