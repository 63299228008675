import {Box, Button, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {dismissBlockingError} from '../../../../../../actions/globalErrorActions';
import {unpackErrorMessage} from './utilities/MarketplaceError.utils';

type AuthErrorProps = {
  errorType: string;
  errorMessage: string;
  isAuthenticated?: boolean;
};

function MarketplaceError({
  errorType = 'authentication error',
  errorMessage = 'An error has been raised during authentication.',
  isAuthenticated = false,
}: AuthErrorProps) {
  const dispatch = useDispatch();

  const dismiss = () => {
    dispatch(dismissBlockingError());
  };

  const {title, message} = unpackErrorMessage(errorMessage);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{backgroundColor: 'background.default', color: 'text.primary'}}
    >
      <Box
        width={{xs: '100%', md: '33.333%'}} // Full width on small screens, 1/3 on medium and up
        textAlign="center"
      >
        <Typography variant="h4" title={errorType}>
          {title}
        </Typography>
        {message}
        {isAuthenticated ? (
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={dismiss}>Continue to Console</Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default MarketplaceError;
