import {type Integration as PublicIntegration} from '@pinecone-experience/integrations';
import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/integrationActions';
import {type ApiKey, type Integration} from '../types';
import {type VercelOAuthConnection} from '../types/vercel';
import {type AsyncDataState, initAsyncDataState, isOrgAction} from './utils';

type ConnectState = {
  organizationId: string;
  globalProjectId: string;
  apiKey: ApiKey;
};

type VercelState = {
  success?: boolean;
  connection?: VercelOAuthConnection;
};

type IntegrationState = {
  organizations: {
    [organizationId: string]: AsyncDataState<Integration[]>;
  };
  info: {
    [integrationSlug: string]: AsyncDataState<PublicIntegration>;
  };
  logos: {
    [integrationSlug: string]: string;
  };
  connect: AsyncDataState<ConnectState>;
  vercel: AsyncDataState<VercelState>;
};

const initialState: IntegrationState = {
  organizations: {},
  info: {},
  logos: {},
  connect: initAsyncDataState<ConnectState>(),
  vercel: initAsyncDataState<VercelState>(),
};

const INFO_ACTIONS = [
  actions.getIntegrationInfoRequest.type,
  actions.getIntegrationInfoSuccess.type,
  actions.getIntegrationInfoFailure.type,
];

const initialInfoState = initAsyncDataState<PublicIntegration>();
const infoReducer = createReducer(initialInfoState, (builder) => {
  builder
    .addCase(actions.getIntegrationInfoRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.getIntegrationInfoSuccess, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(actions.getIntegrationInfoFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});

const initialOrganizationState = initAsyncDataState<Integration[]>();
const organizationReducer = createReducer(initialOrganizationState, (builder) => {
  builder
    .addCase(actions.listIntegrationsRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.listIntegrationsSuccess, (state, action) => {
      state.loading = false;
      state.data = action.payload.integrations;
    })
    .addCase(actions.listIntegrationsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.createIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.createIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.data = [...(state.data || []), action.payload.integration];
    })
    .addCase(actions.createIntegrationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.editIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.editIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.data = (state.data || []).map((integration) =>
        integration.id === action.payload.integration.id ? action.payload.integration : integration,
      );
    })
    .addCase(actions.editIntegrationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.deleteIntegrationRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.deleteIntegrationSuccess, (state, action) => {
      state.loading = false;
      state.data = (state.data || []).filter(
        (integration) => integration.id !== action.payload.integrationId,
      );
    });
});

const integrationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.connectProjectRequest, (state) => {
      state.connect.loading = true;
    })
    .addCase(actions.connectProjectSuccess, (state, action) => {
      state.connect.loading = false;
      state.connect.data = action.payload;
    })
    .addCase(actions.connectProjectFailure, (state, action) => {
      state.connect.loading = false;
      state.connect.error = action.payload.error;
    })
    .addCase(actions.connectProjectReset, (state) => {
      state.connect.loading = false;
      state.connect.error = undefined;
      state.connect.data = undefined;
    })
    .addCase(actions.createVercelIntegrationRequest, (state) => {
      state.vercel.loading = true;
    })
    .addCase(actions.createVercelIntegrationSuccess, (state, action) => {
      state.vercel.loading = false;
      state.vercel.data = action.payload;
    })
    .addCase(actions.createVercelIntegrationError, (state, action) => {
      state.vercel.loading = false;
      state.vercel.error = action.payload.message;
    })
    .addCase(actions.createVercelOAuthConnectionRequest, (state) => {
      state.vercel.loading = true;
    })
    .addCase(actions.createVercelOAuthConnectionSuccess, (state, action) => {
      state.vercel.loading = false;
      state.vercel.data = action.payload;
    })
    .addCase(actions.createVercelOAuthConnectionError, (state, action) => {
      state.vercel.loading = false;
      state.vercel.error = action.payload.message;
    })
    .addCase(actions.connectOrganizationOAuthRequest, (state) => {
      state.vercel.loading = true;
    })
    .addCase(actions.connectOrganizationOAuthSuccess, (state, action) => {
      state.vercel.loading = false;
      state.vercel.data = {
        success: state.vercel.data?.success,
        connection: {
          ...(state.vercel.data?.connection as VercelOAuthConnection),
          ...action.payload,
        },
      };
    })
    .addCase(actions.connectOrganizationOAuthError, (state, action) => {
      state.vercel.loading = false;
      state.vercel.error = action.payload.message;
    })
    .addCase(actions.connectIndexVercelRequest, (state) => {
      state.vercel.loading = true;
    })
    .addCase(actions.connectIndexVercelSuccess, (state, action) => {
      state.vercel.loading = false;
      state.vercel.data = {
        ...state.vercel.data,
        ...action.payload,
      };
    })
    .addCase(actions.connectIndexVercelError, (state, action) => {
      state.vercel.loading = false;
      state.vercel.error = action.payload.message;
    })
    .addCase(actions.updateIntegrationLogo, (state, action) => {
      state.logos[action.payload.integrationSlug] = action.payload.url;
    })
    .addMatcher(
      (action) => INFO_ACTIONS.includes(action.type),
      (state, action) => {
        state.info[action.payload.slug] = infoReducer(state.info[action.payload.slug], action);
      },
    )
    .addMatcher(isOrgAction, (state, action) => {
      state.organizations[action.payload.organizationId] = organizationReducer(
        state.organizations[action.payload.organizationId],
        action,
      );
    });
});

export default integrationReducer;
