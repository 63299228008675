import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {LocalStorageKeys, OrgSupportPage, PINECONE_URLS} from '../../../../../constants';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {getOrgSupportPath} from '../../../../../hooks/navigation';
import {useBooleanState} from '../../../../../hooks/utils';
import {useIsOwnerOfOrganization} from '../../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../../selectors/params';
import {localStorageGet, localStorageSet} from '../../../../../utils/storage';

const styles = {
  description: {
    minWidth: 400,
    width: 640,
  },
  textRow: {
    py: 1,
  },
};

function SupportTrialModal() {
  const segment = useFlag(Flags.SUPPORT_TRIAL_START_NOTIFICATION);
  const isOrgOwner = useIsOwnerOfOrganization();
  const shouldShow = Boolean(segment) && isOrgOwner;
  const orgId = useSelectedOrganizationId();
  const orgSupportPath = getOrgSupportPath(orgId);
  const localStorageKey = `${LocalStorageKeys.VIEWED_SUPPORT_NOTIFICATION}_${orgId}`;
  const hasSeen = localStorageGet(localStorageKey);
  const {val: isOpen, setFalse: close, setTrue: open} = useBooleanState(!hasSeen && shouldShow);

  const onClose = () => {
    localStorageSet(localStorageKey, 'true');
    close();
  };

  useEffect(() => {
    if (shouldShow && !hasSeen) {
      open();
    }
  }, [hasSeen, open, shouldShow]);

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <ModalHeader close={onClose} title="Important Updates" />
      <Box sx={styles.description}>
        <Box sx={styles.textRow}>
          <Typography>
            To give our customers more flexibility and better address their company-specific needs,
            going forward we will have four levels of support. New support pricing will go into
            effect on December 1. Unless you decide to change your support tier sooner, you will
            receive our {segment} support until that time.
          </Typography>
        </Box>
        <Box sx={styles.textRow}>
          <Typography>
            Please see our updated support structure{' '}
            <Link to={`${orgSupportPath}/${OrgSupportPage.SUPPORT_PLANS}`} onClick={onClose}>
              here on the console
            </Link>{' '}
            and{' '}
            <a href={PINECONE_URLS.CONTACT_SALES} target="_blank" rel="noreferrer">
              contact us
            </a>{' '}
            if you have any questions.
          </Typography>
        </Box>
        <Box sx={styles.textRow}>
          <Typography>-Team Pinecone</Typography>
        </Box>
      </Box>
    </BaseModal>
  );
}

export default SupportTrialModal;
