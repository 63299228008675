import {Add} from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {Backdrop, Box, Button, Divider, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import {useEffect, useState} from 'react';
import {type UseFormSetValue} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import ModalForm from '../../../../components/ModalForm';
import Image from '../../../../components/Utilities/Image/Image';
import {CreateIndexParam} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import type {EmbeddingModel} from '../../../../types';
import {fetchEmbeddingModels} from '../../../../utils/sanity/sanityUtils';
import {type IndexSchema} from '../utilities/schema';
import ModelSuggestionForm from './ModelSuggestionForm/ModelSuggestionForm';

type SetupWithModelButtonProps = {
  setValue: UseFormSetValue<IndexSchema>;
};

enum FormState {
  CLOSED = 0,
  MODEL = 1,
  SUGGEST = 2,
}

function SetupWithModelButton({setValue}: SetupWithModelButtonProps) {
  const [embeddingModels, setEmbeddingModels] = useState<EmbeddingModel[]>([]);

  useEffect(() => {
    fetchEmbeddingModels().then((models) => setEmbeddingModels(models));
  }, []);

  const [urlModelName, setUrlModelName] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [formState, setFormState] = useState<FormState>(FormState.CLOSED);

  useEffect(() => {
    const modelName = searchParams.get(CreateIndexParam.SETUP_WITH_MODEL);
    if (modelName) {
      setUrlModelName(modelName);
    }
    searchParams.delete(CreateIndexParam.SETUP_WITH_MODEL);
    setSearchParams(searchParams, {replace: true});
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (urlModelName && embeddingModels.length > 0) {
      const modelIndex = embeddingModels.findIndex((model) => model.name === urlModelName);
      if (modelIndex !== -1) {
        setSelectedRow(modelIndex);
        setFormState(FormState.MODEL);
      }
    }
  }, [embeddingModels, urlModelName]);

  const modelCellRenderer = (row: number, col: number) => {
    const model = embeddingModels[row];
    const selected = selectedRow === row;
    if (col === 0) {
      return (
        <Box display="flex" alignItems="center" gap={1} minWidth={480}>
          <Image alt={model.name} src={model.icon} contrast height={30} padding={0.25} />
          <Typography fontWeight={selected ? 'fontWeightBold' : ''}>{model.name}</Typography>
        </Box>
      );
    }
    if (col === 1) {
      return model.dimension[0];
    }
    if (col === 2) {
      return model.metric[0];
    }
    if (col === 3) {
      return model.type;
    }
    return '';
  };
  const setConfiguration = () => {
    if (selectedRow < 0) {
      return;
    }
    const model = embeddingModels[selectedRow];
    setValue('metric', model.metric[0]);
    setValue('dimensions', Number(model.dimension[0]), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setFormState(FormState.CLOSED);
  };
  const allowSuggestModel = useFlag(Flags.ALLOW_SUGGEST_MODEL);
  return (
    <>
      <Button onClick={() => setFormState(FormState.MODEL)} startIcon={<AutoFixHighIcon />}>
        Setup by model
      </Button>
      <Backdrop
        open={formState !== FormState.CLOSED}
        onClick={() => setFormState(FormState.CLOSED)}
        sx={{zIndex: (theme) => theme.zIndex.modal - 1}}
      >
        <ModalForm
          hideBackdrop
          open={formState === FormState.MODEL}
          close={() => setFormState(FormState.CLOSED)}
          title="Setup by model"
          titleSx={{color: 'primary.dark'}}
          submitText="Set Configuration"
          isValid={selectedRow >= 0}
          onSubmit={setConfiguration}
          width="auto"
          form={
            <Box>
              <Typography pb={4}>
                {`Click on a row to select your model. If it's not in the list, refer to its documentation for the correct metric and dimensions.`}
              </Typography>
              <Table
                isLoading={embeddingModels.length === 0}
                columns={[
                  {
                    name: 'Name',
                    dataType: 'string',
                  },
                  {
                    name: 'Dimension',
                    dataType: 'number',
                  },
                  {
                    name: 'Metric',
                    dataType: 'string',
                  },
                  {
                    name: 'Type',
                    dataType: 'string',
                  },
                ]}
                selectedRow={selectedRow}
                numRows={embeddingModels?.length}
                cellRenderer={modelCellRenderer}
                onClickRow={setSelectedRow}
                bodyCellStyleOverrides={{
                  height: 0,
                }}
              />
              <Divider
                sx={{
                  mt: 4,
                  mx: -4,
                }}
              />
            </Box>
          }
          secondaryButton={
            allowSuggestModel ? (
              <Button
                onClick={() => setFormState(FormState.SUGGEST)}
                sx={{
                  ml: 1,
                  color: 'secondary.main',
                  mr: 3,
                }}
                startIcon={<Add />}
              >
                Suggest a model
              </Button>
            ) : undefined
          }
        />
        <ModelSuggestionForm
          open={formState === FormState.SUGGEST}
          goBack={() => setFormState(FormState.MODEL)}
          close={() => setFormState(FormState.CLOSED)}
        />
      </Backdrop>
    </>
  );
}

export default SetupWithModelButton;
