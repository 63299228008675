import {Paper, Typography} from '@mui/material';
import {SupportTierNameMapping} from '../../../../../constants';
import PlanInfo from '../../PlanInfo/PlanInfo';
import {type SupportPlanDetails} from '../../constants';
import ChangeSupportTierButton from './ChangeSupportTierButton/ChangeSupportTierButton';

const styles = {
  container: {
    borderRadius: 1,
    p: 3,
    minWidth: 175,
    display: 'grid',
    gridRow: '1 / 7',
    gridTemplateRows: 'subgrid',
  },
};

interface SupportPlanCardProps {
  supportPlan: SupportPlanDetails;
  showPlanInfo?: boolean;
}

const SupportPlanCard = ({supportPlan, showPlanInfo}: SupportPlanCardProps) => (
  <Paper sx={styles.container}>
    <Typography variant="h5" color="text.primary">
      {SupportTierNameMapping[supportPlan.tier]}
    </Typography>
    <Typography variant="subtitle1" color="primary.light">
      {supportPlan.subtitle}
    </Typography>
    <Typography variant="body1" color="text.primary">
      {supportPlan.description}
    </Typography>
    <ChangeSupportTierButton desiredTier={supportPlan.tier} variant="contained" fullWidth />
    {showPlanInfo && <PlanInfo plan={supportPlan} allowCollapse={false} />}
    {supportPlan.footer && (
      <Typography sx={{fontStyle: 'italic'}} variant="body1" mb={1} color="text.primary">
        {supportPlan.footer}
      </Typography>
    )}
  </Paper>
);

export default SupportPlanCard;
