import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type PrincipalTypes, type RbacRoles, type RoleBinding} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';

export const getRolesForPrincipalRequest = createAction<{
  globalProjectId: string;
  principalId: string;
}>('GET_ROLES_FOR_PRINCIPAL_REQUEST');
export const getRolesForPrincipalSuccess = createAction<{
  globalProjectId: string;
  principalId: string;
  roles: RoleBinding[];
}>('GET_ROLES_FOR_PRINCIPAL_SUCCESS');
export const getRolesForPrincipalFailure = createAction<{
  globalProjectId: string;
  principalId: string;
  error: string;
}>('GET_ROLES_FOR_PRINCIPAL_FAILURE');

export const editRolesForPrincipalRequest = createAction<{
  globalProjectId: string;
  principalId: string;
}>('EDIT_ROLES_REQUEST');
export const editRolesForPrincipalSuccess = createAction<{
  globalProjectId: string;
  principalId: string;
  roles: RoleBinding[];
}>('EDIT_ROLES_SUCCESS');
export const editRolesForPrincipalFailure = createAction<{
  globalProjectId: string;
  principalId: string;
  error: string;
}>('EDIT_ROLES_FAILURE');
export const createRolesForPrincipalSuccess = createAction<{
  globalProjectId: string;
  principalId: string;
  roles: RbacRoles[];
}>('CREATE_ROLES_SUCCESS');

export function getRolesForPrincipal(
  api: ControllerApi,
  data: {globalProjectId: string; principalId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      getRolesForPrincipalRequest({
        globalProjectId: data.globalProjectId,
        principalId: data.principalId,
      }),
    );
    return api
      .getRolesByPrincipal(data.principalId)
      .then((roles) => {
        dispatch(
          getRolesForPrincipalSuccess({
            globalProjectId: data.globalProjectId,
            principalId: data.principalId,
            roles,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          getRolesForPrincipalFailure({
            globalProjectId: data.globalProjectId,
            principalId: data.principalId,
            error: error.message,
          }),
        );
      });
  };
}

export function editRolesForPrincipal(
  api: ControllerApi,
  data: {
    principalId: string;
    principalType: PrincipalTypes;
    globalProjectId: string;
    roles: RbacRoles[];
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(
      editRolesForPrincipalRequest({
        globalProjectId: data.globalProjectId,
        principalId: data.principalId,
      }),
    );
    return api
      .editRolesForPrincipal(data.principalId, data.principalType, data.roles)
      .then((roles) => {
        dispatch(
          editRolesForPrincipalSuccess({
            globalProjectId: data.globalProjectId,
            principalId: data.principalId,
            roles,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Permission settings updated successfully.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          editRolesForPrincipalFailure({
            globalProjectId: data.globalProjectId,
            principalId: data.principalId,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `API key failed to update.`,
          }),
        );
      });
  };
}
