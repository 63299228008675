import {useCallback} from 'react';
import {
  createIntegration,
  deleteIntegration,
  editIntegration,
  listIntegrations,
} from '../../../actions/integrationActions';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useDashboardApi} from '../../../hooks/use-api';
import {useOrgIntegrations, useOrgIntegrationsAreLoading} from '../../../selectors/integrations';
import {useSelectedOrganization} from '../../../selectors/organizations';
import {type IntegrationCreateData, type IntegrationUpdateData} from '../../../types';
import CreateIntegrationButton from './CreateIntegrationButton/CreateIntegrationButton';
import IntegrationList from './IntegrationList/IntegrationList';

/** A page for users to manage their organization's integrations. */
function IntegrationsPage() {
  const selectedOrg = useSelectedOrganization();
  const loading = useOrgIntegrationsAreLoading(selectedOrg.id);
  const integrations = useOrgIntegrations(selectedOrg.id);

  useDashboardApi(listIntegrations, {organizationId: selectedOrg.id});

  const {setQueryData: setCreateData} = useDashboardApi<{
    organizationId: string;
    data: IntegrationCreateData;
  }>();

  const {setQueryData: setEditData} = useDashboardApi<{
    organizationId: string;
    integrationId: string;
    data: IntegrationUpdateData;
  }>();

  const {setQueryData: setDeleteData} = useDashboardApi<{
    organizationId: string;
    integrationId: string;
  }>();

  const onCreate = useCallback(
    (createData: IntegrationCreateData) => {
      setCreateData({
        action: createIntegration,
        data: {organizationId: selectedOrg.id, data: createData},
      });
    },
    [selectedOrg.id, setCreateData],
  );

  const onEdit = useCallback(
    (integrationId: string, updateData: IntegrationUpdateData) => {
      setEditData({
        action: editIntegration,
        data: {organizationId: selectedOrg.id, integrationId, data: updateData},
      });
    },
    [selectedOrg.id, setEditData],
  );

  const onDelete = useCallback(
    (integrationId: string) => {
      setDeleteData({
        action: deleteIntegration,
        data: {organizationId: selectedOrg.id, integrationId},
      });
    },
    [selectedOrg.id, setDeleteData],
  );

  return (
    <Page
      header={
        <PageHeader
          title="Manage integrations"
          titleHidden
          description={
            <>
              Create and manage integrated applications in <strong>{selectedOrg.name}</strong>
            </>
          }
          actions={<CreateIntegrationButton onCreate={onCreate} />}
        />
      }
      loading={loading}
    >
      <IntegrationList integrations={integrations} onEdit={onEdit} onDelete={onDelete} />
    </Page>
  );
}

export default IntegrationsPage;
