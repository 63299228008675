import {z} from 'zod';

const errorMessage = '* Required';
const errorMap: z.ZodErrorMap = () => ({message: errorMessage});

const schema = z
  .object({
    firstName: z.string({errorMap}).nonempty(errorMessage),
    lastName: z.string({errorMap}).nonempty(errorMessage),
    purpose: z.string({errorMap}).nonempty(errorMessage),
    company: z.string().optional(),
    hasEmbeddingModel: z.string({errorMap}).nonempty(errorMessage),
    useCase: z.string({errorMap}).nonempty(errorMessage),
    language: z.string({errorMap}).nonempty(errorMessage),
  })
  .refine(
    (data) => {
      if (data.purpose === 'Business') {
        return !!data.company;
      }
      return true;
    },
    {message: errorMessage, path: ['company']},
  );

export type RegistrationData = z.infer<typeof schema>;
export default schema;
