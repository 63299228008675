import {useAppSelector as useSelector} from '../app/store';

type LegacyMetric =
  | 'heartbeat'
  | 'vectorCount'
  | 'requestCount'
  | 'errorCount'
  | 'podFullness'
  | 'readUnitsCount'
  | 'writeUnitsCount'
  | 'storageUnitsCount';

// METRICS REVAMP
export type Metric =
  | 'readUnitsTotal'
  | 'writeUnitsTotal'
  | 'recordTotal'
  | 'storageSize'
  | 'requestDuration'
  | 'podFullnessPercentage'
  | 'requestTotal'
  | 'requestStatus';

export const useLegacyMetrics = (metric: LegacyMetric, service: string) =>
  useSelector((state) => state.metrics?.[metric]?.[service]);

export const useMetrics = (metric: Metric, service: string) =>
  useSelector((state) => state.metrics?.[metric]?.[service]);

export const useLatencyMetrics = (service: string) => {
  return useSelector((state) => state.metrics.latency[service]);
};
