import {Box, Button, Link, Typography} from '@mui/material';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {PUBLIC_DOCS_URL} from '../../constants';
import RowWithCheck from '../Utilities/RowWithCheck';
import ForcedMigrationSteps from './ForcedMigrationSteps';

type SelectMigrationTypeFormProps = {
  doMigration: () => void;
  doCreateCollection: () => void;
  close: () => void;
  service: string;
};

function SelectMigrationTypeForm({
  service,
  doMigration,
  doCreateCollection,
  close,
}: SelectMigrationTypeFormProps) {
  return (
    <Box position="relative">
      <Box>
        <ModalHeader title="Start migration" subtitle={service} close={close} />
        <ForcedMigrationSteps fullMigration activeStep={0} />
      </Box>
      <Box py={2}>
        <Typography>
          {`Choose `}
          <Typography component="span" fontWeight="bold">
            “Migrate now”
          </Typography>
          {` if you need your index operational today. Prefer to migrate later? Save your data as a `}
          <Typography component="span" fontWeight="bold">
            collection
          </Typography>
          {` now, and seamlessly convert it into a serverless index whenever you're ready. `}
          <Link
            target="_blank"
            rel="noreferer"
            href={`${PUBLIC_DOCS_URL}/guides/indexes/migrate-a-legacy-starter-index-to-serverless`}
          >
            Learn more
          </Link>
        </Typography>
      </Box>
      <Box sx={{backgroundColor: 'background.surface', p: 3, my: 2}}>
        <Typography fontWeight="bold">What to expect before you start:</Typography>
        <RowWithCheck text="Your new serverless index will not contain any writes made to the old index after migration starts." />
        <RowWithCheck text="This process can take up to 30 minutes." />
        <RowWithCheck text="You will have to reinitialize your client and make minor code changes." />
      </Box>
      <Box display="flex" gap={1} width="100%">
        <Button sx={{flex: 1}} variant="contained" onClick={doMigration}>
          Migrate now
        </Button>
        <Button sx={{flex: 1}} variant="outlined" color="secondary" onClick={doCreateCollection}>
          Create a collection
        </Button>
      </Box>
    </Box>
  );
}

export default SelectMigrationTypeForm;
