import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import {deleteAssistantFile} from '../../actions/assistantFilesActions';
import {AssistantFileStatus} from '../../constants';
import {useAssistantDataApi} from '../../hooks/use-api';
import {useBooleanState} from '../../hooks/utils';
import {useSelectedAssistantName, useSelectedGlobalProjectId} from '../../selectors/params';
import {type AssistantFile} from '../../types';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

interface AssistantFileActionMenuProps {
  name: string;
  id: string;
  status?: AssistantFile['status'];
  onDelete?: () => void;
}

export default function AssistantFileActionMenu({
  name,
  id,
  status,
  onDelete,
}: AssistantFileActionMenuProps) {
  const projectId = useSelectedGlobalProjectId();
  const assistantName = useSelectedAssistantName() || '';
  const {
    val: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal,
  } = useBooleanState();
  const {setQueryData} = useAssistantDataApi<{assistantName: string; fileId: string}>(projectId);

  const handleDelete = useCallback(() => {
    setQueryData({
      action: deleteAssistantFile,
      data: {assistantName, fileId: id},
    });
    onDelete?.();
  }, [id, assistantName, setQueryData, onDelete]);

  const disabled =
    status === AssistantFileStatus.PROCESSING || status === AssistantFileStatus.DELETING;

  return (
    <>
      <SelectorMenuButton
        tooltip={disabled ? 'File is not available' : undefined}
        disabled={disabled}
        id={`${name}-actions-showActions`}
        iconSx={{m: 0}}
        ariaLabel="File actions"
        placement="bottom-end"
        items={[
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
            onClick: openDeleteModal,
            color: 'error',
          },
        ]}
      />
      <DeleteConfirmationModal
        open={showDeleteModal}
        close={closeDeleteModal}
        itemType="File"
        name={name}
        doDelete={handleDelete}
        skipCheck
        warningText="This will permanently erase the file and the details it contains. Once deleted, this assistant will not be able to reference this data for future queries."
      />
    </>
  );
}
