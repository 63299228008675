import CheckIcon from '@mui/icons-material/Check';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {type ButtonProps} from '@mui/material/Button';
import {useEffect, useState} from 'react';
import IconButton from '../Buttons/IconButton';

const TIMEOUT = 2500;

interface CopyButtonProps {
  text: string;
  tooltipLabels?: [action: string, feedback: string];
  description?: string;
  color?: ButtonProps['color'];
  sx?: ButtonProps['sx'];
  icon?: React.ReactElement;
  onCopy?: () => void;
}

function CopyButton({
  text,
  description = 'text',
  color = 'secondary',
  sx = {},
  tooltipLabels = ['Copy', 'Copied'],
  icon,
  onCopy,
}: CopyButtonProps) {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (showFeedback) {
      timeoutId = setTimeout(() => setShowFeedback(false), TIMEOUT);
    }

    return () => clearTimeout(timeoutId);
  }, [showFeedback]);

  const handleCopy = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (onCopy) {
      onCopy();
    } else {
      try {
        navigator.clipboard.writeText(text);
      } catch {
        // If clipboard write fails, its likely due to a permissions issue
        return;
      }
    }
    setShowFeedback(true);
    setTimeout(() => setShowFeedback(false), TIMEOUT);
  };

  return (
    <IconButton
      id={`copy-${description}`}
      ariaLabel={`copy-${description}-icon`}
      tooltip={showFeedback ? tooltipLabels[1] : tooltipLabels[0]}
      color={color}
      onClick={handleCopy}
      disabled={showFeedback}
      sx={sx}
      icon={
        showFeedback ? (
          <CheckIcon fontSize="small" color="success" data-testid="copied-icon" />
        ) : (
          icon || <CopyIcon fontSize="small" data-testid="copy-icon" />
        )
      }
    />
  );
}

export default CopyButton;
