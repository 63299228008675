import {Box, Typography} from '@mui/material';
import {type PaginationState} from '@pinecone-experience/timber/PaginationControls/hooks';
import {type QueryData} from '../../../../../../api/dataPlaneApi';
import {type VectorState} from '../../../../../../reducers/explorerReducer';
import {
  useExplorerResultsNamespaceByGlobalProject,
  useExplorerResultsUsageByGlobalProject,
  useExplorerSearchIsLoadingByGlobalProject,
} from '../../../../../../selectors/explorer';
import ExplorerResultsFooter from '../../ExplorerResultsFooter/ExplorerResultsFooter';
import ResultsGuard from '../../ResultsGuard/ResultsGuard';
import VectorCard from '../../VectorCard/VectorCard';
import {type IndexExplorerState} from '../../utilities/utils';

interface ExplorerResultsProps {
  indexExplorerState: IndexExplorerState;
  setFormQuery: (data: QueryData, namespace: string) => void;
  onSubmit: () => void;
  paginationState: PaginationState<VectorState & {score: number}>;
}

function ExplorerResults({
  indexExplorerState,
  setFormQuery,
  onSubmit,
  paginationState,
}: ExplorerResultsProps) {
  const {service, project} = indexExplorerState;
  const isLoading = useExplorerSearchIsLoadingByGlobalProject(project.id, service);
  const namespace = useExplorerResultsNamespaceByGlobalProject(project.id, service);
  const usage = useExplorerResultsUsageByGlobalProject(project.id, service);
  const readUnits = usage?.readUnits;
  const minItem = paginationState.rowsPerPage * paginationState.currentPage;
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', pt: 3}}>
      <Typography>{`Matches: ${paginationState.label}`}</Typography>
      <ResultsGuard loading={isLoading} total={paginationState.total}>
        <Box sx={{minHeight: 50}}>
          {paginationState.itemsToShow.map((vector, index) => (
            <VectorCard
              indexExplorerState={indexExplorerState}
              first={index === 0 && paginationState.currentPage === 0}
              setFormQuery={setFormQuery}
              onSubmit={onSubmit}
              key={vector.id}
              rank={index + minItem + 1}
              vector={vector}
              namespace={namespace}
            />
          ))}
        </Box>
      </ResultsGuard>
      <ExplorerResultsFooter paginationState={paginationState} readUnits={readUnits} />
    </Box>
  );
}

export default ExplorerResults;
