import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {createCollection} from '../../../../actions/collectionActions';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {createRandomId} from '../../../../utils/data';
import ModalForm from '../../../ModalForm';

function CreateCollectionFromIndexForm({
  service,
  open,
  close,
}: {
  service: string;
  open: boolean;
  close: () => void;
}) {
  const {handleSubmit, control, watch, setValue} = useForm({});
  const data = watch();

  useEffect(() => {
    setValue('name', `${service}-${createRandomId()}`);
  }, [setValue, service]);

  const selectedProject = useSelectedGlobalProject();
  const {setQueryData} = useControllerApi<{
    index: string;
    name: string;
    globalProjectId: string;
  }>(selectedProject);
  const onSubmit = () => {
    setQueryData({
      action: createCollection,
      data: {
        index: service,
        name: data.name,
        globalProjectId: selectedProject?.id,
      },
    });
    close();
  };
  return (
    <ModalForm
      open={open}
      close={close}
      form={
        <TextInput
          name="name"
          control={control}
          required
          autoFocus
          label="Enter Collection Name"
          fullWidth
        />
      }
      title="Create Collection"
      isValid
      onSubmit={handleSubmit(onSubmit)}
      submitText="Create Collection"
    />
  );
}

export default CreateCollectionFromIndexForm;
