import {Link, Typography} from '@mui/material';

export const marketplaceAlreadyLinkedReasons = [
  'DEFAULT',
  'MARKETPLACE_SUBSCRIPTION_ALREADY_LINKED',
  'MARKETPLACE_SUBSCRIPTION_NOT_FOUND',
  'MARKETPLACE_NOT_SUBSCRIBED',
];

export const checkMarketplaceError = (message: string) => {
  const parts = message.split('|');
  if (parts.length < 3) {
    return false;
  }
  return marketplaceAlreadyLinkedReasons.includes(parts[0]);
};
const getMessageContent = (
  errorType: string,
  marketplaceType: string,
  emailBase64: string,
  message: string,
) => {
  switch (errorType) {
    case 'MARKETPLACE_SUBSCRIPTION_ALREADY_LINKED':
      const email = atob(emailBase64);
      return {
        title: `Your ${marketplaceType} organization is already connected to Pinecone`,
        message: (
          <Typography variant="h6">
            Please reach out to the owner of this organization &nbsp;
            <Link href={`mailto:${email}`} target="_blank">
              {email}
            </Link>{' '}
            to be added.
          </Typography>
        ),
      };
    case 'MARKETPLACE_SUBSCRIPTION_NOT_FOUND':
      return {
        title: `Your ${marketplaceType} subscription was not found`,
        message: <Typography>{message}</Typography>,
      };
    case 'MARKETPLACE_NOT_SUBSCRIBED':
      return {
        title: `Your ${marketplaceType} organization is not subscribed to Pinecone`,
        message: <Typography>{message}</Typography>,
      };
    default:
      return {
        title: 'An error has been raised during authentication.',
        message: <Typography>{message}</Typography>,
      };
  }
};

export const unpackErrorMessage = (rawMessage: string) => {
  const parts = rawMessage.split('|');
  if (parts.length < 3) {
    return {
      title: 'An error has been raised during authentication.',
      message: <Typography>{rawMessage}</Typography>,
    };
  }
  const [errorType, marketplaceType, emailB64, message] = parts;
  return getMessageContent(errorType, marketplaceType, emailB64, message);
};
