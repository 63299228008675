import LockIcon from '@mui/icons-material/Lock';
import {Box, Chip, type Theme, Typography} from '@mui/material';
import type * as React from 'react';

const styles = {
  gridContainer: (paid: boolean) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
    gap: 3,
    opacity: (theme: Theme) => (paid ? theme.palette.action.disabledOpacity : 1),
  }),
};
type StyledDescriptiveInputProps = {
  header: string;
  description: string;
  shrinkTypographyHierarchy?: boolean;
  paid?: boolean;
  chip?: string;
  inputComponent: React.ReactNode;
};
function StyledDescriptiveInput({
  header,
  description,
  shrinkTypographyHierarchy,
  paid,
  chip,
  inputComponent,
}: StyledDescriptiveInputProps) {
  return (
    <Box sx={styles.gridContainer(!!paid)}>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <Typography variant={shrinkTypographyHierarchy ? 'h6' : 'h5'}>{header}</Typography>
          {chip && (
            <Chip sx={{ml: 1}} variant="outlined" size="small" color="primary" label={chip} />
          )}
          {paid && <LockIcon sx={{ml: 1}} color="disabled" />}
        </Box>
        <Typography gutterBottom>{description}</Typography>
      </Box>
      <Box>{inputComponent}</Box>
    </Box>
  );
}

export default StyledDescriptiveInput;
