import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  clearNetworkAllowlistUpdateError,
  createNetworkAllowlistEntry,
  deleteNetworkAllowlistEntry,
} from '../actions/networkAllowlistActions';
import {useNetworkAllowlistUpdateError} from '../selectors/networkAllowlists';
import {useSelectedGlobalProject} from '../selectors/projects';
import {type CidrConfig, type NetworkAllowlist, type NetworkAllowlistEntry} from '../types';
import {useControllerApi} from './use-api';

const INTERNET_ACCESS_CIDR_CONFIG = {cidr: '0.0.0.0/0'};

function getAllowlistEntry(config: CidrConfig, allowlist?: NetworkAllowlist) {
  return allowlist?.find((entry) => entry.cidr === config.cidr);
}

interface InternetAccessEntry {
  access: 'enabled' | 'disabled';
  entry?: NetworkAllowlistEntry;
}

export function useInternetAccessEntry(allowlist?: NetworkAllowlist): InternetAccessEntry {
  const entry = getAllowlistEntry(INTERNET_ACCESS_CIDR_CONFIG, allowlist);

  const allowlistExistsWithNoInternetEntry = !!allowlist && entry === undefined;
  const access = allowlistExistsWithNoInternetEntry ? 'disabled' : 'enabled';

  return {access, entry};
}

interface ProjectInternetAccessConfig {
  access: 'enabled' | 'disabled';
  updateAccess: (access: 'enabled' | 'disabled') => void;
  updateError: boolean;
}

export function useProjectInternetAccessConfig(
  allowlist?: NetworkAllowlist,
): ProjectInternetAccessConfig {
  const project = useSelectedGlobalProject();
  const updateError = useNetworkAllowlistUpdateError();
  const {access, entry: internetAccessEntry} = useInternetAccessEntry(allowlist);

  const {setQueryData: setCreateAllowlistEntryData} = useControllerApi<{
    globalProjectId: string;
    config: CidrConfig;
  }>(project);

  const {setQueryData: setDeleteAllowlistEntryData} = useControllerApi<{
    globalProjectId: string;
    entryId: string;
  }>(project);

  const updateAccess = (updatedAccess: 'enabled' | 'disabled') => {
    if (updatedAccess === 'disabled') {
      if (internetAccessEntry) {
        setDeleteAllowlistEntryData({
          action: deleteNetworkAllowlistEntry,
          data: {globalProjectId: project.id, entryId: internetAccessEntry.id},
        });
      }
    } else {
      setCreateAllowlistEntryData({
        action: createNetworkAllowlistEntry,
        data: {globalProjectId: project.id, config: INTERNET_ACCESS_CIDR_CONFIG},
      });
    }
  };

  return {
    access,
    updateAccess,
    updateError,
  };
}

interface ProjectInternetAccessSwitch {
  disabled: boolean;
  setAccess: (disabled: boolean) => void;
}

export function useProjectInternetAccessSwitch(
  allowlist?: NetworkAllowlist,
): ProjectInternetAccessSwitch {
  const dispatch = useDispatch();
  const {access, updateAccess, updateError} = useProjectInternetAccessConfig(allowlist);
  const accessDisabled = access === 'disabled';
  const [disabled, setDisabled] = useState(accessDisabled);
  const allowlistCount = useRef(allowlist?.length);

  // Refresh `disabled` when we detect the allowlist entries have changed
  useEffect(() => {
    if (allowlistCount.current !== allowlist?.length && accessDisabled !== disabled) {
      setDisabled(accessDisabled);
      allowlistCount.current = allowlist?.length;
    }
  }, [accessDisabled, allowlist, disabled]);

  /* If there was an update error, `disabled` should revert to `access` value */
  useEffect(() => {
    if (updateError && accessDisabled !== disabled) {
      setDisabled(accessDisabled);
      dispatch(clearNetworkAllowlistUpdateError());
    }
  }, [updateError, accessDisabled, disabled, dispatch, allowlist?.length]);

  const setAccess = (disable: boolean) => {
    if (disable) {
      updateAccess('disabled');
      setDisabled(true);
    } else {
      updateAccess('enabled');
      setDisabled(false);
    }
  };

  return {
    disabled,
    setAccess,
  };
}
