import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/globalErrorActions';

interface GlobalErrorState {
  error: {
    title?: string;
    message?: string;
    isMarketplaceError?: boolean;
  };
  hasError: boolean;
}

const initialState = {
  error: {},
  hasError: false,
} as GlobalErrorState;

const globalErrorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.showBlockingError, (state, action) => {
      const {title, message, isMarketplaceError} = action.payload;
      state.error = {title, message, isMarketplaceError};
      state.hasError = true;
    })
    .addCase(actions.dismissBlockingError, (state) => {
      state.error = {};
      state.hasError = false;
    });
});

export default globalErrorReducer;
