import {type Action, type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import type DataPlaneApi from '../api/dataPlaneApi';
import {
  type FetchResponse,
  type ListVectorsRequest,
  type ListVectorsResponse,
  type QueryRequest,
  type QueryResponse,
  type UpdateRequest,
  type UpsertRequest,
} from '../api/dataPlaneApi';
import {type IndexStatsDescription} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';

export const vectorFetchRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace: string;
  hidden?: boolean;
}>('VECTOR_FETCH_REQUEST');
export const vectorFetchSuccess = createAction<{
  globalProjectId: string;
  data: FetchResponse;
  serviceName: string;
  hidden?: boolean;
}>('VECTOR_FETCH_SUCCESS');
export const vectorFetchFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: Error;
}>('VECTOR_FETCH_FAILURE');

export const vectorUpdateRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  vectorId: string;
}>('VECTOR_UPDATE_REQUEST');
export const vectorUpdateSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  data: UpdateRequest;
}>('VECTOR_UPDATE_SUCCESS');
export const vectorUpdateFailure = createAction<{globalProjectId: string; error: Error}>(
  'VECTOR_UPDATE_FAILURE',
);

export const vectorQueryRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace?: string;
}>('VECTOR_QUERY_REQUEST');
export const vectorQuerySuccess = createAction<{
  globalProjectId: string;
  data: QueryResponse;
  serviceName: string;
}>('VECTOR_QUERY_SUCCESS');
export const vectorQueryFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: Error;
}>('VECTOR_QUERY_FAILURE');

export const vectorUpsertRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  upserts: UpsertRequest[];
}>('VECTOR_UPSERT_REQUEST');
export const vectorUpsertSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  upserts: UpsertRequest[];
}>('VECTOR_UPSERT_SUCCESS');
export const vectorUpsertFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: Error;
}>('VECTOR_UPSERT_FAILURE');

export const vectorDeleteRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  vectorIds: string[];
  namespace: string;
}>('VECTOR_DELETE_REQUEST');
export const vectorDeleteSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  vectorIds: string[];
  namespace: string;
}>('VECTOR_DELETE_SUCCESS');
export const vectorDeleteFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: Error;
}>('VECTOR_DELETE_FAILURE');

export const listVectorsRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace: string;
  limit: number;
}>('LIST_VECTORS_REQUEST');
export const listVectorsSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  data: ListVectorsResponse;
}>('LIST_VECTORS_SUCCESS');
export const listVectorsFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: string;
  namespace: string;
}>('LIST_VECTORS_FAILURE');

export const describeIndexSuccess = createAction<{
  serviceName: string;
  globalProjectId: string;
  data: IndexStatsDescription;
}>('DESCRIBE_INDEX_SUCCESS');

export const describeIndexFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  error: Error;
}>('DESCRIBE_INDEX_FAILURE');

export const deleteNamespaceRequest = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace: string;
}>('DELETE_NAMESPACE_REQUEST');
export const deleteNamespaceSuccess = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace: string;
}>('DELETE_NAMESPACE_SUCCESS');
export const deleteNamespaceFailure = createAction<{
  globalProjectId: string;
  serviceName: string;
  namespace: string;
  error: string;
}>('DELETE_NAMESPACE_FAILURE');

export function updateVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: UpdateRequest,
) {
  return (dispatch: Dispatch) => {
    dispatch(vectorUpdateRequest({globalProjectId, serviceName, vectorId: data.id}));
    return api
      .updateVectors(data)
      .then(() => {
        dispatch(vectorUpdateSuccess({globalProjectId, serviceName, data}));
        dispatch(enqueNotification({type: NotificationTypes.SUCCESS, text: 'Update Successful.'}));
      })
      .catch((error) => {
        dispatch(vectorUpdateFailure({globalProjectId, error}));
        dispatch(
          enqueNotification({type: NotificationTypes.ERROR, text: `Update failed. ${error}`}),
        );
        return error;
      });
  };
}

export function queryVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: {query: QueryRequest; skipNotify?: boolean; retries?: number},
) {
  return (dispatch: Dispatch) => {
    dispatch(vectorQueryRequest({globalProjectId, serviceName, namespace: data.query.namespace}));
    return api
      .queryVectors(data.query)
      .then((res) => {
        dispatch(
          vectorQuerySuccess({
            data: res,
            serviceName,
            globalProjectId,
          }),
        );
        if (!data.skipNotify) {
          dispatch(enqueNotification({type: NotificationTypes.SUCCESS, text: 'Query Successful.'}));
        }
      })
      .catch((error) => {
        // In v3 free environments, index report as ready but may still reject queries for
        // a small period of time,
        // this retry allows requests that come in reponse to index load to be retried
        if (data.retries && data.retries > 0) {
          dispatch(
            queryVectors(api, globalProjectId, serviceName, {
              ...data,
              retries: data.retries - 1,
            }) as unknown as AnyAction,
          );
          return error;
        }
        dispatch(vectorQueryFailure({globalProjectId, serviceName, error}));
        dispatch(
          enqueNotification({type: NotificationTypes.ERROR, text: `Query failed. ${error}`}),
        );
        return error;
      });
  };
}

export function fetchVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: {namespace: string; ids: string[]; hidden?: boolean},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      vectorFetchRequest({
        globalProjectId,
        serviceName,
        namespace: data.namespace,
        hidden: data.hidden,
      }),
    );
    return api
      .fetchVectors(data.ids, data.namespace)
      .then((result) => {
        dispatch(
          vectorFetchSuccess({data: result, serviceName, globalProjectId, hidden: data.hidden}),
        );
        dispatch(enqueNotification({type: NotificationTypes.SUCCESS, text: 'Fetch Successful.'}));
      })
      .catch((error) => {
        dispatch(vectorFetchFailure({error, serviceName, globalProjectId}));
        dispatch(
          enqueNotification({type: NotificationTypes.ERROR, text: `Fetch failed. ${error}`}),
        );
        return error;
      });
  };
}

export function deleteVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: {namespace?: string; ids: string[]},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      vectorDeleteRequest({
        globalProjectId,
        serviceName,
        vectorIds: data.ids,
        namespace: data.namespace || '',
      }),
    );
    return api
      .deleteVectors(data.ids, data.namespace)
      .then(() => {
        dispatch(
          vectorDeleteSuccess({
            globalProjectId,
            serviceName,
            vectorIds: data.ids,
            namespace: data.namespace || '',
          }),
        );
        dispatch(enqueNotification({type: NotificationTypes.SUCCESS, text: 'Delete Successful.'}));
      })
      .catch((error) => {
        dispatch(vectorDeleteFailure({globalProjectId, serviceName, error}));
        dispatch(
          enqueNotification({type: NotificationTypes.ERROR, text: `Delete failed. ${error}`}),
        );
        return error;
      });
  };
}

export function listVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: ListVectorsRequest,
) {
  return (dispatch: Dispatch) => {
    dispatch(
      listVectorsRequest({
        globalProjectId,
        serviceName,
        namespace: data.namespace,
        limit: data.limit,
      }),
    );
    return api
      .listVectors(data)
      .then((res) => {
        dispatch(
          listVectorsSuccess({
            globalProjectId,
            serviceName,
            data: res,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          listVectorsFailure({
            globalProjectId,
            serviceName,
            namespace: data.namespace,
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Listing vectors failed: ${error}`,
          }),
        );
      });
  };
}

export function describeIndexStats(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
) {
  return (dispatch: Dispatch) => {
    return api
      .describeIndexStats()
      .then((data) => {
        dispatch(describeIndexSuccess({globalProjectId, serviceName, data}));
      })
      .catch((error) => {
        dispatch(describeIndexFailure({globalProjectId, serviceName, error}));
        return error;
      });
  };
}

export function upsertVectors(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: {upserts: UpsertRequest[]; skipNotify?: boolean; retries?: number},
) {
  return (dispatch: Dispatch) => {
    // TODO(damargulis): Once current data operations are removed, simplify this
    // by removing the parallel upsert ability
    dispatch(
      vectorUpsertRequest({
        globalProjectId,
        serviceName,
        upserts: data.upserts,
      }),
    );
    return api
      .upsertMultipleVectorRequests(data.upserts)
      .then(() => {
        dispatch(
          vectorUpsertSuccess({
            globalProjectId,
            serviceName,
            upserts: data.upserts,
          }),
        );
        if (!data.skipNotify) {
          dispatch(
            enqueNotification({type: NotificationTypes.SUCCESS, text: 'Upsert Successful.'}),
          );
        }
        dispatch(describeIndexStats(api, globalProjectId, serviceName) as unknown as Action);
      })
      .catch((error) => {
        if (data.retries && data.retries > 0) {
          dispatch(
            upsertVectors(api, globalProjectId, serviceName, {
              ...data,
              retries: data.retries - 1,
            }) as unknown as AnyAction,
          );
          return;
        }
        dispatch(vectorUpsertFailure({globalProjectId, serviceName, error}));
        dispatch(
          enqueNotification({type: NotificationTypes.ERROR, text: `Upsert failed. ${error}`}),
        );
      });
  };
}

export function deleteNamespace(
  api: DataPlaneApi,
  globalProjectId: string,
  serviceName: string,
  data: {namespace: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(deleteNamespaceRequest({globalProjectId, serviceName, namespace: data.namespace}));
    return api
      .deleteNamespace(data.namespace)
      .then(() => {
        dispatch(deleteNamespaceSuccess({globalProjectId, serviceName, namespace: data.namespace}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Namespace ${data.namespace} successfully deleted.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          deleteNamespaceFailure({
            globalProjectId,
            serviceName,
            namespace: data.namespace,
            error: err.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to delete namespace ${data.namespace}: ${err.message}`,
          }),
        );
      });
  };
}
