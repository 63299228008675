import Box from '@mui/material/Box';
import {type Theme} from '@mui/material/styles';
import {Navigate} from 'react-router-dom';
import {getAssistant} from '../../../actions/assistantActions';
import {listAssistantFiles, uploadAssistantFiles} from '../../../actions/assistantFilesActions';
import StyledTabs from '../../../components/StyledTabs';
import Page from '../../../components/layout/Page/Page';
import {IndexState, ProjectPage} from '../../../constants';
import {
  useNavigateToAssistantFile,
  useNavigateToProjectPageGlobal,
} from '../../../hooks/navigation';
import {useAssistantDataApi, useControllerApi} from '../../../hooks/use-api';
import {
  useAssistantFiles,
  useAssistantFilesAreLoading,
  useAssistantsAreLoading,
  useSelectedAssistant,
} from '../../../selectors/assistants';
import {useSelectedAssistantName, useSelectedOrganizationId} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import AssistantHeader from './AssistantHeader/AssistantHeader';
import AssistantSummary from './AssistantSummary/AssistantSummary';
import ChatTab from './ChatTab/ChatTab';
import FilesTab from './FilesTab/FilesTab';

enum Tabs {
  CHAT = 'chat',
  FILES = 'files',
}

const styles = {
  tabContent: {
    height: (theme: Theme) => `calc(100% - ${theme.spacing(4)})`,
    my: 2,
  },
};

function AssistantDetailPage() {
  const orgId = useSelectedOrganizationId();
  const project = useSelectedGlobalProject();
  const selectedAssistantName = useSelectedAssistantName();
  const selectedAssistant = useSelectedAssistant();
  const assistantsAreLoading = useAssistantsAreLoading(project.id);
  const assistantIsReady = selectedAssistant?.status === IndexState.READY;
  const filesAreLoading = useAssistantFilesAreLoading(selectedAssistantName || '');
  const files = useAssistantFiles(selectedAssistantName || '');

  const {getPath: getProjectPage, go: goToProjectPage} = useNavigateToProjectPageGlobal(
    project.organization_id,
  );
  const {getPath} = useNavigateToAssistantFile(orgId, project.id);
  const getFilePath = (fileId: string) => getPath(selectedAssistantName || '', fileId);

  useControllerApi(project, getAssistant, {
    globalProjectId: project.id,
    assistantName: selectedAssistantName || '',
  });

  useAssistantDataApi(project.id, listAssistantFiles, {
    assistantName: selectedAssistantName || '',
  });

  const {setQueryData} = useAssistantDataApi<{assistantName: string; files: File[]}>(project.id);

  const importFiles = (filesToImport: File[]) => {
    setQueryData({
      action: uploadAssistantFiles,
      data: {assistantName: selectedAssistantName || '', files: filesToImport},
    });
  };

  const tabs = [
    {
      value: Tabs.CHAT,
      component: (
        <Box sx={styles.tabContent}>
          <ChatTab
            globalProjectId={project.id}
            assistantName={selectedAssistantName || ''}
            hasFiles={!!files.length || filesAreLoading}
          />
        </Box>
      ),
    },
    {
      value: Tabs.FILES,
      label: !filesAreLoading ? `FILES (${files.length})` : 'FILES',
      component: (
        <Box sx={styles.tabContent}>
          <FilesTab
            assistantName={selectedAssistantName || ''}
            files={files}
            importFiles={importFiles}
            getFilePath={getFilePath}
            loading={filesAreLoading || !assistantIsReady}
          />
        </Box>
      ),
    },
  ];

  if (!assistantsAreLoading && !selectedAssistant) {
    return <Navigate to={getProjectPage(project.id, ProjectPage.ASSISTANTS)} />;
  }
  if (!selectedAssistant) {
    return <Page loading />;
  }
  return (
    <Page
      header={
        <AssistantHeader
          project={project}
          name={selectedAssistant.name}
          goToList={() => goToProjectPage(project.id, ProjectPage.ASSISTANTS)}
        />
      }
    >
      <AssistantSummary model={selectedAssistant} />
      <StyledTabs
        sticky={false}
        tabs={tabs}
        idPrefix="knowledge-base"
        defaultPath={filesAreLoading || files.length ? Tabs.CHAT : Tabs.FILES}
        contentSx={{pt: 0}}
      />
    </Page>
  );
}

export default AssistantDetailPage;
