import {type ButtonProps, Link} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {Plans} from '../../../constants';
import {getOrgBillingPlansPath} from '../../../hooks/navigation';
import {useSelectedOrganization} from '../../../selectors/organizations';
import {useGlobalProjectsFromSelectedOrganization} from '../../../selectors/projects';
import CreateButton from '../../CreateButton';
import {useCreateProjectButton} from './hooks/createProjectButton';

type CreateProjectButtonProps = {
  text?: string;
  customId?: string;
  variant?: ButtonProps['variant'];
  includePlus?: boolean;
  color?: ButtonProps['color'];
};
function CreateProjectButton({
  text = 'Create project',
  customId = 'create-project-button',
  variant = 'contained',
  includePlus = false,
  color = 'primary',
}: CreateProjectButtonProps) {
  const org = useSelectedOrganization();
  const projects = useGlobalProjectsFromSelectedOrganization();
  const hasProject = projects.length;
  const canCreate = org.plan !== Plans.FREE || !hasProject;

  const {doCreate, modal} = useCreateProjectButton();
  return (
    <>
      <CreateButton
        disabled={!canCreate}
        tooltip={
          canCreate ? (
            ''
          ) : (
            <>
              The Starter plan is limited to 1 project.{' '}
              <Link component={RouterLink} to={getOrgBillingPlansPath()}>
                Upgrade
              </Link>{' '}
              to create more.
            </>
          )
        }
        text={text}
        onClick={doCreate}
        customId={customId}
        variant={variant}
        includePlus={includePlus}
        color={color}
      />
      {modal}
    </>
  );
}

export default CreateProjectButton;
