import {createReducer} from '@reduxjs/toolkit';
import {
  clearNetworkAllowlistUpdateError,
  createNetworkAllowlistEntryFailure,
  createNetworkAllowlistEntryRequest,
  createNetworkAllowlistEntrySuccess,
  deleteNetworkAllowlistEntryFailure,
  deleteNetworkAllowlistEntryRequest,
  deleteNetworkAllowlistEntrySuccess,
  getNetworkAllowlistFailure,
  getNetworkAllowlistRequest,
  getNetworkAllowlistSuccess,
} from '../actions/networkAllowlistActions';
import {type NetworkAllowlist} from '../types';
import {type AsyncDataState, initAsyncDataState} from './utils';

interface NetworkAllowlistState {
  items: Record<string, AsyncDataState<NetworkAllowlist | undefined>>;
  updateError: boolean;
}

const initialState: NetworkAllowlistState = {
  items: {},
  updateError: false,
};

const networkAllowlistReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearNetworkAllowlistUpdateError, (state) => {
      state.updateError = false;
    })
    // Get actions
    .addCase(getNetworkAllowlistRequest, (state, action) => {
      state.items[action.payload.globalProjectId] = initAsyncDataState<NetworkAllowlist>(
        state.items[action.payload.globalProjectId]?.data,
        true,
      );
    })
    .addCase(getNetworkAllowlistSuccess, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.items[action.payload.globalProjectId].data = action.payload.data;
    })
    .addCase(getNetworkAllowlistFailure, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.items[action.payload.globalProjectId].error = action.payload.error;
    })
    // Create entry actions
    .addCase(createNetworkAllowlistEntryRequest, (state, action) => {
      state.items[action.payload.globalProjectId].loading = true;
      state.updateError = false;
    })
    .addCase(createNetworkAllowlistEntrySuccess, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
    })
    .addCase(createNetworkAllowlistEntryFailure, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.updateError = true;
    })
    // Delete entry actions
    .addCase(deleteNetworkAllowlistEntryRequest, (state, action) => {
      state.items[action.payload.globalProjectId] =
        state.items[action.payload.globalProjectId] || initAsyncDataState([], true);
      state.updateError = false;
    })
    .addCase(deleteNetworkAllowlistEntrySuccess, (state, action) => {
      const {globalProjectId} = action.payload;
      state.items[globalProjectId].loading = false;
      const allowlist = state.items[globalProjectId].data || [];

      state.items[globalProjectId].data = allowlist.filter(({id}) => id !== action.payload.entryId);
    })
    .addCase(deleteNetworkAllowlistEntryFailure, (state, action) => {
      state.items[action.payload.globalProjectId].loading = false;
      state.updateError = true;
    });
});

export default networkAllowlistReducer;
