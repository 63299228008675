import {Box, Card, Typography} from '@mui/material';
import AssistantStatusBox from '../../../../components/AssistantStatusBox/AssistantStatusBox';
import SummaryItem from '../../../../components/SummaryItem/SummaryItem';
import {ASSISTANT_DATA_PLANE_URL} from '../../../../constants';
import {type Assistant} from '../../../../types';

type AssistantSummaryProps = {
  model: Assistant;
};

const styles = {
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

function AssistantSummary({model}: AssistantSummaryProps) {
  return (
    <Card sx={styles.card}>
      <Box display="flex" alignItems="baseline">
        <Typography mr={1} variant="h6" component="h1" gutterBottom>
          {model.name}
        </Typography>
        <AssistantStatusBox assistantName={model.name} />
      </Box>
      {model.metadata?.description ? (
        <Typography mb={1}>{model.metadata.description}</Typography>
      ) : null}
      <Box display="flex" gap={4}>
        <SummaryItem label="Date Created" value={new Date(model.created_at).toLocaleDateString()} />
        <SummaryItem label="Last Updated" value={new Date(model.updated_at).toLocaleDateString()} />
        <SummaryItem label="Host" value={`https://${ASSISTANT_DATA_PLANE_URL}`} enableCopy />
      </Box>
    </Card>
  );
}

export default AssistantSummary;
