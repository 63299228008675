import {deleteApiKey, rotateApiKey} from '../../../actions/apiKeyActions';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useDashboardApi} from '../../../hooks/use-api';
import {
  useApiKeysByGlobalProjectId,
  useKeysAreLoadingByGlobalProjectId,
} from '../../../selectors/keys';
import {useIsOwnerOfSelectedProject, useSelectedGlobalProject} from '../../../selectors/projects';
import {type ApiKey} from '../../../types';
import ApiKeyTable from './ApiKeyTable/ApiKeyTable';
import CreateApiKeyButton from './CreateApiKeyButton/CreateApiKeyButton';

/** A page where the user can manage their API keys for the selected project. */
function ApiKeysPage() {
  const selectedProject = useSelectedGlobalProject();

  const isLoading = useKeysAreLoadingByGlobalProjectId(selectedProject?.id);
  const apiKeys = useApiKeysByGlobalProjectId(selectedProject?.id);

  const isOwner = useIsOwnerOfSelectedProject();

  const {setQueryData: setModifyData} = useDashboardApi<{
    globalProjectId: string;
    label: string;
    userName: string;
    keyId: string;
    displayLabel: string;
  }>();

  const canDelete = isOwner && apiKeys.length > 1;
  const onDelete = canDelete
    ? (apiKey: ApiKey) =>
        setModifyData({
          action: deleteApiKey,
          data: {
            label: apiKey.user_label,
            displayLabel: apiKey.display_label,
            globalProjectId: selectedProject.id,
            userName: apiKey.user_name,
            keyId: apiKey.id,
          },
        })
    : undefined;

  const onRotate = isOwner
    ? (apiKey: ApiKey) =>
        setModifyData({
          action: rotateApiKey,
          data: {
            label: apiKey.user_label,
            displayLabel: apiKey.display_label,
            globalProjectId: selectedProject.id,
            userName: apiKey.user_name,
            keyId: apiKey.id,
          },
        })
    : undefined;

  return (
    <Page
      loading={isLoading}
      header={<PageHeader title="API keys" actions={<CreateApiKeyButton isOwner={isOwner} />} />}
    >
      <ApiKeyTable apiKeys={apiKeys} onDelete={onDelete} onRotate={onRotate} />
    </Page>
  );
}

export default ApiKeysPage;
