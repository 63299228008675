import {type SliceTooltipProps} from '@nivo/line';
import ChartTooltip from '../../../../../../components/chart/ChartTooltip/ChartTooltip';

const HeartbeatTooltip = ({slice}: SliceTooltipProps) => {
  const {points} = slice;
  const {data, serieColor} = points[0];
  return (
    <ChartTooltip
      timestamp={new Date(data.x)}
      items={[
        {
          color: serieColor,
          label: 'vector count',
          value: String(data.y),
          unit: '',
        },
      ]}
    />
  );
};

export default HeartbeatTooltip;
