import {type NavigateProps} from 'react-router-dom';
import {useSubstituteProject} from '../../hooks/navigation';
import NavigateWithParams from './NavigateWithParams';

export type SubstituteWithGlobalProjectIdProps = Omit<NavigateProps, 'to'> & {
  projectId: string;
};

function SubstituteWithGlobalProjectId(navigateProps: SubstituteWithGlobalProjectIdProps) {
  const {path} = useSubstituteProject(navigateProps.projectId);
  return <NavigateWithParams {...navigateProps} to={path} />;
}

export default SubstituteWithGlobalProjectId;
