import {Chip} from '@mui/material';
import {capitalize} from '../../../../../../utils/format';

type StatusColor = 'success' | 'error' | 'warning' | 'info';

const colorMap: Record<string, StatusColor> = {
  paid: 'success',
  void: 'error',
  uncollectible: 'error',
  open: 'warning',
  draft: 'warning',
};

function PaymentHistoryStatusCell({status}: {status: string | null}) {
  return status ? (
    <Chip label={capitalize(status)} color={colorMap[status] || 'info'} variant="outlined" />
  ) : (
    <span />
  );
}

export default PaymentHistoryStatusCell;
