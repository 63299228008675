// Replace the deprecated import with this
import {createClient} from '@sanity/client';
import {SANITY_PROJECT_ID, SANITY_READ_TOKEN} from '../../constants';

export const client = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2024-02-26',
  token: SANITY_READ_TOKEN,
  useCdn: true,
});
