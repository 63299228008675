import {checkServiceTerminating, createService, getService} from '../actions/serviceActions';
import {type Vector} from '../api/dataPlaneApi';
import {AUTH0_NAMESPACE, IndexState, LocalStorageKeys, POLLING_INTERVAL} from '../constants';
import {type IndexSchema} from '../pages/indexes/IndexCreationPage/utilities/schema';
import {useServerlessEnvironments} from '../selectors/environments';
import {useSelectedGlobalProject} from '../selectors/projects';
import {useMigrationOperation, useServiceByGlobalProject} from '../selectors/services';
import {OperationStatus} from '../types';
import {type GlobalProject} from '../types';
import {getEffectivePercent} from '../utils/migrations';
import {localStorageSet} from '../utils/storage';
import {HEAP_TRACKING_EVENTS, TRACKING_EVENTS, sendToGa, sendToHeap} from '../utils/tracking';
import {useAuth} from './auth';
import {useIndexStatusColorMap} from './colorMaps';
import {isUpgrading} from './specUtils';
import {useControllerApi} from './use-api';
import {useInterval} from './utils';

export const IndexStateDisplayMap = {
  [IndexState.SCALING_UP]: 'Scaling Up',
  [IndexState.SCALING_DOWN]: 'Scaling Down',
  [IndexState.SCALING_UP_PODS]: 'Scaling Up Pods',
  [IndexState.SCALING_DOWN_PODS]: 'Scaling Down Pods',
  [IndexState.FAILED]: 'Initialization Failed',
} as Record<string, string>;

export function useIndexStatusBox(service: string) {
  const selectedProject = useSelectedGlobalProject();
  const {setQueryData} = useControllerApi<{
    name: string;
    globalProjectId: string;
  }>(selectedProject);
  const serviceInfo = useServiceByGlobalProject(selectedProject?.id, service);
  const serviceStatus = serviceInfo?.status;
  useInterval(
    () => {
      setQueryData({
        action: getService,
        data: {name: service, globalProjectId: selectedProject?.id},
      });
    },
    serviceStatus?.state === IndexState.READY ? null : POLLING_INTERVAL,
  );
  useInterval(
    () => {
      setQueryData({
        action: checkServiceTerminating,
        data: {name: service, globalProjectId: selectedProject?.id},
      });
    },
    serviceStatus?.state === IndexState.TERMINATING ? POLLING_INTERVAL : null,
  );
  let statusText = IndexStateDisplayMap[serviceStatus?.state] || serviceStatus?.state;
  let statusColor = useIndexStatusColorMap(serviceStatus?.state || IndexState.UNKNOWN);
  const upgrading = isUpgrading(serviceInfo);
  const ops = useMigrationOperation(selectedProject?.id, serviceInfo?.name || '');
  if (upgrading) {
    statusText = 'Migrating data';
    statusColor = '#D8C411';
    // The migration operation stops being polled once the import operation kicks off.
    // If the import op reports as complete, asssume the migration operation is complete.
  } else if (
    ops?.migrationOperation?.status === OperationStatus.PENDING &&
    ops?.importOperation?.status !== OperationStatus.COMPLETED
  ) {
    statusColor = '#D8C411';
    const percent = getEffectivePercent(ops.migrationOperation, ops.importOperation);
    statusText = `Migration ${Math.round(percent)}% complete`;
  }
  return {statusText, statusColor, serviceStatus};
}

export function useCreateIndexHandler(
  project: GlobalProject,
  isServerless: boolean,
  dataset?: Vector[],
) {
  const {user} = useAuth();
  const {setQueryData} = useControllerApi<
    IndexSchema & {
      from_collection?: string;
      project: GlobalProject;
      region: string;
      isServerless: boolean;
    }
  >(project);
  const serverlessEnvs = useServerlessEnvironments(project.organization_id);
  return (formData: IndexSchema) => {
    // If a serverless environment has been selected, use the environment region in the call
    // instead of the env name
    const serverlessEnv = serverlessEnvs.find((env) => env.name === formData.environment);
    const dataWithProject = {
      ...formData,
      project,
      region: serverlessEnv?.provider_region || '',
      dataset,
      isServerless,
    };
    const dataForGa = [formData.name];
    setQueryData({action: createService, data: dataWithProject});

    // Check if the index is being created for a commercial account
    const isCommercial = !!(user && user[`${AUTH0_NAMESPACE}hubspotCompanyId`] !== -1);

    sendToGa(TRACKING_EVENTS.CREATE_INDEX, {
      data: dataForGa.toString(),
      from_form_type: !!formData.from_collection,
      isCommercial,
    });
    sendToHeap(HEAP_TRACKING_EVENTS.INDEX_CREATED, {
      indexType: isServerless ? 'Serverless' : 'Dedicated Compute',
      isCommercial,
    });
    localStorageSet(
      `${LocalStorageKeys.SERVERLESS_RELEASE_HAS_CREATED_INDEX}_${project.vault_id}`,
      'true',
    );
  };
}
