import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {Link as RouterLink} from 'react-router-dom';
import EmptyState from '../../../../components/layout/EmptyState/EmptyState';
import {PUBLIC_DOCS, ProjectPage} from '../../../../constants';

export function InternetAccessDisabled() {
  return (
    <Box mt={3}>
      <EmptyState
        heading="Console access limited"
        description={
          <Typography>
            Internet access is disabled for the project. This means that certain operations and
            views are unavailable and can only be accessed through your private network. Manage your{' '}
            <Link
              component={RouterLink}
              to={`/organizations/-/projects/-/${ProjectPage.NETWORK}/access`}
            >
              network access settings
            </Link>{' '}
            or read our{' '}
            <Link href={PUBLIC_DOCS.AWS_PRIVATELINK} target="_blank" rel="noreferrer">
              docs
            </Link>
            .
          </Typography>
        }
      />
    </Box>
  );
}
