import {Box} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import {getPastInvoices} from '../../../actions/organizationActions';
import BillingPlans from '../../../components/billing/plans/BillingPlans/BillingPlans';
import {Plans} from '../../../constants';
import {useDashboardApi} from '../../../hooks/use-api';
import {usePastInvoices, usePastInvoicesAreLoading} from '../../../selectors/billing';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useIsSelectedOrganiztionMarketplace,
  useSelectedOrganizationPlan,
} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import BillingDetails from './BillingDetails/BillingDetails';
import PaymentHistory from './PaymentHistory/PaymentHistory';

function BillingPage() {
  const selectedOrgId = useSelectedOrganizationId();
  const isMarketplace = useIsSelectedOrganiztionMarketplace();
  const plan = useSelectedOrganizationPlan();
  const isOwner = useIsOwnerOfOrganization();
  const isInvoiceDataLoading = usePastInvoicesAreLoading();

  const shouldFetchInvoices =
    isOwner && !(isMarketplace || [Plans.DEDICATED, Plans.INTERNAL].includes(plan));
  useDashboardApi(getPastInvoices, {organizationId: selectedOrgId || ''}, shouldFetchInvoices);
  const pastInvoices = usePastInvoices() || [];
  const shouldShowInvoices = shouldFetchInvoices && pastInvoices.length > 0;

  const isSubscribed = useIsSelectedOrganizationSubscribed();

  if (!isOwner) {
    return (
      <Alert title="Unauthorized" description="You must be an organization owner to view billing" />
    );
  }

  if (!isSubscribed && !isMarketplace) {
    return (
      <Box mt={6}>
        <BillingPlans />
        {shouldShowInvoices ? (
          <PaymentHistory pastInvoices={pastInvoices} isLoading={isInvoiceDataLoading} />
        ) : (
          ''
        )}
      </Box>
    );
  }

  return (
    <Box mt={6} id="billingPage">
      <BillingDetails />
      {shouldShowInvoices ? (
        <PaymentHistory pastInvoices={pastInvoices} isLoading={isInvoiceDataLoading} />
      ) : (
        ''
      )}
    </Box>
  );
}

export default BillingPage;
