import {Box, Card, CardContent, Typography} from '@mui/material';
import {type Model} from '../../../../components/models/constants';

interface SelectableModelCardProps {
  model: Model;
  isSelected: boolean;
  onSelect: () => void;
}

const SelectableModelCard = ({model, isSelected, onSelect}: SelectableModelCardProps) => {
  return (
    <Card
      onClick={onSelect}
      sx={{
        borderWidth: isSelected ? 2 : 1,
        borderColor: isSelected ? 'primary.main' : 'divider',
        cursor: 'pointer',
        width: '49%',
        padding: 1,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            component="img"
            src={model.icon}
            alt={`${model.name} icon`}
            sx={{width: 35, height: 35}}
          />
          <Typography variant="h6" ml={1}>
            {model.name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="start" gap={5}>
          <Box>
            <Typography variant="body2" color="secondary">
              TASK
            </Typography>
            <Typography variant="body2">{model.task}</Typography>
          </Box>
          {model.modality && (
            <Box>
              <Typography variant="body2" color="secondary">
                MODALITY
              </Typography>
              <Typography variant="body2">{model.modality}</Typography>
            </Box>
          )}
          {model.maxInputTokens && (
            <Box>
              <Typography variant="body2" color="secondary">
                MAX TOKENS
              </Typography>
              <Typography variant="body2">{model.maxInputTokens}</Typography>
            </Box>
          )}
          {model.freeTier && (
            <Box>
              <Typography variant="body2" color="secondary">
                FREE TIER
              </Typography>
              <Typography variant="body2">{model.freeTier}</Typography>
            </Box>
          )}
          {model.price && (
            <Box>
              <Typography variant="body2" color="secondary">
                PRICE
              </Typography>
              <Typography variant="body2">{model.price}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SelectableModelCard;
