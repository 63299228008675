import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Paper, Typography} from '@mui/material';
import {QuotaKeys} from '@pinecone-experience/commons/quotas';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {AssistantQuotaKeys} from '../../../constants';
import {type UsageQuota} from '../../../types';
import {QuotaUtilizationItem} from './QuotaUtilizationItem/QuotaUtilizationItem';
import {
  AssistantCompletionTokensTooltip,
  AssistantPromptTokensTooltip,
  AssistantQueriesTooltip,
  AssistantStorageTooltip,
  RuTooltip,
  StorageTooltip,
  WuTooltip,
} from './Tooltips/Tooltips';
import {type EnrichedUsageQuota, UsageQuotaEffects} from './types';

interface QuotaUtilizationTrackerProps {
  quotas: UsageQuota[];
  title: string;
  action?: React.ReactNode;
  titleAction?: React.ReactNode;
  isLoading?: boolean;
  contentHeight?: number;
  getBlockedText: (
    enrichedQuotas: EnrichedUsageQuota[],
  ) => {header: string; body: React.ReactNode} | null;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 2,
    pt: 1.75, // Offset IconButton's margin
  },
  quotaUtilizationItemsContainer: (contentHeight?: number) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: contentHeight ?? 'auto',
    gap: 2,
  }),
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  action: {
    mt: 1,
    color: 'text.primary',
    width: '100%',
  },
};

export const QuotaUtilizationInfoMap: Record<
  Partial<QuotaKeys | AssistantQuotaKeys>,
  Omit<EnrichedUsageQuota, 'utilization' | 'limit'>
> = {
  [QuotaKeys.SERVERLESS_STORAGE_GB_PER_PROJECT]: {
    key: QuotaKeys.SERVERLESS_STORAGE_GB_PER_PROJECT,
    name: 'Storage',
    effects: [UsageQuotaEffects.WRITE],
    tooltip: StorageTooltip,
    unit: 'GB',
  },
  [QuotaKeys.READ_UNITS_PER_MONTH]: {
    key: QuotaKeys.READ_UNITS_PER_MONTH,
    name: 'Read Units',
    abbreviation: 'RUs',
    effects: [UsageQuotaEffects.READ],
    tooltip: RuTooltip,
  },
  [QuotaKeys.WRITE_UNITS_PER_MONTH]: {
    key: QuotaKeys.WRITE_UNITS_PER_MONTH,
    name: 'Write Units',
    abbreviation: 'WUs',
    effects: [UsageQuotaEffects.WRITE],
    tooltip: WuTooltip,
  },
  [AssistantQuotaKeys.STORAGE_GB_PER_PROJECT]: {
    key: AssistantQuotaKeys.STORAGE_GB_PER_PROJECT,
    name: 'Storage',
    effects: [UsageQuotaEffects.WRITE],
    tooltip: AssistantStorageTooltip,
    unit: 'GB',
  },
  [AssistantQuotaKeys.QUERIES_PER_MONTH]: {
    key: AssistantQuotaKeys.QUERIES_PER_MONTH,
    name: 'Queries',
    effects: [UsageQuotaEffects.READ],
    tooltip: AssistantQueriesTooltip,
  },
  [AssistantQuotaKeys.PROMPT_TOKENS]: {
    key: AssistantQuotaKeys.PROMPT_TOKENS,
    name: 'Input Tokens',
    effects: [UsageQuotaEffects.READ],
    tooltip: AssistantPromptTokensTooltip,
  },
  [AssistantQuotaKeys.COMPLETIONS_TOKENS]: {
    key: AssistantQuotaKeys.COMPLETIONS_TOKENS,
    name: 'Output Tokens',
    effects: [UsageQuotaEffects.READ],
    tooltip: AssistantCompletionTokensTooltip,
  },
} as Record<QuotaKeys | AssistantQuotaKeys, Omit<EnrichedUsageQuota, 'utilization' | 'limit'>>;

function QuotaUtilizationTracker({
  quotas,
  isLoading,
  title,
  action,
  titleAction = null,
  contentHeight,
  getBlockedText,
}: QuotaUtilizationTrackerProps) {
  const enrichedQuotas = quotas
    .filter((quota) => quota.key in QuotaUtilizationInfoMap)
    .map((quota) => {
      return {
        ...quota,
        ...QuotaUtilizationInfoMap[quota.key],
      };
    });
  const blocked = getBlockedText(enrichedQuotas);

  if (!isLoading && !quotas.length) {
    return null;
  }

  return (
    <Paper sx={styles.root}>
      <Box sx={styles.row}>
        <Typography variant="overline">{title}</Typography>
        <Box ml="auto">
          {blocked ? <WarningAmberIcon fontSize="small" color="error" /> : titleAction}
        </Box>
      </Box>
      {blocked && (
        <Typography>
          <strong>{blocked.header}</strong> – {blocked.body}
        </Typography>
      )}
      <LoadingContainer loading={isLoading}>
        <Box sx={styles.quotaUtilizationItemsContainer(contentHeight)}>
          {enrichedQuotas.map((quota) => (
            <QuotaUtilizationItem key={quota.key} enrichedQuota={quota} />
          ))}
        </Box>
      </LoadingContainer>
      {action && <Box sx={styles.row}>{action}</Box>}
    </Paper>
  );
}
export default QuotaUtilizationTracker;
