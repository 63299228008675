import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import {Box, Button, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {clearCreatedApiKey} from '../../../actions/apiKeyActions';
import {RegistrationFormContext} from '../../../context/registration-form';
import {useBooleanState} from '../../../hooks/utils';
import {useCreatedApiKey} from '../../../selectors/keys';
import {useSelectedGlobalProjectId} from '../../../selectors/params';
import {MODAL_WIDTH_MEDIUM} from '../../../styles/theme';

const API_KEY_REDACTED = '********-****-****-****-************';

function ApiKeyCreatedModal() {
  const dispatch = useDispatch();
  const selectedProjectId = useSelectedGlobalProjectId();
  const createdKey = useCreatedApiKey(selectedProjectId);
  const clearKey = () => {
    dispatch(clearCreatedApiKey({globalProjectId: selectedProjectId}));
  };
  const {val: isShowing, setFalse: onClickHide, setTrue: onClickShow} = useBooleanState();
  const {isShowingRegistration} = useContext(RegistrationFormContext);
  return (
    <BaseModal open={!!createdKey && !isShowingRegistration} onClose={() => {}}>
      <Box width={MODAL_WIDTH_MEDIUM}>
        {createdKey?.data && (
          <>
            <Typography variant="h5">API key generated</Typography>
            <Typography my={2}>
              Your
              <Typography display="inline" fontWeight="bold">
                {` "${createdKey?.data?.display_label}" `}
              </Typography>
              API key has been generated.
            </Typography>
            <CodeBlock
              code={isShowing ? createdKey?.data?.value || '' : API_KEY_REDACTED}
              controls={
                isShowing ? (
                  <IconButton
                    id="hide-api-key"
                    tooltip="Hide Key Value"
                    onClick={onClickHide}
                    icon={<VisibilityIcon color="inherit" fontSize="small" />}
                  />
                ) : (
                  <IconButton
                    id="show-api-key"
                    tooltip="Show Key Value"
                    onClick={onClickShow}
                    icon={<VisibilityOffIcon color="inherit" fontSize="small" />}
                  />
                )
              }
              copyButtonValue={createdKey?.data?.value || ''}
              language="plaintext"
              showLineNumbers={false}
            />
            <Alert
              severity="warning"
              title="You must copy and save this key immediately"
              description="For security reasons, we will not show you this key again after you close this dialog. Please save it somewhere secure for future use."
            />
          </>
        )}
        {createdKey?.error && <Alert severity="error" title="API key creation failed" />}
        <Box display="flex" justifyContent="end">
          <Button variant="contained" onClick={clearKey}>
            Close
          </Button>
        </Box>
      </Box>
    </BaseModal>
  );
}

export default ApiKeyCreatedModal;
