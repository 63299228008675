import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import {useCallback} from 'react';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {useBooleanState} from '../../../../../../hooks/utils';
import {useIsOwnerOfOrganization} from '../../../../../../selectors/organizations';
import {type Integration, type IntegrationUpdateData} from '../../../../../../types';
import IntegrationForm from '../../../IntegrationForm/IntegrationForm';
import {type UpdateIntegrationFormData} from '../../../IntegrationForm/utilities/schema';

type IntegrationActionsProps = {
  /** The integration to be acted upon. */
  integration: Integration;
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: IntegrationUpdateData) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** Actions that may be taken for a given integration. */
function IntegrationActions({integration, onEdit, onDelete}: IntegrationActionsProps) {
  const {val: editFormOpen, setTrue: showEditForm, setFalse: hideEditForm} = useBooleanState(false);
  const {
    val: deleteModalOpen,
    setTrue: showDeleteModal,
    setFalse: hideDeleteModal,
  } = useBooleanState(false);

  const onSubmit = useCallback(
    (data: UpdateIntegrationFormData) => {
      const updateData: IntegrationUpdateData = {
        name: data.name,
        logo: data.logo,
        return_mechanism: data.return_mechanism,
        allowed_origins: data.allowed_origins.map((origin) => origin.origin),
      };
      onEdit(integration.id, updateData);
    },
    [integration.id, onEdit],
  );

  const doDelete = useCallback(() => {
    onDelete(integration.id);
    hideDeleteModal();
  }, [hideDeleteModal, integration.id, onDelete]);

  const isOrgOwner = useIsOwnerOfOrganization();

  return (
    <>
      <IconButton
        icon={<EditIcon fontSize="small" />}
        id={`edit-integration-${integration.slug}`}
        tooltip={
          isOrgOwner
            ? 'Edit integration details'
            : 'You must be an organization owner to edit an integration'
        }
        border
        onClick={showEditForm}
        disabled={!isOrgOwner}
      />
      <IntegrationForm
        open={editFormOpen}
        close={hideEditForm}
        onSubmit={onSubmit}
        submitText="Save Changes"
        title="Edit integration"
        initialValues={integration}
      />
      <IconButton
        icon={<DeleteOutlineIcon fontSize="small" />}
        id={`delete-integration-${integration.slug}`}
        tooltip={
          isOrgOwner
            ? 'Delete integration'
            : 'You must be an organization owner to delete an integration'
        }
        border
        onClick={showDeleteModal}
        disabled={!isOrgOwner}
      />
      <DeleteConfirmationModal
        open={deleteModalOpen}
        close={hideDeleteModal}
        doDelete={doDelete}
        itemType="Integration"
        name={integration.slug}
      />
    </>
  );
}

export default IntegrationActions;
