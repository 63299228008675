import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type Backup} from '../types';
import {formatPluralAmount} from '../utils/format';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const listBackupsRequest = createAction<{globalProjectId: string}>('LIST_BACKUPS_REQUEST');
export const listBackupsSuccess = createAction<{globalProjectId: string; backups: Backup[]}>(
  'LIST_BACKUPS_SUCCESS',
);
export const listBackupsFailure = createAction<{globalProjectId: string; error: string}>(
  'LIST_BACKUPS_FAILURE',
);

export function listBackups(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listBackupsRequest({globalProjectId: data.globalProjectId}));
    return api
      .listBackups()
      .then((res) => {
        dispatch(listBackupsSuccess({globalProjectId: data.globalProjectId, backups: res.backups}));
      })
      .catch((err) => {
        dispatch(listBackupsFailure({globalProjectId: data.globalProjectId, error: err.message}));
      });
  };
}

export const createBackupRequest = createAction<{globalProjectId: string}>('CREATE_BACKUP_REQUEST');
export const createBackupSuccess = createAction<{globalProjectId: string; backup: Backup}>(
  'CREATE_BACKUP_SUCCESS',
);
export const createBackupFailure = createAction<{globalProjectId: string; error: string}>(
  'CREATE_BACKUP_FAILURE',
);

export function createBackup(
  api: ControllerApi,
  data: {globalProjectId: string; indexId: string; name: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(createBackupRequest({globalProjectId: data.globalProjectId}));
    return api
      .createBackup(data.indexId, data.name)
      .then((res) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Backup ${data.name} creation initialized.`,
          }),
        );
        dispatch(createBackupSuccess({globalProjectId: data.globalProjectId, backup: res}));
      })
      .catch((err) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Backup failed to create. ${decodeErrorMessage(err.message)}`,
          }),
        );
        dispatch(createBackupFailure({globalProjectId: data.globalProjectId, error: err.message}));
      });
  };
}

export const deleteBackupsRequest = createAction<{globalProjectId: string; backupIds: string[]}>(
  'DELETE_BACKUP_REQUEST',
);
export const deleteBackupsSuccess = createAction<{globalProjectId: string; backupIds: string[]}>(
  'DELETE_BACKUPS_SUCCESS',
);
export const deleteBackupSuccess = createAction<{globalProjectId: string; backupId: string}>(
  'DELETE_BACKUP_SUCCESS',
);
export const deleteBackupsFailure = createAction<{globalProjectId: string; error: string}>(
  'DELETE_BACKUP_FAILURE',
);

export function deleteBackups(
  api: ControllerApi,
  data: {globalProjectId: string; backupIds: string[]},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      deleteBackupsRequest({globalProjectId: data.globalProjectId, backupIds: data.backupIds}),
    );
    return Promise.all(
      data.backupIds.map((backupId) => {
        return api.deleteBackup(backupId).then(() => {
          dispatch(deleteBackupSuccess({globalProjectId: data.globalProjectId, backupId}));
        });
      }),
    )
      .then(() => {
        dispatch(
          deleteBackupsSuccess({globalProjectId: data.globalProjectId, backupIds: data.backupIds}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Deleted ${formatPluralAmount(data.backupIds.length, 'backup', 'backups')}`,
          }),
        );
      })
      .catch((err) => {
        dispatch(deleteBackupsFailure({globalProjectId: data.globalProjectId, error: err.message}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Some backups failed to delete`,
          }),
        );
      });
  };
}

export const getBackupRequest = createAction<{globalProjectId: string; backupId: string}>(
  'GET_BACKUP_REQUEST',
);
export const getBackupSuccess = createAction<{globalProjectId: string; backup: Backup}>(
  'GET_BACKUP_SUCCESS',
);
export const getBackupFailure = createAction<{
  globalProjectId: string;
  backupId: string;
  error: string;
}>('GET_BACKUP_FAILURE');

export function getBackup(api: ControllerApi, data: {globalProjectId: string; backupId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getBackupRequest({globalProjectId: data.globalProjectId, backupId: data.backupId}));
    return api
      .getBackup(data.backupId)
      .then((res) => {
        dispatch(getBackupSuccess({globalProjectId: data.globalProjectId, backup: res}));
      })
      .catch((err) => {
        dispatch(
          getBackupFailure({
            globalProjectId: data.globalProjectId,
            backupId: data.backupId,
            error: err.message,
          }),
        );
      });
  };
}
