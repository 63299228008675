import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {Box, Typography} from '@mui/material';
import LinearProgress from '@pinecone-experience/timber/Progress/LinearProgress';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {formatLargeNumber} from '../../../../utils/format';
import {type EnrichedUsageQuota} from '../types';

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  progress: {
    mt: 0.5,
    height: 6,
    borderRadius: 1,
    backgroundColor: 'action.selected',
  },
};

const displayNumber = (limit: number, unit: string = '') => {
  return `${formatLargeNumber(limit)}${unit}`;
};

export const QuotaUtilizationItem = ({enrichedQuota}: {enrichedQuota: EnrichedUsageQuota}) => {
  const {key, name, abbreviation, tooltip, utilization, limit, unit} = enrichedQuota;
  const progress = Math.min((utilization / limit) * 100, 100);
  const highUtilization = progress >= 80;
  const limitReached = progress >= 100;

  return (
    <Box key={name} data-testid={key}>
      <Box sx={styles.row}>
        <Box sx={styles.row}>
          <Typography fontWeight="bold" variant="body2">
            {abbreviation || name}
          </Typography>

          <Tooltip placement="top-start" title={tooltip({limit})}>
            <InfoOutlined fontSize="inherit" color="secondary" />
          </Tooltip>
        </Box>
        {limitReached ? (
          <Typography
            fontWeight="bold"
            variant="body2"
            ml="auto"
            color="error"
            noWrap
            textOverflow="unset"
            overflow="visible"
          >
            {displayNumber(limit, unit)} / {displayNumber(limit, unit)}
          </Typography>
        ) : (
          <Typography
            fontWeight="bold"
            variant="body2"
            ml="auto"
            color={highUtilization ? 'error' : undefined}
            noWrap
            textOverflow="unset"
            overflow="visible"
          >
            {displayNumber(utilization)} / {displayNumber(limit, unit)}
          </Typography>
        )}
      </Box>
      <LinearProgress value={progress} error={highUtilization} />
    </Box>
  );
};
