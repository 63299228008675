import {useEffect} from 'react';
import {
  editOrgRole,
  exitOrganization,
  getOrganizationUsers,
  getPendingInvites,
  removeUserFromOrg,
} from '../../../../actions/organizationActions';
import UserTable from '../../../../components/UserTable';
import {type Roles} from '../../../../constants';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useInvitesAreLoading, useSelectedOrgPendingInvites} from '../../../../selectors/invites';
import {
  useIsOnlyOwner,
  useOrgMembers,
  useSelectedOrganization,
} from '../../../../selectors/organizations';
import {useUserDataIsLoading} from '../../../../selectors/users';
import {type Invite} from '../../../../types';

const OrganizationUsersTable = () => {
  const organization = useSelectedOrganization() || {};
  const {setQueryData} = useDashboardApi<{organizationId: string}>();
  const {setQueryData: setQueryDataInvites} = useDashboardApi<{organizationId: string}>();
  const invitesAreLoading = useInvitesAreLoading();
  useEffect(() => {
    if (organization.id) {
      setQueryData({action: getOrganizationUsers, data: {organizationId: organization.id}});
      setQueryDataInvites({action: getPendingInvites, data: {organizationId: organization.id}});
    }
  }, [organization.id, setQueryData, setQueryDataInvites]);
  let pendingInvites = useSelectedOrgPendingInvites() || [];
  pendingInvites = pendingInvites.map((invite: Invite) => ({
    ...invite,
    pending: true,
  }));

  const {setQueryData: setQueryDataExit} = useDashboardApi<{
    organizationId: string;
  }>();
  const handleExitOrganization = () => {
    setQueryDataExit({
      action: exitOrganization,
      data: {organizationId: organization.id},
    });
  };
  const {setQueryData: setQueryDataRemoveUser} = useDashboardApi<{
    organizationId: string;
    userId: string;
  }>();
  const handleRemoveUser = (user: string) => {
    setQueryDataRemoveUser({
      action: removeUserFromOrg,
      data: {
        organizationId: organization.id,
        userId: user,
      },
    });
  };
  const {setQueryData: setQueryDataEdit} = useDashboardApi<{
    organizationId: string;
    userId: string;
    role: Roles;
  }>();
  const handleEditUser = (role: Roles, user: string) => {
    setQueryDataEdit({
      action: editOrgRole,
      data: {
        organizationId: organization.id,
        userId: user,
        role,
      },
    });
  };
  const userDataLoading = useUserDataIsLoading();
  const userIds = useOrgMembers(organization.id);
  const isOnlyOwner = useIsOnlyOwner();
  return (
    <UserTable
      userIds={userIds.map((user) => user.userId)}
      invites={pendingInvites}
      isLoading={userDataLoading || invitesAreLoading}
      handleEditUser={handleEditUser}
      handleRemoveUser={handleRemoveUser}
      handleExit={handleExitOrganization}
      canLeave={!isOnlyOwner}
    />
  );
};

export default OrganizationUsersTable;
