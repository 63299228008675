import {Box, Paper, Typography} from '@mui/material';
import {type PropsWithChildren} from 'react';
import ThemeAwarePineconeLogo from '../../Utilities/ThemeAwarePineconeLogo';

type PopupFrameProps = PropsWithChildren<{
  header: string;
  subheader?: string;
}>;

const styles = {
  page: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(240, 243, 247)',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    p: 3,
    '@media (min-width: 52.5em)': {
      width: 461,
      p: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  header: {
    fontFamily: 'MediumLLWeb, sans-serif',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#121142',
    mb: 1.5,
  },
  subheader: {
    fontFamily: 'MediumLLWeb, sans-serif',
    fontSize: '18px',
    mt: 0,
    fontWeight: 'normal',
    color: '#121142',
    mb: 2.25,
  },
  logo: {
    height: 40,
    mt: 1.5,
    mb: 3,
  },
  card: {
    p: 5,
    border: 0,
    borderRadius: '10px',
    boxShadow: 8,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 52.5em)': {
      minHeight: 485,
      width: '100%',
      flexGrow: 0,
      boxShadow: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

/**
 * A page layout that is designed to flow seamlessly with the authentication flow. Intended for
 * use in authorization flows, which frequently open in a popup window.
 */
function AuthPage({header, subheader, children}: PopupFrameProps) {
  return (
    <Box sx={styles.page}>
      <Box sx={styles.container}>
        <Typography variant="h1" sx={styles.header}>
          {header}
        </Typography>
        {subheader && (
          <Typography variant="h2" sx={styles.subheader}>
            {subheader}
          </Typography>
        )}
        <Paper sx={styles.card}>
          <ThemeAwarePineconeLogo fullLogo sx={styles.logo} />
          <Box sx={styles.content}>{children}</Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default AuthPage;
