export const multilingualE5LargeMarkdown = `
### Overview 
A powerful text embedding model trained for retrieval with support for 100+ languages. 
Good for short queries expected to return medium-length passages of text (1-2 paragraphs).
For best performance on retrieval tasks, set \`input_type\` parameter to either \`query\` or \`passage\`. 
You can learn more about the model with the following resources:
- [HuggingFace](https://huggingface.co/intfloat/multilingual-e5-large)
- [Multilingual E5 Text Embeddings: A Technical Report](https://arxiv.org/abs/2402.05672)

### Create Index


### Installation: 

\`\`\`python
!pip install --upgrade pinecone-client
\`\`\`

### Embed & Upsert

\`\`\`python
from pinecone import Pinecone

pc = Pinecone("API-KEY")

# Embed data
data = [
    {"id": "vec1", "text": "Apple is a popular fruit known for its sweetness and crisp texture."},
    {"id": "vec2", "text": "The tech company Apple is known for its innovative products like the iPhone."},
    {"id": "vec3", "text": "Many people enjoy eating apples as a healthy snack."},
    {"id": "vec4", "text": "Apple Inc. has revolutionized the tech industry with its sleek designs and user-friendly interfaces."},
    {"id": "vec5", "text": "An apple a day keeps the doctor away, as the saying goes."},
]

embeddings = pc.inference.embed(
    "multilingual-e5-large",
    inputs=[d['text'] for d in data],
    parameters={
        "input_type": "passage"
    }
)

vectors = []
for d, e in zip(data, embeddings):
    vectors.append({
        "id": d['id'],
        "values": e['values'],
        "metadata": {'text': d['text']}
    })

index.upsert(
    vectors=vectors,
    namespace="ns1"
)
\`\`\`

### Query
\`\`\`python
query = "Tell me about the tech company known as Apple"

x = pc.inference.embed(
    model="multilingual-e5-large",
    inputs=[query],
    parameters={
        "input_type": "query"
    }
)

results = index.query(
    namespace="ns1",
    vector=x[0].values,
    top_k=3,
    include_values=False,
    include_metadata=True
)

print(results)
\`\`\`

### Citation

\`\`\`LaTeX
@article{wang2024multilingual,
  title={Multilingual E5 Text Embeddings: A Technical Report},
  author={Wang, Liang and Yang, Nan and Huang, Xiaolong and Yang, Linjun and Majumder, Rangan and Wei, Furu},
  journal={arXiv preprint arXiv:2402.05672},
  year={2024}
}
\`\`\`

`;

export const bgeRerankerV2M3Markdown = `
### Overview 

This is an open source, high performance, multilingual model. It works well on messy data and short queries expected to return medium-length passages of text (1-2 paragraphs). 
This model returns a relevance score for a query and passage. A sigmoid function can map the relevance score to a float value in the range [0,1]. 
Reranking models are designed to provide superior accuracy over retriever models but are much slower, so this model should not be used with more than a few hundred documents. 
Due to the slowness of rerankers, we recommend using them in a two-stage retrieval system: use a retrieval to pull in a smaller number of documents from a larger database and then rerank the smaller number of documents using a reranker.
You can learn more about the model with the following resources:
- [HuggingFace](https://huggingface.co/BAAI/bge-reranker-v2-m3)
- [BGE M3-Embedding: Multi-Lingual, Multi-Functionality, Multi-Granularity Text Embeddings Through Self-Knowledge Distillation](https://arxiv.org/abs/2402.03216)

### Installation

\`\`\`bash
pip install -U pinecone-client
pip install -U --pre pinecone-plugin-inference==1.1.0.* # Release candidate
\`\`\`

### Rerank

\`\`\`python
from pinecone import Pinecone

pc = Pinecone("API-KEY")

query = "Tell me about Apple's products"
results = pc.inference.rerank(
    model="bge-reranker-v2-m3",
    query=query,
    documents=[
        "Apple is a popular fruit known for its sweetness and crisp texture.",	
        "Apple is known for its innovative products like the iPhone.",
        "Many people enjoy eating apples as a healthy snack.",
        "Apple Inc. has revolutionized the tech industry with its sleek designs and user-friendly interfaces.",
        "An apple a day keeps the doctor away, as the saying goes.",
    ],
    top_n=3,
    return_documents=True,
)
print(query)
for r in results.data:
    print(r.score, r.document.text)
\`\`\`

### Citation

\`\`\`LaTeX
@misc{li2023making,
      title={Making Large Language Models A Better Foundation For Dense Retrieval}, 
      author={Chaofan Li and Zheng Liu and Shitao Xiao and Yingxia Shao},
      year={2023},
      eprint={2312.15503},
      archivePrefix={arXiv},
      primaryClass={cs.CL}
}
@misc{chen2024bge,
      title={BGE M3-Embedding: Multi-Lingual, Multi-Functionality, Multi-Granularity Text Embeddings Through Self-Knowledge Distillation}, 
      author={Jianlv Chen and Shitao Xiao and Peitian Zhang and Kun Luo and Defu Lian and Zheng Liu},
      year={2024},
      eprint={2402.03216},
      archivePrefix={arXiv},
      primaryClass={cs.CL}
}

\`\`\`
`;
