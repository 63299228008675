import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Paper, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {createOrganization} from '../actions/organizationActions';
import {useDashboardApi} from '../hooks/use-api';
import {useUserId} from '../selectors/userSession';

const styles = {
  container: {
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
    backgroundColor: 'background.default',
  },
  card: {
    p: 3,
    minWidth: 300,
    width: '50%',
    maxWidth: 1200,
  },
};

type FormValues = {
  name: string;
};

const schema = z.object({
  name: z.string().max(100, 'Name must be 100 characters or less'),
});

function CreateOrganizationPage() {
  const {
    handleSubmit,
    formState: {isValid},
    control,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(schema),
  });
  const {setQueryData} = useDashboardApi<{name: string; userId: string}>();
  const userId = useUserId();
  const onSubmitNewOrg: SubmitHandler<FormValues> = (formData) => {
    setQueryData({
      action: createOrganization,
      data: {
        name: formData.name,
        userId: userId || '',
      },
    });
  };
  return (
    <Box sx={styles.container}>
      <Paper sx={styles.card}>
        <Typography variant="h5">Create Organization</Typography>
        <Box height={24} />
        <TextInput
          name="name"
          control={control}
          required
          autoFocus
          label="Organization Name"
          placeholder={"Enter your organization's name"}
          fullWidth
        />
        <Box height={24} />
        <Button
          type="submit"
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit(onSubmitNewOrg)}
          sx={{float: 'right'}}
        >
          Create
        </Button>
      </Paper>
    </Box>
  );
}

export default CreateOrganizationPage;
