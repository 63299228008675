import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {type Theme} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useCallback, useEffect, useRef, useState} from 'react';
import {type FileRejection, useDropzone} from 'react-dropzone';
import {useBooleanState} from '../../../../hooks/utils';
import {type AssistantFile} from '../../../../types';
import {ImportModal} from '../../ImportModal/ImportModal';
import FilesTable from './FilesTable/FilesTable';

type FilesTabProps = {
  assistantName: string;
  files: AssistantFile[];
  loading?: boolean;
  importFiles: (files: File[]) => void;
  getFilePath: (id: string) => string;
};

const MAX_FILES_UPLOAD = 10;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    mb: 2,
  },
  dropzone: {
    flexGrow: 1,
  },
  dropContainer: {
    cursor: 'pointer',
    backgroundColor: 'background.surface',
    display: 'flex',
    placeItems: 'center',
    height: '100%',
    textAlign: 'center',
  },
  acceptDrop: {
    borderColor: 'primary.main',
  },
  rejectDrop: {
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
};

export default function FilesTab({
  assistantName,
  files,
  loading,
  importFiles,
  getFilePath,
}: FilesTabProps) {
  const tabRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const {
    setTrue: openFilesModal,
    setFalse: closeFilesModal,
    val: showFilesModal,
  } = useBooleanState();

  const onRemoveAcceptedFile = useCallback(
    (fileName) => {
      setAcceptedFiles(acceptedFiles.filter((file) => file.name !== fileName));
    },
    [acceptedFiles, setAcceptedFiles],
  );

  const onDrop = useCallback(
    (accepted, rejected) => {
      setAcceptedFiles([...acceptedFiles, ...accepted]);
      setRejectedFiles([...rejectedFiles, ...rejected]);
      openFilesModal();
    },
    [acceptedFiles, rejectedFiles, openFilesModal],
  );

  const onCancel = useCallback(() => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
    closeFilesModal();
  }, [closeFilesModal, setAcceptedFiles, setRejectedFiles]);

  const {getRootProps, getInputProps, isDragAccept, isDragReject, open} = useDropzone({
    noClick: !!files.length || showFilesModal,
    onDrop,
    accept: {'application/pdf': [], 'text/plain': []},
  });

  useEffect(() => {
    if (!acceptedFiles.length && !rejectedFiles.length && showFilesModal) {
      closeFilesModal();
    }
  }, [acceptedFiles.length, rejectedFiles.length, showFilesModal, closeFilesModal]);

  if (loading && !files.length) {
    return (
      <Box sx={styles.root}>
        <LoadingContainer loading />
      </Box>
    );
  }

  return (
    <Box sx={styles.root} ref={tabRef}>
      <Box sx={styles.header}>
        <Typography component="h2">Create and manage documents for your assistant</Typography>
        <Button variant="contained" onClick={open} disabled={loading}>
          Upload
        </Button>
      </Box>
      <Box {...getRootProps()} sx={styles.dropzone} data-testid="dropzone">
        <input {...getInputProps()} data-testid="dropzone-input" />
        {files.length ? (
          <FilesTable files={files} getFilePath={getFilePath} loading={loading} />
        ) : (
          <Card
            sx={[
              styles.dropContainer,
              isDragAccept && !showFilesModal ? styles.acceptDrop : null,
              isDragReject && !showFilesModal ? styles.rejectDrop : null,
            ]}
          >
            <Box width="100%">
              <CloudUploadOutlinedIcon fontSize="large" color="secondary" />
              <Typography mb={1}>
                <strong>Drag files here or click to browse</strong>
              </Typography>
              <Typography color="secondary">
                Accepted file types: PDF, txt. Limit 10 files per upload.
              </Typography>
            </Box>
          </Card>
        )}
      </Box>

      <ImportModal
        open={showFilesModal}
        container={tabRef.current}
        loading={loading}
        assistantName={assistantName}
        maxFiles={MAX_FILES_UPLOAD}
        acceptedFiles={acceptedFiles}
        rejectedFiles={rejectedFiles}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        getDropzoneProps={getRootProps}
        onClose={onCancel}
        onAddFiles={open}
        onRemoveAcceptedFile={onRemoveAcceptedFile}
        onImportFiles={importFiles}
      />
    </Box>
  );
}
