// import Stripe from 'stripe';

/**
 * Options are taken from the Stripe code and need to be manually updated if new ones are added
 *
 * Descriptions were lifted and slightly modified from their website:
 * https://stripe.com/docs/billing/customer/tax-ids
 */
export const TAX_IDS = [
  'ad_nrt',
  'ae_trn',
  'ar_cuit',
  'au_abn',
  'au_arn',
  'bg_uic',
  'bo_tin',
  'br_cnpj',
  'br_cpf',
  'ca_bn',
  'ca_gst_hst',
  'ca_pst_bc',
  'ca_pst_mb',
  'ca_pst_sk',
  'ca_qst',
  'ch_vat',
  'cl_tin',
  'cn_tin',
  'co_nit',
  'cr_tin',
  'do_rcn',
  'ec_ruc',
  'eg_tin',
  'es_cif',
  'eu_oss_vat',
  'eu_vat',
  'gb_vat',
  'ge_vat',
  'hk_br',
  'hu_tin',
  'id_npwp',
  'il_vat',
  'in_gst',
  'is_vat',
  'jp_cn',
  'jp_rn',
  'jp_trn',
  'ke_pin',
  'kr_brn',
  'li_uid',
  'mx_rfc',
  'my_frp',
  'my_itn',
  'my_sst',
  'no_vat',
  'nz_gst',
  'pe_ruc',
  'ph_tin',
  'ro_tin',
  'rs_pib',
  'ru_inn',
  'ru_kpp',
  'sa_vat',
  'sg_gst',
  'sg_uen',
  'si_tin',
  'sv_nit',
  'th_vat',
  'tr_tin',
  'tw_vat',
  'ua_vat',
  'us_ein',
  'uy_ruc',
  've_rif',
  'vn_tin',
  'za_vat',
] as const;

// This type should be a reflection of Stripe's Stripe.TaxId.Type value
export type TaxId = (typeof TAX_IDS)[number];

/**
 * Commented out options are not yet supported by Orb, but supported by Stripe
 *
 * Commenting out prevents the options from appearing in the billing pages dropdowns
 */
export const COUNTRY_TAX_IDS: Record<string, TaxId[]> = Object.freeze({
  AD: ['ad_nrt'],
  AE: ['ae_trn'],
  // AR: ['ar_cuit'],
  AT: ['eu_vat'],
  AU: ['au_abn', 'au_arn'],
  BE: ['eu_vat'],
  BG: ['bg_uic', 'eu_vat'],
  // BO: ['bo_tin'],
  BR: ['br_cnpj', 'br_cpf'],
  CA: ['ca_bn', 'ca_gst_hst', 'ca_pst_bc', 'ca_pst_mb', 'ca_pst_sk', 'ca_qst'],
  CH: ['ch_vat'],
  CL: ['cl_tin'],
  // CN: ['cn_tin'],
  // CO: ['co_nit'],
  // CR: ['cr_tin'],
  // CS: ['rs_pib'],
  CY: ['eu_vat'],
  CZ: ['eu_vat'],
  DE: ['eu_vat'],
  DK: ['eu_vat'],
  // DO: ['do_rcn'],
  // EC: ['ec_ruc'],
  EE: ['eu_vat'],
  EG: ['eg_tin'],
  ES: ['es_cif', 'eu_vat'],
  FI: ['eu_vat'],
  FR: ['eu_vat'],
  GB: ['eu_vat', 'gb_vat'],
  GE: ['ge_vat'],
  GR: ['eu_vat'],
  HK: ['hk_br'],
  HR: ['eu_vat'],
  HU: ['eu_vat', 'hu_tin'],
  ID: ['id_npwp'],
  IE: ['eu_vat'],
  IL: ['il_vat'],
  IN: ['in_gst'],
  IS: ['is_vat'],
  IT: ['eu_vat'],
  JP: ['jp_cn', 'jp_rn', 'jp_trn'],
  KE: ['ke_pin'],
  LI: ['li_uid'],
  LT: ['eu_vat'],
  LU: ['eu_vat'],
  LV: ['eu_vat'],
  MT: ['eu_vat'],
  MX: ['mx_rfc'],
  MY: ['my_frp', 'my_itn', 'my_sst'],
  NL: ['eu_vat'],
  NO: ['no_vat'],
  NZ: ['nz_gst'],
  // PE: ['pe_ruc'],
  PH: ['ph_tin'],
  PL: ['eu_vat'],
  PT: ['eu_vat'],
  RO: [
    'eu_vat',
    // 'ro_tin',
  ],
  RU: ['ru_inn', 'ru_kpp'],
  SA: ['sa_vat'],
  SE: ['eu_vat'],
  SG: ['sg_gst', 'sg_uen'],
  SI: ['eu_vat', 'si_tin'],
  SK: ['eu_vat'],
  // SV: ['sv_nit'],
  TH: ['th_vat'],
  TR: ['tr_tin'],
  TW: ['tw_vat'],
  UA: ['ua_vat'],
  // Product Decision - Hiding for US
  // US: ['us_ein'],
  // UY: ['uy_ruc'],
  // VE: ['ve_rif'],
  // VN: ['vn_tin'],
  ZA: ['za_vat'],
});

export type TaxCountryCode = keyof typeof COUNTRY_TAX_IDS;

export const TAX_ID_OPTIONS: Record<TaxId, {value: TaxId; label: string; description: string}> =
  Object.freeze({
    ad_nrt: {value: 'ad_nrt', label: 'AD NRT', description: 'Andorran NRT'},
    ae_trn: {value: 'ae_trn', label: 'AE TRN', description: 'United Arab Emirates TRN'},
    ar_cuit: {value: 'ar_cuit', label: 'AR CUIT', description: 'Argentinian tax ID'},
    au_abn: {
      value: 'au_abn',
      label: 'AU ABN',
      description: 'Australian Business Number (AU ABN)',
    },
    au_arn: {
      value: 'au_arn',
      label: 'AU ARN',
      description: 'Australian Taxation Office Reference Number',
    },
    bg_uic: {
      value: 'bg_uic',
      label: 'BG UIC',
      description: 'Bulgaria Unified Identification Code',
    },
    bo_tin: {value: 'bo_tin', label: 'BO TIN', description: 'Bolivian tax ID'},
    br_cnpj: {value: 'br_cnpj', label: 'BR CNPJ', description: 'Brazilian CNPJ'},
    br_cpf: {value: 'br_cpf', label: 'BR CPF', description: 'Brazilian CPF'},
    ca_bn: {value: 'ca_bn', label: 'CA BN', description: 'Canadian BN'},
    ca_gst_hst: {value: 'ca_gst_hst', label: 'CA GST HST', description: 'Canadian GST/HST'},
    ca_pst_bc: {
      value: 'ca_pst_bc',
      label: 'CA PST BC',
      description: 'Canadian PST number (British Columbia)',
    },
    ca_pst_mb: {
      value: 'ca_pst_mb',
      label: 'CA PST MB',
      description: 'Canadian PST number (Manitoba)',
    },
    ca_pst_sk: {
      value: 'ca_pst_sk',
      label: 'CA PST SK',
      description: 'Canadian PST number (Saskatchewan)',
    },
    ca_qst: {value: 'ca_qst', label: 'CA QST', description: 'Canadian QST number (Québec)'},
    ch_vat: {value: 'ch_vat', label: 'CH VAT', description: 'Switzerland VAT'},
    cl_tin: {value: 'cl_tin', label: 'CL TIN', description: 'Chilean TIN'},
    cn_tin: {value: 'cn_tin', label: 'CN TIN', description: 'Chinese tax ID'},
    co_nit: {value: 'co_nit', label: 'CO NIT', description: 'Colombian NIT'},
    cr_tin: {value: 'cr_tin', label: 'CR TIN', description: 'Costa Rican tax ID'},
    do_rcn: {value: 'do_rcn', label: 'DO RCN', description: 'Dominican RCN'},
    ec_ruc: {value: 'ec_ruc', label: 'EC RUC', description: 'Ecuadorian RUC'},
    eg_tin: {value: 'eg_tin', label: 'EG TIN', description: 'Egyptian Tax Identification Number'},
    es_cif: {
      value: 'es_cif',
      label: 'ES CIF',
      description: 'Spanish NIF number (previously Spanish CIF number)',
    },
    eu_oss_vat: {
      value: 'eu_oss_vat',
      label: 'EU OSS VAT',
      description: 'European One Stop Shop VAT number for non-Union scheme',
    },
    eu_vat: {value: 'eu_vat', label: 'EU VAT', description: 'European VAT'},
    gb_vat: {value: 'gb_vat', label: 'GB VAT', description: 'United Kingdom VAT'},
    ge_vat: {value: 'ge_vat', label: 'GE VAT', description: 'Georgian VAT'},
    hk_br: {value: 'hk_br', label: 'HK BR', description: 'Hong Kong BR'},
    hu_tin: {value: 'hu_tin', label: 'HU TIN', description: 'Hungary tax number (adószám)'},
    id_npwp: {value: 'id_npwp', label: 'ID NPWP', description: 'Indonesian NPWP'},
    il_vat: {value: 'il_vat', label: 'IL VAT', description: 'Israel VAT'},
    in_gst: {value: 'in_gst', label: 'IN GST', description: 'Indian GST'},
    is_vat: {value: 'is_vat', label: 'IS VAT', description: 'Icelandic VAT'},
    jp_cn: {
      value: 'jp_cn',
      label: 'JP CN',
      description: 'Japanese Corporate Number (*Hōjin Bangō*)',
    },
    jp_rn: {
      value: 'jp_rn',
      label: 'JP RN',
      description:
        "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    },
    jp_trn: {
      value: 'jp_trn',
      label: 'JP TRN',
      description: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
    },
    ke_pin: {
      value: 'ke_pin',
      label: 'KE PIN',
      description: 'Kenya Revenue Authority Personal Identification Number',
    },
    kr_brn: {value: 'kr_brn', label: 'KR BRN', description: 'Korean BRN'},
    li_uid: {value: 'li_uid', label: 'LI UID', description: 'Liechtensteinian UID'},
    mx_rfc: {value: 'mx_rfc', label: 'MX RFC', description: 'Mexican RFC'},
    my_frp: {value: 'my_frp', label: 'MY FRP', description: 'Malaysian FRP'},
    my_itn: {value: 'my_itn', label: 'MY ITN', description: 'Malaysian ITN'},
    my_sst: {value: 'my_sst', label: 'MY SST', description: 'Malaysian SST'},
    no_vat: {value: 'no_vat', label: 'NO VAT', description: 'Norwegian VAT'},
    nz_gst: {value: 'nz_gst', label: 'NZ GST', description: 'New Zealand GST'},
    pe_ruc: {value: 'pe_ruc', label: 'PE RUC', description: 'Peruvian RUC'},
    ph_tin: {
      value: 'ph_tin',
      label: 'PH TIN',
      description: 'Philippines Tax Identification Number',
    },
    ro_tin: {value: 'ro_tin', label: 'RO TIN', description: 'Romanian tax ID'},
    rs_pib: {value: 'rs_pib', label: 'RS PIB', description: 'Serbian PIB'},
    ru_inn: {value: 'ru_inn', label: 'RU INN', description: 'Russian INN'},
    ru_kpp: {value: 'ru_kpp', label: 'RU KPP', description: 'Russian KPP'},
    sa_vat: {value: 'sa_vat', label: 'SA VAT', description: 'Saudi Arabia VAT'},
    sg_gst: {value: 'sg_gst', label: 'SG GST', description: 'Singaporean GST'},
    sg_uen: {value: 'sg_uen', label: 'SG UEN', description: 'Singaporean UEN'},
    si_tin: {
      value: 'si_tin',
      label: 'SI TIN',
      description: 'Slovenia tax number (davčna številka)',
    },
    sv_nit: {value: 'sv_nit', label: 'SV NIT', description: 'El Salvadorian NIT'},
    th_vat: {value: 'th_vat', label: 'TH VAT', description: 'Thai VAT'},
    tr_tin: {value: 'tr_tin', label: 'TR TIN', description: 'Turkish Tax Identification Number'},
    tw_vat: {value: 'tw_vat', label: 'TW VAT', description: 'Taiwanese VAT'},
    ua_vat: {value: 'ua_vat', label: 'UA VAT', description: 'Ukrainian VAT'},
    us_ein: {value: 'us_ein', label: 'US EIN', description: 'United States EIN'},
    uy_ruc: {value: 'uy_ruc', label: 'UY RUC', description: 'Uruguayan RUC'},
    ve_rif: {value: 've_rif', label: 'VE RIF', description: 'Venezuelan RIF'},
    vn_tin: {value: 'vn_tin', label: 'VN TIN', description: 'Vietlabelse tax ID'},
    za_vat: {value: 'za_vat', label: 'ZA VAT', description: 'South African VAT'},
  });
