import Alert, {type AlertProps} from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useContext, useEffect} from 'react';
import {AlertBarContext} from '../../../../context/alert-bar';

const styles = {
  root: {
    gridArea: 'alert',
    width: '100%',
  },
  action: {
    position: 'relative',
    top: -4,
  },
  text: {
    mt: '3px', // vertically aligns text in Alert
  },
};

function AlertBar({children, ...props}: AlertProps) {
  const {setPageHasAlertBar} = useContext(AlertBarContext);

  useEffect(() => {
    setPageHasAlertBar(true);
    return () => setPageHasAlertBar(false);
  }, [setPageHasAlertBar]);

  return (
    <Box sx={styles.root}>
      <Alert {...props}>
        <Typography variant="inherit" sx={styles.text}>
          {children}
        </Typography>
      </Alert>
    </Box>
  );
}

export default AlertBar;
