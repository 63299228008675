import {useIsFreeEnvironment} from '../../../../selectors/environments';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {type IndexSchema} from '../utilities/schema';

interface SelectedIndexArgs {
  formData: IndexSchema;
  projectEnvironment?: string;
  onServerlessSelectionPage: boolean;
}

export interface SelectedIndex extends IndexSchema {
  isServerless: boolean;
  requiresSubscriptionUpgrade?: boolean;
}

export const useSelectedIndex = ({
  formData,
  onServerlessSelectionPage,
}: SelectedIndexArgs): SelectedIndex => {
  const {environment} = formData;
  const viewerHasSubscription = useIsSelectedOrganizationSubscribed();
  const orgId = useSelectedOrganizationId();
  const requiresSubscription = !useIsFreeEnvironment(environment, orgId);

  return {
    ...formData,
    environment,
    isServerless: onServerlessSelectionPage,
    requiresSubscriptionUpgrade: !viewerHasSubscription && requiresSubscription,
  };
};
