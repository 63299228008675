import {useAppSelector as useSelector} from '../app/store';
import {type BulkImport} from '../types';

export const useBulkImports = (projectId: string, indexName: string): BulkImport[] => {
  return Object.values(
    useSelector(
      (state) => state.bulkImports.projects[projectId]?.indexes[indexName]?.data?.imports,
    ) || {},
  );
};

export const useBulkImportsAreLoading = (projectId: string, indexName: string) => {
  return useSelector((state) => {
    const importData = state.bulkImports.projects[projectId]?.indexes[indexName];
    if (!importData) {
      return true;
    }

    return importData.loading;
  });
};

export const useBulkImport = (
  projectId: string,
  indexName: string,
  importId: string,
): BulkImport | undefined => {
  const imports =
    useSelector(
      (state) => state.bulkImports.projects[projectId]?.indexes[indexName]?.data?.imports,
    ) || {};

  return imports[importId];
};
