import {Paper, Typography} from '@mui/material';
import {ReturnMechanism} from '@pinecone-experience/integrations';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useFormContext, useWatch} from 'react-hook-form';
import {type IntegrationFormData} from '../utilities/schema';
import styles from '../utilities/styles';

/** Form field for the return mechanism for the API key. */
function ReturnMechanismField() {
  const {control} = useFormContext<IntegrationFormData>();
  const returnMechanism = useWatch({name: 'return_mechanism'});

  return (
    <Paper sx={styles.section}>
      <SelectInput
        name="return_mechanism"
        control={control}
        required
        label="Return mechanism"
        placeholder="Select return mechanism"
        fullWidth
        options={[
          {label: 'Web Message', value: ReturnMechanism.WEB_MESSAGE},
          {label: 'Copy/Paste', value: ReturnMechanism.COPY_PASTE},
        ]}
      />
      {returnMechanism === ReturnMechanism.COPY_PASTE && (
        <Typography>
          Users will copy and paste their Pinecone API keys into your application.
        </Typography>
      )}
      {returnMechanism === ReturnMechanism.WEB_MESSAGE && (
        <Typography>
          Your application will receive the Pinecone API key automatically via a web message. The
          key will only be provided to the allowed origins specified below.
        </Typography>
      )}
      {!returnMechanism && (
        <Typography>
          Select the mechanism your integration will use to receive the Pinecone API key.
        </Typography>
      )}
    </Paper>
  );
}

export default ReturnMechanismField;
