import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import type React from 'react';
import {type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {SupportCategory, SupportSeverityLevel} from '../../constants';
import {useAuth} from '../../hooks/auth';
import {useNavigateToSupport} from '../../hooks/navigation';
import {useSelectedOrganizationId} from '../../selectors/params';
import FileAttachmentInput from './FileAttachmentInput';
import RichTextEditor from './RichTextEditor';

type TicketFormProps = {
  isSubmitting: boolean;
  handleSubmit: (data: FormData) => Promise<void>;
};

const schema = z.object({
  email: z.string().email('Invalid email address'),
  category: z.string().min(1, 'Category is required'),
  subject: z.string().min(1, 'Subject is required'),
  severity: z.string().min(1, 'Severity is required'),
  description: z.string().min(1, 'Description is required'),
  files: z.array(z.instanceof(File)).optional(),
});

type FormData = z.infer<typeof schema>;

const TicketForm: React.FC<TicketFormProps> = ({isSubmitting, handleSubmit}) => {
  const {user} = useAuth();
  const orgId = useSelectedOrganizationId();
  const {go: navigateToSupport} = useNavigateToSupport(orgId);

  const {
    control,
    formState: {isValid},
    handleSubmit: rhfHandleSubmit,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      email: user?.email || '',
      category: '',
      subject: '',
      severity: SupportSeverityLevel.SEV4,
      description: '',
      files: [],
    },
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleSubmit(data);
  };

  return (
    <Box sx={{flex: 2, marginRight: 3, minWidth: '790px'}}>
      <Typography variant="h5" sx={{marginBottom: 3}}>
        Submit a request
      </Typography>

      <form id="support-ticket-form" onSubmit={rhfHandleSubmit(onSubmit)}>
        <TextInput
          name="email"
          label="Your email address"
          control={control}
          fullWidth
          required
          disabled
        />
        <SelectInput
          name="category"
          label="Ticket category"
          placeholder="Select a category"
          control={control}
          options={[
            {value: '', label: 'Select a category'},
            {value: SupportCategory.BILLING_QUESTION, label: 'Billing question'},
            {value: SupportCategory.INDEX_MANAGEMENT, label: 'Index management'},
            {
              value: SupportCategory.PROJECT_OR_ORGANIZATION_MANAGEMENT,
              label: 'Project or Organization management',
            },
            {value: SupportCategory.DRIVER_OR_CLIENT_QUESTION, label: 'Driver or client question'},
            {
              value: SupportCategory.WEBSITE_OR_CONSOLE_QUESTION,
              label: 'Website or console question',
            },
            {value: SupportCategory.EARLY_ACCESS_QUESTION, label: 'Early access question'},
          ]}
          fullWidth
          required
        />
        <TextInput
          name="subject"
          label="Subject"
          placeholder="Enter a subject"
          control={control}
          fullWidth
          required
        />
        <RichTextEditor
          name="description"
          control={control}
          label="Description"
          placeholder="Enter a description"
          required
        />
        <Typography
          variant="caption"
          sx={{
            color: 'secondary.main',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            letterSpacing: '0.4px',
          }}
        >
          Please provide the details of your request. Be sure to state the name(s) of the project(s)
          and index(es) in question. Include or attach below any applicable code, version
          specifications, full stack traces of errors, and application logs.
        </Typography>
        <Box sx={{marginBottom: 3}} />
        <SelectInput
          name="severity"
          label="Severity"
          control={control}
          options={[
            {
              value: SupportSeverityLevel.SEV1,
              label: 'Sev 1 - Production env is severely impacted',
            },
            {
              value: SupportSeverityLevel.SEV2,
              label: 'Sev 2 - Production env is degraded, performance is severely impacted',
            },
            {
              value: SupportSeverityLevel.SEV3,
              label: 'Sev 3 - Dev impact or minor production issue',
            },
            {value: SupportSeverityLevel.SEV4, label: 'Sev 4 - Question or feedback'},
          ]}
          fullWidth
          required
        />
        <FileAttachmentInput name="files" control={control} label="Attachments" fullWidth />
        <Box sx={{display: 'flex', justifyContent: 'flex-start', marginTop: 2}}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{marginRight: 1}}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button
            onClick={() => {
              navigateToSupport();
            }}
            variant="text"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default TicketForm;
