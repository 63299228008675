import {moviesDataset} from './movies';
import {tatoebaMultilingual} from './tatoebaMultilingual';
import {type Dataset} from './types';
import {wikipediaDataset} from './wikipedia';

/* the key here is used for the url path */
export const datasets: Record<string, Dataset> = {
  movies: moviesDataset,
  'tatoeba-en-es': tatoebaMultilingual,
  wikipedia: wikipediaDataset,
};
