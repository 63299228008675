import {ResponsiveLine} from '@nivo/line';
import {storageUnitsCountQuery} from '../../../../../actions/prometheusActions';
import ChartLayout from '../../../../../components/chart/ChartLayout/ChartLayout';
import ChartLegend from '../../../../../components/chart/ChartLegend/ChartLegend';
import {useFormatDateTick, useGetYAxisLabel, useNivoTheme} from '../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../hooks/use-api';
import {useLegacyMetrics} from '../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {useServiceByGlobalProject} from '../../../../../selectors/services';
import {type PrometheusLegacyResponse} from '../../../../../types';
import {chartAxisBottom, chartAxisY, chartMargin, timeXScale} from '../../../../../utils/chart';
import {getSpecs} from '../../../../../utils/services';
import VectorCountTooltip from '../VectorCountChart/VectorCountTooltip/VectorCountTooltip';

const storageUnitsCountColor = '#0D3082';

function Chart({formattedData}: {formattedData: PrometheusLegacyResponse}) {
  const nivoTheme = useNivoTheme();
  const formatTick = useFormatDateTick({formattedData});
  const {yAxisMin, yAxisMax} = useGetYAxisLabel(formattedData);
  return (
    <ResponsiveLine
      theme={nivoTheme}
      data={formattedData}
      colors={[storageUnitsCountColor]}
      xScale={timeXScale}
      yScale={{type: 'linear', min: yAxisMin, max: yAxisMax}}
      gridYValues={5}
      curve="linear"
      lineWidth={2}
      margin={chartMargin}
      axisLeft={chartAxisY({})}
      axisTop={null}
      axisBottom={{
        ...chartAxisBottom,
        format: formatTick,
      }}
      enablePoints={false}
      enableGridX={false}
      useMesh
      enableSlices="x"
      sliceTooltip={VectorCountTooltip}
    />
  );
}

const legend = [
  {
    name: 'Storage Gigabytes',
    color: storageUnitsCountColor,
  },
];

function ServerlessStorageUnitsChart({
  service,
  start,
  end,
}: {
  service: string;
  start: string;
  end: string;
}) {
  const projectId = useSelectedGlobalProjectId();
  const serviceInfo = useServiceByGlobalProject(projectId || '', service);
  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    storageUnitsCountQuery,
    projectId || '',
    service,
    start,
    end,
    !!serverlessSpec,
  );
  const vectorCountMetrics = useLegacyMetrics('storageUnitsCount', service);
  return (
    <ChartLayout
      title="Storage"
      error={vectorCountMetrics?.error}
      chart={<Chart formattedData={vectorCountMetrics?.data || []} />}
      tooltip="The total size of the index, in GBs"
      legend={<ChartLegend keys={legend} />}
      loading={vectorCountMetrics?.loading}
    />
  );
}

export default ServerlessStorageUnitsChart;
