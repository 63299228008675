import {zodResolver} from '@hookform/resolvers/zod';
import {ReturnMechanism} from '@pinecone-experience/integrations';
import {useCallback} from 'react';
import {type DefaultValues, FormProvider, useForm} from 'react-hook-form';
import ModalForm from '../../../../components/ModalForm';
import {MODAL_WIDTH_MEDIUM} from '../../../../styles/theme';
import {type Integration} from '../../../../types';
import AllowedOriginsField from './AllowedOriginsField/AllowedOriginsField';
import LogoField from './LogoField/LogoField';
import NameField from './NameField/NameField';
import ReturnMechanismField from './ReturnMechanismField/ReturnMechanismField';
import SlugField from './SlugField/SlugField';
import {type IntegrationFormData, createSchema, updateSchema} from './utilities/schema';

type IntegrationFormProps<T extends IntegrationFormData> = {
  /** Whether the form is open. */
  open: boolean;
  /** Callback for when the form is closed. */
  close: () => void;
  /** The title of the modal. */
  title: string;
  /** Callback for when the form is submitted. */
  onSubmit: (data: T) => void;
  /** The text to display on the submit button. */
  submitText: string;
  /** The initial values for the form. */
  initialValues?: Integration;
};

/** A modal form for creating or editing an integration. */
function IntegrationForm<T extends IntegrationFormData>({
  open,
  close,
  title,
  onSubmit,
  submitText,
  initialValues,
}: IntegrationFormProps<T>) {
  const defaultValues = {
    name: initialValues?.profile.name ?? '',
    slug: initialValues?.slug ?? '',
    return_mechanism: initialValues?.return_mechanism ?? ReturnMechanism.WEB_MESSAGE,
    allowed_origins: initialValues?.allowed_origins?.map((origin) => ({origin})) ?? [{origin: ''}],
  } as DefaultValues<T>;
  const formMethods = useForm<T>({
    mode: 'onChange',
    resolver: zodResolver(initialValues ? updateSchema : createSchema),
    defaultValues,
  });
  const {reset, handleSubmit} = formMethods;

  const onClose = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  const submit = useCallback(
    (data: T) => {
      onSubmit(data);
      reset();
      close();
    },
    [close, onSubmit, reset],
  );

  return (
    <FormProvider {...formMethods}>
      <ModalForm
        open={open}
        close={onClose}
        title={title}
        onSubmit={handleSubmit(submit)}
        submitText={submitText}
        isValid
        form={
          <div data-testid="integration-form">
            <NameField />
            <SlugField disabled={!!initialValues} />
            <LogoField slug={initialValues?.slug} />
            <ReturnMechanismField />
            <AllowedOriginsField />
          </div>
        }
        width={MODAL_WIDTH_MEDIUM}
      />
    </FormProvider>
  );
}

export default IntegrationForm;
