import {LANGUAGE_OPTIONS} from '../../../../../../components/CodeSnippets/constants';

export const DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS = [
  LANGUAGE_OPTIONS.python,
  LANGUAGE_OPTIONS.javascript,
  LANGUAGE_OPTIONS.go,
  LANGUAGE_OPTIONS.java,
];

export const DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS = [
  LANGUAGE_OPTIONS.python,
  LANGUAGE_OPTIONS.javascript,
];

export const CREATE_EMBEDDINGS_CODE = {
  [LANGUAGE_OPTIONS.python]: `data = [
    {"id": "vec1", "text": "Apple is a popular fruit known for its sweetness and crisp texture."},
    {"id": "vec2", "text": "The tech company Apple is known for its innovative products like the iPhone."},
    {"id": "vec3", "text": "Many people enjoy eating apples as a healthy snack."},
    {"id": "vec4", "text": "Apple Inc. has revolutionized the tech industry with its sleek designs and user-friendly interfaces."},
    {"id": "vec5", "text": "An apple a day keeps the doctor away, as the saying goes."},
    {"id": "vec6", "text": "Apple Computer Company was founded on April 1, 1976, by Steve Jobs, Steve Wozniak, and Ronald Wayne as a partnership."}
]

embeddings = pc.inference.embed(
    model="multilingual-e5-large",
    inputs=[d['text'] for d in data],
    parameters={"input_type": "passage", "truncate": "END"}
)
print(embeddings[0])`,
  [LANGUAGE_OPTIONS.javascript]: `const model = 'multilingual-e5-large';

const data = [
    { id: 'vec1', text: 'Apple is a popular fruit known for its sweetness and crisp texture.' },
    { id: 'vec2', text: 'The tech company Apple is known for its innovative products like the iPhone.' },
    { id: 'vec3', text: 'Many people enjoy eating apples as a healthy snack.' },
    { id: 'vec4', text: 'Apple Inc. has revolutionized the tech industry with its sleek designs and user-friendly interfaces.' },
    { id: 'vec5', text: 'An apple a day keeps the doctor away, as the saying goes.' },
    { id: 'vec6', text: 'Apple Computer Company was founded on April 1, 1976, by Steve Jobs, Steve Wozniak, and Ronald Wayne as a partnership.' }
];

const embeddings = await pc.inference.embed(
  model,
  data.map(d => d.text),
  { inputType: 'passage', truncate: 'END' }
);

console.log(embeddings[0]);`,
  [LANGUAGE_OPTIONS.java]: ``,
  [LANGUAGE_OPTIONS.go]: ``,
};

export const UPSERT_CODE_SNIPPETS = {
  python: `# Wait for the index to be ready
while not pc.describe_index(index_name).status['ready']:
    time.sleep(1)

index = pc.Index(index_name)

vectors = []
for d, e in zip(data, embeddings):
    vectors.append({
        "id": d['id'],
        "values": e['values'],
        "metadata": {'text': d['text']}
    })

index.upsert(
    vectors=vectors,
    namespace="ns1"
)`,
  javascript: `const index = pc.index(indexName);

const vectors = data.map((d, i) => ({
  id: d.id,
  values: embeddings[i].values,
  metadata: { text: d.text }
}));

await index.namespace('ns1').upsert(vectors);`,
  java: `//Add to the main function:

Index index = pc.getIndexConnection(indexName);

// Check if index is ready
while(!pc.describeIndex(indexName).getStatus().getReady()) {
    Thread.sleep(3000);
}

List<Float> values1 = Arrays.asList(1.0f, 1.5f);
List<Float> values2 = Arrays.asList(2.0f, 1.0f);
List<Float> values3 = Arrays.asList(0.1f, 3.0f);
List<Float> values4 = Arrays.asList(1.0f, -2.5f);
List<Float> values5 = Arrays.asList(3.0f, -2.0f);
List<Float> values6 = Arrays.asList(0.5f, -1.5f);

index.upsert("vec1", values1, "ns1");
index.upsert("vec2", values2, "ns1");
index.upsert("vec3", values3, "ns1");
index.upsert("vec1", values4, "ns2");
index.upsert("vec2", values5, "ns2");
index.upsert("vec3", values6, "ns2");`,
  go: `// Add to the main function:

idxModel, err := pc.DescribeIndex(ctx, indexName)
if err != nil {
    log.Fatalf("Failed to describe index \\"%v\\": %v", idx.Name, err)
}

idxConnection1, err := pc.Index(pinecone.NewIndexConnParams{Host: idxModel.Host, Namespace: "ns1"})
if err != nil {
    log.Fatalf("Failed to create IndexConnection1 for Host %v: %v", idxModel.Host, err)
}

idxConnection2, err := pc.Index(pinecone.NewIndexConnParams{Host: idxModel.Host, Namespace: "ns2"})
if err != nil {
    log.Fatalf("Failed to create IndexConnection1 for Host %v: %v", idxModel.Host, err)
}

vectors1 := []*pinecone.Vector{
    {
        Id:     "vec1",
        Values: []float32{1.0, 1.5},
    },
    {
        Id:     "vec2",
        Values: []float32{2.0, 1.0},
    },
    {
        Id:     "vec3",
        Values: []float32{0.1, 3.0},
    },   
}

vectors2 := []*pinecone.Vector{
    {
        Id:     "vec1",
        Values: []float32{1.0, -2.5},
    },
    {
        Id:     "vec2",
        Values: []float32{3.0, -2.0},
    },
    {
        Id:     "vec3",
        Values: []float32{0.5, -1.5},
    },
}

count1, err := idxConnection1.UpsertVectors(ctx, vectors1)
if err != nil {
    log.Fatalf("Failed to upsert vectors: %v", err)
} else {
    fmt.Printf("Successfully upserted %d vector(s)!\\n", count1)
}

count2, err := idxConnection2.UpsertVectors(ctx, vectors2)
if err != nil {
    log.Fatalf("Failed to upsert vectors: %v", err)
} else {
    fmt.Printf("Successfully upserted %d vector(s)!\\n", count2)
}`,
};

export const CHECK_THE_INDEX = {
  python: `print(index.describe_index_stats())`,
  javascript: `const stats = await index.describeIndexStats();

console.log(stats)`,
  java: `// Add to the main function:

// Wait for the upserted vectors to be indexed
Thread.sleep(10000);

DescribeIndexStatsResponse indexStatsResponse = index.describeIndexStats(null);
System.out.println(indexStatsResponse);`,
  go: `// Add to the main function:

stats, err := idxConnection1.DescribeIndexStats(ctx)
if err != nil {
    log.Fatalf("Failed to describe index \\"%v\\": %v", idx.Name, err)
} else {
    fmt.Printf("%+v", *stats)
}`,
};

export const CREATE_A_QUERY_VECTOR = {
  [LANGUAGE_OPTIONS.python]: `query = "Tell me about the tech company known as Apple."

embedding = pc.inference.embed(
    model="multilingual-e5-large",
    inputs=[query],
    parameters={
        "input_type": "query"
    }
)`,
  [LANGUAGE_OPTIONS.javascript]: `const query = [
  'Tell me about the tech company known as Apple.',
];

const embedding = await pc.inference.embed(
  model,
  query,
  { inputType: 'query' }
);`,
};

export const RUN_A_SIMILARITY_SEARCH_CODE = {
  [LANGUAGE_OPTIONS.python]: `results = index.query(
    namespace="ns1",
    vector=embedding[0].values,
    top_k=3,
    include_values=False,
    include_metadata=True
)

print(results)`,
  [LANGUAGE_OPTIONS.javascript]: `const queryResponse = await index.namespace("ns1").query({
  topK: 3,
  vector: embedding[0].values,
  includeValues: false,
  includeMetadata: true
});

console.log(queryResponse);`,
  [LANGUAGE_OPTIONS.java]: `// Add to the main function:

List<Float> queryVector1 = Arrays.asList(1.0f, 1.5f);
List<Float> queryVector2 = Arrays.asList(1.0f, -2.5f);

QueryResponseWithUnsignedIndices queryResponse1 = index.query(3, queryVector1, null, null, null, "ns1", null, false, true);
QueryResponseWithUnsignedIndices queryResponse2 = index.query(3, queryVector2, null, null, null, "ns2", null, false, true);

System.out.println(queryResponse1);
System.out.println(queryResponse2);`,
  [LANGUAGE_OPTIONS.go]: `// Add to the main function:

queryVector1 := []float32{0.1, 0.2}
queryVector2 := []float32{1.0,-2.5}

res1, err := idxConnection1.QueryByVectorValues(ctx, &pinecone.QueryByVectorValuesRequest{
    Vector:        queryVector1,
    TopK:          3,
    IncludeValues: true,
})
if err != nil {
    log.Fatalf("Error encountered when querying by vector: %v", err)
} else {
    for _, match := range res1.Matches {
        fmt.Printf("Match vector %v, with score %f\n", match.Vector.Id, match.Score)
    } 
}

res2, err := idxConnection2.QueryByVectorValues(ctx, &pinecone.QueryByVectorValuesRequest{
    Vector:        queryVector2,
    TopK:          3,
    IncludeValues: true,
})
if err != nil {
    log.Fatalf("Error encountered when querying by vector: %v", err)
} else {
    for _, match := range res2.Matches {
        fmt.Printf("Match vector %v, with score %f\n", match.Vector.Id, match.Score)
    } 
}`,
};
