import {Box, Link, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {PUBLIC_DOCS} from '../../../constants';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useSelectedOrganization,
} from '../../../selectors/organizations';
import Usage from './Usage/Usage';

function UsagePage() {
  const selectedOrg = useSelectedOrganization();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const isOwner = useIsOwnerOfOrganization();
  if (!isSubscribed) {
    return (
      <Alert title="Not Available" description="This feature isn't available on the starter plan" />
    );
  }
  if (!isOwner) {
    return (
      <Alert
        title="Unauthorized"
        description="You must be an owner of the organization to view usage"
      />
    );
  }
  return (
    <Page
      header={
        <PageHeader
          title="Usage"
          titleHidden
          description={
            <>
              Track and monitor resource allocation across your <strong>{selectedOrg?.name}</strong>{' '}
              Projects
            </>
          }
        />
      }
    >
      <Box sx={{display: 'flex'}}>
        <Alert
          sx={{marginBottom: 0}}
          description={
            <Typography>
              Usage and cost for public preview products, including{' '}
              <Link href={PUBLIC_DOCS.INFERENCE} target="_blank" underline="always">
                Pinecone Inference
              </Link>{' '}
              and{' '}
              <Link href={PUBLIC_DOCS.ASSISTANT} target="_blank" underline="always">
                Pinecone Assistant
              </Link>
              , aren&lsquo;t yet displayed in this view.
            </Typography>
          }
        />
      </Box>
      <Usage />
    </Page>
  );
}
export default UsagePage;
