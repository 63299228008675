import {Typography} from '@mui/material';
import Table, {type TableColumn} from '@pinecone-experience/timber/Table';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {GUTTER} from '../../../styles/theme';
import {type Dataset} from '../data/types';

const TOOLTIP_MIN_LENGTH = 25;

const styles = {
  maxLinesText: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
};

function MetadataTable({dataset, rows = 4}: {dataset: Dataset; rows?: number}) {
  const metadataWithId = dataset.exampleVectorData.map((vector) => ({
    id: vector.id,
    ...vector.metadata,
  })) as Record<string, string>[];

  const metadataFields: Record<string, string> = {};
  metadataWithId.forEach((data) => {
    Object.keys(data).forEach((field) => {
      if (!metadataFields[field]) {
        metadataFields[field] = typeof data[field];
      }
    });
  });

  const metadataColumns = Object.keys(metadataFields).map((key) => ({
    name: key,
    dataType: metadataFields[key],
  })) as readonly TableColumn[];

  const metadataCellRenderer = (row: number, col: number) => {
    if (!metadataWithId[row]) return null;
    const data = metadataWithId[row];
    const columnName = metadataColumns[col]?.name;
    const value = Array.isArray(data?.[columnName])
      ? (data[columnName] as string[]).join(', ')
      : data?.[columnName];
    return (
      <Tooltip title={value?.length > TOOLTIP_MIN_LENGTH ? value : ''}>
        <Typography sx={styles.maxLinesText}>{value}</Typography>
      </Tooltip>
    );
  };

  return (
    <Table
      columns={metadataColumns}
      cellRenderer={metadataCellRenderer}
      numRows={rows}
      gutter={GUTTER}
    />
  );
}

export default MetadataTable;
