import BAAIIcon from '../../icons/models/baai.svg';
import MicrosoftIcon from '../../icons/models/microsoft.svg';
import {type Metric} from '../../types';

export interface Model {
  icon: any;
  name: string;
  task: string;
  modality: string;
  price: string;
  freeTier?: string;
  dimension?: number[];
  metric?: Metric[];
  type?: string;
  maxInputTokens?: number;
}

export const MicrosoftE5Large = {
  icon: MicrosoftIcon,
  name: 'Microsoft/multilingual-e5-large',
  dimension: [1024],
  metric: ['cosine'] as Metric[],
  type: 'text',
  task: 'Embed',
  modality: 'Text',
  maxInputTokens: 507,
  freeTier: '5M Tokens',
  price: '$0.08 / 1M Tokens',
};

export const BGERerankerV2M3 = {
  icon: BAAIIcon,
  name: 'BAAI/bge-reranker-v2-m3',
  task: 'Rerank',
  modality: 'Text',
  price: '$2.00 / 1K Requests',
};
