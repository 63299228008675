import {NARROW_PAGE_WIDTH, WIDE_PAGE_WIDTH} from '../../../../styles/theme';

export type PageWidth = 'narrow' | 'wide' | 'variable' | 'fullscreen';

/**
 * Returns 5 column layout, based on gutter value and fullWidth bool.
 */
export function getPageColumns(width: PageWidth, gutter: string) {
  if (width === 'fullscreen') {
    return `
      0
      0
      1fr
      0
      0
    `;
  }

  if (width === 'variable') {
    return `
    ${gutter}
    ${gutter}
    minmax(0, 1fr)
    ${gutter}
    ${gutter}
  `;
  }

  const maxWidth = width === 'narrow' ? NARROW_PAGE_WIDTH : WIDE_PAGE_WIDTH;

  return `
    minmax(${gutter}, auto)
    ${gutter}
    minmax(auto, ${maxWidth})
    ${gutter}
    minmax(${gutter}, auto)
  `;
}
