import {Box, Button, Link, Typography} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {LocalStorageKeys, PUBLIC_DOCS_URL} from '../../constants';
import {useNavigateToCollectionPageGlobal} from '../../hooks/navigation';
import {useBooleanState} from '../../hooks/utils';
import {useArchivedIndexesByGlobalProject} from '../../selectors/forcedMigration';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../selectors/params';
import {localStorageGet, localStorageSet} from '../../utils/storage';
import ProgressRow from '../Utilities/ProgressRow';

function ArchivedModal() {
  const orgId = useSelectedOrganizationId();
  const globalProjectId = useSelectedGlobalProjectId();
  const {go} = useNavigateToCollectionPageGlobal(orgId, globalProjectId || '');
  const archived = useArchivedIndexesByGlobalProject(globalProjectId || '') || [];
  const hasSeen = localStorageGet(LocalStorageKeys.VIEWED_ARCHIVED_MODAL);
  const {val: open, setFalse: close} = useBooleanState(!hasSeen && archived.length > 0);
  const onClose = () => {
    localStorageSet(LocalStorageKeys.VIEWED_ARCHIVED_MODAL, 'true');
    close();
  };
  return (
    <BaseModal open={open} onClose={onClose} showCloseIcon>
      <Box sx={{width: 400, p: 1}}>
        <Box position="relative">
          <Box>
            <Typography variant="h5" mb={2}>
              Your pod index has been archived as a collection
            </Typography>
          </Box>
          <Typography>
            We wanted to inform you that your index has now been archived as a collection as part of
            our transition to a more efficient, serverless platform.
          </Typography>
          <Typography fontWeight="bold">To get up and running again</Typography>
          <Box>
            <ProgressRow stage="done" text="Navigate to the 'Collections' section." />
            <ProgressRow
              stage="done"
              text="Serverless indexes scale automatically based on usage."
            />
            <ProgressRow
              stage="done"
              text="Locate your archived collection and select the option to 'Create index'."
            />
            <ProgressRow
              stage="done"
              text={
                <>
                  <Typography>
                    Once your new serverless index is created, you will need to make some minor code
                    changes.{' '}
                  </Typography>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={`${PUBLIC_DOCS_URL}/guides/indexes/migrate-a-legacy-starter-index-to-serverless`}
                  >
                    Learn more
                  </Link>
                  .
                </>
              }
            />
          </Box>
        </Box>
        <Button onClick={go} fullWidth variant="contained" sx={{mb: 2, mt: 3}}>
          See Collections
        </Button>
      </Box>
    </BaseModal>
  );
}

export default ArchivedModal;
