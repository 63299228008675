import {getBulkImport} from '../actions/bulkImportActions';
import {POLLING_INTERVAL} from '../constants';
import {useBulkImport} from '../selectors/bulkImports';
import {type GlobalProject} from '../types';
import {useDataPlane} from './use-api';
import {useInterval} from './utils';

export function usePollImportStatus(
  project: GlobalProject,
  indexName: string,
  host: string,
  importId: string,
) {
  const {setQueryData} = useDataPlane<{id: string}>(project, indexName, host);

  const {status, percentComplete} = useBulkImport(project.id, indexName, importId) || {};
  useInterval(
    () => {
      setQueryData({
        action: getBulkImport,
        data: {id: importId},
      });
    },
    status === 'InProgress' || status === 'Pending' ? POLLING_INTERVAL : null,
  );
  return {status, percentComplete};
}
