import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {getOrganizations} from '../../../../actions/organizationActions';
import {getUserInfo} from '../../../../actions/userActions';
import AuthPage from '../../../../components/layout/AuthPage/AuthPage';
import {useSelectedIntegration} from '../../../../context/selected-integration';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useConnectIntegration} from '../../../../selectors/integrations';
import {useOrganizationsAreLoading} from '../../../../selectors/organizations';
import {useProjectInfoLoading} from '../../../../selectors/projects';
import {useUserIsLoading} from '../../../../selectors/userSession';
import ConnectProject from './ConnectProject/ConnectProject';
import HostGuard from './HostGuard/HostGuard';
import {useFinishConnection, useHandleConnection} from './hooks';

function GrantPageContents() {
  const connection = useConnectIntegration();
  const integration = useSelectedIntegration();

  const {onConfirm, onCancel, onReset} = useHandleConnection();
  useFinishConnection();

  return (
    <ConnectProject
      appName={integration.profile.name}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onReset={onReset}
      isConnecting={connection.loading}
      error={connection.error}
    />
  );
}

function GrantPage() {
  const connection = useConnectIntegration();
  const integration = useSelectedIntegration();

  useDashboardApi(getOrganizations);
  useDashboardApi(getUserInfo);
  const orgsAreLoading = useOrganizationsAreLoading();
  const projectsAreLoading = useProjectInfoLoading();
  const userIsLoading = useUserIsLoading();
  const loading = (orgsAreLoading || projectsAreLoading || userIsLoading) && !connection.loading;

  return (
    <AuthPage
      header="Connect in seconds..."
      subheader={`Select a project to connect with ${integration.profile.name}`}
    >
      <LoadingContainer loading={loading}>
        {!loading && (
          <HostGuard>
            <GrantPageContents />
          </HostGuard>
        )}
      </LoadingContainer>
    </AuthPage>
  );
}

export default GrantPage;
