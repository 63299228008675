import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {listApiKeys} from '../../../../actions/apiKeyActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useApiKeysByGlobalProjectId} from '../../../../selectors/keys';
import {useGlobalProject, useGlobalProjectsFromOrganization} from '../../../../selectors/projects';
import {useServicesAreLoadingByGlobalProject} from '../../../../selectors/services';
import {type Organization} from '../../../../types';

export const ProjectsSelector = ({selectedOrg}: {selectedOrg: Organization}) => {
  const {control, setValue, watch, setError, clearErrors} = useFormContext();
  const projects = useGlobalProjectsFromOrganization(selectedOrg?.id);
  const projectOptions = projects.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  const selectedProjectId = watch('projectId');
  const selectedProject = useGlobalProject(selectedProjectId);

  const {setQueryData: queryApiKeys} = useDashboardApi<{globalProjectId: string}>();
  useEffect(() => {
    if (selectedProject?.vault_id) {
      queryApiKeys({
        action: listApiKeys,
        data: {globalProjectId: selectedProject?.id},
      });
    }
  }, [selectedProject, queryApiKeys]);
  const apiKeys = useApiKeysByGlobalProjectId(selectedProject?.id);
  const keysAreLoading = useServicesAreLoadingByGlobalProject(selectedProject?.id);
  useEffect(() => {
    if (!keysAreLoading && apiKeys.length === 0) {
      setError('projectId', {
        type: 'manual',
        message: 'No API keys found for this project. Please create one.',
      });
    } else {
      clearErrors('projectId');
    }
  }, [keysAreLoading, apiKeys, setError, clearErrors]);

  useEffect(() => {
    setValue('projectId', '');
    const firstProject = projects[0];
    if (firstProject) {
      setValue('projectId', firstProject.id);
    }
  }, [setValue, projects]);

  return (
    <SelectInput
      label="Pinecone Project"
      name="projectId"
      control={control}
      fullWidth
      disabled={!selectedOrg}
      options={projectOptions}
    />
  );
};

export default ProjectsSelector;
