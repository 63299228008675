import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type CreateCmekKeyRequest} from '../api/types/controllerApi';
import {type CmekKey} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const listCmekKeysRequest = createAction<{
  globalProjectId: string;
}>('LIST_CMEK_KEYS_REQUEST');
export const listCmekKeysSuccess = createAction<{
  globalProjectId: string;
  data: {cmek_spec: CmekKey}[];
}>('LIST_CMEK_KEYS_SUCCESS');
export const listCmekKeysFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('LIST_CMEK_KEYS_FAILURE');

export function listCmekKeys(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listCmekKeysRequest({globalProjectId: data.globalProjectId}));
    return api
      .listCmekKeys(data.globalProjectId)
      .then((response) => {
        dispatch(
          listCmekKeysSuccess({
            globalProjectId: data.globalProjectId,
            data: response.cmek_keys,
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to fetch CMEK keys. ${message}`,
          }),
        );
        dispatch(
          listCmekKeysFailure({
            globalProjectId: data.globalProjectId,
            error: message,
          }),
        );
      });
  };
}

export const createCmekKeyRequest = createAction<{
  globalProjectId: string;
}>('CREATE_CMEK_KEY_REQUEST');
export const createCmekKeySuccess = createAction<{
  globalProjectId: string;
  key: CmekKey;
}>('CREATE_CMEK_KEY_SUCCESS');
export const createCmekKeyFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('CREATE_CMEK_KEY_FAILURE');

export function createCmekKey(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    data: CreateCmekKeyRequest;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createCmekKeyRequest({globalProjectId: data.globalProjectId}));
    return api
      .createCmekKey(data.globalProjectId, data.data)
      .then((response) => {
        dispatch(
          createCmekKeySuccess({
            globalProjectId: data.globalProjectId,
            key: response,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Key created successfully.',
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to create key. ${message}`,
          }),
        );
        dispatch(
          createCmekKeyFailure({
            globalProjectId: data.globalProjectId,
            error: message,
          }),
        );
      });
  };
}

export const deleteCmekKeyRequest = createAction<{
  globalProjectId: string;
  cmekId: string;
}>('DELETE_CMEK_KEY_REQUEST');
export const deleteCmekKeySuccess = createAction<{
  globalProjectId: string;
  cmekId: string;
}>('DELETE_CMEK_KEY_SUCCESS');
export const deleteCmekKeyFailure = createAction<{
  globalProjectId: string;
  cmekId: string;
  error: string;
}>('DELETE_CMEK_KEY_FAILURE');

export function deleteCmekKey(api: ControllerApi, data: {globalProjectId: string; cmekId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(
      deleteCmekKeyRequest({
        globalProjectId: data.globalProjectId,
        cmekId: data.cmekId,
      }),
    );
    return api
      .deleteCmekKey(data.cmekId)
      .then(() => {
        dispatch(
          deleteCmekKeySuccess({
            globalProjectId: data.globalProjectId,
            cmekId: data.cmekId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Key deleted successfully.',
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to delete key. ${message}`,
          }),
        );
        dispatch(
          deleteCmekKeyFailure({
            globalProjectId: data.globalProjectId,
            cmekId: data.cmekId,
            error: message,
          }),
        );
      });
  };
}
