import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/dashboardActions';
import {type UsageDatapoint} from '../pages/org-settings/UsagePage/Usage/config';

interface UsageDataState {
  usage: Record<string, {usageData: UsageDatapoint[]}>;
  loading: boolean;
}

const initialState = {
  usage: {},
  loading: true,
} as UsageDataState;

const usageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUsageDataSuccess, (state, action) => {
      state.usage[action.payload.organizationId] = action.payload.data;
      state.loading = false;
    })
    .addCase(actions.getUsageDataFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.getUsageDataRequest, (state) => {
      state.loading = true;
    });
});

export default usageReducer;
