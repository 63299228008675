import {Add} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {
  type Control,
  type UseFormGetFieldState,
  type UseFormSetValue,
  useFieldArray,
} from 'react-hook-form';
import ClearIcon from '../../../../../../../components/StyledClearIcon';
import {type SchemaType} from '../../../utilities/schema';
import FilterInputRow from './FilterInputRow/FilterInputRow';

type FilterInputProps = {
  control: Control<SchemaType>;
  remove: () => void;
  setValue: UseFormSetValue<SchemaType>;
  getFieldState: UseFormGetFieldState<SchemaType>;
  prefix: `filters.${number}`;
};

const comparatorOptions = [
  {
    label: 'And',
    value: '$and',
  },
  {
    label: 'Or',
    value: '$or',
  },
];

const styles = {
  dottedLine: {
    position: 'absolute',
    borderBottom: 'dotted 2px lightgrey',
    right: 2,
    width: '8px',
    top: '29px',
  },
};

function FilterInput({control, remove, setValue, prefix, getFieldState}: FilterInputProps) {
  const {fields, append, remove: removeRow} = useFieldArray({control, name: `${prefix}.nodes`});
  const onAddRow = () => {
    append({
      key: '',
      op: '$eq',
      value: '',
      valueType: 'auto',
    });
  };
  const hasSubfilter = fields.length > 1;
  return (
    <Box sx={{display: 'flex'}}>
      {hasSubfilter && (
        <Box sx={{display: 'inline-flex', position: 'relative', paddingRight: '12px'}}>
          <IconButton
            id={`${prefix}-remove`}
            onClick={remove}
            icon={<ClearIcon />}
            sx={{
              position: 'relative',
              top: 12,
            }}
          />
          <SelectInput
            control={control}
            name={`${prefix}.operation`}
            options={comparatorOptions}
            sx={{minWidth: 40}}
          />
          <Box sx={styles.dottedLine} />
        </Box>
      )}
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        {fields.map((field, index) => {
          return (
            <FilterInputRow
              key={field.id}
              control={control}
              prefix={`${prefix}.nodes.${index}`}
              remove={hasSubfilter ? () => removeRow(index) : () => remove()}
              getFieldState={getFieldState}
              setValue={setValue}
              isFirst={index === 0}
              isLast={index === fields.length - 1}
            />
          );
        })}
        <Button
          data-heap="metadata-filter-add-subfilter"
          color="secondary"
          startIcon={<Add />}
          onClick={onAddRow}
        >
          {hasSubfilter ? 'Add another value' : 'Add subfilter'}
        </Button>
      </Box>
    </Box>
  );
}

export default FilterInput;
