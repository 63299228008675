import {Box, Card, type CardProps} from '@mui/material';

const styles = {
  root: {
    mb: 3,
    boxShadow: 16,
  },
  content: {
    mx: 'auto',
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
};

interface PagerFooterProps {
  children: React.ReactNode;
  sx?: Required<CardProps['sx']>;
}

function PageFooter({children, sx = {}}: PagerFooterProps) {
  return (
    <Card sx={[styles.root, sx]}>
      <Box sx={styles.content}>{children}</Box>
    </Card>
  );
}

export default PageFooter;
