import {Plans} from '../constants';

const EnterpriseLikePlans = [Plans.ENTERPRISE, Plans.DEDICATED, Plans.INTERNAL];
export const canUseSso = (plan: Plans) => {
  return EnterpriseLikePlans.includes(plan);
};

export const canUsePrivateEndpoints = (plan: Plans) => {
  return EnterpriseLikePlans.includes(plan);
};
