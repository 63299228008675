import CorporateFare from '@mui/icons-material/CorporateFare';
import {useNavigateToIndexListFromOrg} from '../../../../../hooks/navigation';
import {useOrganizations, useSelectedOrganization} from '../../../../../selectors/organizations';
import NavSelector from '../NavSelector/NavSelector';

function OrganizationSelector() {
  const organizations = useOrganizations();
  const selectedOrg = useSelectedOrganization();
  const {go: goToOrg} = useNavigateToIndexListFromOrg();

  const selectorItems = Object.keys(organizations).map((orgId: string) => ({
    id: orgId,
    name: organizations[orgId].name,
    onClick: () => goToOrg(orgId),
  }));

  return (
    <NavSelector
      id="organizations"
      startIcon={<CorporateFare color="secondary" />}
      title="Organizations"
      placeholder="Select Organization"
      items={selectorItems}
      selectedItem={selectedOrg}
    />
  );
}

export default OrganizationSelector;
