import {keyframes} from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Box, type Theme, Typography} from '@mui/material';

type ProgressRowProps = {
  stage: 'done' | 'progress' | 'waiting';
  text: string | React.ReactNode;
};

const animate = (center: number, spread: number, height: number, color: string) => keyframes`
  0% {
    box-shadow: ${center - spread}px 0 0 0 ${color}, ${center}px 0 0 0 ${color}, ${
  center + spread
}px 0 0 0 ${color};
  }
  16.667% {
    box-shadow: ${center - spread}px ${height * -1}px 0 0 ${color}, ${center}px 0 0 0 ${color}, ${
  center + spread
}px 0 0 0 ${color};
  }
  50% {
    box-shadow: ${center - spread}px 0 0 0 ${color}, ${center}px ${height * -1}px 0 0 ${color}, ${
  center + spread
}px 0 0 0 ${color};
  }
  83.333% {
    box-shadow: ${center - spread}px 0 0 0 ${color}, ${center}px 0 0 0 ${color}, ${
  center + spread
}px ${height * -1}px 0 0 ${color};
  }
  100% {
    box-shadow: ${center - spread}px 0 0 0 ${color}, ${center}px 0 0 0 ${color}, ${
  center + spread
}px 0 0 0 ${color};
  }
`;

const styles = {
  row: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    textAlign: 'left',
    height: '42px',
  },
  icon: {
    pt: 1,
    pr: 2,
    width: 24,
  },
  animatedDots: {
    position: 'relative',
    left: '-9990px',
    width: '3px',
    height: '3px',
    borderRadius: '3px',
    animation: (theme: Theme) =>
      `${animate(9999, 8, 8, theme.palette.secondary.main)} 1.0s infinite linear`,
  },
};

function ProgressRow({stage, text}: ProgressRowProps) {
  return (
    <Box sx={styles.row}>
      <Box sx={styles.icon}>
        {stage === 'done' && <CheckIcon color="primary" />}
        {stage === 'progress' && <Box sx={styles.animatedDots} />}
        {stage === 'waiting' && <RadioButtonUncheckedIcon color="secondary" />}
      </Box>
      <Typography color={stage === 'waiting' ? 'secondary' : 'initial'}>{text}</Typography>
    </Box>
  );
}

export default ProgressRow;
