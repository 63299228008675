import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import LoadingContainer from '../../LoadingContainer';
import Tooltip from '../../Tooltip';

const CHART_HEIGHT = 275;

interface ChartLayoutProps {
  title: string;
  tooltip: string;
  loading: boolean;
  hasData: boolean;
  children: React.ReactElement | null;
  error?: string;
  control?: React.ReactNode;
}

const styles = {
  card: {
    p: 3,
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
    mb: 2,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    mt: -0.25,
  },
  tooltip: {
    m: 0,
    p: 0,
    pt: 0.5,
    mt: 0.25,
  },
  chartContainer: {
    height: CHART_HEIGHT,
  },
  chart: {
    height: '100%',
    display: 'flex',
    svg: {
      overflow: 'visible',
      pointerEvents: 'none',
    },
    'svg:focus': {
      outlineOffset: (theme: Theme) => theme.spacing(2),
    },
  },
  message: {
    width: '100%',
    placeSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: 1,
  },
};

export default function ChartLayout({
  title,
  tooltip,
  children,
  control,
  loading,
  error,
  hasData,
}: ChartLayoutProps) {
  return (
    <Card sx={styles.card}>
      <Box sx={styles.header}>
        <Box sx={styles.title}>
          <Typography variant="subtitle1" component="h3">
            {title}
          </Typography>
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon sx={styles.tooltip} fontSize="small" color="secondary" />
          </Tooltip>
        </Box>
        {control}
      </Box>
      <Box sx={styles.chartContainer}>
        <LoadingContainer loading={loading} showChildrenInBackground={!!hasData}>
          <Box sx={styles.chart}>
            {error && (
              <Typography sx={styles.message} color="error">
                <ErrorOutlineOutlinedIcon color="error" />
                {error}
              </Typography>
            )}
            {!error && !hasData && (
              <Typography sx={styles.message} color="secondary">
                No data available
              </Typography>
            )}
            {!error && hasData && children}
          </Box>
        </LoadingContainer>
      </Box>
    </Card>
  );
}
