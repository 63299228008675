import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {type Control} from 'react-hook-form';

type RichTextEditorProps = {
  name: string;
  control: Control<any>;
  label: string;
  placeholder?: string;
  required?: boolean;
};
// @TODO: (@kylermintah, @cboltt)
// This is a placeholder for a rich text editor that we will implement
function RichTextEditor({
  name,
  control,
  label,
  placeholder,
  required = false,
}: RichTextEditorProps) {
  return (
    <TextInput
      name={name}
      control={control}
      label={label}
      placeholder={placeholder}
      fullWidth
      multiline
      rows={6}
      required={required}
    />
  );
}

export default RichTextEditor;
