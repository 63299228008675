import {useMemo} from 'react';
import {useAppSelector as useSelector} from '../app/store';
import {LocalStorageKeys, Roles} from '../constants';
import {type GlobalProject} from '../types';
import {getLegacyId} from '../utils/projects';
import {localStorageGet} from '../utils/storage';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from './params';
import {useUserId} from './userSession';

export const useAllGlobalProjects = () => {
  return useSelector((state) => Object.values(state.project.globalItems));
};

export const useGlobalProjectsFromOrganization = (organizationId: string) => {
  const allItems = useSelector((state) => state.project.globalItems);
  return useMemo(() => {
    return Object.values(allItems).filter((project) => project.organization_id === organizationId);
  }, [allItems, organizationId]);
};

export const useGlobalProjectsFromOrganizationById = (organizationId: string) => {
  const projects = useGlobalProjectsFromOrganization(organizationId);
  return useMemo(() => {
    const byId: Record<string, GlobalProject> = {};
    projects.forEach((proj) => {
      byId[proj.id] = proj;
    });
    return byId;
  }, [projects]);
};

export const useGlobalProjectsFromSelectedOrganization = () => {
  const orgId = useSelectedOrganizationId();
  return useGlobalProjectsFromOrganization(orgId);
};

export const useGlobalProject = (id: string) => {
  return useSelector((state) => state.project.globalItems[id]);
};

export const useSelectedGlobalProject = () => {
  const id = useSelectedGlobalProjectId();
  return useGlobalProject(id);
};

export const useSelectedProjectId = () => {
  const project = useSelectedGlobalProject();
  return project ? getLegacyId(project) : '';
};

export const useProjectByLegacyId = (projectId: string) => {
  // eslint-disable-next-line
  const [_env, vaultId] = projectId.split(':');
  const projects = useAllGlobalProjects();
  return projects.find((project) => project.vault_id === vaultId);
};

export const useIsSelectedProjectValid = () => {
  const project = useGlobalProject(useSelectedGlobalProjectId() || '');
  const orgId = useSelectedOrganizationId();
  return project?.organization_id === orgId;
};

/**
 * Get selected project ID by first checking for a projectId in the url,
 * then defaulting to the last project stored into local storage
 */
export const useSelectedProjectIdWithFallback = () => {
  const selectedProjectId = useSelectedGlobalProjectId();

  const projectId =
    selectedProjectId || localStorageGet(LocalStorageKeys.LAST_SELECTED_PROJECT) || '';
  return projectId;
};

/**
 * Get selected project with fallback to local storage
 */
export const useSelectedProjectWithFallback = () => {
  const selectedProjectId = useSelectedProjectIdWithFallback();
  return useGlobalProject(selectedProjectId);
};

export const useGlobalProjectIsCreating = (projectId: string) => {
  return useSelector((state) => state.project.globalItems?.[projectId]?.creating);
};

export const useProjectInfoLoading = () => {
  return useSelector((state) => state.project.loading);
};

export const useProjectRole = (userId: string, projectId?: string) => {
  return useSelector((state) => {
    return state.project.userRoles.find(
      (userRole) => userRole.userId === userId && userRole.projectId === projectId,
    );
  })?.role;
};

export const useSelectedProjectRole = () => {
  const userId = useUserId();
  const selectedProject = useSelectedGlobalProject();
  return useProjectRole(userId || '', selectedProject?.id);
};

export const useProjectMembers = (projectId?: string) => {
  return useSelector((state) => {
    return state.project.userRoles.filter((userRole) => userRole.projectId === projectId);
  });
};

export const useProjectCellData = (projectId: string) => {
  const getLoadedState = (state: {loading?: boolean; items: unknown[]} | undefined) => {
    const {loading, items} = state || {};
    return loading ? undefined : items;
  };

  const indexes = useSelector((state) => getLoadedState(state.services.projects[projectId]));
  const apiKeys = useSelector((state) => getLoadedState(state.keys.projects[projectId]));
  const members = useProjectMembers(projectId);

  return {
    loading: [indexes, apiKeys, members].includes(undefined),
    indexes: indexes?.length,
    apiKeys: apiKeys?.length,
    members: members?.length,
  };
};

export const useIsOwnerOfSelectedProject = () => {
  const roles = useSelectedProjectRole();
  return roles ? roles.includes(Roles.OWNER) : false;
};

export const useOwnedProjectIds = () => {
  const userId = useUserId();
  return useSelector((state) => {
    return (state.project.userRoles || [])
      .filter((userRole) => userRole.userId === userId && userRole.role === Roles.OWNER)
      .map((userRole) => userRole.projectId);
  });
};
