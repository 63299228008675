import {Box} from '@mui/material';
import {useIsDarkTheme} from '../../../selectors/ui';

const CONTRAST_COLOR = '#E2E2E5';

const styles = {
  contrastContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
};

interface CommonProps {
  height?: number | string;
  width?: number | string;
  alt?: string;
  sx?: object;
  padding?: number | string;
}

interface SingleSource extends CommonProps {
  src: string;
  contrast?: boolean;
  circular?: boolean;
}

interface DynamicSource extends CommonProps {
  srcDark: string;
  srcLight: string;
}

type ImageProps = (SingleSource | DynamicSource) & Omit<React.ComponentProps<'img'>, 'ref'>;

function Image(props: ImageProps) {
  const isDarkTheme = useIsDarkTheme();
  let src;
  if ('src' in props) {
    src = props.src;
  } else {
    src = isDarkTheme ? props.srcDark : props.srcLight;
  }

  let backgroundColor = 'transparent';
  let borderRadius: number | string = 1;
  if ('contrast' in props && props.contrast && isDarkTheme) {
    backgroundColor = CONTRAST_COLOR;
    borderRadius = props.circular ? '100%' : 1;
  }

  return (
    <Box sx={[styles.contrastContainer, {backgroundColor, borderRadius, padding: props.padding}]}>
      <Box
        component="img"
        src={src}
        alt={props.alt}
        height={props.height}
        width={props.width}
        sx={[styles.image, props.sx]}
        {...props}
      />
    </Box>
  );
}

export default Image;
