import {z} from 'zod';

const errorMessage = '* Required';
const errorMap: z.ZodErrorMap = () => ({message: errorMessage});

const schema = z.object({
  feedback: z.string(),
  reason: z.string({errorMap}).nonempty(errorMessage),
  features: z.string(),
});

export default schema;
export type ChurnData = z.infer<typeof schema>;
