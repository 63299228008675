import {Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {type Dispatch, type MouseEvent, type SetStateAction} from 'react';
import {type RequestType, requestTypes} from '../../../../../hooks/chart';

const styles = {
  rangeToggleButtonGroup: {
    height: 30,
    backgroundColor: 'background.paper',
    float: 'right',
    m: 1,
  },
};

interface FilterRequestTypeProps {
  selectedRequestTypes: RequestType[];
  setSelectedRequestTypes: Dispatch<SetStateAction<RequestType[]>>;
  exclusive?: boolean;
}
const FilterRequestTypes = ({
  selectedRequestTypes,
  setSelectedRequestTypes,
  exclusive = false,
}: FilterRequestTypeProps) => {
  const handleChange = (_event: MouseEvent<HTMLElement>, newList: RequestType[]) => {
    // protects against exclusive null value
    if (newList[0]?.name) {
      const sortedList = requestTypes.filter((rt) =>
        newList.map((item) => item.name).includes(rt.name),
      );
      setSelectedRequestTypes(sortedList);
    }
  };
  return (
    <Box display="flex" alignItems="center">
      <Typography noWrap variant="overline" textTransform="capitalize" mr={1}>
        Operation
      </Typography>
      <ToggleButtonGroup
        sx={styles.rangeToggleButtonGroup}
        value={exclusive ? selectedRequestTypes[0] : selectedRequestTypes}
        size="small"
        onChange={exclusive ? (event, value) => handleChange(event, [value]) : handleChange}
        exclusive={exclusive}
        aria-label="Request-Type-Selector"
      >
        {requestTypes.map((option) => (
          <ToggleButton sx={{textWrap: 'nowrap'}} key={option.name} value={option}>
            {option.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default FilterRequestTypes;
