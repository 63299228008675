import {Box} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useMatch} from 'react-router-dom';
import {
  fetchProjectQuotaLimits,
  fetchProjectQuotaUtilization,
} from '../../../../actions/quotaActions';
import {Plans} from '../../../../constants';
import {
  getOrgSettingsPath,
  useNavigateToAssistants,
  useNavigateToSelectProject,
} from '../../../../hooks/navigation';
import {useDashboardApi} from '../../../../hooks/use-api';
import {
  useOrganizationsAreLoading,
  useSelectedOrganization,
} from '../../../../selectors/organizations';
import {
  useGlobalProjectsFromOrganization,
  useSelectedProjectWithFallback,
} from '../../../../selectors/projects';
import {NAV_BAR_WIDTH} from '../../../../styles/theme';
import ArchivedIndicator from '../../../ForcedMigration/ArchivedIndicator';
import ForcedMigrationTracker from '../../../ForcedMigration/ForcedMigrationTracker';
import AssistantQuotaUtilizationTracker from '../../../Quotas/AssistantQuotaUtilizationTracker/AssistantQuotaUtilizationTracker';
import QuotaUtilizationTrackerContainer from '../../../Quotas/StarterUsageQuotaUtilizationTracker/StarterUsageQuotaUtilizationTracker';
import MigrationOperationTracker from '../../../ServerlessMigration/MigrationOperationTracker/MigrationOperationTracker';
import OrgSettingsNav from './OrgSettingsNav/OrgSettingsNav';
import ProjectNav from './ProjectNav/ProjectNav';

const styles = {
  root: {
    gridArea: 'navBar',
    width: NAV_BAR_WIDTH,
    backgroundColor: 'background.paper',
    borderRight: 1,
    borderColor: 'divider',
    zIndex: 1000,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    justifyContent: 'space-between',
  },
  trackers: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};
interface NavigationSidebarProps {
  hide?: boolean;
}

function NavigationBar({hide}: NavigationSidebarProps) {
  const selectedProject = useSelectedProjectWithFallback();
  const selectedOrg = useSelectedOrganization();
  const isLoading = useOrganizationsAreLoading();

  const {path: selectProjectPath} = useNavigateToSelectProject(selectedOrg?.id);
  const {path: assistantsPath} = useNavigateToAssistants(selectedOrg?.id, selectedProject?.id);
  const orgSettingsPath = getOrgSettingsPath(selectedOrg?.id);
  const inOrgSettings = useMatch(`${orgSettingsPath}/*`);
  const inAssistantPage = useMatch(`${assistantsPath}/*`);
  const inSelectProjectPage = useMatch(selectProjectPath);

  // Select the first project in the organization to fetch quota limits and utilization
  const projects = useGlobalProjectsFromOrganization(selectedOrg?.id);
  const projectId = projects[0]?.id;

  const showQuotaUtilizationTracker =
    projectId && selectedOrg.plan === Plans.FREE && !inAssistantPage;

  useDashboardApi(showQuotaUtilizationTracker ? fetchProjectQuotaLimits : undefined, {projectId});
  useDashboardApi(showQuotaUtilizationTracker ? fetchProjectQuotaUtilization : undefined, {
    projectId,
  });

  if (inSelectProjectPage || hide) {
    return null;
  }

  if (inOrgSettings) {
    return (
      <Box sx={styles.root}>
        <OrgSettingsNav selectedOrg={selectedOrg} />
        {showQuotaUtilizationTracker && <QuotaUtilizationTrackerContainer projectId={projectId} />}
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      <Box>
        <LoadingContainer loading={isLoading}>
          <ProjectNav selectedOrgId={selectedOrg?.id} selectedProject={selectedProject} />
        </LoadingContainer>
      </Box>
      <Box sx={styles.trackers}>
        <ArchivedIndicator />
        {selectedProject && <MigrationOperationTracker project={selectedProject} />}
        <ForcedMigrationTracker />
        {showQuotaUtilizationTracker && <QuotaUtilizationTrackerContainer projectId={projectId} />}
        {inAssistantPage && (
          <AssistantQuotaUtilizationTracker
            orgId={selectedOrg?.id}
            projectId={selectedProject.id}
          />
        )}
      </Box>
    </Box>
  );
}

export default NavigationBar;
