import {AddCircleOutline} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  useGlobalProjectsFromOrganization,
  useProjectInfoLoading,
} from '../../../../../../selectors/projects';
import useTheme from '../../../../../../styles/theme';
import styles from '../utilities/styles';

type ProjectSelectorProps = {
  orgId: string;
  disabled?: boolean;
};

// TODO(@retwere): consolodate this with the project selector for Vercel.
function ProjectSelector({orgId, disabled = false}: ProjectSelectorProps) {
  const {control} = useFormContext();
  const projectsAreLoading = useProjectInfoLoading();
  const projects = useGlobalProjectsFromOrganization(orgId);
  const theme = useTheme();

  const projectOptions = [
    ...projects.map((project) => ({
      label: project.name,
      value: project.id,
    })),
    {
      label: 'New project...',
      value: '',
      icon: <AddCircleOutline sx={{color: theme.palette.secondary.main}} />,
    },
  ];

  const renderValue = (value: string) => {
    return value === '' ? (
      <i>New project...</i>
    ) : (
      Object.values(projects).find((proj) => proj.id === value)?.name
    );
  };

  const currentProjectId = useWatch({name: 'globalProjectId'});
  const shouldDisable = disabled || !orgId || projectsAreLoading;

  return (
    <>
      <Typography variant="h6" sx={styles.label}>
        Project
      </Typography>
      <SelectInput
        name="globalProjectId"
        placeholder="Select a project"
        control={control}
        fullWidth
        disabled={shouldDisable}
        options={projectOptions}
        renderValue={renderValue}
      />
      <Box sx={{display: currentProjectId ? 'none' : 'block'}}>
        <Typography variant="h6" sx={styles.label}>
          Create a project
        </Typography>
        <TextInput name="newProjectName" control={control} fullWidth disabled={shouldDisable} />
      </Box>
    </>
  );
}

export default ProjectSelector;
