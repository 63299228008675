import {useAppSelector as useSelector} from '../app/store';

const emptyArr: never[] = [];

export const useApiKeysByGlobalProjectId = (projectId: string) => {
  return useSelector((state) => {
    return state.keys.projects[projectId]?.items || emptyArr;
  });
};

export const useCreatedApiKey = (projectId: string) => {
  return useSelector((state) => {
    return state.keys.projects[projectId]?.created;
  });
};

export const useKeysAreLoadingByGlobalProjectId = (projectId: string) => {
  return useSelector((state) => state.keys.projects[projectId]?.loading);
};

export const useFirstApiKeyByGlobalProjectId = (projectId: string) => {
  return useApiKeysByGlobalProjectId(projectId)[0];
};
