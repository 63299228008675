import {createReducer} from '@reduxjs/toolkit';
import type Orb from 'orb-billing';
import type Stripe from 'stripe';
import * as actions from '../actions/organizationActions';
import * as serviceActions from '../actions/serviceActions';
import type {
  OrgBillingDetails,
  ServerlessSavings,
  SupportData,
} from '../actions/types/organizationActions.types';
import * as userActions from '../actions/userActions';
import {type AsyncDataState, initAsyncDataState} from './utils';

interface BillingState {
  billingDetails: AsyncDataState<OrgBillingDetails>;
  currentSubscription: AsyncDataState<Orb.Subscription>;
  serverlessSavings: AsyncDataState<ServerlessSavings>;
  support: AsyncDataState<SupportData>;
  setupIntent?: Stripe.SetupIntent;
  upcomingInvoice?: Stripe.Invoice;
  pastInvoices?: Stripe.Invoice[];
  pastInvoicesLoading: boolean;
  showChurn: boolean;
  upgradeLoading: boolean;
  invoicesLoading: Record<string, boolean>;
}

const initialState = {
  billingDetails: initAsyncDataState(),
  currentSubscription: initAsyncDataState(),
  serverlessSavings: initAsyncDataState(),
  support: initAsyncDataState(),
  setupIntent: undefined,
  upcomingInvoice: undefined,
  pastInvoices: [],
  subscriptionCancelLoading: false,
  pastInvoicesLoading: true,
  showChurn: false,
  upgradeLoading: false,
  invoicesLoading: {},
} as BillingState;

const supportPlanLoadingActions = [
  actions.fetchSupportRequest.type,
  actions.changeSupportRequest.type,
];

const supportPlanErrorActions = [actions.fetchSupportError.type, actions.changeSupportError.type];

const billingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.updateBillingDetailsRequest, (state) => {
      state.billingDetails.loading = true;
    })
    .addCase(actions.updateBillingDetailsSuccess, (state, action) => {
      state.setupIntent = undefined;
      state.billingDetails.data = action.payload;
      state.billingDetails.loading = false;
    })
    .addCase(serviceActions.upgradeServiceSuccess, (state) => {
      state.upgradeLoading = false;
    })
    .addCase(serviceActions.upgradeServiceFailure, (state) => {
      state.upgradeLoading = false;
    })
    .addCase(serviceActions.upgradeServiceRequest, (state) => {
      state.upgradeLoading = true;
    })
    .addCase(actions.updateBillingDetailsError, (state) => {
      state.setupIntent = undefined;
      state.billingDetails.loading = false;
    })
    .addCase(actions.getPastInvoicesRequest, (state) => {
      state.pastInvoicesLoading = true;
    })
    .addCase(actions.getPastInvoicesSuccess, (state, action) => {
      state.pastInvoices = action.payload;
      state.pastInvoicesLoading = false;
    })
    .addCase(actions.getPastInvoicesFailure, (state) => {
      state.pastInvoicesLoading = false;
    })
    .addCase(actions.createSubscriptionRequest, (state) => {
      state.currentSubscription.loading = true;
    })
    .addCase(actions.createSubscriptionSuccess, (state, action) => {
      state.currentSubscription.data = action.payload.subscription;
      state.currentSubscription.loading = false;
      state.setupIntent = undefined;
    })
    .addCase(actions.createSubscriptionFailure, (state, action) => {
      state.currentSubscription.loading = false;
      state.currentSubscription.error = action.payload?.error?.message;
    })
    .addCase(actions.updateSubscriptionRequest, (state) => {
      state.currentSubscription.loading = true;
    })
    .addCase(actions.updateSubscriptionSuccess, (state, action) => {
      state.currentSubscription.data = action.payload.subscription;
      state.currentSubscription.loading = false;
    })
    .addCase(actions.updateSubscriptionError, (state, action) => {
      state.currentSubscription.loading = false;
      state.currentSubscription.error = action.payload?.error;
    })
    .addCase(actions.cancelSubscriptionRequest, (state) => {
      state.currentSubscription.loading = true;
    })
    .addCase(actions.cancelSubscriptionSuccess, (state) => {
      state.currentSubscription.data = undefined;
      state.currentSubscription.loading = false;
      state.currentSubscription.error = undefined;
      state.showChurn = true;
    })
    .addCase(actions.cancelSubscriptionError, (state, action) => {
      state.currentSubscription.loading = false;
      state.currentSubscription.error = action.payload?.error;
    })
    .addCase(actions.createSetupIntentSuccess, (state, action) => {
      state.setupIntent = action.payload;
    })
    .addCase(userActions.churnSurveySuccess, (state) => {
      state.showChurn = false;
    })
    .addCase(userActions.clearChurnSurvey, (state) => {
      state.showChurn = false;
    })
    .addCase(actions.getBillingDetailsRequest, (state) => {
      state.billingDetails.loading = true;
    })
    .addCase(actions.getBillingDetailsSuccess, (state, action) => {
      state.billingDetails.data = action.payload;
      state.billingDetails.loading = false;
    })
    .addCase(actions.getServerlessSavingsRequest, (state) => {
      state.serverlessSavings.loading = true;
    })
    .addCase(actions.getServerlessSavingsSuccess, (state, action) => {
      state.serverlessSavings.data = action.payload.data;
      state.serverlessSavings.loading = false;
    })
    .addCase(actions.downloadInvoiceRequest, (state, action) => {
      state.invoicesLoading[action.payload] = true;
    })
    .addCase(actions.downloadInvoiceSuccess, (state, action) => {
      state.invoicesLoading[action.payload] = false;
    })
    .addCase(actions.downloadInvoiceFailure, (state, action) => {
      state.invoicesLoading[action.payload] = false;
    })
    .addCase(actions.resetStripeState, () => {
      return initialState;
    })
    .addCase(actions.fetchSupportSuccess, (state, action) => {
      state.support.loading = false;
      state.support.data = action.payload;
    })
    .addCase(actions.changeSupportSuccess, (state, action) => {
      state.support.loading = false;
      state.support.data = action.payload;
      state.support.data.completedAction = action.payload.completedAction;
    })
    .addCase(actions.changeSupportReset, (state) => {
      if (state.support.data) {
        state.support.data.completedAction = undefined;
      }
    })
    .addMatcher(
      (action) => supportPlanLoadingActions.includes(action.type),
      (state) => {
        state.support.loading = true;
      },
    )
    .addMatcher(
      (action) => supportPlanErrorActions.includes(action.type),
      (state, action) => {
        state.support.loading = false;
        state.support.error = action.payload?.error?.message;
      },
    );
});

export default billingReducer;
