import CodingLanguageSelector from '../../../../../components/CodeSnippets/CodingLanguageSelector';
import Page from '../../../../../components/layout/Page/Page';
import PageHeader from '../../../../../components/layout/PageHeader/PageHeader';
import {useNavigateToGetStartedPage} from '../../../../../hooks/navigation';
import {useOrganizationsAreLoading} from '../../../../../selectors/organizations';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {type Guide as GuideType} from '../types';
import Guide from './Guide';

function GuidePage({guide, title}: {guide: GuideType; title: string}) {
  const organizationsLoading = useOrganizationsAreLoading();
  const organizationId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {go: navigateToGetStartedPage} = useNavigateToGetStartedPage(organizationId, projectId);

  return (
    <Page
      loading={organizationsLoading}
      header={
        <PageHeader
          title={title}
          breadcrumb={{
            text: 'Get started guides',
            action: navigateToGetStartedPage,
          }}
          actions={
            <CodingLanguageSelector
              label="Select a language"
              languageOptions={guide.supportedLanguages}
              sx={{
                width: 150,
                mb: -4,
              }}
            />
          }
        />
      }
      width="wide"
    >
      <Guide guide={guide} projectId={projectId} />
    </Page>
  );
}

export default GuidePage;
