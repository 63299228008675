import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import {ProjectPage} from '../../../../../constants';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {useNavigateToProjectPageGlobal} from '../../../../../hooks/navigation';
import {useAssistantsList} from '../../../../../selectors/assistants';
import {useSelectedOrganizationPlan} from '../../../../../selectors/organizations';
import {useServicesByGlobalProject} from '../../../../../selectors/services';
import {type GlobalProject} from '../../../../../types';
import {canUsePrivateEndpoints} from '../../../../../utils/planRules';
import NavSection from '../NavSection/NavSection';

function getTitleWithCount(title: string, count: number) {
  return `${title}${count ? ` (${count})` : ''}`;
}

function ProjectNav({
  selectedOrgId,
  selectedProject,
}: {
  selectedOrgId: string;
  selectedProject: GlobalProject;
}) {
  const plan = useSelectedOrganizationPlan();
  const enableGetStartedGuide = useFlag(Flags.ENABLE_GET_STARTED_GUIDE);
  const enableInferencePage = useFlag(Flags.SHOULD_SHOW_INFERENCE_PAGE);
  const enableStorageIntegrationsPage = useFlag(Flags.ENABLE_STORAGE_INTEGRATIONS_PAGE);
  const enableCmekPage = useFlag(Flags.ENABLE_CMEK_PROJECTS);
  const {getPath: getProjectPagePath} = useNavigateToProjectPageGlobal(selectedOrgId);
  const indexCount = useServicesByGlobalProject(selectedProject?.id)?.length ?? 0;
  const assistantCount = useAssistantsList(selectedProject?.id)?.length ?? 0;

  if (!selectedProject?.id) {
    return null;
  }

  return (
    <nav>
      {enableGetStartedGuide ? (
        <NavSection
          id="get-started-nav-section"
          icon={<RocketLaunchOutlinedIcon color="secondary" />}
          title="Get started"
          path={getProjectPagePath(selectedProject.id, ProjectPage.GET_STARTED)}
        />
      ) : null}
      <NavSection
        id="database-nav-section"
        icon={<TableRowsOutlinedIcon color="secondary" />}
        title="Database"
        items={[
          {
            title: getTitleWithCount('Indexes', indexCount),
            path: getProjectPagePath(selectedProject.id, ProjectPage.INDEXES),
          },
          !selectedProject.force_encryption_with_cmek
            ? {
                title: 'Backups',
                path: getProjectPagePath(selectedProject.id, ProjectPage.BACKUPS),
              }
            : undefined,
        ].filter((item) => item !== undefined)}
      />
      <NavSection
        id="assistants-nav-section"
        icon={<EmojiObjectsOutlinedIcon color="secondary" />}
        title="Assistant (Beta)"
        items={[
          {
            title: getTitleWithCount('Assistants', assistantCount),
            path: getProjectPagePath(selectedProject.id, ProjectPage.ASSISTANTS),
          },
        ]}
      />
      {enableInferencePage ? (
        <NavSection
          id="inference-nav-section"
          icon={<OfflineBoltOutlinedIcon color="secondary" />}
          title="Inference"
          path={getProjectPagePath(selectedProject.id, ProjectPage.MODELS)}
        />
      ) : null}
      <NavSection
        id="api-nav-section"
        icon={<LockOutlined color="secondary" />}
        title="API keys"
        path={getProjectPagePath(selectedProject.id, ProjectPage.KEYS)}
      />
      <NavSection
        id="manage-nav-section"
        icon={<SettingsOutlinedIcon color="secondary" />}
        title="Manage"
        items={[
          {
            title: 'Members',
            path: getProjectPagePath(selectedProject.id, ProjectPage.MEMBERS),
          },
          enableCmekPage
            ? {
                title: 'CMEK',
                path: getProjectPagePath(selectedProject.id, ProjectPage.CMEK),
              }
            : undefined,
          enableStorageIntegrationsPage
            ? {
                title: 'Storage integrations',
                path: getProjectPagePath(selectedProject.id, ProjectPage.STORAGE),
              }
            : undefined,
          canUsePrivateEndpoints(plan)
            ? {
                title: 'Network',
                path: getProjectPagePath(selectedProject.id, ProjectPage.NETWORK),
              }
            : undefined,
        ].filter((item) => item !== undefined)}
      />
    </nav>
  );
}

export default ProjectNav;
