import {type PodBasedIndexSpec} from '../types/index';

export function getTotalPods(podSpec: PodBasedIndexSpec) {
  const replicas = Number(podSpec.replicas || 0);
  const shards = Number(podSpec.shards || 0);
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const [_, size] = podSpec.pod_type?.split('.') || [];
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const [__, multiplier] = size?.split('x') || [];
  return replicas * shards * Number(multiplier || 1);
}
