import {type Dispatch, createAction} from '@reduxjs/toolkit';
import {push} from 'redux-first-history';
import DashboardApi, {type CreateProjectParams} from '../api/DashboardApi';
import {ProjectPage, type Roles} from '../constants';
import {type UsageDatapoint} from '../pages/org-settings/UsagePage/Usage/config';
import {type GlobalProject, type UserMember} from '../types';
import {viewedProjectsHistory} from '../utils/projects';
import {NotificationTypes, enqueNotification} from './notificationActions';

export const getProjectsRequest = createAction<undefined>('GET_PROJECTS_REQUEST');
export const getProjectsSuccess = createAction<GlobalProject[]>('GET_PROJECTS_SUCCESS');

export const getProjectInfoSuccess = createAction<{users: UserMember[]; projectId: string}>(
  'GET_PROJECT_INFO_SUCCESS',
);

export const removeUserFromProjectRequest = createAction<undefined>(
  'REMOVE_USER_FROM_PROJECT_REQUEST',
);
export const removeUserFromProjectSuccess = createAction<{
  orgId: string;
  projectId: string;
  userId: string;
}>('REMOVE_USER_FROM_PROJECT_SUCCESS');
export const removeUserFromProjectFailure = createAction<Error>('REMOVE_USER_FROM_PROJECT_FAILURE');

export const createProjectRequest = createAction<undefined>('CREATE_PROJECT_REQUEST');
export const createProjectSuccess = createAction<{
  organizationId: string;
  project: GlobalProject;
  userId: string;
}>('CREATE_PROJECT_SUCCESS');
export const createProjectFailure = createAction<Error>('CREATE_PROJECT_FAILURE');

export const editProjectRequest = createAction<undefined>('EDIT_PROJECT_REQUEST');
export const editProjectSuccess = createAction<{
  organizationId: string;
  globalProjectId: string;
  editData: {name: string; quota: number};
}>('EDIT_PROJECT_SUCCESS');
export const editProjectFailure = createAction<Error>('EDIT_PROJECT_FAILURE');

export const deleteProjectRequest = createAction<undefined>('DELETE_PROJECT_REQUEST');
export const deleteProjectSuccess = createAction<{
  organizationId: string;
  globalProjectId: string;
}>('DELETE_PROJECT_SUCCESS');
export const deleteProjectFailure = createAction<Error>('DELETE_PROJECT_FAILURE');

export const exitProjectSuccess = createAction<{
  orgId: string;
  globalProjectId: string;
  userId: string;
}>('EXIT_PROJECT_SUCCESS');

export const editProjectRoleRequest = createAction<undefined>('EDIT_PROJECT_ROLE_REQUEST');
export const editProjectRoleSuccess = createAction<{
  organizationId: string;
  projectId: string;
  userId: string;
  role: Roles;
}>('EDIT_PROJECT_ROLE_SUCCESS');

// TODO: move to projectActions - separate out this whole file, dashboardActions is too broad
export function getProjectInfo(token: string, project: GlobalProject) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token).getProjectInfo(project.id).then((res) => {
      dispatch(getProjectInfoSuccess({users: res.userRoles, projectId: project.id}));
    });
  };
}

export function exitProject(
  token: string,
  data: {orgId: string; userId: string; globalProjectId: string},
) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .exitProject(data.globalProjectId)
      .then(() => {
        dispatch(
          exitProjectSuccess({
            orgId: data.orgId,
            globalProjectId: data.globalProjectId,
            userId: data.userId,
          }),
        );
        dispatch(push(`/organizations/${data.orgId}/projects`));
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to exit: ${error.message}`,
          }),
        );
      });
  };
}

export function editProjectRole(
  token: string,
  data: {organizationId: string; project: GlobalProject; userId: string; role: Roles},
) {
  return (dispatch: Dispatch) => {
    dispatch(editProjectRoleRequest());
    return new DashboardApi(token)
      .editProjectRole(data.project.id, data.userId, data.role)
      .then(() => {
        dispatch(
          editProjectRoleSuccess({
            organizationId: data.organizationId,
            projectId: data.project.id,
            userId: data.userId,
            role: data.role,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to update user role: ${error.message}`,
          }),
        );
      });
  };
}

export function createProject(
  token: string,
  data: {
    organizationId: string;
    userId: string;
    params: CreateProjectParams;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createProjectRequest());
    return new DashboardApi(token)
      .createProject(data.organizationId, data.params)
      .then((res) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Project ${data.params.name} created successfully.`,
          }),
        );
        dispatch(
          createProjectSuccess({
            organizationId: data.organizationId,
            project: res.globalProject,
            userId: data.userId,
          }),
        );

        if (data.params.force_encryption_with_cmek) {
          dispatch(
            push(
              `/organizations/${data.organizationId}/projects/${res.globalProject.id}/${ProjectPage.CMEK}`,
            ),
          );
        } else {
          dispatch(
            push(
              `/organizations/${data.organizationId}/projects/${res.globalProject.id}/${ProjectPage.INDEXES}`,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(createProjectFailure(error));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Project ${data.params.name} failed to create. ${error.message}`,
          }),
        );
      });
  };
}

export function editProject(
  token: string,
  data: {
    organizationId: string;
    globalProjectId: string;
    editData: {quota: number; name: string};
    setQuota: boolean;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(editProjectRequest());
    return new DashboardApi(token)
      .editProject(data.globalProjectId, data.editData)
      .then(() => {
        // TODO: either separate out actions or combine endpoints stop this nonsense lol
        if (data.setQuota) {
          return new DashboardApi(token)
            .setQuota(data.globalProjectId, data.editData.quota)
            .then(() => {});
        }
        return Promise.resolve();
      })
      .then(() => {
        dispatch(
          editProjectSuccess({
            organizationId: data.organizationId,
            globalProjectId: data.globalProjectId,
            editData: {
              quota: data.editData.quota,
              name: data.editData.name,
            },
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Project updated successfully.',
          }),
        );
      })
      .catch((error) => {
        dispatch(editProjectFailure(error));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Project failed to update. ${error.message}`,
          }),
        );
      });
  };
}

export function deleteProject(token: string, data: {project: GlobalProject}) {
  const {project} = data;
  return (dispatch: Dispatch) => {
    dispatch(deleteProjectRequest());
    return new DashboardApi(token)
      .deleteProject(project.id)
      .then(() => {
        dispatch(
          deleteProjectSuccess({
            globalProjectId: project.id,
            organizationId: project.organization_id,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Project deleted successfully',
          }),
        );
        viewedProjectsHistory.remove(project.organization_id, project.id);
        dispatch(push(`/organizations/${project.organization_id}/projects`));
      })
      .catch((error) => {
        dispatch(deleteProjectFailure(error));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Project failed to delete. ${error.message}`,
          }),
        );
      });
  };
}

export function removeUserFromProject(
  token: string,
  data: {orgId: string; user: string; project: GlobalProject},
) {
  return (dispatch: Dispatch) => {
    dispatch(removeUserFromProjectRequest());
    return new DashboardApi(token)
      .removeUserFromProject(data.project.id, data.user)
      .then(() => {
        dispatch(
          removeUserFromProjectSuccess({
            orgId: data.orgId,
            projectId: data.project.id,
            userId: data.user,
          }),
        );
        dispatch(
          enqueNotification({type: NotificationTypes.SUCCESS, text: 'User removed from project.'}),
        );
      })
      .catch((error) => {
        dispatch(removeUserFromProjectFailure(error));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `User not removed from project. ${error.message}`,
          }),
        );
        return error;
      });
  };
}

export const getUsageDataRequest = createAction<undefined>('GET_USAGE_DATA_REQUEST');
export const getUsageDataSuccess = createAction<{
  organizationId: string;
  data: {usageData: UsageDatapoint[]};
}>('GET_USAGE_DATA_SUCCESS');
export const getUsageDataFailure = createAction<Error>('GET_USAGE_DATA_ERROR');

export function getUsageData(
  token: string,
  data: {organizationId: string; startTimestamp: number; endTimestamp: number},
) {
  return (dispatch: Dispatch) => {
    dispatch(getUsageDataRequest());
    return new DashboardApi(token)
      .getUsageData(data.organizationId, data.startTimestamp, data.endTimestamp)
      .then((usageData) => {
        dispatch(getUsageDataSuccess({organizationId: data.organizationId, data: usageData}));
      })
      .catch((error) => {
        dispatch(getUsageDataFailure(error.message));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Usage data failed to load. ${error.message}`,
          }),
        );
        return error;
      });
  };
}
