import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Button, Link, Typography} from '@mui/material';

type ForcedMigrationTrackerBoxProps = {
  daysPast: number;
  onClick: () => void;
};

function ForcedMigrationTrackerBox({daysPast, onClick}: ForcedMigrationTrackerBoxProps) {
  const color = 'error';
  return (
    <Button
      fullWidth
      onClick={onClick}
      color="secondary"
      variant="outlined"
      sx={{px: 1, textWrap: 'wrap'}}
    >
      <WarningAmberIcon color={color} fontSize="small" />
      <Typography px={1} color="text.primary" textAlign="left">
        {`Your index is ${daysPast} days past the migration deadline. `}
        <Link color="inherit">Take action now!</Link>
      </Typography>
    </Button>
  );
}

export default ForcedMigrationTrackerBox;
