import {Typography} from '@mui/material';
import {Outlet, useParams} from 'react-router-dom';
import {IntegrationContext} from '../../../../context/selected-integration';
import {useIntegration} from '../../../../hooks/integrations';
import LoadingPage from '../../../../pages/app/LoadingPage/LoadingPage';

function IntegrationGuard() {
  const {integrationId} = useParams();
  const integration = useIntegration(integrationId);

  if (!integrationId) return <Typography color="error">No integrationId provided</Typography>;

  if (!integration) return null;

  if (integration.loading) return <LoadingPage />;

  if (!integration.data) {
    return <Typography color="error">Integration {integrationId} not found</Typography>;
  }

  return (
    <IntegrationContext.Provider value={integration.data}>
      <Outlet />
    </IntegrationContext.Provider>
  );
}

export default IntegrationGuard;
