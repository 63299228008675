import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import AssistantFileActionMenu from '../../../../../../components/AssistantFileActionMenu/AssistantFileActionMenu';
import AssistantFileStatusLabel from '../../../../../../components/AssistantFileStatusLabel/AssistantFileStatusLabel';
import {AssistantFileStatus} from '../../../../../../constants';
import {type AssistantFile} from '../../../../../../types';
import {formatByteSize} from '../../../../../../utils/format';

interface FileItemProps {
  file: AssistantFile;
  goToFile: (fileId: string) => void;
}

const styles = {
  root: {
    p: 0.75,
    pt: 0,
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'background.surface',
    },
    '&:focus': {
      backgroundColor: 'background.surface',
      outline: 'none',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
  },
  name: {
    fontWeight: '600',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
  },
  fileSize: {
    mr: 0.5,
  },
};

export default function FileItem({file, goToFile}: FileItemProps) {
  return (
    <Card component="button" sx={styles.root} onClick={() => goToFile(file.id)}>
      <Box sx={styles.header}>
        <Typography noWrap sx={styles.name}>
          <Tooltip placement="left" title={file.name}>
            <span>{file.name}</span>
          </Tooltip>
        </Typography>

        <AssistantFileActionMenu name={file.name} id={file.id} status={file.status} />
      </Box>
      {file.status === AssistantFileStatus.AVAILABLE ? (
        <Box sx={styles.fileInfo}>
          <Typography color="secondary">
            Updated {new Date(file.updated_on).toLocaleDateString()}
          </Typography>
          <Typography color="secondary" sx={styles.fileSize}>
            {file.size ? formatByteSize(file.size, 2) : '--'}
          </Typography>
        </Box>
      ) : (
        <Typography color="secondary">
          <AssistantFileStatusLabel inline fileId={file.id} />
        </Typography>
      )}
    </Card>
  );
}
