import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface IndexTabHeaderProps {
  title: string;
  subtitle?: React.ReactNode;
  actions: React.ReactNode;
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    mb: 2,
  },
  subtitle: {
    mt: 0.5,
  },
};

export default function IndexTabHeader({title, subtitle, actions}: IndexTabHeaderProps) {
  return (
    <Box sx={styles.header}>
      <Box>
        <Typography component="h2" variant="h6">
          {title}
        </Typography>
        {subtitle ? <Typography sx={styles.subtitle}>{subtitle}</Typography> : null}
      </Box>
      {actions ? <Box>{actions}</Box> : null}
    </Box>
  );
}
