import {
  createPrivateEndpoint,
  deletePrivateEndpoint,
} from '../../../../../actions/privateEndpointsActions';
import {useControllerApi} from '../../../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {type EndpointFormSchema} from '../CreatePrivateEndpointModal/endpointFormSchema';

export function useProjectEndpointActions() {
  const project = useSelectedGlobalProject();

  const {setQueryData: setDeleteEndpointData} = useControllerApi<{
    globalProjectId: string;
    endpointId: string;
  }>(project);

  const {setQueryData: setCreateEndpointData} = useControllerApi<{
    globalProjectId: string;
    formData: EndpointFormSchema;
  }>(project);

  return {
    deleteEndpoint: (endpointId: string) => {
      setDeleteEndpointData({
        action: deletePrivateEndpoint,
        data: {globalProjectId: project.id, endpointId},
      });
    },
    createEndpoint: (formData: EndpointFormSchema) => {
      setCreateEndpointData({
        action: createPrivateEndpoint,
        data: {globalProjectId: project.id, formData},
      });
    },
  };
}
