import {Box, Step, StepLabel, Stepper} from '@mui/material';

type ForcedMigrationStepsProps = {
  fullMigration: boolean;
  activeStep: number;
};

function ForcedMigrationSteps({fullMigration, activeStep}: ForcedMigrationStepsProps) {
  const steps = ['Select type', fullMigration ? 'Migration' : 'Collection', 'Complete'];

  return (
    <Box pt={2}>
      <Stepper activeStep={activeStep}>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default ForcedMigrationSteps;
