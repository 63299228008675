import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Link, Typography, alpha} from '@mui/material';
import {LocalStorageKeys} from '../../constants';
import {useNavigateToCollectionPageGlobal} from '../../hooks/navigation';
import {useArchivedIndexesByGlobalProject} from '../../selectors/forcedMigration';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../selectors/params';
import {localStorageGet} from '../../utils/storage';

function ArchivedIndicator() {
  const globalProjectId = useSelectedGlobalProjectId();
  const orgId = useSelectedOrganizationId();
  const archivedIndexes = useArchivedIndexesByGlobalProject(globalProjectId || '');
  const {getPath} = useNavigateToCollectionPageGlobal(orgId || '', globalProjectId || '');
  const path = getPath();
  const hasViewed = localStorageGet(LocalStorageKeys.VIEWED_ARCHIVED_NOTIFICATION);
  if (!archivedIndexes || archivedIndexes.length === 0 || hasViewed) {
    return null;
  }
  return (
    <Box
      sx={{
        border: 1,
        borderColor: (theme) => alpha(theme.palette.secondary.main, 0.23),
        borderRadius: 1,
        display: 'flex',
        p: 1,
      }}
    >
      <InfoOutlinedIcon fontSize="small" sx={{mr: 1}} />
      <Typography>
        Your pod index has been auto-archived. <Link href={path}>View</Link>
      </Typography>
    </Box>
  );
}

export default ArchivedIndicator;
