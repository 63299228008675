import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link, {type LinkProps} from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import {type MutableRefObject, useEffect, useRef} from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import {type ChatMessage} from '../../types';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../../utils/tracking';
import ThemeAwarePineconeLogo from '../Utilities/ThemeAwarePineconeLogo';

interface ChatMessageBlockProps {
  message: ChatBlockMessage;
  showAssistantActions: boolean;
  chatId?: string;
  onCopyChatHistory: () => void;
}

interface ChatBlockMessage extends Omit<ChatMessage, 'role'> {
  role: ChatMessage['role'] | 'error';
}

const styles = {
  root: {
    display: 'flex',
    gap: 2,
    pb: 1,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  avatar: {
    backgroundColor: 'background.surface',
  },
  userAvatar: {
    svg: {
      color: 'text.primary',
    },
  },
  errorAvatar: {
    backgroundColor: 'background.surface',
  },
  name: {
    fontWeight: 'bold',
    mb: 0.5,
  },
  content: {
    overflowWrap: 'anywhere',
    width: '100%',
    '> p': {
      mt: 0,
      mb: 2,
      mx: 0,
    },
    '> p:last-of-type': {
      mb: 0,
    },
    table: {
      my: 0.5,
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      th: {
        border: 1,
        borderColor: 'divider',
        fontWeight: '600',
        color: 'text.secondary',
        py: 0.5,
        px: 1,
      },
      td: {
        border: 1,
        borderColor: 'divider',
        py: 0.5,
        px: 1,
      },
    },
  },
  actions: {
    mt: 1,
    gap: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    m: 0,
  },
};

const RoleContent: Record<ChatBlockMessage['role'], {name: string; avatar: React.ReactNode}> = {
  assistant: {
    name: 'Pinecone',
    avatar: (
      <Avatar sx={styles.avatar}>
        <ThemeAwarePineconeLogo sx={{width: '24px'}} />
      </Avatar>
    ),
  },
  user: {
    name: 'You',
    avatar: (
      <Avatar sx={[styles.avatar, styles.userAvatar]}>
        <PersonIcon />
      </Avatar>
    ),
  },
  error: {
    name: 'Error',
    avatar: (
      <Avatar sx={styles.errorAvatar}>
        <ErrorOutlineOutlinedIcon color="error" />
      </Avatar>
    ),
  },
};

const LinkComponent = (props: LinkProps) => {
  return <Link {...props} target="_blank" rel="noreferrer" />;
};

function ChatMessageBlock({
  chatId,
  message: {role, content},
  showAssistantActions,
  onCopyChatHistory,
}: ChatMessageBlockProps) {
  const ref = useRef<HTMLDivElement>(null);
  const animationFrameRef: MutableRefObject<number | undefined> = useRef();

  useEffect(() => {
    animationFrameRef.current = window.requestAnimationFrame(() => {
      if (ref.current) {
        ref.current.scrollIntoView({behavior: 'smooth', block: 'end'});
      }
      return () => animationFrameRef.current && cancelAnimationFrame(animationFrameRef.current);
    });
  }, [content, showAssistantActions]);

  const roleContent = RoleContent[role];
  const assistantResponseIsLoading = role === 'assistant' && content === '';

  const handleNegativeFeedback = () => {
    sendToHeap(HEAP_TRACKING_EVENTS.KM_CHAT_NEGATIVE_FEEDBACK, {chatId});
  };

  return (
    <div ref={ref}>
      <Box sx={styles.root}>
        {roleContent.avatar}
        <Box>
          <Typography sx={styles.name} color={role === 'error' ? 'error' : undefined}>
            {roleContent.name}
          </Typography>
          <Typography
            sx={styles.content}
            data-testid="message-content"
            color={role === 'error' ? 'error' : undefined}
          >
            {assistantResponseIsLoading ? <CircularProgress size={14} sx={{mt: 0.5}} /> : null}
            <ReactMarkdown
              remarkPlugins={[remarkGfm, [remarkMath, {singleDollarTextMath: false}]]}
              rehypePlugins={[rehypeKatex]}
              components={{
                a: LinkComponent,
              }}
            >
              {content}
            </ReactMarkdown>
          </Typography>
          {role === 'assistant' && showAssistantActions && (
            <Box sx={styles.actions} data-testid="assistant-actions">
              <CopyButton
                description="assistant-chat-copy-messages"
                color="secondary"
                text=""
                tooltipLabels={['Copy messages', 'Copied']}
                onCopy={onCopyChatHistory}
                sx={styles.actionButton}
              />
              {/**
               * This button does not trigger an action in the Console.
               * Clicks are tracked through Heap.
               */}
              <CopyButton
                description="assistant-chat-response-negative-feedback"
                color="secondary"
                text=""
                tooltipLabels={['Mark response as unsatisfactory', 'Feedback sent']}
                onCopy={handleNegativeFeedback}
                icon={<ThumbDownAltOutlinedIcon fontSize="small" />}
                sx={styles.actionButton}
              />
              {chatId && (
                <CopyButton
                  description="assistant-chat-copy-id"
                  color="secondary"
                  text={chatId}
                  tooltipLabels={['Copy chat ID', 'Copied']}
                  icon={<TagOutlinedIcon fontSize="small" />}
                  sx={styles.actionButton}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ChatMessageBlock;
