import {useEffect} from 'react';
import {
  editProjectRole,
  exitProject,
  getProjectInfo,
  removeUserFromProject,
} from '../actions/dashboardActions';
import {type Roles} from '../constants';
import {useDashboardApi} from '../hooks/use-api';
import {useInvitesAreLoading, useSelectedProjectPendingInvites} from '../selectors/invites';
import {
  useIsOwnerOfOrganization,
  useOrganizationsAreLoading,
  useSelectedOrganization,
} from '../selectors/organizations';
import {
  useProjectInfoLoading,
  useProjectMembers,
  useSelectedGlobalProject,
} from '../selectors/projects';
import {useUserId} from '../selectors/userSession';
import {type GlobalProject} from '../types';
import InviteUsersButton from './InviteUsersButton';
import UserTable from './UserTable';
import Page from './layout/Page/Page';
import PageHeader from './layout/PageHeader/PageHeader';

const ProjectUserTable = () => {
  const project = useSelectedGlobalProject();
  const selectedOrg = useSelectedOrganization();
  const isLoading = useOrganizationsAreLoading();
  const userId = useUserId();
  const invitesAreLoading = useInvitesAreLoading();
  const projectInfoLoading = useProjectInfoLoading();
  const pendingInvites = useSelectedProjectPendingInvites() || [];

  const {setQueryData} = useDashboardApi<GlobalProject>();
  useEffect(() => {
    if (project?.id) {
      setQueryData({action: getProjectInfo, data: project});
    }
  }, [project, setQueryData]);
  const {setQueryData: setQueryRemoveUser} = useDashboardApi<{
    orgId: string;
    user: string;
    project: GlobalProject;
  }>();
  const handleRemoveUser = (user: string) => {
    setQueryRemoveUser({
      action: removeUserFromProject,
      data: {orgId: selectedOrg.id, user, project},
    });
  };
  const {setQueryData: setQueryExitProject} = useDashboardApi<{
    orgId: string;
    globalProjectId: string;
    userId: string;
  }>();
  const handleExitProject = () => {
    setQueryExitProject({
      action: exitProject,
      data: {
        orgId: selectedOrg.id,
        globalProjectId: project.id,
        userId: userId || '',
      },
    });
  };
  const {setQueryData: setQueryEditUser} = useDashboardApi<{
    organizationId: string;
    project: GlobalProject;
    userId: string;
    role: Roles;
  }>();
  const handleEditUser = (role: Roles, user: string) => {
    setQueryEditUser({
      action: editProjectRole,
      data: {
        organizationId: project.organization_id,
        userId: user,
        project,
        role,
      },
    });
  };
  const userIds = useProjectMembers(project?.id);
  const isOrgOwner = useIsOwnerOfOrganization();
  return (
    <Page header={<PageHeader title="Members" actions={<InviteUsersButton isOrg={false} />} />}>
      <UserTable
        userIds={userIds.map((user) => user.userId)}
        invites={pendingInvites}
        isLoading={isLoading || invitesAreLoading || projectInfoLoading}
        handleEditUser={handleEditUser}
        handleRemoveUser={handleRemoveUser}
        handleExit={handleExitProject}
        canLeave={!isOrgOwner}
        project={project}
      />
    </Page>
  );
};

export default ProjectUserTable;
