import {type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {type PrivateEndpointFormData, type PrivateEndpointsItem} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {projectLoaded} from './serviceActions';
import {decodeErrorMessage} from './utils/errors';

export const listPrivateEndpointsRequest = createAction<{globalProjectId: string}>(
  'LIST_PRIVATE_ENDPOINTS_REQUEST',
);
export const listPrivateEndpointsSuccess = createAction<PrivateEndpointsItem>(
  'LIST_PRIVATE_ENDPOINTS_SUCCESS',
);
export const listPrivateEndpointsFailure = createAction<{globalProjectId: string; error: string}>(
  'LIST_PRIVATE_ENDPOINTS_FAILURE',
);

export function listPrivateEndpoints(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listPrivateEndpointsRequest({globalProjectId: data.globalProjectId}));
    return api
      .listPrivateEndpoints(data.globalProjectId)
      .then((response) => {
        dispatch(
          listPrivateEndpointsSuccess({
            globalProjectId: data.globalProjectId,
            endpoints: response.endpoints,
          }),
        );
        dispatch(projectLoaded({globalProjectId: data.globalProjectId}));
      })
      .catch((error) => {
        dispatch(
          listPrivateEndpointsFailure({
            globalProjectId: data.globalProjectId,
            error: decodeErrorMessage(error.message),
          }),
        );
      });
  };
}

export const createPrivateEndpointRequest = createAction<{globalProjectId: string}>(
  'CREATE_PRIVATE_ENDPOINT_REQUEST',
);
export const createPrivateEndpointSuccess = createAction<{
  globalProjectId: string;
  endpointCreated: boolean;
}>('CREATE_PRIVATE_ENDPOINT_SUCCESS');
export const createPrivateEndpointFailure = createAction<{globalProjectId: string; error: string}>(
  'CREATE_PRIVATE_ENDPOINT_FAILURE',
);
export const createPrivateEndpointClearResponse = createAction<undefined>(
  'CREATE_PRIVATE_ENDPOINT_CLEAR_RESPONSE',
);

export function createPrivateEndpoint(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    formData: PrivateEndpointFormData;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createPrivateEndpointRequest({globalProjectId: data.globalProjectId}));
    return api
      .createPrivateEndpoint(data.globalProjectId, data.formData)
      .then((response) => {
        dispatch(
          createPrivateEndpointSuccess({
            globalProjectId: data.globalProjectId,
            endpointCreated: response.ok,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Endpoint connection has been created.`,
          }),
        );
        dispatch(
          listPrivateEndpoints(api, {
            globalProjectId: data.globalProjectId,
          }) as unknown as AnyAction,
        );
      })
      .catch((error) => {
        dispatch(
          createPrivateEndpointFailure({
            globalProjectId: data.globalProjectId,
            error: decodeErrorMessage(error.message),
          }),
        );
      });
  };
}

export const deletePrivateEndpointRequest = createAction<{
  globalProjectId: string;
  endpointId: string;
}>('DELETE_PRIVATE_ENDPOINT_REQUEST');
export const deletePrivateEndpointSuccess = createAction<{
  globalProjectId: string;
  endpointId: string;
}>('DELETE_PRIVATE_ENDPOINT_SUCCESS');
export const deletePrivateEndpointsFailure = createAction<{
  globalProjectId: string;
  endpointId: string;
}>('DELETE_PRIVATE_ENDPOINT_FAILURE');

export function deletePrivateEndpoint(
  api: ControllerApi,
  data: {globalProjectId: string; endpointId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      deletePrivateEndpointRequest({
        globalProjectId: data.globalProjectId,
        endpointId: data.endpointId,
      }),
    );
    return api
      .deletePrivateEndpoint(data.endpointId)
      .then(() => {
        dispatch(
          deletePrivateEndpointSuccess({
            globalProjectId: data.globalProjectId,
            endpointId: data.endpointId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Endpoint connection successfully removed.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          deletePrivateEndpointsFailure({
            globalProjectId: data.globalProjectId,
            endpointId: data.endpointId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Endpoint connection could not be removed. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}
