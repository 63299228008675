import CohereIcon from '../../../icons/models/cohere.svg';
import type {Dataset} from './types';

export const wikipediaDataset: Dataset = {
  datasetInformation: {
    collectionName: 'wikipedia-simple-33efb1f',
    indexName: 'sample-wikipedia',
    title: 'Wikipedia - Simple',
    subtitle: 'A pre-processed version from Wikipedia suitable for semantic search.',
    description: `The dataset consists of encoded vectors derived from the Simple English Wikipedia. It's a pre-processed version from Wikipedia suitable for semantic search.`,
    dimension: 1536,
    metric: 'cosine',
    totalNamespaces: 1,
    totalVectors: 485859,
    upgradeRequired: true,
  },
  embeddingModel: {
    icon: CohereIcon,
    name: 'Cohere/multilingual-22-12',
    dimension: [1536],
    metric: ['cosine'],
    type: 'text',
  },
  exampleVectorData: [
    {
      id: '0',
      values: [
        -0.0187605713, 0.0263916198, 0.0475792587, 0.0330387205, -0.0207503289, 0.0108234063,
        0.0415006578, -0.0138627067, 0.0191322844, 0.0250796918, -0.00681109307, -0.0108999358,
        -0.00717734, 0.0221934505, 0.0597801879, 0.0601737686, -0.0601737686, 0.0462673306,
        -0.014289083, -0.00170687307, 0.00324702193, -0.00905230362, -0.0485413373, 0.00672363117,
        -0.0276816823, 0.0509902723, -0.0114137745, 0.0119166803, -0.00312129548, 0.0248391721,
        -0.00871338882, -0.0219091978, -0.0523022, 0.00404237816, 0.0462236, 0.0103751644,
        0.00606766716, 0.0335197635, -0.00372806215, -0.00717734, -0.0970826745, -0.0344599783,
        0.0139938993, -0.0969077498, -0.0270257182, -0.0247735754, -0.025998041, 0.0284907054,
        0.0312457532, 0.0876805261, -0.0261292346, -0.00191869482, 0.0139720337, 0.0277691446,
        0.0613982342, 0.00723747, 0.00620979303, 0.0448679402, 0.0592554174, -0.0267196018,
        0.0297589023, 0.0219310634, -0.0138845723, -0.0175361056, 0.0236584358, 0.0266758706,
        -0.0206519347, 0.0457425602, 0.00536250602, 0.053045623, -0.0286000315, 0.0383083,
        -0.01045716, 0.000265631272, 0.0221059881, 0.0508590788, -0.0342194587, 0.00134199311,
        0.0151855676, 0.0437090695, 0.00106457493, -0.0345255733, -0.0811208859, 0.0568064861,
        0.0094568152, 0.0385925509, -0.00490879733, 0.0305897892, -0.0358812325, -0.0411289446,
        -0.000572943594, -0.0145296035, 0.0504654981, -0.00567955524, 0.0128568951, -0.0277254134,
        -0.07836584, 0.0357281752, 0.00535157323, 0.0275067575, 0.00717734, -0.020247424,
        0.0079644965, 0.0130208861, -0.00447695469, -0.0343506485, 0.00352580659, 0.0228931438,
        -0.0223137103, -0.00478853751, -0.0333011076, -0.00310489652, -0.0277691446, 0.00844553672,
        0.0331261829, 0.0279003363, 0.0143765453, -0.0426376611, 0.0712376907, -0.0142672174,
        0.0145842666, -0.0163553692, 0.0320110433, -0.00677282875, 0.0236803014, -0.0161476471,
        -0.0618355423, -0.0133488681, 0.000370688, 0.0432280302, -0.00417357124, -0.0197773147,
        -0.034044534, -0.0415006578, -0.053045623, -0.0317923911, 0.0112388507, 0.0101674423,
        -0.0348754227, 0.0156447422, 0.024248803, 0.0190120246, 0.0140922936, 0.00449882,
        -0.0340226665, 0.0406041741, -0.0589930303, 0.0145405363, -0.00396584906, -0.00594740734,
        -0.0687887594, -0.0130208861, 0.0221387856, 0.00882271584, 0.0309177712, 0.0617043488,
        0.0277035479, 0.00630272087, 0.0138299083, 0.0554070957, 0.00251179561, 0.0085002007,
        -0.0103041017, 0.0115121687, -0.0167926792, -0.042856317, -0.0215046871, -0.0629725456,
        -0.0437090695, 0.0104954243, 0.00877351873, -0.00968093611, -0.0112497834, -0.0608297326,
        0.00091493316, -0.00435942784, -0.00527504412, 0.0616168864, -0.0489786491, 0.0121025369,
        0.0218217373, -0.0428344496, 0.00989959, -0.03338857, 0.0541389, 0.00552923, -0.0165630914,
        -0.000468740967, -0.0105500882, 0.0137533788, 0.00161121169, -0.0181264728, 0.014945047,
        0.0169348046, -0.0308959056, 0.0228056833, 0.0456550978, 0.00579434913, -0.00792076532,
        -0.046354793, -0.0119822761, 0.00344927749, 0.0340663977, -0.0540514365, -0.0213078987,
        0.0367339849, 0.00404237816, -0.01143564, -0.0222699791, -0.0114465719, -0.00661430415,
        0.0515150428, 0.00940761715, -0.0301524792, -0.0399919413, 0.00766931288, -0.0138627067,
        -0.0221497193, -0.0127147697, 0.00598567165, -0.0096208062, 0.00717187347, -0.0234835129,
        0.00809022319, -0.0610046536, -0.00180663425, 0.0232867226, 0.0125945099, -0.0299338251,
        0.0280533955, 0.00941855, -0.0282501839, 0.00841273833, -0.00450975262, 0.00745612429,
        0.00814488716, -0.0286000315, -0.0260636378, -0.0490661114, -0.0338477455, -0.025451405,
        -0.0077021108, -0.0161039177, 0.0466171764, -0.0318142548, -0.0100909136, 0.0165740252,
        -0.0782783777, -0.0117308237, 0.00659243856, 0.0157431364, -0.0448242091, -0.047185678,
        -0.0556257516, -0.0162679087, 0.00981759466, 0.0224995669, 0.00434576161, 0.05418263,
        -0.0295839775, -0.00726480177, -0.0114028417, -0.0529144332, -0.00307483133, -0.0696196482,
        0.016858276, -0.0127585009, -0.00609499915, -0.00992692262, -0.0474480651, 0.0155135496,
        0.00598020526, -0.0172846522, 0.00351214083, -0.0314644091, -0.0118182851, -0.0125617115,
        -0.045042865, -0.0234616473, 0.0396202281, -0.0499844588, 0.00954427663, 0.030546058,
        0.0141032264, 0.027419297, 0.0243799966, 0.0304148663, 0.041544389, 0.0532205477,
        0.0173611809, 0.0302618071, 0.0224558357, 0.0483664162, -0.0181264728, -0.0423971415,
        -0.0540077053, 0.035269, 0.00324702193, 0.00592007535, -0.0116542941, 0.0196023919,
        -0.0290592071, -0.0429219119, -0.0131192803, -0.0697508454, 0.0333011076, -0.0429875106,
        0.0403199233, 0.0477541834, -0.0633223951, -0.0155682126, -0.0000235950611, 0.024248803,
        0.0209361855, -0.00782237109, -0.0308303088, -0.0301306155, -0.0302618071, -0.00889377855,
        0.0129115591, 0.0300868843, -0.0430968367, -0.00458354875, -0.0192197468, 0.0318142548,
        0.0348972864, 0.0261292346, 0.0585557222, -0.0293871891, -0.0231336653, -0.0175033063,
        -0.0368214473, 0.0591242239, 0.0192416124, -0.00407244358, -0.0390517265, 0.0301524792,
        -0.00112128851, -0.0392047837, -0.0238333605, -0.0223683733, -0.0160601866, -0.0198101141,
        0.022936875, 0.000264435512, -0.0335853584, -0.0338477455, 0.000261019, 0.063715972,
        -0.0161476471, -0.0786719546, -0.0462236, 0.0395983607, 0.00324155553, 0.0189464279,
        0.00553469639, 0.0724621639, 0.0270475838, -0.0368870459, 0.0364060029, -0.0231773965,
        -0.0155682126, 0.0236803014, -0.0128240967, 0.00764198089, -0.00915616471, -0.0495034195,
        -0.0145951994, -0.0127366353, -0.0350066125, 0.016912939, -0.0314425416, 0.00248446385,
        -0.00513565168, -0.00451248605, 0.0365153328, 0.0187824368, 0.0102002406, 0.0313988104,
        0.028403243, 0.0333667025, 0.0440151878, 0.055232171, -0.0235272441, 0.0589493029,
        0.0512089245, -0.00697508408, 0.00167544151, -0.0105446214, -0.0497658029, 0.0316174664,
        -0.00443322351, 0.0347442292, -0.0115777655, -0.0152402306, -0.0493284948, 0.068745032,
        0.0439933203, -0.0167270824, -0.0183013957, -0.015928993, -0.0274848938, -0.0387456082,
        0.00305023277, 0.00551009784, 0.0482789539, -0.0115231015, -0.0192306787, -0.0047284076,
        0.047185678, -0.00942948274, -0.000811755483, 0.030393, 0.0247298442, 0.015109038,
        0.0243144, -0.0393797085, -0.0217670724, 0.00189409615, -0.00795356371, -0.0278566051,
        -0.0165412258, 0.0118838819, 0.0324702188, 0.0201052967, -0.00435669441, -0.0478416421,
        -0.0277910102, -0.015819665, -0.0126273073, 0.04587375, 0.0242050737, 0.0263041575,
        0.0564566366, 0.00284251082, 0.0304367319, 0.0110201957, 0.0309833679, -0.00191186182,
        0.0701006874, 0.0162241776, 0.0521710068, -0.0102603706, 0.00792623218, -0.0568502173,
        -0.00800276082, -0.0550135151, 0.0325358175, 0.0596927255, 0.00428836467, 0.0468795635,
        0.0284251086, 0.0323171616, -0.0184763204, -0.0398170166, 0.0401231349, 0.0479728356,
        0.0141797559, 0.00409157574, -0.0329731256, -0.0197882485, -0.0212095045, 0.0317049287,
        0.0176563654, -0.008921111, 0.0290373415, -0.0503343083, 0.00658150576, -0.0201052967,
        -0.000576701714, -0.0131302131, 0.0368433148, 0.0587743782, 0.0298026334, 0.00983946,
        0.0336290896, -0.0142453527, 0.00230544037, 0.0378053933, -0.00230954, 0.0198866427,
        -0.0427688546, -0.0507278852, -0.0196242575, 0.00198702444, -0.0210236479, -0.0227838177,
        0.0181483384, -0.0134691279, 0.0207503289, -0.0187496394, -0.0192197468, 0.00781690516,
        -0.00678922795, 0.0125289131, 0.0193946697, -0.0411726758, 0.00684389146, -0.0206300691,
        0.000923816, -0.0216905437, -0.0185419172, 0.017568903, -0.00653777504, 0.016202312,
        0.0357719064, 0.0192306787, -0.0435341448, -0.0130318189, -0.060786, -0.00698601687,
        -0.00710081076, 0.0162679087, 0.0077950391, -0.0482789539, -0.00696961768, 0.0406260379,
        0.018607514, -0.0200178362, -0.0118838819, 0.00567955524, 0.00595287373, 0.00433482882,
        0.00360506889, 0.00515751727, 0.0183560606, -0.0237240326, 0.0128459623, 0.00484046806,
        -0.000919716258, -0.0258887131, -0.00506458897, 0.00906323642, -0.0084892679, -0.016584957,
        0.0116433613, -0.0201052967, -0.00107550772, -0.00669629965, -0.0627101585, 0.0131848771,
        -0.041872371, 0.00394945033, 0.0103259673, -0.00155381486, -0.0168036111, -0.0500719212,
        -0.014945047, 0.0231118, -0.010725012, -0.0234616473, 0.00128801272, -0.0413257331,
        0.0172081236, -0.0186512433, -0.00226170942, -0.00219611288, -0.00386745459, 0.0106922137,
        -0.0281627215, -0.00631912, 0.00725386897, 0.00700788246, 0.0242269374, 0.0324483551,
        -0.00253229449, -0.0350503437, 0.0254295394, -0.00850566663, -0.0138955051, 0.0234397817,
        0.0180499442, -0.0018489986, -0.0647217855, -0.00806835759, 0.00505638961, 0.0158305988,
        0.00115818647, -0.00190502882, 0.00467921, 0.0125289131, 0.0313550793, -0.00622072536,
        0.0147919888, -0.0164100341, 0.0287312251, 0.00861499459, -0.0346130356, -0.0759169,
        0.0101838419, 0.0161367152, 0.0268945247, 0.0138736395, 0.00541170314, -0.0203895494,
        0.00145132036, -0.00282337843, -0.00777317397, -0.0306335203, -0.004897865, -0.0175470375,
        0.00728666736, -0.015109038, 0.00853299908, -0.0254732706, -0.0165084284, -0.0237458982,
        0.0197773147, -0.0157431364, -0.00877351873, -0.000946364773, -0.00264572166, 0.0022794751,
        -0.00953334384, -0.0581184141, 0.0386581458, -0.0131630115, -0.0336290896, -0.0358156376,
        0.031202022, 0.0131192803, 0.0395327657, 0.0230243374, 0.0138189755, 0.0296495743,
        0.032185968, -0.0354657881, 0.0117745548, -0.000348993373, 0.00540623674, -0.0151746348,
        -0.00452068541, -0.00388385379, 0.0140594961, 0.0183779262, -0.00414897269, 0.00347387628,
        0.00345474388, 0.042856317, -0.00690402137, 0.0270038527, 0.0266321395, -0.00096413045,
        -0.00805195887, 0.00792076532, 0.0134581951, 0.0119822761, -0.0234397817, 0.00490333093,
        -0.0159071274, 0.0357063077, 0.0077021108, 0.0336946845, -0.0291466694, -0.0155572807,
        -0.0169894677, -0.0345255733, 0.0115886983, 0.00391938491, -0.00730306609, -0.00452615181,
        -0.0274411626, 0.00472020777, -0.0251015574, -0.014999711, 0.0125726443, 0.0355969816,
        0.0145405363, 0.00114930363, 0.00457808236, -0.0287312251, 0.0369526409, -0.0210455135,
        0.025014095, 0.00667443406, 0.011271649, 0.0220841225, 0.000197472502, -0.0337602831,
        -0.0026129235, 0.00686029065, -0.00374172814, -0.0123211909, 0.0427688546, 0.043905858,
        -0.00898670685, 0.0603049584, 0.00895390939, 0.0103314333, -0.0105828866, 0.00705707958,
        -0.0137752444, 0.0136768501, 0.0272662379, 0.00810662191, -0.0394015722, 0.0304367319,
        0.0386144146, -0.0000970280162, -0.0135128591, -0.0180608761, -0.0404511169, 0.00438675936,
        -0.0208815224, 0.0154151544, 0.0269382559, 0.000628632202, 0.0170878638, 0.0135893878,
        -0.00856579654, -0.028403243, 0.00145132036, -0.00576701714, -0.00270858477, -0.0174158458,
        -0.00490879733, -0.00358047034, -0.0224121045, -0.0155900782, 0.00578888273, -0.0030393,
        0.0513401181, -0.0169676021, -0.0201818272, 0.00737959519, -0.0120806713, 0.0198647771,
        0.0106539493, -0.0530018955, 0.00304476637, 0.000545611721, -0.00539530395, 0.0265228115,
        0.0146935945, -0.0151855676, 0.0404511169, -0.0467483699, 0.0185637828, 0.00755998539,
        -0.0195039976, -0.0204442125, 0.0134909935, -0.0367339849, -0.0524333902, 0.0350940749,
        0.0352252685, 0.00649951026, -0.0469670258, -0.00488966517, -0.00346294348, -0.00956614222,
        0.00641751476, 0.00556202838, 0.0332573764, -0.0620542, -0.0176235661, -0.0212095045,
        0.0244893245, -0.0376523361, 0.0217998717, -0.000744109217, 0.00695321895, 0.00600207085,
        0.00837447401, -0.0115449671, -0.00430749729, 0.00522858, 0.0102931689, 0.0216905437,
        -0.0260199066, -0.0256481934, 0.000790573307, 0.0132723385, -0.00272361725, 0.025779387,
        -0.0165412258, -0.00977933, 0.00872432161, -0.0419161022, -0.00422003539, 0.00149778451,
        -0.00584901264, -0.0280096643, -0.0138955051, 0.0207831264, -0.059386611, -0.0111787207,
        -0.0116980253, 0.00771851, -0.00630818726, 0.0215921495, 0.0131192803, -0.0345037095,
        0.00232047285, 0.0192525443, 0.00354220578, 0.0297151711, -0.0547074, -0.0515150428,
        0.0143656125, -0.0104680927, -0.0330605879, 0.00807382353, -0.00546090072, 0.0255607311,
        0.00356407114, -0.0109983301, 0.0441901088, 0.0376304686, -0.0100253168, -0.01587433,
        0.00309123052, 0.0207831264, 0.0198429115, 0.0103095677, -0.0190448221, 0.0247517098,
        -0.00518484926, -0.00150325091, 0.00654324144, -0.00134677615, -0.00992145576, 0.0167708136,
        -0.0244455934, 0.040341787, -0.0154151544, -0.0153823569, -0.0177219622, 0.0242706686,
        0.00585447904, -0.0285125691, -0.0191104189, -0.0194384, -0.0109983301, -0.0252327491,
        -0.0135565903, -0.0118073523, 0.0131302131, -0.0173174497, 0.0158961955, 0.00358867,
        0.00291084033, 0.0236803014, 0.00810115598, 0.00796996336, -0.0115886983, -0.0133598009,
        -0.03338857, 0.0138627067, -0.00857672933, -0.0186184458, 0.0119057475, 0.027091315,
        -0.0425720662, -0.0054363017, -0.00324155553, -0.0139501682, 0.0632786602, 0.0255170017,
        -0.025451405, -0.00329895248, 0.0313113481, 0.0165193602, -0.00781143829, -0.00260609039,
        0.0730306655, 0.0158415306, -0.0224777013, 0.00510012032, -0.00700788246, 0.0257575214,
        0.0191760156, 0.00911243353, -0.0128131639, -0.00347660948, -0.0154260872, -0.0313332155,
        -0.0502468459, -0.0254732706, -0.0380677804, -0.031530004, 0.0197335854, -0.0207065977,
        0.010123712, -0.00264435494, 0.0237458982, 0.030546058, 0.0168910734, 0.0478416421,
        0.0316830613, -0.00443049055, 0.0184763204, 0.0288405512, 0.0166396201, 0.0107578104,
        -0.0502905771, -0.000826788, -0.016256975, -0.0552759022, -0.0135347247, -0.036012426,
        -0.0151527692, -0.0370182358, -0.0319017172, -0.00216468121, 0.0531330854, 0.0338696092,
        0.00291357352, -0.0026826195, -0.0294965152, -0.0102549046, -0.0312676206, 0.0158305988,
        0.0236584358, 0.0431624316, -0.0231773965, 0.024576785, -0.0215046871, -0.0215374865,
        -0.000126324318, -0.021460956, -0.0127912983, -0.0139501682, 0.00487599941, -0.0108452719,
        -0.0268507935, 0.0117636221, 0.00363513408, 0.0281189922, -0.00136112538, -0.0201708935,
        0.0105992854, -0.00905230362, -0.0315956026, 0.0170331988, -0.0358593687, -0.0481477603,
        -0.0333011076, 0.00443869, -0.00264025526, 0.00844553672, -0.000735909678, -0.00338368118,
        -0.00142808841, -0.000754358654, -0.000757091795, 0.00459721452, 0.0191869475,
        -0.0231555309, 0.00886644702, -0.0121681327, 0.00709534436, 0.0355313867, -0.0172190554,
        -0.0302836727, 0.0265228115, 0.0231992621, -0.0258887131, -0.0228712801, 0.0161148496,
        0.0287312251, 0.0490661114, -0.00333721703, 0.00660883775, 0.0331917815, 0.00897030812,
        -0.0077950391, -0.0213406961, 0.039029859, 0.0499407277, -0.0469232947, 0.0408665575,
        -0.00616059545, 0.0263041575, -0.0258887131, -0.0117198909, 0.0122227967, -0.0175579712,
        0.0554945581, 0.046529714, 0.0509465411, 0.00582168065, 0.0332573764, -0.0123649221,
        0.0095497435, 0.0127257025, 0.0101619763, 0.026107369, 0.0241176113, 0.0230899341,
        0.0144093437, -0.0119822761, 0.0163444374, 0.0141360248, -0.000679879391, 0.0108999358,
        -0.0114575047, 0.00490879733, 0.0302399416, 0.0113919089, -0.014453074, -0.0189026967,
        0.00676736236, -0.0091069676, -0.0024379997, 0.00663616927, 0.0284469742, -0.0211767051,
        0.0376304686, 0.0390079953, 0.0223355759, -0.00341921253, -0.00983946, -0.00891564414,
        -0.00695868488, 0.0177547596, 0.00475027272, 0.0244018622, -0.02123137, 0.00616606185,
        -0.0154479528, 0.0207175314, -0.00145815336, 0.0139829665, -0.0104352944, 0.0146826617,
        0.0252327491, 0.0166396201, 0.0302618071, 0.0415662527, 0.00810662191, 0.00270311837,
        0.00415170565, 0.0244674589, 0.0139283026, -0.00603486924, -0.00122378289, -0.00373079535,
        -0.0145842666, 0.00413804, -0.0147154601, 0.0220622569, -0.0256263278, -0.00344927749,
        -0.0110475272, 0.055888135, -0.016420966, -0.00331261847, -0.0116214957, 0.0405167118,
        -0.0253639426, 0.0235272441, 0.0245549195, -0.0376304686, -0.0190776214, 0.0258449838,
        0.0320329107, -0.0096208062, 0.0397514217, 0.0129552893, 0.0654652119, -0.0091998959,
        0.0354001932, 0.00517938286, 0.00370619679, -0.0124086533, 0.00365426624, -0.0269819871,
        -0.0225214325, -0.000402802922, -0.0402105935, 0.000494022912, -0.00278238067,
        -0.00593100814, 0.0172955841, 0.00787703507, -0.00300103542, -0.0196351893, -0.0148029216,
        -0.00626992295, -0.0354657881, 0.0366246589, 0.025670059, 0.000337548146, -0.026107369,
        -0.00802462641, -0.0165412258, 0.015928993, -0.0123867877, -0.0255607311, -0.00381005765,
        -0.00400958024, -0.0209689829, -0.00433209585, -0.0208815224, -0.0476229899, 0.0268507935,
        -0.0129552893, -0.0159399267, 0.0205644723, -0.0327544697, 0.0137643116, 0.0109819314,
        -0.00116365287, 0.00812302157, -0.0350940749, 0.0086751245, 0.00807382353, 0.0101893079,
        0.00529690972, 0.0199741051, 0.00776224118, -0.0126054417, 0.0177656934, -0.0156228766,
        0.0283595119, 0.0169566702, 0.00266212062, 0.0140048321, 0.0187933687, 0.0347660929,
        0.0118510835, -0.0163991, 0.0111732539, 0.0153386258, -0.00485140085, 0.0289936103,
        -0.0226307586, 0.00747252349, -0.0174377114, -0.0187605713, 0.0093638869, -0.0244018622,
        -0.0173611809, 0.0311582908, -0.00472567417, -0.00477213832, 0.018771505, 0.000184319055,
        -0.0243144, 0.0585119911, 0.00168910739, 0.00594740734, 0.00308303093, 0.00446328847,
        -0.00564675685, 0.0101401107, 0.0379584506, 0.00128937932, 0.0209689829, 0.0205098093,
        -0.00170960627, -0.00195012637, 0.00554289622, -0.00535430619, -0.0140266977, -0.0161257833,
        -0.00354220578, 0.018170204, -0.0214828216, -0.0124633163, -0.0362092145, 0.0248391721,
        0.0257137902, 0.0231336653, 0.0202146247, 0.0211767051, -0.0125726443, -0.00358867,
        -0.00840180553, 0.0172737204, 0.000217288092, -0.0118182851, 0.0246423818, -0.0191978812,
        0.0275067575, -0.0232429914, -0.0226307586, -0.00934202131, 0.00896484219, 0.00102221058,
        0.0142125543, 0.00173420494, 0.00151828339, 0.0291685332, -0.00748345628, -0.00193919369,
        -0.0172955841, 0.00329895248, -0.0159399267, -0.00304476637, -0.0274411626, 0.0243362654,
        -0.00524224574, -0.041216407, 0.000883501547, 0.00972466636, -0.00294637168, -0.0203786157,
        0.00281244586, -0.0145405363, 0.000128972097, -0.00218244689, -0.0103806304, 0.0373680852,
        0.0194165353, 0.00660883775, -0.0142562846, 0.0456113666, -0.0112497834, 0.0158305988,
        -0.00181210064, -0.0281189922, -0.0139392354, -0.0102494378, -0.0109928641, -0.00426103314,
        0.039510902, -0.00907416921, -0.00050119753, 0.00246396498, 0.0288842823, 0.00232457253,
        -0.0490661114, 0.0000553896716, -0.0184653867, -0.0323390253, -0.0290373415, 0.000698670046,
        -0.0175579712, 0.00614966266, -0.00234233821, -0.0373243541, -0.00930922292, 0.0193728041,
        -0.00505912257, 0.0116214957, 0.0368214473, 0.0204332788, -0.010069048, -0.0399482101,
        0.00649404386, 0.00646671187, -0.0220841225, 0.00206628675, -0.0211548395, -0.00492793,
        0.0258668475, -0.00877898559, -0.00388658699, 0.0380240493, 0.0101619763, -0.0154588856,
        0.00768571207, -0.026763333, -0.0171534587, -0.0135019263, -0.016092984, 0.0101346439,
        0.00803555921, -0.00567408884, 0.0292559955, -0.00540897, 0.016584957, 0.00750532188,
        -0.0272662379, 0.00643391395, 0.0251452886, -0.0367121212, -0.0111787207, 0.0169785358,
        0.00910150073, 0.0321641043, -0.0417849086, -0.0101893079, -0.0124961147, -0.000772124331,
        0.0156228766, 0.0458300188, -0.00905777048, 0.00366519904, 0.0171643924, 0.00616606185,
        -0.0126163745, -0.0235053785, 0.035684444, -0.00485140085, -0.00413530646, 0.00813942,
        0.00136932486, -0.0343506485, 0.0404073857, -0.00831981, 0.0108999358, 0.00735226367,
        0.0196789205, -0.0252546147, -0.0104243616, -0.00143628789, -0.0177219622, -0.0190120246,
        0.018006213, -0.0364934653, 0.000217800552, 0.00692042056, 0.00746705709, 0.00444415631,
        0.0308521744, 0.00330988527, -0.00125794765, 0.00265118806, -0.000167578313, 0.0251452886,
        0.0310708303, -0.0027673482, 0.0177438278, -0.00261429, 0.0279222019, 0.00554289622,
        0.0395327657, -0.0342413224, 0.0368651785, -0.0188699, 0.0241176113, 0.000666555134,
        -0.0683077201, -0.0237240326, 0.0146279978, 0.00518484926, -0.00177793589, 0.000257090083,
        -0.000435942755, -0.00635738485, 0.0278784707, -0.0186731089, 0.0498532653, -0.00749438908,
        -0.000571918616, -0.0462673306, -0.0110475272, 0.00141852221, -0.00504545681, -0.0183888581,
        0.0140485633, 0.00546090072, -0.0091998959, -0.0201818272, 0.0378928557, -0.000453708461,
        0.00781690516, -0.031857986, -0.051077731, -0.014289083, 0.0161257833, 0.0157322045,
        0.016366303, 0.0294309203, -0.0130099533, -0.036340408, 0.0078715682, 0.0313988104,
        0.00511651952, 0.00863139331, -0.017842222, 0.0173939802, 0.0108726034, -0.0060786,
        -0.00267032022, 0.0216577463, 0.0272006411, -0.0315081403, 0.0136331189, 0.00462454651,
        0.0118948147, -0.0408009626, -0.0317705236, -0.0101565095, -0.0467483699, -0.0290154759,
        0.0172846522, 0.0168036111, 0.0231773965, 0.0191978812, 0.0199959707, 0.0154042216,
        0.0105500882, 0.0416318513, 0.00619886024, 0.0424408726, -0.000267510331, -0.0322734304,
        0.00837447401, 0.00251316209, 0.0205863379, 0.00445235567, -0.00573421875, 0.00546090072,
        0.000314486912, -0.00507552177, 0.014289083, -0.0699257627, 0.00590914255, 0.0258012526,
        -0.000558936037, -0.0186184458, 0.0317267925, 0.0325358175, -0.00522311358, 0.00449608685,
        0.00585447904, -0.0189573597, 0.000484456774, -0.0116980253, -0.00733039808, 0.0108234063,
        -0.00948961265, -0.00490059797, 0.00829247851, 0.00488146581, 0.00169730699, 0.0392485149,
        0.00333448383, 0.0077950391, 0.0172299892, -0.024030149, 0.0299556907, 0.00590367615,
        -0.0308521744, 0.00199249084, -0.0234179161, -0.0317267925, -0.0187168401, 0.0216358807,
        -0.00669083325, 0.0209908485, -0.0060786, 0.00536523899, -0.0103205005, -0.0117308237,
        0.0087461872, -0.0306991171, -0.00451521901, -0.0368214473, 0.0166505538, -0.0180280786,
        0.0249703638, 0.0211111084, -0.00375266094, -0.00296003767, -0.0546199381, 0.0202583559,
        0.00709534436, -0.00177383609, -0.00365699944, -0.00841820519, -0.000658355595,
        -0.0206847321, -0.00975746475, -0.00236283708, -0.00399318105, -0.0432936251,
        -0.00298463646, -0.0110857924, -0.0150653077, 0.0209143199, 0.0159071274, -0.0226744898,
        0.00773490919, -0.00144858728, -0.0353783257, -0.010069048, 0.0262822919, -0.0387456082,
        -0.0305241924, 0.0041161743, 0.0133925993, -0.0544887446, 0.00167134171, -0.0646780506,
        0.0046409457, 0.00461908, 0.0185309835, 0.0470982194, 0.0229806062, -0.0226307586,
        0.00308303093, -0.0275504887, -0.0119604105, -0.0322297, 0.0158852618, -0.00010292144,
        0.0356188454, -0.0175798368, 0.00180253445, -0.0290373415, -0.000131278211, -0.0122665279,
        0.0219857283, -0.0199959707, -0.0177219622, 0.00212778337, 0.00625352375, -0.00091356656,
        0.0169348046, 0.0129880877, 0.040013805, 0.00706254598, 0.0223793071, 0.0203020871,
        0.00743425917, 0.00228904118, -0.0250578262, -0.00246396498, 0.00430476386, 0.0319891796,
        0.0491973, 0.0245549195, 0.0167161506, -0.0354439244, 0.0378491245, -0.00343014533,
        0.0139501682, -0.00680016028, 0.0225214325, -0.00418723701, -0.00391938491, 0.0355751142,
        0.0084072724, -0.0298244972, -0.0382208377, -0.014125092, 0.0260636378, 0.00323062297,
        -0.0334760323, 0.0161913782, 0.0171097293,
      ],
      metadata: {
        langs: 30,
        paragraph_id: 0,
        row_id: 0,
        text: 'The 24-hour clock is a way of telling the time in which the day runs from midnight to midnight and is divided into 24 hours, numbered from 0 to 23. It does not use a.m. or p.m. This system is also referred to (only in the US and the English speaking parts of Canada) as military time or (only in the United Kingdom and now very rarely) as continental time. In some parts of the world, it is called railway time. Also, the international standard notation of time (ISO 8601) is based on this format.',
        title: '24-hour clock',
        url: 'https://simple.wikipedia.org/wiki?curid=9985',
        views: 2450.6254882812,
        wiki_id: 9985,
      },
    },
    {
      id: '1',
      values: [
        -0.00454878295, -0.00812551193, 0.0526305437, -0.000444070232, -0.0271101017,
        -0.00698697334, 0.0631566569, -0.0245107971, 0.0156065682, 0.017958831, 0.00524425786,
        -0.00224619638, -0.0544779859, 0.0200640522, 0.0364332274, 0.0305901617, -0.0522009097,
        0.0595477037, 0.00919960439, 0.019709602, 0.00416210946, -0.024338942, -0.0457563512,
        0.0290649496, -0.04262, 0.0140276514, -0.018356245, 0.00668085692, 0.0331465043,
        0.0137698697, -0.0107946321, -0.0136839421, -0.0211381465, -0.00306922, 0.0324161202,
        0.00756161287, 0.00982794911, -0.000620624225, -0.0273464024, -0.0231359582, -0.0747568533,
        -0.00535972277, 0.0122231757, -0.113767907, -0.0156817548, -0.0171640031, -0.0052523138,
        0.0124809584, 0.0192907061, 0.0802132487, -0.00987091288, -0.0415459089, 0.00963998213,
        0.0288286507, 0.0482912101, 0.000522948918, -0.0185281, 0.0455415323, 0.0657774433,
        -0.0233078133, -0.00173734501, 0.00472600805, -0.021256296, -0.0216644518, 0.0335761383,
        -0.0222766846, -0.0046776738, 0.0223196484, -0.00926405, 0.0480334274, -0.00986554194,
        0.0435222387, 0.0125991078, -0.0139739467, -0.00537314918, 0.04133109, -0.0176580846,
        -0.0209340677, 0.0225129835, 0.0442526229, -0.00446554087, -0.0432214923, -0.08524,
        0.0705034509, -0.00101770286, 0.055466149, -0.0291938409, 0.0314494371, -0.0404933,
        -0.0836503431, 0.0129535589, -0.0290005039, 0.0747998208, -0.00184743956, 0.0170995574,
        -0.0117183523, -0.0570558086, 0.000445077196, -0.0127924448, 0.00744346296, 0.0129857818,
        -0.00626733107, 0.00620825635, 0.0473460108, -0.0302894153, -0.0229426213, 0.00179104973,
        0.00277921511, 0.00132449064, -0.0317501836, 0.00445211446, -0.0000329570248, -0.02975237,
        -0.0138128325, 0.0355095081, 0.00952183269, 0.0336191021, -0.0385814123, 0.0192907061,
        -0.0281627122, 0.0418896191, 0.020676285, 0.015456195, -0.0107248165, 0.00449507823,
        -0.0335331745, -0.0180554986, -0.00149567414, 0.00501332805, 0.0298812613, -0.0338768847,
        -0.0277115926, -0.0390754938, -0.0324161202, 0.00771198608, -0.0265730545, 0.0180984624,
        0.0298382975, -0.0257567447, 0.00528185116, 0.0386673398, 0.0318361111, 0.00376469526,
        0.00393923512, -0.0306546073, 0.0528024, -0.0487208478, 0.0359176621, -0.00431248266,
        -0.0226418748, -0.0383880772, -0.0199888665, 0.00831347797, -0.00115934887, 0.0415673889,
        0.0783228427, 0.0348650515, -0.00134395855, -0.00545639126, 0.0387962312, -0.0145539567,
        -0.0232433677, 0.0218900107, 0.0114176059, -0.0424911082, -0.0178406816, -0.0170136299,
        -0.0595477037, -0.0405577421, 0.000359485421, 0.00747568579, -0.0279908571, -0.0299886707,
        -0.0437155738, 0.000437692797, 0.00628881296, -0.0373999104, 0.0545639135, -0.0291293953,
        0.0200533122, 0.0287642051, -0.0343494862, 0.0276901107, -0.0151984124, 0.0676678419,
        0.00351496856, -0.0122768804, -0.00858737156, -0.00239388435, 0.0298168156, -0.0455844961,
        0.00322496356, 0.0190544054, 0.00580010097, -0.0230285488, 0.0273893662, 0.016422879,
        -0.0044225771, 0.0436296463, -0.054091312, 0.0309338719, 0.0180877224, 0.0352946892,
        -0.0285493862, -0.0108537078, 0.029559033, -0.0156710129, -0.0340702236, -0.0287642051,
        0.0140706152, -0.0250693243, 0.0616099611, 0.0369487926, -0.0418681353, -0.0500956848,
        0.0244678333, -0.035724327, -0.0397629142, -0.0386458561, -0.00836181175, -0.00261944369,
        0.0135980146, -0.0238448586, -0.00654122513, -0.0593758486, -0.0251982156, 0.0148439622,
        0.0144358063, 0.0015601198, 0.0319220386, 0.0108751897, -0.0258211903, 0.033425767,
        -0.021267036, -0.00204211893, 0.0112242689, -0.00719105126, -0.0113853831, -0.0529742539,
        -0.0464437716, -0.0336835496, -0.0104885157, -0.0418036915, 0.04262, -0.013909501,
        -0.0248974711, 0.0112779737, -0.0706323385, -0.0073790173, -0.00980109628, 0.023565596,
        -0.0479904637, -0.012180212, -0.0510838516, -0.00449776324, -0.00620288588, 0.0022314277,
        -0.0190758873, 0.03437097, -0.0402569957, -0.0214925967, -0.0586454645, -0.049623087,
        0.00321422261, -0.0470882282, 0.0152306352, -0.00922645722, 0.0337909572, -0.00746494485,
        -0.0424696282, 0.011976134, 0.00309338723, 0.00639085192, -0.0246396884, -0.0168310329,
        -0.033017613, -0.00306922, -0.0319650024, -0.0254774801, 0.0163584333, -0.0332109481,
        0.00493814144, 0.0454985686, 0.00200184039, 0.0351657979, 0.0287212413, 0.0122983623,
        0.0323301926, 0.0628559068, -0.00420238776, 0.0448541157, 0.0189040322, 0.0285279043,
        -0.00812551193, -0.0669374615, -0.0424481444, 0.0539624207, -0.00397145795, -0.00525768427,
        -0.00467230333, 0.0191295929, 0.00960776, -0.0549935512, -0.000714943, -0.0597625226,
        0.0159395374, 0.00419164682, 0.0369917564, 0.0451978222, -0.0485919565, 0.00623510871,
        -0.00783550646, 0.0386243761, 0.0157891642, 0.00926405, -0.045412641, -0.0252196975,
        0.00536240824, -0.00775495, 0.0148869259, 0.0459711701, -0.0522009097, -0.016992148,
        0.00416210946, 0.0169169605, 0.0466585904, 0.0501386486, 0.0476037897, -0.0170780756,
        -0.074198328, 0.00424266653, -0.0663789362, 0.0361109972, 0.0190436654, -0.0182918,
        -0.0195592288, 0.00932849571, 0.00421581417, 0.00335922511, -0.00671307975, -0.0349724591,
        -0.0622114539, -0.0306975711, 0.0168525148, -0.00502406899, -0.0275182575, 0.0206548031,
        -0.0178729035, 0.0462289527, -0.00837792363, -0.0993321, -0.0314064734, 0.0335976221,
        -0.0239307862, 0.0183992088, 0.0310412813, 0.0481623188, -0.0257352628, -0.0568839535,
        0.0196021926, 0.0058323238, -0.0371421278, 0.0215033367, -0.0374213904, 0.00404664455,
        0.00524425786, -0.02629379, -0.0276041832, -0.0330605768, -0.045412641, 0.0444674417,
        -0.0450259671, -0.011009451, 0.0055047255, -0.0244248696, 0.045412641, -0.0161758363,
        0.016433619, 0.0462719165, 0.0369487926, 0.00553694833, 0.0157462, 0.0267449096,
        -0.0255848896, 0.0564972796, 0.0200640522, -0.0286353137, 0.0231574401, 0.0011492793,
        -0.0673671, 0.0184529144, 0.0333183594, 0.0248545073, -0.00798588, -0.0192477424,
        0.00253217365, 0.0630707294, 0.0381732583, -0.0355524719, -0.0589891747, -0.0147150708,
        -0.0185710639, 0.00677215494, -0.0325450115, 0.012169471, 0.0221477933, 0.0100159151,
        -0.0337050296, 0.00854977872, 0.0457563512, 0.00602566032, -0.00783550646, 0.0416962802,
        0.0321153738, 0.00753476052, 0.0377436206, -0.0321153738, 0.0055423188, 0.000138121613,
        -0.0328027941, -0.0216000061, -0.0335116945, 0.00698697334, 0.0635003671, 0.00852829684,
        -0.00979035534, -0.0396769866, -0.0302034877, 0.000308969495, -0.00460248766, 0.00786235929,
        0.0279908571, 0.0265730545, 0.0274538118, -0.00973665062, 0.000178400092, -0.0229211394,
        0.0110309329, 0.00557454163, 0.0485489927, -0.0155850863, 0.0302894153, 0.0218040831,
        -0.00234420737, -0.0739835128, 0.0178192, -0.0387103036, 0.0275397394, 0.0611803234,
        0.00736290589, 0.00962924119, 0.0243819058, 0.0470452644, -0.0110738967, -0.0263797175,
        0.0366050825, 0.0296664424, 0.0117935389, 0.00339681841, -0.0104831457, -0.0468734093,
        -0.0559387505, 0.0484630652, 0.0276901107, -0.0413740538, 0.0399562493, -0.0472600833,
        0.00490860408, -0.0146721071, 0.0172714125, -0.0304183066, 0.0401710682, 0.063199617,
        0.0246826522, -0.0242100507, 0.0248115435, 0.00466961833, -0.00156549015, 0.0373569466,
        -0.0191295929, 0.0166376978, -0.0860563144, -0.0152306352, -0.0462719165, -0.0286782775,
        0.000703530735, -0.00178299402, 0.00758846523, -0.0167558473, -0.0100749899, -0.0267234277,
        -0.0202359073, -0.0216966737, -0.00657344749, -0.011986875, 0.0487208478, -0.0307620168,
        0.017400302, -0.0199566428, -0.00115800626, 0.00885589514, -0.0305901617, 0.0663359687,
        0.0294516236, 0.0432644561, 0.0192155205, 0.0138880191, -0.036153961, -0.0236300416,
        -0.0510408878, 0.00261407322, 0.0169814061, 0.0051422189, 0.0514705256, -0.0414384976,
        -0.000988165382, 0.0418896191, 0.0236085597, -0.0406436697, -0.00792680494, 0.00619214494,
        0.0233078133, -0.0204077624, -0.0369917564, 0.00671307975, 0.0207407307, -0.0211488865,
        0.00878607854, -0.023372259, -0.0212348141, 0.00060753373, -0.0146613661, 0.00431248266,
        0.00540000154, -0.0237589311, 0.00719642173, -0.0354880244, 0.0274967756, -0.00293764379,
        -0.0391614214, 0.00236166152, -0.0294731054, 0.0151339667, -0.00314440648, -0.0238448586,
        -0.00313903601, -0.0399992131, -0.0136302374, -0.00414868351, 0.00425877795, -0.0135443099,
        0.00225962256, -0.0456704237, 0.0207299907, -0.0311916545, -0.000119408905, -0.00515564531,
        -0.00274699228, 0.0206977669, -0.0333398394, -0.010053508, 0.000203574149, -0.0103112906,
        0.0265515726, 0.0119331703, -0.0383236296, -0.035724327, 0.0268738, -0.015638791,
        -0.0210951827, 0.0107946321, 0.0153165627, 0.0023764302, -0.0250048786, -0.0207729544,
        0.0189577378, -0.0228566937, -0.000568262185, -0.0166269559, 0.0136946831, 0.017593639,
        -0.00154669362, 0.00819532759, 0.00397414342, -0.0203970224, 0.0482052825, 0.0310842451,
        -0.00309607247, -0.0435866863, 0.018753659, 0.0237589311, 0.026078973, 0.0255419258,
        -0.0180984624, -0.0165839922, -0.0230929945, -0.00122983626, -0.00494082691, -0.00856052,
        -0.0091029359, -0.001183516, 0.00843699835, -0.0149621125, 0.0239522681, 0.0128998542,
        -0.00756161287, -0.0402140319, 0.0107033346, -0.00104119873, -0.0338768847, 0.000658553152,
        0.0201714616, 0.0251767337, -0.002702686, -0.0387103036, 0.0418466553, -0.0112887146,
        0.0070138257, -0.0513416342, 0.0231144764, -0.00546176173, 0.0475608259, 0.0170995574,
        0.00836718269, 0.00494619738, 0.0140276514, -0.0128783723, -0.00474480493, 0.00531407399,
        -0.00534629682, 0.0268093552, -0.00448165229, 0.0160147231, -0.0158643499, 0.0345857888,
        -0.0210307371, 0.0112564918, -0.0122876214, 0.025885636, -0.0386673398, 0.0160039831,
        0.0159610193, 0.00574639626, -0.00711586466, -0.00952183269, 0.0188932922, -0.0086732991,
        0.00732531259, -0.0218685288, -0.00636937, 0.0236085597, 0.000804898271, 0.050439395,
        -0.0422548093, 0.0115250153, -0.0229426213, -0.0285064224, 0.0146398842, -0.015445454,
        0.00527379569, 0.0177117903, -0.0325450115, 0.00638011098, -0.0281627122, -0.0190544054,
        0.0139309829, 0.0241670869, 0.0205259118, -0.00399294, 0.0166269559, -0.061996635,
        0.0123305852, -0.0145646976, 0.00673456164, -0.00124930416, 0.0137591287, 0.0155421225,
        -0.0198170114, -0.0234152228, 0.00947349798, 0.0136302374, 0.00873774476, 0.000959970406,
        0.0461000614, 0.0154884178, 0.00357672898, 0.0510408878, 0.00183401338, 0.0345213413,
        0.0180877224, -0.00192531128, 0.00670770928, -0.00155743456, 0.00386673398, 0.036304336,
        -0.0397414342, 0.0286138318, 0.0161221325, 0.0157569405, 0.00498916069, -0.00581084192,
        -0.0497949421, 0.0123842899, -0.0149835944, 0.0354665443, 0.0192584824, 0.00123990583,
        0.00241268077, 0.00573028484, -0.000589408388, -0.00165410282, 0.00091163622,
        -0.00151447079, -0.0232004039, -0.0236730054, -0.0164658427, -0.00158160157, -0.00733068306,
        -0.0174432658, -0.0025885636, 0.00709975325, 0.0561106056, -0.0343494862, -0.0214174092,
        0.0203970224, -0.00449776324, 0.0176580846, -0.00037660377, 0.00379960309, 0.0154239722,
        -0.00713197608, 0.0106925936, 0.0222552028, 0.0246396884, -0.0228566937, 0.0153273037,
        -0.0597195588, 0.0184529144, 0.00017454008, -0.000934460724, -0.00437692786, -0.00913515873,
        -0.0317931473, -0.0287212413, 0.0217611194, 0.0262078643, 0.0323731564, -0.0240167137,
        -0.0414814614, 0.0160039831, 0.00376201, 0.00580547145, 0.00366265629, 0.0154776769,
        -0.0132220816, -0.0236730054, -0.00662715221, 0.0166591797, -0.0314924, 0.0215248186,
        -0.0418466553, -0.0194518194, -0.0100051742, 0.00924256817, -0.000668622728, -0.00800199155,
        -0.0113101965, 0.0113316784, 0.00338339224, -0.019709602, -0.0135550508, -0.0190007016,
        0.00172526145, 0.0116753886, 0.000571283104, -0.0391399413, -0.0249619167, 0.00930701382,
        -0.047732681, -0.0219114926, -0.0287427232, -0.0179051273, -0.0326094553, 0.0281627122,
        0.029172359, -0.0585595369, -0.0129213361, -0.0176151209, -0.0192155205, -0.0293442141,
        0.0156710129, -0.00899015646, -0.0167451054, 0.0158643499, 0.0238663405, -0.00805032533,
        0.019516265, -0.0665507913, -0.0367554538, 0.0259930454, -0.0505682863, -0.0448111519,
        -0.0095272027, 0.0108644487, 0.00735216495, 0.0384095572, -0.0169706661, 0.030160524,
        0.03671249, -0.020268131, -0.00684734154, -0.0148117393, 0.0130824503, 0.0153487856,
        0.0117183523, -0.0297738519, 0.0424051806, 0.00189443107, 0.0101448065, 0.0052925921,
        0.0210737, 0.00521203503, -0.00299134827, -0.00903849, 0.0136409784, -0.0115464972,
        -0.0105046276, -0.00509119965, 0.00806643628, -0.00457026483, 0.0139309829, -0.0183884688,
        -0.0158858318, -0.00689567532, -0.0440807678, -0.0124057718, -0.0189684778, 0.014285434,
        -0.00686882343, 0.0464008078, 0.0470023, 0.000769318955, 0.0468304455, 0.017002888,
        -0.00350422761, 0.00598269654, -0.0177225303, -0.0137483878, 0.0262723099, -0.00243819063,
        -0.0147687756, 0.0165088065, 0.0464008078, -0.0358747, -0.0040976638, -0.0260574911,
        0.00548055815, 0.0538335294, 0.0241885688, -0.0232648496, -0.00433664955, 0.0188288465,
        0.0147795165, -0.00763142901, -0.00487906625, 0.0479045361, -0.00781939551, -0.0259500816,
        0.00694938, 0.00628344249, 0.0449400395, -0.00361432205, 0.0155206406, -0.018356245,
        0.0163154695, -0.0294301417, -0.0318575911, -0.0436081663, 0.00257782266, -0.0153058218,
        -0.016422879, 0.0461430252, -0.0253915526, 0.0320079662, 0.0120083569, 0.0180340167,
        0.0201607216, 0.03091239, 0.0017816514, 0.00730920117, 0.0255634077, 0.0202466492,
        0.0259500816, 0.0163369514, 0.0166914016, -0.0102575859, 0.0128676314, -0.00779254315,
        -0.0247470979, -0.0113316784, -0.0242744964, -0.0197310839, -0.0291293953, -0.0180554986,
        0.0257782266, 0.0467015542, 0.00815773476, 0.0022314277, -0.0134154186, -0.0243819058,
        -0.0158965737, -0.0296449605, 0.00731994212, 0.0251982156, 0.0336405858, 0.00566046871,
        -0.0128783723, -0.0517283082, -0.000966683496, -0.0260145273, -0.0263582356, -0.00237911544,
        -0.0129750408, 0.0135443099, 0.0109933391, -0.0304612704, -0.00979572628, -0.00404127408,
        0.0112135289, 0.00473137852, -0.0295160692, -0.00203540572, -0.0199351609, -0.0251122881,
        -0.0105422204, -0.0196236745, -0.0179158673, -0.022620393, 0.0116216838, 0.00953257363,
        -0.00800736155, -0.0180340167, -0.00565509824, -0.0178943854, -0.0111813061, 0.00219920487,
        -0.0169491842, 0.0186355095, -0.0321583375, -0.00216026907, -0.00366534153, 0.0143069159,
        0.00555305975, -0.0406221896, -0.0346717127, -0.0124594765, 0.0243174601, 0.00090492313,
        -0.016594734, 0.0439089127, 0.0272604749, 0.0411377512, 0.0121050254, -0.0136946831,
        0.042125918, -0.0141565427, 0.00425609248, -0.0211918503, 0.0559387505, 0.055852823,
        -0.0247041341, 0.0409873798, -0.0225129835, 0.0170673337, -0.00873237383, -0.0216751918,
        -0.0162188, -0.0223626122, 0.0151876714, 0.0107731503, 0.0270241741, 0.013522828,
        0.0354020968, -0.0115572382, 0.0151447076, 0.0010660371, 0.0191295929, 0.0229211394,
        -0.00338876271, 0.0467015542, -0.0018125315, -0.00516370079, 0.0111490833, 0.0214925967,
        0.000423931, -0.0126528125, -0.00445748493, -0.0293227322, 0.012942818, -0.0240381956,
        -0.0229641031, -0.00302088587, -0.00506971776, -0.0329961292, 0.0194410793, 0.0198384933,
        -0.00960776, -0.0145969205, 0.0160684269, 0.000740452728, 0.0203970224, -0.00529527711,
        0.00699771428, 0.00696549146, -0.0354665443, 0.0204292443, -0.00517444173, 0.00583769428,
        -0.0170780756, 0.0151554486, -0.0211918503, 0.0220618658, 0.000137702053, 0.0302894153,
        -0.024338942, 0.000758578, 0.0553372577, 0.0190436654, 0.00901163835, 0.0455844961,
        0.00365191535, -0.0101018427, 0.0200855341, 0.0373784266, -0.00483610248, 0.0180232767,
        0.0176795665, 0.00242207921, 0.00856589, 0.00498647569, -0.0238448586, 0.0128246676,
        -0.00845311, -0.00339144794, 0.00261675846, 0.0307620168, -0.00662715221, -0.00373247243,
        -0.00584843522, 0.0352302417, -0.0300745983, 0.0075132791, 0.000347401889, -0.0216000061,
        -0.000342367071, -0.0151232257, 0.0119439112, 0.00176554, 0.0331250206, 0.00382108497,
        0.0602351241, -0.0193766337, 0.0161650963, -0.0207085088, 0.0284849405, -0.0174325258,
        -0.019913679, -0.0421044379, -0.0207836945, 0.0164765827, -0.0170243699, -0.00177762355,
        -0.0117505752, 0.00193739485, 0.0345857888, 0.019237, 0.00563898683, -0.0411592349,
        -0.0200533122, 0.0149191488, -0.0284634586, 0.0219437145, 0.0206333213, -0.00526573975,
        -0.0221263114, -0.0172069669, -0.0238878224, 0.0270886198, -0.00742198108, -0.019505525,
        0.00294301426, -0.00371636101, -0.0257567447, -0.00873774476, -0.021256296, -0.0319650024,
        0.0134906052, 0.00706753042, -0.000546444673, 0.0258641541, -0.0419970267, 0.0142639522,
        0.0240596775, 0.0115572382, 0.0265086088, -0.0120835435, 0.0161113907, 0.0207944363,
        -0.00059712847, -0.00612232881, 0.00299403351, 0.0116753886, 0.000786772929, 0.0139524648,
        -0.0180984624, 0.0572706275, 0.014500252, -0.0111705652, 0.0264012, 0.0114176059,
        0.0500527211, 0.0134691233, -0.0227063205, 0.00537583418, -0.0141565427, -0.000652511371,
        0.0362184085, -0.0337050296, 0.020869622, 0.00675067306, -0.0161113907, -0.00238851388,
        -0.0069386391, -0.0264012, 0.018742919, -0.00404664455, -0.00195753411, 0.0102575859,
        0.00541879795, -0.00926942099, 0.0656055883, 0.00719105126, 0.00804495439, -0.0112779737,
        0.0126420716, -0.0116753886, 0.00133523159, 0.0377651, -0.0182488356, 0.00752402,
        0.0183132812, 0.0189684778, 0.00258587836, -0.00188637536, -0.0406007059, -0.0156065682,
        -0.0230715126, 0.00441989163, 0.0220618658, -0.0253271069, 0.0126635535, -0.0319220386,
        0.0376791731, 0.00896330457, 0.0359821059, -0.00203003525, 0.0238878224, -0.0167451054,
        0.0040600705, -0.0096775759, 0.0126098488, 0.00375126908, -0.00367876771, 0.0253915526,
        -0.00490054814, 0.0573135912, -0.0262723099, -0.0194733012, 0.0259286, 0.00839403458,
        -0.00873237383, 0.00765291089, -0.00399025483, 0.0188932922, 0.00891497, -0.00498379,
        0.0102253631, -0.00929090288, 0.00400905125, -0.00210387912, -0.0268308371, -0.0258641541,
        0.0291079134, 0.0174647477, -0.0400206968, -0.00715345796, 0.00825440325, 0.00522009097,
        -0.00792143401, 0.0266160183, -0.03669101, -0.00396608748, 0.0140920971, 0.00691178674,
        0.0365191549, 0.0237159692, 0.00830810796, -0.00910830684, 0.0370562, 0.00107207883,
        0.00889348798, 0.00861422438, 0.00555305975, 0.00021297246, 0.00049844617, -0.0206225812,
        -0.00982257817, 0.0432429761, -0.00728771929, -0.0145217339, -0.015456195, 0.0320294462,
        0.00990313571, -0.0551224425, 0.00421581417, -0.0208911039, -0.0326524191, -0.0142746931,
        0.0024435611, -0.0271315835, -0.00341561507, -0.0102629559, -0.000596457161, 0.00157354586,
        0.02859235, -0.0041325721, 0.00346126384, 0.0081094, 0.0299242251, 0.0173036344,
        -0.0552083664, 0.000545102055, 0.00458637625, -0.00235092058, -0.00245295931, -0.0354020968,
        -0.00886663608, 0.0149728535, -0.00648215, -0.00991924666, 0.0206977669, 0.0167021416,
        0.00235494832, 0.00909219496, -0.00866255816, -0.0387103036, -0.0180554986, 0.00917812251,
        0.00995683949, -0.00448702276, 0.00398219889, 0.0396769866, -0.0242100507, -0.00953794364,
        -0.00530870352, -0.0166162159, 0.00820606854, 0.0286567956, -0.0163154695, 0.0153487856,
        0.000932446797, 0.00906534307, 0.016036205, -0.0438874289, -0.00292690285, -0.0464437716,
        0.00105261093, 0.0174325258, 0.0279908571, -0.00183401338, 0.0343065225, 0.00820606854,
        0.000511872349, -0.00132449064, -0.0314494371, 0.0337909572, -0.000235125626, -0.0158213861,
        -0.000872700359, 0.00925330911, -0.0300316345, 0.0495371595, 0.00976887345, -0.0126957763,
        -0.00201392383, 0.0434363112, -0.0170673337, -0.00564972777, 0.00416210946, 0.0215677824,
        -0.0229211394, 0.000167659164, -0.0195699707, 0.0167665873, -0.00346126384, 0.00881830137,
        0.0103005497, 0.0428777821, -0.0173573382, -0.0186032858, 0.00511536701, -0.0168202929,
        0.00514758937, 0.0136624603, -0.0166162159, -0.0163262095, -0.0122876214, 0.0257137809,
        -0.000144079473, 0.0188073646, -0.00901700836, 0.0347576402, -0.0106442589, 0.0335331745,
        0.0161865782, -0.0592899211, -0.00779254315, -0.00337802176, -0.0142532112, -0.00413794257,
        0.0175184533, 0.00130703661, -0.00538657513, 0.00152655435, -0.0097796144, 0.0380228832,
        -0.0232433677, -0.00399294, -0.0469163731, -0.00513684843, -0.00629418343, 0.00858737156,
        -0.011396124, -0.00704067806, 0.0310627632, -0.0119439112, -0.00744883344, 0.0379154757,
        -0.00452461559, 0.016798811, -0.0447681881, -0.0402569957, -0.0186569914, 0.00666474551,
        0.0221263114, 0.00940368231, 0.0344354138, 0.0201929435, -0.0375502817, -0.0123305852,
        0.0256278533, 0.0308049805, 0.0238448586, -0.00448970776, 0.0332968757, 0.00826514419,
        -0.016594734, 0.000825037481, 0.0208481401, 0.0162510239, -0.0251982156, 0.00220860331,
        -0.000833764498, 0.0230285488, -0.0324590839, -0.0250693243, -0.0212133322, -0.00104388385,
        -0.0190651473, -0.00556917116, -0.0131576359, 0.0218040831, 0.00177762355, 0.021256296,
        0.00212938897, 0.00302088587, 0.0166806597, 0.0103811063, 0.033425767, -0.00896867458,
        -0.0083457008, -0.0130502274, 0.0101394355, 0.0141995065, 0.00228781765, -0.00227439147,
        0.0211166646, -0.00370562, -0.000921034545, 0.0103649953, -0.059676595, 0.00412451616,
        0.0131039321, -0.0261434186, -0.0126313306, 0.0140920971, 0.0281412303, -0.00967220496,
        0.0137591287, -0.0107570393, -0.0159717593, 0.0335761383, 0.0147258118, -0.00977424439,
        0.0322657451, 0.0040976638, 0.00683123, -0.00582158286, 0.00185818051, -0.00644992711,
        0.0157569405, 0.0277330745, -0.00457563531, 0.0128676314, -0.00669159787, 0.0372710191,
        0.0134476414, 0.0254774801, -0.0134369, -0.00186623621, -0.0155958273, -0.00203809096,
        0.0175399352, -0.0200103484, 0.0125454031, -0.00261138799, 0.00599880796, -0.00266375,
        0.0136087555, 0.0133831957, -0.00311755436, 0.00333237275, 0.0066003, -0.00680437777,
        -0.0119224293, 0.0247041341, 0.028399013, 0.0119439112, -0.0110201919, -0.035745807,
        0.0237159692, 0.0271101017, 0.00614381069, -0.0159180555, -0.00633714721, -0.00817384571,
        -0.0242315326, -0.0406221896, 0.00947349798, 0.00531675899, -0.0261434186, -0.0105368504,
        -0.00708364183, 0.00135805609, -0.00940368231, -0.00319274073, -0.0254774801, 0.0178514216,
        -0.0059343623, 0.0054698172, -0.0202359073, 0.0401925519, -0.03209389, -0.0377221368,
        0.00838866457, 0.000329108734, -0.0413525701, 0.0196129344, -0.0496660508, -0.0077656908,
        -0.0235226322, 0.015080262, 0.0476897173, 0.0317716636, -0.0219544563, 0.0243604239,
        -0.0219759382, 0.00259259134, -0.038065847, -0.00079281471, 0.0187751409, -0.0022300852,
        -0.018549582, 0.0300745983, -0.0267449096, -0.00433396408, 0.000451118947, 0.046357844,
        0.0101555474, -0.00578936, -0.0029161619, 0.0186032858, 0.00310949865, 0.014489511,
        0.0180232767, 0.0442096591, 0.0114820516, 0.00930701382, -0.0225344654, -0.00813625287,
        0.00085793162, -0.00353376521, 0.00895256363, 0.010622777, -0.0224485379, 0.0438659489,
        0.0464867353, -0.0103166606, -0.0286567956, 0.0215785243, 0.0000825289244, 0.0238018949,
        0.0000295165719, 0.0359606259, -0.00390969776, -0.0223196484, 0.0134583823, 0.00102441595,
        -0.0219329745, -0.0368843451, -0.0116753886, 0.0242744964, 0.00141377456, -0.0274967756,
        0.00373515766, 0.0246182065,
      ],
      metadata: {
        langs: 30,
        paragraph_id: 1,
        row_id: 1,
        text: 'A time in the 24-hour clock is written in the form hours:minutes (for example, 01:23), or hours:minutes:seconds (01:23:45). Numbers under 10 have a zero in front (called a leading zero); e.g. 09:07. Under the 24-hour clock system, the day begins at midnight, 00:00, and the last minute of the day begins at 23:59 and ends at 24:00, which is identical to 00:00 of the following day. 12:00 can only be mid-day. Midnight is called 24:00 and is used to mean the end of the day and 00:00 is used to mean the beginning of the day. For example, you would say "Tuesday at 24:00" and "Wednesday at 00:00" to mean exactly the same time.',
        title: '24-hour clock',
        url: 'https://simple.wikipedia.org/wiki?curid=9985',
        views: 2450.6254882812,
        wiki_id: 9985,
      },
    },
    {
      id: '2',
      values: [
        -0.0147783281, 0.0209240075, 0.052561108, 0.00973863155, -0.017635949, 0.0206848755,
        0.0429241061, -0.0166315977, 0.0467023849, -0.0064386162, -0.000515253691, -0.0225142334,
        -0.042684976, 0.0111674415, 0.0381175652, 0.0137739759, -0.0448610745, 0.0396240912,
        -0.00881199632, -0.0114424434, -0.0126381, 0.00649242057, -0.0594002679, 0.0385719128,
        -0.039576266, 0.0217848811, -0.00657611666, -0.0164881181, 0.0126859266, 0.0393849611,
        0.0202424824, -0.020505527, -0.0307523143, -0.0257783774, 0.0223109704, 0.0464393422,
        0.0859438702, -0.0195011757, -0.00919460692, -0.0223229267, -0.0647568181, -0.0278588217,
        -0.00958917383, -0.101104803, -0.00915873703, -0.0174685568, -0.0165120307, 0.00774786109,
        0.0236022808, 0.0524654537, -0.0481850021, 0.0171457306, -0.00862667, 0.0634176806,
        0.0533741564, 0.00842340756, -0.0132120168, 0.0282414332, 0.090152584, -0.0348653756,
        0.024092501, 0.00808264501, -0.0304892678, -0.0138337584, 0.0531350225, -0.00210136827,
        -0.000194668, 0.0324979722, -0.00140265585, 0.0657133386, -0.0352718979, 0.0258262046,
        -0.0302979629, -0.00598725537, 0.00248098955, 0.0477306508, -0.0293892641, 0.0164163783,
        0.0470132567, 0.00882993173, -0.0220240131, -0.0212348793, -0.044311069, 0.0307284,
        -0.0124348393, 0.0273805596, -0.0294131767, 0.012901145, -0.0240566302, -0.0412741,
        0.0161892045, -0.0258740298, 0.0363240764, -0.0200153086, -0.00580491778, -0.00807068869,
        -0.0660003, 0.0303936154, 0.0180544294, 0.0123272296, -0.0222153179, 0.0096967835,
        0.00321034063, 0.0136544099, -0.0423023663, -0.0201827, 0.0384045206, -0.00729351118,
        0.00771199167, -0.00224634167, 0.0104440693, 0.00167690974, -0.0364197306, 0.0441915058,
        0.0123630995, 0.00750872958, 0.0342675485, 0.000986417523, 0.047826305, -0.0115500521,
        0.0308001395, -0.0111435289, 0.00110523601, -0.0357979871, 0.0106234178, -0.00215367833,
        -0.0418719277, 0.00944569521, -0.00731742429, 0.0346023291, 0.00373344077, -0.0365632102,
        0.0226696674, -0.0412741, -0.0182576906, -0.0459849909, 0.0223827101, 0.0133435391,
        -0.0341718942, 0.000315728335, 0.0156272445, -0.0203740057, 0.017241383, 0.0103364596,
        -0.0424219333, 0.0289349146, -0.0495480523, 0.0346979834, -0.0228370596, -0.0327610187,
        -0.0330240615, -0.0139174545, 0.0387153924, 0.0047826306, 0.00932015106, 0.0590176582,
        0.0449328125, -0.0457219481, -0.00242867949, 0.0436654165, 0.00897938851, 0.000810058031,
        -0.0363719054, 0.0329045, -0.0184489973, 0.00219552615, -0.0342914611, -0.0412980132,
        -0.0499306619, -0.0205294415, 0.015447896, -0.0160696376, -0.0302023105, -0.0778133944,
        0.0175522529, -0.0125065781, -0.000587366812, 0.0642307252, -0.00399947446, -0.00778970914,
        0.0508871861, -0.024726199, 0.0139413672, -0.0120880986, 0.0364914685, 0.000708800741,
        -0.0595915727, -0.0291262195, -0.0142044127, 0.0455306396, 0.00966689177, -0.0292218719,
        0.0212707482, -0.0157109406, -0.0286957826, 0.00710818451, 0.0017292198, -0.0456741191,
        -0.0116098356, -0.0558132976, -0.0210435744, 0.00880004, 0.0533263274, 0.00648046425,
        -0.0237218458, 0.0324740596, -0.0173489917, -0.0160935521, -0.00289498596, 0.022299014,
        0.00092140364, 0.0599741861, 0.00218506437, -0.0142761515, -0.0467741266, 0.00150951766,
        -0.0415371433, -0.0324262343, 0.00306088338, -0.0117174443, -0.0112212468, 0.0307762269,
        -0.0364675559, 0.00884786621, -0.0567219965, -0.0161772463, 0.0377349555, 0.014335935,
        -0.0214381404, 0.0153163737, -0.00720981508, -0.00999569707, -0.00631307205, -0.0221674927,
        -0.0235066283, -0.00440599816, -0.0015438929, -0.0374479964, -0.0507437065, -0.0136065837,
        -0.0457219481, -0.0197163932, 0.000353092648, 0.0029502851, -0.0277631693, -0.0418001898,
        0.0115620093, -0.0279066488, -0.00451659644, 0.00668970402, -0.00248995703, -0.0335979797,
        0.00434920471, -0.0643742085, -0.000863115303, -0.000351224415, 0.018556606, 0.0341718942,
        0.017360948, -0.0122913597, -0.0190229118, -0.0423023663, -0.0431393273, -0.00468697771,
        -0.0484241322, 0.0270457752, -0.00866851769, -0.0352479853, -0.0110000502, -0.0252762,
        0.00746688154, -0.0275718644, -0.000587366812, 0.0463197753, -0.00747883832, -0.00480953278,
        0.00630111573, -0.00701253163, -0.0129131023, 0.0278588217, -0.0612654947, 0.0147902844,
        0.0457697734, -0.0178870372, -0.00367963617, 0.0313979685, 0.0076821, 0.0522741489,
        0.0551437289, -0.0231479313, 0.0183294304, 0.00488725025, -0.0059095379, 0.014850067,
        -0.0232555401, -0.0412262753, 0.0185326934, -0.0129728848, -0.0555263385, -0.00400545308,
        0.0308479667, -0.02014683, -0.0726959854, -0.0298914406, -0.0658568218, 0.0329284109,
        -0.0104739601, 0.0135587575, 0.019429436, -0.006271224, -0.0180544294, -0.0166315977,
        0.00265286537, -0.000566816423, 0.00778970914, -0.0648524687, -0.0352479853, -0.0117294006,
        -0.029030567, 0.0452675968, 0.0586828738, -0.077096, 0.00692285737, -0.0174446441,
        -0.00305490522, 0.0374001712, 0.0362762511, 0.0255870726, -0.0218087938, -0.0534698069,
        -0.0130565809, -0.023733804, 0.0452675968, 0.000421469304, 0.0148978932, -0.0235544555,
        0.0276914295, -0.00988211, 0.000187662197, -0.00462121656, -0.0323066674, -0.0391936563,
        -0.0463436879, 0.0261131618, -0.0184609536, -0.0136663662, 0.0210794434, 0.00839351676,
        0.0198479164, 0.000874324644, -0.0347936377, -0.0152565911, 0.0122913597, -0.0507915355,
        0.0173848607, 0.011466356, 0.0384762622, 0.000702822465, -0.0135228876, 0.0338131972,
        -0.0129728848, -0.00854297355, 0.00267976755, -0.0438328087, -0.00182636699, -0.0154837659,
        -0.025084896, -0.019752264, 0.0120462505, -0.0556698181, 0.00420273654, -0.00765818683,
        0.0121359248, 0.00926634669, 0.0000829487471, 0.04627195, 0.0159261599, 0.0472523868,
        0.0492132679, 0.0158185493, 0.0189870428, 0.00250789174, 0.0211870521, -0.0059723095,
        0.018951172, 0.0265675113, -0.00222242856, 0.0301783979, -0.0116337482, -0.0705916211,
        0.0425414965, 0.0317566656, 0.0616002791, -0.0109223323, -0.0382610448, -0.0181739964,
        0.0710220635, 0.0797742754, -0.0357501619, -0.00860275608, -0.025443593, 0.00152969442,
        -0.0386197418, -0.0202544387, -0.0013847209, 0.0369219072, -0.00960710924, -0.0535654612,
        -0.0079032965, 0.0405088775, 0.0324262343, 0.00734133786, 0.0460567288, 0.0247022863,
        0.0204696581, 0.0405327938, -0.0127098402, -0.024726199, -0.014694632, -0.0291979592,
        -0.00823210273, 0.00842340756, 0.0198718291, 0.0313501433, 0.0152805038, -0.00868645217,
        -0.0419914946, -0.0387632176, 0.0329284109, -0.00831579883, 0.0135587575, -0.0042954,
        0.03830887, 0.0187239982, -0.0247740261, 0.0223587975, -0.027117515, 0.0574393906,
        -0.0089315623, 0.0217250977, -0.0237935856, 0.0188196506, 0.0188435633, 0.00909297634,
        -0.0523219779, 0.0037244733, 0.00167690974, 0.0442154184, 0.0595437475, 0.0326175392,
        0.0482089147, -0.0078734057, 0.0561959073, 0.0149815893, 0.0124707092, 0.0407480113,
        0.0762351304, 0.0070603583, 0.0264001191, 0.00607095147, 0.0137978885, -0.0428523682,
        0.0389784388, 0.0500741415, -0.0198240038, 0.0355110317, -0.0460567288, 0.0122913597,
        -0.0108685279, 0.0237935856, -0.0134511478, 0.0292696971, 0.0571046062, 0.0426610634,
        0.0204337891, 0.0311588366, 0.0236501079, 0.0155913746, 0.0263283812, 0.0242240224,
        0.0254914202, -0.0530871972, -0.022299014, -0.0173131227, -0.0112391813, -0.00983428396,
        0.0201946571, 0.0211870521, -0.0094815651, 0.0550959036, 0.0187359545, -0.0210316163,
        -0.00762231695, -0.0102826552, -0.000680030265, 0.0252522882, -0.0249414183, -0.00219552615,
        -0.0515089296, 0.00494404417, -0.0196446534, -0.0240446739, 0.0391936563, 0.00652829045,
        -0.0147424582, 0.0404610522, -0.0444784611, -0.0173011646, -0.0260175094, -0.0116815744,
        -0.0260892492, -0.0279305615, 0.0211511832, 0.0339566767, -0.02867187, 0.000698338787,
        0.0330240615, 0.0363719054, -0.0181739964, 0.0108266799, 0.0161892045, -0.00169484469,
        -0.0183413867, 0.0100793932, 0.00532964384, -0.0127217965, -0.037591476, 0.0452436842,
        -0.00767612178, 0.0172174685, -0.00127636443, -0.0296762213, -0.0022911788, -0.0127217965,
        -0.00769405672, -0.00138397363, -0.0150413727, 0.00774188293, 0.01361854, -0.0451719426,
        0.0258022901, -0.0319001451, 0.012303317, -0.0198240038, -0.0179348644, -0.0119984243,
        -0.0445741154, 0.00410409458, -0.00267229462, -0.0253479406, -0.0357023366, 0.0204577018,
        -0.0654263869, 0.0165000744, -0.0117951622, -0.000705437968, -0.00598725537, -0.0092304768,
        0.0305610076, -0.0228251033, -0.0223229267, 0.00197731867, 0.0150772426, 0.0231598876,
        0.003135612, -0.0140370205, 0.00280232239, 0.0335979797, -0.0358458161, -0.0324740596,
        0.0212707482, 0.0111554852, 0.0275001246, -0.0265435986, -0.027834909, 0.024331633,
        0.0115799438, -0.0101391766, 0.0124587519, 0.00764025189, 0.0220240131, 0.0317088403,
        0.0105935261, -0.0273327325, -0.0143478913, 0.019190304, 0.0123152733, -0.0173489917,
        -0.0495958775, 0.00955330394, -0.0105277654, 0.0174087752, 0.00339566753, 0.0185446497,
        -0.0187239982, -0.0350805931, -0.0162729, -0.0131522333, 0.0000563733884, -0.0312066637,
        -0.0304653551, -0.0106473304, 0.000968482636, -0.0233990196, 0.0254914202, -0.0102228727,
        -0.011466356, -0.0138696283, -0.0113766817, -0.0229088, 0.0138457147, 0.0203740057,
        0.00513236038, -0.011585922, -0.0627002865, 0.0085011255, -0.0265675113, -0.0248696785,
        -0.0587307028, 0.0252522882, -0.00854895171, 0.0245827194, 0.0136304963, 0.0079869926,
        0.0182816051, 0.00768807856, -0.037950173, -0.0278588217, -0.00283071934, 0.0106533095,
        0.00633698516, 0.00537149189, -0.0147544146, -0.0112152686, 0.0131761469, 0.0160935521,
        0.00233452138, 0.0170620345, 0.0177316014, -0.0143120214, 0.019429436, -0.0145989796,
        0.00862069149, -0.0139174545, -0.00674948702, 0.0190348681, 0.000748780556, 0.00839351676,
        -0.00415491033, -0.00269022956, 0.0130804945, -0.00604404928, 0.00807666685, -0.0111734206,
        0.00940982532, 0.00206400384, 0.00393371331, 0.00792721, -0.028911, 0.014335935,
        0.0162370298, -0.0293175243, -0.0193576962, -0.0333827585, 0.00626524584, 0.0118609229,
        -0.00173370354, 0.00276944181, -0.0134750614, -0.0113348337, -0.0392175689, 0.0210077036,
        -0.0215098802, 0.0258022901, -0.00771797, -0.0159381162, 0.010910376, -0.0161533337,
        -0.0523219779, -0.0136304963, 0.0313262306, 0.0146587621, -0.00758046936, 0.0337653719,
        0.0191305224, -0.00932015106, 0.0249175038, 0.0019040847, 0.00183981808, -0.0266153384,
        -0.00466605369, -0.0211511832, -0.0112451594, 0.0128294062, 0.0388827845, -0.0453154221,
        0.0277392566, 0.0280262139, 0.0195729155, 0.0143478913, -0.0152565911, 0.00741905533,
        0.00291740452, -0.0115022259, 0.00194593275, 0.0112033114, 0.03830887, 0.0114723351,
        0.0183892138, 0.00945167337, -0.00422067149, -0.00788536202, -0.0294370893, -0.0039934963,
        0.0126141878, -0.0302023105, -0.0187120419, -0.0154000698, 0.0105935261, -0.0129131023,
        0.00409811642, 0.0233272798, -0.0440958515, 0.00134885125, -0.000233340048, -0.0173729043,
        0.0205413979, -0.0137141924, -0.00479159784, 0.00113811658, -0.0116815744, -0.00374240824,
        -0.0166315977, 0.0217250977, -0.00693481416, 0.0329045, -0.0489502214, 0.00394268101,
        -0.00600519031, -0.00266033807, -0.0209957473, 0.0164402928, -0.0354871191, -0.0564350374,
        0.00226726569, 0.0485437, 0.0363719054, -0.0103902649, -0.01532833, 0.00951743405,
        -0.0164881181, 0.0088837361, 0.0106652658, 0.0126739703, -0.0343153737, -0.0360132083,
        0.0277392566, 0.0300110057, -0.0251088087, 0.00638481136, -0.0131761469, -0.00092065637,
        -0.0159978978, 0.00866253953, 0.00153791462, -0.000758868933, -0.0425654091, 0.0177555159,
        -0.00100883609, -0.0497871824, -0.0525132827, -0.0134870177, -0.0330718905, 0.00996580627,
        0.00294879056, -0.04627195, -0.0229805391, 0.00105815695, -0.0434501953, -0.0189870428,
        0.0104560256, -0.00493806601, -0.0122794034, 0.0085011255, 0.0110359192, -0.0681046546,
        0.000530946709, 0.00236740196, -0.0411545336, -0.00725764176, 0.0257066377, 0.0125663616,
        -0.00276495819, -0.02014683, 0.00844134297, -0.031613186, 0.0143120214, -0.0676742196,
        -0.0524654537, 0.00672557391, -0.0148739805, -0.0504567511, 0.00500083808, -0.0150174592,
        -0.00602312526, 0.0182935614, -0.0171098597, 0.0333110206, 0.0168229025, -0.0350566804,
        -0.00344050466, 0.00756851258, 0.0148381107, 0.0275479518, -0.00866851769, 0.00108057552,
        0.0240207613, -0.0248218514, 0.0311827492, 0.00390083296, -0.00576605881, -0.0116935316,
        0.0190468263, -0.0226098858, 0.00729351118, -0.0243077185, -0.0147305019, -0.0240685865,
        0.0141685428, 0.0232196711, 0.00288452394, -0.0208881386, -0.0408436619, -0.017002251,
        -0.0512219705, -0.026519686, -0.0586350486, 0.0311827492, -0.0407241, 0.0338610224,
        0.0167033374, -0.00534458924, 0.0330240615, -0.0176718198, 0.00477067381, -0.0214500967,
        -0.0257066377, -0.0427088886, 0.0171816, -0.0342197195, -0.0177913848, 0.00261550094,
        0.0285044778, -0.0217729248, -0.0147544146, -0.0515567549, 0.0107429838, 0.0385240875,
        0.0298914406, -0.0355349444, -0.0347218961, 0.0103245033, 0.0030040897, 0.0143120214,
        -0.00232555391, 0.0926873758, 0.0103364596, 0.0188794341, 0.0186044313, -0.00631307205,
        0.0499784872, 0.0163565967, -0.0130446246, 0.0120940767, 0.0175761674, 0.00990004465,
        -0.00936797727, -0.0245588068, -0.00513833854, -0.0231120605, -0.0150533291, 0.0367306024,
        -0.0189033467, 0.0180066042, 0.00786144845, 0.0468697771, 0.00208941172, 0.0633698553,
        0.0171935558, -0.00897341, 0.0223229267, 0.0330479741, 0.0299153533, -0.00857286528,
        0.0224066228, -0.0440480262, 0.0308479667, 0.00636687689, -0.0304653551, -0.0254196804,
        -0.0155913746, -0.0110837463, -0.0356066823, -0.0162011608, 0.0108984187, 0.0317805782,
        0.0286240429, -0.0138935409, -0.0101451548, -0.00202813419, -0.0144674573, -0.00477665197,
        0.0155555056, 0.0234827157, 0.0459849909, -0.037950173, 0.021976186, -0.0386197418,
        -0.0224903189, -0.0275957771, 0.0220718384, -0.0150891989, -0.0133315818, 0.0489263088,
        0.0234468449, -0.0139891943, 0.0216772724, 0.0116935316, 0.0320675373, 0.025921857,
        -0.0326414518, 0.0221674927, -0.0314457938, -0.0328566693, -0.0157348532, 0.00250041904,
        -0.027117515, -0.0276914295, -0.0128174499, -0.0109223323, 0.0263762064, -0.0141924554,
        -0.0226816237, 0.0147185447, 0.0215935763, -0.0318523198, -0.00364077743, 0.00180693751,
        -0.0323305801, -0.000606048969, -0.0121717947, 0.0273327325, 0.036037121, -0.0366349481,
        -0.0331197158, 0.00860873424, 0.0264240336, 0.0141446292, -0.0391936563, 0.00144300924,
        0.0234468449, 0.0437132418, 0.0207207464, -0.00345843961, 0.0207327027, -0.0096429782,
        0.0334784128, -0.0249414183, 0.0390262641, 0.0428284556, -0.0253957678, 0.0466784723,
        0.0201707445, 0.0216772724, -0.0269979481, 0.0128772324, -0.00215517287, 0.0220120568,
        -0.00318343844, 0.0141326729, 0.0469415188, 0.00826797262, 0.0502176173, -0.0018233778,
        -0.0154000698, 0.0202783532, 0.0051204036, 0.0288631748, 0.00584676582, 0.0180305168,
        0.00503072934, 0.0161413774, 0.02723708, 0.0358936414, -0.018233778, 0.0116576618,
        0.00352718984, -0.00624133274, 0.0150533291, -0.00459431438, -0.011269073, -0.0480415225,
        0.0176120363, 0.000695349649, -0.0160696376, 0.022179449, 0.0144794136, -0.0269501228,
        0.0377827808, 0.0195609592, -0.0235066283, -0.0257783774, 0.0129370149, 0.0352958106,
        -0.0217490122, 0.00203411258, 0.0163565967, 0.0172892082, -0.0221914053, 0.0197761767,
        -0.00230911374, 0.0180544294, -0.0105038518, -0.0117294006, -0.0195848718, 0.0277631693,
        0.0128413625, 0.0276914295, 0.0173489917, 0.046224121, -0.0241522826, -0.0110478764,
        0.0284805633, 0.0263283812, 0.0228609741, 0.0143239778, 0.00774188293, 0.0151370252,
        0.0027216156, 0.00175014383, -0.026041422, 0.0349849425, -0.00510246865, 0.0120163588,
        0.00884188805, 0.0394567, -0.0098283058, -0.0157228969, -0.0196566116, 0.0169544239,
        -0.0174207315, 0.0153761571, 0.0264718588, -0.0192500874, -0.000757748, 0.0109761367,
        0.0517002344, 0.000952042348, 0.0401740968, -0.021103356, 0.0537089407, -0.0359653793,
        0.0232196711, 0.0000184953278, 0.0320914499, -0.00143478916, -0.0368979946, -0.0466784723,
        -0.011825054, 0.010832658, -0.0266631637, 0.0154120261, 0.0130565809, 0.00936199911,
        0.031613186, 0.0104560256, -0.000595586956, -0.0415610597, -0.0183533449, 0.0109582022,
        -0.00799894892, 0.0181381255, 0.0319479704, 0.00441795494, -0.0141446292, -0.0104560256,
        -0.0161533337, 0.0187239982, -0.00205204729, -0.0205294415, -0.00827992894, -0.0222631451,
        -0.0219642296, -0.00229267334, -0.0154598523, -0.0495002232, 0.00302053, -0.00741905533,
        -0.000112092901, 0.0101750465, -0.0290544797, -0.00618154975, -0.00747286, -0.0124348393,
        -0.00387094147, 0.00659405161, -0.0038858871, -0.000454723544, -0.02158162, 0.0175642092,
        0.0536132865, 0.00639676815, -0.00224634167, 0.00783753581, 0.0090989545, 0.0198837854,
        0.0259935968, 0.0107190702, 0.00847123377, 0.00723970681, 0.0161892045, 0.0189870428,
        0.00616959296, -0.018999, -0.00452257507, -0.0130685372, -0.0119147282, -0.0260175094,
        -0.00334186293, 0.0119745107, -0.0600698367, 0.0185685623, -0.0243555456, -0.00549703557,
        0.0151609387, -0.0154598523, 0.00355409225, -0.00717992382, 0.00601116847, -0.0115321176,
        0.0439523719, 0.0164163783, 0.00451061828, -0.0261849016, 0.0258979443, 0.00397556135,
        0.00364675559, 0.0680568293, -0.0302262232, -0.003404635, -0.00257365289, 0.00846525561,
        -0.0139294108, -0.0070603583, -0.00223886874, 0.0209479202, 0.00193547073, 0.00515328441,
        -0.0077956873, -0.0125783179, 0.0113408118, 0.0059244833, -0.00420572562, 0.0531828515,
        0.0365871228, -0.014969633, 0.0159859415, 0.00729351118, -0.00211930298, -0.0128413625,
        0.00908101909, 0.00312066637, 0.00425056275, 0.0270457752, -0.0216653161, 0.0399588756,
        -0.0247022863, -0.0233511925, -0.000306013622, -0.00166644773, -0.000979692, 0.0197044369,
        -0.00480953278, 0.00432828069, 0.00823808089, -0.00205952022, 0.00751470821, -0.0127696227,
        0.00404132251, -0.0171935558, -0.0127457101, -0.00730546797, -0.00395164825, -0.00886580069,
        -0.0399110503, 0.0272131674, 0.0370653868, -0.010988093, -0.0335979797, 0.0168946423,
        -0.0199913941, 0.00274253963, -0.000144973485, 0.00371251674, 0.0131881032, 0.0307762269,
        -0.0176479053, 0.0106413523, 0.0366588607, -0.0124826655, 0.0227892343, 0.011866902,
        -0.00278139859, -0.0158424638, -0.0236022808, -0.0120761413, 0.013259843, 0.0597828813,
        -0.0300349183, -0.0247501116, -0.0103304815, 0.0320675373, -0.00997178443, -0.0573915653,
        0.00465708645, -0.00591551606, -0.0539958961, -0.0340284146, -0.0143478913, 0.00839351676,
        0.0143120214, -0.0337653719, -0.0274283849, -0.0136663662, 0.0303697027, -0.00245109806,
        0.0000815942913, 0.0147783281, 0.010192981, 0.00037495076, -0.053756766, -0.00602611434,
        0.0117533142, -0.00973265246, -0.0032193081, -0.0345784165, 0.00908699818, 0.0357740745,
        -0.0269979481, -0.00185326929, 0.0297240485, 0.000836213, -0.00860873424, -0.00658807345,
        -0.0198718291, -0.000756627065, -0.0111435289, 0.00775383972, -0.00718590198, 0.00446877,
        0.00826797262, 0.0311827492, -0.0197283495, 0.0175761674, -0.00361686433, -0.0160218123,
        -0.00261101732, 0.0170739908, 0.00536252419, 0.0140489768, 0.0314457938, 0.034004502,
        -0.000216152475, -0.0535654612, -0.0214620531, -0.0408675782, 0.0294131767, 0.0138696283,
        0.0280979536, -0.000405029015, 0.0252762, 0.00915275887, -0.00795112271, 0.00866253953,
        -0.00234199432, 0.0313979685, -0.00380816939, -0.00445681391, 0.0141565856, -0.00970276166,
        -0.0236979332, 0.0416806228, 0.00499485945, -0.00661796471, 0.00593644, 0.00509051234,
        -0.0277631693, -0.00860275608, 0.024845764, 0.00680329185, -0.03520016, 0.0060320925,
        -0.0224424936, 0.0131881032, -0.00592149422, 0.0291501321, 0.0064625293, 0.0511263199,
        -0.014969633, -0.0112511376, 0.00164104009, -0.0234468449, -0.00712611945, -0.00369757111,
        -0.0161174648, -0.0142881079, -0.0248218514, 0.0242718495, -0.00950547773, 0.0176837761,
        -0.0118011404, 0.0281457789, -0.00397855043, 0.0245588068, 0.0206968337, -0.0731264204,
        -0.0101212412, 0.0116995098, -0.0239011962, -0.00492013106, 0.0384762622, 0.00119864673,
        -0.0142402817, 0.0394806154, -0.0184609536, 0.0483045653, -0.0157109406, 0.00994787086,
        -0.0428045429, -0.0151968077, -0.0179826897, 0.00198778068, 0.00846525561, 0.0090989545,
        0.00215218356, -0.0154957222, -0.0241642408, 0.0479697846, 0.00284118135, -0.00121433975,
        -0.0166315977, -0.0372088626, -0.00973863155, 0.0258740298, 0.0107728746, 0.0194413923,
        0.00282175187, 0.0101630893, -0.0032193081, 0.00892558414, 0.0238055419, 0.0506958812,
        0.0256349, -0.0218805335, 0.0386675671, 0.00402637711, 0.0000235978914, -0.0224424936,
        0.0202903096, 0.0271892529, -0.0183653012, 0.00379322376, 0.00419077976, 0.0316849276,
        -0.0341479816, -0.0022299015, -0.0131881032, -0.0097745005, -0.00540736131, 0.0125783179,
        0.0186044313, 0.0186163895, 0.00953536946, 0.0368501656, 0.0165359452, -0.00128607918,
        0.0166674666, -0.0064625293, 0.0369936451, -0.0052280128, -0.0431393273, -0.020350093,
        0.00415789941, 0.0210316163, 0.0133076692, -0.00990004465, 0.0186761711, 0.0132000595,
        -0.00102976011, -0.00428344356, -0.04029366, 0.028074041, 0.00621741964, -0.0139533244,
        0.000454723544, 0.0382849574, 0.0141685428, 0.00362284249, -0.00437311782, -0.011825054,
        -0.0167511627, -0.00325517775, -0.000335344579, 0.00724568497, 0.0161772463, 0.013140277,
        -0.0124348393, 0.00832177699, 0.0186283458, -0.0209598783, 0.0272609927, -0.00946960784,
        0.0111674415, 0.00826199353, 0.0213305317, 0.0267349035, 0.0459849909, -0.00557774259,
        -0.00402936619, -0.0258262046, -0.0143598476, -0.0163087696, -0.026160989, -0.02412837,
        0.000148990148, -0.0146348486, 0.0151968077, -0.0241403263, 0.0264001191, 0.0187718235,
        -0.00284417043, -0.00197432959, -0.0185685623, 0.00629513711, -0.0173011646, -0.00902721472,
        0.0105696134, -0.0159859415, -0.00765220867, -0.0250131562, 0.0199316125, 0.0168946423,
        -0.0161772463, -0.0221674927, -0.00682122679, 0.00000109757627, 0.0124348393, -0.0137859322,
        0.0106891785, -0.0283609983, -0.0298197, -0.0102647208, -0.0122196209, 0.00847123377,
        0.01278158, 0.0280979536, 0.00134885125, 0.0187120419, 0.000881797459, 0.00876417,
        -0.00955330394, 0.0508393608, -0.0295327418, -0.00621144101, 0.0110179847, 0.0282414332,
        -0.0443828106, 0.00301604625, -0.0354871191, -0.00801090617, -0.0327610187, 0.018831607,
        0.0312305763, 0.0154239833, 0.0152685475, 0.00212976499, -0.0350327678, -0.0338849351,
        -0.0266153384, 0.00165299664, -0.012106033, -0.000956526084, -0.0339327641, -0.00363181,
        -0.0281218663, 0.00569133, 0.0252283756, 0.0565306917, -0.0152207213, -0.0211870521,
        0.00662394287, 0.00408914918, -0.00230164081, 0.0137022361, -0.0194055233, 0.0439762846,
        -0.0109641803, 0.0239848923, -0.0189392157, -0.00776579604, 0.00577502605, 0.00448670518,
        0.0087283, 0.00808264501, 0.00815438479, 0.00238982053, 0.0205772668, 0.00517719751,
        -0.0185087789, 0.00513236038, 0.00334186293, 0.0466545597, -0.00830384158, 0.0382849574,
        -0.0330479741, -0.0318284035, 0.0369697325, 0.0149457203, -0.0216414016, -0.0326175392,
        -0.0159978978, 0.00768807856, 0.005733178, -0.0141446292, -0.00113438012, 0.0301066581,
      ],
      metadata: {
        langs: 30,
        paragraph_id: 2,
        row_id: 2,
        text: 'However, the US military prefers not to say 24:00 - they do not like to have two names for the same thing, so they always say "23:59", which is one minute before midnight.',
        title: '24-hour clock',
        url: 'https://simple.wikipedia.org/wiki?curid=9985',
        views: 2450.6254882812,
        wiki_id: 9985,
      },
    },
    {
      id: '3',
      values: [
        -0.0191982649, 0.0238196421, 0.0352236405, 0.0550886728, 0.00628829375, -0.0317288674,
        -0.015531051, 0.010139442, 0.024417432, -0.00299182534, 0.00507834321, -0.00119414355,
        0.00511857914, 0.0167726148, 0.0532033332, 0.0306712389, -0.0699874461, 0.0670904592,
        0.0129329627, -0.01108786, -0.013415793, -0.0094266925, -0.0353156105, 0.00942094438,
        -0.04375365, 0.0191062968, 0.00256791152, 0.029337706, 0.0110533722, -0.0117948614,
        -0.00130335521, -0.041500438, -0.0340050682, 0.0107947122, 0.0254750624, 0.00463000033,
        0.0190948, 0.00336831808, -0.0201754216, -0.0405807607, -0.0752755925, -0.0481451079,
        0.0129674505, -0.0483750254, -0.0479611717, 0.00153902255, -0.0585834458, 0.0358214304,
        0.0254520699, 0.0568820424, -0.0258199405, 0.00778851751, 0.00446618302, 0.0103233783,
        0.0530653819, 0.00733442651, -0.00175169786, 0.0400289558, 0.0654350445, -0.0457769372,
        0.0195546392, -0.00579109322, 0.00277771289, -0.0222791824, 0.0240265708, 0.0112028196,
        -0.00947842468, 0.0257279724, 0.0046472447, 0.0151171964, -0.00339993206, 0.0254290774,
        0.014622869, 0.0119673014, 0.0166231673, 0.0339360945, -0.0596410744, 0.0202558935,
        0.0390403, 0.0603308305, 0.00420465, 0.0260958448, -0.0714589283, 0.0658029094,
        -0.00376492902, 0.0438686088, 0.0054059783, 0.0161518324, -0.0166001748, -0.0642394647,
        0.00509846117, 0.0018767165, 0.051639881, -0.0189453531, -0.000980031095, 0.0103521179,
        -0.0742179602, -0.00479669217, 0.0148297967, 0.0598709919, -0.014323974, 0.0160138812,
        0.00444319099, -0.0103693614, -0.0144389337, -0.00813339651, 0.0313839875, -0.0201179422,
        -0.0163817517, 0.00831158366, -0.05021438, -0.0160253774, -0.0400289558, 0.032786496,
        0.0394311659, -0.0083863074, 0.0507202037, -0.0733902529, 0.0499384776, -0.00213537575,
        0.0395921096, -0.02217572, 0.0164162405, -0.0148068052, 0.00130622915, -0.012381156,
        -0.0638256073, -0.0105877854, 0.00379654299, 0.0256130137, -0.0135767367, -0.0498924963,
        -0.00454090675, -0.0389483348, -0.0284410212, -0.01242714, 0.0141170472, 0.0260498598,
        -0.0446043499, 0.0152206598, 0.0377297625, 0.0355915129, 0.0283260606, 0.0467426,
        -0.0572958961, 0.0294526666, -0.0492487215, 0.0341430195, -0.00339418417, -0.0164277367,
        -0.0320967399, -0.0109843956, -0.0222102068, -0.00678262, 0.0330624, 0.0405117869,
        0.0354535617, 0.00178618578, -0.0349247456, 0.0293147154, 0.00556404795, -0.00455240253,
        -0.0357294641, 0.00529676676, -0.015531051, -0.0311080851, -0.0595491081, -0.048880849,
        -0.0163587611, -0.0118063577, 0.0115419505, -0.0344189256, -0.0254060868, -0.0396840759,
        -0.0123351719, -0.000805436168, 0.00559853576, 0.0407187119, -0.0544448979, 0.0107774688,
        0.0263717473, -0.0434547551, -0.00953015592, -0.0188648812, 0.0545828491, -0.0280731507,
        -0.0209456515, -0.016220808, -0.00976582337, 0.0311770607, 0.00308091892, -0.00638600951,
        0.0466046482, 0.0260268673, -0.0169335585, 0.00479381811, 0.0266706422, -0.0171749741,
        -0.0219917838, -0.0491107702, 0.0072999387, 0.00264407229, 0.00406669825, -0.0563302375,
        -0.00218710769, 0.0299814809, -0.0293147154, 0.0136112245, 0.00854150299, 0.0128754824,
        -0.0270844977, 0.0256130137, -0.0106969969, -0.0368100852, -0.0599629618, 0.0274523683,
        0.00519042881, -0.0476852693, 0.00714474311, -0.0279352, -0.00377929909, 0.0224976074,
        -0.00398335233, 0.0015648884, -0.0577557348, -0.0166116711, -0.00850701518, 0.0151976673,
        -0.0216009207, -0.0369250439, 0.0238886178, -0.0180601627, 0.0130824102, 0.000301589491,
        0.00978881586, 0.00228194939, -0.0015648884, -0.0276592951, -0.0550886728, -0.00758158974,
        -0.0147263333, 0.00936346501, -0.00225177244, 0.0212100577, -0.0416613817, -0.000367870933,
        -0.00448917504, -0.0514559448, -0.000189503829, 0.00639175717, -0.010484321, -0.0529274307,
        -0.0119673014, -0.041293513, -0.0114672268, 0.0287169237, 0.0259578917, -0.0419372842,
        0.012082261, -0.0150827076, -0.00482543185, -0.0670904592, -0.0376148038, 0.000955602212,
        -0.03770677, 0.00176894187, -0.0173819, -0.00542034814, -0.00880591, -0.0435927063,
        0.0330164172, -0.0211065952, 0.00460413471, -0.00515019288, -0.0306482464, -0.0472714156,
        -0.00288836169, -0.0438226238, -0.00875417888, 0.0475013331, -0.0452711172, 0.0167956073,
        0.0317978449, -0.00359536358, -0.00283231866, 0.0131743774, 0.0465356708, 0.0498924963,
        0.0458689071, 0.00767930551, 0.054352928, 0.0231298842, 0.0312690288, -0.0309701338,
        -0.0158299468, -0.0193132237, 0.013760672, -0.00426500337, -0.0102141667, -0.0337291658,
        0.0375688188, -0.0112545509, -0.0470644869, -0.00777127314, -0.083138831, 0.0545368642,
        -0.0611125566, 0.0168760791, 0.03770677, -0.0373388976, -0.0306942314, -0.0193477124,
        0.0762412474, -0.00881165825, -0.00502373744, -0.0528814457, -0.0185659863, -0.00490015559,
        -0.0172899328, -0.00862197485, 0.0321427211, -0.0824950561, -0.00510133523, -0.00853000674,
        0.0060526263, 0.0394541584, 0.019715583, 0.073206313, -0.0552726053, -0.0408796556,
        -0.0169335585, -0.022394143, 0.0318898112, 0.0248312876, 0.0249692388, -0.0383965299,
        0.0491567515, 0.010484321, -0.015312627, -0.0407646969, -0.010311882, -0.0368560702,
        -0.0210721064, 0.048972819, 0.00485129794, -0.00344591588, 0.0060871141, -0.00632278156,
        0.0448802523, -0.00580546306, -0.068240054, -0.0313380063, -0.00653545698, -0.0103751095,
        0.0151057, 0.016910566, 0.0431788489, 0.0135422489, -0.0471794456, 0.0265786741,
        0.000150076245, -0.0291767623, -0.00150022365, -0.0125650913, 0.0146458615, -0.014323974,
        -0.0392012447, -0.00845528301, -0.00852426, -0.0431328677, 0.0104785739, -0.0515019298,
        0.000899559353, -0.0069205719, -0.0150827076, 0.0138756316, -0.00853575487, 0.0257509649,
        0.0306252539, 0.011605178, 0.0074378904, 0.0347638, 0.0162323043, -0.0499384776,
        0.037660785, 0.0696195737, 0.0192097593, 0.0316139087, -0.00962212402, -0.0732523,
        0.0167381279, 0.0375458263, 0.0444204137, 0.0110763637, -0.0354305692, -0.0471794456,
        0.0802418441, 0.0685159639, -0.035292618, -0.0231758691, -0.0246703438, -0.0167151354,
        -0.0118753333, 0.0104785739, 0.0234172847, 0.0519617684, -0.0116396658, -0.0423511416,
        -0.00383390486, 0.0246243607, 0.0290158186, -0.0295216423, 0.0316369, 0.0381666087,
        0.0361203253, 0.0178992208, -0.0131743774, 0.00828284398, -0.0271304809, -0.0174393822,
        -0.00661018072, -0.00261964346, -0.00978306774, -0.0100877108, 0.0212100577, 0.0205203,
        -0.0335452296, -0.0434317626, -0.0127145387, -0.0202673897, 0.0211640745, -0.00858173892,
        0.0301884077, 0.0163817517, -0.00573648745, 0.0258889161, 0.0179222114, 0.0153241232,
        -0.0165197048, 0.0672744, -0.0074551343, 0.0370629951, -0.00404945435, 0.0490188,
        -0.0255670287, 0.0294066817, -0.042581059, 0.0547667854, 0.00416728808, 0.0127375312,
        0.0620782189, 0.007851745, 0.0153701073, 0.0128409946, -0.0149562526, 0.0397530533,
        0.0538011231, 0.00654695276, -0.0211755708, -0.0222791824, -0.00348615181, -0.00604687817,
        -0.0016036873, 0.000565098599, 0.0117833661, 0.0304183271, -0.0450641885, 0.0141860228,
        0.00950141624, 0.0318208337, -0.00227189041, 0.0606987029, 0.0574798323, 0.0256130137,
        0.0193132237, 0.0473633818, 0.0245553851, 0.0230953973, -0.0133238249, -0.0255210456,
        0.0380746424, -0.0260038767, -0.0381436162, -0.0291997548, 0.0240955465, -0.00304930517,
        0.0152896354, 0.0225895736, 0.00917378161, 0.00771379331, -0.0178992208, -0.0150252283,
        0.0281881094, -0.00886913855, 0.00600664271, 0.0366031565, -0.0168990716, 0.0177037884,
        0.0180601627, -0.00655270088, -0.0253371093, -0.0106280213, 0.000455886911, -0.0298665203,
        0.0113120312, 0.0499384776, -0.0255900212, -0.0374078751, -0.0256360061, -0.0733902529,
        -0.0294296741, -0.00284237764, 0.019715583, 0.0129789459, -0.0500764288, -0.0225435905,
        0.0419602767, 0.0152436513, -0.0437306575, -0.0143584618, 0.00217704871, 0.0296595935,
        -0.0112660471, -0.0155885303, -0.0220722556, 0.0187384263, -0.0254750624, -0.023773659,
        -0.0110246316, -0.0235437397, -0.0116339177, -0.0137261841, -0.00128754822, -0.0291767623,
        -0.013415793, -0.0142320069, -0.0127490265, -0.0065412051, -0.0225550868, -0.050260365,
        0.0218998156, 0.0216584, 0.0213939939, -0.0206467565, -0.0117488774, -0.0172899328,
        -0.0184510257, 0.00181636272, 0.00499499729, -0.0194051918, -0.00770229753, -0.0390632935,
        -0.0343039632, 0.00949566811, -0.00871394295, 0.0295446347, 0.0116396658, 0.00725395465,
        0.0235897228, -0.030073449, 0.0113522671, 0.0140480706, -0.00672514038, 0.00678836834,
        0.00379079487, -0.00321887061, -0.0302803759, 0.041592408, -0.0183130745, -0.0291307792,
        0.0217158813, 0.0187959056, -0.0134272892, -0.0220837519, -0.00767355785, 0.0047737,
        -0.00164967123, -0.0297975447, -0.0000115520988, 0.023256341, 0.010524557, 0.0238426346,
        0.0155655388, 0.00994401146, -0.0271994565, 0.0159219131, 0.0239116102, -0.0215779301,
        -0.0570659786, -0.000607489957, 0.0301424246, 0.0159908887, 0.00207789592, 0.000435409718,
        0.00117905508, 0.00536286831, -0.0246473514, -0.0236357078, 0.0002701552, 0.00649522105,
        -0.0221182387, 0.000891655916, -0.017128991, 0.0174508765, 0.0239116102, -0.0102371583,
        -0.0176233165, -0.0147033408, -0.0122891879, -0.0217733607, 0.00659868494, -0.00643774122,
        0.00509846117, 0.0131858736, -0.0275443364, 0.0517778322, -0.010053223, -0.00931748096,
        -0.0745398477, 0.0200144779, 0.00384540088, 0.042581059, 0.0476392843, -0.0284410212,
        -0.0144849177, 0.0171059985, -0.028257085, 0.0216813926, -0.0295906179, 0.010524557,
        0.0196236148, -0.0145194056, -0.00298032933, -0.00588306086, -0.0145883812, 0.00807591621,
        0.0129099702, 0.021485962, 0.0257049818, -0.00404083217, 0.0188648812, -0.00257078558,
        0.0191522799, -0.0126340669, 0.00560141, 0.00358386757, -0.020796204, -0.0166806467,
        0.0301884077, 0.0319817774, 0.0298205372, 0.00297170738, 0.0344419144, -0.0260498598,
        -0.0113867549, -0.0260498598, -0.0288548768, -0.0109441597, 0.00593766663, -0.00200604624,
        -0.000249318779, -0.0304183271, 0.00575373136, -0.030073449, 0.00693781581, 0.0102486545,
        0.00909905788, 0.0364652053, 0.0136802, -0.00397760421, -0.0480531417, 0.0373618901,
        -0.00454090675, 0.0115994299, -0.00493751746, -0.00267137517, 0.0269005615, -0.000935484248,
        -0.0299354959, -0.00352638774, -0.00339131, 0.0120477732, -0.0178302433, 0.0305102952,
        0.0252221506, 0.0249462482, 0.0391092785, 0.0256130137, -0.0065412051, -0.0198305417,
        0.00855299924, 0.0016036873, 0.0108464444, 0.0343039632, -0.00620782189, -0.00502948556,
        0.0710450709, 0.0249232557, -0.0103176301, -0.0263257641, -0.0375458263, -0.0288318843,
        0.00184653967, -0.034166012, 0.0191062968, 0.0235897228, 0.00947267655, -0.0030176912,
        -0.00211669481, 0.021612417, -0.0119902929, -0.0108521925, -0.0263717473, -0.0000948417219,
        -0.0168760791, -0.0371089801, -0.0229114611, -0.00944968406, -0.00328497239, 0.0117546255,
        -0.00718497904, 0.0377297625, 0.00666766055, -0.00892087, -0.0113522671, 0.00345741189,
        0.0424431078, 0.0152206598, -0.0465356708, -0.0102544017, 0.00590317883, 0.0174623728,
        0.0256589968, -0.0113177793, 0.00908181351, 0.036327254, -0.0793681517, -0.0113177793,
        0.00415004371, -0.0349707305, -0.0190833043, 0.0141055509, -0.0607446879, -0.0186924413,
        0.00126814877, 0.046512682, 0.014323974, -0.0223251674, 0.0019155154, -0.000778133224,
        -0.0184740182, 0.00765056582, -0.00921401754, 0.0300504565, -0.040419817, -0.0476392843,
        -0.00950141624, 0.0336372, -0.0352236405, -0.00377355097, 0.00201754202, -0.0182555951,
        0.0165426955, 0.0078172572, 0.0201179422, -0.00740340259, -0.0368100852, 0.00167984806,
        -0.0053111366, -0.027613312, -0.0272684321, -0.0251761675, 0.00639750529, 0.0190143287,
        -0.0000260904544, -0.00668490445, -0.0528354608, -0.00404083217, -0.0374308676,
        0.00565601559, 0.0200144779, 0.00221153651, -0.0371779576, 0.00901283789, 0.0111051034,
        -0.035338603, -0.0114269909, 0.0177152846, 0.0114499824, 0.0104498332, 0.0297745541,
        0.0080989087, -0.0234632678, 0.00843804, 0.0407646969, -0.00260383636, 0.0348787643,
        -0.0540770255, -0.0665846393, 0.0261418279, -0.0211640745, -0.0208881721, -0.0136572085,
        -0.0223021749, 0.0112775434, 0.025153175, -0.0107832169, 0.046719607, 0.00721371919,
        -0.0245783757, 0.00433685351, 0.00640900154, 0.0109326644, 0.0358904079, -0.00406669825,
        -0.0243714489, 0.0439835675, -0.0233942922, -0.004854172, 0.00801268872, 0.0127375312,
        0.00811615214, 0.0108119566, -0.031452965, 0.0478692055, -0.0166806467, 0.00204771897,
        -0.0406267457, 0.029383691, 0.0113810068, -0.0124961156, -0.0116856499, 0.00382815674,
        0.000290093536, -0.0224861111, -0.0371549651, -0.0304183271, 0.0174278859, -0.00947267655,
        0.0434547551, 0.0148068052, 0.0284640137, 0.0382815674, 0.0053887344, 0.0135192564,
        -0.0144044459, -0.00197586929, -0.0334072784, -0.00877717, -0.0056905034, -0.0190143287,
        -0.0121512366, 0.0395001397, -0.00971983932, -0.00763906958, -0.0465356708, 0.0054059783,
        0.0214514732, 0.0343039632, -0.0340510532, -0.0258659255, 0.0398910046, 0.0124616278,
        -0.0101221986, 0.00902433414, 0.0499844626, 0.02510719, -0.00248887669, 0.00905307382,
        0.0230494123, 0.0455700122, 0.0205662847, 0.0047593303, -0.0211640745, 0.00164104917,
        0.0222561918, -0.0155195547, -0.017083006, -0.0120017892, -0.0406957231, -0.0142090144,
        0.0249232557, -0.0125076119, 0.047547318, 0.00457539456, 0.00579109322, 0.00750686601,
        0.0221182387, 0.0259119086, 0.0297975447, -0.00263545034, 0.0191292875, 0.0239116102,
        0.0208191946, 0.0243024733, -0.023727674, -0.00789772905, -0.0133698089, -0.0470874794,
        0.00450354489, -0.0203708522, 0.0129329627, -0.0353845842, -0.00517031085, 0.00939795282,
        0.0507661887, 0.0142090144, -0.00439145928, -0.0117546255, -0.000852138503, -0.00912779756,
        0.0055956617, 0.0017430759, 0.0381206237, 0.0343729407, -0.0336831808, 0.0221412312,
        -0.0356604867, -0.000668562308, -0.0025607266, -0.0293147154, -0.00371607114, -0.010444086,
        -0.000980031095, 0.00667340867, -0.0209456515, -0.00266131619, 0.0357984416, 0.0361893028,
        0.013461777, -0.0396380909, 0.00550082, -0.00462425267, -0.0300504565, -0.00620207377,
        -0.0277052801, -0.0298665203, -0.0425350778, 0.0282800775, -0.0244864095, -0.0187844094,
        0.00214830879, -0.0245553851, 0.00182354765, 0.00903582945, -0.00130407372, 0.00623081392,
        0.0312460363, -0.0145768858, -0.00839780364, 0.0222217031, -0.00772528956, 0.0284410212,
        -0.0090473257, -0.025842933, 0.00427075149, 0.0375918113, -0.0240265708, -0.0299354959,
        0.0349017531, 0.0252911262, 0.0363502465, 0.00581983291, -0.0280501582, 0.0295446347,
        0.0208766758, 0.0243254658, -0.0334302709, 0.0203823484, 0.0054059783, -0.00830008741,
        0.0531573482, 0.0129329627, 0.0459378809, -0.00497487932, 0.00591467461, -0.0125995791,
        -0.00424488587, 0.0179796908, 0.0359363928, 0.0484669954, 0.0115074627, 0.0398910046,
        0.00653545698, -0.00336544425, 0.0026167694, 0.00439433334, -0.0100647183, -0.00634577358,
        0.00844953582, -0.00742064649, -0.0186924413, 0.00684584817, -0.00738041056, 0.00163673819,
        0.0188648812, -0.00869095, -0.0428339727, -0.0143814543, 0.00894386228, -0.0289238524,
        -0.0150367245, 0.0249692388, -0.00777127314, -0.0102888905, 0.0155425463, 0.0406267457,
        -0.00248743966, 0.0611125566, 0.0167151354, 0.0105073135, -0.0153241232, -0.00993251521,
        -0.010610777, -0.0176807959, -0.00793796498, 0.0219572969, 0.0184970107, 0.00539160846,
        0.00189108646, -0.00528239645, 0.0217618644, 0.0143814543, 0.0233138204, -0.0259349011,
        0.0109326644, 0.0294756573, -0.0215434413, 0.00391437672, 0.0575718, -0.00326485443,
        0.0104153454, -0.0023164372, 0.0340740457, -0.0235667322, 0.0211065952, 0.0110993553,
        -0.018163627, -0.0196581036, 0.00104182202, 0.016784111, 0.0060526263, -0.0135537442,
        0.00820237212, -0.0257509649, 0.0555025265, -0.00795520842, 0.0133238249, -0.0235667322,
        0.0507661887, -0.010610777, 0.0369020514, -0.000631918898, -0.0122317085, 0.00972558744,
        -0.00556404795, 0.024417432, 0.000419602788, 0.0397300608, 0.0241645221, 0.072746478,
        0.0155655388, 0.0622621551, 0.00266275322, 0.00288117654, 0.0118753333, -0.00686884,
        -0.0443284474, -0.00628254563, 0.0186579544, -0.0238886178, 0.023773659, 0.00328784646,
        -0.0143699581, 0.0256589968, -0.0108464444, 0.00723671075, -0.0195201505, 0.000891655916,
        -0.000560428365, -0.0287399162, 0.0320277624, 0.0172899328, -0.00850126706, -0.0279352,
        -0.0319587886, -0.0262567867, 0.00615034206, -0.00875992607, -0.029383691, -0.0251301825,
        -0.0363502465, -0.0345798656, -0.0111510875, -0.0169335585, -0.0268085934, 0.0327175222,
        0.0402588733, 0.0113752587, 0.0194396786, 0.00576522714, 0.0328094885, -0.045087181,
        -0.0137261841, 0.00102960749, -0.0247163288, -0.00959338434, 0.00505822524, 0.00852426,
        0.0221987106, 0.0215434413, -0.0193362162, -0.00234517711, 0.0103923539, -0.00186953158,
        0.0337981433, 0.0266016666, 0.0187729131, 0.00816788431, -0.00656419666, 0.0247853044,
        0.0189683456, -0.00401784, 0.000381881633, -0.0107602244, -0.00858173892, -0.00784599688,
        0.00352063985, 0.0198995173, -0.0261418279, -0.0208881721, -0.0150482198, -0.0284410212,
        0.00783450156, 0.00386264478, -0.00323611451, 0.0301424246, 0.0000391581343, 0.00344304205,
        -0.0101451902, 0.0590432845, -0.00944968406, -0.013116898, -0.00930598471, -0.000765200297,
        -0.0123236757, -0.00366721326, 0.0536631718, -0.0164507274, 0.00788048469, 0.0202099085,
        0.00768505363, -0.00490877777, -0.0195316467, 0.0106912488, 0.019934006, -0.0126110753,
        0.00173014298, -0.00211238395, -0.0181291401, -0.0181751233, -0.0274063833, -0.00433685351,
        0.0381896, 0.0119558051, 0.0155080585, 0.0217043851, -0.00558416592, -0.00840355176,
        0.00601813849, 0.0250382144, 0.004098312, 0.00513007492, 0.017128991, -0.00725395465,
        0.0476392843, -0.00049109332, -0.00989227928, 0.0250842, 0.0100704664, -0.00847252738,
        0.00177181582, -0.00753560616, -0.0101336949, 0.0185314976, -0.0241415296, 0.0149102686,
        -0.0418453179, 0.00206927396, -0.0197730623, -0.0183705539, -0.0493406877, 0.0262797792,
        0.0264637154, -0.0490188, -0.00529964035, 0.014197519, -0.0109671522, -0.00268718228,
        -0.00141687784, -0.0156460106, -0.0315449312, -0.00454090675, -0.0161978174, 0.0180256758,
        0.0224286299, 0.0193017274, -0.0129214665, 0.0505362675, -0.0202788841, 0.00894386228,
        0.0122891879, -0.0044776788, -0.0129329627, -0.014496414, -0.0144504299, -0.0304183271,
        0.00956464373, -0.000308594841, 0.00590892695, 0.0104325898, 0.036672134, 0.020922659,
        -0.0490647852, -0.00437134132, -0.00839780364, -0.0130134337, -0.0286479481, 0.0100359786,
        0.00412417809, 0.0145883812, -0.00315851672, -0.0370170139, -0.0281421263, 0.0230724048,
        -0.00920252129, 0.016266793, 0.0191522799, 0.0288548768, 0.00142909237, -0.025544038,
        0.0117948614, 0.0035465057, -0.00855299924, 0.00108924287, -0.035338603, -0.0179796908,
        0.0150827076, 0.000595634745, -0.00114456716, 0.0360973366, -0.00359536358, -0.00426213,
        0.000258838874, 0.00186522061, -0.0139101194, -0.0157724656, 0.00708726328, -0.0170025341,
        0.000737897353, 0.00211382075, 0.00844953582, -0.0253830943, 0.0220147762, 0.00465299236,
        -0.0243024733, 0.0146573577, 0.0062423097, -0.0224631187, 0.0204513241, 0.0317978449,
        0.0180946514, 0.03140698, -0.0484669954, -0.0137836635, -0.00729419058, -0.0207732115,
        -0.00511283102, 0.0062595536, -0.0168300942, 0.0238886178, 0.0248312876, -0.00661018072,
        -0.00949566811, -0.0352236405, 0.0363962315, -0.00451504067, 0.0143469665, 0.024463417,
        0.027613312, -0.0157839619, 0.00608136645, -0.001725832, 0.00319300476, 0.0102314102,
        0.0254060868, -0.030372344, 0.00323611451, -0.00975432806, -0.00211382075, -0.00911630131,
        0.00730568683, -0.0371319726, 0.0254520699, -0.00151603064, 0.0146458615, -0.0050438554,
        0.0267396178, 0.0213710014, -0.0362122953, 0.0189453531, -0.0159564018, -0.0292687304,
        0.0332923196, 0.00703553157, -0.0233713, 0.011691398, 0.0459378809, -0.0182326026,
        0.0339820758, -0.0123926522, 0.065481022, -0.039132271, -0.00639750529, 0.0208996665,
        -0.0537091568, -0.042627044, -0.0109096719, 0.0138296476, -0.0153356194, 0.0153356194,
        0.0263717473, 0.00103822944, 0.00428512134, -0.0110763637, 0.050352335, -0.00255928957,
        0.0275213439, -0.026532691, 0.000529892219, -0.0236816909, -0.033913102, -0.0156460106,
        0.0138871279, 0.0273604, -0.013070914, -0.0252911262, 0.0153471157, -0.0231298842,
        -0.0124041475, -0.0224861111, -0.0379137, 0.00666766055, 0.0117948614, 0.0156575069,
        0.0104325898, -0.00382815674, 0.0197385754, -0.0308551732, -0.00265844236, 0.0371089801,
        -0.0103348736, 0.00217992277, -0.00777702127, 0.0243484564, -0.00484267576, -0.0165082086,
        -0.00249175075, 0.0278892145, 0.0244864095, -0.0354075767, -0.00542034814, 0.0265096985,
        0.0132318577, -0.0351776592, -0.0463977195, -0.045340091, -0.0380286574, -0.0204283334,
        0.0174393822, 0.00471622031, 0.020750219, 0.0407417044, 0.00733442651, 0.016910566,
        0.00166547811, 0.0479611717, -0.0124616278, 0.0417993329, -0.0292917229, -0.0263947397,
        0.0376148038, 0.0153356194, 0.0284410212, 0.00623656204, 0.00429086946, 0.0202558935,
        -0.0111166, -0.0164047442, -0.00462425267, -0.0409946181, 0.0418223254, 0.0110533722,
        -0.002728855, 0.00379366893, -0.00947842468, 0.0131858736, 0.00473921234, -0.00596065866,
        0.0178417396, -0.0180716589, -0.000431817229, 0.0134502808, 0.00779426517, 0.023773659,
        -0.0184970107, 0.0100302305, 0.00535424659, -0.00238110218, 0.000590605254, 0.05660614,
        -0.00444031693, 0.0174393822, 0.0157954581, 0.00794946123, 0.0256130137, 0.0308781657,
        -0.00974858, -0.0086679589, -0.0175313484, -0.0212330502, -0.00878866669, 0.00073502335,
        -0.00486566778, 0.0121397404, -0.00699529564, 0.00677687209, -0.0129559543, 0.00650096918,
        -0.00253629754, -0.0143009825, 0.0154850669, 0.0107027451, -0.00820237212, 0.0283260606,
        0.0162093136, 0.0198420379, -0.00909331, 0.00434260117, -0.0365571752, 0.018853385,
        0.0125880837, -0.00875417888, 0.000292069395, 0.0113867549, -0.0137261841, 0.00704127969,
        0.0100359786, -0.00116037414, -0.0100877108, -0.00646648137, -0.00992676709, -0.00135365,
        -0.00645498512, 0.0271074884, 0.00732293073, -0.00115965563, 0.0165312, -0.00295446347,
        -0.0312230457, -0.0209916346, 0.0399829708, -0.0323726423, 0.00315276883, 0.0126570594,
        0.0269925296, -0.0190373212, 0.0207847077, -0.0381896, -0.0230609085, -0.0115994299,
        0.00890937448, 0.0147608211, 0.0118293492, 0.0126110753, -0.00696080783, -0.0262797792,
        -0.0123351719, -0.0157954581, -0.00846677925, 0.0165771842, 0.0195776317, -0.0184855144,
        0.00744938618, -0.0361893028, 0.00853000674, 0.0127030434, 0.0256360061, -0.0104555814,
        -0.0065412051, 0.0195316467, 0.0100762146, 0.00801268872, 0.000324042543, -0.00463000033,
        0.0342809707, -0.0110533722, 0.0250382144, 0.00428512134, 0.0125880837, 0.0194511749,
        -0.0297745541, 0.0128409946, 0.0178877246, 0.0200949498, 0.00992676709, 0.0150022367,
        -0.0130249299, -0.0398450196, 0.0329704322, -0.00777127314, 0.0252221506, 0.00811615214,
        0.0213480107, -0.0227620136, -0.0199799892, 0.00533412863, 0.000297637773, -0.0100992071,
        -0.0199225098, -0.0246243607, 0.003397058, -0.0112430556, -0.0239116102, 0.0210261233,
        0.0187959056,
      ],
      metadata: {
        langs: 30,
        paragraph_id: 3,
        row_id: 3,
        text: '24-hour clock time is used in computers, military, public safety, and transport. In many Asian, European and Latin American countries people use it to write the time. Many European people use it in speaking.',
        title: '24-hour clock',
        url: 'https://simple.wikipedia.org/wiki?curid=9985',
        views: 2450.6254882812,
        wiki_id: 9985,
      },
    },
  ],
};
