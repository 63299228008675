// Configuration
import CohereIcon from './icons/models/cohere.svg';
import HuggingFaceIcon from './icons/models/huggingface.svg';
import OpenAiIcon from './icons/models/openai.svg';
import AWS_IMAGE_DARK from './icons/providers/aws-dark.png';
import AWS_IMAGE from './icons/providers/aws.png';
import AZURE_IMAGE from './icons/providers/azure.png';
import GCP_IMAGE from './icons/providers/gcp.png';
import {type AssistantChatConfig, type EmbeddingModel, StorageIntegrationProvider} from './types';

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const AUTH0_NAMESPACE = 'https://pinecone.io/';
export const PUBLIC_DOCS_URL = 'https://docs.pinecone.io';
export const PINECONE_COMMUNITY_FORUM_URL = 'https://community.pinecone.io/';
export const PINECONE_SUPPORT_URL = 'https://support.pinecone.io';
export const DASHBOARD_BASE_URL_CLOUDRUN = import.meta.env.VITE_DASHBOARD_URL_CLOUDRUN;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '';
export const LAUNCH_DARKLY_ID = import.meta.env.VITE_LAUNCH_DARKLY_ID || '';
export const GLOBAL_CONTROL_PLANE_URL = import.meta.env.VITE_GLOBAL_CONTROL_PLANE_URL;
export const CONNECT_URL = import.meta.env.VITE_CONNECT_URL;
export const USE_CONTROL_PLANE_OVERRIDE = !!import.meta.env.VITE_CONTROL_PLANE_USE_OVERRIDE;
export const SANITY_PROJECT_ID = import.meta.env.VITE_SANITY_PROJECT_ID || '';
export const SANITY_READ_TOKEN = import.meta.env.VITE_SANITY_READ_TOKEN || '';
export const ASSISTANT_DATA_PLANE_URL = import.meta.env.VITE_ASSISTANT_DATA_PLANE_URL;
export const INKEEP_API_KEY = import.meta.env.VITE_INKEEP_API_KEY;
export const INKEEP_INTEGRATION_ID = import.meta.env.VITE_INKEEP_INTEGRATION_ID;
export const INKEEP_ORGANIZATION_ID = import.meta.env.VITE_INKEEP_ORGANIZATION_ID;
export const ZENDESK_EMAIL = import.meta.env.VITE_ZENDESK_EMAIL;
export const ZENDESK_API_TOKEN = import.meta.env.VITE_ZENDESK_API_TOKEN;

export enum DeletionProtection {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export const PodTypes = {
  STARTER: 'starter',
  NANO: 'nano',
};

// Behavior
export const POLLING_INTERVAL = 1000;

// States that can exist in the describe_index response
export enum IndexState {
  READY = 'Ready',
  INITIALIZING = 'Initializing',
  SCALING_DOWN = 'ScalingDown',
  SCALING_UP = 'ScalingUp',
  SCALING_UP_PODS = 'ScalingUpPodSize',
  SCALING_DOWN_PODS = 'ScalingDownPodSize',
  TERMINATING = 'Terminating',
  UNKNOWN = 'Unknown',
  FAILED = 'InitializationFailed',
}

// Note: These match up to the values saved in the user db.
// Changes to names should be done in the name mapping below.
export enum Plans {
  FREE = 'free',
  STANDARD = 'standard',
  ENTERPRISE = 'enterprise',
  DEDICATED = 'dedicated',
  INTERNAL = 'internal',
}

export const PlansInOrder = [Plans.FREE, Plans.STANDARD, Plans.ENTERPRISE, Plans.DEDICATED];

// Maps internal plan name (as used in the user db) -> external name
export const PlanNameMapping = {
  [Plans.FREE]: 'Starter',
  [Plans.STANDARD]: 'Standard',
  [Plans.ENTERPRISE]: 'Enterprise',
  [Plans.DEDICATED]: 'Enterprise Dedicated',
  [Plans.INTERNAL]: 'Internal',
};
// Note: these match the values provided in the Zendesk ticket form field
// https://pineconehelp.zendesk.com/admin/objects-rules/tickets/ticket-fields/5965726288401
export enum SupportSeverityLevel {
  SEV1 = 'sev1_-_production_env_is_severly_impacted',
  SEV2 = 'sev2_-_production_env_is_degraded',
  SEV3 = 'sev3_-_dev_impact_or_minor_production_issue',
  SEV4 = 'sev_4_-_question_or_feedback',
}
// Note: these match the values provided in the Zendesk ticket form field
// https://pineconehelp.zendesk.com/admin/objects-rules/tickets/ticket-fields/12362156615709
export enum SupportCategory {
  BILLING_QUESTION = 'category_billing_question',
  INDEX_MANAGEMENT = 'category_index_management',
  PROJECT_OR_ORGANIZATION_MANAGEMENT = 'category_project_or_organization_management',
  DRIVER_OR_CLIENT_QUESTION = 'category_driver_or_client_question',
  WEBSITE_OR_CONSOLE_QUESTION = 'category_website_or_console_question',
  EARLY_ACCESS_QUESTION = 'category_early_access_question',
}

// Note: These match up to the values saved in the organization db.
// Changes to names should be done in the name mapping below.
export enum SupportTiers {
  FREE = 'free',
  DEVELOPER = 'developer',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
  INTERNAL = 'internal',
}

export const SupportTiersInOrder = [
  SupportTiers.FREE,
  SupportTiers.DEVELOPER,
  SupportTiers.PRO,
  SupportTiers.ENTERPRISE,
];

export const SupportTierNameMapping = {
  [SupportTiers.FREE]: 'Free',
  [SupportTiers.DEVELOPER]: 'Developer',
  [SupportTiers.PRO]: 'Pro',
  [SupportTiers.ENTERPRISE]: 'Enterprise',
  [SupportTiers.INTERNAL]: 'Internal',
};

export enum Roles {
  OWNER = 'owner',
  USER = 'user',
}

export const freeEnvironmentPodName = {
  value: 'nano',
  displayName: 'starter',
};

export const lowercaseAlphaNumbericErrorMessage =
  'Can only contain lowercase letters, numbers, and hyphens.';
export const requiredField = 'Required.';
export const UPGRADE_TOOLTIP = 'Upgrade this project to a paid plan for more pod types and sizes.';

export enum Providers {
  GCP = 'gcp',
  AWS = 'aws',
  AZURE = 'azure',
}

export const PROVIDER_INFORMATION_MAP = {
  gcp: {
    name: 'GCP',
    fullname: 'Google Cloud Platform',
    logo: GCP_IMAGE,
    logoDark: GCP_IMAGE,
    link: 'https://console.cloud.google.com/billing',
  },
  aws: {
    name: 'AWS',
    fullname: 'Amazon Web Services',
    logo: AWS_IMAGE,
    logoDark: AWS_IMAGE_DARK,
    link: 'https://aws.amazon.com/marketplace/pp/prodview-xhgyscinlz4jk',
  },
  azure: {
    name: 'Azure',
    fullname: 'Microsoft Azure',
    logo: AZURE_IMAGE,
    logoDark: AZURE_IMAGE,
    link: 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/pineconesystemsinc1688761585469.pineconesaas?tab=Overview',
  },
};

export const DEFAULT_INDEX_SETTINGS = {
  indexType: 'serverless' as const,
  provider: Providers.AWS,
  region: 'us-east-1',
};

export const datadogConfig = {
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
};

export const localStoragePrefix = 'pinecone';
export const LocalStorageKeys = {
  LAST_SELECTED_PROJECT: 'last_selected_project',
  LAST_SELECTED_ORGANIZATION: 'last_selected_organization',
  SEEN_SERVERLESS_MODAL: 'seen_serverless_modal',
  SERVERLESS_RELEASE_HAS_CREATED_INDEX: 'serverless_release_has_created_index',
  SERVERLESS_RELEASE_HAS_QUERIED: 'serverless_release_has_queried',
  MULTIPLE_ORGS_NOTIFICATION: 'multiple_orgs_notification',
  VIEWED_INDEXES_HISTORY: 'viewed_indexes_history',
  VIEWED_PROJECTS_HISTORY: 'viewed_projects_history',
  SKIP_REGISTRATION_FORM: 'skip_registration_form',
  VIEWED_FORCED_MIGRATION_FORM: 'viewed_forced_migration_form',
  VIEWED_ARCHIVED_NOTIFICATION: 'viewed_archived_notification',
  VIEWED_ARCHIVED_MODAL: 'viewed_archived_modal',
  VIEWED_SERVERLESS_GA_ALERT: 'viewed_serverless_ga_alert',
  VIEWED_PRICING_CHANGE_NOTIFICATION: 'viewed_pricing_change_notification',
  POD_TO_SERVERLESS_ALERT: 'pod_to_serverless_alert',
  INDEX_SORT_ORDER: 'index_sort_order',
  PROJECT_SORT_ORDER: 'project_sort_order',
  INDEX_METRICS_INTERVAL: 'index_metrics_interval',
  GET_STARTED_GUIDES: 'get_started_guide',
  VIEWED_SUPPORT_NOTIFICATION: 'viewed_support_notification',
} as const;

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

// Changes in routes should be reflected here as well as in /src/components/app/MainApp.tsx
export enum RoutePath {
  AWS_BEDROCK_INTEGRATION = '/aws-bedrock-integration',
  VERCEL_INTEGRATION = '/oauth/vercel',
  ORGANIZATION_ROOT = '/organizations',
  ORGANIZATION = '/organizations/:orgId',
  PROJECT_LIST = '/organizations/:orgId/projects',
  CREATE_PROJECT = '/organizations/:orgId/projects/create/*',
  PROJECT = '/organizations/:orgId/projects/:projectId',
  CREATE_INDEX = '/organizations/:orgId/projects/:projectId/create-index/*',
  CREATE_INDEX_WITH_SAMPLE_DATA = '/organizations/:orgId/projects/:projectId/create-index-with-sample-data',
  DATASET_GALLERY = '/organizations/:orgId/projects/:projectId/datasets',
  INDEX_LIST = '/organizations/:orgId/projects/:projectId/indexes',
  INDEX = '/organizations/:orgId/projects/:projectId/indexes/:indexName/*',
  KEYS = '/organizations/:orgId/projects/:projectId/keys',
  MEMBERS = '/organizations/:orgId/projects/:projectId/members',
  SETTINGS = '/organizations/:orgId/settings/*',
  BILLING_PLANS = '/organizations/:orgId/settings/billing/plans',
  CREATE_ORGANIZATION = '/organizations/create-organization',
}

export enum ProjectPage {
  INDEXES = 'indexes',
  BACKUPS = 'backups',
  MODELS = 'models',
  KEYS = 'keys',
  MEMBERS = 'members',
  CREATE_INDEX = 'create-index',
  DATASET_GALLERY = 'datasets',
  NETWORK = 'network',
  ASSISTANTS = 'assistant',
  GET_STARTED = 'get-started',
  STORAGE = 'storage',
  CMEK = 'cmek-encryption',
}

export enum CreateIndexTabs {
  SERVERLESS = 'serverless',
  PODS = 'pods',
  STARTER = 'starter',
}

export enum ProjectNetworkTabs {
  PRIVATELINK_ENDPOINT = 'privatelink_endpoint',
  ACCESS = 'access',
}

export enum OrgSettingsPage {
  BILLING = 'billing',
  BILLING_PLANS = 'billing/plans',
  USAGE = 'usage',
  PROJECTS = 'projects',
  MEMBERS = 'members',
  ACCOUNT = 'account',
  INTEGRATIONS = 'integrations',
  SUPPORT = 'support',
}

// TODO: @tinedkim Update when tickets page is implemented
export enum OrgSupportPage {
  SUPPORT_PLANS = 'plans',
  SUPPORT_TICKETS = 'ticket',
}

export enum IndexDetailTab {
  BROWSER = 'browser',
  CONFIGURATION = 'config',
  METRICS = 'metrics',
  METRICS_CLASSIC = 'metrics-classic', // todo(cboltt): Remove once metrics revamp is rolled out
  NAMESPACES = 'namespaces',
  IMPORTS = 'imports',
}

export enum PaymentStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  RESTRICTED = 'restricted',
  SUSPENDED = 'suspended',
  DEACTIVATED = 'deactivated',
}

export const DEEPLINK_TOKEN = '-';

export const PUBLIC_DOCS = {
  CHOOSING_INDEX_TYPE_AND_SIZE: `${PUBLIC_DOCS_URL}/guides/indexes/choose-a-pod-type-and-size`,
  UNDERSTANDING_COST: `${PUBLIC_DOCS_URL}/docs/understanding-cost`,
  UNDERSTANDING_COST_WU: `${PUBLIC_DOCS_URL}/docs/understanding-cost#write-units`,
  UNDERSTANDING_COST_RU: `${PUBLIC_DOCS_URL}/docs/understanding-cost#read-units`,
  UNDERSTANDING_COST_STORAGE: `${PUBLIC_DOCS_URL}/docs/understanding-cost#storage`,
  PROMETHEUS_MONITORING: `${PUBLIC_DOCS_URL}/guides/operations/monitoring#monitor-with-prometheus`,
  DELETION_PROTECTION: `${PUBLIC_DOCS_URL}/guides/indexes/prevent-index-deletion`,
  AWS_PRIVATELINK: `${PUBLIC_DOCS_URL}/guides/operations/enable-aws-privatelink`,
  INFERENCE: `${PUBLIC_DOCS_URL}/guides/inference/understanding-inference`,
  ASSISTANT: `${PUBLIC_DOCS_URL}/guides/assistant/understanding-assistant`,
  INTEGRATIONS: `${PUBLIC_DOCS_URL}/integrations/overview`,
  API_REFERENCE: `${PUBLIC_DOCS_URL}/reference/api/introduction`,
  EXAMPLES: `${PUBLIC_DOCS_URL}/examples/notebooks`,
  MODEL_GALLERY: `${PUBLIC_DOCS_URL}/models/overview`,
  MANAGE_API_KEYS: `${PUBLIC_DOCS_URL}/guides/projects/manage-api-keys`,
  BACKUPS: `${PUBLIC_DOCS_URL}/guides/indexes/back-up-an-index`,
  IMPORTS: `${PUBLIC_DOCS_URL}/guides/data/understanding-imports`,
  CMEK: `${PUBLIC_DOCS_URL}/guides/operations/configure-cmek`,
};

export const PINECONE_URLS = {
  CONTACT_SALES: 'https://www.pinecone.io/contact/',
  COMMUNITY_FORUM: 'https://community.pinecone.io/',
  VECTOR_DATABASE: 'https://www.pinecone.io/learn/vector-database/',
};

export const SERVERLESS_ENV = 'serverless';

export enum CreateIndexParam {
  NAME = 'name',
  DIMENSIONS = 'dimensions',
  METRIC = 'metric',
  FROM_COLLECTION = 'from_collection',
  FROM_BACKUP = 'from_backup',
  ENVIRONMENT = 'environment',
  SETUP_WITH_MODEL = 'withModel',
  CLOUD = 'cloud',
  REGION = 'region',
}

export const DefaultEmbeddingModels = [
  {
    icon: HuggingFaceIcon,
    imageRef: '',
    name: 'multilingual-e5-large',
    dimension: [1024],
    metric: ['cosine'],
    type: 'text',
  },
  {
    icon: OpenAiIcon,
    imageRef: '',
    name: 'text-embedding-3-small',
    dimension: [1536],
    metric: ['cosine'],
    type: 'text',
  },
  {
    icon: OpenAiIcon,
    imageRef: '',
    name: 'text-embedding-3-large',
    dimension: [3072],
    metric: ['cosine'],
    type: 'text',
  },
  {
    icon: CohereIcon,
    imageRef: '',
    name: 'Cohere-embed-multilingual-v3.0',
    dimension: [1024],
    metric: ['cosine'],
    type: 'text',
  },
  {
    icon: OpenAiIcon,
    imageRef: '',
    name: 'text-embedding-ada-002',
    dimension: [1536],
    metric: ['cosine'],
    type: 'text',
  },
  {
    icon: HuggingFaceIcon,
    imageRef: '',
    name: 'CLIP-ViT-B-32-laion2B-s34B-b79K',
    dimension: [512],
    metric: ['cosine'],
    type: 'text + image',
  },
] as EmbeddingModel[];

export enum AssistantQuotaKeys {
  STORAGE_GB_PER_PROJECT = 'assistant_storage_gb_per_project',
  QUERIES_PER_MONTH = 'assistant_queries_per_month',
  PROMPT_TOKENS = 'assistant_prompt_tokens',
  COMPLETIONS_TOKENS = 'assistant_completions_tokens',
}

// Must be in-sync with RESTRICTIONS enum definition in Dashboard
export enum FreeTierDowngradeRestrictionKeys {
  SERVERLESS_FREE_TIER_INDEXES = 'serverless_free_tier_indexes',
  NON_SERVERLESS_FREE_TIER_INDEXES = 'non_serverless_free_tier_indexes',
  PROJECT_COUNT = 'project_count',
  STORAGE_UNITS_GBS = 'storage_units_gbs',
  PRIVATE_ENDPOINTS = 'private_endpoints',
  ASSISTANT_COUNT = 'assistant_count',
  ASSISTANT_FILE_COUNT = 'assistant_file_count',
  ASSISTANT_STORAGE_GBS = 'assistant_storage_gbs',
}

export const API_KEY_LABEL_MAX_LENGTH = 80;

export enum AssistantFileStatus {
  AVAILABLE = 'Available',
  DELETING = 'Deleting',
  PROCESSING = 'Processing',
  PROCESSING_FAILED = 'ProcessingFailed',
}

export enum AssistantPagePanel {
  SETTINGS = '',
  FILES = 'files',
  CONNECT = 'connect',
}

export enum Purpose {
  BUSINESS = 'Business',
  PERSONAL = 'Personal',
  NONE = 'None',
}

export const STORAGE_INTEGRATION_PROVIDER_INFO = {
  [StorageIntegrationProvider.S3]: {
    name: 'Amazon S3',
  },
  [StorageIntegrationProvider.GCS]: {
    name: 'Google Cloud Storage',
  },
  [StorageIntegrationProvider.AzureBlob]: {
    name: 'Azure Blob Storage',
  },
};

export const BulkImportErrorModes = {
  continue: 'continue',
  abort: 'abort',
};

export const DEFAULT_ASSISTANT_CHAT_CONFIG: AssistantChatConfig = {
  model: 'gpt-4o',
};
