import {type ButtonProps} from '@mui/material';
import CreateButton from '../../../../components/CreateButton';
import {useBooleanState} from '../../../../hooks/utils';
import {useGlobalProjectIsCreating, useSelectedGlobalProject} from '../../../../selectors/projects';
import CreateCollectionForm from '../CreateCollectionForm/CreateCollectionForm';

type CreateCollectionButtonProps = {
  text?: string;
  customId?: string;
  variant?: ButtonProps['variant'];
  includePlus?: boolean;
  color?: ButtonProps['color'];
};

function CreateCollectionButton({
  text = 'Create Collection',
  customId,
  variant = 'contained',
  includePlus = false,
  color = 'primary',
}: CreateCollectionButtonProps) {
  const {val: showForm, setTrue: setShowForm, setFalse: setHideForm} = useBooleanState();
  const selectedProject = useSelectedGlobalProject();
  const isCreating = useGlobalProjectIsCreating(selectedProject.id);
  return (
    <>
      <CreateButton
        text={text}
        onClick={setShowForm}
        customId={customId}
        variant={variant}
        includePlus={includePlus}
        color={color}
        disabled={isCreating}
      />
      <CreateCollectionForm open={showForm} close={setHideForm} />
    </>
  );
}

export default CreateCollectionButton;
