import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {createPrivateEndpointClearResponse} from '../../../../../actions/privateEndpointsActions';
import {useProjectInternetAccessSwitch} from '../../../../../hooks/internet-access';
import {useNetworkAllowlistItem} from '../../../../../selectors/networkAllowlists';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {useCreatePrivateEndpointResponse} from '../../../../../selectors/privateEndpoints';
import {MODAL_WIDTH_MEDIUM} from '../../../../../styles/theme';
import AccessStep from './AccessStep/AccessStep';
import AuthorizeEndpointStep from './AuthorizeEndpointStep/AuthorizeEndpointStep';
import SelectRegionStep from './SelectRegionStep/SelectRegionStep';
import {type EndpointFormSchema, endpointFormSchema} from './endpointFormSchema';

interface CreatePrivateEndpointModalProps {
  open: boolean;
  onCreate: (formData: EndpointFormSchema) => void;
  onClose: () => void;
}

const STEPS = ['Select Region', 'Authorize VPC Endpoint', 'Access'];
const PRIVATELINK_ENDPOINTS: Record<string, string> = {
  'us-east-1': 'com.amazonaws.vpce.us-east-1.vpce-svc-05ef6f1f0b9130b54',
  'us-west-2': 'com.amazonaws.vpce.us-west-2.vpce-svc-04ecb9a0e0d5aab01',
  'eu-west-1': 'com.amazonaws.vpce.eu-west-1.vpce-svc-03c6b7e17ff02a70f',
};

const styles = {
  stepper: {
    pt: 2,
    pb: 4,
    ml: -1,
  },
};

export default function CreatePrivateEndpointModal({
  open,
  onCreate,
  onClose,
}: CreatePrivateEndpointModalProps) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const response = useCreatePrivateEndpointResponse();
  const projectId = useSelectedGlobalProjectId();
  const {data: allowlist} = useNetworkAllowlistItem(projectId) || {};
  const endpointCreated = response.data?.endpointCreated;

  const {disabled: internetAccessDisabled, setAccess: setInternetAccess} =
    useProjectInternetAccessSwitch(allowlist);

  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      region: Object.keys(PRIVATELINK_ENDPOINTS)[0],
      cloud: 'aws',
      cloud_endpoint_id: '',
    },
    resolver: zodResolver(endpointFormSchema),
  });

  const handleClose = useCallback(() => {
    dispatch(createPrivateEndpointClearResponse());
    setActiveStep(0);
    reset();
    onClose();
  }, [dispatch, reset, onClose]);

  useEffect(() => {
    // Move to the final step if user has submitted and endpoint was created.
    if (activeStep === 1 && endpointCreated) {
      setActiveStep(2);
    }
  }, [activeStep, endpointCreated]);

  const data = watch();

  return (
    <BaseModal open={open} onClose={handleClose} sx={{width: MODAL_WIDTH_MEDIUM}}>
      <form onSubmit={handleSubmit(onCreate)} autoComplete="off" id="create-private-endpoint-form">
        <ModalHeader title="Add Private Endpoint" close={handleClose} />
        <Box sx={styles.stepper}>
          <Stepper activeStep={activeStep}>
            {STEPS.map((step) => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 && (
          <SelectRegionStep
            control={control}
            endpoints={PRIVATELINK_ENDPOINTS}
            selectedRegion={data.region}
            onClose={handleClose}
            onNext={() => setActiveStep(activeStep + 1)}
          />
        )}
        {activeStep === 1 && (
          <AuthorizeEndpointStep
            loading={response.loading}
            error={response.error ? `${response.error}` : undefined}
            control={control}
            selectedServiceName={PRIVATELINK_ENDPOINTS[data.region]}
            isValid={isValid}
            onClose={handleClose}
            onNext={handleSubmit(onCreate)}
          />
        )}
        {activeStep === 2 && (
          <AccessStep
            selectedRegion={data.region}
            cloudEndpointId={data.cloud_endpoint_id}
            internetAccessDisabled={internetAccessDisabled}
            setInternetAccess={setInternetAccess}
            onClose={handleClose}
          />
        )}
      </form>
    </BaseModal>
  );
}
