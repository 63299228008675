import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import BulkImportStatusLabel from '../../../../../components/BulkImportStatusLabel/BulkImportStatusLabel';
import {type BulkImport} from '../../../../../types';
import ImportActionMenu from './ImportActionMenu/ImportActionMenu';

interface ImportCellProps {
  indexName: string;
  bulkImport: BulkImport;
  column: number;
  onCancel: (id: string) => void;
}

const styles = {
  statusLabel: {
    minWidth: '120px',
  },
};

export function ImportCell({indexName, bulkImport, column, onCancel}: ImportCellProps) {
  switch (column) {
    case 0:
      return <Typography variant="inherit">{bulkImport.id}</Typography>;
    case 1:
      return (
        <Box sx={styles.statusLabel}>
          <BulkImportStatusLabel indexName={indexName} importId={bulkImport.id} />
        </Box>
      );
    case 2:
      return (
        <Typography variant="inherit">
          {new Date(bulkImport.createdAt).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </Typography>
      );
    case 3:
      return (
        <Typography variant="inherit" color={bulkImport.finishedAt ? 'inherit' : 'secondary'}>
          {bulkImport.finishedAt
            ? new Date(bulkImport.finishedAt).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })
            : '-'}
        </Typography>
      );
    case 4:
      return (
        <Tooltip title={bulkImport.uri} placement="top">
          <Typography variant="inherit">{bulkImport.uri}</Typography>
        </Tooltip>
      );
    case 5:
      return (
        <Typography variant="inherit">{bulkImport.recordsImported.toLocaleString()}</Typography>
      );
    case 6:
      return (
        <Tooltip
          title={
            bulkImport.error && (
              <Typography variant="inherit" color="error">
                {bulkImport.error}
              </Typography>
            )
          }
          placement="top"
        >
          <Typography variant="inherit" color={bulkImport.error ? 'error' : 'secondary'}>
            {bulkImport.error || '-'}
          </Typography>
        </Tooltip>
      );
    case 7:
      return <ImportActionMenu id={bulkImport.id} status={bulkImport.status} onCancel={onCancel} />;
    default:
      return null;
  }
}
