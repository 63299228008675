import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/organizationActions';
import {type SsoConnectionConfig, type SsoConnectionRule} from '../types';
import {type AsyncDataState, initAsyncDataState, isOrgAction} from './utils';

interface OrganizationState {
  connection?: SsoConnectionConfig;
  rules?: SsoConnectionRule[];
}

const initialOrganizationState = initAsyncDataState<OrganizationState>();

interface SsoState {
  organizations: Record<string, AsyncDataState<OrganizationState>>;
}

const initialState = {
  organizations: {},
} as SsoState;

const SsoLoadingActions = [
  actions.getSsoConfigRequest.type,
  actions.setSsoConfigRequest.type,
  actions.editSsoConfigRequest.type,
  actions.deleteSsoRequest.type,
  actions.editSsoRulesRequest.type,
];

const SsoErrorActions = [
  actions.getSsoConfigError.type,
  actions.setSsoConfigError.type,
  actions.editSsoConfigError.type,
  actions.deleteSsoError.type,
  actions.editSsoRulesError.type,
];

const organizationReducer = createReducer(initialOrganizationState, (builder) => {
  builder
    .addCase(actions.getSsoConfigSuccess, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.error = undefined;
    })
    .addCase(actions.setSsoConfigSuccess, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.error = undefined;
    })
    .addCase(actions.editSsoConfigSuccess, (state, action) => {
      state.loading = false;
      state.data = {
        rules: [],
        ...state.data,
        connection: action.payload.data.connection,
      };
      state.error = null;
    })
    .addCase(actions.editSsoRulesSuccess, (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        rules: action.payload.data.rules,
      };
      state.error = null;
    })
    .addCase(actions.deleteSsoSuccess, (state) => {
      state.loading = false;
      state.error = null;
      state.data = undefined;
    })
    .addMatcher(
      (action) => SsoLoadingActions.includes(action.type),
      (state) => {
        state.loading = true;
      },
    )
    .addMatcher(
      (action) => SsoErrorActions.includes(action.type),
      (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      },
    );
});

const ssoReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isOrgAction, (state, action) => {
    state.organizations[action.payload.organizationId] = organizationReducer(
      state.organizations[action.payload.organizationId],
      action,
    );
  });
});

export default ssoReducer;
