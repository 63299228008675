import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EmptyStateCard from '../../../../components/EmptyStateCard';
import {PUBLIC_DOCS} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import GettingStartedGuide from './GettingStartedGuide/GettingStartedGuide';

interface AssistantEmptyStateProps {
  onOpenCreateModal: () => void;
}

function AssistantEmptyState({onOpenCreateModal}: AssistantEmptyStateProps) {
  const showQuickstartGuide = useFlag(Flags.ENABLE_ASSISTANT_PLAYGROUND);
  return (
    <EmptyStateCard
      title="Welcome to Assistant"
      description={
        <Typography textAlign="center">
          Start building accurate question-answering capability into your AI products.
          <br />
          Read through our{' '}
          <Link target="_blank" rel="noreferrer" href={PUBLIC_DOCS.ASSISTANT}>
            documentation
          </Link>
          {showQuickstartGuide ? ' or follow the quickstart guide below.' : ' for more.'}
        </Typography>
      }
      cta={
        <Box display="flex" gap={2}>
          <Button variant="contained" color="primary" onClick={onOpenCreateModal}>
            Create an assistant
          </Button>
        </Box>
      }
      footer={showQuickstartGuide && <GettingStartedGuide />}
    />
  );
}

export default AssistantEmptyState;
