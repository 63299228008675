import Table from '@pinecone-experience/timber/Table';
import {useNavigateToBackupDetailsPage} from '../../../../hooks/navigation';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';
import {type Backup} from '../../../../types';
import {groupBy} from '../../../../utils/types';
import BackupsTableRow from './BackupsTableRow';

type BackupsTableProps = {
  loading: boolean;
  backups: Backup[];
};

function BackupsTable({loading, backups}: BackupsTableProps) {
  const byIndex = Object.entries(groupBy(backups, (b) => b.index_id));
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {go: goToDetailsPath} = useNavigateToBackupDetailsPage(orgId, projectId);
  const cellRenderer = (row: number, col: number) => {
    const [indexId, indexBackups] = byIndex[row];
    return <BackupsTableRow indexId={indexId} backups={indexBackups} col={col} />;
  };
  const goToBackups = (row: number) => {
    const [, indexBackups] = byIndex[row];
    goToDetailsPath(indexBackups[0].index_id);
  };
  return (
    <Table
      isLoading={loading}
      pagination={{labelled: false, sizeControl: true}}
      columns={[
        {name: 'Source Index', dataType: 'string'},
        {name: 'Region', dataType: 'string'},
        {name: 'Cloud', dataType: 'string'},
        {name: 'Dimensions', dataType: 'number'},
        {name: 'Backups', dataType: 'number'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={byIndex.length}
      cellRenderer={cellRenderer}
      onClickRow={goToBackups}
    />
  );
}

export default BackupsTable;
