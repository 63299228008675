import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {PROVIDER_INFORMATION_MAP} from '../../../../../../constants';
import {useBooleanState} from '../../../../../../hooks/utils';
import {type PrivateEndpoint} from '../../../../../../types';

interface PrivateEndpointCellProps {
  endpoints: PrivateEndpoint[];
  row: number;
  col: number;
  onDelete: (id: string) => void;
}

export default function PrivateEndpointCell({
  endpoints,
  row,
  col,
  onDelete,
}: PrivateEndpointCellProps) {
  const endpoint = endpoints[row];

  const {
    val: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: hideDeleteModal,
  } = useBooleanState();

  const handleDelete = useCallback(() => {
    onDelete(endpoint.id);
  }, [onDelete, endpoint.id]);

  const cloudName = PROVIDER_INFORMATION_MAP[endpoint.cloud]?.fullname ?? endpoint.cloud;

  switch (col) {
    case 0:
      return <Typography>{endpoint.cloud_endpoint_id}</Typography>;

    case 1:
      return <Typography>{cloudName}</Typography>;

    case 2:
      return <InlineCode text={endpoint.region} />;

    case 3:
      return (
        <>
          <SelectorMenuButton
            iconSx={{m: -1}}
            id={`${endpoint.id}-actions-showActions`}
            placement="bottom-end"
            ariaLabel="Endpoint actions"
            items={[
              {
                id: 'delete',
                name: 'Delete',
                icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
                onClick: openDeleteModal,
                color: 'error',
              },
            ]}
          />
          <DeleteConfirmationModal
            open={showDeleteModal}
            close={hideDeleteModal}
            itemType="Endpoint"
            name={endpoint.cloud_endpoint_id}
            doDelete={handleDelete}
            warningText="This action cannot be undone. Please type the ID above to confirm."
          />
        </>
      );

    default:
      return null;
  }
}
