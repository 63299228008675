import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Pagination, {type PaginationProps} from '@mui/material/Pagination';
import Select, {type SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {useCallback, useEffect} from 'react';
import {type PaginationState} from './hooks';

type PaginationControlsProps<T> = Omit<PaginationProps, 'onChange'> & {
  itemLabel?: [singular: string, plural: string];
  paginationState: PaginationState<T>;
  includeSizeControls: boolean;
};

const styles = {
  root: (hasSizeControl: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: hasSizeControl ? 'space-between' : 'flex-end',
  }),
  select: {
    display: 'flex',
    alignItems: 'center',
    pr: 1,
  },
  pagination: {
    justifySelf: 'flex-end',
    ul: {
      color: 'primary.dark',
      '.Mui-selected': {
        backgroundColor: 'primary.dark',
        color: 'background.paper',
      },
      justifyContent: 'end',
      svg: {
        color: 'text.secondary',
      },
    },
  },
};

function PaginationControls<T>({
  itemLabel = ['row', 'rows'],
  paginationState,
  includeSizeControls,
  ...props
}: PaginationControlsProps<T>) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, plural] = itemLabel;

  const {setRowsPerPage, currentPage, setCurrentPage, rowsPerPage, total} = paginationState;
  useEffect(() => {
    const maxPage = Math.floor(total / rowsPerPage);
    if (currentPage > maxPage) {
      setCurrentPage(maxPage);
    }
  }, [currentPage, setCurrentPage, rowsPerPage, total]);

  const handlePageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, newPage: number) => {
      setCurrentPage(newPage - 1);
    },
    [setCurrentPage],
  );

  const handlePageSizeChange = useCallback(
    (event: SelectChangeEvent) => {
      if (includeSizeControls) {
        setRowsPerPage(parseInt(event.target.value, 10));
      }
    },
    [includeSizeControls, setRowsPerPage],
  );

  if (total <= rowsPerPage) return null;

  return (
    <Box sx={styles.root(!!includeSizeControls)}>
      {includeSizeControls ? (
        <Box sx={styles.select}>
          <Typography id="rows-per-page" variant="caption" pr={1}>
            <Typography textTransform="capitalize" variant="caption">
              {plural}
            </Typography>{' '}
            per page:
          </Typography>
          <Select
            labelId="rows-per-page"
            disableUnderline
            variant="standard"
            value={rowsPerPage.toString()}
            sx={{pt: 0.5}}
            size="small"
            onChange={handlePageSizeChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </Box>
      ) : null}
      <Pagination
        count={Math.ceil(total / rowsPerPage)}
        page={currentPage + 1}
        onChange={handlePageChange}
        shape="rounded"
        sx={styles.pagination}
        {...props}
      />
    </Box>
  );
}

export default PaginationControls;
