import {createContext, useContext, useMemo, useState} from 'react';
import {type LanguageTypes} from '../../../../../components/CodeSnippets/constants';
import {LocalStorageKeys} from '../../../../../constants';
import {localStorageGet, localStorageSet} from '../../../../../utils/storage';
import {type Guide, type GuideId} from '../types';

export enum GuideStatus {
  INCOMPLETE = 'incomplete',
  IN_PROGRESS = 'in_progress',
  SKIPPED = 'skipped',
  COMPLETE = 'complete',
}

export type Block = {index: number; status: GuideStatus};

export type Section = {
  index: number;
  status: GuideStatus;
  blocks: Block[];
  sectionState: Record<string, string>;
};

export interface GuideContextState {
  id: GuideId;
  sections: Section[];
}

export function createGuideContextState(
  guide: Guide,
  sectionIndex: number,
  blockIndex: number,
  completed: boolean,
): GuideContextState {
  const defaultState: GuideContextState = {
    id: guide.id,
    sections: [],
  };

  guide.sections.forEach((section) => {
    const blocks: Block[] = [];

    section.blocks.forEach((block) => {
      let blockStatus: GuideStatus;
      if (completed) {
        blockStatus = GuideStatus.COMPLETE;
      } else if (
        section.index < sectionIndex ||
        (section.index === sectionIndex && block.index < blockIndex)
      ) {
        blockStatus = GuideStatus.COMPLETE;
      } else if (section.index === sectionIndex && block.index === blockIndex) {
        blockStatus = GuideStatus.IN_PROGRESS;
      } else {
        blockStatus = GuideStatus.INCOMPLETE;
      }

      blocks[block.index] = {
        index: block.index,
        status: blockStatus,
      };
    });

    let sectionStatus: GuideStatus;
    if (completed) {
      sectionStatus = GuideStatus.COMPLETE;
    } else if (section.index < sectionIndex) {
      sectionStatus = GuideStatus.COMPLETE;
    } else if (section.index === sectionIndex) {
      sectionStatus = GuideStatus.IN_PROGRESS;
    } else {
      sectionStatus = GuideStatus.INCOMPLETE;
    }

    defaultState.sections[section.index] = {
      index: section.index,
      status: sectionStatus,
      blocks,
      sectionState: {},
    };
  });

  return defaultState;
}

export const GuideContext = createContext(
  {} as {
    state: GuideContextState;
    supportedLanguages?: LanguageTypes[];
    updateBlockStatus: (sectionIndex: number, blockIndex: number, status: GuideStatus) => void;
    updateSectionStatus: (sectionIndex: number, status: GuideStatus) => void;
    updateSectionState: (sectionIndex: number, key: string, value: string) => void;
    getSectionState: (sectionIndex: number, key: string) => string | undefined;
    resetGuide: () => void;
  },
);

export const useGuideContext = () => {
  return useContext(GuideContext);
};

function calculateSectionStatus(blocks: Block[]): GuideStatus {
  const blockStatuses = blocks.map((block) => block.status);
  if (
    blockStatuses.every(
      (status) => status === GuideStatus.COMPLETE || status === GuideStatus.SKIPPED,
    )
  ) {
    return GuideStatus.COMPLETE;
  }
  if (blockStatuses.some((status) => status === GuideStatus.IN_PROGRESS)) {
    return GuideStatus.IN_PROGRESS;
  }
  return GuideStatus.INCOMPLETE;
}

function updateSectionStatus(
  state: GuideContextState,
  sectionIndex: number,
  status: GuideStatus,
  localStorageKey: string,
) {
  const newState = {...state};
  newState.sections[sectionIndex].status = status;

  if (newState.sections[sectionIndex + 1]) {
    newState.sections[sectionIndex + 1].blocks[0].status = GuideStatus.IN_PROGRESS;
    newState.sections[sectionIndex + 1].status = GuideStatus.IN_PROGRESS;
    localStorageSet(
      localStorageKey,
      JSON.stringify({
        sectionIndex: sectionIndex + 1,
        blockIndex: 0,
        completed: false,
      }),
    );
  } else {
    localStorageSet(
      localStorageKey,
      JSON.stringify({
        sectionIndex,
        blockIndex: 0,
        completed: true,
      }),
    );
  }
  return newState;
}

function updateBlockStatus(
  state: GuideContextState,
  sectionIndex: number,
  blockIndex: number,
  status: GuideStatus,
  localStorageKey: string,
) {
  const newState = {...state};
  newState.sections[sectionIndex].blocks[blockIndex].status = status;

  // update next block to in progress
  if (newState.sections[sectionIndex].blocks[blockIndex + 1]) {
    newState.sections[sectionIndex].blocks[blockIndex + 1].status = GuideStatus.IN_PROGRESS;
    localStorageSet(
      localStorageKey,
      JSON.stringify({
        sectionIndex,
        blockIndex: blockIndex + 1,
        completed: false,
      }),
    );
  } else if (newState.sections[sectionIndex + 1]) {
    newState.sections[sectionIndex + 1].blocks[0].status = GuideStatus.IN_PROGRESS;
    newState.sections[sectionIndex + 1].status = GuideStatus.IN_PROGRESS;
    localStorageSet(
      localStorageKey,
      JSON.stringify({
        sectionIndex: sectionIndex + 1,
        blockIndex: 0,
        completed: false,
      }),
    );
  } else {
    localStorageSet(
      localStorageKey,
      JSON.stringify({
        sectionIndex,
        blockIndex,
        completed: true,
      }),
    );
  }

  // update section status
  newState.sections[sectionIndex].status = calculateSectionStatus(
    newState.sections[sectionIndex].blocks,
  );

  return newState;
}

export const GuideContextProvider = ({
  children,
  guide,
  projectId,
}: {
  children: React.ReactNode;
  guide: Guide;
  projectId: string;
}) => {
  const localStorageKey = `${LocalStorageKeys.GET_STARTED_GUIDES}-${projectId}-${guide.id}`;

  // Check for existing progress in local storage
  const localStorageProgress = JSON.parse(localStorageGet(localStorageKey) || '{}');

  const [state, setState] = useState(
    createGuideContextState(
      guide,
      localStorageProgress?.sectionIndex || 0,
      localStorageProgress?.blockIndex || 0,
      localStorageProgress?.completed || false,
    ),
  );

  const value = useMemo(
    () => ({
      state,
      supportedLanguages: guide.supportedLanguages,
      updateBlockStatus: (sectionIndex: number, blockIndex: number, status: GuideStatus) => {
        setState((prevState) =>
          updateBlockStatus(prevState, sectionIndex, blockIndex, status, localStorageKey),
        );
      },
      updateSectionStatus: (sectionIndex: number, status: GuideStatus) => {
        setState((prevState) =>
          updateSectionStatus(prevState, sectionIndex, status, localStorageKey),
        );
      },
      updateSectionState: (sectionIndex: number, key: string, val: string) => {
        setState((prevState) => {
          const newState = {...prevState};
          newState.sections[sectionIndex].sectionState[key] = val;
          return newState;
        });
      },
      getSectionState: (sectionIndex: number, key: string) => {
        return state.sections[sectionIndex].sectionState[key];
      },
      resetGuide: () => {
        setState(createGuideContextState(guide, 0, 0, false));
        localStorageSet(
          localStorageKey,
          JSON.stringify({
            sectionIndex: 0,
            blockIndex: 0,
            completed: false,
          }),
        );
      },
    }),
    [state, guide, localStorageKey],
  );

  return <GuideContext.Provider value={value}>{children}</GuideContext.Provider>;
};
