import {Box, Typography} from '@mui/material';

export interface EmptyStateProps {
  heading: string;
  description: React.ReactNode;
  children?: React.ReactNode;
}

const styles = {
  root: {
    position: 'relative',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    textAlign: 'center',
    backgroundColor: 'background.surface',
    height: `100%`,
    boxSizing: 'border-box',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
  },
  description: {
    maxWidth: '50ch',
  },
};

export default function EmptyState({heading, description, children}: EmptyStateProps) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.text}>
        <Typography variant="h5" component="h2">
          {heading}
        </Typography>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>
      {children}
    </Box>
  );
}
