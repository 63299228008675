import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  List,
  Typography,
} from '@mui/material';
import {Fragment} from 'react';
import RowWithCheck from '../../../Utilities/RowWithCheck';
import RowWithClose from '../../../Utilities/RowWithClose';
import {type ItemType, type PlanDetails} from '../constants';

const styles = {
  container: {
    borderRadius: 1,
    p: 3,
    flex: 1,
  },
  summary: {
    p: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
  },

  formattedItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 1,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
  },
  tag: {
    px: '6px',
    py: '3px',
    fontSize: '0.5625rem',
    lineHeight: '120%',
    fontWeight: 'medium',
    letterSpacing: '0.45px',
  },
};

interface ItemProps {
  item: ItemType;
}

const Item = ({item}: ItemProps) => {
  const {item: mainItem, description, badge, disabled} = item;
  return (
    <Box sx={{...styles.formattedItem, color: disabled ? 'text.disabled' : undefined}}>
      <Box>
        <Typography>{mainItem}</Typography>
        {description && (
          <Typography variant="body2" color={disabled ? 'text.disabled' : 'secondary'}>
            {description}
          </Typography>
        )}
      </Box>
      {badge && (
        <Chip
          size="small"
          label={badge}
          sx={() => ({
            ...styles.tag,
            borderColor: badge === 'COMING SOON' ? 'grey.300' : 'primary.light',
            bgcolor: badge === 'COMING SOON' ? 'grey.200' : 'primary.lighter',
            color: badge === 'COMING SOON' ? 'text.secondary' : 'primary.main',
          })}
        />
      )}
    </Box>
  );
};

const PlanInfo = ({plan, allowCollapse}: {plan: PlanDetails; allowCollapse: boolean}) => (
  <Box pt={1}>
    {plan.details.map((section, index) =>
      allowCollapse ? (
        <Accordion
          key={index}
          sx={{border: 0, m: 0, p: 0}}
          TransitionProps={{unmountOnExit: true, mountOnEnter: true}}
          defaultExpanded={!section.defaultCollapsed}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box sx={styles.summary}>
              <Typography fontWeight="bold">{section.sectionName}</Typography>
              {section.badge ? (
                <Chip
                  size="small"
                  label={section.badge}
                  sx={() => ({
                    ...styles.tag,
                    borderColor: section.badge === 'COMING SOON' ? 'grey.300' : 'primary.light',
                    bgcolor: section.badge === 'COMING SOON' ? 'grey.200' : 'primary.lighter',
                    color: section.badge === 'COMING SOON' ? 'text.secondary' : 'primary.main',
                  })}
                />
              ) : null}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{py: 0}}>
            {section.items.map((item, sectionIndex) => {
              const isDisabled = item.disabled;
              const hasSubItem = typeof item.description === 'object';
              return (
                <Box key={sectionIndex}>
                  {!isDisabled ? (
                    <RowWithCheck
                      text={<Item item={item} />}
                      sx={{alignItems: hasSubItem ? 'flex-start' : 'center'}}
                    />
                  ) : (
                    <RowWithClose
                      text={<Item item={item} />}
                      sx={{alignItems: hasSubItem ? 'flex-start' : 'center'}}
                    />
                  )}
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Fragment key={index}>
          <Box mt={1} sx={styles.summary}>
            <Typography fontWeight="bold">{section.sectionName}</Typography>
            {section.badge ? (
              <Chip
                size="small"
                label={section.badge}
                sx={() => ({
                  ...styles.tag,
                  borderColor: section.badge === 'COMING SOON' ? 'grey.300' : 'primary.light',
                  bgcolor: section.badge === 'COMING SOON' ? 'grey.200' : 'primary.light',
                  color: section.badge === 'COMING SOON' ? 'text.secondary' : 'primary.main',
                })}
              />
            ) : null}
          </Box>

          <List>
            {section.items.map((item, sectionIndex) => {
              const isDisabled = item.disabled;
              const hasSubItem = typeof item.description === 'object';
              return (
                <Box component="li" key={sectionIndex}>
                  {!isDisabled ? (
                    <RowWithCheck
                      text={<Item item={item} />}
                      sx={{alignItems: hasSubItem ? 'flex-start' : 'center'}}
                    />
                  ) : (
                    <RowWithClose
                      text={<Item item={item} />}
                      sx={{alignItems: hasSubItem ? 'flex-start' : 'center'}}
                    />
                  )}
                </Box>
              );
            })}
          </List>
        </Fragment>
      ),
    )}
  </Box>
);

export default PlanInfo;
