import {Typography} from '@mui/material';
import {getRolesForPrincipal} from '../../../../../actions/rbacActions';
import {useControllerApi} from '../../../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {useRolesByPrincipalIdForProject} from '../../../../../selectors/rbac';
import {type ApiKey} from '../../../../../types';
import {RbacRoleDisplays, getFormValuesFromRoles} from '../../utils';

type ApiKeyPermissionsCellProps = {
  apiKey: ApiKey;
};

function ApiKeyPermissionsCell({apiKey}: ApiKeyPermissionsCellProps) {
  const project = useSelectedGlobalProject();
  useControllerApi(project, getRolesForPrincipal, {
    globalProjectId: project.id,
    principalId: apiKey.id,
  });
  const roles = useRolesByPrincipalIdForProject(apiKey.id, project.id);
  const formRoles = getFormValuesFromRoles(roles.map((role) => role.role));
  const baseRole = RbacRoleDisplays[formRoles.baseRole] || formRoles.baseRole;
  return <Typography>{baseRole}</Typography>;
}

export default ApiKeyPermissionsCell;
