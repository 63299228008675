import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import type * as React from 'react';
import {useForm} from 'react-hook-form';
import {type Roles} from '../constants';
import {useBooleanState} from '../hooks/utils';
import {useUserInfo} from '../selectors/users';
import ModalForm from './ModalForm';
import RoleOptions from './RoleOptions';

type EditUserButtonProps = {
  userId: string;
  userRole?: Roles;
  handleEditUser: (role: Roles, user: string) => void;
  label: string;
};

const EditUserButton: React.FC<EditUserButtonProps> = ({
  handleEditUser,
  userId,
  userRole,
  label,
}) => {
  const {val: showConfirm, setTrue: setShowModal, setFalse: hideModal} = useBooleanState();
  const {
    control,
    handleSubmit,
    reset,
    formState: {isValid},
  } = useForm<{role: Roles}>({
    defaultValues: {
      role: userRole,
    },
  });
  const doEdit = (formData: {role: Roles}) => {
    handleEditUser(formData.role, userId);
    hideModal();
  };
  const userData = useUserInfo(userId);
  const closeModal = () => {
    reset();
    hideModal();
  };
  return (
    <>
      <IconButton
        id={`edit-user-${userId}`}
        ariaLabel="edit-user"
        tooltip="Edit Role"
        border
        onClick={setShowModal}
        icon={<EditOutlinedIcon fontSize="small" />}
      />
      <ModalForm
        open={showConfirm}
        close={closeModal}
        title={`Edit ${userData?.name}'s Role`}
        isValid={isValid}
        onSubmit={handleSubmit((formData) => doEdit(formData))}
        submitText="Edit User"
        form={<RoleOptions control={control} label={`${label} Role`} name="role" />}
      />
    </>
  );
};

export default EditUserButton;
