import {Box, Typography} from '@mui/material';
import CodingLanguageSelector from '../../../../../../components/CodeSnippets/CodingLanguageSelector';
import {DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS} from './constants';

export const Section1Header = () => {
  return (
    <Box>
      <Typography mb={2}>
        This guide shows you how to set up and use a Pinecone vector database in minutes. Pinecone
        is a managed, cloud-native vector database with a streamlined API and no infrastructure
        hassles that provides long-term memory for high-performance AI applications.
      </Typography>
      <Typography>Select the SDK you would like to use to connect to Pinecone.</Typography>
    </Box>
  );
};

export const SelectPineconeSDK = () => {
  return (
    <Box>
      <CodingLanguageSelector
        languageOptions={DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS}
        inputComponent="SelectBoxInput"
      />
    </Box>
  );
};
