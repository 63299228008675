import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type FeedbackData} from '../components/app/MainLayout/AppBar/FeedbackModal/utilities/schema';
import {type ChurnData} from '../components/app-modals/ChurnModal/utils/schema';
import {type RegistrationData} from '../components/app-modals/RegistrationModal/utils/schema';
import {type SchemaType as RegionRequestData} from '../components/environment/RegionPicker/RegionRequestModal/utilities/schema';
import {type ModelSuggestionData} from '../pages/indexes/IndexCreationPage/SetupWithModelButton/ModelSuggestionForm/ModelSuggestionSchema';
import {type GlobalProject, type Organization, type User} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';

export const getUserRequest = createAction<undefined>('GET_USER_REQUEST');
export const getUserSuccess = createAction<User>('GET_USER_SUCCESS');
export const getUserFailure = createAction<User>('GET_USER_FAILURE');

export const registerUserRequest = createAction<undefined>('REGISTER_USER_REQUEST');
export const registerUserSuccess = createAction<{
  user: User;
  globalOrg?: Organization;
  project?: GlobalProject;
}>('REGISTER_USER_SUCCESS');
export const registerUserFailure = createAction<Error>('REGISTER_USER_FAILURE');

export const suggestModelRequest = createAction<undefined>('SUGGEST_MODEL_REQUEST');
export const suggestModelSuccess = createAction<undefined>('SUGGEST_MODEL_SUCCESS');
export const suggestModelFailure = createAction<Error>('SUGGEST_MODEL_FAILURE');

export const churnSurveyRequest = createAction<undefined>('CHURN_SURVEY_REQUEST');
export const churnSurveyFailure = createAction<Error>('CHURN_SURVEY_FAILURE');
export const churnSurveySuccess = createAction<undefined>('CHURN_SURVEY_SUCCESS');
export const clearChurnSurvey = createAction<undefined>('CLEAR_CHURN_SURVEY');

export const submitFeedbackRequest = createAction<undefined>('SUBMIT_FEEDBACK_REQUEST');
export const submitFeedbackSuccess = createAction<undefined>('SUBMIT_FEEDBACK_SUCCESS');
export const submitFeedbackFailure = createAction<Error>('SUBMIT_FEEDBACK_FAILURE');

export const requestRegionRequest = createAction<undefined>('REQUEST_REGION_REQUEST');
export const requestRegionFailure = createAction<Error>('REQUEST_REGIONFAILURE');
export const requestRegionSuccess = createAction<undefined>('REQUEST_REGIONSUCCESS');

export function getUserInfo(token: string) {
  return (dispatch: Dispatch) => {
    dispatch(getUserRequest());
    return new DashboardApi(token)
      .getUserInfo()
      .then((res) => {
        dispatch(getUserSuccess(res.user));
      })
      .catch((err) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong. ${err.message}`,
          }),
        );
        dispatch(getUserFailure(err));
      });
  };
}

export function registerUser(
  token: string,
  data: {
    orgId?: string;
    projectId?: string;
    registrationData: RegistrationData;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(registerUserRequest());
    return new DashboardApi(token)
      .registerUser(data.registrationData, data.orgId, data.projectId)
      .then((res) => {
        dispatch(
          registerUserSuccess({user: res.user, globalOrg: res.globalOrg, project: res.project}),
        );
      })
      .catch((err) => {
        dispatch(registerUserFailure(err));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong. ${err.message}`,
          }),
        );
      });
  };
}

// TODO(damargulis): Persist this?
export const patchUserParams = createAction<{params: Record<string, string>}>('PATCH_USER_PARAMS');

export function suggestModel(token: string, data: ModelSuggestionData) {
  return (dispatch: Dispatch) => {
    dispatch(suggestModelRequest());
    return new DashboardApi(token)
      .suggestModel(data)
      .then(() => {
        dispatch(suggestModelSuccess());
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Model suggestion submitted`,
          }),
        );
      })
      .catch((err) => {
        dispatch(suggestModelFailure(err));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong. ${err.message}`,
          }),
        );
      });
  };
}

export function submitChurnSurvey(
  token: string,
  data: {
    churnData: ChurnData;
    orgId?: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(churnSurveyRequest());
    return new DashboardApi(token)
      .submitChurnSurvey(data.churnData, data.orgId)
      .then(() => {
        dispatch(churnSurveySuccess());
      })
      .catch((err) => {
        dispatch(churnSurveyFailure(err));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong. ${err.message}`,
          }),
        );
      });
  };
}

export function submitFeedback(
  token: string,
  data: {
    feedback: FeedbackData;
    context: {orgId?: string; projectId?: string; location: string};
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(submitFeedbackRequest());
    return new DashboardApi(token)
      .submitFeedback(data.feedback, data.context)
      .then(() => {
        dispatch(submitFeedbackSuccess());
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Thanks for your feedback.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(submitFeedbackFailure(err));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong. ${err.message}`,
          }),
        );
      });
  };
}

export function requestRegion(
  token: string,
  data: {requestData: RegionRequestData; context: {projectId?: string; orgId?: string}},
) {
  return (dispatch: Dispatch) => {
    dispatch(requestRegionRequest());
    return new DashboardApi(token)
      .requestRegion(data.requestData, data.context)
      .then(() => {
        dispatch(requestRegionSuccess());
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Region request submitted.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(requestRegionFailure(err));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Region request failed to submit. ${err}`,
          }),
        );
      });
  };
}
