import {Box, Typography} from '@mui/material';
import {type RequestType} from '../../../hooks/chart';

const styles = {
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    p: 1,
    mx: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  key: {
    display: 'flex',
    alignItems: 'center',
    m: 1,
    textTransform: 'uppercase',
    color: 'text.secondary',
  },
  icon: {
    width: 12,
    height: 12,
    borderRadius: 1,
    m: 1,
  },
};
interface ChartLegendProps {
  keys: RequestType[];
}

const ChartLegend = ({keys}: ChartLegendProps) => {
  return (
    <Box sx={styles.root} data-testid="chart-legend">
      {keys.map((key) => (
        <Box key={key.name} sx={styles.key}>
          <Box sx={[styles.icon, {background: key.color}]} />
          <Typography variant="body2">{key.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};
export default ChartLegend;
