import {Box} from '@mui/material';
import {FormProvider} from 'react-hook-form';
import {type UpdateBillingDetailsData} from '../../../../actions/types/organizationActions.types';
import PaymentFormButtons from './PaymentFormButtons/PaymentFormButtons';
import PaymentFormFields from './PaymentFormFields/PaymentFormFields';
import PaymentProcessor from './PaymentProcessor/PaymentProcessor';
import StripeProvider from './StripeProvider/StripeProvider';
import {type UsePaymentFormReturn} from './usePaymentForm';

export type PaymentFormElementProps = {
  includeCancel: boolean;
  onSuccess: (billingDetailsData: UpdateBillingDetailsData) => void;
  show: boolean;
  submitText: string;
};

export type PaymentFormProps = UsePaymentFormReturn & PaymentFormElementProps;

function PaymentForm({
  cancel,
  defaultAddressOpts,
  formMethods,
  includeCancel,
  onSuccess,
  show,
  submitText,
}: PaymentFormProps) {
  // Stripe elements must be kept in the DOM while card processing is happening,
  // so showPaymentForm is used to control a display: none flag
  return (
    <Box sx={{display: show ? 'block' : 'none'}}>
      <FormProvider {...formMethods}>
        <StripeProvider>
          <PaymentProcessor onSuccess={onSuccess}>
            <PaymentFormFields defaultAddressOpts={defaultAddressOpts} />
            <PaymentFormButtons
              includeCancel={includeCancel}
              submitText={submitText}
              onCancel={cancel}
            />
          </PaymentProcessor>
        </StripeProvider>
      </FormProvider>
    </Box>
  );
}

export default PaymentForm;
