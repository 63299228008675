import {z} from 'zod';
import {StorageIntegrationProvider} from '../../../../../types';

const s3Schema = z.object({
  name: z.string().nonempty({message: 'Name must not be empty.'}),
  provider: z.literal(StorageIntegrationProvider.S3),
  aws_iam_role_arn: z.string().nonempty({message: 'Role ARN must not be empty.'}),
});

const gcsSchema = z.object({
  name: z.string().nonempty({message: 'Name must not be empty.'}),
  provider: z.literal(StorageIntegrationProvider.GCS),
  gcp_service_account_key_json: z
    .string()
    .nonempty({message: 'Service account key must not be empty.'}),
});

const azureBlobSchema = z.object({
  name: z.string().nonempty({message: 'Name must not be empty.'}),
  provider: z.literal(StorageIntegrationProvider.AzureBlob),
  azure_app_credentials_client_id: z.string().nonempty({message: 'Client ID must not be empty.'}),
  azure_app_credentials_client_secret: z
    .string()
    .nonempty({message: 'Client secret must not be empty.'}),
  azure_app_credentials_tenant_id: z.string().nonempty({message: 'Tenant ID must not be empty.'}),
});

export const schema = z.discriminatedUnion('provider', [s3Schema, gcsSchema, azureBlobSchema]);

export type StorageFormData = z.infer<typeof schema>;
