import {Box} from '@mui/material';
import AnimatedPineconeLogo from './AnimatedPineconeLogo/AnimatedPineconeLogo';

const styles = {
  container: {
    backgroundColor: 'background.default',
    width: '100vw',
    height: '100vh',
    display: 'grid',
    placeItems: 'center',
  },
};

function LoadingPage() {
  return (
    <Box sx={styles.container}>
      <AnimatedPineconeLogo />
    </Box>
  );
}

export default LoadingPage;
