import {createReducer} from '@reduxjs/toolkit';
import {toggleTheme} from '../actions/uiActions';
import {THEME, localStoragePrefix} from '../constants';

const localStorageKey = `${localStoragePrefix}-app-theme`;

const initialState = {
  theme: JSON.parse(localStorage.getItem(localStorageKey) as string) || THEME.LIGHT,
};

const uiReducer = createReducer(initialState, (builder) => {
  builder.addCase(toggleTheme, (state) => {
    state.theme = state.theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT;
    localStorage.setItem(localStorageKey, JSON.stringify(state.theme));
  });
});

export default uiReducer;
