import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {createApiKeyWithRoles} from '../../../../actions/apiKeyActions';
import ModalForm from '../../../../components/ModalForm';
import {API_KEY_LABEL_MAX_LENGTH, Plans} from '../../../../constants';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationPlan} from '../../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {
  ControlPlaneRoles,
  DataPlaneRoles,
  type ProjectRoles,
  type RbacRoles,
} from '../../../../types';
import {ALPHANUMERIC_HYPHENS_REGEX} from '../../../../utils/format';
import ApiKeyPermissionControl from '../ApiKeyPermissionControl/ApiKeyPermissionControl';
import {type BaseOptions, ProjectOptions, apiKeyPermissionsSchema} from '../types';
import {getRoles} from '../utils';

type CreateApiKeyFormProps = {
  open: boolean;
  close: () => void;
};

const labelSchema = z.object({
  label: z
    .string()
    .nonempty({message: 'Label must not be empty'})
    .max(API_KEY_LABEL_MAX_LENGTH, {
      message: `Label must not be longer than ${API_KEY_LABEL_MAX_LENGTH} characters`,
    })
    .regex(ALPHANUMERIC_HYPHENS_REGEX, {
      message: 'Label can only contain alpha-numeric characters and hyphens',
    }),
});

const schema = labelSchema.merge(apiKeyPermissionsSchema);

type SchemaType = z.infer<typeof schema>;

function CreateApiKeyForm({open, close}: CreateApiKeyFormProps) {
  const formState = useForm({
    mode: 'onChange',
    defaultValues: {
      label: '',
      baseRole: ProjectOptions[0] as ProjectRoles | BaseOptions,
      controlPlaneRole: ControlPlaneRoles.INDEX_RESOURCE_EDITOR,
      dataPlaneRole: DataPlaneRoles.INDEX_DATA_EDITOR,
    },
    resolver: zodResolver(schema),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: {isValid},
  } = formState;
  const project = useSelectedGlobalProject();
  const {setQueryData} = useDashboardApi<{
    label: string;
    roles: RbacRoles[];
    globalProjectId: string;
  }>();
  const handleCreate = (data: SchemaType) => {
    const roles = getRoles(data);
    setQueryData({
      action: createApiKeyWithRoles,
      data: {
        globalProjectId: project.id,
        label: data.label,
        roles,
      },
    });
    reset();
    close();
  };
  const plan = useSelectedOrganizationPlan();
  return (
    <ModalForm
      customId="create-api-key-form"
      title="Generate new secret key"
      isValid={isValid}
      onSubmit={handleSubmit(handleCreate)}
      submitText="Create key"
      open={open}
      close={close}
      form={
        <FormProvider {...formState}>
          <Box data-testid="create-api-key-inner-form" display="flex" flexDirection="column" pb={1}>
            <Typography pb={2}>
              {`Create a name for your API key and specify the key's permissions. Keep your keys secure and never share them publicly. Note: `}
              <Typography component="span" sx={{fontStyle: 'italic'}}>
                Custom permissions apply only to databases (indexes) and do not affect assistants or
                inference services.
              </Typography>
            </Typography>
            <TextInput
              name="label"
              control={control}
              required
              autoFocus
              placeholder="Ex. chatbot"
              label="API key name"
              fullWidth
            />
            <ApiKeyPermissionControl locked={plan === Plans.FREE} control={control} />
          </Box>
        </FormProvider>
      }
    />
  );
}

export default CreateApiKeyForm;
