import {type LanguageTypes} from '../../../../components/CodeSnippets/constants';
import {type GuideStatus} from './utilities/GuideContext';

export enum GuideId {
  DEVELOPER_QUICKSTART = 'developer-quickstart',
  INTERACTIVE_DEMO = 'vector-search-101',
}

export type BlockComponentProps = {
  handleNext: () => void;
  status: GuideStatus;
  blockTitle: string;
  sectionIndex: number;
  blockIndex: number;
};

export interface Block {
  index: number;
  title: string;
  component: React.ComponentType<BlockComponentProps>;
  hideNextButton?: boolean;
  supportedLanguages?: LanguageTypes[];
}

export interface Section {
  index: number;
  title: string;
  component?: React.ComponentType;
  blocks: Block[];
  supportedLanguages?: LanguageTypes[];
}

export interface Guide {
  id: GuideId;
  supportedLanguages?: LanguageTypes[];
  sections: Section[];
}
