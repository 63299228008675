import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Card} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {CreateIndexParam, Providers} from '../../../constants';
import {useNavigateToCreateIndexGlobal} from '../../../hooks/navigation';
import {useServerlessEnvironments} from '../../../selectors/environments';
import {useIsSelectedOrganizationSubscribed} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {indexSchema} from '../../indexes/IndexCreationPage/utilities/schema';

const styles = {
  card: {
    p: 3,
    borderRadius: 0.5,
    borderWidth: 1.5,
    backgroundColor: 'background.surface',
    borderColor: 'divider',
  },
  form: {
    display: 'flex',
    gap: 2,
    alignItems: 'end',
  },
  nameInput: {
    width: '60%',
  },
  providerInput: {
    width: '20%',
  },
  regionInput: {
    width: '20%',
  },
  submitButton: {
    width: '10%',
    pb: 2,
  },
  buttonContent: {
    height: '42px',
  },
};

const CreateIndexDeeplinkForm = () => {
  const orgId = useSelectedOrganizationId();
  const {force_encryption_with_cmek: projectIsCmekEncrypted} = useSelectedGlobalProject() || {};
  const serverlessEnvs = useServerlessEnvironments(orgId);
  const isSubscribed = useIsSelectedOrganizationSubscribed();

  const {control, setValue, watch} = useForm({
    resolver: zodResolver(indexSchema),
    defaultValues: {
      name: 'multilingual-e5-large',
      provider: Providers.AWS,
      region: 'us-east-1',
      dimensions: [1024],
    },
  });

  const {name, provider, region, dimensions} = watch();
  const params = {
    [CreateIndexParam.NAME]: name,
    [CreateIndexParam.CLOUD]: provider,
    [CreateIndexParam.REGION]: region,
    [CreateIndexParam.DIMENSIONS]: dimensions[0].toString(),
  };

  const selectedProvider = watch('provider');

  const providerOptions = useMemo(() => {
    if (!isSubscribed || projectIsCmekEncrypted) {
      return [{value: Providers.AWS, label: 'AWS'}];
    }
    return [
      {value: Providers.AWS, label: 'AWS'},
      {value: Providers.GCP, label: 'GCP'},
      {value: Providers.AZURE, label: 'Azure'},
    ];
  }, [isSubscribed, projectIsCmekEncrypted]);

  const regionOptions = useMemo(() => {
    if (!isSubscribed) {
      return [{value: 'us-east-1', label: 'us-east-1'}];
    }

    if (!Array.isArray(serverlessEnvs)) {
      return [];
    }

    return serverlessEnvs
      .filter((env) => env.provider.toLowerCase() === selectedProvider.toLowerCase())
      .map((env) => ({
        value: env.provider_region,
        label: env.provider_region,
      }));
  }, [serverlessEnvs, selectedProvider, isSubscribed]);

  useEffect(() => {
    // When provider changes, we set the first available region for that provider
    const availableRegions = regionOptions.map((option) => option.value);
    if (availableRegions.length > 0 && !availableRegions.includes(watch('region'))) {
      setValue('region', availableRegions[0] ?? '');
    }
  }, [selectedProvider, regionOptions, setValue, watch]);

  const navigateToCreateIndex = useNavigateToCreateIndexGlobal(orgId, '-', params);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      navigateToCreateIndex.go();
    },
    [navigateToCreateIndex],
  );

  return (
    <Card variant="outlined" sx={styles.card}>
      <form onSubmit={handleSubmit} aria-label="Create Index Form">
        <Box sx={styles.form}>
          <Box sx={styles.nameInput}>
            <TextInput
              name="name"
              control={control}
              label="Index name"
              placeholder="Enter index name"
              fullWidth
            />
          </Box>
          <Box sx={styles.providerInput}>
            <SelectInput
              name="provider"
              control={control}
              label="Cloud"
              options={providerOptions}
              fullWidth
              disabled={!isSubscribed}
            />
          </Box>
          <Box sx={styles.regionInput}>
            <SelectInput
              name="region"
              control={control}
              label="Region"
              options={regionOptions}
              fullWidth
              disabled={!isSubscribed}
            />
          </Box>
          <Box sx={styles.submitButton}>
            <Button sx={styles.buttonContent} type="submit" variant="contained" fullWidth>
              Create index
            </Button>
          </Box>
        </Box>
      </form>
    </Card>
  );
};

export default CreateIndexDeeplinkForm;
