import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';

interface DropAreaProps {
  hasFiles: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  importModalOpen: boolean;
  openImportModal: () => void;
}

const styles = {
  dropContainer: (hasFiles: boolean) => ({
    cursor: hasFiles ? 'default' : 'pointer',
    backgroundColor: 'background.surface',
    display: 'flex',
    placeItems: 'center',
    height: '100%',
    textAlign: 'center',
    p: 2,
  }),
  acceptDrop: {
    borderColor: 'primary.main',
  },
  rejectDrop: {
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
  importLink: {
    cursor: 'pointer',
  },
};

export default function DropArea({
  hasFiles,
  isDragAccept,
  isDragReject,
  importModalOpen,
  openImportModal,
}: DropAreaProps) {
  return (
    <Box flexGrow={hasFiles ? 0 : 1}>
      <Card
        sx={[
          styles.dropContainer(hasFiles),
          isDragAccept && !importModalOpen ? styles.acceptDrop : null,
          isDragReject && !importModalOpen ? styles.rejectDrop : null,
        ]}
      >
        <Box width="100%">
          {hasFiles ? (
            <Typography color="secondary">
              Drag files here or{' '}
              <Link onClick={openImportModal} sx={styles.importLink}>
                click to upload
              </Link>
            </Typography>
          ) : (
            <>
              <CloudUploadOutlinedIcon fontSize="large" color="secondary" />
              <Typography mb={1}>
                <strong>Drag files here or click to browse</strong>
              </Typography>
              <Typography color="secondary">
                Accepted file types: PDF, txt. Limit 10 files per upload.
              </Typography>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
}
