import {useMemo} from 'react';
import {useAppSelector as useSelector} from '../app/store';
import {Providers} from '../constants';
import {Flags, useFlag} from '../hooks/flags';

const emptyArr: never[] = [];

export const useOrganizationEnvironments = (orgId: string) => {
  return useSelector((state) => state.environments.organizations[orgId]?.data || emptyArr);
};

export const useV4FreeEnvironments = (orgId: string) => {
  const allEnvs = useOrganizationEnvironments(orgId);
  return useMemo(() => allEnvs.filter((env) => env.env_type === 'starter'), [allEnvs]);
};

export const useServerlessFreeEnvironments = (orgId: string) => {
  const allEnvs = useOrganizationEnvironments(orgId);
  return useMemo(
    () => allEnvs.filter((env) => env.env_type === 'serverless' && env.is_free_tier_eligible),
    [allEnvs],
  );
};

export const useSelectableFreeEnvironments = (orgId: string) => {
  const v4 = useV4FreeEnvironments(orgId);
  const serverless = useServerlessFreeEnvironments(orgId);

  return useMemo(() => [...serverless, ...v4], [v4, serverless]);
};

export const useIsV4FreeTier = (environment: string, orgId: string) => {
  const v4freeEnvironments = useV4FreeEnvironments(orgId);
  return !!v4freeEnvironments.find((env) => env.name === environment);
};

export const useIsServerlessFreeTier = (environment: string, orgId: string) => {
  const freeEnvs = useServerlessFreeEnvironments(orgId);

  return !!freeEnvs.find((env) => env.name === environment);
};

export const useIsFreeEnvironment = (environment: string, orgId: string) => {
  const isV4 = useIsV4FreeTier(environment, orgId);
  const isServerlessFreeTier = useIsServerlessFreeTier(environment, orgId);

  return isV4 || isServerlessFreeTier;
};

export const useV3PaidEnvironments = (orgId: string) => {
  const allEnvs = useOrganizationEnvironments(orgId);
  return useMemo(() => allEnvs.filter((env) => env.env_type === 'pod'), [allEnvs]);
};

export const useServerlessPaidEnvironments = (orgId: string) => {
  const shouldShowGcpServerless = useFlag(Flags.SHOW_GCP_SERVERLESS);
  const allEnvs = useOrganizationEnvironments(orgId);
  const environments = useMemo(
    () => allEnvs.filter((env) => env.env_type === 'serverless' && !env.is_free_tier_eligible),
    [allEnvs],
  );

  return useMemo(() => {
    return environments.filter((env) => {
      if (env.provider === Providers.GCP) {
        return shouldShowGcpServerless;
      }
      return true;
    });
  }, [environments, shouldShowGcpServerless]);
};

export const useServerlessEnvironments = (orgId: string) => {
  const freeEnvs = useServerlessFreeEnvironments(orgId);
  const paidEnvs = useServerlessPaidEnvironments(orgId);
  return [...freeEnvs, ...paidEnvs];
};

export const useEnvironmentInfo = (environment: string, orgId: string) => {
  const envs = useOrganizationEnvironments(orgId);
  return envs.find((env) => env.name === environment);
};
