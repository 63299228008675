import {Box, Button, Divider, Link, Typography} from '@mui/material';
import {useNavigateToSelectProject, useNavigateToSupportPlans} from '../../hooks/navigation';
import {useSelectedOrganizationId} from '../../selectors/params';
import {LinkSection} from './LinkSection';

export const NoAccessMessage = () => {
  const selectedOrgId = useSelectedOrganizationId();
  const {go: navigateToSupportPlans} = useNavigateToSupportPlans(selectedOrgId);

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', padding: 2}}>
      <Typography variant="h5" sx={{marginTop: 8}}>
        No access to prioritized assistance
      </Typography>
      <Typography variant="body1" sx={{marginTop: 3}}>
        You do not have access to prioritized assistance with your current plan.
      </Typography>
      <Typography sx={{mt: 2}}>
        <Link onClick={navigateToSupportPlans} style={{cursor: 'pointer'}}>
          Upgrade
        </Link>
        {' your support plan for prioritized assistance'}
      </Typography>
      <Divider sx={{width: '100%', my: 8}} />
      <LinkSection orientation="horizontal" />
    </Box>
  );
};

export const SubmissionSuccessMessage = () => {
  const orgId = useSelectedOrganizationId();
  const navigateToProjects = useNavigateToSelectProject(orgId);

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', padding: 2}}>
      <Typography variant="h5" sx={{marginTop: 8}}>
        Thank you for your submission
      </Typography>
      <Typography variant="body1" sx={{marginTop: 3, marginBottom: 3}}>
        Your request has been received, and our support team will review it shortly. You can expect
        a response within the{' '}
        <Link
          href="https://docs.pinecone.io/troubleshooting/pinecone-support-slas"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pinecone Support SLAs
        </Link>{' '}
        specified for your organizational tier.
      </Typography>
      <Typography variant="body1">
        In the meantime, feel free to continue working on your projects.
      </Typography>
      <Button
        sx={{marginTop: 3, maxWidth: 150}}
        onClick={() => navigateToProjects.go()}
        variant="contained"
      >
        Go to my projects
      </Button>
      <Divider sx={{width: '100%', my: 8}} />
      <LinkSection orientation="horizontal" />
    </Box>
  );
};
