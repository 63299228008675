import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {
  type CreateStorageIntegrationRequest,
  type UpdateStorageIntegrationRequest,
} from '../api/types/controllerApi';
import {type StorageIntegration} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const listStorageIntegrationsRequest = createAction<{
  globalProjectId: string;
}>('LIST_STORAGE_INTEGRATIONS_REQUEST');
export const listStorageIntegrationsSuccess = createAction<{
  globalProjectId: string;
  data: StorageIntegration[];
}>('LIST_STORAGE_INTEGRATIONS_SUCCESS');
export const listStorageIntegrationsFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('LIST_STORAGE_INTEGRATIONS_FAILURE');

export function listStorageIntegrations(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listStorageIntegrationsRequest({globalProjectId: data.globalProjectId}));
    return api
      .listStorageIntegrations()
      .then((response) => {
        dispatch(
          listStorageIntegrationsSuccess({
            globalProjectId: data.globalProjectId,
            data: response.data,
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to list storage integrations. ${message}`,
          }),
        );
        dispatch(
          listStorageIntegrationsFailure({
            globalProjectId: data.globalProjectId,
            error: message,
          }),
        );
      });
  };
}

export const createStorageIntegrationRequest = createAction<{
  globalProjectId: string;
}>('CREATE_STORAGE_INTEGRATION_REQUEST');
export const createStorageIntegrationSuccess = createAction<{
  globalProjectId: string;
  integration: StorageIntegration;
}>('CREATE_STORAGE_INTEGRATION_SUCCESS');
export const createStorageIntegrationFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('CREATE_STORAGE_INTEGRATION_FAILURE');

export function createStorageIntegration(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    data: CreateStorageIntegrationRequest;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createStorageIntegrationRequest({globalProjectId: data.globalProjectId}));
    return api
      .createStorageIntegration(data.data)
      .then((response) => {
        dispatch(
          createStorageIntegrationSuccess({
            globalProjectId: data.globalProjectId,
            integration: response,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Storage integration created successfully.',
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to create storage integration. ${message}`,
          }),
        );
        dispatch(
          createStorageIntegrationFailure({
            globalProjectId: data.globalProjectId,
            error: message,
          }),
        );
      });
  };
}

export const updateStorageIntegrationRequest = createAction<{
  globalProjectId: string;
  integrationId: string;
}>('UPDATE_STORAGE_INTEGRATION_REQUEST');
export const updateStorageIntegrationSuccess = createAction<{
  globalProjectId: string;
  integration: StorageIntegration;
}>('UPDATE_STORAGE_INTEGRATION_SUCCESS');
export const updateStorageIntegrationFailure = createAction<{
  globalProjectId: string;
  integrationId: string;
  error: string;
}>('UPDATE_STORAGE_INTEGRATION_FAILURE');

export function updateStorageIntegration(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    integrationId: string;
    data: UpdateStorageIntegrationRequest;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(
      updateStorageIntegrationRequest({
        globalProjectId: data.globalProjectId,
        integrationId: data.integrationId,
      }),
    );
    return api
      .updateStorageIntegration(data.integrationId, data.data)
      .then((response) => {
        dispatch(
          updateStorageIntegrationSuccess({
            globalProjectId: data.globalProjectId,
            integration: response,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Storage integration updated successfully.',
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to update storage integration. ${message}`,
          }),
        );
        dispatch(
          updateStorageIntegrationFailure({
            globalProjectId: data.globalProjectId,
            integrationId: data.integrationId,
            error: message,
          }),
        );
      });
  };
}

export const validateStorageIntegrationSuccess = createAction<{
  globalProjectId: string;
  integration: StorageIntegration;
}>('VALIDATE_STORAGE_INTEGRATION_SUCCESS');

export function validateStorageIntegration(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    integrationId: string;
  },
) {
  return (dispatch: Dispatch) => {
    return api
      .validateStorageIntegration(data.integrationId)
      .then((response) => {
        dispatch(
          validateStorageIntegrationSuccess({
            globalProjectId: data.globalProjectId,
            integration: response,
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to validate storage integration. ${message}`,
          }),
        );
      });
  };
}

export const deleteStorageIntegrationRequest = createAction<{
  globalProjectId: string;
  integrationId: string;
}>('DELETE_STORAGE_INTEGRATION_REQUEST');
export const deleteStorageIntegrationSuccess = createAction<{
  globalProjectId: string;
  integrationId: string;
}>('DELETE_STORAGE_INTEGRATION_SUCCESS');
export const deleteStorageIntegrationFailure = createAction<{
  globalProjectId: string;
  integrationId: string;
  error: string;
}>('DELETE_STORAGE_INTEGRATION_FAILURE');

export function deleteStorageIntegration(
  api: ControllerApi,
  data: {globalProjectId: string; integrationId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      deleteStorageIntegrationRequest({
        globalProjectId: data.globalProjectId,
        integrationId: data.integrationId,
      }),
    );
    return api
      .deleteStorageIntegration(data.integrationId)
      .then(() => {
        dispatch(
          deleteStorageIntegrationSuccess({
            globalProjectId: data.globalProjectId,
            integrationId: data.integrationId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Storage integration deleted successfully.',
          }),
        );
      })
      .catch((error) => {
        const message = decodeErrorMessage(error.message);
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Unable to delete storage integration. ${message}`,
          }),
        );
        dispatch(
          deleteStorageIntegrationFailure({
            globalProjectId: data.globalProjectId,
            integrationId: data.integrationId,
            error: message,
          }),
        );
      });
  };
}
