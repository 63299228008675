import {Box, Button, Link, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import {Link as RouterLink} from 'react-router-dom';
import {useIsUpgradeLoading} from '../../../selectors/billing';
import RowWithCheck from '../../Utilities/RowWithCheck';

type MigrationDetailsProps = {
  onSubmit: () => void;
  variant?: 'normal' | 'alt';
};

function MigrationDetails({onSubmit, variant = 'normal'}: MigrationDetailsProps) {
  const upgradeLoading = useIsUpgradeLoading();
  return (
    <>
      <Box>
        {variant === 'normal' && (
          <>
            <RowWithCheck text="Serverless indexes scale automatically based on usage." />
            <RowWithCheck text="No pods to provision or manage as you scale" />
          </>
        )}
        {variant === 'alt' && (
          <>
            <RowWithCheck text="Serverless indexes can be scaled at any time with no data downloads, downtime or code changes" />
            <RowWithCheck text="No need to provision and manage resources." />
          </>
        )}
      </Box>
      <Alert
        severity="warning"
        icon={false}
        description={
          <Typography>
            This process takes around five minutes. After converting your index, you may need to
            upgrade and reinitialize your client to access your index. See{' '}
            <Link
              component={RouterLink}
              to="https://docs.pinecone.io/guides/indexes/convert-a-starter-index-to-serverless"
              target="_blank"
            >
              docs
            </Link>{' '}
            for more guidance.
          </Typography>
        }
      />
      <Button
        disabled={upgradeLoading}
        onClick={onSubmit}
        fullWidth
        variant="contained"
        sx={{mb: 2, mt: 3}}
      >
        Start conversion
      </Button>
    </>
  );
}

export default MigrationDetails;
