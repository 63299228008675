import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {deleteProject} from '../../../actions/dashboardActions';
import {Roles} from '../../../constants';
import {useDashboardApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {useProjectRole} from '../../../selectors/projects';
import {useUserId} from '../../../selectors/userSession';
import {type GlobalProject} from '../../../types';
import {TRACKING_EVENTS, sendToGa} from '../../../utils/tracking';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import ConfigureProjectForm from './ConfigureProjectForm/ConfigureProjectForm';

interface ProjectActionsProps {
  project: GlobalProject;
  renderAsMenu?: boolean;
}

function ProjectActions({project, renderAsMenu = false}: ProjectActionsProps) {
  const userId = useUserId();
  const disableEdit = useProjectRole(userId || '', project?.id) !== Roles.OWNER;
  const {val: showDelete, setTrue: setShowDelete, setFalse: setHideDelete} = useBooleanState();
  const {val: showEdit, setTrue: setShowEdit, setFalse: setHideEdit} = useBooleanState();
  const {setQueryData} = useDashboardApi<{project: GlobalProject}>();
  const onDeleteProject = () => {
    setQueryData({
      action: deleteProject,
      data: {
        project,
      },
    });
    sendToGa(TRACKING_EVENTS.DELETE_PROJECT);
  };

  if (disableEdit && !renderAsMenu) {
    return null;
  }

  return (
    <>
      {renderAsMenu ? (
        <SelectorMenuButton
          tooltip={disableEdit ? 'Only available to project owners' : undefined}
          iconSx={{m: -1}}
          disabled={disableEdit}
          id={`${project.id}-actions-menu`}
          placement="left-start"
          items={[
            {
              id: `${project.id}-actions-configure`,
              name: 'Configure',
              icon: <SettingsOutlinedIcon fontSize="small" />,
              onClick: setShowEdit,
            },
            {
              id: 'delete',
              name: 'Delete',
              icon: <DeleteOutlinedIcon fontSize="small" />,
              onClick: setShowDelete,
            },
          ]}
        />
      ) : (
        <>
          <IconButton
            id="configure-project"
            tooltip="Configure Project"
            onClick={setShowEdit}
            dataTestId="configure-project"
            icon={<SettingsOutlinedIcon fontSize="small" />}
            border
          />
          <IconButton
            id="delete-project"
            tooltip="Delete Project"
            dataTestId="delete-project"
            onClick={setShowDelete}
            icon={<DeleteOutlinedIcon fontSize="small" />}
            border
          />
        </>
      )}
      <DeleteConfirmationModal
        open={showDelete}
        close={setHideDelete}
        itemType="Project"
        name={project.name}
        doDelete={onDeleteProject}
        warningText="Before you can delete a project, you must first delete all associated indexes and collections within it."
      />
      <ConfigureProjectForm open={showEdit} close={setHideEdit} project={project} />
    </>
  );
}

export default ProjectActions;
