export const HOUR_FORMAT = 'h:mm aa';
export const DAY_FORMAT = 'MMM dd';
export const EXTENDED_DAY_FORMAT = 'MMM dd, yyyy';

export const TIME_INTERVAL = Object.freeze({
  '15m': '15m',
  '4hrs': '4hrs',
  '12hrs': '12hrs',
  '1d': '1d',
  '2d': '2d',
  '1w': '1w',
});

export const TIME_INTERVAL_STEP_MAP: Record<TimeInterval, string> = {
  '15m': '60000', // 1 minute
  '4hrs': '60000', // 1 minute
  '12hrs': '300000', // 5 minutes
  '1d': '300000', // 5 minutes
  '2d': '300000', // 5 minutes
  '1w': '3600000', // 1 hour
};

export const TIME_INTERVAL_TICK_STEP_MAP: Record<TimeInterval, number | undefined> = {
  '15m': undefined,
  '4hrs': 5,
  '12hrs': 5,
  '1d': 60,
  '2d': 60,
  '1w': 1440,
};

export type TimeInterval = (typeof TIME_INTERVAL)[keyof typeof TIME_INTERVAL];
