import {Box, Link, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';

function ErrorPage() {
  return (
    <Box p={3}>
      <Alert
        severity="error"
        title="We have experienced an unexpected error."
        description={
          <Typography>
            Please refresh the page, or contact{' '}
            <Link href="mailto:support@pinecone.io" target="_blank">
              support@pinecone.io
            </Link>{' '}
            if the problem persists.
          </Typography>
        }
      />
    </Box>
  );
}

export default ErrorPage;
