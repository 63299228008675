import {Box, type BoxProps, Button} from '@mui/material';
import * as React from 'react';
import Tooltip from '../Tooltip';
import {type CommonButtonProps} from './Utils/types';

const styles = {
  root: {
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderColor: 'divider',
  },
};

export interface IconButtonProps extends CommonButtonProps {
  icon: React.ReactElement;
  tooltip?: string;
  shape?: 'circle' | 'squircle';
  border?: boolean;
  sx?: Required<BoxProps['sx']>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      id,
      color,
      onClick,
      icon,
      tooltip,
      shape = 'squircle',
      border,
      disabled,
      href,
      dataHeap,
      ariaLabel,
      ariaControls,
      ariaExpanded,
      ariaHaspopup,
      dataTestId,
      tabIndex,
      sx = {},
    },
    ref,
  ) => {
    const handleClick = (evt: React.MouseEvent) => {
      evt.stopPropagation();
      if (onClick) {
        onClick(evt);
      }
    };

    return (
      <Box component="span" m={1} sx={sx}>
        <Tooltip title={tooltip}>
          <Button
            id={id}
            ref={ref}
            tabIndex={tabIndex}
            variant={border ? 'outlined' : 'text'}
            color={color}
            sx={[
              styles.root,
              {borderRadius: shape === 'squircle' ? 2 : '100%'},
              {fill: disabled ? 'action.disabled' : ''},
            ]}
            onClick={handleClick}
            href={href}
            disabled={disabled}
            data-testid={dataTestId || id}
            data-heap={dataHeap}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            aria-haspopup={ariaHaspopup}
          >
            {icon}
          </Button>
        </Tooltip>
      </Box>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
