import LoadingCard from '@pinecone-experience/timber/Cards/LoadingCard';

function PaymentLoadingCard({show}: {show: boolean}) {
  const title = 'Almost there';
  const text =
    "We're verifying your payment details. We'll get you started on your new project as soon as this step is complete.";
  return <LoadingCard show={show} title={title} text={text} />;
}

export default PaymentLoadingCard;
