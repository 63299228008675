import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/cmekKeysActions';
import {type CmekKey} from '../types';
import {type AsyncDataState, initAsyncDataState, isGlobalProjectAction} from './utils';

interface CmekKeysState {
  projects: {
    [projectId: string]: AsyncDataState<Record<string, CmekKey>>;
  };
}

const initialState: CmekKeysState = {
  projects: {},
};

const initialProjectState = initAsyncDataState<Record<string, CmekKey>>();

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.listCmekKeysRequest, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(actions.listCmekKeysSuccess, (state, action) => {
      const keys: Record<string, CmekKey> = {};
      action.payload.data.forEach((key) => {
        keys[key.cmek_spec.id] = key.cmek_spec;
      });

      state.data = keys;
      state.loading = false;
    })
    .addCase(actions.listCmekKeysFailure, (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    })
    .addCase(actions.createCmekKeyRequest, (state) => {
      state.error = undefined;
      state.loading = true;
    })
    .addCase(actions.createCmekKeySuccess, (state, action) => {
      const data = state.data || {};
      data[action.payload.key.id] = action.payload.key;

      state.data = data;
      state.error = undefined;
      state.loading = false;
    })
    .addCase(actions.createCmekKeyFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    })
    .addCase(actions.deleteCmekKeyRequest, (state) => {
      state.error = undefined;
      state.loading = true;
    })
    .addCase(actions.deleteCmekKeySuccess, (state, action) => {
      const data = state.data || {};
      delete data[action.payload.cmekId];

      state.data = data;
      state.error = undefined;
      state.loading = false;
    })
    .addCase(actions.deleteCmekKeyFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});

const cmekKeysReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default cmekKeysReducer;
