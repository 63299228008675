import {useAppSelector as useSelector} from '../app/store';

const emptyMap = {};

export const useCollectionsByGlobalProjectId = (projectId: string) => {
  return useSelector((state) => state.collections.projects[projectId]?.items);
};

export const useCollectionsAreLoadingByGlobalProjectId = (projectId: string) => {
  return useSelector((state) => state.collections.projects[projectId]?.loading ?? true);
};

export const useAllCollectionStatusesByGlobalProjectId = (projectId: string) => {
  return (
    useSelector((state) => state.collections.projects[projectId]?.collectionStatus) || emptyMap
  );
};

export const useCollectionStatusByGlobalProjectId = (projectId: string, name: string) => {
  return useAllCollectionStatusesByGlobalProjectId(projectId)[name];
};
