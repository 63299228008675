import {getBackup} from '../../../../../actions/backupActions';
import StatusBox from '../../../../../components/StatusBox';
import {IndexState, POLLING_INTERVAL} from '../../../../../constants';
import {useIndexStatusColorMap} from '../../../../../hooks/colorMaps';
import {useControllerApi} from '../../../../../hooks/use-api';
import {useInterval} from '../../../../../hooks/utils';
import {type Backup, type GlobalProject} from '../../../../../types';

type BackupStatusBoxProps = {
  backup: Backup;
  project: GlobalProject;
};

function BackupStatusBox({backup, project}: BackupStatusBoxProps) {
  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    backupId: string;
  }>(project);
  useInterval(
    () => {
      setQueryData({
        action: getBackup,
        data: {
          backupId: backup.id,
          globalProjectId: project?.id,
        },
      });
    },
    backup?.status !== IndexState.READY ? POLLING_INTERVAL : null,
  );
  const color = useIndexStatusColorMap(backup?.status || IndexState.UNKNOWN);
  return <StatusBox statusText={backup?.status} color={color} sx={{ml: 2}} />;
}

export default BackupStatusBox;
