import {Box, Paper, Typography} from '@mui/material';
import {format} from 'date-fns/format';

const styles = {
  container: {
    p: 2,
    minWidth: 200,
    boxShadow: 2,
    zIndex: 50,
  },
  colorTag: {
    display: 'inline-block',
    borderRadius: '4px',
    height: 25,
    width: 5,
    mr: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
};

interface ChartTooltipProps {
  timestamp: Date;
  items: {
    color: string;
    label: string;
    value: string | number;
    unit: string;
  }[];
}

function ChartTooltip({timestamp, items}: ChartTooltipProps) {
  return (
    <Paper sx={styles.container}>
      <Typography variant="overline" color="text.secondary">
        {format(timestamp, 'M/d/yy')}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {format(timestamp, 'h:mm:ss aa')}
      </Typography>
      <Box display="flex" flexDirection="column">
        {items.map((row) => {
          return (
            <Box key={row.label} display="flex" justifyContent="space-between" alignItems="center">
              <Box sx={styles.row}>
                <Box sx={[styles.colorTag, {backgroundColor: row.color}]} />
                <Typography variant="body2" fontWeight="bold" display="inline-block" noWrap>
                  {row.label.toUpperCase()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography ml={2} variant="h6">
                  {Number(row.value).toLocaleString()}
                </Typography>
                <Typography ml={0.5} variant="overline" color="text.secondary">
                  {row.unit.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
}

export default ChartTooltip;
