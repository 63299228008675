import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/prometheusActions';
import {
  type PrometheusLatencyResponse,
  type PrometheusLegacyResponse,
  type PrometheusTimestampedResponse,
} from '../types';
import {type AsyncDataState, initAsyncDataState} from './utils';

type MetricsState = {
  latency: Record<string, AsyncDataState<PrometheusLatencyResponse>>;
  writeUnitsCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  readUnitsCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  vectorCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  requestCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  errorCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  podFullness: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  heartbeat: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  storageUnitsCount: Record<string, AsyncDataState<PrometheusLegacyResponse>>;
  // Metrics Revamp
  writeUnitsTotal: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  readUnitsTotal: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  recordTotal: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  storageSize: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  requestDuration: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  requestTotal: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  requestStatus: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
  podFullnessPercentage: Record<string, AsyncDataState<PrometheusTimestampedResponse>>;
};

const initialState = {
  latency: {},
  vectorCount: {},
  requestCount: {},
  errorCount: {},
  podFullness: {},
  readUnitsCount: {},
  heartbeat: {},
  writeUnitsCount: {},
  storageUnitsCount: {},
  // Metrics Revamp
  writeUnitsTotal: {},
  readUnitsTotal: {},
  recordTotal: {},
  storageSize: {},
  requestDuration: {},
  requestTotal: {},
  requestStatus: {},
  podFullnessPercentage: {},
} as MetricsState;

const metricReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.errorCountQueryRequest, (state, action) => {
      state.errorCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.errorCountQuerySuccess, (state, action) => {
      state.errorCount[action.payload.service].data = action.payload.data;
      state.errorCount[action.payload.service].loading = false;
    })
    .addCase(actions.errorCountQueryFailure, (state, action) => {
      state.errorCount[action.payload.service].data = undefined;
      state.errorCount[action.payload.service].error = action.payload.error.message;
      state.errorCount[action.payload.service].loading = false;
    })
    .addCase(actions.requestCountQueryRequest, (state, action) => {
      state.requestCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.readUnitsCountQueryRequest, (state, action) => {
      state.readUnitsCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.storageUnitsCountQueryRequest, (state, action) => {
      state.storageUnitsCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.requestCountQuerySuccess, (state, action) => {
      state.requestCount[action.payload.service].data = action.payload.data;
      state.requestCount[action.payload.service].loading = false;
    })
    .addCase(actions.requestCountQueryFailure, (state, action) => {
      state.requestCount[action.payload.service].data = undefined;
      state.requestCount[action.payload.service].error = action.payload.error.message;
      state.requestCount[action.payload.service].loading = false;
    })
    .addCase(actions.readUnitsCountQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.readUnitsCount[service].data = data;
      state.readUnitsCount[service].loading = false;
    })
    .addCase(actions.readUnitsCountQueryFailure, (state, action) => {
      state.readUnitsCount[action.payload.service].data = undefined;
      state.readUnitsCount[action.payload.service].error = action.payload.error.message;
      state.readUnitsCount[action.payload.service].loading = false;
    })
    .addCase(actions.storageUnitsCountQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.storageUnitsCount[service].data = data;
      state.storageUnitsCount[service].loading = false;
    })
    .addCase(actions.storageUnitsCountQueryFailure, (state, action) => {
      state.storageUnitsCount[action.payload.service].data = undefined;
      state.storageUnitsCount[action.payload.service].error = action.payload.error.message;
      state.storageUnitsCount[action.payload.service].loading = false;
    })
    .addCase(actions.writeUnitsCountQueryRequest, (state, action) => {
      state.writeUnitsCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.writeUnitsCountQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.writeUnitsCount[service].data = data;
      state.writeUnitsCount[service].loading = false;
    })
    .addCase(actions.writeUnitsCountQueryFailure, (state, action) => {
      state.writeUnitsCount[action.payload.service].data = undefined;
      state.writeUnitsCount[action.payload.service].error = action.payload.error.message;
      state.writeUnitsCount[action.payload.service].loading = false;
    })
    .addCase(actions.vectorCountQueryRequest, (state, action) => {
      state.vectorCount[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.vectorCountQuerySuccess, (state, action) => {
      state.vectorCount[action.payload.service].data = action.payload.data;
      state.vectorCount[action.payload.service].loading = false;
    })
    .addCase(actions.vectorCountQueryFailure, (state, action) => {
      state.vectorCount[action.payload.service].data = undefined;
      state.vectorCount[action.payload.service].error = action.payload.error.message;
      state.vectorCount[action.payload.service].loading = false;
    })
    .addCase(actions.heartbeatQueryRequest, (state, action) => {
      state.heartbeat[action.payload] = {
        ...state.heartbeat[action.payload],
        loading: true,
      };
    })
    .addCase(actions.heartbeatQuerySuccess, (state, action) => {
      state.heartbeat[action.payload.service].data = action.payload.data;
      state.heartbeat[action.payload.service].loading = false;
    })
    .addCase(actions.heartbeatQueryFailure, (state, action) => {
      state.heartbeat[action.payload.service].data = undefined;
      state.heartbeat[action.payload.service].error = action.payload.error.message;
      state.heartbeat[action.payload.service].loading = false;
    })
    .addCase(actions.latencyQueryRequest, (state, action) => {
      state.latency[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.latencyQuerySuccess, (state, action) => {
      state.latency[action.payload.service].data = action.payload.data;
      state.latency[action.payload.service].loading = false;
    })
    .addCase(actions.latencyQueryFailure, (state, action) => {
      state.latency[action.payload.service].data = undefined;
      state.latency[action.payload.service].error = action.payload.error.message;
      state.latency[action.payload.service].loading = false;
    })
    .addCase(actions.podFullnessQueryRequest, (state, action) => {
      state.podFullness[action.payload] = initAsyncDataState([], true);
    })
    .addCase(actions.podFullnessQuerySuccess, (state, action) => {
      state.podFullness[action.payload.service].data = action.payload.data;
      state.podFullness[action.payload.service].loading = false;
    })
    .addCase(actions.podFullnessQueryFailure, (state, action) => {
      state.podFullness[action.payload.service].data = undefined;
      state.podFullness[action.payload.service].error = action.payload.error.message;
      state.podFullness[action.payload.service].loading = false;
    })
    // Metrics Revamp
    .addCase(actions.readUnitsTotalQueryRequest, (state, action) => {
      state.readUnitsTotal[action.payload] = initAsyncDataState(
        state.readUnitsTotal[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.readUnitsTotalQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.readUnitsTotal[service].data = data;
      state.readUnitsTotal[service].loading = false;
    })
    .addCase(actions.readUnitsTotalQueryFailure, (state, action) => {
      state.readUnitsTotal[action.payload.service].data = undefined;
      state.readUnitsTotal[action.payload.service].error = action.payload.errorMessage;
      state.readUnitsTotal[action.payload.service].loading = false;
    })
    .addCase(actions.writeUnitsTotalQueryRequest, (state, action) => {
      state.writeUnitsTotal[action.payload] = initAsyncDataState(
        state.writeUnitsTotal[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.writeUnitsTotalQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.writeUnitsTotal[service].data = data;
      state.writeUnitsTotal[service].loading = false;
    })
    .addCase(actions.writeUnitsTotalQueryFailure, (state, action) => {
      state.writeUnitsTotal[action.payload.service].data = undefined;
      state.writeUnitsTotal[action.payload.service].error = action.payload.errorMessage;
      state.writeUnitsTotal[action.payload.service].loading = false;
    })
    .addCase(actions.recordTotalQueryRequest, (state, action) => {
      state.recordTotal[action.payload] = initAsyncDataState(
        state.recordTotal[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.recordTotalQuerySuccess, (state, action) => {
      state.recordTotal[action.payload.service].data = action.payload.data;
      state.recordTotal[action.payload.service].loading = false;
    })
    .addCase(actions.recordTotalQueryFailure, (state, action) => {
      state.recordTotal[action.payload.service].data = undefined;
      state.recordTotal[action.payload.service].error = action.payload.errorMessage;
      state.recordTotal[action.payload.service].loading = false;
    })
    .addCase(actions.storageSizeQueryRequest, (state, action) => {
      state.storageSize[action.payload] = initAsyncDataState(
        state.storageSize[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.storageSizeQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.storageSize[service].data = data;
      state.storageSize[service].loading = false;
    })
    .addCase(actions.storageSizeQueryFailure, (state, action) => {
      state.storageSize[action.payload.service].data = undefined;
      state.storageSize[action.payload.service].error = action.payload.errorMessage;
      state.storageSize[action.payload.service].loading = false;
    })
    .addCase(actions.requestDurationQueryRequest, (state, action) => {
      state.requestDuration[action.payload] = initAsyncDataState(
        state.requestDuration[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.requestDurationQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.requestDuration[service].data = data;
      state.requestDuration[service].loading = false;
    })
    .addCase(actions.requestDurationQueryFailure, (state, action) => {
      state.requestDuration[action.payload.service].data = undefined;
      state.requestDuration[action.payload.service].error = action.payload.errorMessage;
      state.requestDuration[action.payload.service].loading = false;
    })
    .addCase(actions.requestTotalQueryRequest, (state, action) => {
      state.requestTotal[action.payload] = initAsyncDataState(
        state.requestTotal[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.requestTotalQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.requestTotal[service].data = data;
      state.requestTotal[service].loading = false;
    })
    .addCase(actions.requestTotalQueryFailure, (state, action) => {
      state.requestTotal[action.payload.service].data = undefined;
      state.requestTotal[action.payload.service].error = action.payload.errorMessage;
      state.requestTotal[action.payload.service].loading = false;
    })
    .addCase(actions.requestStatusQueryRequest, (state, action) => {
      state.requestStatus[action.payload] = initAsyncDataState(
        state.requestStatus[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.requestStatusQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.requestStatus[service].data = data;
      state.requestStatus[service].loading = false;
    })
    .addCase(actions.requestStatusQueryFailure, (state, action) => {
      state.requestStatus[action.payload.service].data = undefined;
      state.requestStatus[action.payload.service].error = action.payload.errorMessage;
      state.requestStatus[action.payload.service].loading = false;
    })
    .addCase(actions.podFullnessPercentageQueryRequest, (state, action) => {
      state.podFullnessPercentage[action.payload] = initAsyncDataState(
        state.podFullnessPercentage[action.payload]?.data || {maxValue: null, data: []},
        true,
      );
    })
    .addCase(actions.podFullnessPercentageQuerySuccess, (state, action) => {
      const {data, service} = action.payload;
      state.podFullnessPercentage[service].data = data;
      state.podFullnessPercentage[service].loading = false;
    })
    .addCase(actions.podFullnessPercentageQueryFailure, (state, action) => {
      state.podFullnessPercentage[action.payload.service].data = undefined;
      state.podFullnessPercentage[action.payload.service].error = action.payload.errorMessage;
      state.podFullnessPercentage[action.payload.service].loading = false;
    });
});

export default metricReducer;
