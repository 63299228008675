import {useAppSelector as useSelector} from '../app/store';
import {DEFAULT_ASSISTANT_CHAT_CONFIG} from '../constants';
import {getInitialChatState} from '../reducers/assistants';
import {initAsyncDataState} from '../reducers/utils';
import {type AssistantChatConfig, type AssistantFile} from '../types';
import {useSelectedAssistantName, useSelectedGlobalProjectId} from './params';

export const useAssistantQuotas = (projectId: string) => {
  return useSelector((state) => state.assistants.projects[projectId]?.quotas);
};

export const useAssistantsAreLoading = (globalProjectId: string) => {
  return useSelector((state) => {
    const projectData = state.assistants.projects[globalProjectId];
    if (!projectData) {
      return true;
    }
    return projectData.assistants.loading;
  });
};

export const useAssistants = (globalProjectId: string) => {
  return useSelector((state) => state.assistants.projects[globalProjectId]?.assistants.data);
};

export const useAssistantsList = (globalProjectId: string) => {
  const assistants = useAssistants(globalProjectId) || {};
  return Object.values(assistants).sort((a, b) => b.updated_at.localeCompare(a.updated_at));
};

export const useSelectedAssistant = () => {
  const projectId = useSelectedGlobalProjectId();
  const assistantName = useSelectedAssistantName();
  const assistants = useAssistants(projectId) || {};
  return assistants[assistantName || ''];
};

export const useAssistant = (projectId: string, baseName: string) => {
  const assistants = useAssistants(projectId) || {};
  return assistants[baseName];
};

export const useAssistantChat = (projectId: string, assistantName: string) => {
  return useSelector((state) => {
    return (
      state.assistants.projects[projectId]?.chats[assistantName] ||
      initAsyncDataState(getInitialChatState())
    );
  });
};
export const useAssistantChatConfig = (
  projectId: string,
  assistantName: string,
): AssistantChatConfig => {
  return useSelector((state) => {
    return (
      state.assistants.projects[projectId]?.chats[assistantName]?.data?.config ||
      DEFAULT_ASSISTANT_CHAT_CONFIG
    );
  });
};

export const useAssistantFiles = (assistantName: string): AssistantFile[] => {
  return Object.values(
    useSelector((state) => state.assistantFiles.assistants[assistantName]?.data) || {},
  ).sort((a, b) => b.updated_on.localeCompare(a.updated_on));
};

export const useAssistantFilesAreLoading = (assistantName: string) => {
  return useSelector((state) => {
    const assistantFiles = state.assistantFiles.assistants[assistantName];
    if (!assistantFiles) {
      return true;
    }

    return assistantFiles.loading;
  });
};

export const useAssistantFile = (
  assistantName: string,
  fileId: string,
): AssistantFile | undefined => {
  const assistantFiles =
    useSelector((state) => state.assistantFiles.assistants[assistantName]?.data) || {};

  return assistantFiles[fileId];
};

export const useAcceptedAssistantsTerms = (orgId: string) => {
  return useSelector((state) => state.assistants.organizations[orgId]?.acceptedTerms);
};
