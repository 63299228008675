import SearchIcon from '@mui/icons-material/Search';
import {Box, InputAdornment, TextField} from '@mui/material';
import type React from 'react';
import {NoAccessMessage, SubmissionSuccessMessage} from './FullPageNotices';
import InkeepCustomTriggerComponent from './InkeepSearchTrigger';
import {LinkSection} from './LinkSection';
import TicketForm from './TicketForm';

interface SupportTicketPageContentProps {
  hasAccess: boolean;
  isSubmissionSuccessful: boolean;
  isSubmitting: boolean;
  onSubmit: (data: any) => Promise<void>;
}

const styles = {
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: 2,
  },
  rightColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    padding: 2,
  },
  searchField: {
    marginBottom: 2,
    backgroundColor: 'background.paper',
    maxWidth: 250,
    '& .MuiInputBase-root': {
      height: 40,
    },
  },
};

const SupportTicketPageContent: React.FC<SupportTicketPageContentProps> = ({
  hasAccess,
  isSubmissionSuccessful,
  isSubmitting,
  onSubmit,
}) => {
  if (!hasAccess) {
    return <NoAccessMessage />;
  }

  if (isSubmissionSuccessful) {
    return <SubmissionSuccessMessage />;
  }

  return (
    <Box sx={styles.contentWrapper}>
      <TicketForm isSubmitting={isSubmitting} handleSubmit={onSubmit} />
      <Box sx={styles.rightColumn}>
        <InkeepCustomTriggerComponent>
          <TextField
            fullWidth
            placeholder="Search"
            sx={styles.searchField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </InkeepCustomTriggerComponent>
        <LinkSection />
      </Box>
    </Box>
  );
};

export default SupportTicketPageContent;
