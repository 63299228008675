import {Link, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {useNavigateToBackupDetailsPage} from '../../../../hooks/navigation';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';
import {useIndexById, useServicesAreLoadingByGlobalProject} from '../../../../selectors/services';
import {type Backup} from '../../../../types';
import BackupActions from '../BackupActions/BackupActions';

const styles = {
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

type BackupsTableRowProps = {
  col: number;
  indexId: string;
  backups: Backup[];
};

function BackupsTableRow({col, indexId, backups}: BackupsTableRowProps) {
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {getPath: getBackupDetailsPath} = useNavigateToBackupDetailsPage(orgId, projectId);
  const index = useIndexById(projectId, indexId);
  const indexesLoading = useServicesAreLoadingByGlobalProject(projectId);
  const link = getBackupDetailsPath(indexId);
  const backup = backups[0];
  if (!backup) {
    return null;
  }
  if (col === 0) {
    if (!index && !indexesLoading) {
      return <Typography color="secondary">{`${backup.index_name} (Deleted)`}</Typography>;
    }
    return (
      <Link sx={styles.link} component={RouterLink} to={link}>
        <Typography color="action.primary">{backup.index_name}</Typography>
      </Link>
    );
  }
  if (col === 1) {
    return <Typography>{backup.region}</Typography>;
  }
  if (col === 2) {
    return <Typography>{backup.cloud?.toUpperCase()}</Typography>;
  }
  if (col === 3) {
    return <Typography>{backup.dimension}</Typography>;
  }
  if (col === 4) {
    return (
      <Link sx={styles.link} component={RouterLink} to={link}>
        <Typography color="action.primary">{backups.length}</Typography>
      </Link>
    );
  }
  if (col === 5) {
    return <BackupActions indexId={indexId} sourceIndex={index} />;
  }
  return null;
}

export default BackupsTableRow;
