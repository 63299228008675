import {useMemo} from 'react';
import {useAppSelector as useSelector} from '../app/store';

export const useRolesByPrincipalIdForProject = (principalId: string, projectId: string) => {
  const allRoles = useSelector((state) => state.rbac.roleBindings);
  return useMemo(
    () =>
      allRoles.filter((role) => role.principalId === principalId && role.projectId === projectId),
    [allRoles, principalId, projectId],
  );
};
