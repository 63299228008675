import Button, {type ButtonProps} from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {forwardRef} from 'react';

export const NavButtonStyles = {
  fontSize: '15px',
  lineHeight: '1.25',
  fontWeight: '500',
  color: 'text.primary',
};

const styles = {
  button: {
    alignItems: 'flex-end',
    color: 'text.primary',
  },
  title: NavButtonStyles,
};

const ProjectButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({children, onClick, ...props}, ref) => {
    return (
      <Button ref={ref} sx={styles.button} onClick={onClick} {...props}>
        <Typography noWrap sx={styles.title}>
          {children}
        </Typography>
      </Button>
    );
  },
);

ProjectButton.displayName = 'ProjectButton';
export default ProjectButton;
