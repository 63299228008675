import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import CodingLanguageSelector from '../../../../../components/CodeSnippets/CodingLanguageSelector';
import {useSelectedLanguage} from '../../../../../components/CodeSnippets/utils';
import {PUBLIC_DOCS} from '../../../../../constants';
import {ASSISTANT_DOCS_LANGUAGES, CONNECT_CONTENT} from '../../../docs/docs';

interface ConnectPanelProps {
  assistantName: string;
}

const styles = {
  root: {
    mt: -1, // Offsets spacing of Selector
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  scrollBox: {
    overflowY: 'auto',
  },
  language: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default function ConnectPanel({assistantName}: ConnectPanelProps) {
  const selectedLanguage = useSelectedLanguage(ASSISTANT_DOCS_LANGUAGES);
  const content = CONNECT_CONTENT[selectedLanguage](assistantName);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.scrollBox}>
        <Box sx={styles.language}>
          <Typography fontWeight="500">Language</Typography>
          <CodingLanguageSelector languageOptions={ASSISTANT_DOCS_LANGUAGES} />
        </Box>
        <Box sx={styles.sections}>
          {content.map((section) => (
            <Box sx={styles.section} key={section.title}>
              <Box>
                <Typography variant="subtitle1">{section.title}</Typography>
                <Typography>{section.description}</Typography>
              </Box>
              {section.code && (
                <CodeBlock
                  condensed
                  code={section.code}
                  language={selectedLanguage}
                  customStyles={{
                    fontSize: 12,
                  }}
                />
              )}
            </Box>
          ))}
          <Typography>
            Read more about the Assistant API in our{' '}
            <Link href={PUBLIC_DOCS.ASSISTANT} target="_blank">
              documentation
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
