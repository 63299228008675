import {ZENDESK_API_TOKEN, ZENDESK_EMAIL} from '../constants';

// Use these IDs to set the id for custom fields in Zendesk
export const ZENDESK_TICKET_CATEGORY_FIELD_ID = '12362156615709';
export const ZENDESK_TICKET_SEVERITY_FIELD_ID = '5965726288401';

export type ZendeskTicketData = {
  ticket: {
    subject: string;
    comment: {
      body: string;
      uploads: string[];
      public: boolean;
    };
    requester: {
      name: string;
      email: string;
    };
    custom_fields: {
      id: string;
      value: string;
    }[];
    tags: string[];
  };
};

const ZENDESK_API_URL = 'https://pineconehelp.zendesk.com/api/v2';

class ZendeskApi {
  private static async request(url: string, options: RequestInit = {}): Promise<Response> {
    const headers = {
      Authorization: `Basic ${btoa(`${ZENDESK_EMAIL}/token:${ZENDESK_API_TOKEN}`)}`,
      ...options.headers,
    };

    const response = await fetch(`${ZENDESK_API_URL}/${url}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Request failed: ${response.status} ${response.statusText}\n${errorText}`);
    }

    return response;
  }

  static async uploadFile(file: File): Promise<string> {
    const fileData = await file.arrayBuffer();

    const response = await this.request(`uploads?filename=${encodeURIComponent(file.name)}`, {
      method: 'POST',
      headers: {
        'Content-Type': file.type,
      },
      body: fileData,
    });

    if (!response.ok) {
      throw new Error('File upload failed');
    }

    const responseJson = await response.json();
    return responseJson.upload.token;
  }

  static async createTicket(zendeskData: ZendeskTicketData): Promise<Response> {
    const response = await this.request('tickets.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(zendeskData),
    });
    return response;
  }
}

export default ZendeskApi;
