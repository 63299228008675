import PineconeFullLogoDark from '../../icons/pinecone_full_logo_dark.svg';
import PineconeFullLogoLight from '../../icons/pinecone_full_logo_light.svg';
import PineconeLogoDark from '../../icons/pinecone_logo_small_dark.svg';
import PineconeLogoLight from '../../icons/pinecone_logo_small_light.svg';
import Image from './Image/Image';

const ThemeAwarePineconeLogo = ({fullLogo, sx}: {fullLogo?: boolean; sx?: object}) => {
  return (
    <Image
      alt="Pinecone"
      height={50}
      srcLight={fullLogo ? PineconeFullLogoDark : PineconeLogoDark}
      srcDark={fullLogo ? PineconeFullLogoLight : PineconeLogoLight}
      sx={sx}
    />
  );
};

export default ThemeAwarePineconeLogo;
