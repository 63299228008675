import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

interface PageControlsProps {
  children: React.ReactNode;
  action?: React.ReactNode;
}

const HEIGHT = '42px';

const styles = {
  root: {
    mb: 1,
    display: 'flex',
    gap: 2,
    flexWrap: 'wrap',
    height: HEIGHT,
  },
  divider: {
    height: HEIGHT,
  },
  action: {
    display: 'flex',
    mt: 1,
    height: HEIGHT,
    // Ensures all the button children expand to meet the height
    button: {
      height: HEIGHT,
    },
  },
};

export default function PageControls({children, action}: PageControlsProps) {
  return (
    <Box sx={styles.root}>
      {children}
      {action ? (
        <>
          <Divider orientation="vertical" flexItem variant="middle" sx={styles.divider} />
          <Box sx={styles.action}>{action}</Box>
        </>
      ) : null}
    </Box>
  );
}
