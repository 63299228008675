import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {usePollImportStatus} from '../../hooks/bulkImports';
import {useBulkImportStatusColorMap} from '../../hooks/colorMaps';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {useServiceByGlobalProject} from '../../selectors/services';

type ImportStatusBoxProps = {
  indexName: string;
  importId: string;
};

const styles = {
  root: {
    width: 'max-content',
  },
  status: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  statusIcon: {
    display: 'inline-block',
    borderRadius: '100%',
    height: 8,
    width: 8,
  },
  processingBar: {
    mt: 1,
    width: '120px',
  },
};

function BulkImportStatusLabel({indexName, importId}: ImportStatusBoxProps) {
  const project = useSelectedGlobalProject();
  const index = useServiceByGlobalProject(project.id, indexName);
  const {status = 'InProgress', percentComplete = 0} = usePollImportStatus(
    project,
    indexName,
    index.host,
    importId,
  );
  const color = useBulkImportStatusColorMap(status);
  const statusLabel = status === 'InProgress' ? `${percentComplete}% complete` : status;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.status}>
        <Box sx={[styles.statusIcon, {background: color}]} id="bulk-import-status-indicator" />
        <Typography variant="inherit">{statusLabel}</Typography>
      </Box>
      {status === 'InProgress' && (
        <LinearProgress
          sx={styles.processingBar}
          color="secondary"
          variant={percentComplete < 100 ? 'determinate' : 'query'}
          value={percentComplete}
        />
      )}
    </Box>
  );
}

export default BulkImportStatusLabel;
