import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Divider, Typography} from '@mui/material';
import Tooltip from '@pinecone-experience/timber/Tooltip/index';
import {type DatasetInformation} from '../../../../components/datasets/data/types';
import {type EmbeddingModel} from '../../../../types';
import {getServerlessWriteTooltip} from '../../IndexCreationPage/IndexCreationFooter/PriceEstimator/utilities/utils';

function TableItem({
  name,
  value,
  info,
}: {
  name: string;
  value?: string | string[] | number | number[];
  info?: string;
}) {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" py={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography fontWeight="fontWeightBold">{name}</Typography>
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon
                sx={{fontSize: '1rem', position: 'relative', top: 2}}
                color="secondary"
              />
            </Tooltip>
          )}
        </Box>
        <Typography>
          {Array.isArray(value) ? (value as string[] | number[]).join(', ') : value}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
}

interface IndexConfigurationTableProps {
  datasetInformation: Omit<DatasetInformation, 'collectionName'>;
  embeddingModel: EmbeddingModel;
  indexInformation: {
    indexType?: 'pod' | 'serverless';
    provider?: string;
    region?: string;
  };
  writeUnits: number;
}

function IndexConfigurationTable({
  datasetInformation,
  embeddingModel,
  indexInformation,
  writeUnits,
}: IndexConfigurationTableProps) {
  return (
    <Box>
      <Typography pb={1}>INDEX CONFIGURATION</Typography>
      <TableItem name="Name" value={datasetInformation.indexName} />
      <TableItem name="Dimension" value={embeddingModel.dimension} />
      <TableItem name="Metric" value={embeddingModel.metric} />
      <TableItem name="Index Type" value={indexInformation.indexType} />
      <TableItem name="Cloud provider" value={indexInformation.provider} />
      <TableItem name="Region" value={indexInformation.region} />
      {datasetInformation?.storageCostDollarsPerMonth && (
        <TableItem
          name="Storage Cost"
          value={`$${datasetInformation.storageCostDollarsPerMonth} / mo`}
        />
      )}
      <TableItem
        info={getServerlessWriteTooltip(writeUnits)}
        name="Write units"
        value={`${writeUnits} WU`}
      />
    </Box>
  );
}

export default IndexConfigurationTable;
