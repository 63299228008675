import {ControlPlaneRoles, DataPlaneRoles, ProjectRoles, type RbacRoles} from '../../../types';
import {assertUnreachable} from '../../../utils/types';
import {BaseOptions, type FormWithRoles} from './types';

export function getRoles(formData: FormWithRoles): RbacRoles[] {
  switch (formData.baseRole) {
    case ProjectRoles.PROJECT_EDITOR:
      return [ProjectRoles.PROJECT_EDITOR];
    case BaseOptions.CUSTOM:
      return [formData.controlPlaneRole, formData.dataPlaneRole].filter((val) => !!val);
    default:
      return assertUnreachable(formData.baseRole);
  }
}

function getControlPlaneRole(roles: RbacRoles[]) {
  const controlPlaneOptions: RbacRoles[] = Object.values(ControlPlaneRoles);
  return (roles.find((role) => controlPlaneOptions.includes(role)) as ControlPlaneRoles) || '';
}

function getDataPlaneRole(roles: RbacRoles[]) {
  const dataPlaneOptions: RbacRoles[] = Object.values(DataPlaneRoles);
  return (roles.find((role) => dataPlaneOptions.includes(role)) as DataPlaneRoles) || '';
}

export function getFormValuesFromRoles(roles: RbacRoles[]): FormWithRoles {
  if (roles.length === 1) {
    const baseRole = roles[0];
    if (baseRole === ProjectRoles.PROJECT_EDITOR) {
      return {
        baseRole: ProjectRoles.PROJECT_EDITOR,
        controlPlaneRole: '',
        dataPlaneRole: '',
      };
    }
  }
  return {
    baseRole: BaseOptions.CUSTOM,
    controlPlaneRole: getControlPlaneRole(roles),
    dataPlaneRole: getDataPlaneRole(roles),
  };
}

export const RbacRoleDisplays = {
  [ProjectRoles.PROJECT_EDITOR]: 'All',
  [BaseOptions.CUSTOM]: 'Custom',
};
