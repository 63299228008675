import {LocalStorageKeys} from '../constants';
import {useOrganizations} from '../selectors/organizations';
import {useAllGlobalProjects, useGlobalProjectsFromOrganization} from '../selectors/projects';
import {getLegacyId} from '../utils/projects';
import {localStorageGet} from '../utils/storage';

export const useDefaultOrgId = () => {
  const organizations = Object.values(useOrganizations());
  const previousId = localStorageGet(LocalStorageKeys.LAST_SELECTED_ORGANIZATION);
  const previousOrg = organizations.find((org) => org.id === previousId);
  const allProjects = useAllGlobalProjects();
  const withProjects = organizations.find(
    (org) => !!allProjects.find((proj) => proj.organization_id === org.id),
  );
  return previousOrg?.id || withProjects?.id || organizations[0]?.id || '';
};

// For now, check both global and legacy -- after a period of time we can remove the legacy checks;
export const useDefaultProjectId = (orgId: string) => {
  const projects = Object.values(useGlobalProjectsFromOrganization(orgId));
  const previousId = localStorageGet(LocalStorageKeys.LAST_SELECTED_PROJECT);
  const previousLegacyProject = projects.find((proj) => getLegacyId(proj) === previousId);
  if (previousLegacyProject) {
    return previousLegacyProject.id;
  }
  const previousGlobalProject = projects.find((proj) => proj.id === previousId);
  return previousGlobalProject?.id || projects[0]?.id || '';
};
