import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useCallback, useMemo} from 'react';
import ChatMessageBlock from '../../../../../components/ChatMessageBlock/ChatMessageBlock';
import ThemeAwarePineconeLogo from '../../../../../components/Utilities/ThemeAwarePineconeLogo';
import {type AssistantChat} from '../../../../../types';

type ChatHistoryProps = {
  chat: AssistantChat;
  hasFiles: boolean;
};

const styles = {
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  messages: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    bottom: 0,
    p: 2,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
    gap: 2,
    scrollbarGutter: 'stable',
  },
};

function ChatHistory({chat, hasFiles}: ChatHistoryProps) {
  const {data, loading, error} = chat;
  const messages = useMemo(() => data?.messages ?? [], [data?.messages]);
  const chatId = useMemo(() => data?.id, [data?.id]);

  const handleCopyChatHistory = useCallback(() => {
    if (messages.length) {
      navigator.clipboard.writeText(JSON.stringify(messages));
    }
  }, [messages]);

  if (messages.length === 0) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box textAlign="center">
          <ThemeAwarePineconeLogo fullLogo sx={{maxWidth: '160px'}} />
          <Typography color="secondary" mt={0.5}>
            {hasFiles
              ? 'Ask your assistant a question to get started.'
              : 'Your assistant is set up. Get started by uploading your files.'}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={styles.root}>
      <Box sx={styles.messages}>
        {messages.map((chatMessage, index) => {
          return (
            <ChatMessageBlock
              key={index}
              message={chatMessage}
              onCopyChatHistory={handleCopyChatHistory}
              // Show for the last assistant message, which will either be the last
              // or second to last message in the list
              showAssistantActions={!loading && index > messages.length - 3}
              chatId={chatId}
            />
          );
        })}
        {error && (
          <ChatMessageBlock
            message={{role: 'error', content: error.toString()}}
            onCopyChatHistory={handleCopyChatHistory}
            showAssistantActions={false}
            chatId={chatId}
          />
        )}
      </Box>
    </Box>
  );
}

export default ChatHistory;
