import * as Sentry from '@sentry/react';
import {DASHBOARD_BASE_URL_CLOUDRUN} from '../constants';
import {type PrometheusTimestampedResponse} from '../types';

function metricParser(
  res: any,
  idExtractor: (metric: Record<string, string>) => Record<string, string>,
  multiplier = 1,
) {
  return res.data.result.map(
    (result: {
      metric: Record<string, string>;
      values: {
        0: number;
        1: string;
      }[];
    }) => ({
      ...idExtractor(result.metric),
      data: result.values
        .map((value) => ({
          x: new Date(value[0] * 1000).toISOString(),
          y: parseFloat(value[1]) * multiplier,
        }))
        .filter((data) => !Number.isNaN(data.y)),
    }),
  );
}

export default class PrometheusApi {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  fetch(url: string, options = {}) {
    return fetch(`${DASHBOARD_BASE_URL_CLOUDRUN}/dashboard/${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then(async (res) => {
      if (!res.ok) {
        let errorResponse;
        try {
          errorResponse = await res.json();
        } catch (e) {
          throw new Error(`Failed to fetch (${res.status})`);
        }

        throw new Error(JSON.stringify(errorResponse));
      }

      let result;
      try {
        result = await res.json();
      } catch (error) {
        Sentry.addBreadcrumb({
          category: 'prometheusApi',
          message: 'Failed to parse response',
          data: {
            error,
            url,
            options,
          },
        });

        throw new Error('Failed to parse response');
      }

      return result;
    });
  }

  get(url: string, params = new URLSearchParams(), options = {}) {
    return this.fetch(`${url}?${params}`, options);
  }

  queryVectorCount(
    projectId: string,
    service: string,
    start: number,
    end: number,
    step: number,
    isServerless: boolean,
  ) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });

    const baseUrl = isServerless
      ? `projects/${projectId}/metrics/vector-count`
      : `projects/${projectId}/vector-count-data`;

    return this.get(baseUrl, params).then((res) =>
      metricParser(res, () => {
        return {
          id: 'vector count',
        };
      }),
    );
  }

  queryRequestCount(projectId: string, service: string, start: number, end: number, step: number) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/metrics/request-count`, params).then((res) =>
      metricParser(
        res,
        (metric) => {
          return {
            id: metric.request_type,
          };
        },
        1 / 60,
      ),
    );
  }

  queryErrorCount(projectId: string, service: string, start: number, end: number, step: number) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/metrics/request-error-count`, params).then((res) =>
      metricParser(
        res,
        (metric) => {
          return {
            id: metric.request_type,
          };
        },
        1 / 60,
      ),
    );
  }

  queryLatency(projectId: string, service: string, start: number, end: number, step: number) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/metrics/request-latency-by-quantile`, params).then(
      (res) =>
        metricParser(res, (metric) => {
          return {
            id: `${metric.request_type} ${metric.quantile}`,
            request_type: metric.request_type,
            quantile: metric.quantile,
          };
        }),
    );
  }

  queryPodFullness(projectId: string, service: string, start: number, end: number, step: number) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/pod-fullness-data`, params).then((res) =>
      metricParser(res, () => {
        return {
          id: 'pod fullness',
        };
      }),
    );
  }

  queryServerlessReadUnitsCount(
    projectId: string,
    service: string,
    start: number,
    end: number,
    step: number,
  ) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });

    return this.get(`projects/${projectId}/metrics/read-units-count`, params).then((res) =>
      metricParser(res, () => {
        return {
          id: 'read units count',
        };
      }),
    );
  }

  queryServerlessWriteUnitsCount(
    projectId: string,
    service: string,
    start: number,
    end: number,
    step: number,
  ) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/metrics/write-units-count`, params).then((res) =>
      metricParser(res, () => {
        return {
          id: 'write units count',
        };
      }),
    );
  }

  queryStorageUnitsCountQuery(
    projectId: string,
    service: string,
    start: number,
    end: number,
    step: number,
  ) {
    const params = new URLSearchParams({
      service,
      start: String(start),
      end: String(end),
      step: String(step),
    });
    return this.get(`projects/${projectId}/metrics/storage-units-count`, params).then((res) =>
      metricParser(res, () => {
        return {
          id: 'storage units count',
        };
      }),
    );
  }

  runQuery(
    projectId: string,
    metric: string,
    service: string,
    start: string,
    end: string,
    step: string,
    endpoint?: string,
  ) {
    const url = `projects/${projectId}/metrics/${metric}`;
    const params = new URLSearchParams({
      service,
      start,
      end,
      step,
    });

    if (endpoint) {
      params.append('endpoint', endpoint);
    }

    return this.get(url, params).then((res) => res as PrometheusTimestampedResponse);
  }

  // METRICS REVAMP
  queryRecordTotal(projectId: string, service: string, start: string, end: string, step: string) {
    return this.runQuery(projectId, 'v2/record-total', service, start, end, step);
  }

  queryReadUnitsTotal(
    projectId: string,
    service: string,
    start: string,
    end: string,
    step: string,
  ) {
    return this.runQuery(projectId, 'v2/read-units-total', service, start, end, step);
  }

  queryWriteUnitsTotal(
    projectId: string,
    service: string,
    start: string,
    end: string,
    step: string,
  ) {
    return this.runQuery(projectId, 'v2/write-units-total', service, start, end, step);
  }

  queryStorageSize(projectId: string, service: string, start: string, end: string, step: string) {
    return this.runQuery(projectId, 'v2/storage-size', service, start, end, step);
  }

  queryRequestDuration(
    projectId: string,
    service: string,
    start: string,
    end: string,
    step: string,
    endpoint?: string,
  ) {
    return this.runQuery(projectId, 'v2/request-duration', service, start, end, step, endpoint);
  }

  queryRequestTotal(projectId: string, service: string, start: string, end: string, step: string) {
    return this.runQuery(projectId, 'v2/request-total', service, start, end, step);
  }

  queryRequestStatus(
    projectId: string,
    service: string,
    start: string,
    end: string,
    step: string,
    endpoint?: string,
  ) {
    return this.runQuery(projectId, 'v2/request-status', service, start, end, step, endpoint);
  }

  queryPodFullnessPercentage(
    projectId: string,
    service: string,
    start: string,
    end: string,
    step: string,
  ) {
    return this.runQuery(projectId, 'v2/pod-fullness', service, start, end, step);
  }
}
