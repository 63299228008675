import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/collectionActions';
import {IndexState} from '../constants';
import {type Collection} from '../types';
import {isGlobalProjectAction} from './utils';

interface ProjectState {
  items: string[];
  loading: boolean;
  collectionStatus: Record<string, Collection>;
}

const initialProjectState = {
  items: [],
  loading: true,
  collectionStatus: {},
} as ProjectState;

interface CollectionsState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as CollectionsState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.createCollectionRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.createCollectionFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.createCollectionSuccess, (state, action) => {
      state.items.push(action.payload.name);
      state.loading = false;
    })
    .addCase(actions.listCollectionsRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.listCollectionsSuccess, (state, action) => {
      state.loading = false;
      state.items = action.payload.collections.map((collection) => collection.name);
      action.payload.collections.forEach((collection) => {
        state.collectionStatus[collection.name] = collection;
      });
    })
    .addCase(actions.listCollectionsFailure, (state) => {
      state.loading = false;
      state.items = [];
    })
    .addCase(actions.deleteCollectionRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.deleteCollectionSuccess, (state, action) => {
      state.loading = false;
      state.collectionStatus[action.payload.collection].status = IndexState.TERMINATING;
    })
    .addCase(actions.deleteCollectionsFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.collectionDataSuccess, (state, action) => {
      state.collectionStatus[action.payload.collectionName] = action.payload.collectionData;
    })
    .addCase(actions.collectionFinishedTerminating, (state, action) => {
      state.items = state.items.filter((collection) => collection !== action.payload.collection);
      delete state.collectionStatus[action.payload.collection];
    });
});

const collectionsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default collectionsReducer;
