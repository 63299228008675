import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import DeleteConfirmationModal from '../../../../../components/DeleteConfirmationModal';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {useBooleanState} from '../../../../../hooks/utils';
import {useIsOwnerOfSelectedProject} from '../../../../../selectors/projects';
import {type ApiKey} from '../../../../../types';
import {TRACKING_EVENTS, sendToGa} from '../../../../../utils/tracking';
import EditApiKeyForm from '../../EditApiKeyForm/EditApiKeyForm';

type ApiKeyActionsProps = {
  /** The API key on which these actions operate. */
  apiKey: ApiKey;
  /**
   * Operation to perform when the user chooses to delete an API key. If not present, the delete
   * button will not be rendered.
   */
  onDelete?: (apiKey: ApiKey) => void;
  /**
   * Operation to perform when the user chooses to rotate an API key. If not present, the rotate
   * button will not be rendered.
   */
  onRotate?: (apiKey: ApiKey) => void;
  /** Whether the key value is currently visible. */
  isShowing: boolean;
  /** Function to set the visibility of the key value. */
  setIsShowing: (isShowing: boolean) => void;
};

/** Action buttons for the user to manage an API key. */
function ApiKeyActions({apiKey, onDelete, onRotate, setIsShowing, isShowing}: ApiKeyActionsProps) {
  const {
    val: showDeleteModal,
    setTrue: setShowDeleteModal,
    setFalse: setHideDeleteModal,
  } = useBooleanState();

  const {
    val: showRotateModal,
    setTrue: setShowRotateModal,
    setFalse: setHideRotateModal,
  } = useBooleanState();

  const {
    val: showEditModal,
    setTrue: setShowEditModal,
    setFalse: setHideEditModal,
  } = useBooleanState();

  const handleDelete = useCallback(() => {
    if (!onDelete) return;
    onDelete(apiKey);
    sendToGa(TRACKING_EVENTS.DELETE_API_KEY);
  }, [onDelete, apiKey]);

  const handleRotate = useCallback(() => {
    if (!onRotate) return;
    onRotate(apiKey);
    sendToGa(TRACKING_EVENTS.ROTATE_API_KEY);
  }, [onRotate, apiKey]);

  const onClickCopy = useCallback(() => {
    navigator.clipboard.writeText(apiKey.value);
    sendToGa(TRACKING_EVENTS.COPY_API_KEY);
  }, [apiKey.value]);

  const onClickShow = useCallback(() => {
    setIsShowing(true);
    sendToGa(TRACKING_EVENTS.SHOW_API_KEY);
  }, [setIsShowing]);

  const onClickHide = useCallback(() => {
    setIsShowing(false);
    sendToGa(TRACKING_EVENTS.HIDE_API_KEY);
  }, [setIsShowing]);
  const showKeyOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);

  const isOwner = useIsOwnerOfSelectedProject();
  return (
    <>
      {showKeyOnce ? (
        <SelectorMenuButton
          id={`api-key-actions--${apiKey.name}`}
          items={[
            {
              id: 'settings',
              name: 'Settings',
              icon: <SettingsOutlinedIcon fontSize="small" />,
              onClick: isOwner ? setShowEditModal : undefined,
            },
            {
              id: 'rotate',
              name: 'Rotate',
              icon: <RefreshIcon fontSize="small" />,
              onClick: isOwner ? setShowRotateModal : undefined,
            },
            {
              id: 'delete',
              name: 'Delete',
              icon: <DeleteOutlinedIcon fontSize="small" />,
              onClick: isOwner ? setShowDeleteModal : undefined,
            },
          ]}
        />
      ) : (
        <>
          {isShowing ? (
            <IconButton
              id={`hide-api-key-${apiKey.name}`}
              tooltip="Hide key value"
              onClick={onClickHide}
              icon={<VisibilityIcon fontSize="small" />}
              border
            />
          ) : (
            <IconButton
              id={`show-api-key-${apiKey.name}`}
              tooltip="Show key value"
              onClick={onClickShow}
              icon={<VisibilityOffIcon fontSize="small" />}
              border
            />
          )}
          <IconButton
            id={`copy-api-key-${apiKey.name}`}
            tooltip="Copy key value"
            onClick={onClickCopy}
            icon={<CopyIcon fontSize="small" />}
            border
          />
          {onRotate && (
            <IconButton
              id={`rotate-api-key-${apiKey.name}`}
              tooltip="Rotate key value"
              onClick={setShowRotateModal}
              icon={<RefreshIcon fontSize="small" />}
              border
            />
          )}
          {onDelete && (
            <IconButton
              id={`delete-api-key-${apiKey.name}`}
              tooltip="Delete key"
              onClick={setShowDeleteModal}
              icon={<DeleteOutlinedIcon />}
              border
            />
          )}
        </>
      )}
      <DeleteConfirmationModal
        open={showDeleteModal}
        close={setHideDeleteModal}
        itemType="API key"
        name={apiKey.display_label}
        doDelete={handleDelete}
      />
      <DeleteConfirmationModal
        open={showRotateModal}
        close={setHideRotateModal}
        itemType="API key"
        name={apiKey.display_label}
        doDelete={handleRotate}
        titleAction="Rotation"
        action="Rotate"
        warningText={`Rotation replaces your old key with a new one to prevent unauthorized access. This action is irreversible and will disable any applications using this key. Confirm rotate for API key "${apiKey.display_label}" by retyping its name below.`}
      />
      <EditApiKeyForm open={showEditModal} close={setHideEditModal} apiKey={apiKey} />
    </>
  );
}

export default ApiKeyActions;
