import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type Marketplace} from '../types';

export const getMarketplaceSetupEntityRequest = createAction<{
  marketplaceType: string;
  marketplaceId: string;
  setupToken: string | null;
}>('VERIFY_MARKETPLACE_REQUEST');

export const getMarketplaceSetupEntityRequestSuccess = createAction<{
  marketplace: Marketplace;
}>('VERIFY_MARKETPLACE_REQUEST_SUCCESS');

export const getMarketplaceSetupEntityRequestError = createAction<{
  marketplaceType: string;
  marketplaceId: string;
  setupToken: string | null;
  error: string;
}>('VERIFY_MARKETPLACE_REQUEST_ERROR');

export function getMarketplaceSetupEntity(
  token: string,
  {
    marketplaceType,
    marketplaceId,
    setupToken,
  }: {marketplaceType: string; marketplaceId: string; setupToken: string | null},
) {
  return (dispatch: Dispatch) => {
    dispatch(getMarketplaceSetupEntityRequest({marketplaceId, setupToken, marketplaceType}));
    return new DashboardApi(token)
      .getMarketplaceEntityForSetup({marketplaceType, marketplaceId, setupToken})
      .then((res) => {
        dispatch(getMarketplaceSetupEntityRequestSuccess({marketplace: res}));
      })
      .catch((error) => {
        dispatch(
          getMarketplaceSetupEntityRequestError({
            marketplaceType,
            marketplaceId,
            setupToken,
            error,
          }),
        );
      });
  };
}
