import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import {deleteAssistant} from '../../../actions/assistantActions';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import {ProjectPage} from '../../../constants';
import {useNavigateToProjectPageGlobal} from '../../../hooks/navigation';
import {useControllerApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {type GlobalProject} from '../../../types';

interface AssistantMenuButtonProps {
  project: GlobalProject;
  name: string;
  disabled?: boolean;
}

export default function AssistantMenuButton({project, name, disabled}: AssistantMenuButtonProps) {
  const {
    val: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: hideDeleteModal,
  } = useBooleanState();

  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    assistantName: string;
  }>(project);

  const {go: goToProjectPage} = useNavigateToProjectPageGlobal(project.organization_id);

  const handleDelete = useCallback(() => {
    setQueryData({
      action: deleteAssistant,
      data: {
        globalProjectId: project.id,
        assistantName: name,
      },
    });
    hideDeleteModal();
    goToProjectPage(project.id, ProjectPage.ASSISTANTS);
  }, [goToProjectPage, hideDeleteModal, name, project.id, setQueryData]);

  return (
    <>
      <SelectorMenuButton
        iconSx={{m: 0}}
        ariaLabel="Assistant actions"
        id={`${name}-actions-showActions`}
        placement="left"
        menuSx={{mr: 1}}
        disabled={disabled}
        items={[
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
            onClick: openDeleteModal,
            color: 'error',
          },
        ]}
      />
      <DeleteConfirmationModal
        open={showDeleteModal}
        close={hideDeleteModal}
        itemType="Assistant"
        name={name}
        doDelete={handleDelete}
        warningText="This action cannot be undone. Please type the name of the assistant to confirm."
      />
    </>
  );
}
