import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/forcedMigrationActions';

type ArchiveData = {
  indexName: string;
};

interface ForcedMigrationState {
  projects: Record<string, ArchiveData[] | undefined>;
  organizationsKeysDeleted: Record<string, boolean>;
  migratingIndex?: string;
}

const initialState = {
  projects: {},
  migratingIndex: undefined,
  organizationsKeysDeleted: {},
} as ForcedMigrationState;

const forcedMigrationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.openModal, (state, action) => {
      state.migratingIndex = action.payload.migratingIndex;
    })
    .addCase(actions.closeModal, (state) => {
      state.migratingIndex = undefined;
    })
    .addCase(actions.getArchivedSuccess, (state, action) => {
      state.projects[action.payload.globalProjectId] = action.payload.archived;
    })
    .addCase(actions.getDeletedApiKeysSuccess, (state, action) => {
      state.organizationsKeysDeleted[action.payload.orgId] = action.payload.apiKeysDeleted;
    });
});

export default forcedMigrationReducer;
