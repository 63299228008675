import {Box, Link, Typography} from '@mui/material';
import type React from 'react';
import ReactMarkdown from 'react-markdown';
import CreateIndexDeeplinkForm from '../CreateIndexDeeplinkForm/CreateIndexDeeplinkForm';
import MarkdownCodeBlock from './MarkdownCodeBlock/MarkdownCodeBlock';

interface CustomMarkdownProps {
  children: string;
  components?: Record<string, React.ComponentType<any>>;
}

const LinkTypography = (props: any) => <Link {...props} variant="body1" sx={{fontSize: 14}} />;
const ParagraphTypography = (props: any) => (
  <Typography {...props} variant="body1" sx={{fontSize: 14, paddingBottom: 2}} />
);
const CreateIndexH3 = ({children, ...props}: any) => (
  <>
    <Typography variant="h6" sx={{my: 2}} {...props}>
      {children}
    </Typography>
    {children.includes('Create Index') && (
      <Box mt={2}>
        <CreateIndexDeeplinkForm />
      </Box>
    )}
  </>
);

const CustomMarkdown: React.FC<CustomMarkdownProps> = ({children, components = {}}) => {
  const defaultComponents = {
    a: LinkTypography,
    code: MarkdownCodeBlock,
    pre: MarkdownCodeBlock,
    p: ParagraphTypography,
    h3: CreateIndexH3,
    ...components,
  };

  return <ReactMarkdown components={defaultComponents}>{children}</ReactMarkdown>;
};

CustomMarkdown.defaultProps = {
  components: {},
};

export default CustomMarkdown;
