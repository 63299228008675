import {Box} from '@mui/material';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import StyledTextField from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {DefaultEmbeddingModels} from '../../../../../constants';
import {metricTypes} from '../../../../../pages/indexes/IndexCreationPage/utilities/utils';

export const CreateIndex = () => {
  const [showCustomDimMetric, setShowCustomDimMetric] = useState(false);

  const {control, setValue, watch} = useFormContext();
  const embeddingModel = watch('embeddingModel');

  useEffect(() => {
    if (embeddingModel) {
      if (embeddingModel !== 'custom') {
        setShowCustomDimMetric(false);
        const vals = embeddingModel.split('|');
        setValue('dimension', vals[0]);
        setValue('metric', vals[1]);
      } else {
        setShowCustomDimMetric(true);
        setValue('metric', '');
        setValue('dimension', '');
      }
    }
  }, [embeddingModel, setValue, setShowCustomDimMetric]);

  const modelsDrop = DefaultEmbeddingModels.map((model, i) => {
    return {
      label: `${model.name} (${model.dimension}, ${model.metric})`,
      value: `${model.dimension}|${model.metric}|${i}`,
    };
  });
  modelsDrop.unshift({label: 'Custom Dimensions and Metrics', value: 'custom'});

  return (
    <Box>
      <Box sx={{mb: 2}}>
        <StyledTextField
          fullWidth
          name="indexName"
          control={control}
          label="Create an index"
          placeholder="Name your index"
        />
        <SelectInput
          fullWidth
          name="embeddingModel"
          control={control}
          options={modelsDrop}
          label="Embedding Model"
          placeholder="Select an embedding model"
          tooltip="Choose the embedding model you're using, or enter dimensions and metric manually."
        />
      </Box>
      <Box sx={{display: showCustomDimMetric ? 'flex' : 'none', gap: 4}}>
        <NumberInput
          name="dimension"
          control={control}
          label="Dimensions"
          placeholder="Enter dimensions"
          tooltip="The dimensions and metric depend on the embedding model you are using."
        />

        <SelectInput
          name="metric"
          control={control}
          options={metricTypes}
          label="Metric"
          tooltip="The distance metric to use for measuring similarity."
        />
      </Box>
    </Box>
  );
};

export default CreateIndex;
