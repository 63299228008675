import {Box, Button, Link, Typography} from '@mui/material';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {
  useNavigateToCollectionPageGlobal,
  useNavigateToIndexPageGlobal,
} from '../../hooks/navigation';

type FinalConfirmationProps = {
  close: () => void;
  orgId: string;
  globalProjectId: string;
  index?: string;
};

function FinalConfirmation({close, orgId, globalProjectId, index}: FinalConfirmationProps) {
  const {getPath, go} = useNavigateToIndexPageGlobal(orgId, globalProjectId);
  const {getPath: getCollectionPath, go: goToCollection} = useNavigateToCollectionPageGlobal(
    orgId,
    globalProjectId,
  );
  const indexPath = getPath(index || '');
  const collectionPath = getCollectionPath();
  const doNav = () => {
    if (index) {
      go(index || '');
      close();
      return;
    }
    goToCollection();
    close();
  };
  return (
    <Box>
      <ModalHeader title="Migration complete" />
      <Box mb={2}>
        {index && (
          <Typography fontWeight="bold" component="span">
            Congratulations!{' '}
          </Typography>
        )}
        {index ? (
          <Typography component="span">
            Your data migration is complete. Reinitialize your client to connect to the new
            serverless <Link href={indexPath}>index</Link> now.
          </Typography>
        ) : (
          <Typography component="span">
            Create a serverless index with your new <Link href={collectionPath}>collection</Link>{' '}
            when you{`'`}re ready. Remember to reset your client afterwards.
          </Typography>
        )}
      </Box>
      <Button fullWidth variant="contained" onClick={doNav}>
        {index ? 'Go to index' : 'See collection'}
      </Button>
    </Box>
  );
}

export default FinalConfirmation;
