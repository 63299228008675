import CheckIcon from '@mui/icons-material/Check';
import {Box, Button, ButtonBase, Card, Link, Typography} from '@mui/material';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useState} from 'react';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {PINECONE_URLS} from '../../../constants';
import {useNavigateToGetStartedPage} from '../../../hooks/navigation';
import {useOrganizationsAreLoading} from '../../../selectors/organizations';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../selectors/params';
import {useIsOwnerOfSelectedProject} from '../../../selectors/projects';
import CreateApiKeyModal from '../ApiKeysPage/CreateApiKeyButton/CreateApiKeyModal';
import {getStartedUtmParameters, lowerSectionItems, upperSectionItems} from './constants';
import {getIsGuideCompleted} from './utilities';

const styles = {
  upperSection: {
    display: 'flex',
    gap: 2,
  },
  upperSectionCard: {
    p: 4,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      mt: 5,
      borderColor: 'divider',
    },
  },
  largeIcon: {
    fontSize: 35,
  },
  lowerSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: 4,
  },
  lowerSectionItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 2,
    p: 1,
    borderRadius: 2,
    '&:hover, &:focus': {
      backgroundColor: 'divider',
    },
  },
  iconBox: {
    borderRadius: 2,
    backgroundColor: 'background.surface',
    p: 2,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lowerSectionItemText: {
    display: 'flex',
    flexDirection: 'column',
  },
};

function GetStartedPage() {
  const organizationsLoading = useOrganizationsAreLoading();
  const [openGenerateKeyForm, setOpenGenerateKeyForm] = useState(false);
  const organizationId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {go: navigateToGetStartedPage} = useNavigateToGetStartedPage(organizationId, projectId);
  const isSelectedProjectOwner = useIsOwnerOfSelectedProject();
  return (
    <Page
      loading={organizationsLoading}
      header={
        <PageHeader
          title="Get started with Pinecone"
          actions={
            <>
              <Tooltip
                title={
                  !isSelectedProjectOwner && 'You must be a project owner to generate an API key'
                }
              >
                <Button
                  variant="contained"
                  onClick={() => setOpenGenerateKeyForm(true)}
                  disabled={!isSelectedProjectOwner}
                >
                  Generate API key
                </Button>
              </Tooltip>
              <CreateApiKeyModal
                open={openGenerateKeyForm}
                onClose={() => setOpenGenerateKeyForm(false)}
              />
            </>
          }
        />
      }
    >
      <Box>
        <Box sx={styles.upperSection}>
          {upperSectionItems.map((item) => {
            const completed = getIsGuideCompleted(projectId, item.guideId);
            return (
              <Card key={item.title} sx={styles.upperSectionCard}>
                <Box sx={styles.largeIcon}>{item.icon}</Box>
                <Typography variant="h6" component="h2" gutterBottom>
                  {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
                {completed ? (
                  <Button
                    variant="text"
                    color="success"
                    startIcon={<CheckIcon color="success" />}
                    onClick={() => navigateToGetStartedPage(item.guideId)}
                  >
                    Completed
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={() => navigateToGetStartedPage(item.guideId)}>
                    Get started
                  </Button>
                )}
              </Card>
            );
          })}
        </Box>
      </Box>
      <Typography variant="h6" mt={4}>
        Additional resources
      </Typography>
      <Box sx={styles.lowerSection}>
        {lowerSectionItems.map((item) => (
          <ButtonBase
            key={item.title}
            sx={styles.lowerSectionItem}
            href={`${item.href}${getStartedUtmParameters}`}
            target="_blank"
          >
            <Box sx={styles.iconBox}>{item.icon}</Box>
            <Box sx={styles.lowerSectionItemText}>
              <Typography fontWeight="bold">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
            </Box>
          </ButtonBase>
        ))}
      </Box>
      <Typography mt={4} variant="body2">
        Contact our{' '}
        <Link href={PINECONE_URLS.CONTACT_SALES} target="_blank">
          sales team
        </Link>{' '}
        for further assistance.
      </Typography>
    </Page>
  );
}

export default GetStartedPage;
