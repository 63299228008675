import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ChartLayout from '@pinecone-experience/timber/charts/ChartLayout/ChartLayout';
import {
  type LineConfig,
  type ReferenceAreaConfig,
} from '@pinecone-experience/timber/charts/LineChart/LineChart';
import TimestampLineChart from '@pinecone-experience/timber/charts/TimestampLineChart/TimestampLineChart';
import {type TimeInterval} from '@pinecone-experience/timber/charts/TimestampLineChart/constants/time';
import {ErrorBoundary} from '@sentry/react';
import {useState} from 'react';
import {type Dispatch} from 'redux';
import {usePrometheusQuery} from '../../../../../hooks/use-api';
import {type Metric, useMetrics} from '../../../../../selectors/metrics';
import {formatLargeNumber} from '../../../../../utils/format';

interface MetricChartProps {
  projectId: string;
  metric: Metric;
  query: (
    token: string,
    globalProjectId: string,
    service: string,
    startTime: string,
    endTime: string,
    step: string,
    endpoint?: string,
  ) => (dispatch: Dispatch) => Promise<any>;
  title: string;
  unit?: string;
  lines: LineConfig[];
  tooltip: string;
  start: string;
  end: string;
  service: string;
  interval: TimeInterval;
  endpoints?: string[];
  referenceAreas?: ReferenceAreaConfig[];
  yAxisFloor?: number;
  yAxisFormatter?: (value: string) => string;
  yAxisShowUnits?: boolean;
}

export default function MetricChart({
  projectId,
  metric,
  query,
  title,
  unit,
  lines,
  tooltip,
  start,
  end,
  service,
  interval,
  endpoints,
  referenceAreas,
  yAxisFloor,
  yAxisFormatter,
  yAxisShowUnits = true,
}: MetricChartProps) {
  const [endpoint, setEndpoint] = useState<string | undefined>(endpoints?.[0]);
  usePrometheusQuery(query, projectId, service, start, end, interval, endpoint);
  const [renderError, setRenderError] = useState('');
  const metrics = useMetrics(metric, service);
  const metricsMaxValue = metrics?.data?.maxValue || 0;
  const metricData = metrics?.data?.data || [];

  // If the max value is less than the floor, set the domain to [0, floor].
  // Otherwise, let the chart dynamically set the domain
  const yAxisDomain: [number, number] | undefined =
    yAxisFloor && metricsMaxValue < yAxisFloor ? [0, yAxisFloor] : undefined;

  const onSetInterval = (_: React.MouseEvent, val?: string) => {
    if (val) {
      setEndpoint(val);
    }
  };

  return (
    <ChartLayout
      loading={metrics?.loading}
      title={title}
      tooltip={tooltip}
      hasData={!!metricData.length}
      error={metrics?.error?.toString() || renderError || undefined}
      control={
        endpoints && (
          <ToggleButtonGroup exclusive value={endpoint} onChange={onSetInterval}>
            {endpoints.map((ep) => (
              <ToggleButton size="small" key={ep} sx={{py: 0}} value={ep}>
                {ep}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )
      }
    >
      <ErrorBoundary onError={() => setRenderError('An error occurred rendering this chart')}>
        <TimestampLineChart
          disableAnimation={metrics?.loading}
          title={`${title} Chart`}
          data={metricData}
          unit={unit}
          lines={lines}
          interval={interval}
          referenceAreas={referenceAreas}
          yAxis={{
            domain: yAxisDomain,
            formatter: yAxisFormatter ?? ((value: string) => formatLargeNumber(Number(value))),
            showUnits: yAxisShowUnits,
          }}
        />
      </ErrorBoundary>
    </ChartLayout>
  );
}
