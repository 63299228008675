import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/backupActions';
import {type Backup} from '../types';
import {type AsyncDataState, initAsyncDataState, isGlobalProjectAction} from './utils';

interface ProjectState {
  backups: AsyncDataState<Backup[]>;
}

const initialProjectState = {
  backups: initAsyncDataState([]),
} as ProjectState;

interface BackupsState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as BackupsState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.listBackupsRequest, (state) => {
      state.backups.loading = true;
    })
    .addCase(actions.listBackupsSuccess, (state, action) => {
      state.backups.loading = false;
      state.backups.data = action.payload.backups;
    })
    .addCase(actions.listBackupsFailure, (state) => {
      state.backups.loading = false;
    })
    .addCase(actions.createBackupRequest, (state) => {
      state.backups.loading = true;
    })
    .addCase(actions.createBackupFailure, (state) => {
      state.backups.loading = true;
    })
    .addCase(actions.createBackupSuccess, (state, action) => {
      state.backups.data = state.backups.data || [];
      state.backups.data.push(action.payload.backup);
      state.backups.loading = false;
    })
    .addCase(actions.getBackupSuccess, (state, action) => {
      state.backups.data = (state.backups.data || []).map((backup) => {
        if (backup.id === action.payload.backup.id) {
          return action.payload.backup;
        }
        return backup;
      });
    })
    .addCase(actions.deleteBackupsRequest, (state) => {
      state.backups.loading = true;
    })
    .addCase(actions.deleteBackupsSuccess, (state) => {
      state.backups.loading = false;
    })
    .addCase(actions.deleteBackupsFailure, (state) => {
      state.backups.loading = false;
    })
    .addCase(actions.deleteBackupSuccess, (state, action) => {
      state.backups.data = (state.backups.data || []).filter((backup) => {
        return backup.id !== action.payload.backupId;
      });
    });
});

const backupsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default backupsReducer;
