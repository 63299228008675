import {Box, Paper, useTheme} from '@mui/material';

type JSONValue = string | number | boolean | JSONObject | JSONArray;

interface JSONObject {
  [x: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> {}

type StyledJsonViewProps = {
  data: JSONObject;
};

const styles = {
  row: {
    '& pre': {
      display: 'inline',
      textWrap: 'wrap',
    },
    color: 'primary.dark',
  },
};

const MAX_VALUES_TO_SHOW = 7;

function StyledJsonValue({value}: {value: JSONValue}) {
  const theme = useTheme();
  if (typeof value === 'string') {
    return <pre>{`"${value}"`}</pre>;
  }
  if (typeof value === 'number') {
    return (
      <>
        {value < 0 && <pre style={{color: '#E36219', paddingRight: 1}}>-</pre>}
        <pre style={{color: theme.palette.primary.main}}>{Math.abs(value)}</pre>
      </>
    );
  }
  if (typeof value === 'boolean') {
    return <pre>{value.toString()}</pre>;
  }
  if (Array.isArray(value)) {
    const newArr = value.slice(0, MAX_VALUES_TO_SHOW);
    return (
      <Box display="inline">
        <pre style={{color: theme.palette.text.secondary}}>[</pre>
        {newArr.map((val, index) => (
          <>
            <StyledJsonValue key={index} value={val} />
            {index !== value.length - 1 && <pre>, </pre>}
          </>
        ))}
        {value.length > MAX_VALUES_TO_SHOW && <pre style={{color: '#E36219'}}>...</pre>}
        <pre style={{color: theme.palette.text.secondary}}>]</pre>
      </Box>
    );
  }
  const keys = Object.keys(value);
  return (
    <Box sx={styles.row} display="inline">
      <pre style={{color: theme.palette.text.secondary}}>{`{`}</pre>
      <Box sx={{pl: 3}}>
        {keys.map((key, index) => {
          // Mutually recusive components
          return (
            /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
            <StyledJsonRow
              key={key}
              keyName={key}
              value={value[key]}
              isLast={index === keys.length - 1}
            />
          );
        })}
      </Box>
      <pre style={{color: theme.palette.text.secondary}}>{`}`}</pre>
    </Box>
  );
}

function StyledJsonRow({
  keyName,
  value,
  isLast,
}: {
  keyName: string;
  value: JSONValue;
  isLast: boolean;
}) {
  const theme = useTheme();
  return (
    <Box key={keyName} sx={styles.row}>
      <pre>{`"${keyName}"`}</pre>
      <pre style={{color: theme.palette.text.secondary}}>: </pre>
      <StyledJsonValue value={value} />
      {!isLast && <pre style={{color: theme.palette.text.secondary}}>,</pre>}
    </Box>
  );
}

function StyledJsonView({data}: StyledJsonViewProps) {
  const theme = useTheme();
  const keys = Object.keys(data);
  return (
    <Paper sx={{overflow: 'hidden', px: 2}}>
      <pre style={{color: theme.palette.text.secondary}}>{`{`}</pre>
      <Box sx={{pl: 3}}>
        {keys.map((key, index) => (
          <StyledJsonRow
            key={key}
            keyName={key}
            value={data[key]}
            isLast={index === keys.length - 1}
          />
        ))}
      </Box>
      <pre style={{color: theme.palette.text.secondary}}>{`}`}</pre>
    </Paper>
  );
}

export default StyledJsonView;
