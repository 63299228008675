import {format} from 'date-fns/format';

export const autoRefreshPollingInterval = 2 * 60 * 1000;

export const timeOptions = [
  {name: '5 min', value: 5},
  {name: '15 min', value: 15},
  {name: '4 hours', value: 60 * 4},
  {name: '12 hours', value: 60 * 12},
  {name: '1 day', value: 60 * 24},
  {name: '2 days', value: 60 * 24 * 2},
  {name: '1 week', value: 60 * 24 * 7},
] as const;

export const formatDateToString = (date: Date) => {
  try {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss");
  } catch (err) {
    return date;
  }
};
