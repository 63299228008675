import {z} from 'zod';
import {isLowercaseAlphaNumericWithHyphens} from '../../../../utils/format';
import {indexNameCharacterLimit} from '../../../indexes/IndexCreationPage/utilities/schema';

export const name = z
  .string()
  .max(indexNameCharacterLimit, {
    message: `Name must be ${indexNameCharacterLimit} characters or less`,
  })
  .nonempty('Name is required')
  .refine((field) => isLowercaseAlphaNumericWithHyphens(field), {
    message: 'Can only contain lowercase letters, numbers, and hyphens.',
  });

export const assistantSchema = z.object({
  name,
  metadata: z.record(z.string()),
});

export type AssistantSchema = z.infer<typeof assistantSchema>;
