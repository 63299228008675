import {useDispatch} from 'react-redux';
import {upsertVectors} from '../../../../../actions/dataActions';
import {NotificationTypes, enqueNotification} from '../../../../../actions/notificationActions';
import {type UpsertRequest} from '../../../../../api/dataPlaneApi';
import {useDataPlane} from '../../../../../hooks/use-api';
import {
  type GlobalProject,
  type IndexStatsDescription,
  type Organization,
} from '../../../../../types';
import {type FilterInputNode} from '../../../../../types/metadata/inputs';
import {type MetadataEntry, type MetadataFilter} from '../../../../../types/metadata/language';
import {type MetadataInput, type UpsertSchemaType, type ValueTypes} from './schema';

function getValueType(value: MetadataEntry): ValueTypes {
  switch (typeof value) {
    case 'number':
      return 'number';
    case 'boolean':
      return 'boolean';
    case 'string':
      return 'string';
    default:
      if (Array.isArray(value)) {
        return 'array';
      }
      return 'string';
  }
}

function parseValueType(value: string, valueType: ValueTypes) {
  switch (valueType) {
    case 'number':
      return parseFloat(value);
    case 'boolean':
      return value.toLowerCase() !== 'false';
    case 'array':
      return value.split(',');
    case 'string':
    case 'auto':
      return value;
    default:
      return value;
  }
}

/*
 * Helper function to convert internal input nodes to nodes consumable by the API
 */
function inputNodeToMetadataFilter(node: FilterInputNode): MetadataFilter {
  if ('operation' in node) {
    // TODO: find out why this cast is needed
    const op = node.operation as '$and';
    return {
      [op]: node.nodes.map((n) => inputNodeToMetadataFilter(n)),
    };
  }
  // TODO: find out why this cast is needed
  const op = node.op as '$eq';
  return {
    [node.key]: {[op]: parseValueType(node.value, node.valueType) as string},
  };
}

/*
 * Convert the base filter inputs to a metadata filter consumable by the API
 */
export function inputNodesToMetadataFilter(filter: FilterInputNode[]) {
  return {
    $and: filter.map((node) => inputNodeToMetadataFilter(node)),
  };
}

export function buildMetadataFromInput(input: MetadataInput[]) {
  const metadata = {} as Record<string, MetadataEntry>;
  input.forEach((field) => {
    metadata[field.key] = parseValueType(field.value, field.valueType);
  });
  return metadata;
}

export function buildInputsFromMetadata(metadata?: Record<string, MetadataEntry>) {
  if (!metadata) {
    return [];
  }
  return Object.entries(metadata || {}).map(([key, value]) => {
    return {
      key,
      value: value.toString(),
      valueType: getValueType(value),
    };
  });
}

export function useDoUpsert(
  project: GlobalProject,
  service: string,
  host: string,
  onSubmit: () => void,
) {
  const dispatch = useDispatch();
  const {setQueryData} = useDataPlane<{upserts: UpsertRequest[]}>(project, service, host);
  const doSubmit = (data: UpsertSchemaType) => {
    try {
      const vectorData = {
        id: data.id,
        values: data.vector,
        metadata: buildMetadataFromInput(data.metadata),
        sparseValues: data.sparse,
      };
      setQueryData({
        action: upsertVectors,
        data: {
          upserts: [
            {
              vectors: [vectorData],
              namespace: data.namespace || '',
            },
          ],
        },
      });
      onSubmit();
    } catch (err: any) {
      dispatch(
        enqueNotification({type: NotificationTypes.ERROR, text: `Upsert failed: ${err.message}`}),
      );
    }
  };
  return doSubmit;
}

export type IndexExplorerState = {
  organization: Organization;
  dimensions: number;
  project: GlobalProject;
  service: string;
  host: string;
  indexStats?: IndexStatsDescription;
};
