import {Box, Button, Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useEffect, useMemo} from 'react';
import {type Control, type UseFormSetValue, useWatch} from 'react-hook-form';
import {Providers} from '../../../constants';
import {useServerlessEnvironments} from '../../../selectors/environments';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {type IndexTargetType, type MigrationSchemaType} from '../schema';

type ConfigureIndexProps = {
  onContinue: () => void;
  goBack: () => void;
  control: Control<MigrationSchemaType>;
  indexNum: number;
  indexList: IndexTargetType[];
  setValue: UseFormSetValue<MigrationSchemaType>;
};

function ConfigureIndex({
  onContinue,
  goBack,
  control,
  indexNum,
  indexList,
  setValue,
}: ConfigureIndexProps) {
  const index = indexList[indexNum];
  const prefix: `indexes.${number}` = `indexes.${indexNum}`;
  const orgId = useSelectedOrganizationId();
  const serverlessEnvironments = useServerlessEnvironments(orgId);
  const providers = [{value: Providers.AWS}];
  const selectedProvider = useWatch({control, name: `${prefix}.cloud`});
  const selectedRegion = useWatch({control, name: `${prefix}.region`});
  const regions = useMemo(() => {
    return serverlessEnvironments
      .filter((env) => env.provider === selectedProvider)
      .map((env) => ({
        value: env.provider_region,
      }));
  }, [serverlessEnvironments, selectedProvider]);
  useEffect(() => {
    if (!regions.find((r) => r.value === selectedRegion)) {
      setValue(`${prefix}.region`, regions[0]?.value || '');
    }
  }, [regions, prefix, selectedRegion, setValue]);
  return (
    <>
      <ModalHeader
        title="Set up your serverless index"
        subtitle={
          <Typography component="span">
            Configuring{' '}
            <Typography component="span" fontWeight="fontWeightBold">
              {index.sourceName}
            </Typography>{' '}
            {indexList.length > 1 ? `(index ${indexNum + 1} of ${indexList.length})` : ''}
          </Typography>
        }
      />
      <Box>
        <TextInput
          required
          label="Serverless index name"
          name={`${prefix}.targetName`}
          control={control}
          fullWidth
        />
        <SelectInput
          fullWidth
          label="Cloud"
          control={control}
          name={`${prefix}.cloud`}
          options={providers}
        />
        <SelectInput
          fullWidth
          label="Region"
          control={control}
          name={`${prefix}.region`}
          options={regions}
        />
      </Box>
      <Box display="flex" gap={1}>
        <Button sx={{flex: 1}} variant="outlined" onClick={goBack}>
          Back
        </Button>
        <Button sx={{flex: 1}} variant="contained" onClick={onContinue}>
          Next
        </Button>
      </Box>
    </>
  );
}

export default ConfigureIndex;
