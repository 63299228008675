import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {Box, Button, Paper, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect} from 'react';
import type Stripe from 'stripe';
import {createSetupIntent, getBillingDetails} from '../../../../../actions/organizationActions';
import Image from '../../../../../components/Utilities/Image/Image';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../hooks/utils';
import {
  useBillingDetails,
  useCurrentSubscription,
  useSetupIntent,
} from '../../../../../selectors/billing';
import {
  useSelectedOrgMarketplaceInfo,
  useSelectedOrganization,
} from '../../../../../selectors/organizations';
import {capitalize} from '../../../../../utils/format';
import {TAX_ID_OPTIONS, type TaxId} from '../../../../../utils/stripe/constants';
import EditBillingModal from './EditBillingModal/EditBillingModal';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  card: {
    flex: 1,
    p: 3,
  },
  marketplaceCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  marketplaceManageButton: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  dataBox: {
    my: 1,
  },
};

function friendlyFormatTaxIdType(taxIdType: Stripe.TaxId | string) {
  return (
    TAX_ID_OPTIONS[taxIdType as TaxId]?.label ||
    capitalize((taxIdType as string).split('_').join(' '))
  );
}

function BillingContactCard() {
  const {val: showForm, setTrue: setShowForm, setFalse: setHideForm} = useBooleanState();
  const {setQueryData} = useDashboardApi<{organizationId: string}>();
  const organization = useSelectedOrganization();
  const marketplaceInfo = useSelectedOrgMarketplaceInfo();
  const currentSubscription = useCurrentSubscription();
  const updateSubscriptionLoading = currentSubscription.loading;
  const setupIntent = useSetupIntent();
  const {data: billingDetails} = useBillingDetails();

  const defaultPaymentMethod = billingDetails?.paymentMethod;
  const card = defaultPaymentMethod?.card;
  const customerTaxId = billingDetails?.taxId;

  useEffect(() => {
    if (!marketplaceInfo && organization.id) {
      setQueryData({
        action: getBillingDetails,
        data: {
          organizationId: organization.id,
        },
      });
    }
  }, [setQueryData, organization.id, marketplaceInfo]);

  const onClickEdit = () => {
    if (!setupIntent && organization.id) {
      setQueryData({
        action: createSetupIntent,
        data: {
          organizationId: organization.id,
        },
      });
    }
    setShowForm();
  };

  if (marketplaceInfo) {
    return (
      <Paper sx={[styles.card, styles.marketplaceCard]}>
        <Box sx={styles.marketplaceManageButton}>
          <Button
            endIcon={<ArrowOutwardIcon />}
            href={marketplaceInfo.link}
            size="small"
            target="_blank"
            variant="outlined"
          >
            Manage Subscription
          </Button>
        </Box>
        <Image
          alt={marketplaceInfo.name}
          srcLight={marketplaceInfo.logo}
          srcDark={marketplaceInfo.logoDark}
          height={80}
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography variant="h6" textAlign="center" gutterBottom>
          {`${marketplaceInfo.name} Marketplace Subscription`}
        </Typography>
        <Typography textAlign="center" gutterBottom>
          Your payments are being processed by {marketplaceInfo.fullname}.
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={styles.card}>
        <Box sx={styles.header}>
          <Typography variant="h6">Billing Contact</Typography>
          <Button variant="outlined" size="small" onClick={onClickEdit}>
            Edit
          </Button>
        </Box>
        <LoadingContainer loading={updateSubscriptionLoading}>
          <Box sx={styles.row}>
            <Box sx={styles.column}>
              <Box sx={styles.dataBox}>
                <Typography variant="overline" color="text.secondary">
                  Company
                </Typography>
                <Typography>{billingDetails?.company}</Typography>
              </Box>
              <Box sx={styles.dataBox}>
                <Typography variant="overline" color="text.secondary">
                  Name
                </Typography>
                <Typography>{billingDetails?.name}</Typography>
              </Box>
              <Box sx={styles.dataBox}>
                <Typography variant="overline" color="text.secondary">
                  Email
                </Typography>
                <Typography>{billingDetails?.email}</Typography>
              </Box>
              <Box sx={styles.dataBox}>
                <Typography variant="overline" color="text.secondary">
                  Payment Information
                </Typography>
                {!!(card?.last4 && card?.exp_month && card?.exp_year) && (
                  <Typography>{`${capitalize(card?.brand || 'card')} ending in ${card.last4}     ${
                    card.exp_month
                  }/${card.exp_year % 100}`}</Typography>
                )}
              </Box>
            </Box>
            <Box sx={styles.column}>
              <Box sx={styles.dataBox}>
                <Typography variant="overline" color="text.secondary">
                  Billing Address
                </Typography>
                {!!billingDetails?.billingAddress && (
                  <>
                    <Typography>{billingDetails.billingAddress.line1}</Typography>
                    <Typography>{billingDetails.billingAddress.line2}</Typography>
                    <Typography>{`${billingDetails.billingAddress.city}, ${billingDetails.billingAddress.state}`}</Typography>
                    <Typography>{`${billingDetails.billingAddress.postal_code} ${billingDetails.billingAddress.country}`}</Typography>
                  </>
                )}
              </Box>
              {!!customerTaxId && (
                <Box sx={styles.dataBox}>
                  <Typography variant="overline" color="text.secondary">
                    Tax ID
                  </Typography>
                  <Typography>{`${friendlyFormatTaxIdType(customerTaxId.type)} ${
                    customerTaxId.value
                  }`}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </LoadingContainer>
      </Paper>
      <EditBillingModal open={showForm} onClose={setHideForm} />
    </>
  );
}

export default BillingContactCard;
