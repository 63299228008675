import {type ImportOperationResponse, type MigrationOperationResponse} from '../types';

// When the collection has been created, but the import percentage is at 0,
// show the progress at this percent
const COLLECTION_CREATION_RATIO = 40;
const COLLECTION_ESTIMATION_TIME = 1000 * 60 * 60; // Assume collection creation takes 1 hour
// Indexes take a bit of time to get ready after reaching 100%,
// limit the displayed percentage to 98 to reduce confusion
const MAX_PERCENTAGE = 98;

export function getEffectivePercent(
  migrationOperation: MigrationOperationResponse,
  importOperation?: ImportOperationResponse,
) {
  if (!importOperation) {
    const timeSinceCreation = Date.now() - Number(new Date(migrationOperation.created_at));
    const collectionPercentEstimation = Math.min(1, timeSinceCreation / COLLECTION_ESTIMATION_TIME);
    return COLLECTION_CREATION_RATIO * collectionPercentEstimation;
  }
  return Math.min(
    COLLECTION_CREATION_RATIO +
      (100 - COLLECTION_CREATION_RATIO) * importOperation.percent_complete,
    MAX_PERCENTAGE,
  );
}
