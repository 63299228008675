import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {Box, Typography} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import InputWrapper from '@pinecone-experience/timber/Inputs/Utils/InputWrapper';
import Table from '@pinecone-experience/timber/Table';
import {useCallback} from 'react';
import {type Accept, useDropzone} from 'react-dropzone';
import {type Control, type Path, type PathValue, useController} from 'react-hook-form';

type FileAttachmentInputProps<T extends Record<string, any>> = {
  name: Path<T>;
  control: Control<T>;
  maxFiles?: number;
  acceptedFileTypes?: Accept;
  label?: string;
  error?: string;
  helperText?: string;
  fullWidth?: boolean;
};

const MAX_FILES_UPLOAD = 10;

type CellRendererProps = {
  file: File;
  row: number;
  col: number;
  name: string;
  removeFile: (index: number) => void;
};

const CellRenderer = ({file, row, col, name, removeFile}: CellRendererProps) => {
  switch (col) {
    case 0:
      return <span>{file.name}</span>;
    case 1:
      return <span>{`${(file.size / 1024).toFixed(2)} KB`}</span>;
    case 2:
      return (
        <IconButton
          id={`${name}-delete-button-${row}`}
          onClick={() => removeFile(row)}
          icon={<DeleteOutlinedIcon />}
          ariaLabel="Delete file"
        />
      );
    default:
      return null;
  }
};

function FileAttachmentInput<T extends Record<string, any>>({
  name,
  control,
  maxFiles = MAX_FILES_UPLOAD,
  acceptedFileTypes = {},
  label,
  error,
  fullWidth = false,
}: FileAttachmentInputProps<T>) {
  const {
    field: {onChange, value},
  } = useController({
    name,
    control,
    defaultValue: [] as PathValue<T, Path<T>>, // Ensure defaultValue is correctly typed
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = [...(value as File[]), ...acceptedFiles].slice(0, maxFiles);
      onChange(newFiles);
    },
    [onChange, value, maxFiles],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: acceptedFileTypes as Accept,
    maxFiles: maxFiles - (value as File[]).length,
  });

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = [...(value as File[])];
      newFiles.splice(index, 1);
      onChange(newFiles);
    },
    [value, onChange],
  );

  const createCell = useCallback(
    (row, col) => (
      <CellRenderer
        file={(value as File[])[row]}
        row={row}
        col={col}
        name={name}
        removeFile={removeFile}
      />
    ),
    [name, removeFile, value],
  );

  return (
    <InputWrapper label={label} error={error}>
      <Box sx={{width: fullWidth ? '100%' : 'auto'}}>
        <Box
          {...getRootProps()}
          sx={{
            border: 1,
            borderColor: isDragActive ? 'primary.main' : 'divider',
            backgroundColor: 'background.surface',
            borderRadius: 1,
            p: 2,
            mb: 2,
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputProps()} />

          <Typography my={1} color="secondary">
            <Typography color="primary" component="span">
              Add file
            </Typography>{' '}
            or drop files here
          </Typography>
        </Box>

        {(value as File[]).length > 0 && (
          <Table
            columns={[
              {name: 'File Name', dataType: 'string'},
              {name: 'Size', dataType: 'string'},
              {name: 'Action', dataType: 'actions'},
            ]}
            numRows={(value as File[]).length}
            cellRenderer={createCell}
          />
        )}
      </Box>
    </InputWrapper>
  );
}

export default FileAttachmentInput;
