import {Close} from '@mui/icons-material';
import {Box} from '@mui/material';
import type React from 'react';
import RowWithIcon from './RowWithIcon';

type RowWithCloseProps = {
  text: string | React.ReactNode;
  sx?: object;
};

const styles = {
  closeIcon: {
    color: 'text.disabled',
  },
};

function RowWithClose({text, sx}: RowWithCloseProps) {
  return (
    <RowWithIcon
      icon={
        <Box sx={styles.closeIcon}>
          <Close />
        </Box>
      }
      sx={sx}
    >
      {text}
    </RowWithIcon>
  );
}

export default RowWithClose;
