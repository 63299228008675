import {zodResolver} from '@hookform/resolvers/zod';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Switch from '@pinecone-experience/timber/Inputs/Switch';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {createProject} from '../../../../actions/dashboardActions';
import {type CreateProjectParams} from '../../../../api/DashboardApi';
import {PUBLIC_DOCS} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useUserId} from '../../../../selectors/userSession';
import ModalForm from '../../../ModalForm';
import {createProjectSchema} from './schema';

type ProjectCreationGlobalProps = {
  open: boolean;
  close: () => void;
};

const styles = {
  cmekOption: (cmekEnabled: boolean) => ({
    mt: 1,
    p: 2,
    display: 'flex',
    gap: 0.5,
    color: cmekEnabled ? 'text.primary' : 'text.secondary',
  }),
  cmekTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 1,
  },
  cmekDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

function ProjectCreationGlobal({open, close}: ProjectCreationGlobalProps) {
  const showCmekOption = useFlag(Flags.ENABLE_CMEK_PROJECTS);
  const {
    reset,
    formState: {isValid},
    control,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      force_encryption_with_cmek: false,
    },
    resolver: zodResolver(createProjectSchema),
  });
  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    userId: string;
    params: CreateProjectParams;
  }>();
  const selectedOrganizationId = useSelectedOrganizationId();
  const userId = useUserId();
  const data = watch();

  const onClose = () => {
    reset();
    close();
  };

  const onSubmit = (formData: {name: string; force_encryption_with_cmek: boolean}) => {
    setQueryData({
      action: createProject,
      data: {
        userId: userId || '',
        organizationId: selectedOrganizationId,
        params: {
          name: formData.name,
          environment: 'serverless',
          quota: 5,
          force_encryption_with_cmek: formData.force_encryption_with_cmek,
        },
      },
    });
  };
  return (
    <ModalForm
      open={open}
      close={onClose}
      form={
        <>
          <TextInput
            id="project-name-input"
            name="name"
            control={control}
            required
            autoFocus
            placeholder="Enter Name"
            label="Name"
            fullWidth
          />
          {showCmekOption && (
            <Card sx={styles.cmekOption(data.force_encryption_with_cmek)}>
              <Box>
                <Box sx={styles.cmekTitle}>
                  <ShieldOutlinedIcon fontSize="small" />
                  <Typography fontWeight="600">
                    Encrypt with Customer Managed Encryption Key
                  </Typography>
                </Box>
                <Box sx={styles.cmekDescription}>
                  <Typography>
                    Secure all indexes in the project with customer managed encryption key (CMEK).
                  </Typography>
                  <Typography color="secondary" variant="body2" fontStyle="italic">
                    Note: Some features may be unavailable for projects encrypted with CMEK.{' '}
                    <Link href={PUBLIC_DOCS.CMEK} target="_blank">
                      Learn more
                    </Link>
                  </Typography>
                </Box>
              </Box>
              <Switch
                name="force_encryption_with_cmek"
                control={control}
                inputProps={{'aria-label': 'Toggle CMEK encryption for the project'}}
              />
            </Card>
          )}
        </>
      }
      title="Create a project"
      customId="create-project-modal"
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Create project"
    />
  );
}

export default ProjectCreationGlobal;
