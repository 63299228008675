import {useEffect, useMemo} from 'react';
import StyledTabs from '../../../components/StyledTabs';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {ProjectNetworkTabs} from '../../../constants';
import {useNavigateToDefaultOrg} from '../../../hooks/navigation';
import {useNetworkAllowlistItem} from '../../../selectors/networkAllowlists';
import {useSelectedOrganizationPlan} from '../../../selectors/organizations';
import {useSelectedGlobalProjectId} from '../../../selectors/params';
import {usePrivateEndpointsItem} from '../../../selectors/privateEndpoints';
import {canUsePrivateEndpoints} from '../../../utils/planRules';
import AccessTab from './AccessTab/AccessTab';
import PrivateEndpointsTab from './PrivateEndpointsTab/PrivateEndpointsTab';

export default function ProjectNetworkPage() {
  const plan = useSelectedOrganizationPlan();
  const projectId = useSelectedGlobalProjectId();
  const endpointsItem = usePrivateEndpointsItem(projectId) || {};
  const allowlistItem = useNetworkAllowlistItem(projectId) || {};
  const {go: goHome} = useNavigateToDefaultOrg();

  useEffect(() => {
    if (!canUsePrivateEndpoints(plan)) {
      goHome();
    }
  }, [goHome, plan]);

  const tabs = useMemo(
    () => [
      {
        value: ProjectNetworkTabs.PRIVATELINK_ENDPOINT,
        label: 'PRIVATE ENDPOINTS',
        component: (
          <PrivateEndpointsTab
            loading={endpointsItem.loading ?? true}
            hasError={!!endpointsItem.error}
            endpoints={endpointsItem.data?.endpoints || []}
          />
        ),
      },
      {
        value: ProjectNetworkTabs.ACCESS,
        component: (
          <AccessTab
            loading={allowlistItem.loading ?? true}
            hasError={!!allowlistItem.error}
            allowlist={allowlistItem.data}
          />
        ),
      },
    ],
    [
      endpointsItem.loading,
      endpointsItem.error,
      endpointsItem.data,
      allowlistItem.loading,
      allowlistItem.error,
      allowlistItem.data,
    ],
  );

  return (
    <Page header={<PageHeader title="Network" />}>
      <StyledTabs
        tabs={tabs}
        idPrefix="network-page"
        defaultPath={ProjectNetworkTabs.PRIVATELINK_ENDPOINT}
      />
    </Page>
  );
}
