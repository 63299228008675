import {LocalStorageKeys} from '../constants';
import {type GlobalProject} from '../types';
import {storedHistory} from './stored-history';

export function getLegacyId(project: GlobalProject) {
  return `${project.environment}:${project.vault_id}`;
}

function getViewedProjectsKey(organizationId: string) {
  return `${LocalStorageKeys.VIEWED_PROJECTS_HISTORY}-${organizationId}`;
}

export const viewedProjectsHistory = {
  get: (organizationId: string) => {
    return storedHistory.get(getViewedProjectsKey(organizationId));
  },
  append: (organizationId: string, projectId: string) => {
    return storedHistory.append(getViewedProjectsKey(organizationId), projectId);
  },
  remove: (organizationId: string, projectId: string) => {
    return storedHistory.remove(getViewedProjectsKey(organizationId), projectId);
  },
};
