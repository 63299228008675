import {Link} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@pinecone-experience/timber/Table';
import {PUBLIC_DOCS} from '../../../../../constants';
import {GUTTER} from '../../../../../styles/theme';
import {type PrivateEndpoint} from '../../../../../types';
import PrivateEndpointCell from './PrivateEndpointCell/PrivateEndpointCell';

interface PrivateEndpointsListProps {
  endpoints: PrivateEndpoint[];
  loading?: boolean;
  deleteEndpoint: (id: string) => void;
  openCreateModal: () => void;
}

const styles = {
  listHeader: {
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
};

export default function PrivateEndpointsList({
  endpoints,
  loading,
  openCreateModal,
  deleteEndpoint,
}: PrivateEndpointsListProps) {
  const cellRenderer = (row: number, col: number) => (
    <PrivateEndpointCell endpoints={endpoints} row={row} col={col} onDelete={deleteEndpoint} />
  );

  return (
    <>
      <Box sx={styles.listHeader}>
        <Typography data-testid="list-description">
          Manage private connectivity to your projects via AWS PrivateLink. Read{' '}
          <Link href={PUBLIC_DOCS.AWS_PRIVATELINK} target="_blank" rel="noreferrer">
            documentation
          </Link>
          .
        </Typography>
        <Button variant="contained" onClick={openCreateModal}>
          Add an endpoint
        </Button>
      </Box>
      <Table
        isLoading={loading}
        columns={[
          {name: 'VPC Endpoint ID', dataType: 'string'},
          {name: 'Cloud', dataType: 'string'},
          {name: 'Region', dataType: 'string'},
          {name: 'Actions', dataType: 'actions'},
        ]}
        numRows={endpoints.length}
        cellRenderer={cellRenderer}
        gutter={GUTTER}
      />
    </>
  );
}
