import {Box} from '@mui/material';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useState} from 'react';
import {createCollection} from '../../actions/collectionActions';
import {deleteService} from '../../actions/serviceActions';
import {LocalStorageKeys} from '../../constants';
import {useControllerApi} from '../../hooks/use-api';
import {useSelectedOrganizationId} from '../../selectors/params';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {type GlobalProject} from '../../types';
import {localStorageSet} from '../../utils/storage';
import FinalConfirmation from './FinalConfirmation';
import IntroForm from './IntroForm';
import MigrationInProgressForm from './MigrationInProgressForm';
import MigrationNamePicker from './MigrationNamePicker';
import SelectMigrationTypeForm from './SelectMigrationTypeForm';
import {useStage} from './hooks';

const styles = {
  container: {
    width: 400,
    p: 1,
  },
};

function ActiveForm({
  activeStep,
  setActiveStep,
  project,
  service,
  doClose,
}: {
  activeStep: number;
  setActiveStep: (num: number) => void;
  project: GlobalProject;
  service: string;
  doClose: () => void;
}) {
  const [shouldCreatePod, setShouldCreatePod] = useState(false);
  const {setQueryData: setCreateCollectionData} = useControllerApi<{
    index: string;
    globalProjectId: string;
    name: string;
  }>(project);
  const [name, setName] = useState('');
  const {stage, newIndexName, collectionName} = useStage(service, project, shouldCreatePod, name);
  const doCreateCollection = () => {
    setCreateCollectionData({
      action: createCollection,
      data: {
        index: service,
        globalProjectId: project.id,
        name: collectionName,
      },
    });
  };
  const chooseCreateCollection = () => {
    setShouldCreatePod(false);
    doCreateCollection();
    setActiveStep(3);
  };
  const chooseCreateIndex = () => {
    setShouldCreatePod(true);
    setActiveStep(2);
  };
  const doSetName = (settingName: string) => {
    setName(settingName);
    doCreateCollection();
    setActiveStep(3);
  };
  const orgId = useSelectedOrganizationId();
  const {setQueryData: setDeleteQueryData} = useControllerApi<{
    name: string;
    globalProjectId: string;
  }>(project);
  const doDelete = () => {
    setDeleteQueryData({
      action: deleteService,
      data: {
        name: service,
        globalProjectId: project.id,
      },
    });
    setActiveStep(4);
  };
  switch (activeStep) {
    case 0:
      return <IntroForm onClick={() => setActiveStep(1)} close={doClose} />;
    case 1:
      return (
        <SelectMigrationTypeForm
          doCreateCollection={chooseCreateCollection}
          doMigration={chooseCreateIndex}
          service={service}
          close={doClose}
        />
      );
    case 2:
      return (
        <MigrationNamePicker service={service} globalProjectId={project.id} setName={doSetName} />
      );
    case 3:
      return (
        <MigrationInProgressForm
          service={service}
          fullMigration={shouldCreatePod}
          stage={stage}
          doDelete={doDelete}
          close={doClose}
        />
      );
    case 4:
      return (
        <FinalConfirmation
          globalProjectId={project.id}
          orgId={orgId}
          index={newIndexName}
          close={doClose}
        />
      );
    default:
      return null;
  }
}

function ForcedMigrationWarning({
  service,
  open,
  close,
}: {
  service: string;
  open: boolean;
  close: () => void;
}) {
  const project = useSelectedGlobalProject();
  const [activeStep, setActiveStep] = useState(0);
  const doClose = () => {
    setActiveStep(0);
    close();
    localStorageSet(LocalStorageKeys.VIEWED_FORCED_MIGRATION_FORM, 'true');
  };

  return (
    <BaseModal open={open} onClose={doClose}>
      <Box sx={styles.container}>
        <ActiveForm
          service={service}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          project={project}
          doClose={doClose}
        />
      </Box>
    </BaseModal>
  );
}

export default ForcedMigrationWarning;
