import {type Guide, GuideId} from '../../types';
import {Section1Header, SelectPineconeSDK} from './Section1';
import {GetYourApiKey, InitializeClient, InstallTheSDK, Section2Header} from './Section2';
import {
  CheckTheIndex,
  CreateEmbeddings,
  CreateServerlessIndex,
  Section3Header,
  UpsertVectors,
} from './Section3';
import {CreateQueryVector, RunSimilaritySearch, Section4Header} from './Section4';
import {
  DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS,
  DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS,
} from './constants';

export const DEVELOPER_QUICKSTART_GUIDE: Guide = {
  id: GuideId.DEVELOPER_QUICKSTART,
  supportedLanguages: DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS,
  sections: [
    {
      index: 0,
      title: 'Select a language',
      component: Section1Header,
      blocks: [
        {
          index: 0,
          title: 'Select SDK',
          component: SelectPineconeSDK,
        },
      ],
    },
    {
      index: 1,
      title: 'Initialize a client',
      component: Section2Header,
      blocks: [
        {
          index: 0,
          title: 'Install the SDK',
          component: InstallTheSDK,
        },
        {
          index: 1,
          title: 'Get your API key',
          component: GetYourApiKey,
        },
        {
          index: 2,
          title: 'Initialize a client',
          component: InitializeClient,
        },
      ],
    },
    {
      index: 2,
      title: 'Upsert data',
      component: Section3Header,
      blocks: [
        {
          index: 0,
          title: 'Create a serverless index',
          component: CreateServerlessIndex,
        },
        {
          index: 1,
          title: 'Create vector embeddings',
          component: CreateEmbeddings,
          supportedLanguages: DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS,
        },
        {
          index: 2,
          title: 'Upsert data',
          component: UpsertVectors,
        },
        {
          index: 3,
          title: 'Check the index',
          component: CheckTheIndex,
        },
      ],
    },
    {
      index: 3,
      title: 'Query',
      component: Section4Header,
      blocks: [
        {
          index: 0,
          title: 'Create a query vector',
          component: CreateQueryVector,
          supportedLanguages: DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS,
        },
        {
          index: 1,
          title: 'Run a similarity search',
          component: RunSimilaritySearch,
        },
      ],
    },
  ],
};
