import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {useCallback} from 'react';
import {formStepStyles} from '../stepStyles';

interface AccessStepProps {
  selectedRegion: string;
  cloudEndpointId: string;
  internetAccessDisabled: boolean;
  setInternetAccess: (disable: boolean) => void;
  onClose: () => void;
}

const styles = {
  enabled: {
    color: 'text.secondary',
  },
};

export default function AccessStep({
  selectedRegion,
  cloudEndpointId,
  internetAccessDisabled,
  setInternetAccess,
  onClose,
}: AccessStepProps) {
  const accessDescription = internetAccessDisabled
    ? 'Internet access is off. Users will no longer be able to read/write to indexes over the internet.'
    : 'This will disable internet access to the project and users will no longer be able to read/write to indexes over the internet.';

  const handleInternetAccessChange = useCallback(
    ({target}) => {
      setInternetAccess(target.checked);
    },
    [setInternetAccess],
  );

  return (
    <>
      <Box sx={formStepStyles.content}>
        <Typography data-testid="access-description">
          You can now connect to Pinecone indexes in region <InlineCode text={selectedRegion} /> via
          VPC Endpoint <InlineCode text={cloudEndpointId} />. You can optionally restrict access to
          the project via private endpoints only. You can change this at any time in network access.
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={internetAccessDisabled}
              onChange={handleInternetAccessChange}
              sx={{mr: 1}}
            />
          }
          label={
            <>
              <Typography sx={[!internetAccessDisabled && styles.enabled]} fontWeight={600}>
                Restrict access to only private endpoints
              </Typography>
              <Typography sx={[!internetAccessDisabled && styles.enabled]}>
                {accessDescription}
              </Typography>
            </>
          }
        />
      </Box>
      <Box sx={formStepStyles.actions}>
        <Button variant="contained" onClick={onClose}>
          Finish setup
        </Button>
      </Box>
    </>
  );
}
