import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type ApiKey, ProjectRoles, type RbacRoles} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {createRolesForPrincipalSuccess} from './rbacActions';
import {decodeErrorMessage} from './utils/errors';

export const createApiKeyRequest = createAction<{globalProjectId: string}>(
  'CREATE_API_KEY_REQUEST',
);
export const listApiKeysRequest = createAction<{globalProjectId: string}>('LIST_API_KEYS_REQUEST');
export const listApiKeysSuccess = createAction<{globalProjectId: string; apiKeys: ApiKey[]}>(
  'LIST_API_KEYS_SUCCESS',
);
export const createApiKeySuccess = createAction<{globalProjectId: string; apiKey: ApiKey}>(
  'CREATE_API_KEY_SUCCESS',
);
export const createApiKeyFailure = createAction<{globalProjectId: string; error: string}>(
  'CREATE_API_KEY_FAILURE',
);
export const clearCreatedApiKey = createAction<{globalProjectId: string}>('CLEAR_CREATED_API_KEY');
export const deleteApiKeyRequest = createAction<{globalProjectId: string}>(
  'DELETE_API_KEY_REQUEST',
);
export const deleteApiKeySuccess = createAction<{
  globalProjectId: string;
  label: string;
  keyId: string;
}>('DELETE_API_KEY_SUCCESS');
export const deleteApiKeyFailure = createAction<{globalProjectId: string; error: Error}>(
  'DELETE_API_KEY_FAILURE',
);
export const rotateApiKeyRequest = createAction<{globalProjectId: string}>(
  'ROTATE_API_KEY_REQUEST',
);
export const rotateApiKeySuccess = createAction<{
  globalProjectId: string;
  apiKey: ApiKey;
  keyId: string;
}>('ROTATE_API_KEY_SUCCESS');
export const rotateApiKeyFailure = createAction<{globalProjectId: string; error: Error}>(
  'ROTATE_API_KEY_FAILURE',
);

const keysAreLoading: Record<string, boolean> = {};

export function listApiKeys(token: string, data: {globalProjectId: string}) {
  if (keysAreLoading[data.globalProjectId]) {
    return () => Promise.resolve();
  }
  keysAreLoading[data.globalProjectId] = true;
  return (dispatch: Dispatch) => {
    dispatch(listApiKeysRequest({globalProjectId: data.globalProjectId}));
    return new DashboardApi(token).listApiKeys(data.globalProjectId).then((res) => {
      dispatch(listApiKeysSuccess({globalProjectId: data.globalProjectId, apiKeys: res.keys}));
      keysAreLoading[data.globalProjectId] = false;
    });
  };
}

export function createApiKey(
  token: string,
  data: {globalProjectId: string; label: string; integrationId?: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(createApiKeyRequest({globalProjectId: data.globalProjectId}));
    return new DashboardApi(token)
      .createApiKey(data.globalProjectId, data.label, [ProjectRoles.PROJECT_EDITOR])
      .then((res) => {
        dispatch(createApiKeySuccess({globalProjectId: data.globalProjectId, apiKey: res.key}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `API key "${data.label}" created successfully.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          createApiKeyFailure({globalProjectId: data.globalProjectId, error: error.message}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `API key "${data.label}" failed to create. ${error.message}`,
          }),
        );
        return error;
      });
  };
}

export function createApiKeyWithRoles(
  token: string,
  data: {globalProjectId: string; label: string; roles: RbacRoles[]; integrationId?: string},
) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .createApiKey(data.globalProjectId, data.label, data.roles, data.integrationId)
      .then((res) => {
        dispatch(createApiKeySuccess({globalProjectId: data.globalProjectId, apiKey: res.key}));
        dispatch(
          createRolesForPrincipalSuccess({
            globalProjectId: data.globalProjectId,
            principalId: res.key.id,
            roles: data.roles,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `API key "${data.label}" created successfully.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          createApiKeyFailure({globalProjectId: data.globalProjectId, error: error.messagea}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `API key "${data.label}" failed to create. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export function deleteApiKey(
  token: string,
  data: {
    globalProjectId: string;
    label: string;
    userName: string;
    keyId: string;
    displayLabel: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(deleteApiKeyRequest({globalProjectId: data.globalProjectId}));
    return new DashboardApi(token)
      .deleteApiKey(data.globalProjectId, data.label, data.userName)
      .then(() => {
        dispatch(
          deleteApiKeySuccess({
            globalProjectId: data.globalProjectId,
            label: data.label,
            keyId: data.keyId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `API key "${data.displayLabel}" deleted successfully.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(deleteApiKeyFailure({globalProjectId: data.globalProjectId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `API key "${data.displayLabel}" failed to delete.`,
          }),
        );
        return error;
      });
  };
}

export function rotateApiKey(
  token: string,
  data: {
    globalProjectId: string;
    label: string;
    userName: string;
    keyId: string;
    displayLabel: string;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(rotateApiKeyRequest({globalProjectId: data.globalProjectId}));
    return new DashboardApi(token)
      .rotateApiKey(data.globalProjectId, data.label, data.userName, data.displayLabel)
      .then((res) => {
        dispatch(
          rotateApiKeySuccess({
            globalProjectId: data.globalProjectId,
            apiKey: res.key,
            keyId: data.keyId,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `API key "${data.displayLabel}" rotated successfully.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(rotateApiKeyFailure({globalProjectId: data.globalProjectId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `API key "${data.displayLabel}" failed to rotate.`,
          }),
        );
        return error;
      });
  };
}
