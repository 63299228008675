import {Box, Button, Typography} from '@mui/material';
import ApiQuickstartCard from '../../../../components/ApiQuickstartCard/ApiQuickstartCard';
import ConnectClient from '../../../../components/CodeSnippets/ConnectClient';
import CreateIndex from '../../../../components/CodeSnippets/CreateIndex';
import InstallClient from '../../../../components/CodeSnippets/InstallClient';
import LanguageContentDocs from '../../../../components/CodeSnippets/LanguageContentDocs';
import EmptyStateCard from '../../../../components/EmptyStateCard';
import GlowWrapper from '../../../../components/Utilities/GlowWrapper';
import {LocalStorageKeys} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {
  useNavigateToCreateIndexFromDatasetPageGlobal,
  useNavigateToDatasetGalleryPageGlobal,
} from '../../../../hooks/navigation';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {localStorageGet} from '../../../../utils/storage';
import CreateIndexButton from '../CreateIndexButton/CreateIndexButton';

function IndexListEmptyState() {
  const selectedProject = useSelectedGlobalProject();
  const enableDatasets = useFlag(Flags.SHOW_CREATE_FROM_DATASET);
  const {go: goToCreateFromMoviesDataset} = useNavigateToCreateIndexFromDatasetPageGlobal(
    selectedProject.organization_id,
    selectedProject.id,
    'movies',
  );
  const {go: goToDatasetGalleryPage} = useNavigateToDatasetGalleryPageGlobal(
    selectedProject.organization_id,
    selectedProject.id,
  );

  // Using vaultId instead of id so that having this persist across spanner migration is easier
  const hasCreatedIndex = localStorageGet(
    `${LocalStorageKeys.SERVERLESS_RELEASE_HAS_CREATED_INDEX}_${selectedProject.vault_id}`,
  );

  return (
    <EmptyStateCard
      title={<Box>Welcome to your project</Box>}
      description={
        <>
          <Typography textAlign="center">
            {`You're on the path to building remarkably better GenAI applications.`}
          </Typography>
          <Typography textAlign="center">Create an index to get started.</Typography>
        </>
      }
      cta={
        <Box display="flex" gap={2}>
          <CreateIndexButton />
          <GlowWrapper doGlow={!hasCreatedIndex}>
            <Button
              onClick={enableDatasets ? goToDatasetGalleryPage : goToCreateFromMoviesDataset}
              variant="outlined"
              sx={{
                fontWeight: 'fontWeightBold',
                color: 'text.primary',
                borderColor: 'divider',
              }}
            >
              Load from dataset
            </Button>
          </GlowWrapper>
        </Box>
      }
      footer={
        <ApiQuickstartCard>
          <InstallClient
            title="Install Pinecone"
            subtitle={(name) =>
              `Ready to get started with Pinecone? First, install the ${name} SDK:`
            }
          />
          <ConnectClient
            title="Initialize"
            subtitle="Next, use your API key to initialize your client:"
            service="example-index"
          />
          <CreateIndex />
          <LanguageContentDocs
            subtext="Now you’re ready to upsert vectors into your index and start working with them. Head to our documentation for detailed information and examples:"
            global
          />
        </ApiQuickstartCard>
      }
    />
  );
}

export default IndexListEmptyState;
