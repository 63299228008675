import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {useState} from 'react';

interface ApiKeyWidgetProps {
  apiKey: string;
}

export const API_KEY_REDACTED = '********-****-****-****-************';

function ApiKeyWidget({apiKey}: ApiKeyWidgetProps) {
  const [showApiKey, setShowApiKey] = useState(false);
  const toggleApiKeyVisibility = () => setShowApiKey((visibility) => !visibility);

  return (
    <CodeBlock
      code={showApiKey ? apiKey : API_KEY_REDACTED}
      language="plaintext"
      controls={
        <IconButton
          onClick={toggleApiKeyVisibility}
          id="connect-hide-api-key"
          tooltip={showApiKey ? 'Hide API key' : 'Show API key'}
          icon={
            showApiKey ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <VisibilityOffIcon fontSize="small" />
            )
          }
        />
      }
      label="connect-api-key"
    />
  );
}

export default ApiKeyWidget;
