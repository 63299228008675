import {type Dispatch, type SetStateAction, createContext} from 'react';

interface AlertBarContextType {
  pageHasAlertBar: boolean;
  setPageHasAlertBar: Dispatch<SetStateAction<boolean>>;
}

export const AlertBarContext = createContext<AlertBarContextType>({
  pageHasAlertBar: false,
  setPageHasAlertBar: () => {},
});
