import {useParams} from 'react-router-dom';
import {type IndexDetailTab, type OrgSettingsPage} from '../constants';

export const useSelectedDatasetId = () => {
  const params = useParams();
  return params.datasetId || '';
};

export const useSelectedGlobalProjectId = () => {
  const params = useParams();

  return params.projectId || '';
};

export const useSelectedOrganizationId = (defaultValue = '') => {
  const params = useParams();
  return params.orgId || defaultValue;
};

export const useSelectedService = () => {
  const params = useParams();
  return params.indexName;
};

export const useOrganizationPageTabName = () => {
  const params = useParams<{tabName: OrgSettingsPage}>();
  return params.tabName;
};

export const useIndexDetailPageTabName = () => {
  const params = useParams();
  return params.tabName as IndexDetailTab;
};

export const useSelectedAssistantName = () => {
  const params = useParams();
  return params.assistantName;
};

export const useSelectedFileId = () => {
  const params = useParams();
  return params.fileId || '';
};

export const useSelectedIndexId = () => {
  const params = useParams();
  return params.indexId;
};
