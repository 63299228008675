import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type GetStartedProgressLog} from './types/getStartedGuideActions.types';

export const getStartedProgressRequest = createAction<undefined>(
  'GET_STARTED_PROGRESS_LOG_REQUEST',
);
export const getStartedProgressSuccess = createAction<undefined>(
  'GET_STARTED_PROGRESS_LOG_SUCCESS',
);
export const getStartedProgressFailure = createAction<Error>('GET_STARTED_PROGRESS_LOG_FAILURE');

export function logGetStartedProgress(token: string, data: GetStartedProgressLog) {
  return (dispatch: Dispatch) => {
    dispatch(getStartedProgressRequest());
    return new DashboardApi(token)
      .getStartedGuideLogProgress(data)
      .then(() => {
        dispatch(getStartedProgressSuccess());
      })
      .catch((err: Error) => {
        dispatch(getStartedProgressFailure(err));
      });
  };
}
