import {type Integration} from '@pinecone-experience/integrations';
import {useAppSelector as useSelector} from '../app/store';
import {type AsyncDataState} from '../reducers/utils';
import {Integrations} from '../utils/integrations';

const emptyArr: never[] = [];

export const useOrgIntegrations = (orgId: string) => {
  return useSelector((state) => state.integrations.organizations[orgId]?.data || emptyArr);
};

export const useOrgIntegrationsAreLoading = (orgId: string) => {
  return useSelector((state) => state.integrations.organizations[orgId]?.loading);
};

export const useOrgIntegration = (orgId: string, integrationId: string) => {
  return useSelector((state) =>
    state.integrations.organizations[orgId]?.data?.find(
      (integration) => integration.id === integrationId,
    ),
  );
};

export const useIntegrationInfo = (integrationSlug: string) => {
  return useSelector((state) => state.integrations.info[integrationSlug]) as
    | AsyncDataState<Integration>
    | undefined;
};

export const useIntegrationLogo = (integrationSlug: string) => {
  return useSelector(
    (state) =>
      state.integrations.logos[integrationSlug] ||
      Integrations.getIntegrationLogoUrl(integrationSlug),
  );
};

export const useConnectIntegration = () => {
  return useSelector((state) => state.integrations.connect);
};

export const useVercelIntegration = () => {
  return useSelector((state) => state.integrations.vercel);
};
