import {useLDClient} from 'launchdarkly-react-client-sdk';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getOrganizationEnvironments} from '../../../../actions/environmentActions';
import {NotificationTypes, enqueNotification} from '../../../../actions/notificationActions';
import {
  fetchSupport,
  getOrganizationUsers,
  getPendingInvites,
  resetStripeState,
} from '../../../../actions/organizationActions';
import {DEEPLINK_TOKEN, LocalStorageKeys} from '../../../../constants';
import {useAuth} from '../../../../hooks/auth';
import {useDefaultOrgId} from '../../../../hooks/defaults';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useNavigateToSelectProject} from '../../../../hooks/navigation';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useIsOwnerOfOrganization, useIsSelectedOrgValid} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {localStorageSet} from '../../../../utils/storage';
import {setHeapProperty} from '../../../../utils/tracking';
import SubstituteDefaultOrg from '../../../Navigation/SubstituteDefaultOrg';
import MainLayout from '../../MainLayout/MainLayout';

type InvalidOrgIdProps = {
  orgId: string;
};

function InvalidOrgId({orgId}: InvalidOrgIdProps) {
  const dispatch = useDispatch();
  const defaultOrgId = useDefaultOrgId();
  const {go: goToSelectProject} = useNavigateToSelectProject(defaultOrgId);

  useEffect(() => {
    dispatch(
      enqueNotification({
        type: NotificationTypes.ERROR,
        text: `Could not find organization '${orgId}'.`,
      }),
    );
    goToSelectProject();
  }, [dispatch, goToSelectProject, orgId]);

  return null;
}

function useRefreshOrgData() {
  const selectedOrgId = useSelectedOrganizationId();
  const isValidOrg = useIsSelectedOrgValid();
  const isOwner = useIsOwnerOfOrganization();
  const showSupport = useFlag(Flags.ENABLE_SUPPORT_PLANS) && isOwner;

  const {setQueryData: setQueryDataUsers} = useDashboardApi<{organizationId: string}>();
  const {setQueryData: setQueryDataInvites} = useDashboardApi<{organizationId: string}>();
  const {setQueryData: setQueryOrgEnvironments} = useDashboardApi<{organizationId: string}>();
  const {setQueryData: setQueryOrgSupport} = useDashboardApi<{organizationId: string}>();
  useEffect(() => {
    if (isValidOrg) {
      setQueryDataUsers({action: getOrganizationUsers, data: {organizationId: selectedOrgId}});
      setQueryDataInvites({action: getPendingInvites, data: {organizationId: selectedOrgId}});
      setQueryOrgEnvironments({
        action: getOrganizationEnvironments,
        data: {organizationId: selectedOrgId},
      });
      if (showSupport) {
        setQueryOrgSupport({action: fetchSupport, data: {organizationId: selectedOrgId}});
      }
    }
  }, [
    isValidOrg,
    selectedOrgId,
    setQueryDataInvites,
    setQueryDataUsers,
    setQueryOrgEnvironments,
    setQueryOrgSupport,
    showSupport,
  ]);
}

/**
 * Ensures that a valid organization has been selected before rendering the route.
 * OrganizationGuard should be rendered as a child of OrganizationDataGuard.
 */
function OrganizationGuard() {
  const selectedOrgId = useSelectedOrganizationId();
  const isValidOrg = useIsSelectedOrgValid();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isValidOrg) {
      dispatch(resetStripeState());
      setHeapProperty({orgId: selectedOrgId});
      localStorageSet(LocalStorageKeys.LAST_SELECTED_ORGANIZATION, selectedOrgId);
    }
  }, [selectedOrgId, dispatch, isValidOrg]);

  const {user} = useAuth();
  const ldClient = useLDClient();
  useEffect(() => {
    if (isValidOrg && user && ldClient) {
      ldClient.identify({
        kind: 'multi',
        user: {
          key: user.sub,
          name: user.name,
          email: user.email,
        },
        organization: {
          key: selectedOrgId,
        },
      });
    }
  }, [isValidOrg, ldClient, selectedOrgId, user]);

  useRefreshOrgData();

  if (selectedOrgId === DEEPLINK_TOKEN) {
    return <SubstituteDefaultOrg />;
  }
  if (!isValidOrg) {
    return <InvalidOrgId orgId={selectedOrgId} />;
  }
  return <MainLayout />;
}

export default OrganizationGuard;
