import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {AssistantFileStatus} from '../../constants';
import {usePollAssistantFileState} from '../../hooks/assistants';
import {useAssistantFileStatusColorMap} from '../../hooks/colorMaps';
import {useSelectedAssistantName, useSelectedGlobalProjectId} from '../../selectors/params';

type AssistantFileStatusLabelProps = {
  fileId: string;
  inline?: boolean;
};

const styles = {
  root: {
    width: 'max-content',
  },
  inlineRoot: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  status: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  statusIcon: {
    display: 'inline-block',
    borderRadius: '100%',
    height: 8,
    width: 8,
  },
  processingBar: {
    mt: 1,
    width: '120px',
  },
  inlineProcessingBar: {
    width: '120px',
    display: 'inline-block',
  },
};

export default function AssistantFileStatusLabel({fileId, inline}: AssistantFileStatusLabelProps) {
  const selectedProjectId = useSelectedGlobalProjectId();
  const assistantName = useSelectedAssistantName() || '';
  const {status, percentDone} = usePollAssistantFileState(fileId, assistantName, selectedProjectId);
  const color = useAssistantFileStatusColorMap(status);
  const processingPercentage = percentDone * 100;
  const activelyProcessing =
    status === AssistantFileStatus.PROCESSING &&
    processingPercentage > 0 &&
    processingPercentage < 99;

  const statusLabel =
    status === AssistantFileStatus.PROCESSING_FAILED ? 'Processing failed' : status;
  const label = activelyProcessing ? `${processingPercentage}% processed` : statusLabel;

  return (
    <Box sx={inline ? styles.inlineRoot : styles.root}>
      <Box sx={styles.status}>
        <Box sx={[styles.statusIcon, {background: color}]} id="index-status-indicator" />
        <Typography variant="inherit">{label}</Typography>
      </Box>
      {status === AssistantFileStatus.PROCESSING && (
        <LinearProgress
          sx={inline ? styles.inlineProcessingBar : styles.processingBar}
          color="secondary"
          variant={activelyProcessing ? 'determinate' : 'query'}
          value={processingPercentage}
        />
      )}
    </Box>
  );
}
