import {LANGUAGE_OPTIONS} from '../../../components/CodeSnippets/constants';

interface GetStartedSection {
  title: string;
  description: string;
  code?: string;
}

type GetStartedContent = GetStartedSection[];

export const ASSISTANT_DOCS_LANGUAGES = [LANGUAGE_OPTIONS.python, LANGUAGE_OPTIONS.shell];
export type AssistantLanguageTypes = (typeof ASSISTANT_DOCS_LANGUAGES)[number];

export const GETTING_STARTED_CONTENT: Record<AssistantLanguageTypes, GetStartedContent> = {
  [LANGUAGE_OPTIONS.python]: [
    {
      title: 'Install Pinecone SDK and the Assistant plugin',
      description:
        'To start using Pinecone Assistant with the Python SDK, install or upgrade the package as follows:',
      code: `pip install --upgrade pinecone pinecone-plugin-assistant`,
    },
    {
      title: 'Create a new Assistant',
      description:
        'To create an assistant, use the create assistant endpoint as in the following example:',
      code: `from pinecone import Pinecone
pc = Pinecone(api_key="<<PINECONE_API_KEY>>")
metadata = {"author": "Jane Doe", "version": "1.0"}

assistant = pc.assistant.create_assistant(
    assistant_name="example-assistant", 
    metadata=metadata, 
    timeout=30 # Wait 30 seconds for assistant operation to complete.
)`,
    },
  ],
  [LANGUAGE_OPTIONS.shell]: [
    {
      title: 'Create a new Assistant',
      description:
        'To create an assistant, use the create assistant endpoint as in the following example:',
      code: `PINECONE_API_KEY="<<PINECONE_API_KEY>>"
ASSISTANT_NAME="example-assistant"
METADATA="{'author':'Jane Doe', 'version':'1.0'}"

curl --request POST \
  --url https://api.pinecone.io/assistant/assistants \
  --header "Api-Key: $PINECONE_API_KEY" \
  --header 'Content-Type: application/json' \
  --data '{
  "name": "$ASSISTANT_NAME",
  "metadata": "$METADATA"
}'`,
    },
  ],
};

export const CONNECT_CONTENT: Record<
  AssistantLanguageTypes,
  (assistantName: string) => GetStartedContent
> = {
  [LANGUAGE_OPTIONS.python]: (assistantName: string) => [
    {
      title: 'Install SDK and plugin',
      description:
        'To start using Pinecone Assistant with the Python SDK, install or upgrade the package as follows:',
      code: `pip install --upgrade pinecone pinecone-plugin-assistant`,
    },
    {
      title: 'Upload files',
      description:
        'To upload a file to your assistant from your local device, use the create file operation as in the following example.',
      code: `from pinecone import Pinecone
pc = Pinecone(api_key="<<PINECONE_API_KEY>>")

assistant = pc.assistant.Assistant(
    assistant_name="${assistantName}", 
)

response = assistant.upload_file(
    file_path="/Users/jdoe/Downloads/example_file.txt"
    timeout=None
)`,
    },
    {
      title: 'Chat with your Assistant',
      description:
        'To chat with an assistant, use the chat completion assistant endpoint. This operation returns either a JSON object or a text stream.',
      code: `from pinecone import Pinecone
from pinecone_plugins.assistant.models.chat import Message

pc = Pinecone(api_key='<<PINECONE_API_KEY>>')

assistant = pc.assistant.Assistant(assistant_name="${assistantName}")

msg = Message(content="How old is the earth?")
resp = assistant.chat_completions(messages=[msg])

# With streaming
chunks = assistant.chat_completions(messages=[msg], stream=True, model="gpt-4o")

for chunk in chunks:
    if chunk:
        print(chunk)`,
    },
  ],
  [LANGUAGE_OPTIONS.shell]: (assistantName: string) => [
    {
      title: 'Upload files',
      description:
        'To upload a file to your assistant from your local device, use the create file operation as in the following example.',
      code: `PINECONE_API_KEY="YOUR_API_KEY"
ASSISTANT_NAME="${assistantName}"
LOCAL_FILE_PATH="/Users/jdoe/Downloads/example_file.txt"

curl --request POST \
  --url prod-1-data.ke.pinecone.io/assistant/files/$ASSISTANT_NAME \
  --header "Api-Key: $PINECONE_API_KEY" \
  -F "file=@$LOCAL_FILE_PATH"`,
    },
    {
      title: 'Chat with your Assistant',
      description:
        'To chat with an assistant, use the chat completion assistant endpoint. This operation returns either a JSON object or a text stream.',
      code: `PINECONE_API_KEY="YOUR_API_KEY"
ASSISTANT_NAME="${assistantName}"

curl --request POST \
  --url https://prod-1-data.ke.pinecone.io/assistant/chat/$ASSISTANT_NAME/chat/completions \
  --header 'Api-Key: $PINECONE_API_KEY' \
  --header 'Content-Type: application/json' \
  --data '{
    "model": "gpt-4o",
    "streaming": true,
    "messages": [
      {
      "role": "user",
      "content": "What is the maximum height of a red pine?"
    }
  ]
}'`,
    },
  ],
};
