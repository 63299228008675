import {Box} from '@mui/material';
import {Plans} from '../../../../constants';
import {
  useSelectedOrganizationIsSuspended,
  useSelectedOrganizationPlan,
} from '../../../../selectors/organizations';
import BillingContactCard from './BillingContactCard/BillingContactCard';
import CurrentPlanCard from './CurrentPlanCard/CurrentPlanCard';
import SpecialPlanCard from './SpecialPlanCard/SpecialPlanCard';
import SuspendedPlanCard from './SuspendedPlanCard/SuspendedPlanCard';

interface BillingDetailsProps {
  showPlanInfo?: boolean;
}

const styles = {
  container: {
    display: 'flex',
    gap: 3,
    mb: 3,
  },
};

const BillingDetails = ({showPlanInfo}: BillingDetailsProps) => {
  const currentPlan = useSelectedOrganizationPlan();
  const isSuspended = useSelectedOrganizationIsSuspended();
  if (currentPlan === Plans.DEDICATED || currentPlan === Plans.INTERNAL) {
    return <SpecialPlanCard />;
  }
  return (
    <Box sx={styles.container}>
      <BillingContactCard />
      {isSuspended ? <SuspendedPlanCard /> : <CurrentPlanCard showPlanInfo={showPlanInfo} />}
    </Box>
  );
};

export default BillingDetails;
