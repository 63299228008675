import {Box, Card, Paper, Typography} from '@mui/material';
import {useEffect} from 'react';
import {type Control, type UseFormSetValue, type UseFormWatch} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import StyledTabs from '../../../../components/StyledTabs';
import RegionPicker from '../../../../components/environment/RegionPicker/RegionPicker';
import {CreateIndexTabs} from '../../../../constants';
import {
  useNavigateToCreateIndexGlobal,
  useNavigateToCreateStarterIndexGlobal,
} from '../../../../hooks/navigation';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import AdvancedConfiguration from '../AdvancedConfiguration/AdvancedConfiguration';
import SelectPodType from '../SelectPodType/SelectPodType';
import {type IndexSchema} from '../utilities/schema';
import {useAvailableEnvironmentsForTab} from './hooks/capacityModeEnvironments';

const styles = {
  tabs: {
    backgroundColor: 'none',
  },
  capacityCard: {
    border: 'solid 1px',
    borderColor: 'primary.main',
    borderRadius: 1,
    mt: 2,
    p: 3,
  },
};

type FormValuesWithRegionInfo = {
  provider: string;
  environment: string;
};

type CapacityModesProps<T extends FormValuesWithRegionInfo> = {
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  control: T extends FormValuesWithRegionInfo ? Control<T> : never;
  fromCollection?: boolean;
  fromBackup?: boolean;
};

function CapacityModes<T extends FormValuesWithRegionInfo>({
  setValue,
  watch,
  control,
  fromCollection = false,
  fromBackup = false,
}: CapacityModesProps<T>) {
  const curTab = useParams()['*'];
  const orgId = useSelectedOrganizationId();
  const viewerHasSubscription = useIsSelectedOrganizationSubscribed();
  const project = useSelectedGlobalProject();

  const {go: navigateToCreateIndex} = useNavigateToCreateIndexGlobal(orgId, project.id || '');
  const {go: navigateToCreateStarter} = useNavigateToCreateStarterIndexGlobal(
    orgId,
    project.id || '',
  );

  useEffect(() => {
    // If serverless free tier is available, and free user is on starter tab, reset the page path.
    if (!viewerHasSubscription && curTab === CreateIndexTabs.STARTER) {
      navigateToCreateIndex();
    }
  }, [viewerHasSubscription, curTab, navigateToCreateIndex, navigateToCreateStarter]);

  const capacityModeTabs = [
    {
      value: CreateIndexTabs.SERVERLESS,
      disabled: false,
      component: (
        <Paper sx={styles.capacityCard}>
          <Typography component="span" variant="h6" pr={2} pb={1}>
            Serverless
          </Typography>
          <Typography variant="body1">
            Charges based on data storage, reads, and writes.{' '}
          </Typography>
        </Paper>
      ),
    },
    {
      value: CreateIndexTabs.PODS,
      hidden: project.force_encryption_with_cmek,
      disabled: !viewerHasSubscription || fromBackup,
      tooltip: !viewerHasSubscription
        ? 'Upgrade to a paid plan for access to full pod-based indexes'
        : undefined,
      component: (
        <Box>
          <SelectPodType control={control as unknown as Control<IndexSchema>} isFree={false} />
          <AdvancedConfiguration
            control={control as unknown as Control<IndexSchema>}
            hidePaid={false}
          />
        </Box>
      ),
    },
    {
      value: CreateIndexTabs.STARTER,
      hidden: !viewerHasSubscription || project.force_encryption_with_cmek,
      disabled: (fromCollection && curTab !== CreateIndexTabs.STARTER) || fromBackup,
      component: (
        <Box>
          <Paper sx={styles.capacityCard}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h6" pr={2}>
                Starter
              </Typography>
              <Typography>Free index with a capacity for 100k records.</Typography>
            </Box>
          </Paper>
        </Box>
      ),
    },
  ];
  return (
    <>
      <Card>
        <Typography variant="h6" mb={1}>
          Capacity mode
        </Typography>
        <StyledTabs
          tabs={capacityModeTabs}
          idPrefix="create-project-capacity-mode"
          defaultPath="serverless"
          sx={styles.tabs}
          contentSx={{pt: 0}}
        />
      </Card>
      <RegionPicker
        control={control}
        watch={watch}
        availableEnvironments={useAvailableEnvironmentsForTab(
          curTab,
          fromCollection,
          project.force_encryption_with_cmek,
        )}
        setValue={setValue}
        isServerless={curTab === CreateIndexTabs.SERVERLESS}
        locked={fromBackup || (fromCollection && curTab !== CreateIndexTabs.SERVERLESS)}
        viewerHasSubscription={viewerHasSubscription}
      />
    </>
  );
}

export default CapacityModes;
