import {type Integration as PublicIntegration} from '@pinecone-experience/integrations';
import {createAction} from '@reduxjs/toolkit';
import {type Dispatch} from 'redux';
import DashboardApi from '../api/DashboardApi';
import type {AppDispatch} from '../app/store';
import {API_KEY_LABEL_MAX_LENGTH, DASHBOARD_BASE_URL_CLOUDRUN} from '../constants';
import {type ConnectIndexVercel} from '../pages/integrations/vercel/OAuthVercel2/ConnectIndex/ConnectIndex';
import {
  type ApiKey,
  type Integration,
  type IntegrationCreateData,
  type IntegrationUpdateData,
  ProjectRoles,
} from '../types';
import {type VercelOAuthConnection} from '../types/vercel';
import {Integrations} from '../utils/integrations';
import {generateRandomName} from '../utils/random';
import {createApiKeyFailure, createApiKeyRequest, createApiKeySuccess} from './apiKeyActions';
import {createProjectFailure, createProjectRequest, createProjectSuccess} from './dashboardActions';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {createRolesForPrincipalSuccess} from './rbacActions';

export const createVercelIntegrationRequest = createAction<undefined>(
  'CREATE_VERCEL_INTEGRATION_REQUEST',
);
export const createVercelIntegrationSuccess = createAction<{success: boolean}>(
  'CREATE_VERCEL_INTEGRATION_SUCCESS',
);
export const createVercelIntegrationError = createAction<{message: string}>(
  'CREATE_VERCEL_INTEGRATION_ERROR',
);

export const connectIndexVercelRequest = createAction<undefined>('CONNECT_INDEX_VERCEL_REQUEST');
export const connectIndexVercelSuccess = createAction<{
  success: boolean;
}>('CONNECT_INDEX_VERCEL_SUCCESS');
export const connectIndexVercelError = createAction<{message: string}>(
  'CONNECT_INDEX_VERCEL_ERROR',
);

export const createVercelOAuthConnectionRequest = createAction<undefined>(
  'CREATE_VERCEL_OAUTH_CONNECTION_REQUEST',
);
export const createVercelOAuthConnectionSuccess = createAction<{
  connection: VercelOAuthConnection;
}>('CREATE_VERCEL_OAUTH_CONNECTION_SUCCESS');
export const createVercelOAuthConnectionError = createAction<{message: string}>(
  'CREATE_VERCEL_OAUTH_CONNECTION_ERROR',
);

export const connectOrganizationOAuthRequest = createAction<undefined>(
  'CONNECT_ORGANIZATION_OAUTH_REQUEST',
);
export const connectOrganizationOAuthSuccess = createAction<{
  organizationId: string;
}>('CONNECT_ORGANIZATION_OAUTH_SUCCESS');
export const connectOrganizationOAuthError = createAction<{message: string}>(
  'CONNECT_ORGANIZATION_OAUTH_ERROR',
);

export function connectIndexVercel(token: string, data: ConnectIndexVercel) {
  return (dispatch: Dispatch) => {
    dispatch(connectIndexVercelRequest());
    return new DashboardApi(token)
      .connectIndexVercel(data)
      .then(() => {
        dispatch(connectIndexVercelSuccess({success: true}));
      })
      .catch((error) => {
        dispatch(connectIndexVercelError({message: error.message}));
        return error;
      });
  };
}

export function createVercelOAuthConnection(token: string, data: {code: string; oauthId?: string}) {
  return (dispatch: Dispatch) => {
    dispatch(createVercelOAuthConnectionRequest());
    return new DashboardApi(token)
      .createVercelOAuthConnection(data)
      .then((connectionData) => {
        dispatch(createVercelOAuthConnectionSuccess(connectionData));
      })
      .catch((error) => {
        dispatch(createVercelOAuthConnectionError({message: error.message}));
        return error;
      });
  };
}

export function connectOrganizationOAuth(
  token: string,
  data: {id: string; organizationId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(connectOrganizationOAuthRequest());
    return new DashboardApi(token)
      .connectOrganizationOAuth(data)
      .then((connectData) => {
        dispatch(connectOrganizationOAuthSuccess(connectData));
      })
      .catch((error) => {
        dispatch(createVercelOAuthConnectionError({message: error.message}));
        return error;
      });
  };
}

export const connectProjectRequest = createAction<undefined>('CONNECT_PROJECT_REQUEST');
export const connectProjectSuccess = createAction<{
  organizationId: string;
  globalProjectId: string;
  apiKey: ApiKey;
}>('CONNECT_PROJECT_SUCCESS');
export const connectProjectFailure = createAction<{error: Error}>('CONNECT_PROJECT_FAILURE');
export const connectProjectReset = createAction<undefined>('CONNECT_PROJECT_RESET');

function connectApiKey(
  token: string,
  data: {
    integrationId: string;
    organizationId: string;
    globalProjectId: string;
  },
) {
  const {organizationId, globalProjectId} = data;
  const roles: ProjectRoles[] = [ProjectRoles.PROJECT_EDITOR];
  return (dispatch: Dispatch) => {
    dispatch(createApiKeyRequest({globalProjectId}));
    return new DashboardApi(token)
      .createApiKey(
        globalProjectId,
        generateRandomName(API_KEY_LABEL_MAX_LENGTH),
        roles,
        data.integrationId,
      )
      .then((res) => {
        dispatch(connectProjectSuccess({organizationId, globalProjectId, apiKey: res.key}));
        dispatch(createApiKeySuccess({globalProjectId, apiKey: res.key}));
        dispatch(
          createRolesForPrincipalSuccess({
            globalProjectId,
            principalId: res.key.id,
            roles,
          }),
        );
      })
      .catch((error) => {
        dispatch(connectProjectFailure({error}));
        dispatch(createApiKeyFailure({globalProjectId, error}));
        return error;
      });
  };
}

export function connectProject(
  token: string,
  data: {
    integrationId: string;
    organizationId: string;
    globalProjectId: string;
    newProjectName?: string;
    userId: string;
  },
) {
  return (dispatch: AppDispatch) => {
    dispatch(connectProjectRequest());

    // using an existing project
    if (data.globalProjectId) {
      return dispatch(connectApiKey(token, data));
    }

    // need to create a new project before creating an API key
    if (!data.newProjectName) {
      throw Error('Missing project name');
    }
    dispatch(createProjectRequest());
    return new DashboardApi(token)
      .createProject(data.organizationId, {
        name: data.newProjectName,
        environment: 'serverless',
        quota: 5,
      })
      .then((res) => {
        dispatch(
          createProjectSuccess({
            organizationId: data.organizationId,
            project: res.globalProject,
            userId: data.userId,
          }),
        );
        return dispatch(
          connectApiKey(token, {
            integrationId: data.integrationId,
            organizationId: data.organizationId,
            globalProjectId: res.globalProject.id,
          }),
        );
      })
      .catch((error) => {
        dispatch(createProjectFailure(error));
        dispatch(connectProjectFailure({error}));
        return error;
      });
  };
}

export const getIntegrationInfoRequest = createAction<{slug: string}>(
  'GET_INTEGRATION_INFO_REQUEST',
);
export const getIntegrationInfoSuccess = createAction<PublicIntegration>(
  'GET_INTEGRATION_INFO_SUCCESS',
);
export const getIntegrationInfoFailure = createAction<{slug: string; error: Error}>(
  'GET_INTEGRATION_INFO_FAILURE',
);

export function getIntegrationInfo(slug: string) {
  return (dispatch: Dispatch) => {
    dispatch(getIntegrationInfoRequest({slug}));
    return Integrations.getIntegration(slug)
      .then((res) => {
        dispatch(getIntegrationInfoSuccess(res));
      })
      .catch((error) => {
        dispatch(getIntegrationInfoFailure({slug, error}));
        return error;
      });
  };
}

export const listIntegrationsRequest = createAction<{
  organizationId: string;
}>('LIST_INTEGRATIONS_REQUEST');
export const listIntegrationsSuccess = createAction<{
  organizationId: string;
  integrations: Integration[];
}>('LIST_INTEGRATIONS_SUCCESS');
export const listIntegrationsFailure = createAction<{
  organizationId: string;
  error: Error;
}>('LIST_INTEGRATIONS_FAILURE');

export function listIntegrations(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listIntegrationsRequest(data));
    return new DashboardApi(token)
      .listIntegrations(data.organizationId)
      .then((res) => {
        dispatch(listIntegrationsSuccess({...data, ...res}));
      })
      .catch((error) => {
        dispatch(listIntegrationsFailure({...data, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to load integrations. ${error.message}`,
          }),
        );
      });
  };
}

export const updateIntegrationLogo = createAction<{
  integrationSlug: string;
  url: string;
}>('UPDATE_INTEGRATION_LOGO');

export const createIntegrationRequest = createAction<{
  organizationId: string;
}>('CREATE_INTEGRATION_REQUEST');
export const createIntegrationSuccess = createAction<{
  organizationId: string;
  integration: Integration;
}>('CREATE_INTEGRATION_SUCCESS');
export const createIntegrationFailure = createAction<{
  organizationId: string;
  error: Error;
}>('CREATE_INTEGRATION_FAILURE');

export function createIntegration(
  token: string,
  data: {organizationId: string; data: IntegrationCreateData},
) {
  return (dispatch: AppDispatch) => {
    dispatch(createIntegrationRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .createIntegration(data.organizationId, data.data)
      .then((res) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Integration '${res.slug}' has been created.`,
          }),
        );
        const url = `${DASHBOARD_BASE_URL_CLOUDRUN}/connect/${
          res.slug
        }/logo?t=${new Date().getTime()}`;
        dispatch(updateIntegrationLogo({integrationSlug: res.slug, url}));
        dispatch(createIntegrationSuccess({organizationId: data.organizationId, integration: res}));
      })
      .catch((error) => {
        dispatch(createIntegrationFailure({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to create integration '${data.data.slug}'. ${error.message}`,
          }),
        );
      });
  };
}

export const editIntegrationRequest = createAction<{
  organizationId: string;
}>('EDIT_INTEGRATION_REQUEST');
export const editIntegrationSuccess = createAction<{
  organizationId: string;
  integration: Integration;
}>('EDIT_INTEGRATION_SUCCESS');
export const editIntegrationFailure = createAction<{
  organizationId: string;
  error: Error;
}>('EDIT_INTEGRATION_FAILURE');

export function editIntegration(
  token: string,
  data: {organizationId: string; integrationId: string; data: IntegrationUpdateData},
) {
  return (dispatch: AppDispatch) => {
    dispatch(editIntegrationRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .editIntegration(data.organizationId, data.integrationId, data.data)
      .then((res) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Integration '${res.slug}' has been updated.`,
          }),
        );
        if (data.data.logo) {
          const url = `${DASHBOARD_BASE_URL_CLOUDRUN}/connect/${
            res.slug
          }/logo?t=${new Date().getTime()}`;
          dispatch(updateIntegrationLogo({integrationSlug: res.slug, url}));
        }
        dispatch(editIntegrationSuccess({organizationId: data.organizationId, integration: res}));
      })
      .catch((error) => {
        dispatch(editIntegrationFailure({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to update integration. ${error.message}`,
          }),
        );
      });
  };
}

export const deleteIntegrationRequest = createAction<{
  organizationId: string;
}>('DELETE_INTEGRATION_REQUEST');
export const deleteIntegrationSuccess = createAction<{
  organizationId: string;
  integrationId: string;
}>('DELETE_INTEGRATION_SUCCESS');
export const deleteIntegrationFailure = createAction<{
  organizationId: string;
  error: Error;
}>('DELETE_INTEGRATION_FAILURE');

export function deleteIntegration(
  token: string,
  data: {organizationId: string; integrationId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(deleteIntegrationRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .deleteIntegration(data.organizationId, data.integrationId)
      .then(() => {
        dispatch(deleteIntegrationSuccess(data));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Integration has been deleted.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(deleteIntegrationFailure({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to delete integration. ${error.message}`,
          }),
        );
      });
  };
}
