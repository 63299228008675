import {type GlobalProject} from '../types';
import {viewedProjectsHistory} from './projects';
import {viewedIndexHistory} from './services';

export function getRecentIndexesToDisplay(
  globalProjectId: string,
  allIndexes: string[],
  limit?: number,
) {
  const history = viewedIndexHistory.get(globalProjectId) || [];
  const viewedIndexes = history.filter((index) => allIndexes.includes(index));

  return Array.from(new Set([...viewedIndexes, ...allIndexes])).slice(0, limit);
}

export function getRecentProjectIdsToDisplay(
  orgId: string,
  projects: GlobalProject[],
  limit?: number,
) {
  const history = viewedProjectsHistory.get(orgId) || [];
  const projectIds = projects.map(({id}) => id);
  const viewedProjectIds = history.filter((id) => projectIds.includes(id));

  return Array.from(new Set([...viewedProjectIds, ...projectIds])).slice(0, limit);
}
