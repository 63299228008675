import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Chip from '@mui/material/Chip';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {getLabel} from './utils/getLabel';

interface ProjectEncryptionBadgeProps {
  encrypted?: boolean;
  cmekEncrypted?: boolean;
  condensed?: boolean;
}

const styles = {
  root: {
    borderColor: 'divider',
  },
  condensed: {
    border: 'none',
    span: {
      px: 0.5,
    },
  },
};

export default function ProjectEncryptionBadge({
  encrypted,
  cmekEncrypted = false,
  condensed = false,
}: ProjectEncryptionBadgeProps) {
  if (!encrypted && !cmekEncrypted) {
    return null;
  }

  return (
    <Tooltip
      title={`This project’s data and indexes are protected with ${
        cmekEncrypted ? 'CMEK ' : ''
      }encryption.`}
    >
      <Chip
        sx={[styles.root, condensed && styles.condensed]}
        variant="outlined"
        size={condensed ? 'small' : 'medium'}
        icon={<ShieldOutlinedIcon fontSize="small" color="secondary" />}
        label={getLabel(cmekEncrypted, condensed)}
      />
    </Tooltip>
  );
}
