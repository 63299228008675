import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Link, Paper, Typography} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {type IntegrationFormData} from '../utilities/schema';
import styles from '../utilities/styles';

/**
 * Form field for the allowed web message origins.
 */
function AllowedOriginsField() {
  const {
    control,
    watch,
    formState: {errors},
  } = useFormContext<IntegrationFormData>();
  const allowedOrigins = watch('allowed_origins');
  const {fields, append, remove} = useFieldArray({control, name: 'allowed_origins'});

  // Ensure that at least one text box is always present.
  useEffect(() => {
    if (!allowedOrigins || allowedOrigins.length === 0) {
      append({origin: ''});
    }
  }, [allowedOrigins, append]);

  return (
    <Paper sx={styles.section}>
      <Typography color="text.primary" fontWeight="500" mr={1}>
        Allowed origin
      </Typography>
      {fields.map((field, index) => (
        <TextInput
          key={field.id}
          name={`allowed_origins.${index}.origin`}
          id={`allowed-origins-${index}`}
          control={control}
          fullWidth
          endAdornment={
            fields.length > 1 && (
              <IconButton
                id={`allowed-origins-remove-${index}`}
                icon={<CloseIcon />}
                shape="circle"
                onClick={() => remove(index)}
              />
            )
          }
        />
      ))}
      {errors.allowed_origins && (
        <Typography variant="body2" color="error" sx={styles.error}>
          {errors.allowed_origins.message}
        </Typography>
      )}
      <Button
        id="allowed-origins-add"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => append({origin: ''})}
      >
        Add allowed origin
      </Button>
      <Typography mt={1}>
        List the URL origin(s) where your integration is hosted. The{' '}
        <Link href="https://developer.mozilla.org/en-US/docs/Glossary/Origin">origin</Link> is the
        part of the URL that specifies the protocol, hostname, and port. For example, if your
        integration was hosted at <code>https://www.example.org/integration</code>, the origin would
        be <code>https://www.example.org</code>.
      </Typography>
      <Typography mt={1}>
        You can use <code>*</code> as a wildcard. For example, <code>https://*.example.org</code>{' '}
        would match both <code>https://one.example.org</code> and{' '}
        <code>https://two.example.org</code>, but not <code>https://example.org</code>.
      </Typography>
    </Paper>
  );
}

export default AllowedOriginsField;
