import {z} from 'zod';
import {BulkImportErrorModes} from '../../constants';

export const importDataSchema = z.object({
  integration: z.string().nonempty('Integration is required'),
  uri: z
    .string()
    .nonempty('Data source is required')
    .refine((uri) => uri.startsWith('s3://'), {
      message: 'Data source must start with s3://',
    }),
  onError: z.enum(Object.values(BulkImportErrorModes) as [string, ...string[]]),
});
export type ImportDataSchema = z.infer<typeof importDataSchema>;
