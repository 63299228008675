import {type SliceTooltipProps} from '@nivo/line';
import ChartTooltip from '../../../../../../components/chart/ChartTooltip/ChartTooltip';
import {type QuantileLabel, quantilesNameMap} from '../utilities/LatencyUtils';

const LatencyTooltip = ({slice}: SliceTooltipProps) => {
  const {points} = slice;
  const time = points[0].data.x;
  const items = points.map((point) => {
    const quantile = String(point.serieId).split(' ')[1] as QuantileLabel;
    return {
      color: point.serieColor,
      label: quantilesNameMap[quantile],
      value: String(point.data.y),
      unit: 'MS',
    };
  });
  return <ChartTooltip timestamp={new Date(time)} items={items} />;
};

export default LatencyTooltip;
