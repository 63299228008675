import {Box, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import {useMemo, useState} from 'react';
import {Flags, useFlag} from '../../../../hooks/flags';
import {GUTTER} from '../../../../styles/theme';
import {type ApiKey} from '../../../../types';
import ApiKeyActions from './ApiKeyActions/ApiKeyActions';
import ApiKeyPermissionsCell from './ApiKeyPermissionsCell/ApiKeyPermissionsCell';
import IntegrationChip from './IntegrationChip/IntegrationChip';

type ApiKeyTableProps = {
  /** The API keys to display in the table. */
  apiKeys: ApiKey[];
  /**
   * Operation to perform when the user chooses to delete an API key. If not present, the delete
   * button will not be rendered.
   */
  onDelete?: (apiKey: ApiKey) => void;
  /**
   * Operation to perform when the user chooses to rotate an API key. If not present, the rotate
   * button will not be rendered.
   */
  onRotate?: (apiKey: ApiKey) => void;
};

/** Displays a list of API keys in a table. */
function ApiKeyTable({apiKeys, onDelete, onRotate}: ApiKeyTableProps) {
  const initialIsShowing = useMemo(() => apiKeys.map(() => false), [apiKeys]);
  const [isShowing, setIsShowing] = useState<boolean[]>(initialIsShowing);

  const showKeyOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);
  const cellRenderer = (row: number, col: number) => {
    const apiKey = apiKeys[row];

    if (col === 0) {
      return (
        <Box display="flex" alignItems="center">
          <Box maxWidth={150} overflow="hidden" textOverflow="ellipsis">
            <strong>{apiKey.display_label}</strong>
          </Box>
          <IntegrationChip integrationId={apiKey.integration_id} />
        </Box>
      );
    }
    if (col === 1) {
      return (
        <Typography>
          {apiKey.created_at ? new Date(apiKey.created_at).toLocaleDateString() : '-'}
        </Typography>
      );
    }
    if (col === 2) {
      if (showKeyOnce) {
        return '';
      }
      const value =
        isShowing[row] || !apiKey.value ? apiKey.value : apiKey.value.split(/[a-z0-9]/g).join('*');
      return (
        <Typography variant="body2" sx={{fontFamily: 'monospace'}}>
          {value}
        </Typography>
      );
    }
    if (col === 3) {
      if (!showKeyOnce) {
        return '';
      }
      return <ApiKeyPermissionsCell apiKey={apiKey} />;
    }
    if (col === 4) {
      const setIsShowingForRow = (isShowingForRow: boolean) => {
        const newIsShowing = [...isShowing];
        newIsShowing[row] = isShowingForRow;
        setIsShowing(newIsShowing);
      };
      return (
        <ApiKeyActions
          apiKey={apiKey}
          onDelete={onDelete}
          onRotate={onRotate}
          isShowing={isShowing[row]}
          setIsShowing={setIsShowingForRow}
        />
      );
    }
    return null;
  };
  return (
    <Table
      columns={[
        {name: 'Name', dataType: 'string'},
        {name: 'Created on', dataType: 'string'},
        {name: showKeyOnce ? '' : 'Value', dataType: 'string'},
        {name: showKeyOnce ? 'Permissions' : '', dataType: 'string'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={apiKeys.length}
      cellRenderer={cellRenderer}
      gutter={GUTTER}
    />
  );
}

export default ApiKeyTable;
