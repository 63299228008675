import * as Sentry from '@sentry/react';

export default class StatusApi {
  static async fetchSystemStatus() {
    try {
      const response = await fetch('https://status.pinecone.io/api/v2/summary.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return {
        isOperational: data.status.indicator === 'none',
        statusDescription: data.status.description,
      };
    } catch (error) {
      Sentry.captureException(error);
      throw new Error('Failed to fetch system status');
    }
  }
}
