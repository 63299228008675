import {Box, useTheme} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {type PropsWithChildren} from 'react';
import {STRIPE_PUBLISHABLE_KEY} from '../../../../../constants';
import {useStripeElementsOptions, useStripeIsLoading} from '../hooks/stripe';

export type StripeProviderProps = PropsWithChildren<{}>;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function StripeProvider({children}: StripeProviderProps) {
  const isLoading = useStripeIsLoading();
  const elementsOptions = useStripeElementsOptions();
  const theme = useTheme();
  // Stripe elements are contained in an iframe and must be styled using stripe rules
  // https://stripe.com/docs/elements/appearance-api?platform=web#variables
  const options = {
    ...elementsOptions,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: theme.palette.primary.main,
        borderRadius: theme.spacing(0.75),
        colorBackground: theme.palette.background.paper,
        colorDanger: theme.palette.error.main,
        spacingUnit: theme.spacing(0.75),
        // hardcode to visually match the MUI elements
        // this should be set to theme text color but we don't have those
        colorText: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        // match MUI form helper text font size
        fontSizeBase: `${theme.typography.fontSize}`,
        fontSizeSm: `${theme.typography.body2.fontSize}`,
      },
      rules: {
        '.Input:focus': {
          borderColor: theme.palette.primary.main,
          outline: `solid ${theme.palette.primary.main} 1px`,
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
        },
        '.Input:hover.Input:focus': {
          borderColor: theme.palette.primary.main,
        },
        '.Input': {
          // hardcode to visually match the MUI elements
          // this should be set to theme colors but we don't have those
          borderColor: theme.palette.grey[400],
          boxShadow: 'unset',
          borderWidth: '1px',
          padding: '10px',
        },
        '.Label': {
          // hardcode to visually match the MUI elements
          // this should be set to theme colors but we don't have those
          color: theme.palette.text.primary,
          fontWeight: 'normal',
          fontSize: '14px',
          marginBottom: '8px',
        },
      },
    },
  } as const;

  if (isLoading) {
    return (
      <LoadingContainer loading>
        <Box minHeight={240} />
      </LoadingContainer>
    );
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}

export default StripeProvider;
