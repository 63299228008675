import {ReturnMechanism} from '@pinecone-experience/integrations';
import {z} from 'zod';
import {isLowercaseAlphaNumericWithHyphens} from '../../../../../utils/format';

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1000;

const createFields = {
  name: z.string().nonempty({message: 'Name must not be empty.'}),
  slug: z
    .string()
    .nonempty({message: 'Slug must not be empty.'})
    .refine((text) => isLowercaseAlphaNumericWithHyphens(text), {
      message: 'Only lowercase letters and hypens are allowed in the slug.',
    }),
  logo: z
    .instanceof(File, {message: 'A logo is required.'})
    .refine((file) => !file || file.size < MAX_FILE_SIZE, {
      message: `File is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`,
    })
    .refine((file) => !file || file.type.startsWith('image/'), {
      message: 'File must be an image.',
    }),
  return_mechanism: z.nativeEnum(ReturnMechanism),
  allowed_origins: z.array(z.object({origin: z.string()})),
};

const updateFields = {
  ...createFields,
  logo: createFields.logo.optional(),
};

const createBaseSchema = z.object(createFields);
const updateBaseSchema = z.object(updateFields);

type CreateIntegrationBaseData = z.infer<typeof createBaseSchema>;
type UpdateIntegrationBaseData = z.infer<typeof updateBaseSchema>;

const validateAllowedOrigins = (
  data: CreateIntegrationBaseData | UpdateIntegrationBaseData,
  ctx: z.RefinementCtx,
) => {
  const nonEmptyOrigins = data.allowed_origins.filter((o) => !!o.origin);
  if (nonEmptyOrigins.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'At least one allowed origin is required.',
      path: ['allowed_origins'],
    });
    return;
  }
  nonEmptyOrigins.forEach(({origin}, index) => {
    try {
      const originNoWildcard = origin.replace(/\*+/g, 'example');
      const url = new URL(originNoWildcard);
      if (originNoWildcard !== url.origin) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid origin.',
          path: ['allowed_origins', index, 'origin'],
        });
      }
    } catch (e) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid origin.',
        path: ['allowed_origins', index, 'origin'],
      });
    }
  });
};

export const createSchema = createBaseSchema.superRefine(validateAllowedOrigins);
export const updateSchema = updateBaseSchema.superRefine(validateAllowedOrigins);

export type CreateIntegrationFormData = z.infer<typeof createSchema>;
export type UpdateIntegrationFormData = z.infer<typeof updateSchema>;

export type IntegrationFormData = CreateIntegrationFormData | UpdateIntegrationFormData;
