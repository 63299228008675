export function assertUnreachable(_: never): never {
  throw new Error('Unknown type detected.');
}

// TODO(damargulis): Figure out why typescirpt doesn't recongnize the native and remove this
export function groupBy<T>(objects: T[], getKey: (t: T) => string) {
  const map: Record<string, T[]> = {};
  objects.forEach((obj) => {
    const key = getKey(obj);
    if (map[key]) {
      map[key].push(obj);
    } else {
      map[key] = [obj];
    }
  });
  return map;
}
