import {Box, CircularProgress} from '@mui/material';
import type * as React from 'react';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
  faded: {
    width: '100%',
    height: '100%',
    opacity: 0.2,
  },
  center: {
    position: 'absolute',
  },
};

interface LoadingContainerProps {
  children?: React.ReactNode;
  loading?: boolean;
  showChildrenInBackground?: boolean;
}

function LoadingContainer({
  children = null,
  loading = false,
  showChildrenInBackground = false,
}: LoadingContainerProps) {
  if (loading) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.center}>
          <CircularProgress />
        </Box>
        <Box sx={showChildrenInBackground ? styles.faded : styles.hidden}>{children}</Box>
      </Box>
    );
  }
  // Fragment is needed for typesystem to convert ReactNode -> ReactElement
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default LoadingContainer;
