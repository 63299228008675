import {Box, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useNavigateToBilling} from '../../../../hooks/navigation';
import {useSelectedOrganizationId} from '../../../../selectors/params';

export type PricingChangeCopyProps = {
  onClose: () => void;
  segment: string;
};

const MENTION_PRICING_CHANGE_SEGMENTS = ['3.1', '3.2', '4', '5.1', '5.2'];

function IntroParagraph({segment}: {segment: string}) {
  const mentionPricingChange = MENTION_PRICING_CHANGE_SEGMENTS.includes(segment);
  if (mentionPricingChange) {
    return (
      <Typography>
        On August 29 you should have received an email from us informing you of some exciting new
        Pinecone serverless features, as well as other updates including a new pricing structure and
        a separate, flexible support structure.
      </Typography>
    );
  }
  return (
    <Typography>
      On August 29 you should have received an email from us informing you of some exciting new
      Pinecone serverless features, as well as other updates including a new flexible support
      structure for all our customers.
    </Typography>
  );
}

function SecondParagraph({onClose, segment}: PricingChangeCopyProps) {
  const orgId = useSelectedOrganizationId();
  const {path: billingPath} = useNavigateToBilling(orgId);
  const contactPath =
    'https://www.pinecone.io/contact/?contact_form_inquiry_type=Enterprise+Pricing';
  switch (segment) {
    case '3.1':
      return (
        <Typography>
          Please see our updated pricing structure{' '}
          <Link to={billingPath} onClick={onClose}>
            here on the console
          </Link>{' '}
          and <a href={contactPath}>contact us</a> if you have any questions. New pricing will go
          into effect on October 1 for Pinecone serverless and on December 1 for support.{' '}
          <strong>
            Unless you decide to change your support tier sooner, you will receive our Developer
            support until that time.
          </strong>
        </Typography>
      );
    case '3.2':
      return (
        <Typography>
          Please see our updated pricing structure{' '}
          <Link to={billingPath} onClick={onClose}>
            here on the console
          </Link>{' '}
          and <a href={contactPath}>contact us</a> if you have any questions. New pricing will go
          into effect on October 1 for Pinecone serverless and on December 1 for support.{' '}
          <strong>
            Unless you decide to change your support tier sooner, you will receive our Pro support
            until that time.
          </strong>
        </Typography>
      );
    case '4':
      return (
        <Typography>
          Please see our updated pricing structure{' '}
          <Link to={billingPath} onClick={onClose}>
            here on the console
          </Link>{' '}
          and <a href={contactPath}>contact us</a> if you have any questions. New pricing will go
          into effect on October 1 for Pinecone serverless.{' '}
          <strong>
            Since you are an Enterprise customer, you will continue to receive Enterprise support.
          </strong>
        </Typography>
      );
    case '5.1':
      return (
        <Typography>
          <strong>
            As an existing Standard customer with an annual commitment, these pricing changes do not
            affect you and you will continue to receive what we now call Pro support.
          </strong>{' '}
          We wanted to let you know to avoid any potential confusion. Of course, if you have any
          questions, please feel free to <a href={contactPath}>contact us</a> anytime.
        </Typography>
      );
    case '5.2':
      return (
        <Typography>
          <strong>
            As an existing Enterprise customer with an annual commitment, these pricing changes do
            not affect you and you will continue to receive what we now call Enterprise support.
          </strong>{' '}
          We wanted to let you know to avoid any potential confusion. Of course, if you have any
          questions, please feel free to <a href={contactPath}>contact us</a> anytime.
        </Typography>
      );
    case '6.1':
      return (
        <Typography>
          Please see our updated support structure{' '}
          <Link to={billingPath} onClick={onClose}>
            here on the console
          </Link>{' '}
          and <a href={contactPath}>contact us</a> if you have any questions. New support pricing
          will go into effect on December 1.{' '}
          <strong>
            Unless you decide to change your support tier sooner, you will receive our Developer
            support until that time.
          </strong>
        </Typography>
      );
    case '6.2':
      return (
        <Typography>
          Please see our updated support structure{' '}
          <Link to={billingPath} onClick={onClose}>
            here on the console
          </Link>{' '}
          and <a href={contactPath}>contact us</a> if you have any questions. New support pricing
          will go into effect on December 1.{' '}
          <strong>
            Unless you decide to change your support tier sooner, you will receive our Pro support
            until that time.
          </strong>
        </Typography>
      );
    case '6.3':
      return (
        <Typography>
          <strong>
            As an existing Enterprise customer, these pricing changes do not affect you and you will
            continue to receive Enterprise support.
          </strong>{' '}
          We wanted to let you know to avoid any potential confusion. Of course, if you have any
          questions, please feel free to <a href={contactPath}>contact us</a> anytime.
        </Typography>
      );
    default:
      return null;
  }
}

function PricingChangeCopy({onClose, segment}: PricingChangeCopyProps) {
  return (
    <>
      <Box sx={{pb: 1}}>
        <IntroParagraph segment={segment} />
      </Box>
      <Box sx={{py: 1}}>
        <SecondParagraph segment={segment} onClose={onClose} />
      </Box>
      <Box sx={{py: 1}}>
        <Typography>-Team Pinecone</Typography>
      </Box>
    </>
  );
}

export default PricingChangeCopy;
