import Link from '@mui/material/Link';
import Table from '@pinecone-experience/timber/Table';
import {type CreateCmekKeyRequest} from '../../../../api/types/controllerApi';
import EmptyState from '../../../../components/layout/EmptyState/EmptyState';
import {PUBLIC_DOCS} from '../../../../constants';
import {type CmekKey} from '../../../../types';
import CreateCmekButton from '../CreateCmekButton/CreateCmekButton';
import CmekCell from './CmekCell/CmekCell';

type CmekListProps = {
  keys: CmekKey[];
  onCreate: (data: CreateCmekKeyRequest) => void;
  onDelete: (cmekId: string) => void;
};

function CmekList({keys, onCreate, onDelete}: CmekListProps) {
  const cellRenderer = (row: number, col: number) => (
    <CmekCell cmek={keys[row]} col={col} onDelete={onDelete} />
  );

  if (!keys.length) {
    return (
      <EmptyState
        heading="Setup Customer Managed Encryption Key"
        description={
          <>
            Enhance your data security and govern data access by managing your own encryption keys.{' '}
            <Link href={PUBLIC_DOCS.CMEK} target="_blank">
              Learn more
            </Link>
          </>
        }
      >
        <CreateCmekButton onCreate={onCreate} />
      </EmptyState>
    );
  }

  return (
    <Table
      isLoading={false}
      columns={[
        {name: 'Name', dataType: 'string'},
        {name: 'Role ARN', dataType: 'string'},
        {name: 'Key ARN', dataType: 'string'},
        {name: 'Cloud', dataType: 'string'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={keys.length}
      cellRenderer={cellRenderer}
      customIdPrefix="cmek-page"
    />
  );
}

export default CmekList;
