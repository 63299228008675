import {zodResolver} from '@hookform/resolvers/zod';
import {Button, Typography} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {useDefaultOrgId, useDefaultProjectId} from '../../../../../hooks/defaults';
import {
  useGlobalProject,
  useGlobalProjectsFromOrganization,
  useOwnedProjectIds,
} from '../../../../../selectors/projects';
import useTheme from '../../../../../styles/theme';
import OrganizationSelector from './OrganizationSelector/OrganizationSelector';
import ProjectSelector from './ProjectSelector/ProjectSelector';
import styles from './utilities/styles';

const schema = z
  .object({
    organizationId: z.string().nonempty(),
    globalProjectId: z.string(),
    newProjectName: z.string().optional(),
  })
  .refine((input) => {
    if (!input.globalProjectId && !input.newProjectName) {
      return false;
    }
    return true;
  });

export type ConnectProjectSchema = z.infer<typeof schema>;

type ConnectProjectProps = {
  appName: string;
  onConfirm: (formData: ConnectProjectSchema) => void;
  onCancel: () => void;
  onReset: () => void;
  isConnecting: boolean;
  error?: Error | string | null;
};

function ConnectProject({
  appName,
  onConfirm,
  onCancel,
  onReset,
  isConnecting,
  error,
}: ConnectProjectProps) {
  const defaultOrgId = useDefaultOrgId();
  const defaultProjectId = useDefaultProjectId(defaultOrgId);

  // reject projects where the user is not the owner
  const ownedProjects = useOwnedProjectIds();
  const refinedSchema = schema.superRefine((data, ctx) => {
    if (data.globalProjectId && !ownedProjects.includes(data.globalProjectId)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You are not an owner of this project.',
        path: ['globalProjectId'],
      });
    }
  });

  const formMethods = useForm<ConnectProjectSchema>({
    mode: 'onChange',
    resolver: zodResolver(refinedSchema),
    defaultValues: {
      organizationId: defaultOrgId,
      globalProjectId: defaultProjectId,
      newProjectName: '',
    },
    values: {
      organizationId: defaultOrgId,
      globalProjectId: defaultProjectId,
      newProjectName: '',
    },
  });
  const {formState, handleSubmit, reset, trigger, watch} = formMethods;

  const selectedOrgId = watch('organizationId');
  const selectedProjectId = watch('globalProjectId');
  const selectedProject = useGlobalProject(selectedProjectId);
  const selectedProjectName = selectedProject?.name;
  const newProjectName = watch('newProjectName');
  const projectName = selectedProjectName || newProjectName;

  const projectsForSelectedOrg = useGlobalProjectsFromOrganization(selectedOrgId);
  const defaultProjectIdForSelectedOrg = useDefaultProjectId(selectedOrgId);

  // reset the project id when the org changes
  useEffect(() => {
    const shouldResetProject =
      selectedProjectId && !projectsForSelectedOrg.find((proj) => proj.id === selectedProjectId);
    if (shouldResetProject) {
      reset({
        organizationId: selectedOrgId,
        globalProjectId: defaultProjectIdForSelectedOrg,
        newProjectName,
      });
      trigger();
    }
  }, [
    defaultProjectIdForSelectedOrg,
    newProjectName,
    projectsForSelectedOrg,
    reset,
    selectedOrgId,
    selectedProjectId,
    trigger,
  ]);

  const resetForm = useCallback(() => {
    reset();
    trigger();
    onReset();
  }, [onReset, reset, trigger]);

  const isSubmitting = formState.isSubmitting || isConnecting;
  const disabled = isSubmitting || !!error;
  const theme = useTheme();
  const isOwner = ownedProjects.includes(selectedProjectId) || !selectedProjectId;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onConfirm)} id="select-project">
        <OrganizationSelector disabled={disabled} />
        <ProjectSelector orgId={selectedOrgId} disabled={disabled} />
        {(() => {
          if (!projectName) {
            return (
              <Typography sx={styles.secondaryText(theme)}>
                Please select or create a new project to proceed with authorization.
              </Typography>
            );
          }
          if (!isOwner) {
            return (
              <Typography sx={styles.text}>
                To connect the {projectName} project, you must be a <strong>project owner</strong>.
                Please reach out to an organization administrator to update your role settings.
              </Typography>
            );
          }
          return (
            <Typography sx={styles.text}>
              Do you authorize <strong>{appName}</strong> to read, create, modify, and delete
              indexes and data in the Pinecone project <strong>{projectName}</strong>?
            </Typography>
          );
        })()}
        {error ? (
          <>
            <Typography sx={styles.error(theme)}>{error.toString()}</Typography>
            <Button
              type="button"
              variant="contained"
              size="large"
              color="primary"
              sx={styles.submit}
              fullWidth
              onClick={resetForm}
            >
              Reset
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            disabled={!formState.isValid || isSubmitting}
            variant="contained"
            size="large"
            color="primary"
            sx={styles.submit}
            fullWidth
          >
            {isSubmitting ? 'Authorizing...' : 'Authorize'}
          </Button>
        )}
        <Button
          type="button"
          variant="text"
          size="large"
          color="secondary"
          fullWidth
          sx={styles.cancel}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </form>
    </FormProvider>
  );
}

export default ConnectProject;
