import {Box} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {
  type Control,
  type Path,
  type UseFormGetFieldState,
  type UseFormSetValue,
} from 'react-hook-form';
import {useIsHovering} from '../../../../../../../hooks/utils';
import ValueInput from '../../../ValueInput/ValueInput';
import {type MetadataInput} from '../../../utilities/schema';
import ClearIcon from './ClearIcon/ClearIcon';

type FormValuesWithMetadata = {
  metadata: MetadataInput[];
  [key: string]: any;
};

type MetadataInputRowProps<T extends FormValuesWithMetadata> = {
  // See https://github.com/orgs/react-hook-form/discussions/9789 (although no one seems to know why it works...)
  control: T extends FormValuesWithMetadata ? Control<T> : never;
  setValue: UseFormSetValue<T>;
  index: number;
  remove: () => void;
  getFieldState: UseFormGetFieldState<T>;
  allowAutoType?: boolean;
};

function MetadataInputRow<T extends FormValuesWithMetadata>({
  control,
  setValue,
  index,
  remove,
  getFieldState,
  allowAutoType = true,
}: MetadataInputRowProps<T>) {
  const {isHovering: showClose, props: hoverProps} = useIsHovering();
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}} {...hoverProps}>
      <Box sx={{width: 180, mr: 1}}>
        <TextInput name={`metadata.${index}.key`} control={control} placeholder="Key" />
      </Box>
      <ValueInput
        control={control}
        name={`metadata.${index}.value` as Path<T>}
        typeName={`metadata.${index}.valueType` as Path<T>}
        getFieldState={getFieldState}
        setValue={setValue}
        allowAutoType={allowAutoType}
      />
      <Box mt={1.5}>
        <IconButton
          id={`metadata.${index}.clear`}
          sx={{opacity: showClose ? 1 : 0, transform: 'opacity .25s'}}
          icon={<ClearIcon />}
          onClick={remove}
          ariaLabel={`metadata.${index}.clear`}
          dataHeap="vector-metadata-remove-row"
        />
      </Box>
    </Box>
  );
}

export default MetadataInputRow;
