import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/apiKeyActions';
import {type ApiKey} from '../types';
import {isGlobalProjectAction} from './utils';

interface ProjectState {
  items: ApiKey[];
  created?: {data?: ApiKey; error?: string};
  loading?: boolean;
}

const initialProjectState = {
  items: [],
  roleBindings: [],
} as ProjectState;

interface KeyState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as KeyState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(actions.listApiKeysRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.listApiKeysSuccess, (state, action) => {
      state.loading = false;
      state.items = action.payload.apiKeys;
    })
    .addCase(actions.createApiKeyRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.createApiKeySuccess, (state, action) => {
      state.items.push(action.payload.apiKey);
      state.created = {data: action.payload.apiKey};
      state.loading = false;
    })
    .addCase(actions.clearCreatedApiKey, (state) => {
      state.created = undefined;
    })
    .addCase(actions.createApiKeyFailure, (state, action) => {
      state.loading = false;
      state.created = {error: action.payload.error || 'API key failed to create'};
    })
    .addCase(actions.deleteApiKeyRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.deleteApiKeySuccess, (state, action) => {
      state.items = state.items.filter((key) => key.id !== action.payload.keyId);
      state.loading = false;
    })
    .addCase(actions.deleteApiKeyFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.rotateApiKeyRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.rotateApiKeySuccess, (state, action) => {
      state.items = state.items.map((key) =>
        key.id === action.payload.keyId ? action.payload.apiKey : key,
      );
      state.created = {data: action.payload.apiKey};
      state.loading = false;
    })
    .addCase(actions.rotateApiKeyFailure, (state) => {
      state.loading = false;
    })
    .addDefaultCase((state) => state);
});

const keyReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isGlobalProjectAction, (state, action) => {
    state.projects[action.payload.globalProjectId] = projectReducer(
      state.projects[action.payload.globalProjectId],
      action,
    );
  });
});

export default keyReducer;
