import {useAppSelector as useSelector} from '../app/store';

export const useProjectQuotaUtilization = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaUtilization.projects?.[projectId]?.quotaUtilization || {};
  });
};

export const useProjectQuotaUtilizationIsLoading = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaUtilization?.projects?.[projectId]?.loading;
  });
};
