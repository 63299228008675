import {Paper, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useFormContext} from 'react-hook-form';
import {type IntegrationFormData} from '../utilities/schema';
import styles from '../utilities/styles';

/** Form field for the integration name. */
function NameField() {
  const {control} = useFormContext<IntegrationFormData>();

  return (
    <Paper sx={styles.section}>
      <TextInput
        name="name"
        control={control}
        required
        autoFocus
        label="Integration name"
        fullWidth
      />
      <Typography>Give your integration a name.</Typography>
    </Paper>
  );
}

export default NameField;
