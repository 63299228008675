import {Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {
  cancelSubscription,
  updateSubscription,
} from '../../../../../../../actions/organizationActions';
import {Plans} from '../../../../../../../constants';
import {useDashboardApi} from '../../../../../../../hooks/use-api';
import {useCurrentSubscription} from '../../../../../../../selectors/billing';
import {useFreeTierDowngradeEligibility} from '../../../../../../../selectors/freeTierDowngradeEligibilities';
import {useSelectedOrganizationId} from '../../../../../../../selectors/params';
import ModalForm from '../../../../../../ModalForm';
import FreeTierRestrictions from './FreeTierRestrictions/FreeTierRestrictions';

interface ConfirmChangePlanProps {
  open: boolean;
  close: () => void;
  isUpgrade: boolean;
  plan: Plans;
}

function ConfirmChangePlan({open, close, isUpgrade, plan}: ConfirmChangePlanProps) {
  const selectedOrgId = useSelectedOrganizationId() || '';
  const currentSub = useCurrentSubscription();
  const freeTierEligibility = useFreeTierDowngradeEligibility(selectedOrgId);
  const isIneligibleForFreeTier =
    freeTierEligibility?.data?.restrictionList?.length &&
    freeTierEligibility?.data?.isEligible === false &&
    plan === Plans.FREE;

  const action = isUpgrade ? 'Upgrade' : 'Downgrade';
  const isLoading = currentSub?.loading || freeTierEligibility?.loading;

  const {setQueryData: setQueryDataChangePlan} = useDashboardApi<{
    organizationId: string;
    plan: Plans;
  }>();
  const {setQueryData: setQueryDataDowngrade} = useDashboardApi<{
    organizationId: string;
  }>();

  const doChangePlan = () => {
    if (plan === Plans.FREE) {
      setQueryDataDowngrade({
        action: cancelSubscription,
        data: {
          organizationId: selectedOrgId || '',
        },
      });
    } else {
      setQueryDataChangePlan({
        action: updateSubscription,
        data: {
          plan,
          organizationId: selectedOrgId || '',
        },
      });
    }
    close();
  };

  return (
    <ModalForm
      customId={`${isUpgrade ? 'upgrade' : 'downgrade'}-plan-confirmation-modal`}
      submitButtonId={`${isUpgrade ? 'upgrade' : 'downgrade'}-plan-submit-button`}
      open={open}
      close={close}
      title={`Are you sure you want to ${action.toLowerCase()}?`}
      isValid={!isLoading && !isIneligibleForFreeTier}
      onSubmit={doChangePlan}
      closeText="Stay on current plan"
      submitText={`Continue with ${action.toLowerCase()}`}
      submitColor={`${isUpgrade ? 'primary' : 'error'}`}
      form={
        <LoadingContainer loading={isLoading}>
          {plan === Plans.FREE ? (
            <>
              <Typography>
                If you downgrade to the Starter plan, <strong>you will lose access</strong> to
                serverless indexes, multi-pod indexes, and other premium features. You will also be
                charged for any unbilled usage from the current month.
              </Typography>
              {isIneligibleForFreeTier && freeTierEligibility?.data?.restrictionList ? (
                <FreeTierRestrictions
                  restrictions={freeTierEligibility.data.restrictionList}
                  organizationId={selectedOrgId}
                />
              ) : null}
            </>
          ) : (
            <Typography>
              Changing the plan will affect the features, support level, and pricing for all your
              projects.
            </Typography>
          )}
        </LoadingContainer>
      }
    />
  );
}

export default ConfirmChangePlan;
