import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {useCallback} from 'react';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import {useBooleanState} from '../../../../../../hooks/utils';

type CmekActionsProps = {
  id: string;
  name: string;
  inUse: boolean;
  onDelete: (cmekId: string) => void;
};

function CmekActions({id, name, inUse, onDelete}: CmekActionsProps) {
  const {
    val: deleteModalOpen,
    setTrue: showDeleteModal,
    setFalse: hideDeleteModal,
  } = useBooleanState(false);

  const doDelete = useCallback(() => {
    hideDeleteModal();
    onDelete(id);
  }, [id, hideDeleteModal, onDelete]);

  return (
    <>
      <SelectorMenuButton
        id={`cmek-actions-${id}`}
        items={[
          {
            id: 'delete',
            name: 'Delete',
            icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
            onClick: inUse ? undefined : showDeleteModal,
            color: 'error',
            tooltip: inUse
              ? 'Before deleting this key, you must first delete all related indexes in this project.'
              : '',
          },
        ]}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        close={hideDeleteModal}
        doDelete={doDelete}
        itemType="CMEK key"
        name={name}
      />
    </>
  );
}

export default CmekActions;
