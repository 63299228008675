import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {Box, Link, List, ListItem, Typography} from '@mui/material';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

const DOC_SUBTEXT =
  'Looking to learn more about the vector and index operations available in Pinecone? ' +
  'Head to our documentation for detailed information and examples:';

const styles = {
  docLink: {
    display: 'list-item',
    pl: 0,
    pr: 0,
    '& svg': {
      ml: 1,
      fontSize: '1.2rem',
      transform: 'translateY(0.2rem)',
    },
  },
  docList: {
    listStyle: 'disc inside',
  },
};

const useDocs = (global: boolean, connect: boolean) => {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  if (!global) {
    return selectedLanguageContent.docs;
  }
  return connect ? selectedLanguageContent.connectDocs : selectedLanguageContent.globalDocs;
};

function LanguageContentDocs({
  subtext,
  global = false,
  connect = false,
}: {
  subtext?: React.ReactNode;
  global?: boolean;
  connect?: boolean;
}) {
  const docs = useDocs(global, connect);
  return (
    <Box role="region" aria-label="support">
      <Typography>{subtext || DOC_SUBTEXT}</Typography>
      <List sx={styles.docList}>
        {docs.map((page) => (
          <ListItem key={page.linkText} sx={styles.docLink}>
            <Link href={page.href} rel="noopener" target="_blank" underline="none">
              {page.linkText}
              <ArrowOutwardIcon />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default LanguageContentDocs;
