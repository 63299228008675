import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {type Control} from 'react-hook-form';
import {PUBLIC_DOCS} from '../../../../../../constants';
import {type EndpointFormSchema} from '../endpointFormSchema';
import {formStepStyles} from '../stepStyles';

interface AuthorizeEndpointStepProps {
  control: Control<EndpointFormSchema>;
  selectedServiceName: string;
  isValid: boolean;
  loading: boolean;
  error?: string;
  onClose: () => void;
  onNext: () => void;
}

const styles = {
  serviceName: {
    mt: 0.5,
    py: 0.75,
    px: 1.5,
    borderRadius: 1.5,
    border: 1,
    borderColor: 'divider',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default function AuthorizeEndpointStep({
  control,
  selectedServiceName,
  isValid,
  loading,
  error,
  onClose,
  onNext,
}: AuthorizeEndpointStepProps) {
  return (
    <>
      <Box sx={formStepStyles.content}>
        <Typography data-testid="interface-endpoint-success">
          Create a AWS VPC Endpoint using the provided Service Name and authorize it in Pinecone to
          establish AWS PrivateLink connectivity. See{' '}
          <Link href={PUBLIC_DOCS.AWS_PRIVATELINK} target="_blank" rel="noreferrer">
            documentation
          </Link>
          .
        </Typography>
        <Box>
          <Typography fontWeight={500}>
            Use the following Service Name to create an AWS VPC Interface Endpoint
          </Typography>
          <Box sx={styles.serviceName}>
            <Typography>{selectedServiceName}</Typography>
            <CopyButton sx={{m: 0}} text={selectedServiceName} description="Copy service name" />
          </Box>
        </Box>
        <TextInput
          control={control}
          name="cloud_endpoint_id"
          fullWidth
          label="Enter your created AWS VPC Endpoint ID to authorize connectivity"
          placeholder="ex. VPCE-xxxxxxxxxxxxxxxxx"
          error={error}
        />
      </Box>
      <Box sx={formStepStyles.actions}>
        <Button color="secondary" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!isValid || loading} variant="contained" onClick={onNext}>
          {loading ? 'Creating...' : 'Next'}
        </Button>
      </Box>
    </>
  );
}
