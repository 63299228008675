import {useAppSelector as useSelector} from '../app/store';

export const useSetupIntent = () => {
  return useSelector((state) => state.billing.setupIntent);
};

export const useUpcomingInvoice = () => {
  return useSelector((state) => state.billing.upcomingInvoice);
};

export const usePastInvoices = () => {
  return useSelector((state) => state.billing.pastInvoices);
};

export const useBillingDetails = () => {
  return useSelector((state) => state.billing.billingDetails);
};

export const useServerlessSavings = () => {
  return useSelector((state) => state.billing.serverlessSavings);
};

export const useCurrentSubscription = () => {
  return useSelector((state) => state.billing.currentSubscription);
};

export const usePastInvoicesAreLoading = () => {
  return useSelector((state) => state.billing.pastInvoicesLoading);
};

export const useShouldShowChurn = () => {
  return useSelector((state) => state.billing.showChurn);
};

export const useIsUpgradeLoading = () => {
  return useSelector((state) => {
    return state.billing.upgradeLoading;
  });
};

export const useInvoiceIsLoading = (invoiceId: string) => {
  return useSelector((state) => {
    return state.billing.invoicesLoading[invoiceId];
  });
};

export const useCurrentSupportTier = () => {
  return useSelector((state) => {
    return state.billing.support.data?.currentSupportTier;
  });
};

export const useSupportLoading = () => {
  return useSelector((state) => state.billing.support.loading);
};

export const useIsSupportIncluded = () => {
  return useSelector((state) => state.billing.support.data?.isSupportIncluded);
};

export const useSupportChangeCompleted = () => {
  return useSelector((state) => state.billing.support.data?.completedAction);
};
