const DataVisColors = {
  DARK_BLUE: '#0D3082',
  LIGHT_BLUE: '#8BF6FF',
  AQUA: '#3087C1',
  PURPLE: '#A440CE',
  AMBER: '#FFB400',
  GREEN: '#86AF80',
  ORANGE: '#E36219',
};
export const DataVisColorsArray = Object.values(DataVisColors);
export default DataVisColors;
