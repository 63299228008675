import {Box, Skeleton, Typography} from '@mui/material';
import Alert from '@pinecone-experience/timber/Alert';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import Table from '@pinecone-experience/timber/Table';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useEffect, useState} from 'react';
import {queryVectors} from '../../../../../../actions/dataActions';
import {embed} from '../../../../../../actions/inferenceActions';
import type {QueryRequest} from '../../../../../../api/dataPlaneApi';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import {useControllerApi, useDataPlane} from '../../../../../../hooks/use-api';
import {useDebounce} from '../../../../../../hooks/utils';
import {useExplorerStateByGlobalProject} from '../../../../../../selectors/explorer';
import {
  useInferenceEmbeddings,
  useInferenceEmbeddingsIsLoading,
} from '../../../../../../selectors/inference';
import {useSelectedGlobalProject} from '../../../../../../selectors/projects';
import {useServiceSpecsByGlobalProject} from '../../../../../../selectors/services';
import {InferenceModels} from '../../../../../../types';
import {shortenVector} from '../../../../../../utils/format';
import NextButton from '../../NextButton/NextButton';
import type {BlockComponentProps} from '../../types';
import {useGuideContext} from '../../utilities/GuideContext';
import {
  GENERATE_QUERY_EMBEDDING,
  INDEX_NAME,
  INTERACTIVE_DEMO_LANGUAGE_OPTIONS,
  QUERY_OPTIONS,
  QUERY_VECTOR_SNIPPETS,
  movieVectorData,
} from './constants';

export const Section3Header = () => {
  return <Typography>The indexed data is now queryable.</Typography>;
};

export const CreateQueryVector = ({
  handleNext,
  status,
  blockTitle,
  sectionIndex,
  blockIndex,
}: BlockComponentProps) => {
  const selectedLanguage = useSelectedLanguage(INTERACTIVE_DEMO_LANGUAGE_OPTIONS);
  const [queryOption, setQueryOption] = useState(QUERY_OPTIONS[0].value);
  const [userQuery, setUserQuery] = useState<string>();
  const query = userQuery || queryOption;
  const {updateSectionState} = useGuideContext();
  const project = useSelectedGlobalProject();

  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    model: InferenceModels;
    query: string;
  }>(project);

  const generateEmbedding = () => {
    setQueryData({
      action: embed,
      data: {
        globalProjectId: project.id,
        model: InferenceModels.MULTILINGUAL_E5_LARGE,
        query,
      },
    });
    updateSectionState(sectionIndex, 'queryText', query);
    handleNext();
  };
  return (
    <Box>
      <Typography mb={2}>
        The query operation performs a search over your index using a vector embedding and returns
        the most similar records. The vector embedding used for the query can be one that already
        exists in the index or one that is created from the text in your query.
      </Typography>
      <Typography mb={2}>
        For this example, let’s create a new vector embedding from the query text using the Pinecone
        Inference API. Select one of the sample queries or write your own:
      </Typography>
      <Box my={1}>
        <SelectInput
          label="Query"
          value={queryOption}
          onChange={(val) => {
            setQueryOption(val);
            setUserQuery(undefined);
          }}
          options={QUERY_OPTIONS}
          fullWidth
        />
        {queryOption === QUERY_OPTIONS[3].value && (
          <TextInput
            placeholder="Type you own query here"
            value={userQuery || ''}
            onChange={(val) => setUserQuery(val)}
            fullWidth
          />
        )}
      </Box>
      <Box my={1}>
        <CodeBlock
          language={selectedLanguage}
          code={GENERATE_QUERY_EMBEDDING[selectedLanguage](query)}
          wrapLongLines
          copyButton={false}
        />
      </Box>
      <NextButton
        blockIndex={blockIndex}
        blockTitle={blockTitle}
        onClick={generateEmbedding}
        sectionIndex={sectionIndex}
        status={status}
        title="Generate query embedding"
        loadingTimeout={0.5}
      />
    </Box>
  );
};

const styles = {
  maxLinesText: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  title: {
    minWidth: 175,
  },
};

const createCellRenderer = (queryResults: {vectorId: string; score: number}[]) => {
  function cellRenderer(row: number, column: number) {
    const matchVector = movieVectorData?.[Number(queryResults?.[row]?.vectorId)];
    const matchScore = queryResults?.[row]?.score;
    switch (column) {
      case 0:
        return matchVector ? (
          <Typography>{matchVector.id}</Typography>
        ) : (
          <Skeleton variant="rounded" sx={{my: 2}} />
        );
      case 1:
        return matchVector ? (
          <Typography sx={styles.title}>{matchVector.metadata?.title}</Typography>
        ) : (
          <Skeleton variant="rounded" sx={{my: 2}} />
        );
      case 2:
        return matchVector ? (
          <Tooltip title={matchVector.metadata?.summary}>
            <Typography sx={styles.maxLinesText}>{matchVector.metadata?.summary}</Typography>
          </Tooltip>
        ) : (
          <Skeleton variant="rounded" sx={{my: 2}} />
        );
      case 3:
        return matchScore ? (
          <Typography>{matchScore}</Typography>
        ) : (
          <Skeleton variant="rounded" sx={{my: 2}} />
        );
      default:
        return null;
    }
  }

  return cellRenderer;
};

export const QueryYourIndex = ({sectionIndex, status}: BlockComponentProps) => {
  const selectedLanguage = useSelectedLanguage(INTERACTIVE_DEMO_LANGUAGE_OPTIONS);
  const project = useSelectedGlobalProject();
  const moviesIndex = useServiceSpecsByGlobalProject(project.id).find((s) => s.name === INDEX_NAME);
  const {getSectionState} = useGuideContext();

  const [queryText, setQueryText] = useState('');
  const [topK, setTopK] = useState(5);

  const debouncedQueryText = useDebounce(queryText, 1000);
  const debouncedTopK = useDebounce(topK, 1000);

  const queryIsLoading = useInferenceEmbeddingsIsLoading(debouncedQueryText);
  const queryEmbedding = useInferenceEmbeddings(debouncedQueryText);

  const explorerState = useExplorerStateByGlobalProject(project.id, INDEX_NAME);
  const results = explorerState?.query?.matches;

  useEffect(() => {
    setQueryText(getSectionState(sectionIndex, 'queryText') || '');
  }, [getSectionState, sectionIndex, status]);

  const {setQueryData: setEmbeddingQueryData} = useControllerApi<{
    globalProjectId: string;
    model: InferenceModels;
    query: string;
  }>(project);

  const {setQueryData: setQueryIndexQueryData} = useDataPlane<{
    query: QueryRequest;
    skipNotify: boolean;
    retries?: number;
  }>(project, INDEX_NAME, moviesIndex?.host || '');

  useEffect(() => {
    if (debouncedQueryText.length > 0) {
      setEmbeddingQueryData({
        action: embed,
        data: {
          globalProjectId: project.id,
          model: InferenceModels.MULTILINGUAL_E5_LARGE,
          query: debouncedQueryText,
        },
      });
    }
  }, [project.id, debouncedQueryText, setEmbeddingQueryData]);

  useEffect(() => {
    if (debouncedTopK > 0 && queryEmbedding) {
      setQueryIndexQueryData({
        action: queryVectors,
        data: {
          query: {
            topK: debouncedTopK,
            namespace: '',
            includeMetadata: false,
            includeValues: false,
            vector: queryEmbedding,
          },
          skipNotify: true,
        },
      });
    }
  }, [queryEmbedding, setQueryIndexQueryData, debouncedTopK]);

  if (!moviesIndex?.status?.ready) {
    return (
      <Alert
        severity="error"
        title="Index not ready"
        description={`The index ${INDEX_NAME} can't be found. Please restart the guide.`}
      />
    );
  }

  if (queryIsLoading === false && !queryEmbedding) {
    return (
      <Alert
        severity="error"
        title="Query embedding failed"
        description="Please restart the guide."
      />
    );
  }

  return (
    <Box>
      <Typography mb={2}>
        {`Using the vector embedding just created, the top ${
          topK || 'K'
        } nearest neighbors can be returned:`}
      </Typography>
      <TextInput
        label="Query Text"
        value={queryText}
        onChange={(val) => {
          setQueryText(val);
        }}
        fullWidth
      />
      <NumberInput
        label="TopK"
        tooltip="The number of nearest neighbors to return."
        value={topK}
        onChange={(val) => setTopK(val)}
        fullWidth
        max={20}
        allowNegative={false}
      />
      <CodeBlock
        language={selectedLanguage}
        code={QUERY_VECTOR_SNIPPETS[selectedLanguage](shortenVector(queryEmbedding, 10), topK)}
        sx={{
          my: 2,
        }}
        copyButton={false}
      />
      <Box mt={4}>
        <Typography fontWeight={500} mb={1}>
          TopK similarity search results
        </Typography>
        <Table
          columns={[
            {
              name: 'ID',
              dataType: 'number',
            },
            {
              name: 'Title',
              dataType: 'string',
            },
            {
              name: 'Summary',
              dataType: 'string',
            },
            {
              name: 'Score',
              dataType: 'number',
            },
          ]}
          numRows={results?.length || topK}
          cellRenderer={createCellRenderer(results)}
        />
      </Box>
    </Box>
  );
};
