import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {editOrganization} from '../../../../actions/organizationActions';
import ModalForm from '../../../../components/ModalForm';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganization} from '../../../../selectors/organizations';

interface EditOrgNameModalProps {
  open: boolean;
  onClose: () => void;
}

function EditOrgNameModal({open, onClose}: EditOrgNameModalProps) {
  const org = useSelectedOrganization();
  const {control, handleSubmit} = useForm({
    defaultValues: {
      name: org.name,
    },
  });
  const {setQueryData} = useDashboardApi<{organizationId: string; name: string}>();
  const handleEditOrgName = (formData: {name: string}) => {
    setQueryData({
      action: editOrganization,
      data: {
        organizationId: org.id,
        name: formData.name,
      },
    });
  };
  return (
    <ModalForm
      title="Edit Organization"
      isValid
      onSubmit={handleSubmit(handleEditOrgName)}
      submitText="Save"
      open={open}
      close={onClose}
      form={
        <TextInput
          name="name"
          control={control}
          required
          autoFocus
          fullWidth
          label="Name"
          placeholder="Organization Name"
        />
      }
    />
  );
}

export default EditOrgNameModal;
