import {DAY_FORMAT, HOUR_FORMAT, type TimeInterval} from '../constants/time';

export function getTimestampFormat(interval: TimeInterval) {
  switch (interval) {
    case '15m':
      return HOUR_FORMAT;
    case '4hrs':
      return HOUR_FORMAT;
    case '12hrs':
      return HOUR_FORMAT;
    case '1d':
      return HOUR_FORMAT;
    case '2d':
      return HOUR_FORMAT;
    case '1w':
      return DAY_FORMAT;
    default:
      return DAY_FORMAT;
  }
}
