import {ListItemButton, Typography} from '@mui/material';
import {useMatch, useNavigate} from 'react-router-dom';

export interface NavItemProps {
  title: string;
  path: string;
  pathMatch?: string;
  color?: string;
}
function NavItem({pathMatch, path, title, color}: NavItemProps) {
  const navigate = useNavigate();
  const pathToMatch = pathMatch || `${path}/*`;
  const isSelected = useMatch(pathToMatch);

  return (
    <ListItemButton component="li" selected={!!isSelected} onClick={() => navigate(path)}>
      <Typography noWrap color={color}>
        {title}
      </Typography>
    </ListItemButton>
  );
}

export default NavItem;
