import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Typography} from '@mui/material';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {type Control, type FieldPathValue, type Path} from 'react-hook-form';
import {ProjectRoles} from '../../../../../types';
import {type BaseOptions} from '../../types';

interface RoleLayerControlProps<T extends Record<string, any>, P extends Path<T>> {
  control: Control<T>;
  name: P;
  title: string;
  read: FieldPathValue<T, P>;
  write: FieldPathValue<T, P>;
  descriptions: Record<string, string>;
  disabled: boolean;
  tooltip: string;
  warningText?: string;
  baseRole?: ProjectRoles | BaseOptions;
}

function RoleLayerControl<T extends Record<string, any>, P extends Path<T>>({
  control,
  name,
  title,
  read,
  write,
  descriptions,
  disabled,
  tooltip,
  warningText,
  baseRole,
}: RoleLayerControlProps<T, P>) {
  const options = [
    {value: write, label: 'ReadWrite', disabled},
    {value: read, label: 'ReadOnly', disabled},
    {value: '', label: 'None', disabled},
  ];
  const value = useWatch({control, name});
  const {setValue} = useFormContext<T>();

  useEffect(() => {
    if (baseRole === ProjectRoles.PROJECT_EDITOR) {
      setValue(name, write);
    }
  }, [baseRole, name, write, setValue]);
  const description = descriptions[value];
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1}>
          <Typography fontWeight="500">{title}</Typography>
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon
              sx={{fontSize: '1rem', position: 'relative', top: 2}}
              color="secondary"
            />
          </Tooltip>
        </Box>
        <SelectBoxInput
          control={control}
          name={name}
          gap={0}
          highlightStyle="background"
          options={options}
          hideError
        />
      </Box>
      <Typography component="span">{`${description} `}</Typography>
      <Typography component="span" sx={{fontStyle: 'italic'}}>
        {warningText}
      </Typography>
    </Box>
  );
}

export default RoleLayerControl;
