import {Navigate, type NavigateProps, useLocation} from 'react-router-dom';

type NavigateWithParamsProps = {
  to: string;
} & Omit<NavigateProps, 'to'>;

// A utility component that navigates to a url, while maintaining any query params
// This is useful for initial page navigations, where we may want to navigate a user to
// the correct project/organization, but want to maintain any redirections in the query params
function NavigateWithParams({to, ...navigateProps}: NavigateWithParamsProps) {
  const location = useLocation();
  return <Navigate {...navigateProps} to={{...location, pathname: to}} />;
}

export default NavigateWithParams;
