import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {useIsSelectedOrganizationSubscribed} from '../../../../../selectors/organizations';
import {
  usePendingDatasetByGlobalProject,
  useServiceByGlobalProject,
} from '../../../../../selectors/services';
import {canImport} from '../../../../../utils/services';
import EmptyExplorer from '../EmptyExplorer/EmptyExplorer';
import ExplorerPage, {ExplorerPages} from '../ExplorerPage/ExplorerPage';
import {type QueryExplorerState} from '../QueryExplorer/utils';
import {type IndexExplorerState} from '../utilities/utils';

interface EmptyIndexGuardProps {
  indexExplorerState: IndexExplorerState;
  page: ExplorerPages;
  queryExplorerState: QueryExplorerState;
  onSubmitQueryExplorer: () => void;
  setExplorerTab: (tab: ExplorerPages) => void;
}

function EmptyIndexGuard({
  indexExplorerState,
  page,
  queryExplorerState,
  onSubmitQueryExplorer,
  setExplorerTab,
}: EmptyIndexGuardProps) {
  const {project, service, indexStats} = indexExplorerState;
  const pendingDataset = usePendingDatasetByGlobalProject(project.id, service);
  const serviceInfo = useServiceByGlobalProject(project.id, service);
  const isOrgSubscribed = useIsSelectedOrganizationSubscribed();
  const showBulkImport =
    useFlag(Flags.SHOW_BULK_IMPORT) && isOrgSubscribed && canImport(serviceInfo);

  return (
    <LoadingContainer loading={!indexStats || !!pendingDataset}>
      {(indexStats?.totalVectorCount || 0) > 0 || page === ExplorerPages.ADD ? (
        <ExplorerPage
          page={page}
          indexExplorerState={indexExplorerState}
          queryExplorerState={queryExplorerState}
          onSubmitQueryExplorer={onSubmitQueryExplorer}
        />
      ) : (
        <EmptyExplorer
          indexName={service}
          setExplorerTab={setExplorerTab}
          showBulkImportAction={showBulkImport}
        />
      )}
    </LoadingContainer>
  );
}

export default EmptyIndexGuard;
