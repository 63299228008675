import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/inferenceActions';
import {type InferenceResponse} from '../types';

type QueryState = Partial<InferenceResponse> & {
  error?: string;
  loading: boolean;
};

const initialState = {
  queries: {} as Record<string, QueryState>,
};

const inferenceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.embedTextRequest, (state, action) => {
      state.queries[action.payload.query] = {
        loading: true,
      };
    })
    .addCase(actions.embedTextSuccess, (state, action) => {
      state.queries[action.payload.query] = {
        ...action.payload.data,
        loading: false,
      };
    })
    .addCase(actions.embedTextFailure, (state, action) => {
      state.queries[action.payload.query] = {
        error: action.payload.error,
        loading: false,
      };
    });
});

export default inferenceReducer;
