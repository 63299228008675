import {Box} from '@mui/material';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {submitSupportTicket} from '../../../../actions/supportActions';
import SupportTicketPageContent from '../../../../components/Support/PageContent';
import Page from '../../../../components/layout/Page/Page';
import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import {SupportTiers} from '../../../../constants';
import {useNavigateToSupport} from '../../../../hooks/navigation';
import {useCurrentSupportTier, useIsSupportIncluded} from '../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../selectors/params';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
  },
  backButton: {
    marginBottom: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    alignSelf: 'flex-start',
  },
  backButtonText: {
    color: 'secondary.main',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
  },
};

const SupportTicketPage = () => {
  const orgId = useSelectedOrganizationId();
  const {go: navigateToSupport} = useNavigateToSupport(orgId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const isSupportIncluded = useIsSupportIncluded();
  const currentSupportTier = useCurrentSupportTier();

  const dispatch = useDispatch();

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);

    // Prepare the data for the support ticket
    const ticketData = {
      subject: data.subject,
      email: data.email,
      category: data.category,
      severity: data.severity,
      additionalDetails: data.description,
      files: data.files,
    };

    try {
      dispatch(submitSupportTicket(ticketData));
      setIsSubmissionSuccessful(true);
    } catch (error) {
      // TODO(@kylermintah): handle error
    } finally {
      setIsSubmitting(false);
    }
  };

  const hasAccess = currentSupportTier !== SupportTiers.FREE || isSupportIncluded;

  return (
    <Page
      width="narrow"
      header={
        <PageHeader
          title="Submit a Support Ticket"
          breadcrumb={{
            text: 'Support',
            action: () => navigateToSupport(),
          }}
          titleHidden
        />
      }
    >
      <Box sx={styles.container}>
        <SupportTicketPageContent
          hasAccess={hasAccess ?? false}
          isSubmissionSuccessful={isSubmissionSuccessful}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      </Box>
    </Page>
  );
};

export default SupportTicketPage;
