import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Box from '@mui/material/Box';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import {type AssistantPagePanel} from '../../../../../constants';
import AssistantDrawerControls, {
  type DrawerControls,
} from '../../AssistantDrawerControls/AssistantDrawerControls';

export const DRAWER_PANEL_HEADER_HEIGHT = 70;

interface DrawerPanelProps {
  panel: AssistantPagePanel;
  controls: DrawerControls;
  children: React.ReactNode;
}

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    minHeight: DRAWER_PANEL_HEADER_HEIGHT,
  },
  title: {
    display: 'flex',
    alignItems: 'start',
  },
  closeButton: (show: boolean) => ({
    mt: 1,
    ml: -5,
    opacity: show ? 1 : 0,
    backgroundColor: 'background.paper',
  }),
};

export default function DrawerPanel({panel, controls, children}: DrawerPanelProps) {
  return (
    <>
      <Box sx={styles.header} data-testid={`${panel}-drawer-panel-header`}>
        <IconButton
          shape="circle"
          border
          onClick={controls.onClose}
          disabled={!controls.show}
          tooltip={controls.show ? 'Collapse' : undefined}
          id="close-drawer-button"
          sx={styles.closeButton(controls.show)}
          icon={<KeyboardDoubleArrowRightOutlinedIcon fontSize="small" color="secondary" />}
        />
        <AssistantDrawerControls
          hidden={false}
          selected={panel}
          id="assistant-panel"
          controls={controls}
        />
      </Box>
      {children}
    </>
  );
}
