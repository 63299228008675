import {type Dispatch, createAction} from '@reduxjs/toolkit';
import StatusApi from '../api/status';

export const fetchSystemStatusRequest = createAction('FETCH_SYSTEM_STATUS_REQUEST');
export const fetchSystemStatusSuccess = createAction<{
  isOperational: boolean;
  statusDescription: string;
}>('FETCH_SYSTEM_STATUS_SUCCESS');
export const fetchSystemStatusFailure = createAction<string>('FETCH_SYSTEM_STATUS_FAILURE');

export function fetchSystemStatus() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSystemStatusRequest());
    try {
      const status = await StatusApi.fetchSystemStatus();
      dispatch(fetchSystemStatusSuccess(status));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      dispatch(
        fetchSystemStatusFailure(`An error occurred while fetching system status: ${errorMessage}`),
      );
    }
  };
}
