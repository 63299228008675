import Table from '@pinecone-experience/timber/Table';
import {GUTTER} from '../../../../styles/theme';
import {type Integration, type IntegrationUpdateData} from '../../../../types';
import IntegrationCell from './IntegrationCell/IntegrationCell';

type IntegrationListParams = {
  /** The integrations to be listed. */
  integrations: Integration[];
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: IntegrationUpdateData) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** A list of integrations. Provides options to edit or delete each integration. */
function IntegrationList({integrations, onEdit, onDelete}: IntegrationListParams) {
  const cellRenderer = (row: number, col: number) => (
    <IntegrationCell
      integrations={integrations}
      row={row}
      col={col}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );

  return (
    <Table
      isLoading={false}
      columns={[
        {name: 'Logo', dataType: 'string'},
        {name: 'Integration name', dataType: 'string'},
        {name: 'URL slug', dataType: 'string'},
        {name: 'Return mechanism', dataType: 'string'},
        {name: 'Actions', dataType: 'actions'},
      ]}
      numRows={integrations.length}
      cellRenderer={cellRenderer}
      customIdPrefix="integrations-page"
      gutter={GUTTER}
    />
  );
}

export default IntegrationList;
