import {ResponsiveLine} from '@nivo/line';
import {useFormatDateTick, useGetYAxisLabel, useNivoTheme} from '../../../hooks/chart';
import {type PrometheusLegacyResponse} from '../../../types';
import {chartAxisBottom, chartAxisY, chartMargin, timeXScale} from '../../../utils/chart';
import LineTooltip from './LineTooltip/LineTooltip';

interface LineChartProps {
  formattedData: PrometheusLegacyResponse;
  colors: string[];
  unit?: string;
}

function LineChart({formattedData, colors, unit = '/sec'}: LineChartProps) {
  const nivoTheme = useNivoTheme();
  const {yAxisMin, yAxisMax} = useGetYAxisLabel(formattedData);
  const formatTick = useFormatDateTick({formattedData});
  return (
    <ResponsiveLine
      theme={nivoTheme}
      colors={colors}
      data={formattedData}
      xScale={timeXScale}
      yScale={{type: 'linear', min: yAxisMin, max: yAxisMax}}
      gridYValues={5}
      curve="linear"
      lineWidth={2}
      margin={chartMargin}
      axisLeft={chartAxisY({unit})}
      axisTop={null}
      axisBottom={{
        ...chartAxisBottom,
        format: formatTick,
      }}
      enablePoints={false}
      enableGridX={false}
      useMesh
      enableSlices="x"
      sliceTooltip={(slice) => LineTooltip(slice, unit)}
    />
  );
}

export default LineChart;
