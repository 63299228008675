import {useAppSelector as useSelector} from '../app/store';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from './params';

export const useInviteLoading = () => {
  return useSelector((state) => state.invites.loading);
};

export const useInvitesAreLoading = () => {
  return useSelector((state) => state.invites.loading);
};

export const useSelectedOrgPendingInvites = () => {
  const orgId = useSelectedOrganizationId();
  return useSelector((state) => state.invites.items[orgId || '']);
};

export const useSelectedProjectPendingInvites = () => {
  const projectId = useSelectedGlobalProjectId();
  const orgPendingInvites = useSelectedOrgPendingInvites();
  return orgPendingInvites?.filter((invite) =>
    Object.keys(invite.project_roles)?.includes(projectId || ''),
  );
};
