import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import RowWithCheck from '../../../../components/Utilities/RowWithCheck';
import {MODAL_WIDTH_SMALL} from '../../../../styles/theme';

const TOS_URL = 'https://www.pinecone.io/legal/supplemental-terms/';

interface AssistantWelcomeDialogProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

const styles = {
  root: {
    width: MODAL_WIDTH_SMALL,
  },
  action: {
    mt: 4,
  },
};

export default function AssistantWelcomeDialog({
  open,
  loading,
  onClose,
}: AssistantWelcomeDialogProps) {
  // Users must explicitly click the Accept button or go back to the previous page.
  const handleModalEscape = () => {};

  return (
    <BaseModal sx={styles.root} open={open} onClose={handleModalEscape}>
      <ModalHeader title="Welcome to Pinecone Assistant" />

      <Typography>
        Start building accurate question-answering capability into your AI products.
      </Typography>
      <Box my={2}>
        <RowWithCheck text="Get more relevant answers" />
        <RowWithCheck text="Manage your files with ease" />
        <RowWithCheck text="Full control over your data" />
      </Box>
      <Typography>
        By clicking ‘Accept’, you agree to our{' '}
        <Link target="_blank" rel="noreferrer" href={TOS_URL}>
          Terms of Service
        </Link>
        .
      </Typography>
      <Button
        sx={styles.action}
        fullWidth
        variant="contained"
        color="primary"
        onClick={onClose}
        disabled={loading}
      >
        Accept and Continue
      </Button>
    </BaseModal>
  );
}
