import {useUserId} from '../../../../selectors/userSession';

export const useDefaultServerlessProjectData = (organizationId: string) => {
  const userId = useUserId();
  return {
    organizationId,
    userId: userId || '',
    params: {
      name: 'Serverless',
      environment: 'serverless',
      quota: 5,
    },
  };
};
