import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/assistantFilesActions';
import {AssistantFileStatus} from '../constants';
import {type AssistantFile} from '../types';
import {type AsyncDataState, initAsyncDataState, isAssistantAction} from './utils';

type AssistantState = Record<string, AssistantFile>;
type FilesState = {
  assistants: Record<string, AsyncDataState<AssistantState>>;
};

const initialAssistantState = initAsyncDataState<AssistantState>({}, true);
const initialFilesState: FilesState = {assistants: {}};

const assistantReducer = createReducer(initialAssistantState, (builder) => {
  builder
    .addCase(actions.listAssistantFilesRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.listAssistantFilesSuccess, (state, action) => {
      const files: AssistantState = {};
      action.payload.files.forEach((file) => {
        files[file.id] = file;
      });

      state.data = files;
      state.loading = false;
    })
    .addCase(actions.listAssistantFilesFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.getAssistantFileRequest, (state, action) => {
      const assistantState = state || initAsyncDataState({}, true);
      // If we're polling, don't set loading to true
      assistantState.loading = !action.payload.silentPoll;

      state = assistantState;
    })
    .addCase(actions.getAssistantFileSuccess, (state, action) => {
      const {file} = action.payload;

      state.error = undefined;
      state.data = {
        ...state.data,
        [file.id]: file,
      };
      state.loading = false;
    })
    .addCase(actions.getAssistantFileFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.deleteAssistantFileRequest, (state) => {
      if (state) {
        state.loading = true;
      }
    })
    .addCase(actions.deleteAssistantFileSuccess, (state, action) => {
      const assistantState = state.data || {};

      assistantState[action.payload.fileId].status = AssistantFileStatus.DELETING;

      state.data = assistantState;
      state.loading = false;
    })
    .addCase(actions.assistantFileFinishedDeleting, (state, action) => {
      const assistantState = state.data || {};

      delete assistantState[action.payload.fileId];

      state.data = assistantState;
    })
    .addCase(actions.uploadAssistantFilesRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.uploadAssistantFilesSuccess, (state, action) => {
      const assistantData = state.data || {};

      action.payload.files.forEach((file) => {
        assistantData[file.id] = file;
      });

      state.data = assistantData;
      state.loading = false;
    })
    .addCase(actions.uploadAssistantFilesFailure, (state) => {
      state.loading = false;
    });
});

const assistantFilesReducer = createReducer(initialFilesState, (builder) => {
  builder.addMatcher(isAssistantAction, (state, action) => {
    state.assistants[action.payload.assistantName] = assistantReducer(
      state.assistants[action.payload.assistantName],
      action,
    );
  });
});

export default assistantFilesReducer;
