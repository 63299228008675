import {Box, Button, List, ListItem, Typography} from '@mui/material';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {type Vector} from '../../../../../../api/dataPlaneApi';
import {useBooleanState, useIsHovering} from '../../../../../../hooks/utils';

const DEFAULT_METADATA_ROWS_TO_SHOW = 4;

const styles = {
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoItem: {
    overflow: 'hidden',
    minWidth: 150,
    flexDirection: 'column',
    alignItems: 'flex-start',
    mr: 3,
  },
  metadataItem: {
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    p: 0,
    display: 'block',
  },
  metadataKey: {
    pr: 1,
    fontWeight: 'bold',
  },
  sparseGrid: {
    display: 'flex',
  },
  sparseContainer: {
    flexShrink: 1,
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textWrap: 'nowrap',
  },
  sparseItem: {
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    maxWidth: 100,
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
  },
};

type VectorDataProps = {
  vector: Vector;
};

function InfoItem({
  title,
  text,
  copy = false,
  tooltip = false,
}: {
  title: string;
  text: string | number;
  copy?: boolean;
  tooltip?: boolean;
}) {
  const {isHovering: showCopy, props: hoverProps} = useIsHovering();
  return (
    <Box sx={styles.infoItem} {...hoverProps}>
      <Typography variant="overline" color="text.secondary">
        {title}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'baseline'}}>
        <Typography noWrap py={1}>
          {tooltip ? (
            <Tooltip title={text} followCursor>
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>{text}</>
            </Tooltip>
          ) : (
            text
          )}
        </Typography>
        <Box sx={{visibility: copy && showCopy ? 'visible' : 'hidden'}}>
          <CopyButton text={text.toString()} description={title} />
        </Box>
      </Box>
    </Box>
  );
}

function VectorData({vector}: VectorDataProps) {
  const {
    val: showViewMore,
    setTrue: setShowViewMore,
    setFalse: setHideViewMore,
  } = useBooleanState();
  const metadataKeys = Object.keys(vector.metadata || {});
  const metadataToShow = showViewMore
    ? metadataKeys
    : metadataKeys.slice(0, DEFAULT_METADATA_ROWS_TO_SHOW);
  const remaining = Math.max(metadataKeys.length - DEFAULT_METADATA_ROWS_TO_SHOW, 0);
  return (
    <Box sx={styles.infoContainer}>
      <Box sx={{display: 'flex', pb: 3, justifyContent: 'flex-start', pr: '120px'}}>
        <InfoItem title="ID" text={vector.id} copy tooltip />

        <InfoItem title="Values" text={vector.values.join(', ')} copy />
        {(vector?.sparseValues?.indices?.length || 0) > 0 && (
          <Box sx={styles.infoItem}>
            <Typography variant="overline" color="text.secondary">
              Sparse Values
            </Typography>
            <Box sx={styles.sparseGrid}>
              <Box pr={1}>
                <Typography noWrap fontWeight="bold">
                  Indices:
                </Typography>
                <Typography noWrap fontWeight="bold">
                  Values:
                </Typography>
              </Box>
              <Box>
                <Typography noWrap>[</Typography>
                <Typography noWrap>[</Typography>
              </Box>
              <Box sx={styles.sparseContainer}>
                {vector.sparseValues?.indices.map((ind, index) => {
                  const showComma = index !== (vector.sparseValues?.indices.length || 0) - 1;
                  return (
                    <Box
                      key={index}
                      sx={{display: 'flex', flexDirection: 'column'}}
                      component="span"
                    >
                      <Typography pr={1}>{`${ind}${showComma ? ',' : ''}`}</Typography>
                      <Typography
                        component="span"
                        sx={styles.sparseItem}
                        pr={1}
                      >{`${vector.sparseValues?.values[index].toFixed(3)}${
                        showComma ? ',' : ''
                      }`}</Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <Typography noWrap>]</Typography>
                <Typography noWrap>]</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{pr: 2, pb: 2}}>
        <Typography variant="overline" color="text.secondary">
          Metadata
        </Typography>
        <Box sx={{position: 'relative'}}>
          {showViewMore && (
            <Button
              data-heap="vector-results-collapse-metadata"
              onClick={setHideViewMore}
              sx={{position: 'absolute', right: 0, zIndex: 1000}}
            >
              Collapse
            </Button>
          )}
          <Box
            sx={{pr: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}
          >
            <List sx={styles.metadataItem}>
              {metadataToShow.map((key) => (
                <Tooltip
                  key={key}
                  title={
                    <>
                      <Typography component="span" fontWeight="bold">{`${key}: `}</Typography>
                      <Typography component="span">{`${JSON.stringify(
                        vector.metadata[key],
                        null,
                        2,
                      )}`}</Typography>
                    </>
                  }
                  followCursor
                >
                  <ListItem sx={styles.metadataItem}>
                    <Typography component="span" sx={styles.metadataKey}>{`${key}: `}</Typography>
                    <Typography component="span">
                      {JSON.stringify(vector.metadata[key], null, 2)}
                    </Typography>
                  </ListItem>
                </Tooltip>
              ))}
              {remaining > 0 && !showViewMore && (
                <ListItem sx={styles.metadataItem}>
                  <Button
                    data-heap="vector-results-show-more-metadata"
                    onClick={setShowViewMore}
                    sx={{position: 'relative', right: 8}}
                  >
                    {`Show ${remaining} more`}
                  </Button>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VectorData;
