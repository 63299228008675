import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {type Control} from 'react-hook-form';

function OtherOption({
  fieldName,
  fieldValue,
  optionsArray,
  control,
}: {
  fieldName: string;
  fieldValue?: string;
  optionsArray: {value: string}[];
  control: Control<any, any>;
}) {
  const showAddOther =
    fieldValue === '' ||
    (fieldValue !== undefined && !optionsArray.map((opt) => opt.value).includes(fieldValue));

  return showAddOther ? (
    <TextInput name={fieldName} control={control} placeholder="Add an option" hideError />
  ) : null;
}

export default OtherOption;
