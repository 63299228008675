import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import AssistantStatusBox from '../../../../../components/AssistantStatusBox/AssistantStatusBox';
import {type Assistant} from '../../../../../types';

type AssistantCardProps = {
  assistant: Assistant;
  onSelect: () => void;
};

const styles = {
  card: {
    p: 3,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 8,
    },
  },
};

function AssistantCard({assistant, onSelect}: AssistantCardProps) {
  return (
    <Card sx={styles.card} onClick={onSelect} component="button">
      <Box display="flex" alignItems="baseline">
        <Typography mr={1} variant="h6" gutterBottom>
          {assistant.name}
        </Typography>
        <AssistantStatusBox assistantName={assistant.name} />
      </Box>
      <Typography>Date created: {new Date(assistant.created_at).toLocaleDateString()}</Typography>
      <Typography>Last updated: {new Date(assistant.updated_at).toLocaleDateString()}</Typography>
    </Card>
  );
}

export default AssistantCard;
