import Box from '@mui/material/Box';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useMemo, useState} from 'react';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import Page from '../../../components/layout/Page/Page';
import PageControls from '../../../components/layout/PageControls/PageControls';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import CreateProjectButton from '../../../components/projects/CreateProjectButton/CreateProjectButton';
import ProjectsTable from '../../../components/projects/ProjectsTable/ProjectsTable';
import {LocalStorageKeys} from '../../../constants';
import {useLocalStorageState} from '../../../hooks/utils';
import {
  useOrganizationsAreLoading,
  useSelectedOrganization,
} from '../../../selectors/organizations';
import {useGlobalProjectsFromSelectedOrganization} from '../../../selectors/projects';
import {type GlobalProject} from '../../../types';
import {getRecentProjectIdsToDisplay} from '../../../utils/recent-items';

enum SortOptions {
  NEWEST = 'newest',
  ALPHABETICALLY = 'alphabetically',
  VIEWED = 'viewed',
}

const sortProjects = (sort: SortOptions, projects: GlobalProject[], orgId = '') => {
  const sortedList = [...projects];

  switch (sort) {
    case SortOptions.VIEWED:
      const viewedHistory = getRecentProjectIdsToDisplay(orgId, projects);
      return sortedList.sort(
        (project1, project2) =>
          viewedHistory.indexOf(project1.id) - viewedHistory.indexOf(project2.id),
      );
    case SortOptions.ALPHABETICALLY:
      return sortedList.sort((a, b) => a.name.localeCompare(b.name));
    case SortOptions.NEWEST:
      return sortedList.sort((a, b) => {
        const createdTimeA = new Date(a.created_at || '').getTime();
        const createdTimeB = new Date(b.created_at || '').getTime();

        return createdTimeB - createdTimeA;
      });
    default:
      return sortedList;
  }
};

function ProjectsTablePage() {
  const loading = useOrganizationsAreLoading();
  const selectedOrg = useSelectedOrganization();
  const projectsInOrg = useGlobalProjectsFromSelectedOrganization();
  const noProjectsInOrg = !loading && !projectsInOrg.length;

  const [searchText, setSearchText] = useState('');
  const {val: sort, setVal: setSort} = useLocalStorageState<SortOptions>(
    LocalStorageKeys.PROJECT_SORT_ORDER,
    SortOptions.VIEWED,
  );
  const projects = useMemo(
    () =>
      sortProjects(sort, projectsInOrg, selectedOrg?.id).filter(({name}) =>
        name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [sort, searchText, projectsInOrg, selectedOrg?.id],
  );

  return (
    <Page loading={loading} header={<PageHeader title="Projects" />}>
      {noProjectsInOrg ? (
        <EmptyState
          heading="No projects found"
          description="Ready to create a new one? Let's get started."
        >
          <CreateProjectButton text="Create a project" />
        </EmptyState>
      ) : (
        <>
          <PageControls action={<CreateProjectButton includePlus />}>
            <Box flex={1}>
              <TextInput
                fullWidth
                hideError
                placeholder="Search projects"
                value={searchText}
                onChange={setSearchText}
                searchIcon
                clearable
              />
            </Box>
            <SelectInput
              hideError
              sx={{minWidth: 220}}
              value={sort}
              onChange={setSort}
              options={[
                {value: SortOptions.VIEWED, label: 'Sort by last viewed'},
                {value: SortOptions.NEWEST, label: 'Sort by newest'},
                {value: SortOptions.ALPHABETICALLY, label: 'Sort alphabetically'},
              ]}
            />
          </PageControls>
          <ProjectsTable projects={projects} dataKey={`${sort}-${searchText}`} />
        </>
      )}
    </Page>
  );
}

export default ProjectsTablePage;
