import {Button, type ButtonProps} from '@mui/material';
import {createSetupIntent} from '../../../../../../actions/organizationActions';
import {Plans, PlansInOrder} from '../../../../../../constants';
import {useDashboardApi} from '../../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../../hooks/utils';
import {useCurrentSubscription, useSetupIntent} from '../../../../../../selectors/billing';
import {
  useIsSelectedOrganiztionMarketplace,
  useSelectedOrganization,
} from '../../../../../../selectors/organizations';
import SubscribeModal from '../../../../payment/SubscribeModal/SubscribeModal';
import ConfirmChangePlan from './ConfirmChangePlan/ConfirmChangePlan';
import MarketplaceLinkModal from './MarketplaceLinkModal/MarketplaceLinkModal';

interface ChangePlanButtonProps {
  desiredPlan: Plans;
  variant?: ButtonProps['variant'];
  fullWidth?: ButtonProps['fullWidth'];
  sx?: ButtonProps['sx'];
}

export const getButtonName = (currentPlan: Plans, desiredPlan: Plans, isUpgrade: boolean) => {
  if (currentPlan === desiredPlan) {
    return 'Current';
  }
  if (isUpgrade) {
    return Plans.ENTERPRISE === desiredPlan ? 'Contact Sales' : 'Upgrade';
  }
  return 'Downgrade';
};

const getIsUpgrade = (currentPlan: Plans, desiredPlan: Plans): boolean =>
  PlansInOrder.indexOf(currentPlan) < PlansInOrder.indexOf(desiredPlan);

const ChangePlanButton = ({
  desiredPlan,
  variant = 'contained',
  fullWidth = false,
  sx = {},
}: ChangePlanButtonProps) => {
  const selectedOrganization = useSelectedOrganization();
  const isMarketplace = useIsSelectedOrganiztionMarketplace();
  const currentSubsciption = useCurrentSubscription();
  const isSubscriptionChanging = currentSubsciption?.loading;
  const currentPlan = selectedOrganization.plan;
  const isUpgrade = getIsUpgrade(currentPlan, desiredPlan);

  const {val: showForm, setTrue: setShowForm, setFalse: hideForm} = useBooleanState();
  const {val: showConfirm, setTrue: setShowConfirm, setFalse: setHideConfirm} = useBooleanState();
  const {
    val: showMarketplaceModal,
    setTrue: setShowMarketplaceModal,
    setFalse: setHideMarketplaceModal,
  } = useBooleanState();

  const buttonName = getButtonName(currentPlan, desiredPlan, isUpgrade);
  const buttonId = (() => {
    if (currentPlan === desiredPlan) return 'current-plan-button';
    if (desiredPlan === Plans.ENTERPRISE) return 'contact-sales-button';
    return `${isUpgrade ? 'upgrade' : 'downgrade'}-to-${desiredPlan}-button`;
  })();

  const {setQueryData} = useDashboardApi<{organizationId: string}>();
  const setupIntent = useSetupIntent();
  const handleClick = () => {
    if (desiredPlan === Plans.ENTERPRISE) {
      window.open(
        'https://www.pinecone.io/contact/?contact_form_inquiry_type=Enterprise+Pricing',
        '_blank',
      );
      return;
    }

    if (isMarketplace) {
      if (currentPlan === Plans.FREE || desiredPlan === Plans.FREE) {
        setShowMarketplaceModal();
        return;
      }
      setShowConfirm();
      return;
    }

    if (currentPlan === Plans.FREE) {
      if (!setupIntent) {
        setQueryData({
          action: createSetupIntent,
          data: {
            organizationId: selectedOrganization.id || '',
          },
        });
      }

      setShowForm();
      return;
    }
    setShowConfirm();
  };

  return (
    <>
      <Button
        id={buttonId}
        disabled={currentPlan === desiredPlan || isSubscriptionChanging}
        onClick={handleClick}
        variant={variant}
        fullWidth={fullWidth}
        sx={sx}
        data-cy={`upgrade-to-${desiredPlan}-button`}
      >
        {buttonName}
      </Button>

      {/* Three Different Plan Change Handlers */}
      <SubscribeModal open={showForm} onClose={hideForm} plan={desiredPlan} />
      <ConfirmChangePlan
        open={showConfirm}
        close={setHideConfirm}
        isUpgrade={isUpgrade}
        plan={desiredPlan}
      />
      <MarketplaceLinkModal open={showMarketplaceModal} onClose={setHideMarketplaceModal} />
    </>
  );
};

export default ChangePlanButton;
