import {type StreamOptions} from './types';

export enum StreamErrorType {
  FetchFailed = 'fetchFailed',
  FetchResponseError = 'fetchResponseError',
  FetchTimeout = 'fetchTimeout',
  ReadFailed = 'readFailed',
  EmptyResponse = 'emptyResponse',
}

export const STREAM_ERROR_MESSAGES: Record<StreamErrorType, string> = {
  [StreamErrorType.FetchFailed]: 'Failed to fetch response',
  [StreamErrorType.FetchTimeout]: 'Response timed out',
  [StreamErrorType.FetchResponseError]: '', // If this type occurs, the error in the response is shown
  [StreamErrorType.ReadFailed]: 'Failed to read response',
  [StreamErrorType.EmptyResponse]: 'Response returned empty message',
};

export enum Messages {
  ResponseFailed = 'Failed to fetch response',
  ResponseTimeout = 'Timed out fetching response',
  ReadTimeout = 'Timed out reading response',
  ReadFailed = 'Failed to read response',
  EmptyResponse = 'Response returned empty message',
  StreamClosed = 'Stream closed',
}

export const DEFAULT_STREAM_OPTIONS: StreamOptions = {
  retries: 3,
  retryInterval: 1000, // 1 second
  fetchTimeout: 60000, // 1 minute
  keepOpenAfterRetries: false,
};
