import {deleteBackups} from '../../../../actions/backupActions';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import {useControllerApi} from '../../../../hooks/use-api';
import {type Backup, type GlobalProject} from '../../../../types';

type DeleteBackupsConfirmationProps = {
  open: boolean;
  close: () => void;
  backups: Backup[];
  project: GlobalProject;
};

function DeleteBackupsConfirmation({
  open,
  close,
  backups,
  project,
}: DeleteBackupsConfirmationProps) {
  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    backupIds: string[];
  }>(project);
  const confirmDelete = () => {
    setQueryData({
      action: deleteBackups,
      data: {
        globalProjectId: project.id,
        backupIds: backups.map((backup) => backup.id),
      },
    });
  };
  return (
    <DeleteConfirmationModal
      open={open}
      close={close}
      itemType="backups"
      itemDescription={`${backups.length} backups`}
      submitItemType="source index"
      name={backups[0]?.index_name || ''}
      doDelete={confirmDelete}
      warningText="This action cannot be undone. Please type the name of the source index below to confirm."
    />
  );
}

export default DeleteBackupsConfirmation;
