import {PROVIDER_INFORMATION_MAP} from '../../../../constants';
import {useAuth} from '../../../../hooks/auth';
import {useLogin} from '../../../../hooks/utils';
import {ReactComponent as AccountIcon} from '../../../../icons/integrations/account.svg';
import {ReactComponent as CreateIndexIcon} from '../../../../icons/integrations/create_index.svg';
import {ReactComponent as CredentialsIcon} from '../../../../icons/integrations/credentials.svg';
import {ReactComponent as PineconeLogoDark} from '../../../../icons/pinecone_logo_dark.svg';
import './AwsBedrockStyles.css';

const AwsBedrockLandingPage = () => {
  const {isAuthenticated} = useAuth();
  const login = useLogin();
  const cta = () => {
    if (isAuthenticated) {
      // ToDo update to useNavigate when possible
      window.location.pathname = '/organizations';
      return () => {};
    }
    return login({
      sessionType: 'signup',
      returnUrl: '/organizations',
    });
  };

  // TODO rebuild this in react with MUI Theme after providers are updated
  return (
    <div className="root">
      <a href="https://pinecone.io">
        {/* <div alt="pinecone-logo" className="pinecone-logo" src={PineconeLogoDark} /> */}
        <PineconeLogoDark className="pinecone-logo" />
      </a>
      <section className="section">
        <h2 className="h2">
          <span>Integrate with</span>
          <span className="primary-color"> Amazon Bedrock</span>
        </h2>
        <p className="subtitle">
          Get up and running in seconds. Simply sign up, create an Index and connect to Amazon
          Bedrock
        </p>
        <section className="section">
          <div className="buttons">
            <a onClick={cta}>
              <button className="button" type="button">
                Get started with Pinecone
              </button>
            </a>
            <a href={PROVIDER_INFORMATION_MAP.aws.link} target="_blank" rel="noreferrer">
              <button type="button" className="button outlined-button">
                Signup through AWS Marketplace
              </button>
            </a>
          </div>
        </section>
        <section className="section">
          <h6 className="h6">Get connected in three steps</h6>
          <div className="steps">
            <div>
              <AccountIcon className="icon" />
              <h5 className="h5">1. Get a Pinecone Account</h5>
              <p className="p">Set up an account on our platform or through AWS Marketplace</p>
            </div>
            <div>
              <CreateIndexIcon className="icon" />
              <h5 className="h5">2. Create an Index</h5>
              <p className="p">
                Build your Index with the dimensions and metric of your chosen model
              </p>
            </div>
            <div>
              <CredentialsIcon className="icon" />
              <h5 className="h5">3. Paste Credentials</h5>
              <p className="p">
                Copy your connection url and API key into Amazon Bedrock to get started
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default AwsBedrockLandingPage;
