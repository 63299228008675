import {zodResolver} from '@hookform/resolvers/zod';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {useBooleanState} from '../../hooks/utils';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

interface ChatInputProps {
  loading: boolean;
  disableClearAction: boolean;
  disabled?: boolean;
  placeholder?: string;
  onSendMessage: (message: string) => void;
  onClearChat: () => void;
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mx: 2,
    mb: 1,
  },
  input: {
    backgroundColor: 'background.surface',
    borderRadius: 1,
    p: 1,
    pl: 3,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
    width: '100%',
  },
  sendButton: {
    ':disabled': {
      color: 'secondary',
    },
  },
  clearButton: {
    height: 'min-content',
    m: 0,
    px: 0,
    py: 1,
    color: 'secondary.dark',
    ':disabled': {
      color: 'secondary',
    },
  },
};

function ChatInput({
  onSendMessage,
  onClearChat,
  disabled,
  disableClearAction,
  loading,
  placeholder = 'Ask a question...',
}: ChatInputProps) {
  const {
    val: showClearModal,
    setTrue: openClearModal,
    setFalse: closeClearModal,
  } = useBooleanState();

  const {
    handleSubmit,
    reset,
    register,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
    resolver: zodResolver(
      z.object({
        message: z.string().nonempty(),
      }),
    ),
  });

  const sendMessage = ({message}: {message: string}) => {
    if (!loading) {
      onSendMessage(message.trim());
      reset();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendMessage)} autoComplete="off">
        <Box sx={styles.root}>
          <Box sx={styles.input}>
            <Input
              placeholder={placeholder}
              disabled={disabled}
              disableUnderline
              fullWidth
              autoFocus
              {...register('message')}
            />
            <IconButton type="submit" sx={styles.sendButton} disabled={!isValid || loading}>
              <ArrowForwardIcon />
            </IconButton>
          </Box>
          <Button
            sx={styles.clearButton}
            disabled={disableClearAction}
            variant="text"
            onClick={openClearModal}
          >
            Clear
          </Button>
        </Box>
      </form>
      <DeleteConfirmationModal
        close={closeClearModal}
        open={showClearModal}
        itemType="Conversation"
        action="Clear"
        name="chat history"
        skipCheck
        doDelete={onClearChat}
        title="Clear conversation"
      />
    </>
  );
}

export default ChatInput;
