import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {deleteCollection} from '../../../../actions/collectionActions';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import {CreateIndexParam, IndexState} from '../../../../constants';
import {useNavigateToCreateIndexGlobal} from '../../../../hooks/navigation';
import {useControllerApi} from '../../../../hooks/use-api';
import {useBooleanState} from '../../../../hooks/utils';
import {useSelectedGlobalProject} from '../../../../selectors/projects';

type CollectionActionsProps = {
  collection: string;
  dimensions: string;
  status: IndexState;
  environment: string;
};

function CollectionActions({
  collection,
  dimensions,
  status = IndexState.UNKNOWN,
  environment,
}: CollectionActionsProps) {
  const selectedProject = useSelectedGlobalProject();
  const {val: showDelete, setTrue: openDelete, setFalse: closeDelete} = useBooleanState();
  const {setQueryData} = useControllerApi<{globalProjectId: string; id: string}>(selectedProject);
  const confirmDelete = () => {
    setQueryData({
      action: deleteCollection,
      data: {
        id: collection,
        globalProjectId: selectedProject?.id,
      },
    });
  };

  const {go: goToIndexCreationPage} = useNavigateToCreateIndexGlobal(
    selectedProject.organization_id,
    selectedProject.id,
    {
      [CreateIndexParam.FROM_COLLECTION]: collection,
      [CreateIndexParam.NAME]: collection,
      [CreateIndexParam.ENVIRONMENT]: environment,
      [CreateIndexParam.DIMENSIONS]: dimensions,
    },
  );

  return (
    <>
      <SelectorMenuButton
        id={`collection-actions-${collection}`}
        items={[
          {
            id: `create-index-${collection}`,
            name: 'Create Index',
            icon: <TableRowsOutlinedIcon fontSize="small" />,
            onClick: status === IndexState.READY ? goToIndexCreationPage : undefined,
          },
          {
            id: `delete-collection-${collection}`,
            name: 'Delete',
            icon: <DeleteOutlinedIcon fontSize="small" color="error" />,
            color: 'error',
            onClick: openDelete,
          },
        ]}
      />
      <DeleteConfirmationModal
        close={closeDelete}
        open={showDelete}
        itemType="Collection"
        name={collection}
        doDelete={confirmDelete}
        warningText="This collection will be permanently deleted."
      />
    </>
  );
}

export default CollectionActions;
