import {Box} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect, useMemo} from 'react';
import {
  type Control,
  type Path,
  type PathValue,
  type UseFormGetFieldState,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {ReactComponent as ArrayIcon} from '../../../../../icons/typing/array.svg';
import {ReactComponent as BooleanIcon} from '../../../../../icons/typing/boolean.svg';
import {ReactComponent as NumberIcon} from '../../../../../icons/typing/number.svg';
import {ReactComponent as StringIcon} from '../../../../../icons/typing/string.svg';

type ValueInputProps<T extends Record<string, any>> = {
  control: Control<T>;
  name: Path<T>;
  typeName: Path<T>;
  getFieldState: UseFormGetFieldState<T>;
  setValue: UseFormSetValue<T>;
  allowedTypes?: string[];
  allowAutoType?: boolean;
};

const typeOptions = [
  {
    value: 'string',
    name: 'String',
    icon: <StringIcon aria-label="string" data-testid="string-icon" height="1.5em" width="1.5em" />,
  },
  {
    value: 'number',
    name: 'Number',
    icon: <NumberIcon aria-label="number" data-testid="number-icon" height="1.5em" width="1.5em" />,
  },
  {
    value: 'boolean',
    name: 'Boolean',
    icon: (
      <BooleanIcon aria-label="boolean" data-testid="boolean-icon" height="1.5em" width="1.5em" />
    ),
  },
  {
    value: 'array',
    name: 'Array',
    icon: <ArrayIcon aria-label="array" data-testid="array-icon" height="1.5em" width="1.5em" />,
  },
];

const allowedTypesDefault = ['auto', 'string', 'number', 'boolean', 'array'];

function ValueInput<T extends Record<string, any>>({
  name,
  control,
  typeName,
  getFieldState,
  setValue,
  allowAutoType = true,
  allowedTypes = allowedTypesDefault,
}: ValueInputProps<T>) {
  const value = useWatch({control, name});
  const typeValue = useWatch({control, name: typeName});
  const typeNameFieldState = getFieldState(typeName);
  // Set valueType based on user input
  useEffect(() => {
    if (!allowAutoType && typeValue !== 'auto') {
      return;
    }
    if ((typeValue === 'auto' || !typeNameFieldState.isTouched) && value) {
      // Number.isNaN behaves differently, does not return false for 123abc for example
      // eslint-disable-next-line no-restricted-globals
      if (allowedTypes.includes('number') && !isNaN(value)) {
        setValue(typeName, 'number' as PathValue<T, Path<T>>, {shouldTouch: false});
      } else if (allowedTypes.includes('boolean') && (value === 'true' || value === 'false')) {
        setValue(typeName, 'boolean' as PathValue<T, Path<T>>, {shouldTouch: false});
      } else if (allowedTypes.includes('array') && value.includes(',')) {
        setValue(typeName, 'array' as PathValue<T, Path<T>>, {shouldTouch: false});
      } else if (allowedTypes.includes('string')) {
        setValue(typeName, 'string' as PathValue<T, Path<T>>, {shouldTouch: false});
      }
    } else if (
      allowedTypes.includes('auto') &&
      !value &&
      typeValue !== 'auto' &&
      !typeNameFieldState.isTouched
    ) {
      setValue(typeName, 'auto' as PathValue<T, Path<T>>, {shouldTouch: false});
    }
  }, [
    value,
    setValue,
    typeNameFieldState.isTouched,
    typeName,
    typeValue,
    allowedTypes,
    allowAutoType,
  ]);
  // Update valueType if allowedTypes changes
  useEffect(() => {
    if (!allowedTypes.includes(typeValue)) {
      setValue(typeName, allowedTypes[0] as PathValue<T, Path<T>>, {shouldTouch: false});
    }
  }, [setValue, allowedTypes, typeValue, typeName]);
  const modifiedTypeOptions = useMemo(() => {
    return typeOptions.filter((typeOption) => allowedTypes.includes(typeOption.value));
  }, [allowedTypes]);
  return (
    <Box sx={{display: 'flex', width: '100%'}}>
      <Box sx={{mr: 1, width: '100%', minWidth: 240}}>
        <TextInput
          multiline
          name={name}
          control={control}
          placeholder="Value"
          fullWidth
          maxRows={3}
        />
      </Box>
      <SelectInput
        name={typeName}
        control={control}
        options={modifiedTypeOptions}
        renderValue={() => {
          const opt = typeOptions.find((o) => o.value === typeValue);
          return <Box sx={{mb: -4}}>{opt?.icon}</Box>;
        }}
      />
    </Box>
  );
}

export default ValueInput;
