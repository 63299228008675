import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Typography} from '@mui/material';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import InputWrapper from '@pinecone-experience/timber/Inputs/Utils/InputWrapper';
import {useCallback} from 'react';
import {type DefaultValues, FormProvider, useForm, useWatch} from 'react-hook-form';
import ModalForm from '../../../../components/ModalForm';
import {useStorageIntegrationProviderInfo} from '../../../../hooks/storageIntegrations';
import {MODAL_WIDTH_MEDIUM} from '../../../../styles/theme';
import {type StorageIntegration, StorageIntegrationProvider} from '../../../../types';
import {type StorageFormData, schema} from './utilities/schema';

type StorageFormProps = {
  /** Whether the form is open. */
  open: boolean;
  /** Callback for when the form is closed. */
  close: () => void;
  /** The title of the modal. */
  title: string;
  /** The subtitle of the modal. */
  subtitle?: string;
  /** Callback for when the form is submitted. */
  onSubmit: (data: StorageFormData) => void;
  /** The text to display on the submit button. */
  submitText: string;
  /** The initial values for the form. */
  initialValues?: StorageIntegration;
};

const styles = {
  id: {
    py: 1.25,
    px: 1.5,
    border: 1,
    borderRadius: 1.5,
    backgroundColor: 'background.paper',
    minWidth: 100,
    height: 42,
    borderColor: 'divider',
    width: '100%',
  },
};

/** A modal form for creating or editing an integration. */
function StorageForm({
  open,
  close,
  title,
  subtitle,
  onSubmit,
  submitText,
  initialValues,
}: StorageFormProps) {
  let defaultValues: DefaultValues<StorageFormData>;
  switch (initialValues?.provider) {
    case StorageIntegrationProvider.S3:
      defaultValues = {
        name: initialValues.name,
        provider: initialValues.provider,
        aws_iam_role_arn: initialValues.aws_iam_role.role_arn,
      };
      break;
    case StorageIntegrationProvider.GCS:
      defaultValues = {
        name: initialValues.name,
        provider: initialValues.provider,
      };
      break;
    case StorageIntegrationProvider.AzureBlob:
      defaultValues = {
        name: initialValues.name,
        provider: initialValues.provider,
      };
      break;
    default:
      defaultValues = {
        name: '',
        provider: StorageIntegrationProvider.S3,
        aws_iam_role_arn: '',
      };
      break;
  }
  const formMethods = useForm<StorageFormData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues,
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: {isValid},
  } = formMethods;

  const currentProvider = useWatch({name: 'provider', control});

  const onClose = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  const submit = useCallback(
    (data: StorageFormData) => {
      onSubmit(data);
      reset();
      close();
    },
    [close, onSubmit, reset],
  );

  const providerInfo = useStorageIntegrationProviderInfo();
  const providerOptions = Object.entries(providerInfo).map(([provider, info]) => ({
    label: info.enabled ? info.name : `${info.name} (coming soon)`,
    value: provider,
    disabled: !info.enabled,
  }));

  return (
    <FormProvider {...formMethods}>
      <ModalForm
        open={open}
        close={onClose}
        title={title}
        subtitle={subtitle}
        onSubmit={handleSubmit(submit)}
        submitText={submitText}
        isValid={isValid}
        width={MODAL_WIDTH_MEDIUM}
        form={
          <div data-testid="storage-form">
            {initialValues && initialValues.id && (
              <InputWrapper label="Integration ID">
                <Box sx={styles.id}>
                  <Typography color="secondary">{initialValues.id}</Typography>
                </Box>
              </InputWrapper>
            )}
            <TextInput
              name="name"
              control={control}
              required
              autoFocus
              label="Integration name"
              fullWidth
              outlineInputProps={{autoComplete: 'off'}}
            />
            <SelectInput
              name="provider"
              control={control}
              required
              label="Select data source"
              fullWidth
              options={providerOptions}
              disabled={!!initialValues}
            />
            {currentProvider === StorageIntegrationProvider.S3 && (
              <TextInput
                name="aws_iam_role_arn"
                control={control}
                required
                label="Role ARN"
                tooltip="The Amazon resource name of the IAM role you created for the integration."
                fullWidth
              />
            )}
            {currentProvider === StorageIntegrationProvider.GCS && (
              <TextInput
                name="gcp_service_account_key_json"
                control={control}
                required
                label="Service account key JSON"
                tooltip="The contents of the JSON key file for the service account you created for the integration."
                fullWidth
                multiline
                rows={8}
              />
            )}
            {currentProvider === StorageIntegrationProvider.AzureBlob && (
              <>
                <TextInput
                  name="azure_app_credentials_tenant_id"
                  control={control}
                  required
                  label="Tenant ID"
                  tooltip="The directory (tenant) ID of the Microsoft Entra ID application you registered for the integration."
                  fullWidth
                />
                <TextInput
                  name="azure_app_credentials_client_id"
                  control={control}
                  required
                  label="Client ID"
                  tooltip="The application (client) ID of the Microsoft Entra ID application you registered for the integration."
                  fullWidth
                />
                <TextInput
                  name="azure_app_credentials_client_secret"
                  control={control}
                  required
                  label="Client secret"
                  tooltip="The value of a client secret for the Microsoft Entra ID application you registered for the integration."
                  fullWidth
                />
              </>
            )}
          </div>
        }
      />
    </FormProvider>
  );
}

export default StorageForm;
