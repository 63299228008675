import {type NavigateProps} from 'react-router-dom';
import {useNavigateToDefaultOrg} from '../../hooks/navigation';
import NavigateWithParams from './NavigateWithParams';

export type NavigateToDefaultOrgProps = Omit<NavigateProps, 'to'>;

/** Navigates to the landing page for the default organization. */
function NavigateToDefaultOrg(navigateProps: NavigateToDefaultOrgProps) {
  const {path} = useNavigateToDefaultOrg();
  return <NavigateWithParams {...navigateProps} to={path} />;
}

export default NavigateToDefaultOrg;
