export const benefits = [
  '2 GB storage',
  '1M read units and 2M write units per month',
  'Up to 5 serverless indexes',
  'Up to 100 namespaces / index',
  'Unlimited members',
];

export const limits = [
  'Advanced region selection',
  'Pod-based indexes',
  'Multiple projects and owners',
  'Prometheus metrics and associated integrations',
  'Account security features (RBAC, SSO, Private Link, Audit Logs, IP Restrictions, etc.)',
];
