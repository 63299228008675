import {useAppSelector as useSelector} from '../app/store';

export const useNetworkAllowlistItem = (globalProjectId: string) => {
  return useSelector((state) => state.networkAllowlists.items[globalProjectId]);
};

export const useNetworkAllowlistUpdateError = () => {
  return useSelector((state) => state.networkAllowlists.updateError);
};

export const useNetworkAllowlist = (globalProjectId: string) => {
  return useSelector((state) => state.networkAllowlists.items[globalProjectId]?.data);
};
