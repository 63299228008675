import {zodResolver} from '@hookform/resolvers/zod';
import {
  type PaginationState,
  usePagination,
} from '@pinecone-experience/timber/PaginationControls/hooks';
import {useCallback, useMemo} from 'react';
import {type UseFormReturn, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {queryVectors} from '../../../../../actions/dataActions';
import {NotificationTypes, enqueNotification} from '../../../../../actions/notificationActions';
import {type QueryData, type QueryRequest} from '../../../../../api/dataPlaneApi';
import {useDataPlane} from '../../../../../hooks/use-api';
import {type VectorState} from '../../../../../reducers/explorerReducer';
import {useExplorerResultsByGlobalProject} from '../../../../../selectors/explorer';
import {type GlobalProject} from '../../../../../types';
import {type ConjunctionInputNode} from '../../../../../types/metadata/inputs';
import {type SchemaType, createSchema} from '../utilities/schema';
import {inputNodesToMetadataFilter} from '../utilities/utils';

const DEFAULT_TOP_K = 10;

export type QueryExplorerState = {
  paginationState: PaginationState<VectorState & {score: number}>;
  controllerState: UseFormReturn<SchemaType>;
  onClickQuery: (formData: SchemaType, retries?: number) => void;
  setFormQuery: (query: QueryData, namespace: string) => void;
};

export function useQueryExplorerSetup({
  dimensions,
  project,
  service,
  host,
}: {
  dimensions: number;
  project: GlobalProject;
  service: string;
  host: string;
}) {
  const schema = useMemo(() => createSchema(dimensions), [dimensions]);
  const controllerState = useForm<SchemaType>({
    defaultValues: {
      namespace: '',
      topK: DEFAULT_TOP_K,
      queryMode: 'vector',
      queryVectorText: [],
      queryId: '',
      filters: [],
    },
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const {setValue} = controllerState;
  const results = useExplorerResultsByGlobalProject(project.id, service) || [];
  const paginationState = usePagination(results, ['', ''], 10);
  const {setCurrentPage} = paginationState;
  const dispatch = useDispatch();
  const {setQueryData} = useDataPlane<{query: QueryRequest; skipNotify: boolean; retries?: number}>(
    project,
    service,
    host,
  );
  const doQuery = useCallback(
    (
      query: QueryData,
      namespace: string,
      topK: number,
      filter: ConjunctionInputNode[],
      retries = 0,
    ) => {
      setQueryData({
        action: queryVectors,
        data: {
          query: {
            ...query,
            namespace,
            topK,
            includeMetadata: true,
            includeValues: true,
            filter: inputNodesToMetadataFilter(filter),
          },
          skipNotify: true,
          retries,
        },
      });
      setCurrentPage(0);
    },
    [setQueryData, setCurrentPage],
  );
  // TODO(dan): debounce
  const setFormQuery = useCallback(
    (query: QueryData, namespace: string) => {
      setValue('namespace', namespace);
      if ('id' in query) {
        setValue('queryId', query.id);
        setValue('queryMode', 'id');
      } else {
        setValue('queryVectorText', query.vector);
        setValue('queryMode', 'vector');
      }
    },
    [setValue],
  );
  // TODO(dan): debounce
  const onClickQuery = useCallback(
    (formData: SchemaType, retries = 0) => {
      try {
        if (formData.queryMode === 'vector') {
          doQuery(
            {vector: formData.queryVectorText},
            formData.namespace || '',
            formData.topK,
            formData.filters,
            retries,
          );
        } else if (formData.queryMode === 'id') {
          doQuery(
            {id: formData.queryId || ''},
            formData.namespace || '',
            formData.topK,
            formData.filters,
            retries,
          );
        }
      } catch (err: any) {
        dispatch(enqueNotification({type: NotificationTypes.ERROR, text: err.message}));
      }
    },
    [doQuery, dispatch],
  );
  return {
    paginationState,
    controllerState,
    onClickQuery,
    setFormQuery,
  };
}
