import {type QuotaKeys} from '@pinecone-experience/commons/quotas';
import {type AssistantQuotaKeys} from '../../../constants';
import {type QuotaUnitTooltipProps} from './Tooltips/Tooltips';

export interface EnrichedUsageQuota {
  key: QuotaKeys | AssistantQuotaKeys;
  name: string;
  abbreviation?: string;
  effects: UsageQuotaEffects[];
  tooltip: (tooltipProps: QuotaUnitTooltipProps) => React.ReactNode;
  utilization: number;
  limit: number;
  unit?: string;
}
export enum UsageQuotaEffects {
  WRITE = 'write',
  READ = 'read',
}
export const UsageQuotaEffectActionMap: Record<UsageQuotaEffects, string> = {
  [UsageQuotaEffects.WRITE]: 'writing',
  [UsageQuotaEffects.READ]: 'reading',
};
