import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link as RouterLink} from 'react-router-dom';
import {startBulkImport} from '../../actions/bulkImportActions';
import {listStorageIntegrations} from '../../actions/storageIntegrationActions';
import {type StartBulkImportRequest} from '../../api/dataPlaneApi';
import {PUBLIC_DOCS, ProjectPage} from '../../constants';
import {useNavigateToProjectPageGlobal} from '../../hooks/navigation';
import {useControllerApi, useDataPlane} from '../../hooks/use-api';
import {useSelectedOrganizationId} from '../../selectors/params';
import {useSelectedGlobalProject} from '../../selectors/projects';
import {useServiceByGlobalProject} from '../../selectors/services';
import {
  useStorageIntegrations,
  useStorageIntegrationsAreLoading,
} from '../../selectors/storageIntegrations';
import {type BulkImportErrorMode, StorageIntegrationProvider} from '../../types';
import ModalForm from '../ModalForm';
import {importDataSchema} from './schema';

interface StartBulkImportModalProps {
  selectedIndexName: string;
  open: boolean;
  close: () => void;
}

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

const OnErrorMode: Record<BulkImportErrorMode, string> = {
  continue: 'Continue on error',
  abort: 'Abort on error (default)',
};

function StartBulkImportModal({selectedIndexName, open, close}: StartBulkImportModalProps) {
  const organizationId = useSelectedOrganizationId('-');
  const selectedProject = useSelectedGlobalProject();
  const globalProjectId = selectedProject?.id;
  const indexHost = useServiceByGlobalProject(globalProjectId, selectedIndexName)?.host;
  const {getPath} = useNavigateToProjectPageGlobal(organizationId);

  const storageIntegrations = useStorageIntegrations(globalProjectId).filter(
    ({provider}) => provider === StorageIntegrationProvider.S3,
  );
  const storageIntegrationsLoading = useStorageIntegrationsAreLoading(globalProjectId);
  const {setQueryData: fetchStorageIntegrations} = useControllerApi<{globalProjectId: string}>(
    selectedProject,
  );

  useEffect(() => {
    if (open) {
      fetchStorageIntegrations({action: listStorageIntegrations, data: {globalProjectId}});
    }
  }, [open, fetchStorageIntegrations, globalProjectId]);

  const {
    handleSubmit,
    control,
    watch,
    formState: {isValid},
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      integration: '',
      uri: '',
      onError: 'abort' as BulkImportErrorMode,
    },
    resolver: zodResolver(importDataSchema),
  });

  const data = watch();

  const {setQueryData: setStartBulkImport} = useDataPlane<StartBulkImportRequest>(
    selectedProject,
    selectedIndexName,
    indexHost || '',
  );

  const integrationOptions = [
    {
      label: 'No integration (public bucket)',
      value: 'public',
    },
    ...storageIntegrations.map((integration) => ({
      value: integration.id,
      label: integration.name,
    })),
  ];

  const handleClose = () => {
    reset({
      integration: '',
      uri: '',
      onError: 'continue',
    });
    close();
  };

  const onSubmit = () => {
    setStartBulkImport({
      action: startBulkImport,
      data: {
        integration: data.integration === 'public' ? undefined : data.integration,
        uri: data.uri,
        errorMode: {onError: data.onError},
      },
    });
    handleClose();
  };
  return (
    <ModalForm
      open={open}
      close={handleClose}
      subtitle={
        <>
          Select an S3 storage integration and AWS-hosted serverless index to start a new import
          job.{' '}
          <Link href={PUBLIC_DOCS.IMPORTS} target="_blank">
            Learn more
          </Link>
        </>
      }
      form={
        <Box sx={styles.form}>
          <SelectInput
            name="integration"
            control={control}
            placeholder="Select a storage integration"
            options={integrationOptions}
            optionsAreLoading={storageIntegrationsLoading}
            required
            autoFocus
            label="Storage integration"
            fullWidth
            tooltip={
              <>
                Create{' '}
                <Link component={RouterLink} to={getPath(globalProjectId, ProjectPage.STORAGE)}>
                  a storage integration
                </Link>{' '}
                to import your data or specify a URI from a publicly-accessible S3 bucket.
              </>
            }
          />
          <TextInput
            name="uri"
            control={control}
            label="Data source"
            tooltip={
              <>
                Specify the URI of the prefix containing the namespace and Parquet files you want to
                import.{' '}
                <Link href={`${PUBLIC_DOCS.IMPORTS}#directory-structure`} target="_blank">
                  Learn more
                </Link>
              </>
            }
            fullWidth
            placeholder="s3://BUCKET_NAME/PATH/TO/NAMESPACES"
          />
          <SelectInput
            name="onError"
            control={control}
            label="Error handling"
            fullWidth
            options={Object.keys(OnErrorMode).map((key) => ({
              label: OnErrorMode[key as BulkImportErrorMode],
              value: key,
            }))}
          />
        </Box>
      }
      title="Import data"
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Start import"
    />
  );
}

export default StartBulkImportModal;
