import type {FormConfig, InkeepBaseSettings} from '@inkeep/uikit';
import * as inkeepJS from '@inkeep/uikit-js';
import {submitSupportTicket} from '../../actions/supportActions';
import {store} from '../../app/store';
import {
  INKEEP_API_KEY,
  INKEEP_INTEGRATION_ID,
  INKEEP_ORGANIZATION_ID,
  SupportCategory,
  SupportSeverityLevel,
} from '../../constants';

type ChatMessage = {
  role: string;
  content: string;
};

type ChatSession = {
  chatSessionId: string;
  messages: ChatMessage[];
};

function formatChatSession(chatSession: ChatSession | null): string {
  if (!chatSession) return 'No chat session data available.';

  const {chatSessionId, messages} = chatSession;
  let formattedMessages = 'Chat Messages:\n';

  messages.forEach((message: ChatMessage, index: number) => {
    formattedMessages += `Message ${index + 1}:\n`;
    formattedMessages += `  Role: ${message.role}\n`;
    formattedMessages += `  Content: ${message.content}\n\n`;
  });

  formattedMessages += `Chat Session Link: https://portal.inkeep.com/pinecone/projects/clpa1pr5a001as60142txfyfg/chat/chat-sessions?chatId=${chatSessionId}\n`;

  return formattedMessages;
}

export const baseSettings: InkeepBaseSettings = {
  apiKey: INKEEP_API_KEY ?? '',
  integrationId: INKEEP_INTEGRATION_ID ?? '',
  organizationId: INKEEP_ORGANIZATION_ID ?? '',
  organizationDisplayName: 'Pinecone',
  primaryBrandColor: '#1c17ff',
  theme: {
    stylesheetUrls: ['https://www.pinecone.io/customerStylesheets/pinecone.css'],
    primaryColors: {
      textColorOnPrimary: 'white',
    },
    colors: {
      gray: {
        'gray.50': '242 243 251',
        'gray.100': '236 237 246',
        'gray.200': '223 225 238',
        'gray.300': '204 206 219',
        'gray.400': '166 169 188',
        'gray.500': '129 132 153',
        'gray.600': '102 105 126',
        'gray.700': '76 78 97',
        'gray.800': '51 53 71',
        'gray.900': '25 27 44',
      },
    },
    components: {
      AIChatPageWrapper: {
        defaultProps: {
          size: 'expand', // 'shrink-vertically' 'expand', 'default', 'full-viewport'
          variant: 'no-shadow', // 'no-shadow' or 'container-with-shadow'
        },
      },
      SearchBarTrigger: {
        defaultProps: {
          variant: 'subtle',
        },
      },
    },
  },
  customIcons: {
    search: {builtIn: 'IoSearch'},
  },
  breadcrumbRules: {
    urlToBreadcrumbMapper: [
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'pinecone.io',
        },
        replaceLeading: true,
        breadcrumbName: 'Home',
      },
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'docs.pinecone.io',
        },
        replaceLeading: true,
        breadcrumbName: 'Docs',
      },
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'www.pinecone.io/learn',
        },
        replaceLeading: true,
        breadcrumbName: 'Learn',
      },
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'www.pinecone.io/blog',
        },
        replaceLeading: true,
        breadcrumbName: 'Blog',
      },
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'pinecone.io/community',
        },
        replaceLeading: true,
        breadcrumbName: 'Community',
      },
      {
        matchingRule: {
          ruleType: 'PartialUrl',
          partialUrl: 'https://support.pinecone.io/hc/en-us',
        },
        replaceLeading: true,
        breadcrumbName: 'Support',
      },
    ],
  },
  customCardSettings: [
    {
      filters: {
        RootBreadcrumb: 'Community',
      },
      searchTabLabel: 'Community',
      icon: {builtIn: 'IoPeopleOutline'},
    },
    {
      filters: {
        RootBreadcrumb: 'Blog',
      },
      searchTabLabel: 'Blog',
      icon: {builtIn: 'IoPencilOutline'},
    },
    {
      filters: {
        RootBreadcrumb: 'Learn',
      },
      searchTabLabel: 'Learn',
      icon: {builtIn: 'IoBookOutline'},
    },
    {
      filters: {
        RootBreadcrumb: 'Home',
      },
      searchTabLabel: 'Home',
      icon: {builtIn: 'IoHomeOutline'},
    },
    {
      filters: {
        RootBreadcrumb: 'Support',
      },
      searchTabLabel: 'Support',
      icon: {builtIn: 'IoHelpBuoyOutline'},
    },
  ],
};

export const createSupportFormConfig = (user?: {email?: string}): FormConfig => ({
  heading: 'Contact support',
  successConfirmationPage: {
    heading: 'Submission successful',
    message:
      'Thank you for sharing your feedback. Pinecone may contact you with follow-up questions.',
  },
  fields: [
    {
      type: 'STANDARD_FIELD',
      label: 'Email (associated with Pinecone account)',
      name: 'email',
      inputType: 'EMAIL',
      required: true,
      defaultValue: user?.email,
    },
    {
      type: 'STANDARD_FIELD',
      label: 'Subject',
      name: 'subject',
      inputType: 'TEXT',
      required: true,
    },
    {
      type: 'STANDARD_FIELD',
      label: 'Category',
      name: 'category',
      inputType: 'SELECT',
      defaultValue: '',
      selectOptions: [
        {value: '', label: 'Select a category'},
        {value: SupportCategory.BILLING_QUESTION, label: 'Billing question'},
        {value: SupportCategory.INDEX_MANAGEMENT, label: 'Index management'},
        {
          value: SupportCategory.PROJECT_OR_ORGANIZATION_MANAGEMENT,
          label: 'Project or Organization management',
        },
        {value: SupportCategory.DRIVER_OR_CLIENT_QUESTION, label: 'Driver or client question'},
        {value: SupportCategory.WEBSITE_OR_CONSOLE_QUESTION, label: 'Website or console question'},
        {value: SupportCategory.EARLY_ACCESS_QUESTION, label: 'Early access question'},
      ],
    },
    {
      type: 'INCLUDE_CHAT_SESSION',
      defaultValue: true,
    },
    {
      type: 'STANDARD_FIELD',
      label: 'Additional details',
      name: 'additional_details',
      inputType: 'TEXTAREA',
    },
  ],
  submitCallback: async (values: any) => {
    const {formDetails, chatSession} = values;
    await store.dispatch(
      submitSupportTicket({
        subject: formDetails.subject,
        email: formDetails.email,
        category: formDetails.category,
        severity: formDetails.severity ?? SupportSeverityLevel.SEV4,
        additionalDetails: formDetails.additional_details,
        chatSession: chatSession ? formatChatSession(chatSession) : undefined,
      }),
    );
    // TODO(@kylermintah): handle result
    return {success: true};
  },
});

export const initializeInkeep = () => inkeepJS.Inkeep(baseSettings);
