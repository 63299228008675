import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {Box, Chip, Link, Paper, Typography} from '@mui/material';
import Table from '@pinecone-experience/timber/Table';
import type React from 'react';
import {detailedPricingData} from '../../plans/constants';

const styles = {
  container: {
    mt: 3,
  },
  tableContainer: {
    '& th': {
      fontWeight: 'normal',
    },
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  headingTitle: {
    fontSize: '1.8125rem',
    lineHeight: '120%',
    fontWeight: 'normal',
    letterSpacing: '-0.28px',
  },
  headingText: {
    my: 2,
    fontWeight: 'normal',
  },
  price: {
    fontSize: '2rem',
    lineHeight: '120%',
    fontWeight: 'normal',
    letterSpacing: '-0.24px',
    color: 'text.secondary',
  },
  tag: {
    px: '6px',
    py: '3px',
    fontSize: '0.5625rem',
    lineHeight: '120%',
    fontWeight: 'medium',
    letterSpacing: '0.45px',
  },
  docsLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    fontSize: 16,
    fontWeight: 'normal',
    textDecoration: 'none',
    color: 'text.secondary',
    '&:hover': {
      color: 'text.primary',
      textDecoration: 'underline',
    },
  },
};

const Tag: React.FC<{text: string}> = ({text}) => (
  <Chip
    label={text}
    size="small"
    sx={{
      ...styles.tag,
      borderColor: text === 'COMING SOON' ? 'grey.300' : 'primary.light',
      bgcolor: text === 'COMING SOON' ? 'grey.200' : 'primary.lighter',
      color: text === 'COMING SOON' ? 'text.secondary' : 'primary.main',
    }}
  />
);

const PricingDetailsTable: React.FC = () => {
  const cellRenderer = (row: number, col: number) => {
    const dataRow = detailedPricingData[row];
    if (!dataRow) return '';

    if (dataRow.isSection) {
      if (col === 0) {
        return (
          <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mt: 3}}>
            <Typography variant="h6">{dataRow.feature}</Typography>
            {dataRow.tag && <Tag text={dataRow.tag} />}
            {dataRow.docsLink && (
              <Link href={dataRow.docsLink} sx={styles.docsLink} target="_blank">
                Docs <ArrowOutwardIcon fontSize="small" />
              </Link>
            )}
          </Box>
        );
      }
      return null;
    }

    const renderMultilineText = (text: string) => {
      return text.split('\n').map((line, index) => (
        <Typography key={index} variant="body1" sx={{whiteSpace: 'normal'}}>
          {line}
        </Typography>
      ));
    };

    switch (col) {
      case 0:
        return (
          <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 1, maxWidth: '250px'}}>
            <Typography variant="subtitle2">{dataRow.feature}</Typography>
            {dataRow.tag && <Tag text={dataRow.tag} />}
          </Box>
        );
      case 1:
        return renderMultilineText(dataRow.starter || '');
      case 2:
        return renderMultilineText(dataRow.standard || '');
      case 3:
        return renderMultilineText(dataRow.enterprise || '');
      default:
        return '';
    }
  };

  return (
    <Box sx={styles.container}>
      <Paper>
        <Box sx={styles.tableContainer}>
          <Table
            columns={[
              {name: '', dataType: 'string', width: 450},
              {name: 'Starter', dataType: 'string'},
              {name: 'Standard', dataType: 'string'},
              {name: 'Enterprise', dataType: 'string'},
            ]}
            numRows={detailedPricingData.length + 1}
            cellRenderer={cellRenderer}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default PricingDetailsTable;
