import {useAppSelector as useSelector} from '../app/store';

export const useArchivedIndexesByGlobalProject = (projectId: string) => {
  return useSelector((state) => {
    return state.forcedMigration.projects[projectId];
  });
};

export const useMigratingService = () => {
  return useSelector((state) => {
    return state.forcedMigration.migratingIndex;
  });
};

export const useHasDeletedApiKeys = (orgId: string) => {
  return useSelector((state) => state.forcedMigration.organizationsKeysDeleted[orgId]);
};
