export function prettyPrintExample(obj: object) {
  const output = JSON.stringify(
    obj,
    (k, v) => {
      if (v instanceof Array && k === 'values') {
        return JSON.stringify(v);
      }
      return v;
    },
    2,
  );
  return output
    .replace(/\\/g, '')
    .replace(/"\[/g, '[')
    .replace(/\]"/g, ']')
    .replace(/"\{/g, '{')
    .replace(/\}"/g, '}');
}

const ALPHANUMERIC_LOWERCASE_HYPHENS_REGEX = /^[a-z0-9-]+$/;
// Allows for lowercase letters, numbers, and hypens
export function isLowercaseAlphaNumericWithHyphens(str: string) {
  return ALPHANUMERIC_LOWERCASE_HYPHENS_REGEX.test(str);
}

export const ALPHANUMERIC_HYPHENS_REGEX = /^[a-zA-Z0-9-]+$/;
export function isAlphaNumericWithHyphens(str: string) {
  return ALPHANUMERIC_HYPHENS_REGEX.test(str);
}

const ALPHANUMERIC_LOWERCASE_REGEX = /^[a-z0-9]+$/;
// Allows for lowercase letters, and numbers, only
export function isLowercaseAlphaNumeric(str: string) {
  return ALPHANUMERIC_LOWERCASE_REGEX.test(str);
}

export function capitalize(str: string = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatMoney(cents: number, short = false) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: short ? 0 : 2,
    minimumFractionDigits: short ? 0 : 2,
  });
  let total = cents / 100;
  if (total > 0 && total < 0.01) {
    total = 0.01;
  }
  return formatter.format(total);
}

export function formatLargeNumber(num: number) {
  // If over 1e15, compact just shows as 1000T, so switch to engineering
  const formatter = new Intl.NumberFormat('en-US', {
    notation: num >= 1e15 ? 'engineering' : 'compact',
  });
  return formatter.format(num);
}

export function formatByteSize(bytes: number, toFixed = 3, withSpace = true) {
  if (bytes < 1000) {
    return `${bytes} B`;
  }
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  return `${(bytes / 1000 ** i).toFixed(toFixed)}${withSpace ? ' ' : ''}${units[i]}`;
}

export function isEmail(email: string) {
  // allows for <anything>@anything.anything
  const re = /\S+@\S+\.\S+/;
  const res = re.test(email);
  return res;
}

export function formatStringList(strings: string[]) {
  const len = strings.length;
  if (len === 0) return '';
  if (len === 1) return strings[0];
  if (len === 2) return `${strings[0]} and ${strings[1]}`;
  return `${strings.slice(0, -1).join(', ')} and ${strings[len - 1]}`;
}

export function formatPluralAmount(amount: number, singular: string, plural: string) {
  return `${amount} ${amount === 1 ? singular : plural}`;
}

export function formatBytesToGB(bytes: number) {
  return Number((bytes / 10 ** 9).toFixed(2));
}

export function formatMilliseconds(ms: number, threshold = 1000): string {
  if (ms === 0) {
    return `${ms}`;
  }
  if (ms >= threshold) {
    const seconds = ms / 1000;
    return `${seconds}s`;
  }
  return `${ms}ms`;
}

export function shortenVector(vector: number[] | undefined, len: number) {
  if (!vector) {
    return '[]';
  }

  if (vector.length <= len) {
    return `[${vector}]`;
  }

  // Determine the number of elements to show from the start and end
  const front = Math.floor(len / 2);
  const back = len - front;

  return `[${[...vector.slice(0, front), ' ... ', ...vector.slice(-back)]}]`;
}
