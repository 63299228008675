import {type AnyAction, type Dispatch, createAction} from '@reduxjs/toolkit';
import type ControllerApi from '../api/controllerApi';
import {POLLING_INTERVAL} from '../constants';
import {type Collection} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {projectLoaded, projectNotLoaded} from './serviceActions';

export const listCollectionsRequest = createAction<{globalProjectId: string}>(
  'LIST_COLLECTIONS_REQUEST',
);
export const listCollectionsSuccess = createAction<{
  globalProjectId: string;
  collections: Collection[];
}>('LIST_COLLECTIONS_SUCCESS');
export const listCollectionsFailure = createAction<{globalProjectId: string; error: Error}>(
  'LIST_COLLECTIONS_FAILURE',
);

export function listCollections(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listCollectionsRequest({globalProjectId: data.globalProjectId}));
    return api
      .listCollections()
      .then((res) => {
        dispatch(
          listCollectionsSuccess({
            globalProjectId: data.globalProjectId,
            collections: res.collections,
          }),
        );
        dispatch(projectLoaded({globalProjectId: data.globalProjectId}));
      })
      .catch((err) => {
        if (err.status === 401) {
          dispatch(projectNotLoaded({globalProjectId: data.globalProjectId}));
          setTimeout(() => {
            dispatch(listCollections(api, data) as unknown as AnyAction);
          }, POLLING_INTERVAL);
          return;
        }
        dispatch(listCollectionsFailure({globalProjectId: data.globalProjectId, error: err}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to load collections. ${err.message}`,
          }),
        );
      });
  };
}

export const deleteCollectionRequest = createAction<{globalProjectId: string; collection: string}>(
  'DELETE_COLLECTION_REQUEST',
);
export const deleteCollectionSuccess = createAction<{globalProjectId: string; collection: string}>(
  'DELETE_COLLECTION_SUCCESS',
);
export const deleteCollectionsFailure = createAction<{globalProjectId: string; collection: string}>(
  'DELETE_COLLECTION_FAILURE',
);

export function deleteCollection(api: ControllerApi, data: {globalProjectId: string; id: string}) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCollectionRequest({globalProjectId: data.globalProjectId, collection: data.id}));
    return api
      .deleteCollection(data.id)
      .then(() => {
        dispatch(
          deleteCollectionSuccess({globalProjectId: data.globalProjectId, collection: data.id}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Collection ${data.id} is being deleted.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          deleteCollectionsFailure({globalProjectId: data.globalProjectId, collection: data.id}),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Collection failed to delete. ${err.message}`,
          }),
        );
      });
  };
}

export const createCollectionRequest = createAction<{globalProjectId: string}>(
  'CREATE_COLLECTION_REQUEST',
);
export const createCollectionSuccess = createAction<{globalProjectId: string; name: string}>(
  'CREATE_COLLECTION_SUCCESS',
);
export const createCollectionFailure = createAction<{globalProjectId: string; error: Error}>(
  'CREATE_COLLECTION_FAILURE',
);

export function createCollection(
  api: ControllerApi,
  data: {index: string; name: string; globalProjectId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(createCollectionRequest({globalProjectId: data.globalProjectId}));
    return api
      .createCollection(data.index, data.name)
      .then(() => {
        dispatch(createCollectionSuccess({globalProjectId: data.globalProjectId, name: data.name}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Collection ${data.name} is being created.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(createCollectionFailure({globalProjectId: data.globalProjectId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Collection failed to create. ${error.message}`,
          }),
        );
      });
  };
}

export const collectionDataSuccess = createAction<{
  globalProjectId: string;
  collectionName: string;
  collectionData: Collection;
}>('COLLECTION_DATA_SUCCESS');

export function getCollection(api: ControllerApi, data: {globalProjectId: string; name: string}) {
  return (dispatch: Dispatch) => {
    return api
      .getCollection(data.name)
      .then((collectionData) => {
        dispatch(
          collectionDataSuccess({
            globalProjectId: data.globalProjectId,
            collectionName: data.name,
            collectionData,
          }),
        );
      })
      .catch(() => {});
  };
}

export const collectionFinishedTerminating = createAction<{
  globalProjectId: string;
  collection: string;
}>('COLLECTION_FINISHED_TERMINATING');
export function checkCollectionTerminating(
  api: ControllerApi,
  data: {globalProjectId: string; name: string},
) {
  return (dispatch: Dispatch) => {
    return api.listCollections().then((res) => {
      if (!res.collections.find((collection) => collection.name === data.name)) {
        dispatch(
          collectionFinishedTerminating({
            globalProjectId: data.globalProjectId,
            collection: data.name,
          }),
        );
      }
    });
  };
}
