import {type Theme} from '@mui/material/styles';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';

interface ControlButtonProps {
  id: string;
  title: string;
  hidden: boolean;
  icon: React.ReactElement;
  selected: boolean;

  onClick: () => void;
}

const styles = {
  button: (selected: Boolean) => ({
    m: 0,
    borderRadius: '100%',
    backgroundColor: selected ? 'background.surface' : 'transparent',
    svg: {
      fill: (theme: Theme) => theme.palette.text.secondary,
    },
  }),
};

export function ControlButton({title, id, icon, selected, onClick, hidden}: ControlButtonProps) {
  return (
    <IconButton
      tabIndex={hidden ? -1 : undefined}
      tooltip={hidden ? undefined : title}
      icon={icon}
      onClick={onClick}
      id={`${id}-settings-button`}
      sx={styles.button(selected)}
      disabled={selected || hidden}
      color="secondary"
    />
  );
}
