import Box from '@mui/material/Box';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useCallback, useEffect, useRef, useState} from 'react';
import {type FileRejection, useDropzone} from 'react-dropzone';
import {useNavigateToAssistantFile} from '../../../../../hooks/navigation';
import {useBooleanState} from '../../../../../hooks/utils';
import {useAssistantFiles, useAssistantFilesAreLoading} from '../../../../../selectors/assistants';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {ImportModal} from '../../../ImportModal/ImportModal';
import DropArea from './DropArea/DropArea';
import FileItem from './FileItem/FileItem';

type FilesPanelProps = {
  assistantName: string;
  importFiles: (files: File[]) => void;
};

const MAX_FILES_UPLOAD = 10;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  filesScrollBox: {
    overflowY: 'auto',
  },
  files: {
    my: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default function FilesPanel({assistantName, importFiles}: FilesPanelProps) {
  const project = useSelectedGlobalProject() || {};
  const loading = useAssistantFilesAreLoading(assistantName);
  const files = useAssistantFiles(assistantName);
  const hasFiles = files.length > 0;

  const {go: goToFilePath} = useNavigateToAssistantFile(project.organization_id, project.id);
  const goToFile = (fileId: string) => goToFilePath(assistantName, fileId);

  const panelRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const {
    setTrue: openFilesModal,
    setFalse: closeFilesModal,
    val: showFilesModal,
  } = useBooleanState();

  const onRemoveAcceptedFile = useCallback(
    (fileName) => {
      setAcceptedFiles(acceptedFiles.filter((file) => file.name !== fileName));
    },
    [acceptedFiles, setAcceptedFiles],
  );

  const onDrop = useCallback(
    (accepted, rejected) => {
      setAcceptedFiles([...acceptedFiles, ...accepted]);
      setRejectedFiles([...rejectedFiles, ...rejected]);
      openFilesModal();
    },
    [acceptedFiles, rejectedFiles, openFilesModal],
  );

  const onCancel = useCallback(() => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
    closeFilesModal();
  }, [closeFilesModal, setAcceptedFiles, setRejectedFiles]);

  const {getRootProps, getInputProps, isDragAccept, isDragReject, open} = useDropzone({
    noClick: hasFiles || showFilesModal,
    onDrop,
    accept: {'application/pdf': [], 'text/plain': []},
  });

  useEffect(() => {
    if (!acceptedFiles.length && !rejectedFiles.length && showFilesModal) {
      closeFilesModal();
    }
  }, [acceptedFiles.length, rejectedFiles.length, showFilesModal, closeFilesModal]);

  return (
    <LoadingContainer loading={loading} showChildrenInBackground={hasFiles}>
      <Box {...getRootProps()} data-testid="dropzone" sx={styles.root} ref={panelRef}>
        <input {...getInputProps()} data-testid="dropzone-input" />
        <DropArea
          hasFiles={hasFiles}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          importModalOpen={showFilesModal}
          openImportModal={open}
        />
        <Box sx={styles.filesScrollBox}>
          <Box sx={styles.files}>
            {files.map((file) => (
              <FileItem key={file.id} file={file} goToFile={goToFile} />
            ))}
          </Box>
        </Box>
        <ImportModal
          open={showFilesModal}
          container={panelRef.current}
          loading={loading}
          assistantName={assistantName}
          maxFiles={MAX_FILES_UPLOAD}
          acceptedFiles={acceptedFiles}
          rejectedFiles={rejectedFiles}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          getDropzoneProps={getRootProps}
          onClose={onCancel}
          onAddFiles={open}
          onRemoveAcceptedFile={onRemoveAcceptedFile}
          onImportFiles={importFiles}
        />
      </Box>
    </LoadingContainer>
  );
}
