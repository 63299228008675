import {type QuotaKeys, type QuotaLimit} from '@pinecone-experience/commons/quotas';
import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {NotificationTypes, enqueNotification} from './notificationActions';

export const getProjectQuotaLimitsRequest = createAction<{projectId: string}>(
  'PROJECT_QUOTA_LIMITS_REQUEST',
);
export const getProjectQuotaLimitsSuccess = createAction<{
  projectId: string;
  quotaLimits: Record<QuotaKeys, QuotaLimit>;
}>('PROJECT_QUOTA_LIMITS_SUCCESS');
export const getProjectQuotaLimitsFailure = createAction<{projectId: string; error: Error}>(
  'PROJECT_QUOTA_LIMITS_FAILURE',
);

export const getProjectQuotaUtilizationRequest = createAction<{projectId: string}>(
  'PROJECT_QUOTA_UTILIZATION_REQUEST',
);
export const getProjectQuotaUtilizationSuccess = createAction<{
  projectId: string;
  quotaUtilization: Record<QuotaKeys, number>;
}>('PROJECT_QUOTA_UTILIZATION_SUCCESS');
export const getProjectQuotaUtilizationFailure = createAction<{projectId: string; error: Error}>(
  'PROJECT_QUOTA_UTILIZATION_FAILURE',
);

export function fetchProjectQuotaLimits(token: string, data: {projectId: string}) {
  return async (dispatch: Dispatch) => {
    dispatch(getProjectQuotaLimitsRequest({projectId: data.projectId}));
    return new DashboardApi(token)
      .getProjectQuotaLimits(data.projectId)
      .then((res) => {
        dispatch(
          getProjectQuotaLimitsSuccess({projectId: data.projectId, quotaLimits: res.quotaLimits}),
        );
      })
      .catch((error) => {
        dispatch(getProjectQuotaLimitsFailure({projectId: data.projectId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong fetching quota limits: ${error.message}`,
          }),
        );
      });
  };
}

export function fetchProjectQuotaUtilization(token: string, data: {projectId: string}) {
  return async (dispatch: Dispatch) => {
    dispatch(getProjectQuotaUtilizationRequest({projectId: data.projectId}));
    return new DashboardApi(token)
      .getProjectQuotaUtilization(data.projectId)
      .then((res) => {
        dispatch(
          getProjectQuotaUtilizationSuccess({
            projectId: data.projectId,
            quotaUtilization: res.quotaUtilization,
          }),
        );
      })
      .catch((error) => {
        dispatch(getProjectQuotaUtilizationFailure({projectId: data.projectId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong fetching quota utilization: ${error.message}`,
          }),
        );
      });
  };
}
