import Typography from '@mui/material/Typography';

export interface LegendTextProps {
  value: string;
  inactive?: boolean;
}

export function LegendText({value, inactive}: LegendTextProps) {
  return (
    <Typography
      color="text.primary"
      variant="body2"
      sx={{display: 'inline', opacity: inactive ? 0.5 : 1, cursor: 'pointer'}}
    >
      {value}
    </Typography>
  );
}
